import Vue from "vue";

import SurveyWizard from "./SurveyWizard.vue";

export function load(payload) {
    const selector = "survey-wizard";
    const store = payload;

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return;
  }

  // Create a new Vue app with the imported Home component
  new Vue({
    store,
    beforeCreate() {
      this.$store.commit('initialiseStore');
    },
    render: createElement =>
      createElement(SurveyWizard, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}

// export function appSurveys(appselector) {

//   var VueSurveys = new Vue({
//     render: createElement =>
//       createElement(SurveyWizard, {
//         props: {
//           ...window[appselector],
//         },
//       }),
//   }).$mount(appselector);
//   VueSurveys;
// }
