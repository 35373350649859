
<template>
    <div class="gridWrapper eAppList">
        <table v-if="gridData && gridData.length" class="eAppListTbl">
            <thead>
                <tr>
                    <th v-for="(header, index) in headers" :key="index"
                        @click="sortByCol(header, sortOrder)"
                        :class="{ active: sortColumn == header }">
                        {{ header }}
                        <span v-if="header!=='Status'" :class="sortColumn == header ? 'arrow ' + sortOrder : ''">
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(row, index) in gridData">
                    <tr :class="{masterRow: row['applicationIsMasterChain']}">
                        <td v-if="!showChains">
                            {{row['applicationNumber']}}
                        </td>
                        <td>
                            <a :href="'/eapplications/eapplication?id='+row['businessGuid']" class="businessName">{{ hasBusinessName(row['businessName']) }}</a>
                        </td>
                        <td>
                            {{row['businessCity']}}
                        </td>
                        <td v-if="!showChains" class="eAppTrack_status" :data-appstatus="row['applicationStatus']">
                            {{getStatus(row['applicationStatus'])}}
                        </td>
                        <td>
                            {{dateFormatter(row['createdOn'])}}
                        </td>
                        <td v-if="showChains" class="eAppTrack_status" :data-appstatus="row['applicationStatus']">
                            {{getStatus(row['applicationStatus'])}}
                        </td>

                    </tr>
                </template>
            </tbody>
        </table>
        <p v-else>There are no applications to display.</p>
        
        <grid-paginate v-if="totalPages > 1"
                        :totalPages="totalPages"
                        :currentPage="currentPage"
                        :itemsPerPage="itemsPerPage"
                        @changePage="changePage"/>
    </div>
</template>
<script>
    import GridPaginate from "../../Pagination/pagination.vue";
    import moment from 'moment';
    export default {
        components: {
            GridPaginate
        },
        props: {
            gridData: Array,
            columns: Array,
            headers: Array,
            sortColumn: String,
            sortOrder: String,
            currentPage: Number,
            totalPages: Number,
            itemsPerPage: Number,
            showChains: Boolean
        },
        methods: {
            sortByCol(key, order) {
                this.$emit('sortListBy', { key, order });
            },
            searchBy(filter) {
                this.$emit('searchBy', { filter });
            },
            changePage(pageNumber) {
                this.$emit('changePage', pageNumber );
            },
            dateFormatter(date) {
                return moment(date).format('M/D/yyyy');
            },
            hasBusinessName(bizName) {
                return (bizName ? bizName : '- No business name available -');
            },
            getStatus(status) {
                return this.getEappStatus(status);
            }
        },
        computed: {
        }
    }
</script>
