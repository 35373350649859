<template>
    <div class="keno-archives-wrapper">
        <div class="grid-x date-or-nunber-wrapper">
            <p>Search for winning numbers by drawing # or drawing date. Each drawing’s results will be available on the web
                site within 2 minutes after the drawing.</p>
            <div class="cell small-12 form">
                <div class="keno-search-by-number">
                    <SearchByNumber v-model="number"></SearchByNumber>
                    <input type="submit" class="button" name="SearchByDrawNumber" placeholder="Draw Number"
                        value="Search By Number" @click="clkByNumber($event)">
                </div>

                <div class="or-divisor">
                    <div class="word"><span>or</span></div>
                </div>

                <div class="keno-search-by-date">
                    <SearchByDate></SearchByDate>
                    <input type="submit" class="button" name="SearchByDrawDate" placeholder="Search By Date"
                        value="Search By Date" @click="clkByDate($event)">
                </div>
            </div>
        </div>

        <div class="error-message" v-if="showError">
            <div class="callout alert">
                <p>{{ errMsg }}</p>
            </div>
        </div>

        <div class="grid-x results" v-if="isLoading">
            <div class="cell small-12">
                <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" />
            </div>
        </div>

        <div class="grid-x results" v-if="showResults">
            <div class="cell small-12 text-right">
                <a title="Printer Friendly" class="printfriendly" href="#" @click="print()">Print</a>
            </div>
            <div class="cell small-12 text-left LuckyOne_results">
                Results For: <span v-if="searchNumb != ''">{{ searchNumb }}</span><span v-if="searchDate != ''">{{
                    searchDate }}</span>
            </div>
            <table id="luckyone-table-archives" border="0">
                <thead>
                    <tr>
                        <th>Draw Number</th>
                        <th>Drawn Numbers</th>
                        <th>Booster</th>
                    </tr>
                </thead>
                <!-- <tbody v-if="numberResult">
                    <tr>
                        <td>{{ numberExId }}</td>
                        <td>
                            <span class="draw-numbers" v-for="result in results" :key="result.id">
                                {{ result.value }}
                            </span>
                        </td>
                        <td>
                            {{ numberMod }}
                        </td>
                    </tr>
                </tbody>

                <tbody v-if="!numberResult">
                    <tr v-for="result in pageOfItems" :key="result.id">
                        <td>{{ result.externalDrawId }}</td>
                        <td>
                            <span v-for="number in result.numbers.slice(0, -1)" :key="number.id">
                                {{ number.value }}
                            </span>
                        </td>
                        <td>
                            <span v-for="number in result.numbers.slice(-1)" :key="number.id">
                                {{ number.value }}
                            </span>
                        </td>
                    </tr>
                </tbody> -->

                <tbody v-if="numberResult">
                    <tr>
                        <td>{{ numberExId }}</td>
                        <td class="numbers">
                            <span class="draw-numbers" v-for="result in results" :key="result.id">
                                <span v-if="result.modifier != 4">
                                    {{ result.value }}</span>
                            </span>
                        </td>
                        <td>
                            <span class="draw-numbers" v-for="result in results" :key="result.id">
                                <span v-if="result.modifier === 4">{{ result.value }}</span>
                            </span>
                        </td>
                    </tr>
                </tbody>

                <tbody v-if="!numberResult">
                    <tr v-for="result in pageOfItems" :key="result.id">
                        <td>{{ result.externalDrawId }}</td>
                        <td class="numbers">
                            <span class="draw-numbers" v-for="number in result.numbers" :key="number.id">
                                <span v-if="number.modifier != 4">{{ number.value }}</span>
                            </span>
                        </td>
                        <td>
                            <span class="draw-numbers" v-for="number in result.numbers" :key="number.id">
                                <span v-if="number.modifier === 4">{{ number.value }}</span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <jw-pagination v-if="!numberResult" :items="results" :pageSize="25" @changePage="onChangePage"></jw-pagination>
        </div>
    </div>
</template>

<script>
import JwPagination from 'jw-vue-pagination';
import SearchByNumber from './SearchByNumber.vue';
import SearchByDate from './SearchByDate.vue';
import { HollowDotsSpinner } from 'epic-spinners'

export default {
    components: {
        JwPagination,
        SearchByNumber,
        SearchByDate,
        HollowDotsSpinner

    },
    data: function () {
        return {
            number: '',
            searchNumb: '',
            searchDate: '',
            results: [],
            pageOfItems: [],
            numberMod: '',
            numberExId: '',
            errMsg: '',
            numberResult: false,
            showResults: false,
            showError: false,
            isLoading: false
        }
    },
    mounted: function () {
    },
    methods: {
        clkByNumber(event) {
            this.showResults = false;
            this.showError = false;
            let eventName = event.target.getAttribute('name'),
                inputValue = this.number

            document.getElementsByName('inputKenoDate')[0].value = ""

            this.isLoading = true;

            if (inputValue.length < 4 || document.getElementsByName('inputSearchByNumber')[0].value == "") {
                this.showError = true;
                this.isLoading = false;
                this.errMsg = "We're sorry, we couldn't find any results for that search.";
                return
            } else {
                this.getToken(eventName, inputValue)
            }

            this.searchNumb = inputValue;
            this.searchDate = '';
        },
        clkByDate(event) {
            this.showResults = false;
            this.showError = false;
            let eventName = event.target.getAttribute('name'),
                inputValue = document.querySelector("input[name=inputKenoDate]").value

            document.getElementsByName('inputSearchByNumber')[0].value = "";

            this.isLoading = true;
            this.getToken(eventName, inputValue);

            this.searchNumb = '';
            this.searchDate = inputValue;
        },
        getToken(name, input) {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token, name, input);
                });
        },
        fetchData(token, name, input) {
            let apiToken = token,
                methodName = name,
                inputVal = input,
                methodFormatUrl = '';

            if (methodName == 'SearchByDrawNumber') {
                methodFormatUrl = 'drawId'
            } else if (methodName == 'SearchByDrawDate') {
                methodFormatUrl = 'date'
            }

            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/DrawGames/Keno/' + methodName + '?' + methodFormatUrl + '=' + inputVal, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    // this.showResults = true;
                    let results = json.data;

                    this.isLoading = false;

                    if (json.data === null) {
                        this.showResults = false;
                        this.showError = true;
                        this.results = [];
                        this.errMsg = "We're sorry, we couldn't find any results for that search.";
                        return
                    }
                    else if (json.data.length === 0) {
                        this.showResults = false;
                        this.showError = true;
                        this.results = [];
                        this.errMsg = "We're sorry, we couldn't find any results for that search.";
                        return
                    } else if (json.data.winningNumbers) {
                        if (json.data.winningNumbers.length === 0) {
                            this.showResults = false;
                            this.showError = true;
                            this.results = [];
                            this.errMsg = "We're sorry, we couldn't find any results for that search.";
                            return
                        } else {
                            this.showError = false;
                            this.results = results.winningNumbers;
                            this.numberResult = true;
                            this.numberMod = results.winningNumbers[0].modifier;
                            this.numberExId = results.winningNumbers[0].externalDrawId;
                        }
                        this.showResults = true;
                    }
                    else {
                        this.showError = false;
                        if (methodFormatUrl == 'drawId') {
                            this.results = results.winningNumbers;
                            this.numberResult = true;
                            this.numberMod = results.winningNumbers[0].modifier;
                            this.numberExId = results.winningNumbers[0].externalDrawId;
                        } else {
                            this.numberResult = false;
                            this.results = results;
                        }
                        this.showResults = true;
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },
        print() {
            var kenoTable = document.getElementById("luckyone-table-archives");
            var newWin = window.open("");
            newWin.document.write(kenoTable.outerHTML);
            newWin.print();
            newWin.close();
        }

    },
    computed: {
    }


};
</script>