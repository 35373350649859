<template>
    <div class="userPointBalance" v-if="isAuthed">
        <span class="userGreeting">Hello <span class="userName">{{ userName }}</span></span>
        <span class="pointsLabel">Points: </span>
        <span class="currentBalance" id="currentBalance">{{ userPoints.toLocaleString() }}</span>
        <AutoLogout></AutoLogout>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import AutoLogout from './AutoLogout.vue';

    export default {
        components: {
            AutoLogout
        },
        data: function () {
            return {
                isAuthed: false,
                userName: '',
                userPoints: '',
                userID: ''
            }
        },
        mounted: function () {
            this.checkUserStatus();
            // localStorage.setItem('store', '');
        },
        methods: {
            checkUserStatus: function () {
                // get user infor from cookie
                let userData = this.$cookies.get('apiToken');
                // console.log('USER:');
                // console.log(userData);

                this.userName = userData.user.firstName;
                this.userPoints = userData.user.pointBalance.balance;
                this.userID = userData.user.userId;
                this.isAuthed = this.isUserAuthed();
                // this.getUserPoints();

            },
            // setUser: () => {
            //     this.$store.dispatch('setCurrentUser', {
            //         userId: this.userID,
            //         username: this.userName
            //     });
            // },
            // ...mapActions ({

            // }),
            // ...mapGetters ({
            //     getUserBalance: 'user/getUserBalance'
            // })
        }
    };
</script>

<style lang="scss" scoped>
</style>