import Vue from "vue";

import Component from "./business-sale-agreement.vue";

export function load() {
    const selector = "business-sale-agreement";

    // Is the custom Vue root element in the DOM?
    if (!document.querySelector(selector)) {
        return; // go no further
    }

    // Create a new Vue app with the imported component
    new Vue({
        render: createElement =>
            createElement(Component, {
                props: {
                    ...window[selector]
                }
            }),
    }).$mount(selector);
}
