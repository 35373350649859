<template>
    <div class=" register-form">
        <h2>Contact Information</h2>

        <form data-abide novalidate data-validate-on-blur="true" id="registerForm" v-if="formEnable">
            <div data-abide-error class="alert callout" style="display: none;">
                <p><i class="fi-alert"></i> There are some errors in your form.</p>
            </div>
            <div class="grid-container">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell medium-6">
                        <label>Legal Name</label>
                        <p>{{legalName}}</p>
                    </div>

                    <div class="cell medium-6">
                        <label>Date of Birth</label>
                        <p>Date of Birth Not In Json response</p>
                    </div>

                    <div class="cell medium-6">
                        <label>Email Address</label>
                        <p>{{ email }}</p>
                    </div>

                     <p class="help-text">If you would like to update your name, birthdate or email address, please contact the Ohio Lottery at <b>1-800-686-4208</b></p>
                </div>

                <hr/>

                <div class="grid-x grid-margin-x grid-margin-y">

                    <div class="cell medium-6">
                        <label>Preferred Name
                            <input type="text" v-model="prefName"  >
                            
                            <span class="form-error">
                                Some error here
                            </span>
                        </label>
                    </div>

                    <div class="cell medium-6">
                        <p>Go by name other than your legal name? Great, enter it here and we'll greet you with it when you log in.</p>
                    </div>
                    
                    
                    <div class="cell medium-12">
                        <label>Password
                            <input type="password"  id="password" placeholder="******" readonly >

                            <a href="#">
                                Change Password
                            </a>
                        </label>
                    </div>
                    
                </div>
            </div>

            <hr/>

            <div class="grid-container">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell medium-6">
                        <label>Mailing Address <small>Required</small>
                            <input type="text" v-model="mailingAddress" placeholder="12345 ADDRESS ST" required >
                            <span class="form-error">
                                Please enter your address
                            </span>
                        </label>
                    </div>
                    <div class="cell medium-6">
                        <label>Address (continued)
                            <input type="text" v-model="addressContinued" placeholder="Address (continued)" >
                        </label>
                    </div>
                    <div class="cell medium-6">
                        <label>City <small>Required</small>
                            <input type="text" placeholder="CITY" v-model="city" required >
                            <span class="form-error">
                                Please enter your city
                            </span>
                        </label>
                    </div>
                    <div class="cell medium-6">
                        <label>State <small>Required</small>
                            <select id="select" v-model="state" required>
                                <option value="OH">OH</option>
                                <option value="NY">NY</option>
                                <option value="DS">DS</option>
                            </select>
                            <span class="form-error">
                                Please enter your state
                            </span>
                        </label>
                    </div>

                    <div class="cell medium-6">
                        <label>Zip/Postal Code <small>Required</small>
                            <input type="text" minlength="5" maxlength="5" placeholder="Zip/Postal Code" v-model="zipcode" required pattern="number" >
                            <span class="form-error">
                               Please provide a valid Zip/Postal code
                            </span>
                        </label>
                    </div>

                    <div class="cell medium-6">
                        <label>Country
                            <select id="select" v-model="country" >
                                <option value="USA">United States</option>
                                <option value="CAN">Canada</option>
                            </select>
                        </label>
                    </div>

                </div>
            </div>

            <div class="grid-container">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell medium-6">
                        <label>Phone Number <small>required</small>
                            <input type="text" id="phoneInput" placeholder="123-456-7890" required pattern="^\d{3}-\d{3}-\d{4}$" >
                            <span class="form-error">
                               Please provide a valid phone number
                            </span>
                        </label>
                    </div>

                    <fieldset class="cell small-12">
                        <input  id="extraPoints" name="extraPoints" type="checkbox"><label for="extraPoints"><b>
                            Don’t miss out on any fun! Get email notifications of new promotions, drawings and chances to earn extra points.
                            </b>
                        </label>
                    </fieldset>  
                </div>
            </div>

            <hr />

            <div class="grid-container">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-12">
                        <h4>{{ termsTitle }}</h4>
                        <div class="terms" v-html="termsContent"></div>
                    </div>

                    <fieldset class="cell small-12">
                        <input required id="agreeTerms" v-model="terms" name="agreeTerms" type="checkbox"><label for="agreeTerms"><b>
                            I have read and agree to the above Ohio Lottery Terms of Use. *
                            </b>
                        </label>
                    </fieldset> 
                </div>
            </div>

            <div class="grid-container">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <fieldset class="cell large-6">
                        <button class="button" type="submit" value="Submit" @click="submit">Update Profile</button>
                        <a href="#">Cancel Changes</a>
                    </fieldset>
                </div>
            </div>
        </form>

        <div class="callout success" v-if="formSuccess">
            <p>Your user has been updated successfully.</p>
        </div>

        <div class="reveal callout" id="errorAddressModal" data-reveal>
            <h5>Address Confirmation</h5>
            <p>Please review the verified United States Postal Service address shown below. 
                Click the Confirm button to proceed with registration using the verified address
                 or click on Edit to revise your address.</p>

            <div class="grid-x">
                <div class="cell large-6">
                    <p>Submitted Address</p>
                    <p>{{ this.mailingAddress }} <br>
                    {{ this.city }}  <br>
                    {{ this.state }}  <br>
                    {{ this.zipcode }}  </p>


                    <div>
                        <button class="button" type="submit" value="Edit" @click="editAddress">Edit</button>
                    </div>
                </div>
                <div class="cell large-6" v-if="!verifiedAddress">
                    <p>Verified USPS Address</p>
                    <p>We could not verify the address supplied. 
                        Please review it for accuracy and make any necessary changes before continuing with registration.
                    </p>
                </div>

                <div class="cell large-6" v-if="verifiedAddress">
                    <p>Verified USPS Address</p>
                    <p>{{ this.verifiedMailingAddress }} <br>
                    {{ this.verifiedCity }}  <br>
                    {{ this.verifiedState }}  <br>
                    {{ this.verifiedZipcode }}  </p>


                    <div>
                        <button class="button alert" type="submit" value="Confirm" @click="confirmAddress">Confirm</button>
                        
                    </div>
                </div>

                
            </div>
        </div>

        <div class="reveal alert callout" id="errorRegisterModal" data-reveal>
            <p><i class="fi-alert"></i> {{registerErrorMsg}}</p>
        </div>

    </div>
       
</template>

<script>
    import $ from 'jquery';

    export default {
        components: {
           
        },
        data: function () {
            return {
                userId: '',
                legalName: '',
                email: '',
                firstName: '',
                lastName: '',
                prefName: '',
                password: null,
                mailingAddress: '',
                addressContinued: '',
                city: '',
                state: 'OH',
                zipcode: '',
                country: 'USA',
                termsTitle: '',
                termsContent: '',
                terms: '',
                registerApiToken: '',
                registerErrorMsg: '',
                formEnable: true,
                formSuccess: false,
                verifiedAddress: false,
                verifiedMailingAddress: '',
                verifiedCity: '',
                verifiedState: '',
                verifiedZipcode: '',
                confirmBodyAddress: {},
                phoneInput: document.getElementById('phoneInput')

            }
        },
        mounted: function () {
            this.getToken();
            this.phoneNumberFormat(this.phoneInput);           
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchTerms(token);
                    this.fetchUser(token)
                });

            },
            fetchUser(token) {
                var apiToken = token;
                this.registerApiToken = apiToken;
                var urlProtocol = this.getProtocol();
                this.userId = "107";
                var userId = this.userId;
                
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/PAM/GetUser/"+userId, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var data = json.data;
                    console.log(data)
                    this.legalName = data.firstName + ' ' + data.lastName;
                    this.firstName = data.firstName;
                    this.lastName = data.lastName;
                    this.email = data.email;
                    this.mailingAddress = data.address.address;
                    this.addressContinued = data.address.address2;
                    this.city = data.address.city;
                    this.state = data.address.state;
                    this.zipcode = data.address.zipCode;

                    
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            fetchTerms(token) {
                var apiToken = token;
                this.registerApiToken = apiToken;
                var urlProtocol = this.getProtocol();
                
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=terms-conditions", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var terms = json.data;
                    console.log(terms)
                    this.termsTitle = terms[0].title;
                    this.termsContent = terms[0].centralContent;
                    
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            submit: function() {
                let _this = this;
                            
                $(document).off('formvalid.zf.abide');
                 // script to trigger other events 
                $(document).on("formvalid.zf.abide", function() {
                    var apiToken = _this.registerApiToken;

                    let bodyAddress = {
                        "address": {
                            "address": _this.mailingAddress,
                            "address2": _this.addressContinued,
                            "city": _this.city,
                            "state": _this.state,
                            "zipCode": _this.zipcode
                        },
                        "customerId": 0
                    }

                    
                    const requestAddressOptions = {
                        method: 'POST',
                        headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+apiToken
                        },
                        body: JSON.stringify(bodyAddress)
                    };

                    let bodyRegister = {
                        "userId": _this.userId,
                        "email": _this.email,
                        "firstName": _this.firstName,
                        "lastName": _this.lastName,
                        "address": bodyAddress.address,
                        "validateAddress": true
                    }

                    const requestRegisterOptions = {
                        method: 'POST',
                        headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+apiToken
                        },
                        body: JSON.stringify(bodyRegister)
                    };

                    

                    fetch("https:"+process.env.VUE_APP_APIURL+"/1.0/PAM/ValidateAddress", requestAddressOptions)
                        .then(response => response.json())
                        .then(data => {

                            if( data.data.isProvidedAddressAvailable && data.data.isSuggestedAddressAvailable ){

                                if( bodyAddress.address.address2 === '' ){
                                    bodyAddress.address.address2 = null
                                }

                                let newAddress = bodyAddress.address;
                                let returnAddress = data.data.address;

                                if( JSON.stringify(newAddress) === JSON.stringify(returnAddress)  ){
                                    console.log( 'are same objects')

                                    newAddress = returnAddress;

                                    fetch("https:"+process.env.VUE_APP_APIURL+"/1.0/PAM/UpdateProfile", requestRegisterOptions)
                                    .then(response => response.json())
                                    .then(dataRegister => {
                                        console.log(dataRegister)
                                        $('#errorAddressModal').foundation('close');
                                        if( dataRegister.data == null ){
                                            _this.registerErrorMsg = dataRegister.error.message;
                                            $('#errorRegisterModal').foundation('open');
                                        }else{
                                            _this.formEnable = false;
                                            _this.formSuccess = true;
                                        }

                                        

                                    });
                                }else{
                                    _this.verifiedAddress = true;
                                    _this.confirmBodyAddress = returnAddress;
                                    _this.verifiedMailingAddress = returnAddress.address;
                                    _this.verifiedCity = returnAddress.city;
                                    _this.verifiedState = returnAddress.state;
                                    _this.verifiedZipcode = returnAddress.zipCode;

                                    $('#errorAddressModal').foundation('open');
                                }

                                
                            }else{
                                $('#errorAddressModal').foundation('open');
                            }

                        });
                })
                // to prevent form from submitting upon successful validation
                .on("submit", function(ev) {
                    ev.preventDefault();
                });
                
            },
            editAddress: function(){
                $('#errorAddressModal').foundation('close');
            },
            confirmAddress: function(){
                console.log( this.confirmBodyAddress  )
                var apiToken = this.registerApiToken;

                let confirmBodyRegister = {
                    "userId": this.userId,
                    "email": this.email,
                    "firstName": this.firstName,
                    "lastName": this.lastName,
                    "address": this.confirmBodyAddress,
                    "validateAddress": true
                }

                const confirmRegisterOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiToken
                    },
                    body: JSON.stringify(confirmBodyRegister)
                };

                fetch("https:"+process.env.VUE_APP_APIURL+"/1.0/PAM/UpdateProfile", confirmRegisterOptions)
                .then(response => response.json())
                .then(dataRegister => {
                    console.log(dataRegister)
                    $('#errorAddressModal').foundation('close');
                    if( dataRegister.data == null ){
                        this.registerErrorMsg = dataRegister.error.message;
                        $('#errorRegisterModal').foundation('open');
                    }else{
                        this.formEnable = false;
                        this.formSuccess = true;
                    }

                    

                });
            }
            
        },
        computed: {
           
        },
    };
</script>

<style lang="scss" scoped>
input, select{
    height: auto;
    line-height: inherit;
}
.form-error-birth{
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: #cc4b37;
    display: block;
}

.terms{
    overflow: auto;
    height: 200px;
    width: 100%;
    padding: 10px;
    margin: 0 0 10px;
    background: #fff;
    border: 1px solid #B9BDC3;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-clip: padding-box;
}
</style>