import Vue from "vue";

import Pick5WN from "./Pick5-WinningNumbers.vue";

export function load() {
  const selector = "pick5-numbers";

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return; // go no further
  }

  // Create a new Vue app with the imported component
  new Vue({
    render: createElement =>
      createElement(Pick5WN, {
        props: {
          ...window[selector]
        }
      }),
  }).$mount(selector);
}
