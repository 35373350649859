<template>
    <section class="contentRow content cf form-wrapper eApp">

        <h1 class="eAppHeader">Retailer eApplication System</h1>

        <hollow-dots-spinner :animation-duration="1000"
                             :dot-size="15"
                             :dots-num="3"
                             color="#0FD8C9"
                             v-if="isLoading" />

        <!-- add dashboard links for lottery staff -->
        <!-- ifIsInRole: eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-RegionalManager || eAppDistrictRep || eApp-Security -->
        <a v-if="showApplicationDashboard" class="button eAppDashBtn" href="/eApplications/Dashboard/Regular"><span class="icon-chevron-double-left"></span> Application Dashboard</a>
        <!-- ifIsInRole: eApp-ChainSalesRep || eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-Security -->
        <a v-if="showChainApplicationDashboard" class="button eAppDashBtn" href="/eApplications/Dashboard/Chain"><span class="icon-chevron-double-left"></span> Chain Application Dashboard</a>


        <div v-if="!isLoading" class="eAppContent">
            <h2 v-if="applicationFound && application.applicationIspartOfChain" class="eAppTitle">
                <!-- place the application name here -->
                {{application.applicationMasterChainName}}
            </h2>

            <h2 class="eAppTitle" v-if="applicationFound">
                <!-- place the application name here -->
                {{application.businessInformation.businessLegalName}}
            </h2>

            <h3 class="eAppSubtitle" v-if="application.applicationNumber">Application Number: <strong>{{ application.applicationNumber }}</strong></h3>

            <div v-if="errorMessageShow" class="ErrorLabel alert-error">
                There was an error processing your request.
                <span>{{ additionalErrorInfo }}</span>
            </div>
            <div v-if="showSuccessMessage" class="callout success">
                Your changes were saved successfully.
            </div>
            
            <!-- eApp Tracker will go here -->
            <Tracker :appStatus="application.applicationStatus" v-if="!userIsAdmin"></Tracker>
            <div class="cf content with_subnav content_eApp" v-if="applicationFound">
                <nav class="leftSide">
                    <!-- set up the left nav here -->
                    <left-menu @sectionClick="sectionClicked" :userRole="userRole" :visibleSection="visibleSection" :readOnly="readOnly" :applicationJson="applicationJson"></left-menu>
                </nav>
                <div class="page_content cf " v-if="!userIsAdmin">
                    <!-- all application editing occurs here -->
                    <!-- dynamic based on app ID from querystring -->
                    <div class="form-section form-section-business" v-if="!isLoading && (visibleSection == 'business' || visiblePanel=='landing')">
                        <business-landing v-if="visiblePanel=='landing'"
                                          :appStatus="application.applicationStatus"></business-landing>

                        <business-section v-if="visibleSection=='business'"
                                          :applicationInfoJson="applicationJson"
                                          :businessJson="businessJson"
                                          :contactJson="contactJson"
                                          :principalsJson="principalsJson"
                                          @deletePrincipal="deletePrincipal"
                                          @onPrincipalSaveClick="onPrincipalSaveClick"
                                          :visiblePanel="visiblePanel"
                                          @onSaveClick="onSaveClick"></business-section>
                    </div>
                    <div class="form-section form-section-tax" v-if="!isLoading && visibleSection == 'tax'">
                        <tax :taxJson="taxJson" :applicationInfoJson="applicationJson" :readOnly="readOnly" :visiblePanel="visiblePanel" :principalsJson="principalsJson" @onTaxSaveClick="onTaxSaveClick"></tax>
                    </div>
                    <div class="form-section form-section-eft" v-if="!isLoading && visibleSection == 'eft'">
                        <electronic-funds :readOnly="readOnly" :eftJson="eftJson" @onEftSaveClick="onEftSaveClick" @sectionClick="sectionClicked"></electronic-funds>
                    </div>
                    <!-- div class="form-section form-section-tax" v-if="!isLoading && visibleSection == 'tax'">
            </div -->
                    <div class="form-section form-section-background" v-if="!isLoading && visibleSection == 'background' && visiblePanel == 'background'">
                        <background-check :readOnly="readOnly" @onBackgroundSaveClick="onBackgroundSaveClick" :app="applicationJson" :userRole="userRole" :bgToken="token"></background-check>
                    </div>
                    <div class="form-section form-section-background" v-if="!isLoading && visibleSection == 'background' && visiblePanel =='authorize'">
                        <principal-authorize :readOnly="readOnly" :bgToken="token" :principalJson="principalAuthJson"
                                             @onPrincipalSaveClick="onPrincipalSaveClick"></principal-authorize>
                    </div>
                    <div class="form-section form-section-review" v-if="!isLoading && visibleSection == 'review'">
                        <review :userId="userId" :applicationJson="applicationJson" :reviewToken="token"></review>
                    </div>
                    <div v-if="application.applicationStatus != 1 && !isLoading && visibleSection == 'equipment'">
                        <LotteryEquipment :readOnly="readOnly" :equipmentJson="equipmentJson"></LotteryEquipment>
                    </div>

                </div>
                <div v-else class="page_content cf">
                    <!-- staff review -->
                    <review-staff :userId="userId"
                                  :staffToken="token"
                                  :userRole="userRole"
                                  :applicationJson="applicationJson"
                                  :taxJson="taxJson"
                                  :eftJson="eftJson"
                                  :visibleSection="visibleSection"
                                  :readOnly="readOnly"
                                  @showPanel="showFormSections"
                                  @onNextAdminClick="onNextAdminClick"></review-staff>
                </div>
            </div>
            <div v-else-if="!isUserLogged">
                <SignInRegister v-if="!isUserLogged"></SignInRegister>
            </div>
            <div v-else class="moduleContent cf">
                <h3>We're Sorry.</h3>
                <p>This application could not be found. Please return back to the <a href="/eapplications">eApplication System welcome page</a> and try again.</p>

                <!-- <button type="button" class="button" @click="loadTest()">Load Test</button> -->
            </div>
        </div>
    </section>
</template>

<script>
    import { HollowDotsSpinner } from 'epic-spinners';
    import BusinessSection from './business/business-section';
    import BusinessLanding from './business/sections/business-landing';
    import BackgroundCheck from './background/background-check';
    import PrincipalAuthorize from './background/principal-authorization';
    import ElectronicFunds from './eft/electronic-funds';
    import Tax from './tax/tax';
    import Review from './review/review';
    import ReviewStaff from './review/review-staff';
    import LotteryEquipment from './review/lottery-equipment';
    import LeftMenu from './left-menu';
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    import Tracker from './dashboard/app-status-tracker.vue';
    export default {
        components: { 
            LeftMenu,
            BusinessSection,
            BusinessLanding,
            BackgroundCheck,
            PrincipalAuthorize,
            ElectronicFunds,
            Tax,
            Review, 
            ReviewStaff,
            LotteryEquipment,
            SignInRegister,
            Tracker,
            HollowDotsSpinner
        },
        props: {
            principalListContent: String
        },
        data() {
            return {
                userId: 0,
                token: '',
                eappId: '',
                application: {},
                userEappRole: '',
                isLoading: true,
                visiblePanel: '',
                visibleSection: '',
                businessJson: '',
                applicationJson: '',
                contactJson: '',
                principalsJson: '',
                principalId: 0,
                principalAuthJson: '',
                eftJson: '',
                taxJson: '',
                step: '',
                section: '',
                readOnly: false,
                errorMessageShow: '',
                additionalErrorInfo: '',
                validAdminRoles: ['eApp-CentralLicensingManager','eApp-CentralLicensingStaff','eApp-CentralSalesManager','eAppDistrictRep','eApp-EFTReviewer','eApp-RegionalManager','eApp-RegionalSecurity','eApp-Security','eApp-ChainSalesRep'],
                userIsAdmin: false,
                userRole: '',
                applicationFound: true,
                showApplicationDashboard: false,
                applicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eAppDistrictRep', 'eApp-EFTReviewer', 'eApp-RegionalManager', 'eApp-Security'],
                showChainApplicationDashboard: false,
                chainApplicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eApp-EFTReviewer', 'eApp-Security', 'eApp-ChainSalesRep', 'eApp-RegionalManager'],
                businessLegalName: '',
                chainBusinessLegalName: '',
                isUserLogged: false,
                existingChain: false,
                acodeChain: false,
                masterChain: false,
                chain: false,
                showPrincipals: true,
                showSuccessMessage: false
            }
        },
        mounted: function () {
            this.isLoading = true;
            if(this.isUserAuthed()) {
                this.getToken();
                this.showButtons();
                this.isUserLogged = true;
            } else {
                this.applicationFound = false;
                this.isLoading = false;
            }
        },
        computed:
        {
            GET_APP_API_URL() {
                let url = '';
                switch (this.userEappRole.codename) {
                    case "eApp-CentralSalesManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/review/GetApplicationForCentralSalesById/user/` + this.userId + `/eapplication/` + this.eappId;
                        break;
                    case "eAppDistrictRep":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/review/GetApplicationForSalesRepById/user/` + this.userId + `/eapplication/` + this.eappId;
                        break;
                    case "eApp-RegionalManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/review/GetApplicationForRegionalManagerById/user/` + this.userId + `/eapplication/` + this.eappId;
                        break;
                    case "eApp-CentralLicensingManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/review/GetApplicationForLicensingById/user/` + this.userId + `/eapplication/` + this.eappId;
                        break;
                    case "eApp-EFTReviewer":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/review/GetApplicationForEFTById/user/` + this.userId + `/eapplication/` + this.eappId;
                        break;
                    case "eApp-Security":
                    case "eApp-RegionalSecurity":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/review/GetApplicationForSecurityById/user/` + this.userId + `/eapplication/` + this.eappId;
                        break;
                    default:
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/user/` + this.userId + `/eapplication/` + this.eappId;
                        break;
                }
                return url;
            },
            SAVE_APP_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/SaveApplication/` + this.userId;
            },
            DELETE_PRINCIPAL_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/DeletePrincipal/`;
            },
            CREATE_PRINCIPAL_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/ManagePrincipal`;
            }
        },
        methods: {
            getToken() {
                this.isLoading = true;
                let uId = this.getLoggedInUserId();
                if (uId) {
                    this.userId = uId;
                    // this.userId = 133;
                    // this.userId = 130;
                    // this.userId = 136;
                    // this.userId = 129;
                    this.getAPItoken()
                        .then((token) => {
                            this.userEappRole = this.getEappRole();
                            this.token = token;
                            this.tryGetFromQueryString().then(() => {
                                this.fetchData();
                            });
                        });
                }
            },
            async fetchData() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                };
                let response = await (await fetch(this.GET_APP_API_URL, appsRequestOptions)).json();

                if (response != null && response.statusCode == 200) {
                    this.application = response.data;
                    this.readOnly = this.application.applicationStatus > 1 && this.application.applicationStatus != 8;
                    this.application.applicationStatus === 10 ? this.readOnly = true : false;
                    // need to handle errors when json is not returned.
                    if (this.application != '' && this.application != null) {
                        // if staff user...
                        this.validAdminRoles.forEach(element => {
                            if (this.isUserInRole(element)) {
                                this.userRole = element;
                                this.userIsAdmin = true;
                            }
                        });
                        this.serializeData();
                        this.showFormSections(this.section);
                        this.showPanelFromStepQueryString();

                        this.interactByRole();

                        this.isLoading = false;
                    } else {
                        // need to wait for fetch to complete
                        // this.applicationFound = false;
                        // this.isLoading = false;
                    }
                }
                else {
                    // console.log('error!');
                    this.errorMessageShow = true;
                    this.applicationFound = false;
                    this.isLoading = false;
                }
            },
            interactByRole() {
                let status = this.applicationStatus;
                
                if (this.userRole == 'eApp-CentralSalesManager') {
                    if (status == 6) {
                        // this.readOnly = false;
                    } else {
                        // this.readOnly = true;
                    }
                    this.isCSM = true;
                    this.showApprove = true;
                    if (this.application.applicationIspartOfChain || this.application.applicationMasterChain) {
                        // if app is Part of Corp Chain OR a Master Chain 
                        this.chainRejectCSM = false;
                    } else {
                        this.chainRejectCSM = true;
                    }
                }
                if (this.userRole == 'eApp-RegionalManager') {
                    if (status == 3 || status == 7) {
                        // this.readOnly = false;
                    } else {
                        // this.readOnly = true;
                    }
                    this.isRM = true;
                    // this.showReview = true;
                }
                if (this.userRole == 'eApp-ChainSalesRep') {
                    if (status == 2) {
                        // this.readOnly = false;
                    } else {
                        // this.readOnly = true;
                    }
                    this.successRedirect = '/eapplications/dashboard/chain';
                    // this.showReview = true;
                }
                if (this.userRole == 'eApp-EFTReviewer' || this.userRole == 'eApp-RegionalSecurity' || this.userRole == 'eApp-Security' || this.userRole == 'eAppDistrictRep') {
                    // this.showReview = true;
                }
                // Foundation.reInit('reveal');
                if (this.userRole == 'eApp-EFTReviewer') {
                    if (status == 3 || status == 4 || status == 7) {
                        // this.readOnly = false;
                    } else {
                        // this.readOnly = true;
                    }
                }
                if (this.userRole == 'eApp-RegionalSecurity') {
                    if (status == 3 || status == 4 || status == 7) {
                        // this.readOnly = false;
                    } else {
                        // this.readOnly = true;
                    }
                }
                if (this.userRole == 'eApp-Security') {
                    if (status == 3 || status == 4 || status == 7) {
                        // this.readOnly = false;
                    } else {
                        // this.readOnly = true;
                    }
                }
                if (this.userRole == 'eAppDistrictRep') {
                    if (status == 2) {
                        // this.readOnly = false;
                    } else {
                        // this.readOnly = true;
                    }
                }
                if (this.userRole == 'eAppChainManager') {
                    if (status == 3 || status == 7 || status == 12) {
                        // this.readOnly = false;
                    } else {
                        // this.readOnly = true;
                    }
                }

                if (this.userRole === '' || this.userRole === null || this.userRole === undefined) {
                    this.readOnly = this.application.applicationStatus > 1 && this.application.applicationStatus != 8;
                } else {
                    this.readOnly = false;
                }
            },
            serializeData() {
                this.applicationJson = JSON.stringify(this.application);
                this.businessJson = JSON.stringify(this.application.businessInformation);
                this.contactJson = JSON.stringify(this.application.contactInformation);
                this.principalsJson = JSON.stringify(this.application.principals);
                this.getPrincipal();
                this.eftJson = JSON.stringify(this.application.eft);
                this.taxJson = JSON.stringify(this.application.tax);
                this.equipmentJson = JSON.stringify(this.application.equipment);
                
                
                
                
                // console.log(this.application);
                // console.log('primaryContactPhone: '+this.application.contactInformation.primaryContactPhone);
                // console.log('secondaryContactPhone: '+this.application.contactInformation.secondaryContactPhone);
            },
            tryGetFromQueryString() {

                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('id')) {
                    this.eappId = urlParams.get('id');
                }
                if (urlParams.has('step')) {
                    this.step = urlParams.get('step');
                }

                if (urlParams.has('section')) {
                    this.section = urlParams.get('section');
                }

                if (urlParams.has('p')) {
                    this.principalId = urlParams.get('p');
                }

                //ss = show success message
                if (urlParams.has('ss')) {
                    let ss = urlParams.get('ss');
                    if (ss == '1') {
                        this.showSuccessMessage = true;
                    }
                    //after page loads and we show success message, reset the bit so we have to manually set it again when we save changes
                    //this avoids false positives
                    urlParams.set('ss', '0');
                }
                return Promise.resolve();
            },
            showPanelFromStepQueryString() {
                switch (this.step) {
                    case "business-1":
                        this.visiblePanel = 'business-name';
                        this.visibleSection = 'business';
                        break;
                    case "business-2":
                        this.visiblePanel = 'business-address';
                        this.visibleSection = 'business';
                        break;
                    case "sob":
                        this.visiblePanel = 'business-sale';
                        this.visibleSection = 'business';
                        break;
                    case "business-3":
                        this.visiblePanel = 'business-hours';
                        this.visibleSection = 'business';
                        break;
                    case "principals":
                        this.visiblePanel = "principals";
                        this.visibleSection = 'business';
                        break;
                    case "contact":
                        this.visiblePanel = "contact";
                        this.visibleSection = 'business';
                        break;
                    case "background":
                        this.visiblePanel = 'background';
                        this.visibleSection = 'background';
                        break;
                    case "authorize":
                        this.visiblePanel = 'authorize';
                        this.visibleSection = 'background';
                        break;
                    case "eft":
                        this.visiblePanel = 'eft';
                        this.visibleSection = 'eft';
                        break;
                    case "eft-edit":
                        this.visiblePanel = 'eft-edit';
                        this.visibleSection = 'eft';
                        break;
                    case "tax":
                        this.visiblePanel = 'tax';
                        this.visibleSection = 'tax';
                        break;
                    case "tax-edit-1":
                        this.visiblePanel = 'tax-edit-1';
                        this.visibleSection = 'tax';
                        break;
                    case "tax-edit-2":
                        this.visiblePanel = 'tax-edit-2';
                        this.visibleSection = 'tax';
                        break;
                    case "review":
                        this.visiblePanel = 'review';
                        this.visibleSection = 'review';
                        break;
                    case "liability":
                        this.visiblePanel = 'liability';
                        this.visibleSection = 'liability';
                        break;
                    case "equipment":
                        this.visiblePanel = 'equipment';
                        this.visibleSection = 'equipment';
                        break;
                    case "licensing":
                        this.visiblePanel = 'licensing';
                        this.visibleSection = 'licensing';
                        break;
                    case "chains":
                        this.visiblePanel = 'chains';
                        this.visibleSection = 'chains';
                        break;
                    default:
                        this.visiblePanel = 'landing';
                        this.visibleSection = 'business';
                        break;
                }
            },
            showFormSections(section) {
                switch (section) {
                    case 'tax':
                        this.visibleSection = 'tax';
                        this.visiblePanel = 'tax';
                        break;
                    case 'background':
                        this.visibleSection = 'background';
                        this.visiblePanel = 'background';
                        break;
                    case 'eft':
                        this.visibleSection = 'eft';
                        this.visiblePanel = 'eft';
                        break;
                    case 'review':
                        this.visibleSection = 'review';
                        this.visiblePanel = 'review';
                        break;
                    case 'business':
                    default:
                        this.visibleSection = 'business';
                        this.visiblePanel = 'landing';
                        break;
                }
            },
            sectionClicked(key, showSS) {
                let currentPage = window.location.origin + window.location.pathname;
                let urlParams = new URLSearchParams();
                urlParams.set('id', this.eappId);
                urlParams.set('step', key);

                if (showSS) {
                    urlParams.set('ss', '1');
                }
                else {
                    urlParams.set('ss', '0');
                }

                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            getPrincipal() {
                if (this.principalId > 0) {
                    let principal = this.application.principals.find(x => x.principalInformationID == this.principalId);
                    this.principalAuthJson = JSON.stringify(principal);
                }
                
            },
            showButtons() {
                this.applicationDashboardRoles.forEach(element => {
                    if (this.isUserInRole(element)) {
                        this.showApplicationDashboard = true;
                    }
                });

                this.chainApplicationDashboardRoles.forEach(element => {
                    if (this.isUserInRole(element)) {
                        this.showChainApplicationDashboard = true;
                    }
                });
            },
            async onSaveClick(saveRequest) {
                
                if (saveRequest.principalOwnershipCheck) {
                    this.application.principalOwnershipCheck = saveRequest.principalOwnershipCheck;
                    this.application.principalPercentOwnershipMessage = saveRequest.principalPercentOwnershipMessage;
                }
                this.application.businessInformation = saveRequest.businessInformation;

                // console.log(saveRequest)

                if (saveRequest.businessInformation.saleOfBusiness !== null) {
                    this.application.applicationIsASoB = saveRequest.businessInformation.saleOfBusiness;
                }
                
                this.application.contactInformation = saveRequest.contactInformation;
                this.application.principals = saveRequest.principals;

                // console.log(this.application)
                // Only save if Application has not been submitted, or has been rejected back to applicant and user === createdBy on application data
                if (this.application.applicationStatus === 1|| this.application.applicationStatus === 8 && this.application.createdBy === this.userId) {
                    // Save
                    let response = await this.postSaveData()
                        .then(
                            (response) => {
                                if (response != null && response.statusCode == 200) {
                                    this.navigate(saveRequest.dir);
                                }
                                else {
                                    this.isLoading = false;
                                    this.errorMessageShow = true;
                                }
                                
                            }
                        );
                } else {
                    this.navigate(saveRequest.dir);
                }
            },
            async onNextAdminClick(appRequest) {
                this.application = appRequest.application;
                if (this.userRole === 'eApp-CentralLicensingManager') {
                    this.application.principals = [];
                }
                if (appRequest.updates === true) {
                    // console.log(appRequest)
                    // console.log(this.application)
                    let response = await this.postSaveData()
                    .then(
                            (response) => {
                                if (response != null && response.statusCode == 200) {
                                    this.sectionClicked(this.visibleSection, true);
                                }
                                else {
                                    this.isLoading = false;
                                    this.errorMessageShow = true;
                                }
                                
                            }
                        );
                }
            },
            navigate(dir) {
                this.existingChain = this.application.applicationIspartOfChain;
                this.acodeChain = this.application.applicationChainIsFranchise;
                this.masterChain = this.application.applicationIsMasterChain;
                this.chain = this.application.applicationIspartOfChain || this.application.applicationIsMasterChain;

                if (this.masterChain && this.acodeChain === true) {
                    this.showPrincipals = true;
                }
                else if (!this.masterChain && this.acodeChain === true) {
                    this.showPrincipals = false;
                }
                else if (!this.chain) {
                    this.showPrincipals = true;
                }

                if (!this.existingChain && this.acodeChain === true) {
                    // console.log(this.visiblePanel);
                    // console.log('ACode');
                    switch (dir) {
                        case "next":
                            switch (this.visiblePanel) {
                                case "business-landing":
                                    this.updateUrl('eft');
                                    break;
                                case "business-name":
                                    this.updateUrl("business-2");
                                    break;
                                case "business-address":
                                    this.updateUrl("business-3");
                                    break
                                case "business-sale":
                                    this.updateUrl("business-3");
                                    break;
                                case "business-hours":
                                    // if (this.showPrincipals) {
                                        this.updateUrl("principals");
                                    // }
                                    // else {
                                        // this.updateUrl("contact");
                                    // }
                                    break;
                                case "principals":
                                    this.updateUrl("contact");
                                    break;
                                case "contact":
                                    // this.sectionClicked('eft');
                                    this.sectionClicked('background', false);
                                    break;
                                case "eft":
                                case "eft-edit":
                                    this.sectionClicked('tax', false);
                                    break;
                                case "tax":
                                case "tax-edit-1":
                                    this.updateUrl("tax-edit-2");
                                    break;
                                case "tax-edit-2":
                                    this.sectionClicked('background', false);
                                    break;
                                case "background":
                                    this.sectionClicked('review', false);
                                    break;
                                default:
                                    this.updateUrl('business-landing');
                                    break;
                            }
                            break;
                        case "back":
                            switch (this.visiblePanel) {
                                case "business-name":
                                    break;
                                case "business-address":
                                    this.updateUrl("business-1");
                                    break;
                                case "business-sale":
                                    this.updateUrl("business-2");
                                    break;
                                case "business-hours":
                                    this.updateUrl("business-2");
                                    break;
                                case "principals":
                                    this.updateUrl("business-3");
                                    break;
                                case "contact":
                                    if (this.showPrincipals) {
                                        this.updateUrl("principals");
                                    }
                                    else {
                                        this.updateUrl("business-3");
                                    }
                                    break;
                                case "eft":
                                case "eft-edit":
                                    this.sectionClicked('contact', false);
                                    break;
                                case "tax":
                                case "tax-edit-1":
                                    this.sectionClicked('eft', false);
                                    break;
                                case "tax-edit-2":
                                    this.updateUrl("tax-edit-1");
                                case "background":
                                    this.sectionClicked('contact', false);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
                else if (this.existingChain && this.acodeChain === true) {
                    // console.log(this.visiblePanel);
                    // console.log('ACode Location');
                    switch (dir) {
                        case "next":
                            switch (this.visiblePanel) {
                                case "business-landing":
                                    this.updateUrl('eft');
                                    break;
                                case "business-name":
                                    this.updateUrl("business-2");
                                    break;
                                case "business-address":
                                    this.updateUrl("business-3");
                                    break
                                case "business-sale":
                                    this.updateUrl("business-3");
                                    break;
                                case "business-hours":
                                    // if (this.showPrincipals) {
                                    //     this.updateUrl("principals");
                                    // }
                                    // else {
                                        this.updateUrl("contact");
                                    // }
                                    break;
                                case "principals":
                                    this.updateUrl("contact");
                                    break;
                                case "contact":
                                    this.sectionClicked('eft', false);
                                    // this.sectionClicked('background');
                                    break;
                                case "eft":
                                case "eft-edit":
                                    this.sectionClicked('tax', false);
                                    break;
                                case "tax":
                                case "tax-edit-1":
                                    this.updateUrl("tax-edit-2");
                                    break;
                                case "tax-edit-2":
                                    this.sectionClicked('background', false);
                                    break;
                                case "background":
                                    this.sectionClicked('review', false);
                                    break;
                                default:
                                    this.updateUrl('business-landing');
                                    break;
                            }
                            break;
                        case "back":
                            switch (this.visiblePanel) {
                                case "business-name":
                                    break;
                                case "business-address":
                                    this.updateUrl("business-1");
                                    break;
                                case "business-sale":
                                    this.updateUrl("business-2");
                                    break;
                                case "business-hours":
                                    this.updateUrl("business-2");
                                    break;
                                case "principals":
                                    this.updateUrl("business-3");
                                    break;
                                case "contact":
                                    if (this.showPrincipals) {
                                        this.updateUrl("principals");
                                    }
                                    else {
                                        this.updateUrl("business-3");
                                    }
                                    break;
                                case "eft":
                                case "eft-edit":
                                    this.sectionClicked('contact', false);
                                    break;
                                case "tax":
                                case "tax-edit-1":
                                    this.sectionClicked('eft', false);
                                    break;
                                case "tax-edit-2":
                                    this.updateUrl("tax-edit-1");
                                case "background":
                                    this.sectionClicked('contact', false);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
                else {
                    // console.log('Not ACode')
                    switch (dir) {
                        case "next":
                            switch (this.visiblePanel) {
                                case "business-landing":
                                    this.updateUrl('background');
                                    break;
                                case "business-name":
                                    this.updateUrl("business-2");
                                    break;
                                case "business-address":
                                    this.updateUrl("business-3");
                                    break
                                case "business-sale":
                                    this.updateUrl("business-3");
                                    break;
                                case "business-hours":
                                    if(this.showPrincipals) {
                                        this.updateUrl("principals");
                                    }
                                    else {
                                        this.updateUrl("contact");
                                    }
                                    break;
                                case "principals":
                                    this.updateUrl("contact");
                                    break;
                                case "contact":
                                    this.updateUrl('eft');
                                    break;
                                case "eft":
                                case "eft-edit":
                                    this.sectionClicked('tax', false);
                                    break;
                                case "tax":
                                case "tax-edit-1":
                                    this.updateUrl("tax-edit-2");
                                    break;
                                case "tax-edit-2":
                                    this.sectionClicked('background', false);
                                    break;
                                case "background":
                                    this.updateUrl('review');
                                    break;
                                default:
                                    this.updateUrl('business-landing');
                                    break;
                            }
                            break;
                        case "back":
                            switch (this.visiblePanel) {
                                case "business-name":
                                    this.updateUrl("business-landing");
                                    break;
                                case "business-address":
                                    this.updateUrl("business-1");
                                    break;
                                case "business-sale":
                                    this.updateUrl("business-2");
                                    break;
                                case "business-hours":
                                    this.updateUrl("business-2");
                                    break;
                                case "principals":
                                    this.updateUrl("business-3");
                                    break;
                                case "contact":
                                    if (this.showPrincipals) {
                                        this.updateUrl("principals");
                                    }
                                    else {
                                        this.updateUrl("business-3");
                                    }
                                    break;
                                case "eft":
                                case "eft-edit":
                                    this.sectionClicked('contact', false);
                                    break;
                                case "tax":
                                case "tax-edit-1":
                                    this.sectionClicked('eft', false);
                                    break;
                                case "tax-edit-2":
                                    this.updateUrl("tax-edit-1");
                                    break;
                                case "background":
                                    this.updateUrl('contact');
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
                
            },
            updateUrl(step) {
                // alert('check console');
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                urlParams.set('step', step);
                currentPage += '?' + urlParams.toString();

                //changing this to a page reload, so each section has the most up to date object
                //vue is making it weird to pass the application around and sometimes overwriting itself 
                window.location.href= currentPage;
            },
            onPrincipalSaveClick(principal, dir) {
                    this.createPrincipal(principal)
                        .then(
                            (response) => {
                                if (response != null && response.statusCode == 200) {
                                    this.isLoading = false;
                                    let queryString = window.location.search;
                                    let urlParams = new URLSearchParams(queryString);

                                    if (urlParams.has('n')) {
                                        let stepNumber = urlParams.get('n');
                                        this.navigateToNextPrincipalStep(stepNumber, dir);
                                    }
                                    else {
                                        // redirect to listing page
                                        if (urlParams.has('p')) {
                                            urlParams.delete('p');
                                        }

                                        let currentPage = window.location.origin + window.location.pathname;
                                        currentPage += '?' + urlParams.toString();
                                        window.location.href = currentPage;
                                    }
                                }
                                else {
                                    this.isLoading = false;
                                    this.errorMessageShow = true;
                                    this.additionalErrorInfo = response.error.message;
                                }

                            }
                        );
                
            },
            navigateToNextPrincipalStep(num, dir) {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (dir == 'next') {
                    num++;
                } else {
                    num--;
                }
                
                if (num > 3) {
                    urlParams.delete('n');
                    if (urlParams.has('step')) {
                        urlParams.set('step', 'background');
                    } else {
                        urlParams.append('step', 'background');
                    }
                }
                else {
                    if (urlParams.has('n')) {
                        urlParams.set('n', num);
                    } else {
                        urlParams.append('n', num);
                    }
                }
                // alert('check console');
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            onEftSaveClick(request) {
                this.application.eft = request.eft;
                let response = this.postSaveData()
                    .then(
                        (response) => {
                            if (response != null && response.statusCode == 200) {
                                this.navigate(request.dir);
                            }
                            else {
                                this.isLoading = false;
                                this.errorMessageShow = true;
                            }

                        }
                    );

                response;
            },
            onBackgroundSaveClick(request) {
                this.navigate(request.dir);
            }, 
            async onTaxSaveClick(request) {
                // console.log(request)
                this.application.tax = request.tax;
                let response = await this.postSaveData()
                    .then(
                        (response) => {
                            console.log(response)
                            if (response != null && response.statusCode == 200) {
                                // console.log(response.statusCode)
                                this.navigate(request.dir);
                            }
                            else {
                                // console.log('errorCode: '+response.error.errorCode);
                                if (response.error.errorCode == 141) {
                                    // this.additionalErrorInfo = response.error.message + " Please complete your Business Address under the Business Information area of the application before adding your Tax Information.";
                                    this.additionalErrorInfo = "Please complete your Business Address under the Business Information area of the application before adding your Tax Information.";
                                }
                                else {
                                    this.additionalErrorInfo = response.error.message;
                                }

                                this.isLoading = false;
                                this.errorMessageShow = true;
                            }

                        }
                    );

                response;
            },
            async postSaveData() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        this.application
                    )
                };

                // console.log(this.application)

                let response = await (await fetch(this.SAVE_APP_API_URL, appsRequestOptions)).json();
                return Promise.resolve(response);
            },
            async createPrincipal(principal) {
                let createBody = { applicationId: this.eappId, userId: this.userId, principal: principal };
                // console.log(createBody);
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        createBody
                    )
                };
                let response = await (await fetch(this.CREATE_PRINCIPAL_API_URL, appsRequestOptions)).json();
                return Promise.resolve(response);
            },
            async deletePrincipal(id) {
                let deleteBody = { applicationId: this.eappId, userId: this.userId, principalId: id };
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        deleteBody
                    )
                };

                let response = await (await fetch(this.DELETE_PRINCIPAL_API_URL, appsRequestOptions)).json();

                if (response != null && response.statusCode == 200) {
                    //reload page
                    window.location.href = window.location.href;
                }
                else {
                    this.isLoading = false;
                    this.errorMessageShow = true;
                }
            }
        }
}
</script>

<style lang="scss" scoped>
.success{
    background-color: #209120;
    color: #fff;
    font-weight: bold;
}
</style>