<template>
    <div class="gridWrapper">
        <table v-if="gridData && gridData.length" class="eAppListTbl">
            <thead>
                <tr>
                    <th v-for="(header, index) in headers" :key="index">
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in gridData" :key="index">
                    <td>
                        <a :href="'/eapplications/eapplication?id='+row['businessGuid']">{{row['businessName']}}</a>
                    </td>
                    <td class="eAppTrack_status" :data-appstatus="row['applicationStatus']">
                        <AppStatusTracker :appStatus="row['applicationStatus']"> </AppStatusTracker>
                    </td>
                </tr>
            </tbody>
        </table>
        <p v-else>You have no applications to display.</p>
    </div>
</template>
<script>
    import AppStatusTracker from './app-status-tracker.vue';
    export default {
        components: {
            AppStatusTracker
        },
        props: {
            gridData: Array,
            headers: Array,
        }
    }
</script>