<template>
    <div class="module dcf_lastDay">
        <div class="wrapper-list">

            <div class="list-res">
                <div class="grid-x">
                    <div class="columns small-5 medium-5 large-5 cell">
                        <span class="title">Game Name</span>
                    </div>
                    <div class="columns small-2 medium-2 large-2 cell txtCenter">
                        <span class="title">Game #</span>
                    </div>
                    <div class="columns small-2 medium-2 large-2 cell txtRight">
                        <span class="title">Cost</span>
                    </div>
                    <div class="columns small-3 medium-3 large-3 cell txtRight">
                        <span class="title">Last Day to Redeem</span>
                    </div>
                </div>
                <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#be0f34" v-if="isLoading" />
                <div class="grid-x" v-for="list in games" :key="list.gameID">
                    <div class="columns small-5 medium-5 large-5 cell">
                        <span>{{ list.gameName }}</span>
                    </div>
                    <div class="columns small-2 medium-2 large-2 cell txtCenter">
                        <span>{{ list.gameNumber }}</span>
                    </div>
                    <div class="columns small-2 medium-2 large-2 cell txtRight">
                        <span>${{ list.gamePrice }}</span>
                    </div>
                    <div class="columns small-3 medium-3 large-3 cell txtRight">
                        <span>{{ customFormatter(list.lastDayToRedeem) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from "moment";
import { HollowDotsSpinner } from 'epic-spinners'
export default {
    components: {
        HollowDotsSpinner
    },
    data: function () {
        return {
            pricegroup: [],
            games: [],
            isLoading: false
        }
    },
    mounted: function () {
        this.getToken()
    },
    methods: {
        customFormatter(date) {
            return moment(date).format("MM/DD/YYYY");
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    // this.checkScratchoffs({ token: token, game: this.gameValue })
                    //     .then(() => {
                    //         setTimeout(() => {
                    //             let games = this.getScratchoffs();
                    //             this.games = games();
                    //             this.showShows(games())
                    //         }, 1000);
                    //     })
                    this.isLoading = true;
                    this.fetchData(token);

                });
        },
        fetchData(token) {
            let apiToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/ScratchOffs/ScratchOffGame/GetAllGamesWithLastDayToRedeemSet", {
                headers: {
                    'Authorization': 'Bearer '+apiToken,
                },
            })
            .then(response => response.json())
            .then(json => {
                this.games = json.data;
                this.showShows(this.games);
            })
            .catch(error => {
                // console.error("There was an error!", error);
                return this.errorMessage = error;
            });
        },
        ...mapActions({
            checkScratchoffs: 'getNumbers/getOLCScratchGames'
        }),
        ...mapGetters({
            getScratchoffs: 'getNumbers/getScratchNumbers'
        }),
        showShows(localItems) {
            // let currentDate = new Date();
            let res = [];
            console.log(localItems);


            // localItems = [
            //   {
            //     "gameID": 326,
            //     "gameName": "AAA TEST",
            //     "gameNumber": "688",
            //     "gamePrice": 10,
            //     "closingDate": "2022-12-01T00:00:00",
            //     "lastDayToRedeem": "2022-12-31T00:00:00",
            //   }
            // ]

            // localItems.map((priceGroup) => {
            //     priceGroup.map((game) => {
            //         console.log(game.gameName);
            //         res.push(game);
            //     });
            // });
            Object.entries(localItems).forEach(
                // ([key, value]) => console.log(key, value)
                ([key, value]) => value.map((game) => { res.push(game); })
            );
            
            console.log(res);

            res.sort((a, b) => {
                return new Date(a.lastDayToRedeem) - new Date(b.lastDayToRedeem); // descending
            })

            this.games = res;
            this.isLoading = false;
        },
    },
    computed: {

    },
};
</script>
<style lang="scss" scoped>
</style>  