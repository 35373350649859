<template>
        <main class="productDetailWrap cf">
            <!-- <SignInRegister v-if="!isUserLogged"></SignInRegister> -->
            <!-- <article v-if="isUserLogged"> -->
            <article>
                <Coupon v-if="coupon == true" :id="id"></Coupon>
                <Catalog v-if="catalog === true" :id="id"></Catalog>
                <Drawing v-if="drawing === true" :id="id"></Drawing>
                <Digital v-if="digital === true" :id="id"></Digital>
            </article>
        </main>
</template>

<script>
    // import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    import Coupon from './ProductDetail_Coupon.vue';
    import Catalog from './ProductDetail_Catalog.vue';
    import Drawing from './ProductDetail_Drawings.vue';
    import Digital from './ProductDetail_Digital.vue';

    export default {
        components:{
            // SignInRegister,
            Coupon,
            Catalog,
            Drawing,
            Digital
        },
        data: function () {
            return {
                id: 0,
                isUserLogged: false,
                coupon: false,
                catalog: false,
                drawing: false,
                digital: false
            }
        },
        mounted: function () {        
            this.getToken();
            this.id = document.getElementById("id").value;
            this.isUserLogged = this.isUserAuthed();
            this.getProductType();
        },
        methods: {
            getToken() {
                this.getAPItoken().then((token) => {
                    this.token = token;
                });
            },
            getProductType() {
                let location = window.location.href.toString();

                if (location.includes('digital-catalog')) {
                    this.digital = true;
                } else if (location.includes('drawing')) {
                    this.drawing = true;
                } else if (location.includes('catalog')) {
                    this.catalog = true;
                } else if (location.includes('coupon')) {
                    this.coupon = true;
                }

                /**!SECTION
                 * Sort by Key
                 * - Catalog
                 * - Drawing
                 * - Digital
                 * Then change State based on key
                 */
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>