<template>
    <div>
        <h3>Winning Numbers by Year – Sortable</h3>
        <ul class="winning-annual-export-list">
            <li v-for="i in years" :key="i"><a @click="Download(i)">{{ i }} Winning Numbers</a></li>
        </ul>
    </div>
</template>

<script>
export default {
    components: {
    },
    data: function () {
        return {
            links: [],
            currentYear: new Date().getFullYear(),
            years: [],
            year: ''
        }
    },
    mounted: function () {
        this.getYears();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(token) {
            let apiToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGame/GetAnnualExport?year=" + this.year, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.setAttribute('download', this.year + '_Winning_Numbers.csv');
                    const href = URL.createObjectURL(blob);
                    link.href = href;
                    link.setAttribute('target', '_blank');
                    link.click();
                    URL.revokeObjectURL(href);
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        Download(year) {
            this.year = year;
            this.getToken();
        },
        getYears() {
            for (let year = this.currentYear; year >= 2011; year--) {
                this.years.push(year);
            }
        },
    }
};
</script>
<style scoped>
.winning-annual-export-list {
    list-style: none;
    margin: 0 0 1rem;
}
</style>