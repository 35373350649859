<template>
    <div class="links">
        <div v-if="menu != ''" v-html="menu"></div>
    </div>
</template>
<script>
    // import Vue from 'vue';
    export default {
        data: function () {
            return { 
                menu: '',
            }
        },
        mounted: function () {
            this.getMenu()
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            getMenu() {
                let menuData = JSON.parse(localStorage.getItem('mt_secondaryMenu'));

                if (menuData !== undefined && menuData !== "" && menuData !== null) {
                    this.showMenu(menuData);
                } else {
                    this.getToken();
                }
            },
            showMenu(data) {
                let parser = new DOMParser();
                let htmlDoc = parser.parseFromString(data, 'text/html');

                this.menu = htmlDoc.body.innerHTML;
            },
            fetchData(apiToken) {
                // console.log('Getting Secondary Menu');
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+'/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=secondary-menu', {
                    headers: {
                        'Authorization': 'Bearer '+apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent;

                    localStorage.setItem('mt_secondaryMenu', JSON.stringify(element));  
                    this.showMenu(element)
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>