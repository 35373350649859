<template>
    <div>
        <article class="" v-if="showLanding">
            <div class="grid-x grid-margin-x" id="content" data-mutate="2mri30-sticky" data-events="mutate">

                <div class="medium-8 large-9 cell">
                    <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9"
                        v-if="isLoading" />

                    <div v-if="showArticles">

                        <h1 class="recent-blog-title">{{ blogTitle }}</h1>
                        <p class="recent-blog-subheading">{{ blogSubHead }}</p>

                        <div class="blog-post" v-for="article in displayArticles" :key="article.id">

                            <h3><a @click="goDetail(article.articleID)">{{ article.title }}</a></h3>
                            <div class="blog-post-content"><span v-html="article.content"></span></div>

                            <p>Posted: {{ customFormatter(article.date) }}</p>

                        </div>
                    </div>

                    <!-- <p v-if="showErrorMsg">{{ errorMsg }}</p> -->


                    <BlogDetail :articleId="articleId" v-if="showDetail"></BlogDetail>
                </div>

                <div class="medium-4 large-3 cell">
                    <div class="is-anchored is-at-top blog-right">

                        <a @click="goBlogHome" class="blog-home-link">Blog Home</a>

                        <!-- <div class="blog-search">
                            <h3>Search</h3>
                            <input type="text" placeholder="Search">
                            <input type="submit" class="button" name="search" value="Go">
                        </div> -->

                        <div class="blog-recent-posts">
                            <h3>Recent Posts</h3>
                            <ul>
                                <li v-for="post in recentPosts" :key="post.id">
                                    <a @click="goRecentPosts(post.articleID, post.date)">{{ post.title }}</a>
                                </li>

                            </ul>
                        </div>

                        <div class="blog-archives">
                            <h3>Archives</h3>
                            <ul>
                                <li v-for="archive in archivesArticles" :key="archive.id">
                                    <a @click="goToArchive(archive)">{{ archive }}</a>
                                </li>
                                <li>
                                    <a class="load-more-btn" @click="yearsArchive += loadMoreStep"
                                        v-if="yearsArchive < archivePosts.length">Load more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </article>


        <!-- <a @click="backToLanding" v-if="showBackBtn">Back</a> -->
    </div>
</template>


<script>

import { HollowDotsSpinner } from 'epic-spinners'
import moment from 'moment';
import BlogDetail from './BlogDetail.vue';


export default {
    components: {
        BlogDetail,
        HollowDotsSpinner
    },
    data() {
        return {
            globalToken: '',
            showArticles: true,
            showDetail: false,
            showLanding: true,
            showBackBtn: false,
            showErrorMsg: false,
            haveArticles: true,
            subtractMonth: 0,
            posts: [],
            archivePosts: [],
            recentPostsArr: [],
            displayPosts: parseInt(document.getElementById('recent-posts-display').value),
            yearsArchive: document.getElementById('number-years-archive').value,
            loadMoreStep: document.getElementById('number-years-archive').value,
            // blogTitle: document.getElementById('recent-blog-title').value,
            blogTitle: 'OH! a Blog Home',
            blogSubHead: document.getElementById('recent-blog-subheading').value,
            articleId: '',
            errorMsg: '',
            isLoading: false,
            startOfMonth: '',
            endOfMonth: ''


        };
    },

    mounted() {
        window.addEventListener('popstate', this.handleBackNavigation);

        let url = window.location.href;
        let params = new URLSearchParams(document.location.search);
        if (url.includes('id') && url.includes('date')) {
            let urlArticleId = params.get("id");
            let urlArticleDate = params.get("date");
            let urlDate = moment(urlArticleDate).format('MMMM-YYYY-DD')
            this.goDetail(urlArticleId, urlDate)
        }
        else if (url.includes('date')) {
            let urlArticleDate = params.get("date");
            this.goToArchive(urlArticleDate)
        }
        else {
            this.getToken()
        }
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.handleBackNavigation);
    },
    methods: {
        handleBackNavigation(event) {
            event.preventDefault()
            this.backToLanding();
        },
        customFormatter(date) {
            return moment(date).format('LL');
        },
        goRecentPosts(articleId, articleDate) {
            this.showDetail = false;
            this.isLoading = true;
            this.showArticles = false;
            this.articleId = articleId;

            setTimeout(() => {
                this.isLoading = false;
                this.showDetail = true;
                this.showErrorMsg = false;
            }, 500);


            let urlDate = moment(articleDate).format('MMMM-YYYY-DD')

            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('date', urlDate);
            urlParams.set('id', this.articleId);
            let newRelativePathQuery = window.location.pathname.replace(/\/+$/, '') + '?' + urlParams.toString();
            history.pushState(null, '', newRelativePathQuery);
        },
        goDetail(articleId, articleDate) {
            this.showDetail = false;

            this.articleId = articleId;
            this.showArticles = false;
            this.showDetail = true;
            this.showBackBtn = true;
            this.showErrorMsg = false;

            let urlDate = moment(articleDate).format('MMMM-YYYY-DD')

            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('date', urlDate);
            urlParams.set('id', this.articleId);
            let newRelativePathQuery = window.location.pathname.replace(/\/+$/, '') + '?' + urlParams.toString();
            history.pushState(null, '', newRelativePathQuery);



            if (this.globalToken == "") {
                this.getAPItoken()
                    .then((token) => {
                        this.archivePosts = [];
                        this.recentPostsArr = [];

                        this.getRecentPosts(token);
                        this.getArchivePosts(token);
                    });
            }

        },
        goToArchive(archiveDate) {
            // moment(archiveDate) doesn't work on FIREFOX - INVALID DATE
            //let parseArchiveDate = moment(archiveDate).format('YYYY-MM-DD');

            const [month, year] = archiveDate.split(' ');
            const monthIndex = new Date(`${month} 1, ${year}`).getMonth(); // Convert month name to a numeric month (0-based index)
            const isoDate = `${year}-${String(monthIndex + 1).padStart(2, '0')}-01`; // Create the ISO date format
            let parseArchiveDate = isoDate;
            //console.log(isoDate);

            this.isLoading = true;
            this.showArticles = false;
            this.showDetail = false;

            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('date', parseArchiveDate);
            let newRelativePathQuery = window.location.pathname.replace(/\/+$/, '') + '?' + urlParams.toString();
            history.pushState(null, '', newRelativePathQuery);



            if (this.globalToken == "") {
                this.getAPItoken()
                    .then((token) => {
                        this.archivePosts = [];
                        this.recentPostsArr = [];

                        this.getPosts(token, parseArchiveDate);
                        this.getRecentPosts(token);
                        this.getArchivePosts(token);
                    });
            }
            else {
                this.getPosts(this.globalToken, parseArchiveDate);
            }

        },
        goBlogHome() {
            this.showArticles = false;
            this.showDetail = false;
            this.showBackBtn = false;
            this.showErrorMsg = false;
            this.subtractMonth = 0;
            this.isLoading = true;

            let cleanUrl = window.location.href.split('?')[0]
            let url = new URL(location.href);
            url.searchParams.delete('id');
            url.searchParams.delete('date');
            history.pushState(null, '', cleanUrl);

            if (this.globalToken == "") {
                this.getAPItoken()
                    .then((token) => {
                        this.archivePosts = [];
                        this.recentPostsArr = [];

                        this.getPosts(token, null);
                        this.getRecentPosts(token);
                        this.getArchivePosts(token);
                    });
            }
            else {
                this.getPosts(this.globalToken, null);
            }

        },
        loadMoreArchives() {
            // Load more placeholder. 
            // This is be based on displaying a fixed limit of 20 archive links first. 
            // If there are more than 20 in the archivePosts array, then this function should display the next 20, and repeat until there are no more links to show. At that point the Load More link should be hidden.


            //console.log( this.archivePosts)


        },
        backToLanding() {
            this.showArticles = true;
            this.showDetail = false;
            this.showBackBtn = false;

            let cleanUrl = window.location.href.split('?')[0]
            let url = new URL(location.href);
            url.searchParams.delete('id');
            url.searchParams.delete('date');
            history.pushState(null, '', cleanUrl);

            this.getToken()
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.getPosts(token);
                    this.getRecentPosts(token);
                    this.getArchivePosts(token);
                });

        },
        getPosts(token, archiveMonth) {
            this.topOfPage();

            let apiToken = token;
            this.startOfMonth = moment().startOf('month').format('YYYY-MM-DD'),
                this.endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

            this.globalToken = token;

            if (archiveMonth && typeof archiveMonth !== "undefined") {
                this.startOfMonth = archiveMonth;
                this.endOfMonth = moment(archiveMonth).endOf('month').format('YYYY-MM-DD');
            }

            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/Article/GetByDateRangeAndCategory?dateFrom=" + this.startOfMonth + "&dateTo=" + this.endOfMonth + "&category=BlogPost", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;

                    this.isLoading = false;

                    // console.log(json)
                    if (json.error) {
                        this.showArticles = false;
                        this.showErrorMsg = true
                        this.errorMsg = json.error.message

                        if (json.error.errorCode == 1) {
                            this.showErrorMsg = true;
                            this.errorMsg = json.error.message;
                            return
                        } else {


                            // Previous month when no articles returned
                            if (this.haveArticles) {
                                let previousMonthStart = moment(this.startOfMonth).subtract(this.subtractMonth, 'months').format('YYYY-MM-DD');
                                let previousMonthEnd = moment(previousMonthStart).endOf('month').format('YYYY-MM-DD');

                                this.getPreviousMonthPosts(this.globalToken, previousMonthStart, previousMonthEnd)
                            } else {
                                this.subtractMonth = this.subtractMonth + 1;
                                let previousMonthStart = moment(this.startOfMonth).subtract(this.subtractMonth, 'months').format('YYYY-MM-DD');
                                let previousMonthEnd = moment(previousMonthStart).endOf('month').format('YYYY-MM-DD');

                                this.getPreviousMonthPosts(this.globalToken, previousMonthStart, previousMonthEnd)
                            }




                        }


                    } else {


                        // res.sort(function(a, b) {
                        //     return a.date < b.date;
                        // })
                        res.sort(this.sortDates);
                        // console.log('getPosts sorted posts:');
                        // console.log(res);

                        this.showArticles = true;
                        this.showErrorMsg = false;

                    }

                    this.posts = res;

                })
                .catch(error => {
                    console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        getPreviousMonthPosts(token, previousMonthStart, previousMonthEnd) {

            let apiToken = token;

            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/Article/GetByDateRangeAndCategory?dateFrom=" + previousMonthStart + "&dateTo=" + previousMonthEnd + "&category=BlogPost", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;

                    this.isLoading = false;

                    if (json.error) {
                        this.showArticles = false;
                        this.showErrorMsg = true
                        this.errorMsg = json.error.message;
                        this.haveArticles = false;
                        this.getPosts(this.globalToken, null);

                    } else {

                        // res.reverse();
                        res.sort(this.sortDates);
                        this.showArticles = true;
                        this.showErrorMsg = false;
                        // console.log('getPreviousMonthPosts sorted posts:');
                        // console.log(res);
                    }


                    this.posts = res;


                })
                .catch(error => {
                    console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        getRecentPosts(token) {
            let dateTo = moment(new Date()).format('YYYY-MM-DD'),
                apiToken = token

            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/Article/GetByDateRangeAndCategory?dateTo=" + dateTo + "&category=BlogPost", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;

                    const sortedByMostRecent = res.sort((a, b) => new Date(b.date) - new Date(a.date));
                    this.recentPostsArr = sortedByMostRecent;
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        getArchivePosts(token) {
            // let dateTo = moment(new Date()).format('YYYY-MM-DD'),
            let dateTo = moment().endOf('month').format('YYYY-MM-DD'),
                apiToken = token

            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/Article/GetByDateRangeAndCategory?dateTo=" + dateTo + "&category=BlogPost", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;

                    var counts = {};

                    res.sort(this.sortDates);
                    // res.sort(function (a, b) {
                    //     return b.date.localeCompare(a.date);
                    // });

                    res.forEach(element => {
                        var mydate = (new Date(element.date)).toLocaleString("en-us", {
                            month: "long",
                            year: "numeric"
                        });
                        counts[mydate] = counts[mydate] ? ++counts[mydate] : 1;
                    });


                    for (var key in counts) {
                        this.archivePosts.push(key + ' (' + counts[key] + ')');
                    }

                })
                .catch(error => {
                    console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        topOfPage() {
            window.scrollTo(0, 0);
        }
    },
    computed: {
        displayArticles() {
            // console.log(this.displayPosts);
            // return this.posts.slice(0, this.displayPosts) Input hidden 'recent-posts-display' have the items number to show.
            return this.posts;
        },
        recentPosts() {
            return this.recentPostsArr.slice(0, 5)
        },
        archivesArticles() {
            return this.archivePosts.slice(0, this.yearsArchive)
        }
    }
}

</script>


<style scoped></style>