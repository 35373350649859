<template>
    <div class="eAppModule">
        <div class="moduleContent cf w9review saveForm reviewNotes appReview">
            <h3>Federal Tax Information</h3>

            <p class="w9Link">Download <a href="http://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">IRS W9 Instructions</a> (PDF)</p>

            <div class="formRow cf">
                <label id="W9Name_lb" for="W9Name_txtText">Legal Business Name:</label>
                <input v-model="application.tax.w9Name" name="W9Name_txtText" type="text" id="W9Name_txtText"
                    disabled="disabled">
                <div class="explanation-text"><strong>(This is the legal name of your business)</strong></div>
            </div>
            <div class="formRow cf">
                <label id="W9LegalName_lb" for="W9LegalName_txtText">Doing Business As:</label>
                <input v-model="application.tax.w9LegalName" name="W9LegalName_txtText" type="text" id="W9LegalName_txtText"
                    disabled="disabled">
            </div>
            <div class="formRow cf">
                <label id="W9StreetAddress_lb" for="W9StreetAddress_txtText">Street Address:</label>
                <input v-model="application.tax.w9StreetAddress" name="W9StreetAddress_txtText" type="text"
                    id="W9StreetAddress_txtText" disabled="disabled">
            </div>
            <div class="formRow zipFields cf">
                <label id="W9Zip_lb" for="W9Zip_txtText">Zip Code:</label>
                <input v-model="application.tax.w9Zip" name="W9Zip_txtText" type="text" id="W9Zip_txtText"
                    disabled="disabled">
                <input v-model="application.tax.w9ZipPlus4" name="W9ZipPlus4_txtText" type="text" id="W9ZipPlus4_txtText"
                    disabled="disabled">
            </div>
            <div class="formRow cf">
                <label id="W9City_lb" for="W9City_txtText">City:</label>
                <input v-model="application.tax.w9City" name="W9City_txtText" type="text" id="W9City_txtText"
                    disabled="disabled">
            </div>
            <div class="formRow dividerRow cf">
                <label id="W9State_lb" for="W9State_txtText">State:</label>
                <input v-model="application.tax.w9State" name="W9State_txtText" type="text" id="W9State_txtText"
                    disabled="disabled">
            </div>
            <div class="formRow cf">
                <label id="W9TaxClassification_lb" for="W9TaxClassification_txtText">Federal Tax Classification:</label>
                <input v-model="application.tax.w9TaxClassification" name="W9TaxClassification_txtText" type="text"
                    id="W9TaxClassification_txtText" disabled="disabled">
                <input v-model="application.tax.w9TaxClassificationOther"
                    v-if="application.tax.w9TaxClassification == 'Other'" name="W9TaxClassificationOther_txtText"
                    type="text" id="W9TaxClassificationOther_txtText" disabled="disabled">
            </div>
            <div class="formRow taxID cf">
                <label id="W9TaxID_lb" for="W9TaxID_txtText">Taxpayer Identification Number:</label>
                <input v-model="application.tax.w9TaxID" name="W9TaxID_txtText" type="text" id="W9TaxID_txtText"
                    disabled="disabled">
                <span class="taxIdType" :if="application.tax.w9TaxTypeID == 0">Employer ID</span>
                <span class="taxIdType" :if="application.tax.w9TaxTypeID == 1">Social Security Number</span>
            </div>
            <div class="formRow cf">
                <label id="W9ExemptPayeeCode_lb" for="W9ExemptPayeeCode_txtText">Exempt Payee Code:</label>
                <input v-model="application.tax.w9ExemptPayeeCode" name="W9ExemptPayeeCode_txtText" type="text"
                    id="W9ExemptPayeeCode_txtText" disabled="disabled">
            </div>
            <div class="formRow cf dividerRow">
                <label id="W9FATCAExemptionCode_lb" for="W9FATCAExemptionCode_txtText">Exemption from FATCA reporting
                    code:</label>
                <input v-model="application.tax.w9FATCAExemptionCode" name="W9FATCAExemptionCode_txtText" type="text"
                    id="W9FATCAExemptionCode_txtText" disabled="disabled">
            </div>
            <div class="formRow cf">
                <label id="W9LegalContactFirstName_lb" for="W9LegalContactFirstName_txtText">Legal Contact First
                    Name:</label>
                <input v-model="application.tax.w9LegalContactFirstName" name="W9LegalContactFirstName_txtText" type="text"
                    id="W9LegalContactFirstName_txtText" disabled="disabled">
            </div>
            <div class="formRow cf">
                <label id="W9LegalContactLastName_lb" for="W9LegalContactLastName_txtText">Legal Contact Last Name:</label>
                <input v-model="application.tax.w9LegalContactLastName" name="W9LegalContactLastName_txtText" type="text"
                    id="W9LegalContactLastName_txtText" disabled="disabled">
            </div>
            <div class="formRow cf">
                <label id="W9LegalContactStreetAddress_lb" for="W9LegalContactStreetAddress_txtText">Legal Contact Street
                    Address:</label>
                <input v-model="application.tax.w9LegalContactStreetAddress" name="W9LegalContactStreetAddress_txtText"
                    type="text" id="W9LegalContactStreetAddress_txtText" disabled="disabled">
            </div>
            <div class="formRow zipFields cf">
                <label id="W9LegalContactZip_lb" for="W9LegalContactZip_txtText">Legal Contact Zip:</label>
                <input v-model="application.tax.w9LegalContactZip" name="W9LegalContactZip_txtText" type="text"
                    id="W9LegalContactZip_txtText" disabled="disabled">
            </div>
            <div class="formRow cf">
                <label id="W9LegalContactCity_lb" for="W9LegalContactCity_txtText">Legal Contact City:</label>
                <input v-model="application.tax.w9LegalContactCity" name="W9LegalContactCity_txtText" type="text"
                    id="W9LegalContactCity_txtText" disabled="disabled">
            </div>
            <div class="formRow dividerRow cf">
                <label id="W9LegalContactState_lb" for="W9LegalContactState_txtText">Legal Contact State:</label>
                <input v-model="application.tax.w9LegalContactState" name="W9LegalContactState_txtText" type="text"
                    id="W9LegalContactState_txtText" disabled="disabled">
            </div>
            <div class="formRow dividerRow checkboxRow cf">
                <input v-model="application.tax.w9SignatureCertification"
                    id="W9AgreeFederalCertificationRequirements_checkbox" type="checkbox"
                    name="W9AgreeFederalCertificationRequirements_checkbox" disabled="disabled">
                <label id="W9AgreeFederalCertificationRequirements_lb"
                    for="W9AgreeFederalCertificationRequirements_checkbox">I have read and agree to the Federal
                    Terms.</label>
            </div>
            <div v-if="userRole != 'eApp-CentralLicensingManager' && userRole != 'eApp-RegionalManager'" class="moduleContent reviewNotes appReview cf">
                <div class="moduleContent cf secStateReview saveForm">
                    <h3>Secretary of State Review</h3>
                    <div class="formRow fileUpload cf">
                        <label>File: <a v-if="file === true" @click="downloadFile()">Click Here to View
                                {{ application.tax.AttachedFileName }}</a></label>
                        <!-- <input type="file" id="TaxAttachedFile" name="TaxAttachedFile" @input="event => handleFile(event)" /> -->
                        <VueFileAgent ref="vueFileAgent" :multiple="false" :deletable="true" :meta="true" :linkable="true"
                            :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.zip'" :theme="'list'"
                            :maxSize="'5MB'" :maxFiles="1" :helpText="'Choose file'" :errorText="{
                                type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png, zip.',
                                size: 'Files should not exceed 5mb in size'
                            }" @select="handleFile($event)" @beforedelete="onBeforeDelete($event)"
                            @delete="onDelete($event)">
                        </VueFileAgent>
                        <div class="explanation-text">Upload a copy of the Secretary of State Review</div>
                    </div>
                </div>
            </div>

            <div class="btn-actions" v-if="userRole != 'eApp-RegionalManager'">
                <button type="button" name="Save" @click="onNextAdminClick()" id="taxReviewSoSSave"
                    class="btn btn-primary btnNext" title="Save">Save</button>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    components: {},
    props: {
        applicationJson: String,
        userRole: String,
    },
    data: function () {
        return {
            application: {},
            file: false
        }
    },
    mounted: function () {
        this.init();
    },
    methods: {
        init() {
            this.deserializeJson();
        },
        deserializeJson() {
            this.application = JSON.parse(this.applicationJson);
            
            let file = this.application.tax.AttachedFile;
            if (file === null || file === undefined || file === '' ) {
                this.file = false;
            } else {
                this.file = true;
            }
        },
        onNextAdminClick() {
            //how to upload file?
            this.$emit('onNextAdminClick', { application: this.application, step: 'tax', updates: true });
        },
        downloadFile() {
            var binaryString = window.atob(this.application.tax.attachedFile);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            var blob = new Blob([bytes], { type: this.application.tax.attachedFileExtension });
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        },
        // handleFile(event) {
        //     // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
        //     var file = event.target.files[0];

        //     var reader = new FileReader();
        //     var fileData;
        //     var fileType = '';
        //     var fileName = '';
        //     reader.readAsDataURL(file, 'base64');
        //     reader.onload = function (e) {
        //         var fileDataArray = reader.result.split(',')[1];
        //         fileData = fileDataArray
        //         fileType = file.type;
        //         fileName = file.name;
        //     }

        //     reader.onloadend = () => {
        //         this.application.tax.attachedFile = fileData;
        //         this.application.tax.attachedFileExtension = fileType;
        //         this.application.tax.attachedFileName = fileName;
        //     }
        // },
        handleFile(event) {
            // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
            var _this = this;
            var file = event[0].file;
            var fileName = event[0].file.name;
            var fileType = event[0].file.type

            const reader = new FileReader();
            reader.onload = function (e) {
                _this.application.tax.attachedFile = e.target.result.split(',')[1];
                _this.application.tax.attachedFileExtension = fileType;
                _this.application.tax.attachedFileName = fileName;
            };

            reader.readAsDataURL(file);
        },
        onBeforeDelete(event) {
            // optionally, you can confirm and call the deleteFileRecord method
            this.$refs.vueFileAgent.deleteFileRecord(event);
        },
        onDelete(event) {
            this.application.tax.attachedFile = null;
            this.application.tax.attachedFileExtension = null;
            this.application.tax.attachedFileName = null;
        }
    },
    computed: {}
};
</script>

<style lang="scss" scoped></style>
