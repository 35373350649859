import Vue from "vue";

import ClassicWN from "./Classic-WinningNumbers.vue";

export function load(payload) {
  const selector = "classic-numbers";
  let store = payload;

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return; // go no further
  }

  // Create a new Vue app with the imported component
  new Vue({
    store,
    render: createElement =>
      createElement(ClassicWN, {
        props: {
          ...window[selector]
        }
      }),
  }).$mount(selector);
}
