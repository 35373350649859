<template>
    <ul class="child-menu">
        <li class="childMenuTitle">Also in this section:</li>
        <li class="childMenuItem" v-for="(menuItem, index) in childMenuItems" :key="index">
            <a class="childMenuLink" :href="menuItem.MenuItemRelativeUrl">{{menuItem.MenuItemText}}</a>
        </li>
    </ul>
</template>
<script>
    // import Vue from 'vue';
    import $ from 'jquery';
    let childMenuData = $('#menuDiv').data('json');
    if(childMenuData == 'undefined'){
        childMenuData = '';
    }
    export default {
        data: function () {
            return { 
                childMenuItems: [],
                childMenuJson: childMenuData
            }
        },
        mounted: function () {
            this.loadChildMenu();
        },
        methods: {
            loadChildMenu() {
                // the purpose of this component is to display
                // only the children of the current page as a list of links
                // get the current page URL
                let activePageURL = window.location.pathname.toLowerCase();
                // sort with deepest level first
                this.childMenuJson.sort(function (a, b){
                    return b.NodeLevel - a.NodeLevel;
                });
                // move children into their parents
                // when the Active node is reached then STOP
                this.childMenuJson.forEach((element) => {
                    if (element.NodeLevel > 1) {
                        // is this THE active item?
                        let thisSubURL = element.MenuItemRelativeUrl.toLowerCase();
                        if (activePageURL === thisSubURL) {
                            // this should only target the exact page that is active
                            element.IsActive = true;
                            this.childMenuItems = element.Children;
                        } else if (element.IsActive === undefined) {
                            element.IsActive = false;
                        }
                        let nodeParentID = element.NodeParentID;
                        // find in menu
                        let parentNodeEl = this.childMenuJson.find(x => x.NodeID === nodeParentID);
                        // put into Children[] of parent and remove child
                        if (parentNodeEl === undefined){
                            // This shouldn't happen, but we need to handle the exception
                            console.log('excepted nav item: ' + element);
                        } else {
                            // put it into the child list for the parent menu
                            // DON'T DO ANYTHING, somehow that just works.
                            // Otherwise it duplicates the left nav and children nav!
                            // parentNodeEl.Children.push(element);
                        }
                    }
                });
                // console.log(this.childMenuItems);
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>