<template>
    <div>
        <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-8 large-8">
                <FeaturedRetailerDetail :subtractDays="subtractDays"></FeaturedRetailerDetail>
            </div>
            <div class="cell small-12 medium-4 large-4">
                <FeaturedRetailerCalendar :subtractDays="subtractDays"></FeaturedRetailerCalendar>

                <div class="module red_header number_check_form">
                    <h3>Keno Testimonials</h3>
                    <div class="keno_testimonies_sidebar">
                            <img alt="Keno" src="/getattachment/f62ca30f-0feb-4e9a-a89f-678dfcb0f894/keno.png">
                            <p>Bring more customers into your store with Keno.</p>
                            <a class="button red_btn" href="/retailers/keno">VIEW</a>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import moment from 'moment';
import FeaturedRetailerCalendar from './FeaturedRetailerCalendar.vue';
import FeaturedRetailerDetail from './FeaturedRetailerDetail.vue';

export default {
    components: {
        FeaturedRetailerCalendar,
        FeaturedRetailerDetail
    },
    data: function () {
        return {
            subtractDays: 100
        }
    },
    mounted: function () {
    },
    methods: {


    },
    computed: {
    }
};
</script>

<style lang="scss" scoped></style>