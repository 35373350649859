 <template>
    <div class="module cf updateCreateForm eAppUpdateCurrentInfo">
        <a v-if="!isLoading && isUserLogged" href="/eApplications" class="button eAppDashBtn"
            style="margin:1em 15px;">Create a New Application</a>

        <div v-if="!isLoading && isUserLogged" class="eAppWelcome">
            <p>
                <span v-html="pageContent.urDetailIntro"></span>
                <span v-if="renewalAllowed" v-html="pageContent.urDetailIntroRenew"></span>
            </p>
        </div>

        <SignInRegister v-if="!isLoading && !isUserLogged"></SignInRegister>

        <CurrentAppInfo v-if="!isLoading && isUserLogged" :userId="userId" :agentId="agentId" 
            @licenseReady="processLicense" :eAppToken="token"></CurrentAppInfo>

        <!-- actions -->
        <div v-if="!isLoading && isUserLogged" class="moduleContent Actions cf">
            <button v-if="renewalAllowed && !hasPendingRenewal && !hasPendingUpdates" class="button btn-secondary" id="btnRenew"
                data-open="pnlRenewalPopup">Renew</button>

            <a :href="'/eApplications/Updates/review/renewal?updateId=' + updateWrapperGuid+ '&an=' + agentId"
                v-if="hasPendingRenewal && !hasPendingUpdates" id="btnCurrentRenewal" class="button btn-primary">View Pending Renewal</a>

            <a :href="'/eApplications/Updates/License-Edit?an=' + agentId + '&updateId=' + updateWrapperGuid"
                v-if="hasPendingUpdates" id="btnCurrentUpdate" class="button">View Pending Change Request</a>

            <button v-if="updateAllowed && !hasPendingUpdates && !hasPendingRenewal" id="btnUdate" class="button"
                @click="submitUpdate()">Update</button>
        </div>

        <div v-if="createError" class="error">
            There was an error processing your request. Please refresh the page and try again.
        </div>

        <!-- <div>
            <p>You have pending updates so you cant perform a renewal and update. Please contact the lottery.</p>
            <input type="button" name="btnCancelSubmit" value="Cancel" class="btn btnCancel" />
        </div> -->

        <!-- Renew Only Modal -->
        <div v-if="isUserLogged" class="reveal large eAppModal modalWrapper" id="pnlRenewalPopup" data-reveal>
            <div class="grid-x grid-margin-x modalContent cf">
                <p>Is the information that was provided on the last screen correct? Select "Yes" if the information is
                    correct. Select "No" if you need to update any of the information.</p>

                <div class="cell small-12">
                    <label class="radio">
                        <input type="radio" name="performUpdates" value="0" @click="setRenewType(0)"
                            v-model="formModal.performUpdates" />
                        <span><strong>Yes, </strong> renew without changes.</span>
                    </label>
                </div>
                <div class="cell small-12">
                    <label class="radio">
                        <input type="radio" name="performUpdates" value="1" @click="setRenewType(1)"
                            v-model="formModal.performUpdates" />
                        <span><strong>No, </strong> I need to make updates.<br></span>
                    </label>
                </div>

                <div v-if="renewOnly" class="explainYes">
                    <div class="grid-x grid-margin-y princiaplAuthRadios crimeCheck cf">
                        <div class="cell small-3 medium-2 large-1">
                            <div class="small-12">
                                <label class="radio">
                                    <input type="radio" name="convictions" v-model="formModal.convictions"
                                        @click="setConviction(0)" value="false" /> No
                                </label>
                            </div>
                            <div class="small-12">
                                <label class="radio"><input type="radio" name="convictions" v-model="formModal.convictions"
                                        @click="setConviction(1)" value="true" /> Yes
                                </label>
                            </div>
                        </div>

                        <label for="convictions" class="cell small-9 medium-10 large-11 radioConvictionsLabel">Have you ever been
                            convicted of any criminal offense, including ALL FELONIES AND ALL MISDEMEANORS, in ANY state
                            and/or ANY jurisdiction?</label>

                        <div class="cell small-12">
                            <div v-if="convictionError" class="error">Please provide an answer above.</div>
                        </div>

                        <div v-if="convictionYes" class="small-offset-3 small-9 medium-offset-2 medium-10 large-offset-1 large-11 cell txtExplainYes">
                            <label for="txbxConvictionsExplanation">Please list the type of offense and the nature of
                                ALL such convictions below (failure to report ANY and ALL convictions on this renewal,
                                and any future renewals, will be considered a fraudulent misrepresentation, which may
                                result in the suspension or revocation of your lottery license):</label>
                            <input v-model="formModal.convictionInfo" type="text" id="txbxConvictionsExplanation" class="inputText"  />
                            <div v-if="convictionInfoError" class="error">Please provide conviction details above.</div>
                        </div>
                    </div>

                    <div class="formRow checkboxRow cf">
                        <input type="checkBox" id="chkBkAckPR" v-model="formModal.backgroundcheckApproved"
                            @click="backgroundCheckApproval()" />
                        <label for="chkBkAckPR">I authorize the Ohio Lottery Commission to conduct a background check using
                            the information provided in this renewal application.</label>
                        <div v-if="bgCheckError" class="error">You must authorize the Ohio Lottery Commission to conduct a
                            background check to proceed.<br> <br> </div>
                    </div>

                    <div class="grid-x grid-margin-x grid-margin-y">
                        <p class="intruction">Please read the Terms and Conditions below.<br><span class="small">Please
                                scroll all the way down to read the Terms and Conditions.</span></p>
                        <div class="disclaimerScroll cf scrollReq" id="disclaimerScroll" @scroll="eAppTermsOnScroll">
                            <!-- this needs some scroll magic -->
                            <div class="eApp-TandC-content eAppTandC">
                                <static propKey="eAppTandC"></static>
                            </div>
                        </div>
                    </div>

                    <div class="grid-x grid-margin-x grid-margin-y">
                        <label class="column small-12 txbxSignature">Signature:
                            <input v-model="formModal.userSignature" type="text" id="txbxSignature"
                                class="inputSignature appSignature" :disabled="!eAppTermsOnScrollValid || noCheck" autocomplete="off" @keyup="charCount()"/>
                        </label>
                        <div v-if="userSignatureError" class="error">Enter your legal signature.</div>
                    </div>

                    <div class="formRow checkboxRow cf">
                        <input @click="termsClick()" type="checkBox" id="chkLegName"
                            class="AcceptedAgreement appSignatureCert checkbox" :disabled="!isSigned"/>
                        <label for="chkLegName">Enter your legal name above to digitally sign this application,
                            indemnification agreement, authority to install equipment, electronic fund authorization, and
                            the W-9 form.</label>
                        <div v-if="signatureError" class="error">You must accept Terms and Conditions to proceed.</div>
                    </div>
                    <div v-if="plainRenewalErrorShow" class="ErrorLabel alert-error">{{plainRenewalError}}</div>
                    <div class="columns small-12 cf btn-action">
                        <button class="button btnCancel" data-close>Cancel</button>
                        <button 
                        class="button btnSubmitApp testValidSubmit" 
                        id="btnPlainRenew" 
                        @click="submitPlainRenew()" 
                        :disabled="disabledSubmitUpdates">
                            Renew
                        </button>
                    </div>

                </div>

                <div v-if="updateWithRenewal" id="updatesRenew">
                    <div class="columns small-12 formRow checkboxRow cf">
                        <input type="checkBox" id="chkBgChkApprove" v-model="formModal.backgroundcheckApproved"
                            @click="backgroundCheckApproval()" />
                        <label for="chkBgChkApprove">I authorize the Ohio Lottery Commission to conduct a background check
                            using the information provided in this renewal application.</label>
                        <div v-if="bgCheckError" class="error">You must authorize the Ohio Lottery Commission to conduct a
                            background check to proceed.</div>
                    </div>

                    <div class="columns small-12 cf btn-action">
                        <button class="button btnCancel" data-close>Cancel</button>
                        <button class="button btnRenewUpdate" @click="submitRenewWithUpdates()">Renew and Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import moment from "moment";
import { HollowDotsSpinner } from 'epic-spinners';
import Static from "../../Shared/Static.vue";
import SignInRegister from '/ViewComponents/SignInRegister/SignInRegister.vue';
import CurrentAppInfo from './current-app-info.vue';

export default {
    components: {
        HollowDotsSpinner,
        SignInRegister,
        Static,
        CurrentAppInfo
    },
    data() {
        return {
            isUserLogged: false,
            token: '',
            contentKey: 'eapp-updates-renewals-details',
            pageContent: {},
            termsContentKey: 'eAppTandC',
            termsContent: {},
            renewalProps: {},
            applicationData: {},
            isUserAllowed: false,
            isLoading: true,
            userId: 0,
            agentId: '',
            updateWrapperGuid: '',
            renewalAllowed: false,
            hasPendingRenewal: false,
            updateAllowed: false,
            hasPendingUpdates: false,
            convictionError: false,
            convictionYes: false,
            convictionInfoError: false,
            renewOnly: false,
            updateWithRenewal: false,
            bgCheckApproved: false,
            bgCheckError: false,
            signatureError: false,
            userSignatureError: false,
            eAppTermsOnScrollValid: false,
            showErrorMessage: false,
            buttonSubmitPlainRenew: false,
            formModal: {
                performUpdates: null,
                convictions: null,
                convictionInfo: '',
                backgroundcheckApproved: false,
                userSignature: '',
                signatureTermsChecked: false,
            },
            createError: false,
            disabledSubmitUpdates: true,
            noCheck: false,
            isSigned: false,
            plainRenewalErrorShow: false,
            plainRenewalError: ''
        }
    },
    mounted: function () {

        $(document).foundation();
        $('#formModal').foundation();

        this.isUserLogged = this.isUserAuthed();
        if (this.isUserLogged) {
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.init();
                });
        }

        $('#formModal').on("formvalid.zf.abide", function () { }).on("submit", function (ev) {
            ev.preventDefault();
        }).on("click", ".submit", function () {
            $("#formModal").submit();
        });
    },
    methods: {
        init() {
            let _this = this;
            // get page content from API
            this.pageContent = this.getContentByKey(this.contentKey, 'ur_content');
            // this.termsContent = this.getContentByKey(this.termsContentKey, 'eapp_terms_content');
            

            // get the user ID
            this.userId = this.getLoggedInUserId();
            // this.userId = 133; // FOR TESTING Retailer
            // this.userId = 2505547; // FOR TESTING

            // get the agent number
            this.agentId = this.getURLQuery('an');
            this.isLoading = false;

        },
        charCount() {
            // console.log(this.updateWrapper.updateSignature.length);
            if (this.formModal.userSignature.length >= 4) {
                this.isSigned = true;
                
            } else if (this.formModal.userSignature.length < 4) {
                this.isSigned = false;
            } else {
                this.isSigned = false;
            }
        },
        eAppTermsOnScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
                this.eAppTermsOnScrollValid = true;
            }
        },
        setRenewType(val) {
            this.formReset();
            console.log('val: ', val);
            if (val === 0) {
                this.renewOnly = true;
                this.updateWithRenewal = false;
                this.bgCheckApproved = false;
            } else {
                this.renewOnly = false;
                this.updateWithRenewal = true;
            }
        },
        formReset() {
            // reset the background check since it's on both forms.
            this.formModal.backgroundcheckApproved = false;
            this.bgCheckError = false;
            
        },
        setConviction(val) {
            if (val == 1) {
                this.convictionYes = true;
            } else {
                this.convictionYes = false;
                this.formModal.convictionInfo = '';
            }
            this.bgCheckApproved = false;
            this.convictionError = false;
        },
        backgroundCheckApproval() {
            this.formModal.backgroundcheckApproved ? this.formModal.backgroundcheckApproved = false : this.formModal.backgroundcheckApproved = true;
            this.bgCheckApproved ? this.bgCheckApproved = false : this.bgCheckApproved = true;
  
            this.bgCheckError = !this.bgCheckApproved;
        },
        termsClick() {
            if (this.noCheck === true) {
                this.disabledSubmitUpdates = true;
                this.noCheck = false;
            } else {
                this.disabledSubmitUpdates = false;
                this.noCheck = true;
            }

            this.formModal.signatureTermsChecked = !this.formModal.signatureTermsChecked;
            this.renewalProps.SignatureAcknowledgement = this.formModal.signatureTermsChecked;
        },
        submitPlainRenew() {
            this.plainRenewalErrorShow = false;
            if (this.formModal.convictions == null) {
                this.convictionError = true;
            } else {
                this.convictionError = false;
                if (this.convictionYes && this.formModal.convictionInfo == '') {
                    this.convictionInfoError = true;
                } else {
                    this.convictionInfoError = false;
                }
            }
            if (!this.formModal.backgroundcheckApproved) {
                this.bgCheckError = true;
            } else {
                this.bgCheckError = false;
            }
            if (this.formModal.userSignature === '') {
                this.userSignatureError = true;
            } else {
                this.userSignatureError = false;
            }
            if (!this.formModal.signatureTermsChecked) {
                this.signatureError = true;
            } else {
                this.signatureError = false;
            }

            // final status
            if (!this.convictionError && !this.convictionInfoError && !this.bgCheckError && !this.signatureError) {
                // valid, proceed with submit
                // console.log('SENDING');

                var apiToken = this.token;
                var urlProtocol = this.getProtocol();
                let renewBody = {
                    "userId": this.userId,
                    "agentNumber": this.agentId,
                    "principalConvictions": this.formModal.convictions,
                    "principalConvictionExplanations": this.formModal.convictionInfo,
                    "signature": this.formModal.userSignature
                }
                // return fetch(urlProtocol + process.env.VUE_APP_EAPPAPIURL + "/1.0/Update/CreateUpdate/" + this.userId + "/" + this.agentId + "?hasRenewal=true&hasUpdates=false", {
                return fetch(urlProtocol + process.env.VUE_APP_EAPPAPIURL + "/1.0/Update/CreatePlainRenewal", {
                    method: 'POST',
                    body: JSON.stringify(renewBody),
                    headers: {
                        'Authorization': 'Bearer ' + apiToken,
                        "Content-Type": "application/json"
                    }
                }).then(res => res.json()).then(res => {
                    if (window.location.href.includes('uat')) {
                        alert(JSON.stringify(res))
                    }

                    if (res === null || res === undefined || res === "") {
                        return false;
                    } else if (res.statusCode != 200) {
                        this.plainRenewalErrorShow = true;
                        this.plainRenewalError = res.data;
                    } else if (res.data.updateWrapperGuid === null || res.data.updateWrapperGuid === undefined || res.data.updateWrapperGuid === "") {
                        return false;
                    } else {
                        location.href = "/eApplications/updates";
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        submitRenewWithUpdates() {
            console.log(this.formModal.backgroundcheckApproved, this.bgCheckApproved, this.bgCheckError)

            if (this.bgCheckApproved && !this.bgCheckError) {
                //this.bgCheckError = false;
                console.log('SENDING');
                // process the params
                // hit the API: /1.0/Update/CreateUpdate/userId/agentNumber?hasRenewal=true&hasUpdates=true
                // redirect on success, pulling the "updateWrapperGuid" from the response
                // new URL: /eApplications/Updates/License-Edit?updateId={{ updateWrapperGuid }}&an={{ agentId }}
                // show spinner while waiting
                console.log(this.formModal);
                var apiToken = this.token;
                var urlProtocol = this.getProtocol();
                return fetch(urlProtocol + process.env.VUE_APP_EAPPAPIURL + "/1.0/Update/CreateUpdate/" + this.userId + "/" + this.agentId + "?hasRenewal=true&hasUpdates=true", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + apiToken,
                    }
                }).then(res => res.json()).then(res => {
                    if (window.location.href.includes('uat')) {
                        alert(JSON.stringify(res))
                    }

                    if (res.data.statusCode == 200) {
                        location.href = "/eApplications/Updates/License-Edit?updateId=" + res.data.data.updateWrapperGuid + "&an=" + this.agentId;
                    }
                }).catch(error => {
                    console.log(error);
                });

            } else {
                // show error
                this.bgCheckError = true;
            }
        },
        submitUpdate() {
            var apiToken = this.token;
            var urlProtocol = this.getProtocol();

            return fetch(urlProtocol + process.env.VUE_APP_EAPPAPIURL + "/1.0/Update/CreateUpdate/" + this.userId + "/" + this.agentId + "?hasRenewal=false&hasUpdates=false", {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                }
            }).then(res => res.json()).then(res => {
                if (window.location.href.includes('uat')) {
                    alert(JSON.stringify(res))
                }

                // alert('check console');
                if (res.data.statusCode == 200) {
                    if (res.data.data.updateWrapperGuid !== null && res.data.data.updateWrapperGuid !== undefined) {
                        location.href = "/eApplications/Updates/License-Edit?updateId=" + res.data.data.updateWrapperGuid + "&an=" + this.agentId;
                    }
                } else {
                    // console.log(res.data);
                }
            }).catch(error => {
                console.log(error);
                this.createError = true;
            });
        },
        processLicense(appJSON) {
            // runs after CurrentAppInfo has fetched the applicationData
            this.applicationData = appJSON;
            console.log(appJSON);
            // if this.applicationData.renewDate is within 30 days of current date (Change to 45 days)
            let date = this.applicationData.renewDate;
            const renewDate = moment(date).subtract(45, 'days');
            // console.log(renewDate.format('LLL'))
            // console.log(moment().format('LLL'))
            if (moment() >= renewDate) {
                console.log(moment() > renewDate)
                console.log('--------- SHOW RENEW -----------');
                this.renewalAllowed = true;
            }

            console.log(this.applicationData)

            this.updateWrapperGuid = this.applicationData.updateWrapperGuid;

            // this.hasPendingRenewal = this.applicationData.hasRenewal;
            // this.hasPendingUpdates = this.applicationData.hasUpdate;
            if (this.applicationData.hasRenewal || this.applicationData.hasUpdatesWithRenewal) {
                this.hasPendingRenewal = true;
            }
            
            if (this.applicationData.hasUpdate || this.applicationData.hasUpdatesWithRenewal || !this.applicationData.hasRenewal && !this.applicationData.hasUpdatesWithRenewal && this.applicationData.updateWrapperGuid !== null) {
                this.hasPendingUpdates = true;
            }

            if (Object.keys(this.applicationData).length) {
                this.updateAllowed = true;
            }

        }

    },
    computed: {
    }
}
</script>
<style lang="scss" scoped>
.radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
        margin: 0 1em 0 0;
    }
}

.explainYes {
    margin-top: 1em;

    .radioConvictionsLabel {
        display: flex;
        align-items: center;
        line-height: 1.5em;
    }
}

.btn-action {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0;

    button {
        margin: 0;
        float: none;

        &+button {
            margin-left: 1em !important;
        }
    }
}

.disclaimerScroll {
    border: 1px solid #969696;
    height: 300px;
    padding: 0 0 0 1em;
    margin-bottom: 1em;
    border-radius: 0.8rem;

    .eApp-TandC-content {
        margin: 1em 1em 1em 0;
    }
}
</style>
