import Vue from "vue";

import DrawDetail from "./draw-detail.vue";

export function load() {
  const selector = "draw-detail";

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return; // go no further
  }

  // Create a new Vue app with the imported component
  new Vue({
    render: createElement =>
      createElement(DrawDetail, {
        props: {
          ...window[selector]
        }
      }),
  }).$mount(selector);
}
