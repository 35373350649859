<template>
    <div>
        
    <div v-if="!isLoading" class="eAppModule">
        <div class="cf statusReview addEditListWrap cf">
            <h3>Review Application</h3>
            <p>{{ ReviewContent }}</p>

            <div class="addEditList cf">
                <span class="toolTip" v-if="!businessSectionPopulated" @click="toggleToolTip('showBusInfoTip')"><span class="icon-Status statusBusnInfo needsData toolTipIcon" :title="(businessSectionErrors)"></span><span class="toolTipContent" v-if="showBusInfoTip"> {{ businessSectionErrors }}</span></span>
                <span class="icon-Status statusBusnInfo" v-else></span>
                <label>Business Information</label>
                <button class="button editBtn btn-secondary" @click="editBusinessInformation()">Edit</button>
            </div>
            <div class="addEditList cf" v-if="showEft">
                <span class="toolTip" v-if="!reviewLanding.eftInformationPopulated" @click="toggleToolTip('showEftTip')"><span class="icon-Status statusEFT needsData toolTipIcon" :title="(reviewLanding.eftInformationPopulatedErrors)"></span><span class="toolTipContent" v-if="showEftTip"> {{ reviewLanding.eftInformationPopulatedErrors }}</span></span>
                <span class="icon-Status statusEFT" v-else></span>
                <label>Electronic Funds Transfer</label>
                <button class="button editBtn btn-secondary" @click="editEft()">Edit</button>
            </div>
            <div class="addEditList cf" v-if="showTax">
                <span class="toolTip" v-if="!reviewLanding.federalInformationPopulated" @click="toggleToolTip('showTaxTip')"><span class="icon-Status statusTax needsData toolTipIcon" :title="(reviewLanding.federalInformationPopulatedErrors)"></span><span class="toolTipContent" v-if="showTaxTip"> {{ reviewLanding.federalInformationPopulatedErrors }}</span></span>
                <span class="icon-Status statusTax" v-else></span>
                <label>Federal Tax Information</label>
                <button class="button editBtn btn-secondary" @click="editTax()">Edit</button>
            </div>
            <div class="addEditList cf" v-if="!existingChain">
                <span class="toolTip" v-if="!reviewLanding.backgroundCheckInformationPopulated" @click="toggleToolTip('showPrincipalTip')"><span class="icon-Status statusBgCheck needsData toolTipIcon" :title="(reviewLanding.backgroundCheckInformationPopulatedErrors)"></span><span class="toolTipContent" v-if="showPrincipalTip"> {{ reviewLanding.backgroundCheckInformationPopulatedErrors }}</span></span>
                <span class="icon-Status statusBgCheck" v-else></span>
                <label>Background Check</label>
                <button class="button editBtn btn-secondary" @click="editBackground()">Edit</button>
            </div>
            <span class="statusError" v-if="reviewLanding.businessRelatedFieldsPopulatedErrors !== '' || reviewLanding.eftInformationPopulatedErrors !== '' || reviewLanding.federalInformationPopulatedErrors !== '' || reviewLanding.backgroundCheckInformationPopulatedErrors !== ''">Please complete the areas above indicated with the <span class="icon-exclamation-triangle"></span> icon before submitting this application</span>
        </div>
        <div id="pnlForm" class="form-horizontal eapp_application termsReview">
            <div class="formRow checkboxRow cf termsCheck">
                <span class="req">*</span>
                <input id="AcceptedTC_checkbox" v-model="application.acceptedTC" type="checkbox" name="AcceptedTC_checkbox" @change="checkyCheck()">
                <label for="AcceptedTC_checkbox">By checking this box I agree to the Ohio Lottery Retailer <a href="#eAppTandCmodal" class="eAppModalTerms" data-open="eAppTandCmodal">Terms and Conditions</a></label>
            </div>
            <div class="formRow checkboxRow cf indemnCheck">
                <span class="req">*</span>
                <input id="Indemnification_checkbox" v-model="application.indemnification" type="checkbox" name="Indemnification_checkbox" @change="checkyCheck()">
                <label for="Indemnification_checkbox">By checking this box I agree to the Ohio Lottery <a href="#indemnificationModal" class="eAppModalIndemn" data-open="indemnificationModal">Indemnification Agreement</a>.</label>
            </div>
            <div class="formRow checkboxRow cf reqCheckA">
                <span class="req">*</span>
                <input id="RequiredTermsA_checkbox" v-model="application.requiredTermsA" type="checkbox" name="RequiredTermsA$checkbox" @change="checkyCheck()"><label for="RequiredTermsA_checkbox">By checking this box I confirm that I have authority to and do hereby authorize the Ohio Lottery Commission ("Lottery") or its Vendor to install any equipment required to operate as a Lottery Retailer. I confirm that I am the owner of the property where the equipment will be installed, or alternatively if I am a Lessee of the property, that the terms of my lease agreement, or other agreement between my Landlord and me, allow me to authorize the installation of equipment in the building. I understand that equipment will be installed on the roof of the building and that at no time will the Lottery or its Vendor drill any holes into the roof of the building. I understand that any equipment installed is the property of the Lottery or its Vendor and that if my license to operate as a Lottery Retailer is cancelled, the Lottery or its Vendor will retrieve its equipment at no cost to me. I understand that all equipment installed is subject to a separate Indemnification Agreement.</label>
            </div>
            <div class="formRow eAppSignature appSigManual cf">
                
                <label for="ApplicantSignature_txtText"><span class="req">*</span> Digital Signature:</label>
                <input name="ApplicantSignature$txtText" v-model="application.applicantSignature" type="text" maxlength="200" id="ApplicantSignature_txtText" class="appSignature" autocomplete="off" :disabled="sigDisabled" @input="activateCheckbox":class="{ scripty: application.signatureCertification }">

                <!-- <div v-if="!signatureValid" class="error signatureError">Please provide a digital signature above.</div> -->
            </div>
            <div class="formRow checkboxRow cf appSignatureCert">
                <span class="req">*</span>
                <input id="SignatureCertification_checkbox" v-model="application.signatureCertification" type="checkbox" name="SignatureCertification_checkbox" :disabled="sigAckDisabled" @change="checkyCheck()">
                <label for="SignatureCertification_checkbox">
                    Enter your legal name above to digitally sign this application, the terms and conditions, indemnification agreement, authority to install equipment, Electronic Fund Authorization, the W-9 form and the background check.
                </label>
                <!-- <div v-if="!signatureCertValid" class="error signatureError">Please certify your digital signature above.</div> -->
            </div>
            <div v-if="showError" class="ErrorLabel alert-error submitTermsError">
                You must agree to the Terms &amp;
                Conditions and provide a digital signature above.
            </div>
        </div>

        <div class=" cf eAppRetailerW9PDF" v-if="!(masterChain && !existingChain && acodeChain && !fcodeChain)">
            <a @click="downloadPDF(0)" target="_blank" id="btnGenerateW9" class="w9PDFbtn button btn-secondary"><span class="icon-arrow-down-line"></span> Preview W-9 Form</a>
        </div>
        <div class=" cf eAppRetailerW9PDF">
            <a @click="downloadPDF(1)" target="_blank" id="btnGenerateApplicationPDF" class="w9PDFbtn button btn-secondary"><span class="icon-arrow-down-line"></span> Download eApp Form</a>
        </div>

        <div v-if="appStatus < 2 || appStatus == 8" class=" cf eAppRetailerSubmit">
            <button v-if="allowSubmit" class="btnOpenModal button eAppBtnSubmit" data-open="eAppSubmitModal">Submit Application</button>
            <button v-else @click="trySubmit()" class="button eAppBtnSubmit disabled">Submit Application</button>
        </div>

       
    </div>
    <!-- modal for T&C -->
    <div class="reveal large" id="eAppTandCmodal" data-reveal>
        <static propKey="eAppTandC"></static>
        <button class="close-button" data-close aria-label="Close modal" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- modal for Indemnification -->
    <div class="reveal large" id="indemnificationModal" data-reveal>
        <static propKey="eAppIndemnification"></static>
        <button class="close-button" data-close aria-label="Close modal" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- modal for submit -->
    <div class="reveal small" id="eAppSubmitModal" data-reveal>
        <div class="modalContent cf">
            <h3>Submit application</h3>
            <p>
                Are you done editing the application and wish to move it one step closer to approval?<br>
                You will receive an email with instructions on how to contact your sales rep to setup a background check. You must have a background check completed in order to become an Ohio Lottery Retailer.
            </p>
            <button type="button" class="btn btnCancel" data-close>Cancel</button>
            <button type="button" class="btnSubmitApp button preventDblClick" :class="{ disabled: submitting || !canSubmit }" @click="submitApp()" :disabled="submitting || !canSubmit">{{ submitTxt }}</button>
        </div>
        <button class="close-button" data-close aria-label="Close modal" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div id="overlayPdfDownload" onclick='document.getElementById("overlay").style.display = "none"'>
        <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="3"
            color="#FFF"
            v-if="isPdfDownload"
        />
    </div>

    </div>
</template>


<script>
    import { HollowDotsSpinner } from 'epic-spinners'
    import Static from "../../Shared/Static.vue";
    import $ from 'jquery';
    export default {
        components: {
            Static,
            HollowDotsSpinner
        },
        props: {
            userId: Number,
            reviewToken: String,
            applicationJson: String
        },
        data: function () {
            return {
                reviewLanding: {},
                application: {},
                showBusInfoTip: false,
                showEftTip: false,
                showTaxTip: false,
                showPrincipalTip: false,
                isSignatureChecked: false,
                submitTxt: 'Submit',
                submitting: false,
                ReviewContent: document.getElementById('ReviewContent').value,
                eappId: '',
                signatureValid: true,
                signatureCertValid: true,
                termsValid: true,
                isLoading: true,
                canSubmit: false, 
                businessSectionPopulated: false,
                businessSectionErrors: '',
                associatedW9PdfFile: '',
                associatedApplicationPdfFile: '',
                allowSubmit: false,
                showError: false,
                isPdfDownload: false,
                existingChain: false,
                showEft: false,
                showTax: false,
                acodeChain: false,
                fcodeChain: false,
                masterChain: false,
                chain: false,
                appStatus: '',
                sigDisabled: false,
                sigAckDisabled: true,
            }
        },
        mounted: function () {
            $(document).foundation();
            $('#eAppSubmitModal').foundation();
            this.tryGetFromQueryString();
            this.fetchData();
            this.application = JSON.parse(this.applicationJson);
            this.existingChain = this.application.applicationIspartOfChain;
            this.acodeChain = this.application.applicationChainIsFranchise;
            this.fcodeChain = this.application.applicationChainIsCoorporate;
            this.masterChain = this.application.applicationIsMasterChain;
            this.chain = this.application.applicationIspartOfChain || this.application.applicationIsMasterChain;
            this.appStatus = this.application.applicationStatus;
            if (this.appStatus == 8) {
                this.application.signatureCertification = false;
            }
            this.sigDisabled = this.application.signatureCertification || (this.appStatus > 1 && this.appStatus != 8);
            
            
            console.log(this.appStatus)

            if (this.masterChain && this.acodeChain) {
                this.showEft = false;
                this.showTax = false;

            }
            else if (!this.masterChain && this.acodeChain) {
                this.showEft = true;
                this.showTax = true;
            }
            else if (this.masterChain && this.fcodeChain) {
                this.showEft = true;
                this.showTax = true;
            }
            else if (!this.masterChain && this.fcodeChain) {
                this.showEft = true;
                this.showTax = true;
            }
            else if (!this.chain) {
                this.showEft = true;
                this.showTax = true;
            }

            console.log(this.application)

            this.associatedW9PdfFile = this.application.associatedW9PdfFile;
            this.associatedApplicationPdfFile = this.application.associatedApplicationPdfFile;

        },
        methods: {
            async submitApp() {
                if (this.allowSubmit) {
                    this.submitTxt = "Submitting...";
                    this.submitting = true;
                    let submitBody = { application: this.application, userId: this.userId };
                    const submitRequestOptions = {
                        method: "POST",
                        headers: {
                            'Authorization': 'Bearer ' + this.reviewToken,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(
                            submitBody
                        )
                    };
                    await fetch(this.SUBMIT_API_URL, submitRequestOptions)
                    .then((response) => response.json())
                    .then((json) => {
                        // console.log(json);
                        this.submitTxt = "Submitted";
                        //redirect to eapps listing page
                        let currentPage = window.location.origin + "/eapplications";
                        if (this.acodeChain || this.existingChain) {
                            currentPage = window.location.origin + "/eapplications/chain";
                        }
                        window.location.href = currentPage;
                    })
                    .catch((error) => {
                        console.error("There was an error!", error);
                        // return (this.errorMessage = error);
                    });
                }
            },
            activateCheckbox() {
                if (this.application.applicantSignature === null || this.application.applicantSignature.length < 4 || this.appStatus > 1 && this.appStatus !== 8) {
                    this.sigAckDisabled = true;
                } else {
                    this.sigAckDisabled = false;
                }
            },
            editBusinessInformation() {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                this.step = 'business';
                urlParams.set('step', 'business');
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            editEft() {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                this.step = 'eft';
                urlParams.set('step', 'eft');
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            editTax() {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                this.step = 'tax';
                urlParams.set('step', 'tax');
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            editBackground() {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                this.step = 'background';
                urlParams.set('step', 'background');
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            deserializeJson() {
                this.application = JSON.parse(this.applicationJson);
            },
            tryGetFromQueryString() {
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('id')) {
                    this.eappId = urlParams.get('id');
                }
            },
            async fetchData() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + this.reviewToken,
                        "Content-Type": "application/json"
                    }
                };
                this.reviewLanding = await (await fetch(this.REVIEW_LANDING_API_URL, appsRequestOptions)).json();
                this.businessSectionPopulated = this.reviewLanding.businessRelatedFieldsPopulated
                    && this.reviewLanding.hoursPopulated
                    && this.reviewLanding.contactInformationPopulated
                    && this.reviewLanding.principalInformationPopulated;
                if (!this.businessSectionPopulated) {
                    if (!this.reviewLanding.businessRelatedFieldsPopulated) {
                        this.businessSectionErrors += this.reviewLanding.businessRelatedFieldsPopulatedErrors + ". ";
                    }
                    if (!this.reviewLanding.hoursPopulated) {
                        this.businessSectionErrors += this.reviewLanding.hoursPopulatedErrors + ". ";
                    }
                    if (!this.reviewLanding.contactInformationPopulated) {
                        this.businessSectionErrors += this.reviewLanding.contactInformationPopulatedErrors + ". ";
                    }
                    if (!this.reviewLanding.principalInformationPopulated) {
                        this.businessSectionErrors += this.reviewLanding.principalInformationErrors + ". ";
                    }
                }
                if (this.acodeChain) {
                    this.canSubmit = this.reviewLanding.backgroundCheckInformationPopulated &&
                        this.businessSectionPopulated
                } else {
                    this.canSubmit = this.reviewLanding.backgroundCheckInformationPopulated &&
                        this.businessSectionPopulated &&
                        this.reviewLanding.eftInformationPopulated &&
                        this.reviewLanding.federalInformationPopulated;
                }

                if (this.reviewLanding.businessRelatedFieldsPopulatedErrors === null) {
                    this.reviewLanding.businessRelatedFieldsPopulatedErrors = '';
                }
                if (this.reviewLanding.eftInformationPopulatedErrors === null) {
                    this.reviewLanding.eftInformationPopulatedErrors = '';
                }
                if (this.reviewLanding.federalInformationPopulatedErrors === null) {
                    this.reviewLanding.federalInformationPopulatedErrors = '';
                }
                if (this.reviewLanding.backgroundCheckInformationPopulatedErrors === null) {
                    this.reviewLanding.backgroundCheckInformationPopulatedErrors = '';
                }

                this.isLoading = false;
            },
            validate() {
                this.signatureValid = this.application.signatureCertification;
                //toggle this here again, in case of app in status 8
                this.sigDisabled = this.signatureValid;
                this.signatureCertValid = this.application.signatureCertification;
                this.termsValid = this.application.acceptedTC && this.application.indemnification && this.application.requiredTermsA;
                if (this.signatureValid && this.signatureCertValid && this.termsValid) {
                    this.showError = false;
                    return true;
                }
                return false;
            },
            checkyCheck() {
                if (this.validate()) {
                    this.allowSubmit = true;
                } else {
                    this.allowSubmit = false;
                }
            },
            trySubmit() {
                if (this.validate()) {
                    this.showError = false;
                } else {
                    this.showError = true;
                }
            },
            downloadPDF(pdfType){
                // console.log(this.userId)
                // console.log(this.eappId)
                document.getElementById("overlayPdfDownload").style.display = "flex";
                this.isPdfDownload = true;
                fetch('/Eapp/GeneratePDF?eapplicationGuid='+this.eappId+'&userId='+this.userId+'&type='+pdfType, {
                    method: "GET",
                })
                .then((res) => {
                    console.log(res)
                    document.getElementById("overlayPdfDownload").style.display = "none";
                    this.isPdfDownload = false;
                    window.open(res.url)
                })
                .catch((err) => {
                    document.getElementById("overlayPdfDownload").style.display = "none";
                    this.isPdfDownload = false;
                    console.error(err);
                });
            },
            toggleToolTip(toolTip){

                switch (toolTip) {
                    case "showBusInfoTip":
                        this.showBusInfoTip = !this.showBusInfoTip;
                        this.showEftTip = false;
                        this.showTaxTip = false;
                        this.showPrincipalTip = false;
                        break;
                    case "showEftTip":
                        this.showEftTip = !this.showEftTip;
                        this.showBusInfoTip = false;
                        this.showTaxTip = false;
                        this.showPrincipalTip = false;
                        break;
                    case "showTaxTip":
                        this.showTaxTip = !this.showTaxTip;
                        this.showBusInfoTip = false;
                        this.showEftTip = false;
                        this.showPrincipalTip = false;
                        break;
                    case "showPrincipalTip":
                        this.showPrincipalTip = !this.showPrincipalTip;
                        this.showBusInfoTip = false;
                        this.showEftTip = false;
                        this.showTaxTip = false;
                        break;
                }
            }
        },
        computed:
        {
            REVIEW_LANDING_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/regularapplication/user/` + this.userId + `/eapplication/` + this.eappId + `/reviewlanding`;
            },
            SUBMIT_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/regularapplication/submitapplication`;
            }
        },
    };
</script>

<style lang="scss" scoped>
#overlayPdfDownload {
  position: fixed; 
  display: none; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  z-index: 2;
  cursor: pointer; 
  align-items: center;
  justify-content: center;
}
</style>
