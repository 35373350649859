<template>
    <span>
        <div class="drawHisLI col4 link" @click="getWinners">
            <span v-if="!view">View Winners</span>
            <span v-if="view">Hide Winners</span>
        </div>
        <ul v-if="view === true" class="drawHisLI col5">
            <li class="showingDetails drawHistDetail listRow cf">
                <div class="drawHisLI listHead">
                    Winners
                </div>
                <div class="drawHisLI listHead entryCount">
                    Location
                </div>
            </li>
            <li v-for="winner in data" :key="winner.itenID" class="showingDetails listRow cf">
                    {{ winner.fullName }}
                <span class="entryCount">
                    {{ winner.location }}
                </span>
            </li>
        </ul>
    </span>
</template>

<script>
    // import $ from 'jquery';
    // import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        props: [
            'id',
            'tokenKey',
            'url',
            'winners'
        ],
        components:{
        },
        data: function () {
            return {
                data: '',
                view: false
            }
        },
        mounted: function () {
        },
        methods: {
            getWinners() {
                this.view = !this.view;
                
                if (this.url === false) {
                    this.data = this.winners;
                } else {
                    if (!this.data.length) {
                        let url = process.env.VUE_APP_APIURL + this.url + this.id;

                        fetch(url, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json-patch+json',
                                'Authorization': "Bearer " + this.tokenKey
                            },
                        })
                        .then((response) => response.json())
                        .then((json) => {
                            this.data = json.data;
                        })
                    }
                }
            }
        },
        computed: {
        },
        watch: {
        }
    };
</script>

<style lang="scss" scoped>
    h2 {
        float: left;
    }
    .col5 {
        display: block !important;
    }
    .drawHisViewAll {
        display: inline;
        float: right;
        margin-top: -30px;
        margin-bottom: 10px;
        font-weight: 300;

        @media screen and (min-width: 641px) {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>