<template>
    <div>
        <h3>Federal Tax Information</h3>
        <div v-if="!isLoading && visiblePanel =='tax'">
            <div class="addEditListWrap TaxStatus cf">

                <div class="addEditList cf" v-if="!hasTax">
                    <label>No W9 Entered</label>
                    <button class="button btn-secondary editBtn" @click="editTax()">Start W9</button>
                </div>
                <div class="addEditList cf" v-if="hasTax">
                    <span class="icon-Status statusTax"></span>
                    <label>W9 Entered <span>Tax ID {{tax.w9TaxID}}</span></label>
                    <button class="button btn-secondary editBtn" @click="editTax()">{{ btnText }} W9</button>
                </div>
            </div>
            <div class="moduleContent cf">
                <button class="btnNext button btn-primary" @click="navigateBackgroundCheck()">Background Check</button>
            </div>
        </div>
        <div v-if="!isLoading && visiblePanel == 'tax-edit-1'">
            <tax-edit-1 :taxJson="taxJson" :principalsJson="principalsJson" :readOnly="isDisabled" @onTaxSaveClick="onTaxSaveClick"></tax-edit-1>
        </div>

        <div v-if="!isLoading && visiblePanel == 'tax-edit-2'">
            <tax-edit-2 :taxJson="taxJson" :applicationInfoJson="applicationInfoJson" :principalsJson="principalsJson" :readOnly="isDisabled" @onTaxSaveClick="onTaxSaveClick"></tax-edit-2>
        </div>
    </div>
</template>

<script>

    import { ready } from 'jquery';
    import TaxEdit1 from './tax-edit-1';
    import TaxEdit2 from './tax-edit-2';
    export default {
        components: {
            TaxEdit1,
            TaxEdit2
        },
        props: {
            taxJson: String,
            principalsJson: String,
            visiblePanel: String,
            readOnly: Boolean,
            applicationInfoJson: String
        },
        data: function () {
            return {
                tax: {},
                hasTax: false,
                completedTax: false,
                isLoading: true,
                eappId: '',
                isDisabled: false,
                btnText: 'Edit'
            };
        },
        mounted: function () {
            this.deserializeJson();
            this.isLoading = false;
            this.isDisabled = this.readOnly;
            if (this.isDisabled) {
                this.btnText = 'View';
            }
        },
        methods: {
            deserializeJson() {
                this.tax = JSON.parse(this.taxJson);
                this.hasTax = this.taxJson !== '{}' && this.tax.w9ApplicantSignature !== null;
            },
            editTax() {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                urlParams.set('step', 'tax-edit-1');
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            onTaxSaveClick(taxEditRequest) {
                this.$emit('onTaxSaveClick', taxEditRequest);
                this.step = 'tax';
            },
            navigateBackgroundCheck() {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                urlParams.set('step', 'background');
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            }
        },
        computed: {}
    };
</script>
