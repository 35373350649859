import { render, staticRenderFns } from "./FrequentlyNumbers.vue?vue&type=template&id=c0ab0b64&scoped=true"
import script from "./FrequentlyNumbers.vue?vue&type=script&lang=js"
export * from "./FrequentlyNumbers.vue?vue&type=script&lang=js"
import style0 from "./FrequentlyNumbers.vue?vue&type=style&index=0&id=c0ab0b64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0ab0b64",
  null
  
)

export default component.exports