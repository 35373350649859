<template>
    <div v-if="!readOnly" data-abide novalidate data-validate-on-blur="true" id="updateW9Form" class="grid-x grid-margin-x grid-margin-y update-w9">

        <p v-if="pageContent" v-html="pageContent.updateW9Intro"></p>

        <div class="formRow cell">
            <span class="req">*</span>
            <label title="This is your name" class="helpTxt" for="w9Name">Name:</label>
            <input v-model="taxUpdate.w9Name" type="text" id="w9Name" maxlength="200" required :disabled="readOnly || editing === true" />
            <!-- <div class="form-error error">Please provide your name</div> -->
            <div class="error" id="w9NameError" style="display:none;">Please provide your name</div>
        </div>
        <div class="formRow cell">
            <span class="req">*</span>
            <label title="This is the legal name of your business" class="helpTxt" for="w9LegalName">Legal Business Name:</label>
            <input v-model="taxUpdate.w9LegalName" type="text" id="w9LegalName" @change="legalValueChanged('legalName')" class="infoChangeCheckInput" maxlength="200" required :disabled="readOnly || editing === true" />
            <!-- <div class="form-error error">Please provide the legal business name</div> -->
            <div class="error" id="w9LegalNameError" style="display:none;">Please provide the legal business name</div>
            <div class="error warningMessage" v-if="legalNameChanged">Please make sure to also update the Business Information to match the Legal Name</div>
        </div>
        <div class="formRow cell">
            <span class="req">*</span>
            <label title="This is the entity type of your business" class="helpTxt" for="w9LegalEntityType">Entity Type:</label>
            <select id="w9LegalEntityType" v-model="taxUpdate.w9LegalEntityType" required :disabled="readOnly || editing === true" >
                <option value="null" selected>Select a type</option>
                <option value="1">Sole Proprietor</option>
                <option value="2">Corp</option>
                <option value="3">Partnership</option>
                <option value="4">LLC</option>
                <option value="5">Non-Profit Corp</option>
            </select>
            <!-- <div class="form-error error">Please select an entity type</div> -->
            <div class="error" id="w9LegalEntityTypeError" style="display:none;">Please select an entity type</div>
        </div>
        <div class="formRow cell">
            <span class="req">*</span>
            <label title="This is the address of your business" class="helpTxt" for="w9StreetAddress">Street Address:</label>
            <input v-model="taxUpdate.w9StreetAddress" type="text" @input="invalidateTax()" @change="legalValueChanged('legalAddress')" class="infoChangeCheckInput" id="w9StreetAddress" maxlength="200" required :disabled="readOnly || editing === true" />
            <!-- <div class="form-error error">Please provide the street address</div> -->
            <div class="error" id="w9StreetAddressError" style="display:none;">Please provide the street address</div>
            <div class="error warningMessage" v-if="legalAddressChanged">Please make sure to also update the Business Information to match the Legal Address</div>
        </div>
        <div class="formRow cell">
            <span class="req">*</span>
            <label title="This is the city of your business" class="helpTxt" for="w9City">City:</label>
            <input v-model="taxUpdate.w9City" type="text" @input="invalidateTax()" @change="legalValueChanged('legalCity')" class="infoChangeCheckInput" id="w9City" maxlength="200" required :disabled="readOnly || editing === true" />
            <!-- <div class="form-error error">Please provide the city</div> -->
            <div class="error" id="w9CityError" style="display:none;">Please provide the city</div>
            <div class="error warningMessage" v-if="legalCityChanged">Please make sure to also update the Business Information to match the Legal City</div>
        </div>
        <div class="dividerRow grid-x grid-margin-x grid-margin-y">
            <div class="formRow dividerRow validateZip cell medium-8">
                <div style="display: flex; align-items: center;">
                    <div style="padding-right:10px;">
                        <span class="req">*</span>
                        <label for="w9Zip">Zip:</label>
                        <input v-model="taxUpdate.w9Zip" type="text" id="w9Zip" @input="invalidateTax()" @change="legalValueChanged('legalZip')" :disabled="readOnly || editing === true" class="zipFive infoChangeCheckInput" required pattern="^4[3-5]\d{3}$" minlength="5" maxlength="5" />
                    </div>

                    <div style="padding-right:10px;">
                        <span class="req">*</span>
                        <label for="w9ZipPlus4">Zip +4:</label>
                        <input v-model="taxUpdate.w9ZipPlus4" type="text" id="w9ZipPlus4" class="zipFour" required :disabled="readOnly || editing === true" pattern="\b\d{4}\b" minlength="4" maxlength="4" @input="invalidateTax()" />
                    </div>
                    <a href="https://tools.usps.com/go/ZipLookupAction!input.action" target="_blank" class="eAppZip4Link">Zip +4 Lookup</a>
                </div>
                <!-- <div class="error" v-if="error.zipCode">Please provide a Zip Code</div> -->
                <div class="error" id="w9Zip" style="display:none;">Please provide a Zip Code</div>
                <!-- <div class="error" v-if="error.zipCode4">Please provide a Zip +4</div> -->
                <div class="error" id="w9ZipPlus4Error" style="display:none;">Please provide a Zip +4</div>
                <div class="error" v-if="error.zipCode">Please provide a valid Ohio Zip Code</div>
                <div class="error warningMessage" v-if="legalZipChanged">Please make sure to also update the Business Information to match the Legal Zip Code</div>
            </div>
            <div class="formRow dividerRow cell medium-4">
                <span class="req">*</span>
                <label for="w9State">State:</label>
                <select v-model="taxUpdate.w9State" id="w9State" @input="invalidateTax()" @change="legalValueChanged('legalState')" class="DropDownField infoChangeCheckInput" required :disabled="readOnly || editing === true">
                    <option value="null" selected>Select a state </option>
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AZ">AZ</option>
                    <option value="AR">AR</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="IA">IA</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="ME">ME</option>
                    <option value="MD">MD</option>
                    <option value="MA">MA</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MS">MS</option>
                    <option value="MO">MO</option>
                    <option value="MT">MT</option>
                    <option value="NE">NE</option>
                    <option value="NV">NV</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NY">NY</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="DC">DC</option>
                    <option value="WV">WV</option>
                    <option value="WI">WI</option>
                    <option value="WY">WY</option>
                </select>
                <!-- <div class="form-error error">Please select a State</div> -->
                <div class="error" id="w9StateError" style="display:none;">Please select a State</div>
                <div class="error warningMessage" v-if="legalStateChanged">Please make sure to also update the W9 information to match the Legal State</div>
            </div>

            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label title="This is the tax classification of your business" class="helpTxt" for="w9TaxClassification">Tax Classification:</label>
                <select id="w9TaxClassification" v-model="taxUpdate.w9TaxClassification" required :disabled="readOnly || editing === true">
                    <option value="null">Select a classification</option>
                    <option value="Individual/Sole Proprietor or Single-member">Individual/Sole Proprietor or Single-member</option>
                    <option value="C Corporation">C Corporation</option>
                    <option value="S Corporation">S Corporation</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Trust/Estate">Trust/Estate</option>
                    <option value="LLC - C Corporation">LLC - C Corporation</option>
                    <option value="LLC - S Corporation">LLC - S Corporation</option>
                    <option value="LLC - Partnership">LLC - Partnership</option>
                    <option value="Other">Other</option>
                </select>
                <!-- <div class="form-error error">Please select an tax classification</div> -->
                <div class="error" id="w9TaxClassificationError" style="display:none;">Please select an tax classification</div>
            </div>
            <div class="formRow cell medium-6">
                <div v-if="taxUpdate.w9TaxClassification == 'Other'">
                    <span class="req">*</span>
                    <label title="This is the tax classification of your business" class="helpTxt" for="w9TaxClassificationOther">Other Tax Classification:</label>
                    <input v-model="taxUpdate.w9TaxClassificationOther" type="text" id="w9TaxClassificationOther" maxlength="200" required :disabled="readOnly || editing === true" />
                    <!-- <div class="form-error error">Please provide a tax classification</div> -->
                    <div class="error" id="w9TaxClassificationOtherError" style="display:none;">Please provide a tax classification</div>
                </div>
            </div>

            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label title="This is the tax ID of your business. Enter nine numbers, without dashes or spaces." class="helpTxt" for="w9TaxID">Tax ID:</label>
                <input v-model="taxUpdate.w9TaxID" type="text" id="w9TaxID" minlength="9" maxlength="9" required pattern="^\d{9}$" :disabled="readOnly || editing === true" />
                <!-- <div class="form-error error" data-form-error-on="required">Please provide the tax ID</div> -->
                <div class="error" id="w9TaxIDError" style="display:none;">Please provide the tax ID</div>
                <div class="form-error error" data-form-error-on="pattern">Please enter nine numbers, without dashes or spaces</div>
            </div>
            <div class="formRow cell medium-6">
                <span class="radio-list-horizontal">
                    <span class="req">*</span>
                    <label title="This is the tax ID type of your business" class="helpTxt" for="w9TaxIDType">Tax ID Type:</label>

                    <span class="radio">  
                        <label class="">
                            <input type="radio" name="w9TaxIDType" value="0" v-model="taxUpdate.w9TaxIDType" id="w9Radio1" required :disabled="readOnly || editing === true" /> Employer ID
                        </label>
                    </span>
                    <span class="radio">
                        <label class="">
                            <input type="radio" name="w9TaxIDType" value="1" v-model="taxUpdate.w9TaxIDType" id="w9Radio2" :disabled="readOnly || editing === true" /> Social Security Number
                        </label>
                    </span>
                    
                    <!-- <div class="form-error error">Please select the tax Type</div> -->
                    <div class="error" id="w9TaxIDTypeError" style="display:none;">Please select the tax Type</div>

                </span>
                
            </div>

            <div class="formRow cell medium-6">
                <label title="This is the exempt payee code of your business" class="helpTxt" for="w9ExemptPayeeCode">Exempt Payee Code:</label>
                <select id="w9ExemptPayeeCode" v-model="taxUpdate.w9ExemptPayeeCode" :disabled="readOnly || editing === true">
                    <option value="null" selected="selected">None</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                </select>
            </div>
            <div class="formRow cell medium-6">
                <label title="This is the FATCA exemption code of your business" class="helpTxt"
                    for="w9FATCAExemptionCode">FATCA Exemption Code:</label>
                <select id="w9FATCAExemptionCode" v-model="taxUpdate.w9FATCAExemptionCode" :disabled="readOnly || editing === true">
                    <option value="null" selected="selected">None</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                    <option value="M">M</option>
                </select>
            </div>
        </div>
        <div class="formRow checkboxRow cell">
            <input v-model="taxUpdate.w9DifferentLegalContact" @click="toggleContact" ref="taxUpdate.w9DifferentLegalContact" type="checkbox" id="w9DifferentLegalContact" :disabled="readOnly || editing === true" />
            <label for="w9DifferentLegalContact">Is there a different legal contact? If yes, please click the box to the left.</label>
        </div>
        <div v-if="legalContact" class="validateW9DifferentLegalContact">
            <div class="">
                <span class="req">*</span>
                <label title="This is the first name of your legal contact" for="w9LegalContactFirstName">Legal Contact First Name</label>
                <input v-model="taxUpdate.w9LegalContactFirstName" id="w9LegalContactFirstName" type="text" maxlength="200" required :disabled="readOnly || editing === true" />
                <div class="error" id="w9LegalContactFirstNameError" style="display:none;">Please provide the first name of your legal contact</div>
            </div>
            <div class="">
                <span class="req">*</span>
                <label title="This is the last name of your legal contact" for="w9LegalContactLastName">Legal Contact Last Name:</label>
                <input v-model="taxUpdate.w9LegalContactLastName" type="text" maxlength="200" id="w9LegalContactLastName" required :disabled="readOnly || editing === true" />
                <div class="error" id="w9LegalContactLastNameError" style="display:none;">Please provide the last name of your legal contact</div>
            </div>
        </div>

        <div class="formRow checkboxRow cell">
            <span class="req">*</span>
            <input v-model="taxUpdate.w9AgreeFederalCertificationRequirements" name="w9AgreeFederalCertificationRequirements" type="checkbox" id="w9AgreeFederalCertificationRequirements" required :disabled="readOnly || editing === true" />
            <label for="w9AgreeFederalCertificationRequirements" title="This is the agreement of the federal certification requirements">Agree to Federal Certification Requirements</label>
            <!-- <div class="form-error error">You must select this box to proceed</div> -->
            <div class="error" id="w9AgreeFederalCertificationRequirementsError" style="display:none;">You must select this box to proceed</div>
        </div>
        <div class="formRow checkboxRow cell">
            <span class="req">*</span>
            <input v-model="taxUpdate.w9AgreeTerms" name="w9AgreeTerms" type="checkbox" id="w9AgreeTerms" required :disabled="readOnly || editing === true" />
            <label for="w9AgreeTerms">Do you agree to the terms? If yes, please click the box to the left.</label>
            <!-- <div class="form-error error">You must select this box to proceed</div> -->
            <div class="error" id="w9AgreeTermsError" style="display:none;">You must select this box to proceed</div>
        </div>
    </div>
    <div v-else>
        <div class="moduleContent cf appReview cf">
            <h4>Federal Tax Information Update</h4>
            <dl class="reviewFields cf">
                <dt>Name</dt>
                <dd>{{ taxUpdate.w9Name }}</dd>
                <dt>Legal Business Name</dt>
                <dd>{{ taxUpdate.w9LegalName }}</dd>
                <dt>Legal Street Address</dt>
                <dd>{{ taxUpdate.w9StreetAddress }}<br>
                    {{ taxUpdate.w9City }}, {{ taxUpdate.w9State }} {{ taxUpdate.w9Zip }} {{ taxUpdate.w9ZipPlus4 }}
                </dd>
                <dt>Entity Type</dt>
                <dd>{{ taxUpdate.w9LegalEntityType }}</dd>
                <dt>Tax Classification</dt>
                <dd>{{ taxUpdate.w9TaxClassification }}</dd>
                <dt>Tax ID</dt>
                <dd>XXXXXX{{ taxUpdate.w9TaxID[5] }}{{ taxUpdate.w9TaxID[6] }}{{ taxUpdate.w9TaxID[7] }}{{ taxUpdate.w9TaxID[8] }}</dd>
                <dt>Tax ID Type</dt>
                <dd>{{ taxUpdate.w9TaxIDType }}</dd>
                <dt>Exempt Payee Code</dt>
                <dd>{{ taxUpdate.w9ExemptPayeeCode }}</dd>
                <dt>FATCA Exemption Code</dt>
                <dd>{{ taxUpdate.w9FATCAExemptionCode }}</dd>
                <dt v-if="taxUpdate.w9LegalContactFirstName">Legal Contact First Name</dt>
                <dd v-if="taxUpdate.w9LegalContactFirstName">{{ taxUpdate.w9LegalContactFirstName }}</dd>
                <dt v-if="taxUpdate.w9LegalContactLastName">Legal Contact Last Name</dt>
                <dd v-if="taxUpdate.w9LegalContactLastName">{{ taxUpdate.w9LegalContactLastName }}</dd>
            </dl>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
    props: {
        taxUpdate: Object,
        userId: Number,
        hasRenewal: Boolean,
        pageContent: Object,
        readOnly: Boolean,
        editing: Boolean
    },
    components: {
    },
    data() {
        return {
            updateW9Form: false,
            error: {
                zipCode: false,
                zipCode4: false,
            },
            legalNameOrig: '',
            legalAddressOrig: '',
            legalCityOrig: '',
            legalStateOrig: '',
            legalZipOrig: '',
            legalZipPlus4Orig: '',
            legalNameChanged: false,
            legalAddressChanged: false,
            legalCityChanged: false,
            legalStateChanged: false,
            legalZipChanged: false,
            legalContact: false,
        }
    },
    mounted: function () {
        if (!this.readOnly) {

            let _this = this;
            $("#updateW9Form").foundation();

            $("#updateW9Form").foundation('requiredCheck', $("#w9AgreeFederalCertificationRequirements"))
            $("#updateW9Form").foundation('requiredCheck', $("#w9AgreeTerms"))

            $('#updateW9Form').on("invalid.zf.abide", function (ev, elem) { 
                // console.log('invalid',ev)
                let field = ev.target;
                field.id === 'w9Zip' && (_this.error.zipCode = true);
                field.id === 'w9ZipPlus4' && (_this.error.zipCode4 = true);
            }).on("valid.zf.abide", function (ev, elem) {
                // console.log(ev)
                let field = ev.target;
                field.id === 'w9Zip' && (_this.error.zipCode = false);
                field.id === 'w9ZipPlus4' && (_this.error.zipCode4 = false);
            }).on("formvalid.zf.abide", function (ev, frm) {
                // console.log("Form id " + frm.attr('id') + " is valid");
                _this.updateW9Form = true;
            });

        }
            this.setOriginalVals();
    },
    computed: {
        validateDifferentLegalContact(){
            if(this.taxUpdate.w9DifferentLegalContact){              
                this.reInitFoundation();          
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        invalidateTax() {
            this.$parent.invalidateAddress('tax', this.taxUpdate);
        },
        updateW9(data) {
            this.taxUpdate = data;
        },
        setOriginalVals() {
            if (this.taxUpdate.w9LegalName) {
                this.legalNameOrig = this.taxUpdate.w9LegalName;
            }
            if (this.taxUpdate.w9StreetAddress) {
                this.legalAddressOrig = this.taxUpdate.w9StreetAddress;
            }
            if (this.taxUpdate.w9City) {
                this.legalCityOrig = this.taxUpdate.w9City;
            }
            if (this.taxUpdate.w9State) {
                this.legalStateOrig = this.taxUpdate.w9State;
            }
            if (this.taxUpdate.w9Zip) {
                this.legalZipOrig = this.taxUpdate.w9Zip;
            }
            if (this.taxUpdate.w9ZipPlus4) {
                this.legalZipPlus4Orig = this.taxUpdate.w9ZipPlus4;
            }
            this.legalContact = this.taxUpdate.w9DifferentLegalContact;
        },
        reInitFoundation(){
            setTimeout(() => {
                let firstInput = $(".validateW9DifferentLegalContact input:eq(0)"),
                    secondInput = $(".validateW9DifferentLegalContact input:eq(1)")
                $("#updateW9Form").foundation('validateInput', firstInput);
                $("#updateW9Form").foundation('validateInput', secondInput);
            }, 200);
        },
        legalValueChanged(field) {
            // compare original values to changed
            // if not the same then show message
            switch (field) {
                case "legalName":
                    if (this.legalNameOrig !== this.taxUpdate.w9LegalName) {
                        this.legalNameChanged = true;
                    } else {
                        this.legalNameChanged = false;
                    }
                    // console.log(this.legalNameOrig, this.taxUpdate.w9LegalName, this.legalNameChanged);
                    break;
                case "legalAddress":
                    if (this.legalAddressOrig !== this.taxUpdate.w9StreetAddress) {
                        this.legalAddressChanged = true;
                    } else {
                        this.legalAddressChanged = false;
                    }
                    break;
                case "legalCity":
                    if (this.legalCityOrig !== this.taxUpdate.w9City) {
                        this.legalCityChanged = true;
                    } else {
                        this.legalCityChanged = false;
                    }
                    break;
                case "legalName":
                    if (this.legalStateOrig !== this.taxUpdate.w9State) {
                        this.legalStateChanged = true;
                    } else {
                        this.legalStateChanged = false;
                    }
                    break;
                case "legalZip":
                    if (this.legalZipOrig !== this.taxUpdate.w9Zip) {
                        this.legalZipChanged = true;
                    } else {
                        this.legalZipChanged = false;
                    }
                    break;
                    
            
                default:
                    break;
            }
        },
        toggleContact() {
            this.legalContact = !this.legalContact;
            this.taxUpdate.w9DifferentLegalContact = this.legalContact;
            if (this.legalContact === false) {
                this.taxUpdate.w9LegalContactFirstName = null;
                this.taxUpdate.w9LegalContactLastName = null;
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.validateW9DifferentLegalContact{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;

    div{
        width: 100%;
    }
}
</style>
