<template>
    <section class="logon-panel-password-retrieval">
        <div id="retrievePassword">
            <form>
                <p>Enter the username associated with your account. We'll email you a password recovery link.</p>
                <div class="form-password-reset" role="form">

                    <div class="field-group">
                        <label for="username" class="field-label">
                            Username
                        </label>
                        <input name="username" type="text" class="form-field" placeholder="Username" v-model="username">
                    </div>
                    <button class="button btn-primary" @click="forgotPassword">Submit</button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
    export default {
        data: function() {
            return {
                token: '',
                username: ''
            }
        },
        mounted: function() {
            this.getToken()
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                });
            },
            forgotPassword() {
                let userInput = window.parent.document.getElementById("userInput");
                userInput.value = this.username;
                userInput.click();
            }
        }
    }
</script>