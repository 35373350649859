<template>
    <div class=" cf ">
        <h4>Enter Tax Information</h4>
        <p class="w9Link">Download <a href="http://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">IRS W9 Instructions</a> (PDF)</p>

        <div v-if="!validFormOne" class="alert-error ErrorLabel">The entered values cannot be saved. Please see the fields below for details.</div>

        <div class="grid-x grid-margin-x grid-margin-y cf eAppFormEdit eapp_w9information">
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="W9Name_txtText">Legal Business Name:</label>
                <input v-model="tax.w9Name" :disabled="isDisabled" type="text" maxlength="200" id="W9Name_txtText" autocomplete="off" aria-describedby="W9Name_error">
                <span class="inputNote">(This is the legal name of your business)</span>
                <div v-if="!validW9Name" class="error" role="alert" id="W9Name_error">Please enter your legal business name above.</div>
            </div>
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="W9LegalName_txtText">Business name/disregarded entity name, if different from above:</label>
                <input v-model="tax.w9LegalName" :disabled="isDisabled" name="W9LegalName_txtText" type="text" maxlength="100" id="W9LegalName_txtText" autocomplete="off" aria-describedby="W9LegalName_error">
                <div v-if="!validLegalName" class="error" role="alert" id="W9LegalName_error">Please enter a business name.</div>
            </div>
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="W9StreetAddress_txtText">Street Address:</label>
                <input v-model="tax.w9StreetAddress" :disabled="isDisabled" name="W9StreetAddress_txtText" type="text" maxlength="600" id="W9StreetAddress_txtText" autocomplete="off" aria-describedby="W9StreetAddress_error">
                <div v-if="!validStreet" class="error" role="alert" id="W9StreetAddress_error">Please enter a street address.</div>
            </div>
            <div class="formRow cell">
                <span class="req">*</span>

                <label for="W9City_txtText">City:</label>
                <input v-model="tax.w9City" :disabled="isDisabled" name="W9City_txtText" type="text" maxlength="300" id="W9City_txtText" autocomplete="off" aria-describedby="W9City_error">
                <div v-if="!validCity" class="error" role="alert" id="W9City_error">Please enter a city.</div>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="W9State_dropDownList">State:</label>
                <select v-model="tax.w9State" :disabled="isDisabled" name="W9State_dropDownList" id="W9State_dropDownList" aria-describedby="W9State_error">
                    <option value="null">State</option>
                    <option value="AL"> AL</option>
                    <option value="AK"> AK</option>
                    <option value="AZ"> AZ</option>
                    <option value="AR"> AR</option>
                    <option value="CA"> CA</option>
                    <option value="CO"> CO</option>
                    <option value="CT"> CT</option>
                    <option value="DE"> DE</option>
                    <option value="FL"> FL</option>
                    <option value="GA"> GA</option>
                    <option value="HI"> HI</option>
                    <option value="ID"> ID</option>
                    <option value="IL"> IL</option>
                    <option value="IN"> IN</option>
                    <option value="IA"> IA</option>
                    <option value="KS"> KS</option>
                    <option value="KY"> KY</option>
                    <option value="LA"> LA</option>
                    <option value="ME"> ME</option>
                    <option value="MD"> MD</option>
                    <option value="MA"> MA</option>
                    <option value="MI"> MI</option>
                    <option value="MN"> MN</option>
                    <option value="MS"> MS</option>
                    <option value="MO"> MO</option>
                    <option value="MT"> MT</option>
                    <option value="NE"> NE</option>
                    <option value="NV"> NV</option>
                    <option value="NH"> NH</option>
                    <option value="NJ"> NJ</option>
                    <option value="NM"> NM</option>
                    <option value="NY"> NY</option>
                    <option value="NC"> NC</option>
                    <option value="ND"> ND</option>
                    <option value="OH"> OH</option>
                    <option value="OK"> OK</option>
                    <option value="OR"> OR</option>
                    <option value="PA"> PA</option>
                    <option value="RI"> RI</option>
                    <option value="SC"> SC</option>
                    <option value="SD"> SD</option>
                    <option value="TN"> TN</option>
                    <option value="TX"> TX</option>
                    <option value="UT"> UT</option>
                    <option value="VT"> VT</option>
                    <option value="VA"> VA</option>
                    <option value="WA"> WA</option>
                    <option value="DC"> DC</option>
                    <option value="WV"> WV</option>
                    <option value="WI"> WI</option>
                    <option value="WY"> WY</option>
                </select>

                <div v-if="!validState" class="error" role="alert" id="W9State_error">Please select a state.</div>
            </div>
            <div class="formRow zipFields cell medium-6">
                <span class="req">*</span>
                <label for="W9Zip_txtText">Zip Code:</label>
                <input v-model="tax.w9Zip" name="W9Zip_txtText" :disabled="isDisabled" type="text" maxlength="5" id="W9Zip_txtText" autocomplete="off" aria-describedby="W9Zip_error">
                <!-- zip+4 is optional-->
                <input v-model="tax.w9ZipPlus4" name="W9ZipPlus4_txtText" :disabled="isDisabled" type="text" maxlength="4" id="W9ZipPlus4_txtText" autocomplete="off">
                <div v-if="!validZip" class="error" role="alert" id="W9Zip_error">
                    Please enter a five-digit zip code.
                </div>
            </div>
            <div class="formRow cell dividerRow">
            </div>
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="W9TaxClassification_dropDownList">Federal Tax Classification:</label>
                <select v-model="tax.w9TaxClassification" :disabled="isDisabled" name="W9TaxClassification_dropDownList" id="W9TaxClassification_dropDownList" aria-describedby="W9TaxClassification_error">
                    <option value="null">Select one</option>
                    <option value="Individual/Sole Proprietor or Single-member">Individual/Sole Proprietor or Single-member</option>
                    <option value="C Corporation">C Corporation</option>
                    <option value="S Corporation">S Corporation</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Trust/Estate">Trust/Estate</option>
                    <option value="LLC - C Corporation">LLC - C Corporation</option>
                    <option value="LLC - S Corporation">LLC - S Corporation</option>
                    <option value="LLC - Partnership">LLC - Partnership</option>
                    <option value="Other">Other</option>

                </select>
                <div v-if="!validClassification" class="error" role="alert" id="W9TaxClassification_error">Please select a classification.</div>
                <span class="inputNote"><strong>Note:</strong> For a single-member LLC that is disregarded, do not select LLC; select the appropriate option above for the tax classification of the single-member owner.</span>
            </div>
            <div class="formRow taxClassificationOther cell" v-if="tax.w9TaxClassification == 'Other'">
                <span class="req">*</span>
                <label for="W9TaxClassificationOther_txtText">Tax Classification:</label>
                <input v-model="tax.w9TaxClassificationOther" :disabled="isDisabled" name="W9TaxClassificationOther_txtText" type="text" maxlength="300" id="W9TaxClassificationOther_txtText" autocomplete="off" aria-describedby="W9TaxClassificationOther_error">
                <div v-if="!validClassificationOther" class="error" role="alert" id="W9TaxClassificationOther_error">Please enter a tax classification.</div>
            </div>
            <div class="formRow taxID cell large-5">
                <span class="req">*</span>

                <label for="W9Taxid_txtText">Taxpayer Identification Number:</label>
                <input v-model="tax.w9TaxID" name="W9Taxid_txtText" :disabled="isDisabled" type="text" minlength="9" maxlength="9" id="W9Taxid_txtText" autocomplete="off" aria-describedby="W9Taxid_error" required>
                <div v-if="!validId" class="error" role="alert" id="W9Taxid_error">Please provide a nine-digit Eid or SSN number.</div>
            </div>
            <div class="formRow cell large-7 radios">
                <span class="radio-list-horizontal">
                    <span class="radio">
                        <input id="W9TaxidType_0" :disabled="isDisabled" v-model="tax.w9TaxTypeID" type="radio" name="W9TaxidType" value="0">
                        <label for="W9TaxidType_0">Employer id</label>
                    </span>

                    <span class="radio">
                        <input id="W9TaxidType_1" :disabled="isDisabled" v-model="tax.w9TaxTypeID" type="radio" name="W9TaxidType" value="1">
                        <label for="W9TaxidType_1">Social Security Number</label>
                    </span>
                </span>
                <div v-if="!validIdType" class="error" role="alert" id="W9TaxidType_error">Please select the type of id.</div>
            </div>

            <div class="formRow cell large-6">
                <label class="labelInline" for="W9ExemptPayeeCode_dropDownList">Exempt Payee Code:</label>
                <select v-model="tax.w9ExemptPayeeCode" :disabled="isDisabled" name="W9ExemptPayeeCode_dropDownList" id="W9ExemptPayeeCode_dropDownList">
                    <option value="null">None</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                </select>
                <span class="inputNote">(codes apply only to certain entities, not individuals)</span>
            </div>
            <div class="formRow cell large-6">
                <label class="labelInline" for="W9FATCAExemptionCode_dropDownList">Exemption from FATCA reporting code:</label>
                <select v-model="tax.w9FATCAExemptionCode" :disabled="isDisabled" name="W9FATCAExemptionCode_dropDownList" id="W9FATCAExemptionCode_dropDownList">
                    <option value="null">None</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                    <option value="M">M</option>
                </select>
            </div>
            <div class="formRow dividerRow txtCenter cell">
                <img src="/getattachment/93a496c7-38d0-438c-9885-8534fb108e86/SS4.jpg" class="SS4Img img-responsive">
            </div>
            <div class="formRow eAppSignature cell">
                <span class="req">*</span>
                <label for="W9ApplicantSignature_txtText">Digital Signature:</label>
                <input v-model="tax.w9ApplicantSignature" :disabled="isDisabled" name="W9ApplicantSignature_txtText" type="text" maxlength="200" id="W9ApplicantSignature_txtText" class="appSignature appSigIsManual" autocomplete="off" aria-describedby="W9Signature_error" :class="{ scripty: tax.w9SignatureCertification }">
                <div v-if="!validSignature" class="error" role="alert" id="W9Signature_error">Please enter your name above.</div>

                <div class="appSignatureCert checkbox">
                    <input v-model="tax.w9SignatureCertification" :disabled="isDisabled" id="W9SignatureCertification_checkbox" type="checkbox" aria-describedby="W9SignatureCertification_error">
                    <label for="W9SignatureCertification_checkbox">Enter your legal name above and check the box to digitally sign this form.</label>

                    <div v-if="!validSignatureCert" class="error signatureError" role="alert" id="W9SignatureCertification_error">Please check the box above to confirm your digital signature.</div>
                </div>
            </div>
        </div>

        <span class="error" v-if="specialError === true">Please provide standard English text characters only.</span>

        <div class="btn-actions">
            <button type="button" @click="$refs.uspsValidate.validate('back')" class=" btn-primary btnBack" title="Save the document and return to previous page">Save &amp; Back</button>
            <button type="button" @click="$refs.uspsValidate.validate('next')" class=" btn-primary btnNext" title="Save the document and proceed to next page">Save &amp; Next</button>
        </div>
        <USPSAddressCheckVue :mailingAddress="tax.w9StreetAddress" :city="tax.w9City" :state="tax.w9State" :zipcode="tax.w9Zip" :zipFour="tax.w9ZipPlus4" :allowMultiple="true" ref="uspsValidate"></USPSAddressCheckVue>
    </div>
</template>

<script>
import $ from 'jquery';
    import USPSAddressCheckVue from '../../Shared/USPS-AddressCheck.vue';

    export default {
        components: {
            USPSAddressCheckVue
        },
        props: {
            taxJson: String,
            principalsJson: String,
            readOnly: Boolean
        },
        data: function () {
            return {
                tax: {},
                principals: [],
                principalOptions: [],
                validFormOne: true,
                validW9Name: true,
                validLegalName: true,
                validStreet: true,
                validZip: true,
                validCity: true,
                validState: true,
                validClassification: true,
                validClassificationOther: true,
                validId: true,
                validIdType: true,
                validSignature: true,
                validSignatureCert: true,
                isDisabled: false,
                specialError: false
            };
        },
        mounted: function () {
            this.deserializeJson();
            this.isDisabled = this.readOnly;
        },
        methods: {
            deserializeJson() {
                this.tax = JSON.parse(this.taxJson);
                this.principals = JSON.parse(this.principalsJson);
                this.createPrincipalDropDown();
            },
            validate() {
                return this.validateStepOne();
            },
            saveNext() {
                if (this.validate()) {
                    this.$emit('onTaxSaveClick', { tax: this.tax, dir: 'next' });
                }
            },
            saveBack() {
                if (this.validate()) {
                    this.$emit('onTaxSaveClick', { tax: this.tax, dir: 'back' });
                }
            },
            validateStepOne() {
                this.validW9Name = !!this.tax.w9Name;
                this.validLegalName = !!this.tax.w9LegalName;
                this.validStreet = !!this.tax.w9StreetAddress;
                this.validZip = !!this.tax.w9Zip && this.zipValidation(this.tax.w9Zip)
                this.validCity = !!this.tax.w9City;
                this.validState = !!this.tax.w9State;
                this.validClassification = !!this.tax.w9TaxClassification;
                this.validClassificationOther = this.tax.w9TaxClassification != 'Other'
                    || (this.tax.w9TaxClassification == 'Other' && !!this.tax.w9TaxClassificationOther);

                const taxIDReg = /^\d+$/;

                this.validId = this.tax.w9TaxID.length === 9 && taxIDReg.test(this.tax.w9TaxID);
                // console.log(this.tax.w9TaxID.length)
                // console.log(this.tax.w9TaxID.length === 9)
                this.validIdType = this.tax.w9TaxTypeID != null && this.tax.w9TaxTypeID !== '';
                this.validSignature = !!this.tax.w9ApplicantSignature;
                this.validSignatureCert = !!this.tax.w9SignatureCertification;

                this.validFormOne = this.validW9Name &&
                    this.validLegalName &&
                    this.validStreet &&
                    this.validZip &&
                    this.validState &&
                    this.validClassification &&
                    this.validClassificationOther &&
                    this.validId &&
                    this.validIdType &&
                    this.validSignature &&
                    this.validSignatureCert;

                if (this.validateSpecial($('input[type=text]')) === false) {
                    this.specialError = true;
                    return false;
                } else {
                    this.specialError = false;
                }    

                return this.validFormOne;
            },
            createPrincipalDropDown() {
                // console.log(this.principals[0].principalEmail);
                // need to make sure a principal has been added first
                
                if (this.principals[0] !== undefined && this.principals[0] !== null) {
                    if (this.principals[0].principalEmail !== undefined && this.principals[0].principalEmail !== null) {
                        this.hasPrincipal = true;
                        this.principals.forEach((p) => {
                            let pName = p.principalFirstName + ' ' + p.principalLastName;
                            let id = p.principalInformationID;
                            let principalOption = { value: id, text: pName };
                            this.principalOptions.push(principalOption);
                        });
                    }
                }
            },
            uspsOpen() {
                $('#USPSmodal').foundation('open');
            },
            uspsClose() {
                $('#USPSmodal').foundation('close');
            },
            uspsVerified(msg) {
                this.tax.w9StreetAddress = msg.mailingAddress;
                this.tax.w9City = msg.city;
                this.tax.w9State = msg.state;
                this.tax.w9Zip = msg.zip;
                this.tax.w9ZipPlus4 = msg.zipFour;

                this.uspsClose();

                if (msg.passthrough === 'back') {
                    this.saveBack();
                } else {
                    this.saveNext();
                }
            }
        },
        computed: {}
    };
</script>
