// Global styles
import './styles/_lottery-common.scss';

import Vue from "vue";
import $ from 'jquery';

Vue.config.silent = false; //NOTE revert after testing

import Foundation from "@/foundation-sites/js/foundation.js";
Vue.use(Foundation);

import * as GmapVue from 'gmap-vue'
Vue.use(GmapVue, {
    load: {
      key: 'AIzaSyBPQS5zqrWLNMIm9XhtIzQJY-EQQr5Wu_I',
    },
    installComponents: true
  });

// Vue Cookies plugin
// from: github.com/cmp-cc/vue-cookies#readme
Vue.use(require('vue-cookies'));

Vue.config.productionTip = false;

// vueMixins becomes available to every component
import vueMixins from '@/vueMixins.js';
Vue.mixin(vueMixins);
import { load as loadToken } from "../Views/Shared/token-loader";
loadToken();

// Handle Videos
// from: https://github.com/nasa8x/v-video-embed
import Embed from 'v-video-embed'
Vue.use(Embed);

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.use(VueSlickCarousel)


// Date formatting      
Vue.use(require('vue-moment'));
// from https://www.npmjs.com/package/vue-moment
// see link above for more formatting examples

Vue.filter('pickToD', function (value) {
    if (value == '1') {
        return 'Midday'
    } else if (value == '2') {
        return 'Evening';
    } else {
        return '';
    }
});

// handle jackpot format and labeling
Vue.filter('jackpotFormat', function(jpValue) {
    var formattedValue = '$';
    // test for billion
    if (jpValue > 999) {
        formattedValue = formattedValue + (Math.floor(100*(jpValue/1000))/100) + ' Billion';
    }
    formattedValue = formattedValue + jpValue + ' Million';
    return formattedValue;
});


// from: https://github.com/kaorun343/vue-youtube-embed#readme
import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

// import FoundationDatePicker from "foundation-datepicker/js/foundation-datepicker.js";
// Vue.use(FoundationDatePicker);

// Load Components JS [Pass State here?]
import { loadComponents } from "../Views/component-loader";
import store from '../src/store';
setTimeout(() => {
    loadComponents(store);
},100);

import Password from 'vue-password-strength-meter';
Vue.use(Password)


// register jw pagination component globally
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);

// https://github.com/safrazik/vue-file-agent
import VueFileAgent from 'vue-file-agent';
// eslint-disable-next-line
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(VueFileAgent);


// https://github.com/DerYeger/yeger/tree/main/packages/vue2-masonry-wall
import MasonryWall from '@yeger/vue2-masonry-wall'
Vue.use(MasonryWall)

//https://euvl.github.io/vue-js-modal/Installation.html#client
import VModal from 'vue-js-modal'
Vue.use(VModal)

// Init jQuery items
$(document).foundation();
// $('.fdatepicker').fdatepicker();