<template>
    <div class="lo_container" id="gameStage">
        <!-- MENU LINKS -->
        <ul class="lo_Menu noGutter">
            <li class="lo_MenuItem first"><a class="lo_MenuLink" href="/games/the-lucky-one">How To Play</a></li>
            <li class="lo_MenuItem"><a class="lo_MenuLink" href="/games/the-lucky-one#tab-panel2">Odds &amp; Payouts</a>
            </li>
            <li class="lo_MenuItem"><a class="lo_MenuLink" href="/games/the-lucky-one#tab-panel3">The Lucky One FAQs</a>
            </li>
            <li class="lo_MenuItem last"><a class="lo_MenuLink"
                    href="/winning-numbers/the-lucky-one/the-lucky-one-winning-numbers-archive">The Lucky One Winning
                    Numbers Archive</a></li>
        </ul>
        <!-- Lucky One Draw Animation -->

        <div class="lo_content">
            <div class="lo_logo">
                <img alt="The Lucky One" class="img-responsive"
                    src="/getattachment/5ec0c7de-d201-4a80-843e-6461f6ede5ab/logo-LuckyOne-510x280.png?lang=en-US" />
            </div>

            <div class="lo_drawingForm">
                <h2>Watch The Lucky One drawings online!</h2>
                <button class="button" id="getLatestDraw" type="button">WATCH THE LATEST DRAW</button>

                <h3>If you would like to WATCH A SPECIFIC DRAW:</h3>

                <div class="lo_SpecificNumber">
                    <label>Enter a Draw Number:</label>
                    <input id="DrawID" maxlength="10" type="text" value="" />
                </div>

                <div class="lo_SpecificQuant">
                    <label>How Many Consecutive Draws Do You Want to Watch?</label>
                    <select id="Quantity">
                        <option value="1" selected>One Draw</option>
                        <option value="2">Two Draws</option>
                        <option value="3">Three Draws</option>
                        <option value="4">Four Draws</option>
                        <option value="5">Five Draws</option>
                        <option value="10">Ten Draws</option>
                        <option value="20">Twenty Draws</option>
                    </select>
                    <button class="button" id="getLatestX" type="button">GO</button>
                    <div class="numberError" id="drawError">&nbsp;</div>
                </div>
            </div>
        </div>

        <div class="lo_row">
            <span class="drawIDwrap" id="drawIDwrap">Drawing Number: <span class="drawID" id="drawID"></span></span>
        </div>

        <div class="lo_row" id="gameboard">
            <div class="skewMe1" id="game-28">
                <div>28</div>
            </div>

            <div class="skewMe2" id="game-9">
                <div>9</div>
            </div>

            <div class="skewMe1" id="game-26">
                <div>26</div>
            </div>

            <div class="skewMe2" id="game-30">
                <div>30</div>
            </div>

            <div class="skewMe1" id="game-11">
                <div>11</div>
            </div>

            <div class="skewMe2" id="game-7">
                <div>7</div>
            </div>

            <div class="skewMe1" id="game-20">
                <div>20</div>
            </div>

            <div class="skewMe2" id="game-32">
                <div>32</div>
            </div>

            <div class="skewMe1" id="game-17">
                <div>17</div>
            </div>

            <div class="skewMe2" id="game-5">
                <div>5</div>
            </div>

            <div class="skewMe1" id="game-22">
                <div>22</div>
            </div>

            <div class="skewMe2" id="game-34">
                <div>34</div>
            </div>

            <div class="skewMe1" id="game-15">
                <div>15</div>
            </div>

            <div class="skewMe2" id="game-3">
                <div>3</div>
            </div>

            <div class="skewMe1" id="game-24">
                <div>24</div>
            </div>

            <div class="skewMe2" id="game-36">
                <div>36</div>
            </div>

            <div class="skewMe1" id="game-13">
                <div>13</div>
            </div>

            <div class="skewMe2" id="game-1">
                <div>1</div>
            </div>

            <div class="skewMe1" id="game-10">
                <div>10</div>
            </div>

            <div class="skewMe2" id="game-25">
                <div>25</div>
            </div>

            <div class="skewMe1" id="game-29">
                <div>29</div>
            </div>

            <div class="skewMe2" id="game-12">
                <div>12</div>
            </div>

            <div class="skewMe1" id="game-8">
                <div>8</div>
            </div>

            <div class="skewMe2" id="game-19">
                <div>19</div>
            </div>

            <div class="skewMe1" id="game-31">
                <div>31</div>
            </div>

            <div class="skewMe2" id="game-18">
                <div>18</div>
            </div>

            <div class="skewMe1" id="game-6">
                <div>6</div>
            </div>

            <div class="skewMe2" id="game-21">
                <div>21</div>
            </div>

            <div class="skewMe1" id="game-33">
                <div>33</div>
            </div>

            <div class="skewMe2" id="game-16">
                <div>16</div>
            </div>

            <div class="skewMe1" id="game-4">
                <div>4</div>
            </div>

            <div class="skewMe2" id="game-23">
                <div>23</div>
            </div>

            <div class="skewMe1" id="game-35">
                <div>35</div>
            </div>

            <div class="skewMe2" id="game-14">
                <div>14</div>
            </div>

            <div class="skewMe1" id="game-2">
                <div>2</div>
            </div>

            <div class="skewMe2" id="game-27">
                <div>27</div>
            </div>
        </div>

        <div class="lo_row">
            <div class="highBox" id="highBox">HIGH</div>

            <div class="lowBox" id="lowBox">LOW</div>

            <div class="spacer">&nbsp;</div>

            <div class="evenBox" id="evenBox">EVEN</div>

            <div class="oddBox" id="oddBox">ODD</div>

            <div class="clearfix">&nbsp;</div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
    components: {},
    props: {},
    data() {
        return {
            apiToken: null,
            LODrawings: {}
        }
    },
    mounted: function () {
        var _this = this;

        this.getToken();

        //+++++++++++++++++++++++++++++++ JQUERY Old Game
        function simulateLuckyOneData(DrawID, Quantity) {
            var drawResults = [];

            for (var x = 0; x < Quantity; x += 1) {
                var rando = 1 + Math.round(Math.random() * 35);
                var date = Math.round(new Date().getTime() / 1000);

                var result = {
                    "Number": rando,
                    "DrawNumber": x,
                    "Date": date
                };

                drawResults.push(result);
            }

            return drawResults;
        }

        var PageController = function () {
            this.dirtyContext = false;

            this.setError = function (message) {
                document.getElementById("drawError").innerHTML = message;
            }

            this.setEven = function () {
                var evenBox = document.getElementById("evenBox");
                evenBox.classList.add("winner");

                var oddBox = document.getElementById("oddBox");
                oddBox.classList.remove("winner");
            };

            this.setOdd = function () {
                var oddBox = document.getElementById("oddBox");
                oddBox.classList.add("winner");

                var evenBox = document.getElementById("evenBox");
                evenBox.classList.remove("winner");
            };

            this.setLow = function () {
                var lowBox = document.getElementById("lowBox");
                lowBox.classList.add("winner");

                var highBox = document.getElementById("highBox");
                highBox.classList.remove("winner");
            };

            this.setHigh = function () {
                var highBox = document.getElementById("highBox");
                highBox.classList.add("winner");

                var lowBox = document.getElementById("lowBox");
                lowBox.classList.remove("winner");
            };

            this.setDrawID = function (playID) {

                var drawIDwrap = document.getElementById("drawIDwrap");
                drawIDwrap.classList.remove("show");

                document.getElementById("drawID").innerHTML = playID;

                drawIDwrap.classList.add("show");
            }

            this.resetGameBoard = function () {
                var gameBalls = document.querySelectorAll(".game-ball");
                var numberOfGameBalls = gameBalls.length;

                if (numberOfGameBalls > 0) {
                    for (var x = 0; x < numberOfGameBalls; x += 1) {
                        var gameBall = gameBalls[x];
                        gameBall.remove();
                    }
                }
                document.getElementById("oddBox").classList.remove("winner");
                document.getElementById("evenBox").classList.remove("winner");
                document.getElementById("lowBox").classList.remove("winner");
                document.getElementById("highBox").classList.remove("winner");
                document.getElementById("drawIDwrap").classList.remove("show");
                document.getElementById("drawID").innerHTML = '';
                document.getElementById("drawError").innerHTML = '';

                var tiles = document.getElementById("gameboard").children;
                var numberOfTiles = tiles.length;

                for (var y = 0; y < numberOfTiles; y += 1) {
                    var tile = tiles.item(y);
                    tile.classList.remove("winner");
                }

                this.dirtyContext = false;
            };

            this.setContextDirty = function () {
                this.dirtyContext = true;
            };
        };

        var G_PageController = new PageController();

        var AnimatableBallClass = function () {
            this.createGameBall = function () {
                var gameBall = document.createElement("div");
                gameBall.classList.add("game-ball");

                var gameBallImage = document.createElement("img");
                gameBallImage.class = "img-responsive";
                gameBallImage.src = "/getattachment/366a018e-4f1b-4d9a-b615-4dc15b780a5e/LuckyOne-gameball.png?lang=en-US";
                gameBall.alt = "game ball";

                gameBall.appendChild(gameBallImage);

                return gameBall;
            };



            this.animateGameBallToNumber = function (gameBall, number) {
                var tile = document.getElementById("game-" + number);

                gameBall.style.left = (tile.offsetLeft + (70 / 2) - (35 / 2)) + "px";
                gameBall.style.top = "0";

                gameBall.style.transition = "top 1s ease, left 1s ease";
                gameBall.style.top = (tile.offsetTop + 40) + "px";

                window.setTimeout(function () {
                    tile.classList.add("winner");

                    if (number % 2 === 0) {
                        G_PageController.setEven();
                    } else {
                        G_PageController.setOdd();
                    }

                    if (number <= 18) {
                        G_PageController.setLow();
                    } else {
                        G_PageController.setHigh();
                    }

                }, 1000);

                G_PageController.setContextDirty();
            }
        };

        function initLuckyOnePlay(drawData) {
            // console.log(drawData);
            var playData = drawData;
            var playTiming = 0;

            for (var pd_Idx = 0; pd_Idx < playData.length; pd_Idx += 1) {
                var play = playData[pd_Idx];

                if (play.error !== undefined) {
                    G_PageController.setError(play.error);
                } else {

                    SetUpAnimation(play, playTiming);

                    // console.log(play.Number);
                    playTiming += 4500;

                }
            }
        }


        function simulatePlay() {
            var playData = simulateLuckyOneData(34343434, 1);
            var playTiming = 0;

            for (var pd_Idx = 0; pd_Idx < playData.length; pd_Idx += 1) {
                var play = playData[pd_Idx];
                SetUpAnimation(play, playTiming);

                playTiming += 4500;
            }
        }

        function simulateMultiPlay() {
            var playData = simulateLuckyOneData(34343434, 1 + (Math.random() * 5));
            var playTiming = 0;

            for (var pd_Idx = 0; pd_Idx < playData.length; pd_Idx += 1) {
                var play = playData[pd_Idx];
                SetUpAnimation(play, playTiming);

                playTiming += 4500;
            }
        }

        function SetUpAnimation(playInfo, playTiming) {
            window.setTimeout(function () {
                if (G_PageController.dirtyContext) {
                    G_PageController.resetGameBoard();
                }

                G_PageController.setDrawID(playInfo.DrawNumber);

                var ABC = new AnimatableBallClass();
                var gameBall = ABC.createGameBall();

                document.getElementById("gameStage").appendChild(gameBall);

                ABC.animateGameBallToNumber(gameBall, playInfo.Number);
            }, playTiming);
        }

        function getLuckyOneData(options, callback) {

            var DrawID = options.DrawID,
                Quantity = options.Quantity,
                service = 'latest',
                params = {},
                limitData = false,
                dataArray = true,
                response = '';

            // console.log(options.DrawID +', '+ options.Quantity);

            // check if values were passed
            if (DrawID === "" && Quantity === "") {
                // console.log('-- 11 --');
                // If no Quantity or DrawID are supplied then only the most recent draw will be returned.
                dataArray = false;

                _this.fetchGetLatestDraws().then(res => {
                    var onlyFirst = [res[0]];
                    // console.log(onlyFirst);
                    const newArr = onlyFirst.map(({ id, numbers, externalDrawId, drawDate, drawGameId }) => ({
                        //"id": id,
                        "DrawNumber": numbers[0].externalDrawId,
                        "Date": Math.floor(Date.parse(drawDate) / 1000).toString(),
                        "Numbers": [numbers[0].value]
                    }))

                    // console.log(newArr);

                    response = formatDrawData(newArr);
                    callback(response);
                })


            } else if (DrawID === "" && Quantity !== "") {
                // console.log('-- 22 --');
                // Providing Quantity but no DrawID will return data for the most recent X draws.
                // service = '/cmspages/mobileappwebservice.asmx/GetLast180LuckyOneResults';
                limitData = true;

            } else if (Quantity === "" && DrawID !== "") {
                // console.log('-- 33 --');
                // Providing DrawID but no Quantity will return data for a single draw.
                // service = '/cmspages/mobileappwebservice.asmx/GetLuckyOneByDrawNumber';
                params.DrawID = DrawID;
                params.howMany = 0;

            } else {
                // console.log('-- 44 --');
                // If both values are provided then use them with:

                params.DrawID = DrawID;
                params.howMany = Quantity; // Quantity - 1

                _this.fetchSearchByDrawNumberRange(params).then(res => {
                    // console.log('----', res);

                    const newArr = res.map(({ id, numbers, externalDrawId, drawDate, drawGameId }) => ({
                        //"id": id,
                        "DrawNumber": externalDrawId,
                        "Date": Math.floor(Date.parse(drawDate) / 1000).toString(),
                        "Numbers": [numbers[0].value]
                    }))

                    // console.log(newArr);

                    response = formatDrawData(newArr);
                    callback(response);
                })
            }

            // format results
            var formatDrawData = function (DrawData) {
                // {
                // 	"Number":number,
                // 	"DrawNumber":number,
                // 	"Date":1515186450966
                // }

                var dataCount = 0,
                    dataToSend = [];

                // console.log(DrawData);
                if (DrawData.length < 1) {
                    var response = {};
                    response.error = 'Error: No data available for provided Draw Number.';

                    dataToSend.push(response);
                    return dataToSend;
                }


                if (dataArray) {
                    // reverse so most recent draw is first
                    DrawData.reverse();

                    $.each(DrawData, function (index, el) {
                        var thisDrawObj = DrawData[index];
                        if (limitData && dataCount < Quantity) {
                            var drawNumber = thisDrawObj.Numbers[0],
                                drawID = thisDrawObj.OlcId,
                                drawDate = thisDrawObj.Date,
                                thisDraw = {};

                            drawDate = Number(drawDate.substring(6, 19));

                            // console.log(drawNumber, drawID, drawDate);

                            thisDraw.Number = drawNumber;
                            thisDraw.DrawNumber = drawID;
                            thisDraw.Date = drawDate;

                            dataToSend.push(thisDraw);

                        } else if (!limitData) {
                            var drawNumber = thisDrawObj.Numbers[0],
                                drawID = thisDrawObj.DrawNumber,
                                drawDate = thisDrawObj.Date,
                                thisDraw = {};

                            drawDate = Number(drawDate.substring(6, 19));

                            // console.log(drawNumber, drawID, drawDate);

                            thisDraw.Number = drawNumber;
                            thisDraw.DrawNumber = drawID;
                            thisDraw.Date = drawDate;

                            dataToSend.push(thisDraw);
                        }
                        dataCount++;
                    });
                } else {
                    var thisDraw = {},
                        drawNumber = DrawData[0].Numbers,
                        drawID = DrawData[0].DrawNumber,
                        drawDate = DrawData[0].Date;

                    drawDate = Number(drawDate.substring(6, 19));

                    thisDraw.Number = drawNumber;
                    thisDraw.DrawNumber = drawID;
                    thisDraw.Date = drawDate;

                    dataToSend.push(thisDraw);
                }

                dataToSend.reverse();
                return dataToSend;
            };

        }

        // this starts it all
        var lo_Options = {};

        $('#getLatestDraw').on('click', function (event) {
            lo_Options.DrawID = '';
            lo_Options.Quantity = '';

            getLuckyOneData(lo_Options, initLuckyOnePlay);
        });

        $('#getLatestX').on('click', function (event) {
            var drawID = $('#DrawID').val(),
                quantity = $('#Quantity option:selected').val();
            // console.log(drawID, quantity);
            lo_Options.DrawID = drawID;
            lo_Options.Quantity = quantity;

            getLuckyOneData(lo_Options, initLuckyOnePlay);

        });
    },
    methods: {
        // Get Token
        getToken() {
            this.getAPItoken()
                .then((token) => {
                    this.apiToken = token;
                });
        },
        // CLICK EVENTS ********************************************************
        fetchGetLatestDraws() {
            // hit API
            // console.log(this.apiToken);
            var urlProtocol = this.getProtocol();
            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/TheLuckyOne/GetLatestDraws", {
                headers: {
                    'Authorization': 'Bearer ' + this.apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;
                    return res
                });
        },
        fetchSearchByDrawNumberRange(params) {
            // console.log('DrawID', params);
            // hit API
            // console.log(this.apiToken);
            var urlProtocol = this.getProtocol();
            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/TheLuckyOne/SearchByDrawNumberRange?externalDrawId=" + params.DrawID + "&NumberOfDraws=" + params.howMany, {
                headers: {
                    'Authorization': 'Bearer ' + this.apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;
                    return res
                });
        }

    }
}
</script>

<style lang="css"></style>