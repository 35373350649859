<template>
    <div class="featured-rewards-header">
        <img :src="background" alt="test!">
        <div class="featured-rewards-header-f" v-if="!isUserLogged"><a href="/mylotto-rewards/my-account/register" class="button btn_link btn_Register btn-tertiary">Register Now</a></div>
    </div>
</template>

<script>
// import moment from 'moment'
export default {
    props: {
        contentKey: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            isUserLogged: false,
            background: '/getattachment/679ffcc4-5240-43f0-8803-6066920c393a/new-at-mlr-banner.jpg'
        }
    },
    mounted: function () {
        this.isUserLogged = this.isUserAuthed();
        this.init();
    },
    methods: {
        init() {
            if (this.contentKey !== '') {
                this.getToken();
            }
            console.log(this.contentKey);
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(apiToken) {
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=' + this.contentKey, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken
                }
            })
                .then(response => response.json())
                .then(json => {
                    let element = JSON.parse(json.data[0].centralContent);

                    // localStorage.setItem('signInRegister-eApp', JSON.stringify(element));

                    this.logInText = element.logIn;
                    this.registerText = element.register;
                    this.registerUrl = element.registerurl;
                    this.loadComponent();

                })
        }
    },
    computed: {
    }
};
</script>

<style lang="scss" scoped>
.featured-rewards-header {
    margin-bottom: 20px;
    position: relative;

    img{
        width: 100%;
    }
    .featured-rewards-header-f {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 20px;
        display: flex;
        justify-content: center;

        a {
            margin-bottom: 0;
        }
    }
    .btn-tertiary {
        background-color: #5161ac;
    }
}
</style>