<template>
    <div class="grid-container grid-x grid-margin-x grid-margin-y">
        <div   
            v-for="promotion in promotions" v-bind:key="promotion.id"
            class="cell medium-6 small-12"
        >
            <div class="promotion-block">
                <a :href="`${promotion.pageURL}?id=${promotion.promotionID}`">
                    <img :src="`/getattachment/${promotion.secondChancePromoImg}/attachment.aspx`" :alt="`${promotion.promotionName}`" />
                </a>
            </div>     
        </div>
    </div>
</template>

<script>
   // import moment from 'moment'
    export default {
        data: function () {
            return {
               promotions: []
            }
        },
        mounted: function () {
            this.getToken()
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/SecondChance/Promotion/Get-All-Publish-Promotions", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    this.promotions = results;

                    console.log(results)
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
        },
        computed: {
        }
    };
</script>

