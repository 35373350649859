<template>
    <div class="mlrHomeHeader">
        <div class="mlrIntro cf" v-if="!isUserLogged">
            <h1>Play&nbsp;Games. Earn&nbsp;Points. Get&nbsp;Rewarded.</h1>
            <p class="intro">Sign-in or register to enter your non-winning ticket numbers or promotion codes.</p>
            <a class="button btn_popupLogIn" href="/modals/Log-In">Sign In</a>
            <a class="button btn_joinNow" href="/myLotto/My-Account/Register" id="globalRegister">Register</a>
        </div>

        <div class="modulePrpl quickTicketEntry cf">
            <div :class="contentLeftClasses" v-html="contentLeft"></div>
            <div :class="contentRightClasses" v-html="contentRight"></div>
        </div>

        <PromotionCodeEntry v-if="isUserLogged" contentKey="promotion-code-entry-text" requireDigits="3"></PromotionCodeEntry>
    </div>
</template>

<script>
    import PromotionCodeEntry from '../../../TicketEntry/PromotionCodeEntry.vue';

    export default {
        components:{
            PromotionCodeEntry
        },
        data: function () {
            return {
                username: '',
                token: '',
                pointBalance: '',
                entered: '',
                footerlinks: {
                    link1text: '',
                    link1url: '',
                    link2text: '',
                    link2url: ''
                },
                heading: '',
                logo: '',
                nonauthheadline: '',
                nonauthsubhaedline: '',
                placeholder: '',
                pointsbuttontext: '',
                pointsbuttonurl: '',
                profilebutton: '',
                profilebuttonurl: '',
                submit: '',
                isUserLogged: false,
                contentLeft: '',
                contentRight: '',
                contentLeftClasses: '',
                contentRightClasses: ''

            }
        },
        mounted: function () {
            
            let apiToken = this.$cookies.get('apiToken');
            // console.log(apiToken);
            if(apiToken.user.username == "mobilepublic@mtllc.com"){
                this.isUserLogged = false;
                this.getToken();
                
            }else{
                this.isUserLogged = true
                
                this.getToken();

                this.username = apiToken.user.firstName;
                this.pointBalance = apiToken.user.pointBalance.balance;
            }
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.token = token;
                })
                .then(() => {
                    this.fetchData(this.token);
                })
            },
            fetchData(token) {
                var apiToken = token;

                return fetch(process.env.VUE_APP_APIPROTOCOL +process.env.VUE_APP_APIURL + "/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=RewardsLandingGreetingNoEntry", {
                        headers: {
                            Authorization: "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let res = json.data;
                    let greetingContent = res[0].centralContent;
                    let parsedContent = JSON.parse(greetingContent)
                    
                    console.log(parsedContent)

                    this.pointsbuttontext = parsedContent.pointsbuttontext;
                    this.pointsbuttonurl = parsedContent.pointsbuttonurl;
                    this.profilebutton = parsedContent.profilebutton;
                    this.profilebuttonurl = parsedContent.profilebuttonurl;
                    
                    this.footerlinks.link1text = parsedContent.footerlinks.link1text;
                    this.footerlinks.link2text = parsedContent.footerlinks.link2text;
                    this.footerlinks.link1url  = parsedContent.footerlinks.link1url;
                    this.footerlinks.link2url  = parsedContent.footerlinks.link2url;

                    this.contentLeft = parsedContent.contentleft;
                    this.contentLeftClasses = parsedContent.contentleftclasses;
                    this.contentRight = parsedContent.contentright;
                    this.contentRightClasses = parsedContent.contentrightclasses;


                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
           
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
    .modulePrpl {
         .quickEntryGreeting {
            .button {
                display: inline-flex;
                align-items: center;
            }
         }
    }
    .mlrHomeHeader .quickTicketEntry .quickEntryGreeting p {
        margin-bottom: 0px;
    }
    .input-group {
        width: 100%;
    }
</style>