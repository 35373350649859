import Vue from "vue";

import OnlineClaimForm from "./OnlineClaimForm.vue";

export function load() {
    const selector = "online-claim-form";

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return;
  }

  // Create a new Vue app with the imported Home component
  new Vue({
    render: createElement =>
      createElement(OnlineClaimForm, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}