import Vue from "vue";

import ProgressiveJackpotHome from "./ProgressiveJackpotHome.vue";

export function load(payload) {
	const selector = "progressive-jackpot-home";
	const store = payload;

	// Is the custom Vue root element in the DOM?
	if (!document.querySelector(selector)) {
	return; // go no further
	}

  // Create a new Vue app with the imported component
	new Vue({
		store,
		render: createElement =>
			createElement(ProgressiveJackpotHome, {
			props: {
				...window[selector]
			}
		}),
	}).$mount(selector);
}