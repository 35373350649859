<template>
    <div class="eAppUpdates">
        <h2>Welcome</h2>
        <div class="grid-x grid-margin-x welcomeIntro">
            <div class="columns cell small-12 medium-12 a" v-html="welcomePublic" v-if="!isUserLogged"></div>
            <div class="columns cell small-12 medium-6 large-5 large-offset-1 xlarge-4 xlarge-offset-2 aa" v-html="logInPanel" v-if="!isUserLogged"></div>
            <div class="columns cell small-12 medium-6 large-5 xlarge-4 ab" v-html="registerPanel" v-if="!isUserLogged"></div>

            <div class="columns cell small-12 medium-12 b" v-html="welcomeAuthed" v-if="isUserLogged"></div>
        </div>
        <MyLicenses v-if="isUserLogged" :noDataMessage="noData"></MyLicenses>

    </div>
</template>
<script>
import MyLicenses from '../../../ViewComponents/Eapplications/Listings/my-licenses.vue';

export default {
    components: {
        MyLicenses
    },
    data: function () {
        return {
            isUserLogged: false,
            userEappRole: [],
            userId: 0,
            isLoading: false,
            pageContent: {},
            contentKey: 'updates-renewals-landing',
            noData: '',
            welcomePublic: '',
            welcomeAuthed: '',
            logInPanel: '',
            registerPanel: ''
        }
    },
    mounted: function () {
        this.getContent();
    },
    methods: {
        getContent() {
            let cookieContent = JSON.parse(localStorage.getItem('ur_content'));

            let apiToken = this.$cookies.get('apiToken');

            this.userEappRole = this.getUserRoles(apiToken.user.listOfRoles);
            // console.log('userEappRole--->>>>', this.userEappRole);

            if (cookieContent !== undefined && cookieContent !== "" && cookieContent !== null) {
                this.pageContent = JSON.parse(cookieContent);
                this.setTheStage();
            } else {
                this.getToken();
            }
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(apiToken) {
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=' + this.contentKey, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken
                }
            })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent;

                    localStorage.setItem('ur_content', JSON.stringify(element));

                    this.pageContent = JSON.parse(element);
                    this.setTheStage();

                })
        },
        setTheStage() {
            this.isUserLogged = this.isUserAuthed();
            this.noData = this.pageContent.urNoLicenses;
            this.welcomePublic = this.pageContent.urWelcomePublic;
            this.welcomeAuthed = this.pageContent.urWelcomeAuthed;
            this.logInPanel = this.pageContent.urLogIn;
            this.registerPanel = this.pageContent.urRegister;

            // console.log('isUserLogged ------>>>>', this.isUserLogged);
        },
        getUserRoles(roles) {
            return roles.some(role => role == 'eApp-CentralLicensingManager' || role == 'eApp-CentralSalesManager' || role == 'eApp-ChainApplicant' || role == 'eApp-ChainManager'
            || role == 'eApp-ChainSalesRep' || role == 'eApp-EFTReviewer' || role == 'eAppPrincipal' || role == 'eApp-RegionalManager' || role == 'eApp-Retailer'
             || role == 'eAppDistrictRep' || role == 'eApp-Security');
        }
    }
}
</script>