import Vue from "vue";

import UpdatesRenewalsLanding from "./updates-renewals-landing.vue";

export function load() {
    const selector = "updates-renewals-landing";

    // Is the custom Vue root element in the DOM?
    if (!document.querySelector(selector)) {
        return; // go no further
    }

    // Create a new Vue app with the imported component
    new Vue({
        render: createElement =>
            createElement(UpdatesRenewalsLanding, {
                props: {
                    ...window[selector]
                }
            }),
    }).$mount(selector);
}
