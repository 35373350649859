<template>
    <section class="welcome_buttons">
        <SignInRegister></SignInRegister>
        <div class="signInRegister_btns gridRow"><a class="btn button btn_purple" href="/MyLotto-Rewards/Help">FAQ</a></div>
    </section>
</template>

<script>
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components:{
            SignInRegister
        },
        data: function () {
            return {
                token: '',
                isUserLogged: false
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();            
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    // this.fetchData(token);
                    this.token = token;
                    this.addLogin();
                });
            },
            addLogin() {
                if (isUserLogged === true) {
                    let doc = document.getElementsByClassName('welcome_buttons');

                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>