<template>
    <div class="findretailer_picker">
        <h3>Find a Retailer of the Week</h3>
        <datepicker :disabledDates="disabledDates" placeholder="Select Date" name="inputRetailerDate" :format="customFormatter"></datepicker>
        <button class="button" @click="go">GO</button>
        <div v-if="showError" class="error">Please select a date.</div>
    </div>
</template>

<script>
    import Datepicker from "vue-moment-datepicker";
    import moment from 'moment';

    export default {
        
        props: ['subtractDays'],
        components: {
            Datepicker
        },
        data: function () {
            return {
                disabledDates: {
                    from: new Date()
                },
                showError: false,
                dateFrom: ''
            }
        },
        mounted: function () {
            // init datepicker
        },
        methods: {
            // append date to URL string and go to location
            // go to: /winning-numbers/winning-numbers-archive
            customFormatter(date) {
                return moment(date).format('YYYY-MM-DD');
            },
            go: function () {
                let dateTo = document.querySelector("input[name=inputRetailerDate]").value;
                let dateFrom = moment(dateTo).subtract(this.subtractDays, "days").format('YYYY-MM-DD');
                // reformat date
                let pagePath = '?articleDateFrom='+ dateFrom +'&articleDateTo='+  dateTo;

                if (dateFrom !== '') {
                    window.location.assign(pagePath);
                } else {
                    this.showError = true;
                }
            }
            
        }
    };
</script>

<style lang="scss" scoped>

</style>