<template>
    <div>
        <div class="eAppList cf" v-if="isUserAllowed">
            <h4>Retailer Updates</h4>
            <div class="eAppListCount "></div>

            <ul class="table" v-if="gridData.length > 0 && !isLoading">
                <li class="theader">
                    <div class="table_header link" @click="sortListBy(1)"><span>Agent Number</span></div>
                    <div class="table_header link" @click="sortListBy(2)"><span>Business Name</span></div>
                    <div class="table_header link" @click="sortListBy(3)"><span>Address</span></div>
                    <div class="table_header"><span>Notifications</span></div>
                    <div class="table_header"><span>Actions</span></div>
                </li>
                <li v-for="(row, index) in gridData" :key="index" :id="'appRow'+index" class="detailRow">
                    <div class="table_row" :id="'qvOpen'+index">
                        <div class="table_small">
                            <div class="table_cell"><span class="tr">Agent Number</span></div>
                            <div class="table_cell"><span>{{ row['agentNumber'] }}</span></div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell"><span class="tr">Business Name</span></div>
                            <div class="table_cell"><span>{{ row['businessName'] }}</span></div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell"><span class="tr">Address</span></div>
                            <div class="table_cell">
                                <span>{{ row['address'] }}<br />{{ row['city'] }}, {{ row['state'] }} {{ row['zip'] }} {{
                                    row['zip4'] }}</span>
                            </div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell"><span class="tr">Notifications</span></div>
                            <div  class="table_cell">
                                <span v-if="row['updateUpdateSubmitted'] == 'true' || row['renUpdateSubmitted'] == 'true'">You have unsubmitted elements.</span>
                                <span v-else>&nbsp;</span>
                            </div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell"><span class="tr">Actions</span></div>
                            <div class="table_cell">
                                <span>
                                    <a :href="'/eApplications/updates/license-detail?an=' + row['agentNumber']">Full View</a>
                                    <br />
                                    <a v-if="row['baAgentNumber'] !== null 
                                               || row['biAgentNumber'] !== null
                                               || row['eftAgentNumber'] !== null
                                               || row['w9AgentNumber'] !== null
                                               || row['renAgentNumber'] !== null" 
                                        @click="showQuickView(index)">Quick View</a>
                                </span>
                            </div>
                        </div>
                    </div>

                    <table v-if="row['baAgentNumber'] !== null 
                           || row['biAgentNumber'] !== null
                           || row['eftAgentNumber'] !== null
                           || row['w9AgentNumber'] !== null
                           || row['renAgentNumber'] !== null" class="quick_view table" :id="'qv' + index">
                        <caption colspan="4" class="qvHeading">
                            <span class="quickViewClose" @click="hideQuickView(index)">Close <span class="icon-times-circle"></span></span>
                            <h5>
                                {{ row['businessName'] }}
                                <span class="quickViewAppNumber">({{ row['agentNumber'] }})</span>
                                <a :href="'/eApplications/updates/license-detail?an=' + row['agentNumber']">Full View</a>
                            </h5>
                        </caption>
                        <tr>
                            <th><span>Change Request Type</span></th>
                            <th><span>Batch Number</span></th>
                            <th><span>Status</span></th>
                            <th><span>Part of Renewal?</span></th>
                        </tr>
                        <tr v-if="row['baAgentNumber'] !== null">
                            <td><span>Address relocation Change Request</span></td>
                            <td><span>{{ row['baBatchNumber'] }}</span></td>
                            <td><span v-html="baStatus(row['baElementStatus'])"></span></td>
                            <td><span v-if="row['baIsPartOfARenewal']" class="icon-check-circle"></span></td>
                        </tr>
                        <tr v-if="row['biAgentNumber'] !== null">
                            <td><span>Business Information Change Request</span></td>
                            <td><span>{{ row['biBatchNumber'] }}</span></td>
                            <td><span v-html="biw9renStatus(row['biElementStatus'])"></span></td>
                            <td><span v-if="row['biIsPartOfARenewal']" class="icon-check-circle"></span></td>
                        </tr>
                        <tr v-if="row['eftAgentNumber'] !== null">
                            <td><span>EFT Information Change Request</span></td>
                            <td><span>{{ row['eftBatchNumber'] }}</span></td>
                            <td><span v-html="eftprinStatus(row['eftElementStatus'])"></span></td>
                            <td><span v-if="row['eftIsPartOfARenewal']" class="icon-check-circle"></span></td>
                        </tr>
                        <tr v-if="row['prinAgentNumber'] !== null">
                            <td><span>Principal Change Request</span></td>
                            <td><span>{{ row['prinBatchNumber'] }}</span></td>
                            <td><span v-html="eftprinStatus(row['prinElementStatus'])"></span></td>
                            <td><span v-if="row['prinIsPartOfARenewal']" class="icon-check-circle"></span></td>
                        </tr>
                        <tr v-if="row['w9AgentNumber'] !== null">
                            <td><span>Legal Information Change Request</span></td>
                            <td><span>{{ row['w9BatchNumber'] }}</span></td>
                            <td><span v-html="biw9renStatus(row['w9ElementStatus'])"></span></td>
                            <td><span v-if="row['w9IsPartOfARenewal']" class="icon-check-circle"></span></td>
                        </tr>
                        <tr v-if="row['renAgentNumber'] !== null">
                            <td><span>Renewal - no Changes</span></td>
                            <td><span>{{ row['renBatchNumber'] }}</span></td>
                            <td><span v-html="biw9renStatus(row['renElementStatus'])"></span></td>
                            <td></td>
                        </tr>
                    </table>
                </li>
            </ul>

            <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

            <div class="InfoLabel alert-message cf" v-if="gridData.length == 0 && !isLoading" v-html="noDataMessage"></div>
        </div>

    </div>
</template>

<script>
import { HollowDotsSpinner } from 'epic-spinners';
export default {
    components: {
        HollowDotsSpinner
    },
    props: {
        noDataMessage: String,
    },
    data() {
        return {
            isUserAllowed: false,
            isLoading: false,
            userId: 0,
            userEmail: '',
            token: '',
            validRoles: ['eAppPrincipal', 'eAppPrincipalUpdates', 'eApp-Retailer', 'eApp-ChainApplicant'],
            gridData: [],
            sortOrder: 'asc',
            sortBy: 1
        }
    },
    mounted: function () {
        this.init();
    },
    methods: {
        init() {
            this.validRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.isUserAllowed = true;
                }
            });
            // console.log(this.$cookies.get('apiToken').user.listOfRoles);
            // console.log('isUserAllowed--->>>', this.isUserAllowed);
            if (this.isUserAllowed) {
                this.isLoading = true;
                this.getToken();
            }
        },
        getToken() {

            let uId = this.getLoggedInUserId();
            if (uId) {
                this.userId = uId;
                // this.userId = 133; // FOR TESTING
                this.getAPItoken()
                    .then((token) => {
                        this.token = token;
                        this.fetchData(token, uId);
                    });
            }
        },
        async fetchData(token) {
            const appsRequestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json"
                },
                
            };

            this.gridData = []; //reset

            let applicationsRequest = await (await fetch(this.GET_RETAILER_UPDATES_API_URL, appsRequestOptions)).json();
            // console.log(applicationsRequest)
            this.gridData = applicationsRequest;
            // console.log(this.gridData);
            this.isLoading = false;
        },
        showQuickView(index) {
            let qvEl = document.getElementById('qv' + index);
            qvEl.style.display = "table";
            qvEl.style.background = "rgb(237 237 237 / 38%)";
            let qvBtn = document.getElementById('qvOpen' + index);
            qvBtn.style.display = "none";
            let appRow = document.getElementById('appRow' + index);
            appRow.classList.add("open");

        },
        hideQuickView(index) {
            let qvEl = document.getElementById('qv' + index);
            qvEl.style.display = "none";
            qvEl.style.background = "transparent";
            let qvBtn = document.getElementById('qvOpen' + index);
            qvBtn.style.display = "table";
            let appRow = document.getElementById('appRow' + index);
            appRow.classList.remove("open");
        },
        baStatus(int) {
            switch (int) {
                case 0: return 'Open'
                case 1: return 'Submitted'
                case 2: return 'Processing'
                case 3: return 'Processing'
                case 4: return 'Rejected'
                case 5: return 'Rejected'
                case 6: return 'Processed'
            }
        },
        biw9renStatus(int) {
            switch (int) {
                case 0: return 'Open'
                case 1: return 'Submitted'
                case 2: return 'Rejected'
                case 3: return 'Processed'
            }
        },
        eftprinStatus(int) {
            switch (int) {
                case 0: return 'Open'
                case 1: return 'Submitted'
                case 2: return 'Processing'
                case 3: return 'Rejected'
                case 4: return 'Processed'
            }
        },
        sortListBy( key ) {
            let apps = this.gridData;

            //if the same key is clicked again, toggle the asc/desc
            if (key === this.sortBy) {
                if (this.sortOrder === 'asc') {
                    this.sortOrder = 'desc';
                } else {
                    this.sortOrder = 'asc';
                }
            }
            //otherwise, sort the new column selected by asc order
            else {
                this.sortOrder = 'asc';
            }

            // set current sortBy to key
            this.sortBy = key;

            if (key === 1) {
                switch (this.sortOrder) {
                    case 'asc':
                        apps = apps.sort((a,b) => a.agentNumber - b.agentNumber);
                        break;
                    case 'desc':
                        apps = apps.sort((a,b) => b.agentNumber - a.agentNumber);
                        break;
                }
            } else if (key === 2) {
                switch (this.sortOrder) {
                    case 'asc':
                        apps = apps.sort((a,b) => { if (a.businessName > b.businessName) { return 1 } if (a.businessName < b.businessName) { return -1 }});
                        break;
                    case 'desc':
                        apps = apps.sort((a,b) => { if (a.businessName < b.businessName) { return 1 } if (a.businessName > b.businessName) { return -1 }});
                        break;
                }
            } else if (key === 3) {
                switch (this.sortOrder) {
                    case 'asc':
                        apps = apps.sort((a,b) => { if (a.city > b.city) { return 1 } if (a.city < b.city) { return -1 }});
                        break;
                    case 'desc':
                        apps = apps.sort((a,b) => { if (a.city < b.city) { return 1 } if (a.city > b.city) { return -1 }});
                        break;
                }               
            }

            this.gridData = apps;
        }
    },
    computed: {
        GET_RETAILER_UPDATES_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/GetUpdatesForRetailer/' + this.userId;
        },  
    }
}
</script>