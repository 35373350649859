<template>
    <div class="mlrHomeDynamic">
        <RewardsGreeting v-if="tEntry === 'true'"></RewardsGreeting>
        <RewardsGreetingNoTicket v-if="tEntry === 'false'"></RewardsGreetingNoTicket>
    </div>
</template>

<script>
    import RewardsGreeting from './RewardsGreeting.vue';
    import RewardsGreetingNoTicket from './RewardsGreetingNoTicket.vue';
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components:{
            RewardsGreeting,
            RewardsGreetingNoTicket,
            SignInRegister
        },
        data: function () {
            return {
                token: '',
                tEntry: false
            }
        },
        mounted: function () {
            
            let apiToken = this.$cookies.get('apiToken');
            // console.log(apiToken);
            if(apiToken.user.username == "mobilepublic@mtllc.com"){
                this.isUserLogged = false;
                this.getToken();
                
            }else{
                this.isUserLogged = true
                
                this.getToken();

                this.username = apiToken.user.firstName;
                this.pointBalance = apiToken.user.pointBalance.balance;
            }

        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.token = token;
                })
                .then(() => {
                    this.manualTicketEntry();
                })
            },
            manualTicketEntry() {
                this.fetchContentFromAPI('manual-ticket-entry', 'manual-ticket-entry')
                .then(json => {
                    this.tEntry = json.enabled;
                });
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
</style>