<template>
    <div class="featured-rewards">
        <div class="featured-rewards-item" v-for="reward in rewards" :key="reward.id">
            <div class="featured-rewards-img">
                <img :src="`/getattachment/${reward.image1}/attachment.aspx`" :alt="reward.title"  />
            </div>

            <div class="featured-rewards-cta">
                <a :href="`${reward.link}`" class="featured-rewards-btn">
                    {{ reward.teaser }}
                </a>
            </div>

            <div class="featured-rewards-copy">
                <h3>{{ reward.title }}</h3>
                <p v-html="reward.content"></p>
            </div>
        </div>
    </div>
</template>

<script>
   // import moment from 'moment'
    export default {
        data: function () {
            return {
                rewards: []
            }
        },
        mounted: function () {
            this.getToken()
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetAllByCategory/NewAtMLR", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    console.log(results)

                    let filterZeroResults = results.filter(function( obj ) {
                        return obj.image1 !== "00000000-0000-0000-0000-000000000000";
                    });

                    filterZeroResults.sort(this.sortDates);

                    this.rewards = filterZeroResults;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>

</style>