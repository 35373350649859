<template>
    <div>
        <SignInRegister v-if="!isUserLogged"></SignInRegister>
        <TicketEntryForm v-if="isUserLogged" contentKey="cash-explosion-entry-text" requireDigits="3" placeholderProp="" headingProp=""
            submitTextProp="">
        </TicketEntryForm>
    </div>
</template>

<script>
import SignInRegister from '../../../../ViewComponents/SignInRegister/SignInRegister.vue'
import TicketEntryForm from '../../../TicketEntry/TicketEntry.vue';
export default {
    components: {
        TicketEntryForm,
        SignInRegister
    },
    data: function () {
        return {
            isUserLogged: false
        }
    },
    mounted: function () {
        this.isUserLogged = this.isUserAuthed();
    },
    methods: {

    }
}
</script>