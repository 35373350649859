import { render, staticRenderFns } from "./ScratchOffs-Last-Day.vue?vue&type=template&id=65a5b385&scoped=true"
import script from "./ScratchOffs-Last-Day.vue?vue&type=script&lang=js"
export * from "./ScratchOffs-Last-Day.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a5b385",
  null
  
)

export default component.exports