<template>
    <div class="eAppModule">

        <div class="moduleContent cf eAppAdminReviewSubmit" v-if="this.userRole == 'eAppDistrictRep' && pendingSoB === true">
            <div class="addEditListWrap">
                <h3>Sale of Business Pending</h3>
                <div class="addEditList cf" style="padding-top: 0; justify-content: flex-start; align-items: flex-start;">
                    <span class="icon-exclamation-triangle" style="margin: 6px 1rem 0 0;"
                        v-if="!application.businessInformation.isBusinessTransferCompleted"></span>
                    <label class="error" v-if="!application.businessInformation.isBusinessTransferCompleted">Sale of Business has not
                        yet been completed by the previous owner.</label>

                    <span class="icon-Status" style="margin: 8px 1rem 0 0;"
                        v-if="application.businessInformation.isBusinessTransferCompleted"></span>
                    <label class="success" v-if="application.businessInformation.isBusinessTransferCompleted">Sale of Business has been
                        completed by the previous owner.</label>
                </div>
            </div>
        </div>

        <div class="moduleContent cf eAppAdminReviewSubmit">
            <h3>Final Review</h3>

            <div v-if="hasErrors" class="ErrorLabel alert-error">
                There were errors:
                <ul>
                    <template v-for="error in errors">
                        <li v-if="error !== ''">
                            {{ error }}
                        </li>
                    </template>
                </ul>
            </div>

            <div v-if="appOpen && !showApprove">
                <button type="button" data-open="modalMarkReviewed" class="eAppBtnReview button btn-secondary"
                    @click="openModal('#modalMarkReviewed')" :disabled="pendingSoB">Mark as reviewed</button>
                <div class="eAppModal modalWrapper reveal small reviewModal" id="modalMarkReviewed" data-reveal>

                    <h3>Mark Application as Reviewed</h3>
                    <p>Are you done reviewing the application and wish to move it one step closer to approval?</p>

                    <textarea v-model="reviewNotes" id="txbxReviewNotes" rows="2" cols="20"></textarea>

                    <button type="button" class="btnCancel btn-secondary" @click="closeModal('#modalMarkReviewed')">Cancel</button>
                    <button type="button" class="btnSubmitApp button btn-secondary" @click="postReviewed()">Done reviewing</button>

                </div>
            </div>

            <div v-if="showApprove && appOpen">
                <div v-if="isCLM">
                    <div class="cf statusReview addEditListWrap cf">
                        <div class="addEditList cf">
                            <span class="toolTip" @click="toggleToolTip('showBusInfoTip')"><span class="icon-Status statusBusnInfo"></span></span>
                            <label>Business Information</label>
                            <button class="button editBtn btn-secondary" @click="emitSection('business')">Edit</button>
                        </div>
                        <div class="addEditList cf">
                            <span class="toolTip" @click="toggleToolTip('showEftTip')"><span class="icon-Status statusEFT"></span></span>
                            <label>Electronic Funds Transfer</label>
                            <button class="button editBtn btn-secondary" @click="emitSection('eft')">Edit</button>
                        </div>
                        <div class="addEditList cf">
                            <span class="toolTip" @click="toggleToolTip('showTaxTip')"><span class="icon-Status statusTax"></span></span>
                            <label>Federal Tax Information</label>
                            <button class="button editBtn btn-secondary" @click="emitSection('tax')">Edit</button>
                        </div>
                        <div class="addEditList cf">
                            <span class="toolTip" @click="toggleToolTip('showPrincipalTip')"><span class="icon-Status statusBgCheck"></span></span>
                            <label>Background Check</label>
                            <button class="button editBtn btn-secondary" @click="emitSection('background')">Edit</button>
                        </div>
                    </div>
                    <div id="pnlForm" class="form-horizontal eapp_application termsReview">
                        <div class="formRow checkboxRow cf termsCheck">
                            <span class="req">*</span>
                            <input id="AcceptedTC_checkbox" v-model="application.acceptedTC" type="checkbox" name="AcceptedTC_checkbox" @change="checkyCheck()" disabled>
                            <label for="AcceptedTC_checkbox">By checking this box I agree to the Ohio Lottery Retailer <a href="#eAppTandCmodal" class="eAppModalTerms" data-open="eAppTandCmodal">Terms and Conditions</a></label>
                        </div>
                        <div class="formRow checkboxRow cf indemnCheck">
                            <span class="req">*</span>
                            <input id="Indemnification_checkbox" v-model="application.indemnification" type="checkbox" name="Indemnification_checkbox" @change="checkyCheck()" disabled>
                            <label for="Indemnification_checkbox">By checking this box I agree to the Ohio Lottery <a href="#indemnificationModal" class="eAppModalIndemn" data-open="indemnificationModal">Indemnification Agreement</a>.</label>
                        </div>
                        <div class="formRow checkboxRow cf reqCheckA">
                            <span class="req">*</span>
                            <input id="RequiredTermsA_checkbox" v-model="application.requiredTermsA" type="checkbox" name="RequiredTermsA$checkbox" @change="checkyCheck()" disabled>
                            <label for="RequiredTermsA_checkbox">By checking this box I confirm that I have authority to and do hereby authorize the Ohio Lottery Commission ("Lottery") or its Vendor to install any equipment required to operate as a Lottery Retailer. I confirm that I am the owner of the property where the equipment will be installed, or alternatively if I am a Lessee of the property, that the terms of my lease agreement, or other agreement between my Landlord and me, allow me to authorize the installation of equipment in the building. I understand that equipment will be installed on the roof of the building and that at no time will the Lottery or its Vendor drill any holes into the roof of the building. I understand that any equipment installed is the property of the Lottery or its Vendor and that if my license to operate as a Lottery Retailer is cancelled, the Lottery or its Vendor will retrieve its equipment at no cost to me. I understand that all equipment installed is subject to a separate Indemnification Agreement.</label>
                        </div>
                        <div class="formRow eAppSignature appSigManual cf">
                            
                            <label for="ApplicantSignature_txtText"><span class="req">*</span> Digital Signature:</label>
                            <input name="ApplicantSignature$txtText" v-model="application.applicantSignature" type="text" maxlength="200" id="ApplicantSignature_txtText" class="appSignature" autocomplete="off" disabled :class="{ scripty: application.signatureCertification }">

                            <!-- <div v-if="!signatureValid" class="error signatureError">Please provide a digital signature above.</div> -->
                        </div>
                        <div class="formRow checkboxRow cf appSignatureCert">
                            <span class="req">*</span>
                            <input id="SignatureCertification_checkbox" v-model="application.signatureCertification" type="checkbox" name="SignatureCertification_checkbox" disabled @change="checkyCheck()">
                            <label for="SignatureCertification_checkbox">
                                Enter your legal name above to digitally sign this application, the terms and conditions, indemnification agreement, authority to install equipment, Electronic Fund Authorization, the W-9 form and the background check.
                            </label>
                            <!-- <div v-if="!signatureCertValid" class="error signatureError">Please certify your digital signature above.</div> -->
                        </div>
                    </div>
                    <div class="formRow dividerRow cf">
                        <div class=" cf eAppRetailerW9PDF">
                            <a @click="downloadPDF(0)" target="_blank" id="btnGenerateW9" class="w9PDFbtn button btn-secondary"><span class="icon-arrow-down-line"></span> Preview W-9 Form</a>
                        </div>
                        <div class=" cf eAppRetailerW9PDF">
                            <a @click="downloadPDF(1)" target="_blank" id="btnGenerateApplicationPDF" class="w9PDFbtn button btn-secondary"><span class="icon-arrow-down-line"></span> Download eApp Form</a>
                        </div>
                    </div>
                </div>
                <span v-if="isChainSalesManager || isCSR || isRM"></span>
                <button v-else type="button" data-open="modalApprove" class="eAppBtnApprove button btn-secondary"
                    @click="openModal('#modalApprove')">Approve</button>

                <div class="eAppModal reveal small reviewModal" id="modalApprove" data-reveal>
                    <h3>Approve Application</h3>
                    <p>Do you wish to approve the application and move it one step closer to final approval?</p>

                    <button type="button" class="btnCancel button btn-secondary" @click="closeModal('#modalApprove')">Cancel</button>
                    <button type="button" class="btnSubmitApp button btn-secondary" :disabled="approvalPending" @click="postApprove()">Approve</button>

                </div>
            </div>

            <div v-if="isCLM && appOpen">
                <button type="button" data-open="pnlRejectSectionLM" class="eAppBtnReject button btn-secondary"
                    @click="openModal('#pnlRejectSectionLM')">Reject</button>
                    
                <div class="eAppModal modalWrapper reveal small reviewModal" id="pnlRejectSectionLM" data-reveal>
                    <h3>Reject Application</h3>
                    
                    <div class="grid-x">
                        <div class="cell">Choose the type of rejection:</div>
                        <div class="cell medium-6 reviewRadios">
                            <input type="radio" id="toRegMan" name="rejectType" value="toRegMan"
                                @click="toggleRejectMessage('LMtoRM')" v-if="chainRejectLM">
                            <label for="toRegMan" v-if="chainRejectLM">Return To Regional Manager</label>
                        </div>
                        <div class="cell medium-6 reviewRadios">
                            <input type="radio" id="toApplicantLM" name="rejectType" value="toApplicant"
                                @click="toggleRejectMessage('LMtoApplicant')">
                            <label for="toApplicantLM">Reject To Applicant</label>
                        </div>
                    </div>

                    <div class="toRegMan togglePanel" v-if="rejectType == 'LMtoRM'">
                        <label for="rejectionMessageLicensing">Rejection message for Regional Manager:</label>
                        <textarea v-model="lmRejectMsgRM" id="rejectionMessageLicensing" rows="2" cols="20"></textarea>
                    </div>

                    <div class="toApplicant togglePanel" v-if="rejectType == 'LMtoApplicant'">
                        <p>Choose the reason(s) for rejection to be included in a message to the applicant:</p>
                        <div class="rejectOption" v-for="option in lmMessageOptions" :key="option.id">
                            <input type="checkbox" @click="checkLMreason(option.id)" :id="'lmReject' + option.id">
                            <label :for="'lmReject' + option.id">{{ option.message }}</label>
                            <div class="nestedCheckTbl" v-for="child in option.children" :key="child.id">
                                <input type="checkbox" @click="checkLMtypo(option.id, child.id)"
                                    :id="'lmReject' + child.id">
                                <label :for="'lmReject' + child.id">{{ child.message }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="formActions">
                        <button type="button" class="btnCancel btn-secondary" @click="closeModal('#pnlRejectSectionLM')">Cancel</button>

                        <button v-if="rejectType == 'LMtoApplicant'" type="button" class="btnSubmitApp button btn-secondary"
                            @click="rejectLMtoApplicant()">Reject To Applicant</button>
                        <button v-if="rejectType == 'LMtoRM'" type="button" class="btnSubmitApp button btn-secondary"
                            @click="rejectLMtoRM()">{{ lmRejectBtnTxt }}</button>
                    </div>
                    <div v-if="lmRejectError" class="error">Please provide the reason(s) for rejection.</div>
                </div>
            </div>

            <div v-if="isCSM && appOpen">
                <button type="button" data-open="pnlRejectSectionCS" class="eAppBtnReject button btn-secondary"
                    @click="openModal('#pnlRejectSectionCS')">Reject</button>
                <div class="eAppModal modalWrapper reveal small reviewModal" id="pnlRejectSectionCS" data-reveal>
                    <h3>Reject Application</h3>
                    
                    <div class="grid-x">
                        <div class="cell">Choose the type of rejection:</div>
                        <div class="cell medium-6 reviewRadios">
                            <input type="radio" id="rejectCSMtoRM" name="rejectType" value="toRegMan"
                                @click="toggleRejectMessage('CSMtoRM')" v-if="chainRejectCSM">
                            <label for="rejectCSMtoRM" v-if="chainRejectCSM">Return To Regional Manager</label>
                        </div>
                        <div class="cell medium-6 reviewRadios">
                            <input type="radio" id="rejectCSMtoCLM" name="rejectType" value="toCentLic"
                                @click="toggleRejectMessage('CSMtoCLM')">
                            <label for="rejectCSMtoCLM">Reject To Central Licensing</label>
                        </div>
                    </div>

                    <div class="toRegMan togglePanel" v-if="rejectType == 'CSMtoRM'">
                        <label for="rejectionMessageCSMtoRM">Rejection message for Regional Manager:</label>
                        <textarea v-model="csmRejectMsgRM" id="rejectionMessageCSMtoRM" rows="2" cols="20"></textarea>
                    </div>

                    <div class="toCentLic togglePanel" v-if="rejectType == 'CSMtoCLM'">
                        <label for="rejectionMessageCSMtoCLM">Rejection message for Central Licensing:</label>
                        <textarea v-model="csmRejectMsgCLM" id="rejectionMessageCSMtoCLM" rows="2" cols="20"></textarea>
                    </div>

                    <div class="formActions">
                        <button type="button" class="btnCancel btn-secondary" @click="closeModal('#pnlRejectSectionLM')">Cancel</button>

                        <button v-if="rejectType == 'CSMtoRM'" type="button" class="btnSubmitApp button btn-secondary"
                            @click="returnCSMtoRM()">Return To Regional Manager</button>
                        <button v-if="rejectType == 'CSMtoCLM'" type="button" class="btnSubmitApp button btn-secondary"
                            @click="rejectCSMtoCLM()">Return To Central Licensing</button>
                    </div>
                    <div v-if="csmRejectError" class="error">Please provide the reason(s) for rejection.</div>
                </div>
            </div>

            <div v-if="isRM && appOpen">
                <button type="button" data-open="pnlRejectSectionRM" class="eAppBtnReject button btn-secondary"
                    @click="openModal('#pnlRejectSectionRM')">Reject</button>
                <div class="eAppModal modalWrapper reveal small reviewModal" id="pnlRejectSectionRM" data-reveal>
                    <h3>Reject Application</h3>
                    
                    <div class="grid-x">
                        <div class="cell">Choose the type of rejection:</div>
                        <div class="cell medium-6 reviewRadios">
                            <input type="radio" id="recommendReject" name="rejectType" value="recommendReject"
                                @click="toggleRejectMessage('RMrecommend')">
                            <label for="recommendReject">Recommend Reject</label>
                        </div>
                        <div class="cell medium-6 reviewRadios">
                            <input type="radio" id="returnRMtoApplicant" name="rejectType" value="toApplicant"
                                @click="toggleRejectMessage('RMtoApp')">
                            <label for="returnRMtoApplicant">Return To Applicant</label>
                        </div>
                    </div>

                    <div v-if="rejectType == 'RMrecommend'" class="RecRej togglePanel">
                        <label for="rejectionMessageRM">Rejection message:</label>
                        <textarea v-model="rmRejectMsg" id="rejectionMessageRM" rows="2" cols="20"></textarea>
                    </div>
                    <div v-if="rejectType == 'RMtoApp'" class="toApplicant togglePanel">
                        <label for="rejectionMessageRMtoApp">Sections to review message:</label>
                        <textarea v-model="rmRecommendMsg" id="rejectionMessageRMtoApp" rows="2" cols="20"></textarea>
                    </div>

                    <button type="button" class="btnCancel btn-secondary" data-close>Cancel</button>

                    <button v-if="rejectType == 'RMrecommend'" type="button" class="btnSubmitApp button RecRej btn-secondary"
                        @click="recommendReject()">Recommend Rejection</button>
                    <button v-if="rejectType == 'RMtoApp'" type="button" class="btnSubmitApp button toApplicant btn-secondary"
                        @click="returnToApplicant()">Return To Applicant</button>
                    <br>
                    <br>
                    <div v-if="rmRejectError" class="error">Please provide the reason for rejection.</div>
                </div>
            </div>

            <div v-if="isCSM && appOpen">
                <button type="button" data-open="pnlDenySection" class="eAppBtnReject button btn-secondary" @click="openModal('#pnlDenySection')">Deny</button>
                <div class="eAppModal modalWrapper reveal small" id="pnlDenySection" data-reveal>
                    <div class="modalContent cf">
                        <h3>Deny Application</h3>
                        <label for="denyMessage">Reason for denial (Be specific. This will be sent to the
                            applicant.):</label>

                        <textarea v-model="csmDenyMsg" id="denyMessage" rows="2" cols="20"></textarea>

                        <button type="button" class="btnCancel button btn-secondary"
                            @click="closeModal('#pnlRejectSectionLM')">Cancel</button>
                        <button type="button" class="btnSubmitApp button btn-secondary" @click="denyApplication()">Deny
                            Application</button>
                        <br>
                        <br>
                        <div v-if="csmDenyError" class="error">Please provide the reason for denial.</div>
                    </div>
                </div>
            </div>

        </div>
        <!-- modal for T&C -->
        <div class="reveal large" id="eAppTandCmodal" data-reveal>
            <static propKey="eAppTandC"></static>
            <button class="close-button" data-close aria-label="Close modal" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <!-- modal for Indemnification -->
        <div class="reveal large" id="indemnificationModal" data-reveal>
            <static propKey="eAppIndemnification"></static>
            <button class="close-button" data-close aria-label="Close modal" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div id="overlayPdfDownload" onclick='document.getElementById("overlay").style.display = "none"'>
            <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="15"
                :dots-num="3"
                color="#FFF"
                v-if="isPdfDownload"
            />
        </div>
    </div>
</template>


<script>

    import { HollowDotsSpinner } from 'epic-spinners'
    import $ from 'jquery';
    import Review from './review.vue';
    import Static from "../../Shared/Static.vue";

export default {
    components: {
        Static,
        Review
    },
    props: {
        applicationJson: String,
        userId: Number,
        finalToken: String,
        userRole: String
    },
    data: function () {
        return {
            application: {},
            errors: [],
            hasErrors: false,
            reviewNotes: '',
            showReview: false,
            showApprove: false,
            isCLM: false,
            isChainSalesManager: false,
            isCSM: false,
            isRM: false,
            rejectType: '',
            lmRejectMsgApplicant: '',
            approvalPending: false,
            lmMessageOptions: [
                {
                    id: 1,
                    message: 'Failed the IRS check',
                    value: false,
                    children: [],
                },
                {
                    id: 2,
                    message: 'Failed the Secretary of State check',
                    value: false,
                    children: []
                },
                {
                    id: 3,
                    message: 'Failed DBA and/or business address check',
                    value: false,
                    children: []
                },
                {
                    id: 4,
                    message: 'Had a previous bond payment and we need verification of repayment to bond company',
                    value: false,
                    children: []
                },
                {
                    id: 6,
                    message: 'Lack of activity',
                    value: false,
                    children: []
                },
                {
                    id: 7,
                    message: 'Applicant Decision',
                    value: false,
                    children: []
                },
                {
                    id: 5,
                    message: 'Typo in following section(s) of the application:',
                    value: false,
                    children: [
                        {
                            id: 8,
                            message: 'Business Information',
                            value: false
                        },
                        {
                            id: 9,
                            message: 'Electronic Funds Transfer',
                            value: false
                        },
                        {
                            id: 10,
                            message: 'Federal Tax Information',
                            value: false
                        },
                        {
                            id: 11,
                            message: 'Background Check',
                            value: false
                        }
                    ]
                }
            ],
            lmRejectMsgRM: '',
            lmRejectError: false,
            chainRejectLM: false,
            lmRejectBtnTxt: 'Return To Regional Manager',
            csmRejectMsgRM: '',
            csmRejectMsgCLM: '',
            csmRejectError: false,
            csmDenyMsg: '',
            csmDenyError: false,
            rmRejectMsg: '',
            rmRecommendMsg: '',
            rmRejectError: false,
            pendingSoB: false,
            successRedirect: '/eapplications/dashboard/regular',
            appOpen: true,
            status: 0,
            ReviewContent: document.getElementById('ReviewContent').value,
            isPdfDownload: false,
            eappId: '',
            isCSR: false,
            step: ''
        }
    },
    mounted: function () {
        this.init();
        $(document).foundation();
    },
    methods: {
        init() {
            this.deserializeJson();
            this.getUserInfo();
            this.tryGetFromQueryString();
        },
        deserializeJson() {
            this.application = JSON.parse(this.applicationJson);
            // prevent processing if SoB not completed
            if (this.application.businessInformation.isBusinessBeingTransfer && !this.application.businessInformation.isBusinessTransferCompleted) {
                this.pendingSoB = true;
            }
        },
        tryGetFromQueryString() {
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            if (urlParams.has('id')) {
                this.eappId = urlParams.get('id');
            }
        },
        getUserInfo() {
            let status = this.application.applicationStatus;
            
            if (this.userRole == 'eApp-CentralLicensingManager') {
                this.step = 'clm';
                if (status == 5 || status == 14 || status == 7) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
                if (this.application.applicationIspartOfChain && !this.application.applicationMasterChain) {
                    // if app is Part of Corp Chain but NOT a Master Chain 
                    this.isCLM = false;
                } else {
                    this.isCLM = true;
                }
                if (this.application.applicationIspartOfChain || this.application.applicationMasterChain) {
                    // if app is Part of Corp Chain OR a Master Chain 
                    this.chainRejectLM = false;
                    this.lmRejectBtnTxt = 'Return To Chain Sales Representative';
                } else {
                    this.chainRejectLM = true;
                }
                this.showApprove = true;
            }
            if (this.userRole == 'eApp-CentralSalesManager') {
                this.step = 'csm';
                if (status == 6) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
                this.isCSM = true;
                this.showApprove = true;
                if (this.application.applicationIspartOfChain || this.application.applicationMasterChain) {
                    // if app is Part of Corp Chain OR a Master Chain 
                    this.chainRejectCSM = false;
                } else {
                    this.chainRejectCSM = true;
                }
            }
            if (this.userRole == 'eApp-RegionalManager') {
                this.step = 'rm';
                if (status == 3 || status == 7 || status == 13) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
                this.isRM = true;
                // this.showReview = true;
            }
            if (this.userRole == 'eApp-ChainSalesRep') {
                this.step = 'rep';
                this.isCSR = true;
                if (status == 2) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
                this.successRedirect = '/eapplications/dashboard/chain';
                // this.showReview = true;
            }
            if (this.userRole == 'eApp-EFTReviewer' || this.userRole == 'eApp-RegionalSecurity' || this.userRole == 'eApp-Security' || this.userRole == 'eAppDistrictRep') {
                // this.showReview = true;
            }
            // Foundation.reInit('reveal');
            if (this.userRole == 'eApp-EFTReviewer') {
                this.step = 'eft';
                if (status == 3 || status == 4 || status == 7) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
            }
            if (this.userRole == 'eApp-RegionalSecurity') {
                this.step = 'sec';
                if (status == 3 || status == 4 || status == 7) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
            }
            if (this.userRole == 'eApp-Security') {
                this.step = 'sec';
                if (status == 3 || status == 4 || status == 7) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
            }
            if (this.userRole == 'eAppDistrictRep') {
                this.step = 'rep';
                if (status == 2) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
            }
            if (this.userRole == 'eAppChainManager') {
                this.step = 'rm';
                this.isChainSalesManager = true;
                if (status == 3 || status == 7 || status == 12) {
                    this.appOpen = true;
                } else {
                    this.appOpen = false;
                }
            }
        },
        toggleRejectMessage(val) {
            this.lmRejectError = false;
            this.csmRejectError = false;
            this.rmRejectError = false;
            this.csmDenyError = false;
            this.rejectType = val;
        },
        checkLMreason(optionId) {
            this.lmMessageOptions.forEach(element => {
                if (optionId == element.id) {
                    element.value == !element.value;
                }
            });
            console.log(this.lmMessageOptions);
        },
        checkLMtypo(optionId, childId) {
            // hopefully this is wired right
            this.lmMessageOptions.forEach(element => {
                if (optionId == element.id) {
                    let children = element.children;
                    element.children.forEach(ele => {
                        if (childId == ele.id) {
                            ele.value == !ele.value;
                        }
                    });
                }
            });
            console.log(this.lmMessageOptions);
        },
        rejectLMtoApplicant() {
            // parse out the lmRejectMsgApplicant into a text string and submit for the value.
            this.lmMessageOptions.forEach(element => {
                if (element.value) {
                    this.lmRejectMsgApplicant = this.lmRejectMsgApplicant + ' ' + element.message;
                    element.children.forEach(ele => {
                        if (ele.value) {
                            this.lmRejectMsgApplicant = this.lmRejectMsgApplicant + ' ' + ele.message;
                        }
                    });
                }
            });
            // if (this.lmRejectMsgApplicant == '') {
            //     this.lmRejectError = true;
            // } else {
            this.lmRejectError = false;
            // proceed to submit
            this.postReject(this.REJECT_TO_APPLICANT_API_URL, this.lmRejectMsgApplicant);
            this.closeModal('#pnlRejectSectionLM');
            // }
        },
        rejectLMtoRM() {
            if (this.lmRejectMsgRM == '') {
                this.lmRejectError = true;
            } else {
                this.lmRejectError = false;
                // this.application.clmRejectedRegManagerMessage = this.lmRejectMsgRM;
                // proceed to submit
                this.postReject(this.REJECT_TO_REGIONALMANAGER_API_URL, this.lmRejectMsgRM);
                this.closeModal('#pnlRejectSectionLM');
            }
        },
        returnCSMtoRM() {
            if (this.csmRejectMsgRM == '') {
                this.csmRejectError = true;
            } else {
                this.csmRejectError = false;
                // this.application.centralSalesManagerRejectedRegManagerDate = this.csmRejectMsgRM;
                // proceed to submit
                this.postReject(this.REJECT_TO_REGIONALMANAGER_API_URL, this.csmRejectMsgRM);
                this.closeModal('#pnlRejectSectionCS');
            }
        },
        rejectCSMtoCLM() {
            if (this.csmRejectMsgCLM == '') {
                this.csmRejectError = true;
            } else {
                this.csmRejectError = false;
                // this.application.clmRejectedRegManagerMessage = this.lmRejectMsgRM;
                // proceed to submit
                this.postReject(this.RETURN_TO_LICENSING_API_URL, this.csmRejectMsgCLM);
            }
        },
        recommendReject() {
            if (this.rmRejectMsg == '') {
                this.rmRejectError = true;
            } else {
                this.rmRejectError = false;
                // this.application.regionalManagerRejectionMessage = this.rmRejectMsg;
                // proceed to submit
                this.postReject(this.RECOMMEND_REJECT_APP_API_URL, this.rmRejectMsg);
                this.closeModal('#pnlRejectSectionRM');
            }
        },
        returnToApplicant() {
            if (this.rmRecommendMsg == '') {
                this.rmRejectError = true;
            } else {
                this.rmRejectError = false;
                // this.application.rmRejectedApplicantMessage = this.rmRecommendMsg;
                // proceed to submit
                this.postReject(this.REJECT_TO_APPLICANT_API_URL, this.rmRecommendMsg);
                this.closeModal('#pnlRejectSectionRM');
            }
        },
        denyApplication() {
            if (this.csmDenyMsg == '') {
                this.csmDenyError = true;
            } else {
                this.csmDenyError = false;
                //post
                this.postReject(this.DENY_APP_API_URL, this.csmDenyMsg);
                this.closeModal('#pnlDenySection');
            }
        },
        async postReject(url, notes) {
            this.errors = [];
            this.isLoading = true;
            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.finalToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    {
                        'userId': this.userId,
                        'applicationGuid': this.application.applicationGuid,
                        'message': notes,
                        'step': this.step
                    }
                )
            };
            let response = await (await fetch(url, appsRequestOptions)).json();

            // redirect if successful
            if (response.errorList.length > 0) {
                this.errors = response.errorList;
                this.hasErrors = true;
                this.showReview = false;
                this.isLoading = false;
            }
            else {
                // alert(response)
                window.location.assign(this.successRedirect);
            }

        },
        async postApprove() {
            this.approvalPending = true;
            this.errors = [];
            this.isLoading = true;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.finalToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    {
                        'userId': this.userId,
                        'applicationGuid': this.application.applicationGuid,
                        'step': this.step
                    }
                )
            };

            let response = await (await fetch(this.APPROVE_APP_API_URL, appsRequestOptions)).json();

            // redirect if successful
            if (response.errorList.length > 0) {
                this.errors = response.errorList;
                this.hasErrors = true;
                this.showReview = false;
                this.isLoading = false;
                this.approvalPending = false;
                this.closeModal('#modalApprove');
            }
            else {
                window.location.assign(this.successRedirect);
            }

        },
        async postReviewed() {
            this.errors = [];
            this.isLoading = true;
            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.finalToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    {
                        'userId': this.userId,
                        'applicationGuid': this.application.applicationGuid,
                        'message': this.reviewNotes,
                        'step': this.step
                    }
                )
            };
            let response = await (await fetch(this.REVIEW_APP_API_URL, appsRequestOptions)).json();

            // redirect if successful
            if (response.errorList.length > 0) {
                this.errors = response.errorList;

                for (let index = 0; index < this.errors.length; index++) {
                    let thisError = this.errors[index];
                    if (thisError == 'eapp.EquipmentInformationService.EquipmentMissing ') {
                        console.log(thisError);
                        this.errors[index] = 'Please fill out the Lottery Equipment form before marking as reviewed.';
                    }
                }
                this.errors.push('');
                this.hasErrors = true;
                this.showReview = false;
                this.isLoading = false;
                this.closeModal('#modalMarkReviewed');
            }
            else {
                window.location.assign(this.successRedirect);
            }
        },
        openModal(target) {
            let popup = new Foundation.Reveal($(target));
            popup.open();
            // $(target).foundation('open');
        },
        closeModal(target) {
            // $(target).foundation('close');
            this.lmRejectError = false;
            this.csmRejectError = false;
            this.rmRejectError = false;
            this.csmDenyError = false;
            $(target).hide();
            $('.reveal-overlay').hide();
        },
        downloadPDF(pdfType){
            // console.log(this.userId)
            // console.log(this.eappId)
            document.getElementById("overlayPdfDownload").style.display = "flex";
            this.isPdfDownload = true;
            fetch('/Eapp/GeneratePDFForLicensing?eapplicationGuid='+this.eappId+'&userId='+this.userId+'&type='+pdfType, {
                method: "GET",
            })
            .then((res) => {
                console.log(res)
                document.getElementById("overlayPdfDownload").style.display = "none";
                this.isPdfDownload = false;
                window.open(res.url)
            })
            .catch((err) => {
                document.getElementById("overlayPdfDownload").style.display = "none";
                this.isPdfDownload = false;
                console.error(err);
            });
        },
        emitSection(e) {
            this.$emit('edit', e);
        }
    },
    computed:
    {
        REVIEW_APP_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/RegularApplication/ReviewApplication';
        },
        APPROVE_APP_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/ApproveApplication`;
        },
        DENY_APP_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/DenyApplication`;
        },
        REJECT_TO_APPLICANT_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/RejectApplicationToApplicant`;
        },
        REJECT_TO_REGIONALMANAGER_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/RejectApplicationToRegionalManager`;
        },
        RECOMMEND_REJECT_APP_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/RecommendRejectApplication`;
        },
        RETURN_TO_LICENSING_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/ReturnApplicationToLicensing`;
        },
    },
};
</script>

<style lang="scss" scoped>
    #overlayPdfDownload {
        position: fixed; 
        display: none; 
        width: 100%; 
        height: 100%; 
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5); 
        z-index: 2;
        cursor: pointer; 
        align-items: center;
        justify-content: center;
    }
</style>