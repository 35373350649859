<template>
    <div class="page_content cf">

        <h1>Confirm Deletion</h1>

        <div class="module module_pageContent">
            <h4><strong>Are you sure you want to delete you account?</strong></h4><h4>

            <p>Confirm that you would like to delete your MyLotto Rewards account by entering the email address and password associated with your account.</p>

            <p>Once you confirm deletion, you will be logged out, and you will no longer have access to any data in your account.</p></h4>
        </div>

        <section class="olcForms mlrDeletionForm">
            <UserLoginRegisterVue v-if="!isUserLogged"></UserLoginRegisterVue>
            <div class="mlrDeleteError" id="mlrDeleteError" style="display:none">
                Sorry, the credentials you've entered do not match our records.
            </div>
            <div class="halfForm clear" v-if="isUserLogged && loaded">
                <label for="deleteAccountEmail"></label>
                <input type="text" id="deleteAccountEmail" class="reqTxt disabled" :placeholder="user.user.email" readonly="readonly">
            </div>
            <div class="halfForm halfFormRight" v-if="isUserLogged && loaded">
                <label for="deleteAccountPass"></label>
                <input type="password" id="deleteAccountPass" class="reqTxt" placeholder="Password *" @input="comparePassword" v-model="passInput">
            </div>
            <div class="fullForm" v-if="isUserLogged && loaded">
                <!-- <button class="mlrAccountDeletion button btn_purple" :disabled="disable" @click="confirmDelete">Confirm Deletion</button> -->
            </div>
            <div v-if="error === true" id="errorDeletion" class="error">{{ errorMessage }}</div>
        </section>

        <!-- <script type="text/javascript">
            let loadcauhoi = function() {

                document.getElementById("errorDeletion").innerHTML = "";
                
                var email = document.getElementById("txtEmail").value;
                var userId = parseInt(document.getElementById("txtUserId").value);
                var password = document.getElementById("txtPassword").value;
                var token = document.getElementById("txtToken").value;
                var apiProcess = document.getElementById("apiProcess").value;

                var sendObj = {
                    "email": email,
                    "userId": userId,
                    "password": password
                }

                $.ajax({
                    type: 'POST',
                    url: '@Url.Action("DeleteUser","Account")?email='+ email +'&userId='+ userId +'&password='+ password,
                    url: apiProcess + '/1.0/PAM/DeleteUser',
                    data: JSON.stringify(sendObj),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    success: function (response) {

                        if (response.statusCode === 200){
                            document.cookie = "apiToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                            document.cookie = "TokenInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                            window.location.href = '/mylotto-rewards/my-account/my-profile/delete-your-account/account-deleted';
                        }
                        else
                        {
                            document.getElementById("errorDeletion").innerHTML = response.message;
                        }

                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        var errorElem = document.getElementById('mlrDeleteError');
                            errorElem.style.display = 'block';
                    }
                });
            }

            let deleteButton = document.getElementById('btnGet');

            deleteButton.addEventListener('click', loadcauhoi);

        </script> -->

        <button class="mlrAccountDeletion button btn_purple" id="btnGet" @click="loadcauhoi()" :disabled="disable">Confirm Deletion</button>
    </div>
</template>

<script>
    import $ from 'jquery';
    import UserLoginRegisterVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
           UserLoginRegisterVue
        },
        data: function () {
            return {
                token: '',
                user: '',
                pass: '',
                passInput: '',
                disable: true,
                error: false,
                isUserLogged: false,
                loaded: false,
                txtEmail: document.getElementById('txtEmail'),
                txtUserId: document.getElementById('txtUserId'),
                txtPassword: document.getElementById('txtPassword'),
                btnGet: document.getElementById('btnGet'),
                txtToken: document.getElementById('txtToken'),
                apiProcess: document.getElementById('apiProcess'),
                error: false,
                errorMessage: ""
            }
        },
        mounted: function () {
            this.getToken();
            this.isUserLogged = this.isUserAuthed();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.getPasswordRules();
                    this.populateFields();
                });
            },
            getPasswordRules() {
                var apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetPasswordRules';

                return fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    this.rules = json.data;
                    this.loaded = true;
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            populateFields() {
                this.txtEmail.value = this.user.user.email;
                this.txtUserId.value = this.user.user.userId;
                this.txtToken.value = this.token;
                this.apiProcess.value = process.env.VUE_APP_APIURL;
            },
            comparePassword(pass) {
                let password = this.passInput,
                    _passInput = document.getElementById('txtPassword');

                // let target = document.getElementById('btnGet');

                if (password.length >= 1) {
                    this.disable = false;
                    // target.disabled = false;
                    _passInput.value = password;
                } else {
                    this.disable = true;
                    // target.disabled = true;
                }

                this.pass = password;
            },
            async loadcauhoi() {
                // document.getElementById("errorDeletion").innerHTML = "";
                
                let email = this.user.user.email,
                    userId = this.user.user.userId,
                    password = this.passInput,
                    token = this.token,
                    apiProcess = process.env.VUE_APP_APIURL;

                var sendObj = {
                    "email": email,
                    "userId": userId,
                    "password": password
                }

                const requestData = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(sendObj)
                };

                return fetch(process.env.VUE_APP_APIURL + '/1.0/PAM/DeleteUser', requestData)
                    .then(response => response.json())
                    .then(json => {
                        if (json.statusCode === 200 && json.error === null) {
                            alert(json)
                            document.cookie = "apiToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                            document.cookie = "TokenInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                            window.location.href = '/mylotto-rewards/my-account/my-profile/delete-your-account/account-deleted';
                        }
                        else
                        {
                            this.error = true;
                            this.errorMessage = json.error.message;
                        }
                    });

                // $.ajax({
                //     type: 'POST',
                //     url: '@Url.Action("DeleteUser","Account")?email='+ email +'&userId='+ userId +'&password='+ password,
                //     url: apiProcess + '/1.0/PAM/DeleteUser',
                //     data: JSON.stringify(sendObj),
                //     headers: {
                //         'Accept': 'application/json',
                //         'Content-Type': 'application/json',
                //         'Authorization': 'Bearer ' + token
                //     },
                //     success: function (response) {

                //         if (response.statusCode === 200){
                //             document.cookie = "apiToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                //             document.cookie = "TokenInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                //             window.location.href = '/mylotto-rewards/my-account/my-profile/delete-your-account/account-deleted';
                //         }
                //         else
                //         {
                //             document.getElementById("errorDeletion").innerHTML = response.message;
                //         }

                //     },
                //     error: function (jqXHR, textStatus, errorThrown) {
                //         var errorElem = document.getElementById('mlrDeleteError');
                //             errorElem.style.display = 'block';
                //     }
                // });
            },
            confirmDelete() {
                this.txtPassword.value = this.pass;
                this.btnGet.click();
            }
		}
    };
</script>