<template>
    <div class="module winningNumbers_ArchivePicker">
        <h3 class="wnArchiveHide">You choose the date, we show the winning numbers.</h3>
        <p>Select any day from today all the way back to 2000, and see the results of all drawings in that specific day, including prize payouts. <strong>Do you want to see all winning numbers as far back as 1985?</strong> <a href="/Winning-Numbers/Check-Your-Numbers/Winning-Numbers-Archive">View archive</a>. </p>
        <datepicker :disabledDates="disabledDates" placeholder="Select Date" name="inputArchiveDate" :format="customFormatter"></datepicker>
        <button id="archiveSubmit" class="button" @click="go">GO</button>
        <div v-if="showError" class="error">Please select a date.</div>
    </div>
</template>

<script>
    // use a date picker calendar plugin
    // https://zurb.com/university/lessons/97 - not working
    // https://www.npmjs.com/package/vue-moment-datepicker - trying this one instead
    import Datepicker from "vue-moment-datepicker";
    import moment from 'moment';

    export default {
        components: {
            Datepicker
        },
        data: function () {
            return {
                disabledDates: {
                    from: new Date()
                },
                showError: false,
                archiveDate: ''
            }
        },
        mounted: function () {
            // init datepicker
        },
        methods: {
            // append date to URL string and go to location
            // go to: /winning-numbers/winning-numbers-archive
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');
            },
            go: function () {
                let archiveDate = document.querySelector("input[name=inputArchiveDate]").value
                // reformat date
                let pagePath = '/winning-numbers/winning-numbers-archive?date='

                if (archiveDate !== '') {
                    window.location.assign(pagePath + archiveDate);
                } else {
                    // show an error
                }
            }
        }
    };
</script>


<style lang="scss" scoped>
</style>