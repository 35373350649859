<template>
  <div>
      <h2>Scratch-Offs</h2>
      <div class="scratchoff_rules_list module">
        <div class="scratchoff_rules_listitem" v-for="pdfInfo in scratchOffs" :key="pdfInfo.id">
          <a :href="pdfInfo.gameRulesPDFURL" target="_blank">
          <span class="icon-doc"></span> {{ pdfInfo.gameName  }} </a>&nbsp;#{{pdfInfo.gameNumber}}
        </div>
      </div>
      <h2>EZPLAY® Games</h2>
      <div class="scratchoff_rules_list module">
        <div class="scratchoff_rules_listitem" v-for="pdfInfo in ezPlayGames" :key="pdfInfo.id">
          <a :href="pdfInfo.gameRulesPDFURL" target="_blank">
          <span class="icon-doc"></span> {{ pdfInfo.gameName  }} </a>&nbsp;#{{pdfInfo.gameNumber}}
        </div>
      </div>
      <h2>EZPLAY® Progressive Jackpot Games</h2>
      <div class="scratchoff_rules_list module">
        <div class="scratchoff_rules_listitem" v-for="pdfInfo in progressiveJackpotGames" :key="pdfInfo.id">
          <a :href="pdfInfo.gameRulesPDFURL" target="_blank">
          <span class="icon-doc"></span> {{ pdfInfo.gameName  }} </a>&nbsp;#{{pdfInfo.gameNumber}}
        </div>
      </div>
      <h2>Draw Games</h2>
      <div class="scratchoff_rules_list module">
        <div class="scratchoff_rules_listitem" v-for="pdfInfo in drawGames" :key="pdfInfo.id">
          <a :href="pdfInfo.gameRulesPDFURL" target="_blank">
          <span class="icon-doc"></span> {{ pdfInfo.gameName  }} </a>&nbsp;#{{pdfInfo.gameNumber}}
        </div>
      </div>
  </div>
</template>

<script>



export default {
  data: function () {
    return {
      onlineGame: [],
      scratchOffs: [],
      drawGames: [],
      ezPlayGames: [],
      progressiveJackpotGames: []

    };
  },
  mounted: function () {
    this.getToken();

  },
  methods: {
    getToken() {
      // then hit API with token
      this.getAPItoken()
      .then((token) => {
          this.fetchData(token);
      });

    },
    fetchData(token) {
        let apiToken = token;
        let headers = {
            'Authorization': 'Bearer '+apiToken,
        }

        Promise.all([
            fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/OnlineGames/OnlineGame/ArchivedGameRules", {
                headers,
            }).then(resp => resp.json()),
            fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/ScratchOffs/ScratchOffGame/ArchivedGameRules", {
                headers,
            }).then(resp => resp.json()),
            fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGame/ArchivedGameRules", {
                headers,
            }).then(resp => resp.json())
        ])
        .then(json => {
            this.onlineGame = json[0].data;
            this.scratchOffs = json[1].data;
            this.drawGames = json[2].data;

            let progressiveJackpotGames = []
            let EZPlayRegular = []

            this.onlineGame.filter(function (el) {
              console.log(el)
                if(el.gameType == 0){
                  progressiveJackpotGames.push(el)
                }
                else if(el.gameType == 1){
                  EZPlayRegular.push(el)
                }
              }
            );

            this.ezPlayGames = EZPlayRegular;
            this.progressiveJackpotGames = progressiveJackpotGames;


            

        })
        .catch(error => {
            // console.error("There was an error!", error);
            return this.errorMessage = error;
        });
    },    


  },
  computed: {},
};
</script>
<style lang="scss" scoped>

.scratchoff_rules_list {
    columns: 2 auto;
}

</style>