<template>
    <nav class="left-nav-menu">
        <span v-for="(menuSection, index) in menuItems" :key="index">
            <h3><a :href="menuSection.MenuItemRelativeUrl">{{menuSection.MenuItemText}}</a></h3>
            <ul id="menuElem" class="menuList menuListParent">
                <li class="menuItem" :class="{ menuItemActive: menuItem.IsActive }" v-for="(menuItem, index) in menuSection.Children" :key="index">
                    <a class="menuLink" :class="{ menuLinkActive: menuItem.IsActive }" :href="menuItem.MenuItemRelativeUrl">{{menuItem.MenuItemText}}</a>

                    <ul class="menuList menuListChild" v-if="menuItem.Children.length > 0">
                        <li class="menuItem" :class="{ menuItemActive: menuChildItem.IsActive }" v-for="(menuChildItem, index) in menuItem.Children" :key="index">
                            <a class="menuLink" :class="{ menuLinkActive: menuChildItem.IsActive }" :href="menuChildItem.MenuItemRelativeUrl">{{menuChildItem.MenuItemText}}</a>

                            <ul class="menuList menuListGrandChild" v-if="menuChildItem.Children.length > 0">
                                <li class="menuItem" :class="{ menuItemActive: menuGrandChildItem.IsActive }" v-for="(menuGrandChildItem, index) in menuChildItem.Children" :key="index">
                                    <a class="menuLink" :class="{ menuLinkActive: menuGrandChildItem.IsActive }" :href="menuGrandChildItem.MenuItemRelativeUrl">{{menuGrandChildItem.MenuItemText}}</a>
                                    
                                    <ul class="menuList menuListGreatGrandChild" v-if="menuGrandChildItem.Children.length > 0">
                                        <li class="menuItem" :class="{ menuItemActive: menuGreatGrandChildItem.IsActive }" v-for="(menuGreatGrandChildItem, index) in menuGrandChildItem.Children" :key="index">
                                            <a class="menuLink" :class="{ menuLinkActive: menuGreatGrandChildItem.IsActive }" :href="menuGreatGrandChildItem.MenuItemRelativeUrl">{{menuGreatGrandChildItem.MenuItemText}}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </span>
    </nav>
</template>

<!-- 
JSON Strucure for menu
[
    {
        "MenuItemText":"Draw Games",
        "MenuItemRelativeUrl":"~/games/draw-games",
        "Children":[],
        "NodeLevel":2,
        "NodeID":76,
        "NodeParentID":73
    }
]
-->

<script>
    //import { defineComponent } from '@vue/composition-api'
    import $ from 'jquery';
    let leftMenuData = $('#menuDiv').data('json');
    if(leftMenuData == 'undefined'){
        leftMenuData = '';
    }

    export default {
        components: {

        },
        data: function () {
            return {
                menuItems: [],
                // Turn staticExceptions into a dynamic list later
                staticExceptions: [
                    "mylotto-rewards/redemption-central/drawings",
                    "mylotto-rewards/redemption-central/coupons-and-lottery-cash",
                    "mylotto-rewards/redemption-central/catalog",
                    "mylotto-rewards/redemption-central/digital-catalog",
                    "mylotto-rewards/redemption-central/interactive-games"
                ],
                leftMenuJson: leftMenuData
            }
        },
        mounted: function () {
            this.loadLeftMenu();
        },
        methods: {
            loadLeftMenu() {
                let activePage = window.location.pathname.toLowerCase();
                // sort with deepest level first
                this.leftMenuJson.sort(function (a, b){
                    return b.NodeLevel - a.NodeLevel;
                });
                // move children into their parents
                this.leftMenuJson.forEach((element) => {
                    if (element.NodeLevel > 1) {
                        // is this an active item?
                        let thisURL = element.MenuItemRelativeUrl.toLowerCase();
                        if (activePage === thisURL) {
                            // this should only target the exact page that is active
                            element.IsActive = true;
                        } else {
                            this.staticExceptions.forEach((urlString) => {
                                let activeUrl = thisURL,
                                    compareUrlIndex = activeUrl.indexOf(urlString);
                                // check if element is on current path 
                                if (compareUrlIndex > -1 && element.MenuItemRelativeUrl[urlString.length + 1] != "/" && activePage.indexOf(activeUrl) > -1) {
                                    element.IsActive = true;
                                }
                            })
                        }
                        
                        if (element.IsActive === undefined) {
                            element.IsActive = false;
                        }
                        let parentID = element.NodeParentID;
                        // find in menu
                        let parentNode = this.leftMenuJson.find(x => x.NodeID === parentID);
                        // put into Children[] of parent and remove child
                        if (parentNode === undefined){
                            // This item most likely has an unpublished parent.
                            // Don't do anything
                            // this.menuItems.push(element);
                            // console.log('excepted nav item: ');
                            // console.log(element);
                        } else {
                            // put it into the child list for the parent menu
                            parentNode.Children.push(element);
                            // make parent node active
                            if (element.IsActive) {
                                parentNode.IsActive = true;
                            // } else {
                            //     parentNode.IsActive = false;
                            }
                        }
                    } else {
                        // top level items
                        this.menuItems.push(element);
                    }
                });
                // console.log(this.menuItems);
                // this.menuItems = this.leftMenuJson;
            }
        },
        computed: {
        
        }
    }
</script>