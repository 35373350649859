import Vue from "vue";

import DashboardChains from "./dashboard-chains.vue";

export function load() {
    const selector = "dashboard-chains";

    // Is the custom Vue root element in the DOM?
    if (!document.querySelector(selector)) {
        return; // go no further
    }

    // Create a new Vue app with the imported component
    new Vue({
        render: createElement =>
            createElement(DashboardChains, {
                props: {
                    ...window[selector]
                }
            }),
    }).$mount(selector);
}
