<template>
    
    <div class="bydate-wrapper">

        <datepicker 
            :disabledDates="disabledDates"
            placeholder="Select Date" 
            name="inputKenoDate"
            :format="customFormatter"
            >
        </datepicker>
    </div>
    
       
</template>

<script>
    import moment from 'moment';
    import Datepicker from "vue-moment-datepicker";

    export default {
        components: {
           Datepicker
        },
        data: function () {
            return {
                disabledDates: {
                    from: new Date()
                },

            }
        },
        methods:{
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');
            },
        }
           
        
    };
</script>

<style>
.bydate-wrapper{
    padding: 2.5rem 2.5rem 0 2.5rem;
}
</style>