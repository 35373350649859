<template>
    <div class="game_home_wrapper">
        <ul id="keno_numbers" class="cf winningNumbers">	
			<li class="kenoNumbers">
				<a href="/games/keno" class="gameLogo gameLogo--KENO ir">KENO</a>
				<div class="winningNumbersContent">
					<span class="jackpot">
                        <span class="wnLbl">Current Drawing:</span> <span class="drawNumber wnVal">{{currentDrawing}}</span>
                    </span>
					<span class="booster">
                        <span class="wnLbl">Booster:</span> <span class="kenoBoosterBall wnVal"> {{booster}} </span>
                    </span>
					<span class="numbers cf">

                        <div class="winningBall" v-for="number in numbers" :key="number.id">
                            {{ number.value }}
                        </div>
                    </span>
					<span class="updateFreq">Draws occur every few minutes</span>
				</div>
				<!-- <a class="btn_purple btn_pastDraws" href="/WinningNumbers/KenoDrawings/KenoDrawingsArchive">Past Draws</!-->
			</li>
		</ul>
    </div>
</template>


<script>

    

    export default {
        components: {
            
        },
        data: function () {
            return {
                currentDrawing: '',
                numbers: [],
                booster: ''

            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                let apiToken = token;
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/Keno/GetLatestDraws", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let results = json.data;
                    this.currentDrawing = results[0].externalDrawId;
                    this.numbers = results[0].numbers;
                    // console.log(this.numbers);
                    let boostPos = '';
                    for (let i = this.numbers.length - 1; i >= 0; i--) {
                        if (this.numbers[i].modifier == 4) {
                            this.booster = this.numbers[i].value;
                            boostPos = i;
                        }
                    };
                    // remove from array
                    this.numbers.splice(boostPos, 1);
                    // console.log('booster: '+this.booster);
                    
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            }
        }
    };
</script>

<style lang="css" scoped>


</style>