<template>
    <div id="progressive_jackpot"
        class="grid-x grid-margin-x small-up-2 medium-up-2 large-up-4 progressive-jackpot-wrap">
        <div v-for="(games, index) in progressiveGames" :key="games.id" :class="{'winningNumbersItem': true}">
            <div class="winningNumbersWrap">
                <a :href="setGameLink(games.name)"><img :src="games.logoURL" :alt="games.name"
                        class="winNumGameLogo"></a>
                <div>
                    <span class="jackpot-current">Current Jackpot: <strong>${{ numberWithCommas(games.jackpot)
                            }}</strong></span>
                    <span class="jackpot-date">{{ customFormatter(games.jackpotLastUpdatedDate) }}</span>
                    <span class="jackpot-update">Jackpot updates every 2 minutes</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    components: {
    },
    data: function () {
        return {
            progressiveGames: []
        }
    },
    mounted: function () {
        this.getAPItoken()
            .then((token) => {
                this.fetchData(token);
            });
    },
    methods: {
        customFormatter(date) {
            return moment(date).format('MM/DD/yyyy') + ' ' + moment(date).format('LT');
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        fetchData(token) {
            var apiToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/OnlineGames/OnlineGame/GetProgressiveGames", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    let response = json.data;
                    this.progressiveGames = response;
                    this.findHighestJackpot();

                    // console.log(response)
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
        },
        findHighestJackpot() {
            this.progressiveGames.forEach(function (item) {
                if (item.games.length > 1) {
                    let jackpotArray = [];
                    item.games.forEach(function (gameItem) {
                        jackpotArray.push(gameItem.jackpotAmount);
                    });
                    jackpotArray.sort(function (a, b) { return a - b });
                    let jpVal = jackpotArray.pop();
                    item.jackpot = jpVal;
                }
            });
        },
        setGameLink(name) {
            let gameURL = '';
            switch (name) {
                case "Lucky Numbers":
                    gameURL = "/games/ezplay-progressive-jackpot/lucky-numbers";
                    break;
                case "Going Pro":
                    gameURL = "/games/ezplay-progressive-jackpot/going-pro";
                    break;
                case "Ohio Jackpot 7's":
                    gameURL = "/games/ezplay-progressive-jackpot/ohio-jackpot-7s";
                    break;
                case "Perfect Game 300":
                    gameURL = "/games/ezplay-progressive-jackpot/perfect-game-300";
                    break;
                case "Quick Spot":
                    gameURL = "/games/ezplay-progressive-jackpot/quick-spot";
                    break;
                case "Rock'n 777's":
                    gameURL = "/games/ezplay-progressive-jackpot/rock-n-777s";
                    break;
                case "Twenty 20's":
                    gameURL = "/games/ezplay-progressive-jackpot/twenty-20s";
                    break;

                default:
                    "/games/ezplay-progressive-jackpot";
                    break;
            }
            return gameURL;
        }
    }
};
</script>


<style lang="scss" scoped></style>