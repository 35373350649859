<template>
    <div class="check_your_numbers_panel" :class="gameName">
        <h3>See Past {{ titleGameName }} Draw Results</h3>
        <div class="panel_content">
            <div class="pastDraws_form">
                <div class="grid-x">
                    <p class="drawInfo cell">{{ descGame }}</p>

                    <div class="pastDraws_option cell small-12 medium-6">
                        <div class="grid-x">
                            <div class="pastDraws_txt cell small-12 large-7">
                                See up to 180 days of past draw results.
                            </div>
                            <div class="cell small-12 large-5">
                                <button class="button" @click="go">Go</button>
                            </div>
                        </div>
                    </div>
                    <div class="pastDraws_option cell small-12 medium-6">
                        <div class="grid-x">
                            <div class="pastDraws_txt cell small-12 large-7">
                                Download 180 days of results as a spreadsheet.
                            </div>
                            <div class="cell small-12 large-5">
                                <button class="button" @click="download180">Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pastDraws_results" v-if="showResults">
                <table cellpadding="0" cellspacing="0" border="0" class="past_winning_numbers_results">
                    <tbody>
                        <tr class="pastDraws_results_head">
                            <th v-for="thead in theadNamesArr" :key="thead">
                                {{ thead }}
                            </th>
                        </tr>

                        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9"
                            v-if="isLoading" />

                        <tr v-for="draw in pageOfItems" v-bind:key="draw.id" class="pastDraws_results_row"
                            v-if="!showPickGamesTable">

                            <td> {{ draw.drawDate | moment("ddd, MM/DD/YYYY") }} </td>
                            <td v-if="pickDate !== null">{{ draw.modifier | pickToD }}</td>
                            <td>
                                <ul class="winningNumbersList">
                                    <li v-for="number in spliceArray(draw.numbers)" v-bind:key="number.id"
                                        class="winNumBall">
                                        {{ number.value }}
                                    </li>
                                </ul>
                            </td>
                            <td v-if="pickDate === null" class="pastDraws_modBall" :class="'pastDraws_modBall_' + gameName">
                                <ul class="winningNumbersList">
                                    <li class="winNumBall">
                                        {{ getMultiplier(draw.numbers) }}
                                    </li>
                                </ul>
                            </td>
                            <td v-if="gameName == 'megaMillions'" class="pastDraws_jackpot prizePool">${{
                                numberWithCommas(draw.prizePool) }}</td>
                            <td v-if="gameName == 'powerBall'" class="pastDraws_jackpot prizePool">${{
                                numberWithCommas(draw.prizePool) }}</td>
                            <td v-if="gameName == 'rollingCashFive'" class="pastDraws_jackpot prizePool">${{
                                numberWithCommas(draw.prizePool) }}</td>
                            <td v-if="gameName == 'rollingCashFive'" class="pastDraws_jackpot prizePool">${{
                                numberWithCommas(draw.prizePayout) }}</td>  
                            <td v-if="gameName == 'classicLotto'" class="kicker-td">{{ draw.extendedNumbers.map(item =>
                                `${item.value}`).toString().replaceAll(',', '') }}</td>
                            <!-- <td v-if="gameName !== 'luckyForLife'" class="pastDraws_jackpot">${{
                                numberWithCommas(draw.prizePool) }}</td> -->
                                <td v-if="gameName == 'classicLotto'" class="pastDraws_jackpot">${{
                                numberWithCommas(draw.prizePool) }}</td>
                            <td v-if="gameName == 'classicLotto'" class="pastDraws_jackpot">${{
                                numberWithCommas(draw.prizePayout) }}</td>
                        </tr>

                        <tr v-for="draw in pageOfItems" v-bind:key="draw.id" class="pastDraws_results_row"
                            v-if="showPickGamesTable">

                            <td class="dateCol"> {{ draw.drawDate | moment("ddd, MM/DD/YYYY") }} </td>

                            <td class="drawCol">
                                <span v-if="draw.modifier == 2">Evening</span> <span v-if="draw.modifier == 1">Mid
                                    Day</span>
                            </td>

                            <td class="numberCol">
                                <ul class="winningNumbersList">
                                    <li v-for="number in spliceArray(draw.numbers)" v-bind:key="number.id"
                                        class="winNumBall">
                                        {{ number.value }}
                                    </li>
                                </ul>
                            </td>
                            
                            <td v-if="draw.prizePayout !== null" class="pastDraws_jackpot">${{
                                numberWithCommas(draw.prizePayout) }}</td>
                        </tr>



                        <!-- <tr class="pastDraws_results_row">
                            <td class="pastDraws_date">Tuesday, 5/18/2021</td>
                            <td class="pastDraws_numbers">
                                <div class="numbers_wrap">
                                    
                                        
                                </div>
                            </td>
                            <td class="pastDraws_megaplier">	
                                2
                            </td>
                            <td class="pastDraws_jackpot">{{ draw.jackpotWon }} </td>
                        </tr> -->
                    </tbody>
                </table>

                <div class="cyn_note">
                    (<span class="asterisk">*</span>) For payout information prior to July 1, 2009, check the <a
                        href="/winning-numbers/check-your-numbers/winning-numbers-archive">Archive file</a>.
                </div>

                <jw-pagination :items="draws" @changePage="onChangePage" :pageSize="20"
                    :class="[hidePagination ? 'hidding-class' : '']" :labels="customLabels"
                    :styles="customStyles"></jw-pagination>
            </div>
            <span class="disclaimer">
                (*) For payout information prior to July 1, 2009 check the <a href="/winning-numbers/check-your-numbers/winning-numbers-archive">Archive File</a>
            </span>
        </div>
    </div>
</template>

<script>
import JwPagination from 'jw-vue-pagination';
import { HollowDotsSpinner } from 'epic-spinners'
import moment from 'moment';

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};

const customStyles = {

    li: {
        display: 'inline-block'
    }
}

export default {
    components: {
        JwPagination,
        HollowDotsSpinner
    },
    props: {
        formatPickDate: {
            Type: Boolean,
            default: null
        },
        gameName: String,
        titleGameName: String,
        descGame: String,
        theadNames: Array

    },
    // props: [
    //     'gameName', 
    //     'titleGameName', 
    //     'descGame', 
    //     'theadNames', 
    //     'show' ],
    data: function () {
        return {
            game: this.gameName,
            draws: [],
            pageOfItems: [],
            theadNamesArr: this.theadNames,
            showResults: false,
            pickDate: this.formatPickDate,
            hasMegaPlier: {},
            hidePagination: false,
            isLoading: false,
            showPickGamesTable: false,
            customLabels,
            customStyles
        }
    },
    mounted: function () {
    },
    methods: {
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        go() {
            this.showResults = true;
            this.isLoading = true;
            this.getToken();
        },
        download180() {
            this.getAPItoken()
                .then((token) => {
                    this.fetchDownload(token);
                });

        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(token) {
            var apiToken = token;
            var urlProtocol = this.getProtocol();
            var gameNameApi = this.game;

            var sinceDate = moment(moment().subtract(180, 'days')).format("MM-DD-YYYY");
            var toDate = moment().format("MM-DD-YYYY");

            // alert('Mega token: '+token);
            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + gameNameApi + "/SearchDrawDateRange?sinceDate=" + encodeURIComponent(sinceDate) + "&toDate=" + encodeURIComponent(toDate), {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    var arrOfResults = results.draws

                    if (this.game.indexOf('pick') >= 0) {
                        this.showPickGamesTable = true;
                    } else {
                        this.showPickGamesTable = false;
                    }

                    this.draws = arrOfResults;
                    this.isLoading = false;
                    if (arrOfResults.length <= 8) {
                        this.hidePagination = true
                    }
                })
                .catch(error => {
                    return this.errorMessage = error;
                });
        },
        fetchDownload(token) {
            var apiToken = token;
            var urlProtocol = this.getProtocol();
            var gameNameApi;
            this.game === 'megaMillions' ? gameNameApi = 'MegaMillions' : '';
            this.game === 'powerBall' ? gameNameApi = 'PowerBall' : '';
            this.game === 'pick3' ? gameNameApi = 'Pick3' : '';
            this.game === 'pick4' ? gameNameApi = 'Pick4' : '';
            this.game === 'pick5' ? gameNameApi = 'Pick5' : '';
            this.game === 'luckyForLife' ? gameNameApi = 'LuckyForLife' : '';
            this.game === 'rollingCashFive' ? gameNameApi = 'RollingCashFive' : '';
            this.game === 'classicLotto' ? gameNameApi = 'ClassicLotto' : '';

            return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + gameNameApi + "/GetNDaysExport?daysFromNow=180", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.blob())
                .then(blob => {
                    const currentDate = new Date();
                    const formattedDate = `${currentDate.getFullYear()}_${(currentDate.getMonth() + 1).toString().padStart(2, '0')}_${currentDate.getDate().toString().padStart(2, '0')}`;
                    const link = document.createElement('a');

                    link.setAttribute('download', gameNameApi + '_' + formattedDate + '.csv');
                    const href = URL.createObjectURL(blob);
                    
                    link.href = href;
                    link.setAttribute('target', '_blank');
                    link.click();

                    URL.revokeObjectURL(href);
                })
                .catch(error => {
                    return this.errorMessage = error;
                });
        },
        onChangePage(pageOfItems) {
            this.pageOfItems = pageOfItems;
        },
        spliceArray(arr) {
            // remove last position of array = multiplier value 
            if (this.game === "megaMillions" || this.game === "powerBall") {
                arr = arr.slice(0, -1);
                return arr
            } else {
                return arr
            }
        },
        getMultiplier(arr) {
            //console.log(this.game)
            // return last position of array for multiplier value
            if (this.game === "megaMillions" || this.game === "powerBall") {
                arr = arr.slice(-1)[0];
                //console.log(arr)
                return arr.value
            } else {
                return ''
            }
        },

    }

};

</script>

<style scoped>
.hidding-class {
    display: none !important;
}

.hollow-dots-spinner {
    width: 90px;
    position: absolute;
    left: 45%;
    margin-top: 20px;
}

.pastDraws_modBall_classicLotto,
.pastDraws_modBall_rollingCashFive {
    display: none;
}
.disclaimer {
    font-size: 1.2rem;
}
.disclaimer a {
    height: auto;
}
</style>



