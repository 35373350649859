<template>
    <div class="eApp">
        <header id="eAppHeader" class="cf">
            <h1 class="cf">Retailer Network</h1>
        </header>
        <SignInRegister v-if="!isLoading && !isUserLogged"></SignInRegister>

        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />
        <div v-if="!isLoading && isUserLogged && updateFound" class="eAppUpdates">

            <div class="eAppHeader cf">
                <!-- ifIsInRole: eApp-Retailer || eAppPrincipal -->
                <a v-if="showApplicationUpdatesHome" class="button eAppDashBtn" href="/eApplications/Updates">Application
                    Updates Home</a>
                <!-- ifIsInRole: eApp-Retailer && eApp-ChainApplicant -->
                <a v-if="showApplicationsHome" class="button eAppDashBtn" href="/eApplications">Application Home</a>
                <!-- ifIsInRole: eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-RegionalManager || eAppDistrictRep || eApp-Security -->
                <a v-if="showApplicationDashboard" class="button eAppDashBtn"
                    href="/eApplications/Dashboard/Regular">Application Dashboard</a>
                <!-- ifIsInRole: eApp-ChainSalesRep || eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-Security -->
                <a v-if="showChainApplicationDashboard" class="button eAppDashBtn"
                    href="/eApplications/Dashboard/Chain">Chain Application Dashboard</a>

                <h1>Agent Number: <strong>{{ updateWrapper.agentNumber }}</strong></h1>
                <h3 style="clear:both;">Batch Number: <strong>{{ updateWrapper.batchNumber }}</strong></h3>

            </div>
            <div class="cf updateCreateForm module">
                <h3>EFT / Bank Information</h3>
                <div class="moduleContent cf">

                    <div v-if="hasErrors" class="ErrorLabel alert-error">
                        There were errors:
                        <ul>
                            <template v-for="error in errors">
                                <li v-if="error !== ''">
                                    {{ error }}
                                </li>
                            </template>
                        </ul>
                    </div>

                    <EFTUpdate :eftUpdate="eftUpdate" :role="isEFT" :token="token" :userId="userId" :showSecondary="showSecondary" :readOnly="!showSubmitButton" @rejectedSecondary="rejectedSecondary">
                    </EFTUpdate>
                    <br><br>

                    <div class="formRow checkboxRow cell">
                        <span class="req"></span>
                        <input type="checkbox" id="bankInformationProcessed" name="bankInformationProcessed"
                            v-model="eftUpdate.bankInformationProcessed">
                        <label for="bankInformationProcessed">Bank Information Processed</label>
                    </div>

                    <div class="formRow cell">
                        <span>EFT Information Approved:</span>
                        <span class="radio radio-list-vertical">
                            <div class="radio">
                                <input type="radio" id="eftInformationApprovedTrue" name="eftInformationApproved" value="true" v-model="eftUpdate.eftInformationApproved">
                                <label for="eftInformationApprovedTrue">Approved</label>
                            </div>
                            <div class="radio">
                                <input type="radio" id="eftInformationApprovedFalse" name="eftInformationApproved" value="false" v-model="eftUpdate.eftInformationApproved">
                                <label for="eftInformationApprovedFalse">Rejected</label>
                            </div>
                        </span>
                    </div>
                        
                    <div class="updatesActions eftReview-submit" v-if="actionsAllowed && isEFT">
                        <input type="button" @click="saveClick()" value="Save" class="button eAppBtnSubmit btnSave" />
                    </div>

                    <div class="updatesActions eftReview-submit" v-if="actionsAllowed">
                        <button v-if="showRejectButton" class="button btn-secondary eAppBtnSubmit" data-open="rejectModal">Reject</button>
                        <button v-if="showApproveButton" class="button btn-secondary eAppBtnSubmit" data-open="approveModal">Approve</button>
                        <button v-if="showSubmitButton" class="button eAppBtnSubmit" @click="submitClick()">Submit</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="reveal small eAppModal" id="rejectModal" data-reveal>
            <button class="close-button" data-close aria-label="Close modal" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
            <h2>Reject EFT Update</h2>
            <div class="toApplicant">
                <p>Choose the reason(s) for rejection to be included in a message to the retailer:</p>
                <input type="checkbox" id="badRouting" v-model="badRouting" /><label for="badRouting">Bad Routing number</label>
                <br>
                <input type="checkbox" id="badAccount" v-model="badAccount" /><label for="badAccount">Bad Account name</label>
            </div>
            <div class="eAppModalBtns">
                <button class="button btnCancel" data-close aria-label="Cancel" type="button">Cancel</button>
                <button class="button" aria-label="Reject" type="button" @click="rejectClick()">Reject</button>
            </div>
            <div v-if="eftRejectError" class="error">Please provide the reason(s) for rejection.</div>
        </div>

        <div class="reveal small eAppModal" id="approveModal" data-reveal>
            <button class="close-button" data-close aria-label="Close modal" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
            <h2>Approve Update</h2>
            <p>Do you wish to approve the Update and move it one step closer to final approval?</p>
            <br>
            <div class="eAppModalBtns">
                <button class="button btnCancel" data-close aria-label="Cancel" type="button">Cancel</button>
                <button class="button" aria-label="Approve" type="button" @click="approveClick()">Approve</button>
                
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
// import moment from "moment";
import { HollowDotsSpinner } from 'epic-spinners';
import SignInRegister from '/ViewComponents/SignInRegister/SignInRegister.vue';
import EFTUpdate from '/Views/Eapplication/updates/update-eft.vue';

export default {
    components: {
        HollowDotsSpinner,
        SignInRegister,
        EFTUpdate,
    },
    data() {
        return {
            isUserLogged: false,
            updateGUID: '', // primary identifier            
            updateWrapper: {}, // JSON for entire Update
            isUserAllowed: false, // proper role and identified on the Update
            errors: [],
            hasErrors: false,
            isLoading: false,
            updateFound: false,
            userId: 0,
            isEFT: false,
            agentId: '0',
            token: '',
            readOnly: false,
            eftUpdate: {},
            showEftUpdate: false,
            hasRenewal: false,
            badAccount: false,
            badRouting: false,
            renewal: {},
            showSubmitButton: false,
            showApproveButton: false,
            showRejectButton: false,
            showApplicationUpdatesHome: false,
            applicantRole: ['eApp-Retailer'],
            applicationUpdatesHomeRoles: ['eApp-Retailer', 'eAppPrincipal'],
            showApplicationsHome: false,
            applicationsHomeRoles: ['eApp-Retailer', 'eApp-ChainApplicant'],
            showApplicationDashboard: false,
            applicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eAppDistrictRep', 'eApp-EFTReviewer', 'eApp-RegionalManager', 'eApp-Security'],
            showChainApplicationDashboard: false,
            chainApplicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eApp-EFTReviewer', 'eApp-Security', 'eApp-ChainSalesRep', 'eApp-RegionalManager'],
            actionsAllowed: false,
            rejectModal: false,
            eftRejectError: false,
            successRedirect: '/eapplications/dashboard/regular?appType=updates',
            showSecondary: false
        }
    },
    mounted: function () {
        $(document).foundation();

        // get the user ID
        this.isUserLogged = this.isUserAuthed();
        this.userId = this.getLoggedInUserId();
        // this.userId = 130; // TESTING EFT
        this.showButtons();
        if (this.isUserLogged) {
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getContent();
                });
        }
        this.isLoading = false;

    },
    computed: {
        GET_UPDATE_WRAPPER_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/user/' + this.userId + '/reviewupdate/' + this.updateGUID;
        },
        SAVE_EFT_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SaveEft';
        },
        APPROVE_EFT_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/ApproveUpdateEft';
        },
        REJECT_EFT_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/RejectUpdateEftToApplicant';
        },
        SUBMIT_EFT_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SubmitUpdateEft';
        }
    },
    methods: {
        rejectedSecondary(data) {
            this.showSecondary = data;
        },
        getContent() {
            this.isLoading = true;
            this.tryGetFromQueryString().then(() => {
                this.getUpdateWrapper();
            });
        },
        showButtons() {
            this.applicationDashboardRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationDashboard = true;
                }
            });

            this.chainApplicationDashboardRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showChainApplicationDashboard = true;
                }
            });

            this.applicationUpdatesHomeRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationUpdatesHome = true;
                }
            });

            this.applicationsHomeRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationsHome = true;
                }
            });

            this.applicantRole.forEach(element => {
                console.log(this.isUserInRole(element));
                if (this.isUserInRole(element)) {
                    this.showRejectButton = false;
                    this.showApproveButton = false;
                    this.showSubmitButton = true;
                }
            });

            if (this.isUserInRole('eApp-EFTReviewer')) {
                this.isEFT = true;
                this.showRejectButton = true;
                this.showApproveButton = true;
                this.showSubmitButton = false;
            }

        },
        getUpdateWrapper() {
            if (this.updateGUID == '') {
                this.updateFound = false;
            } else {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    this.userId = uId;
                    this.fetchData();
                }
            }
        },
        async fetchData() {
            const appsRequestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
            };
            this.updateWrapper = await (await fetch(this.GET_UPDATE_WRAPPER_API_URL, appsRequestOptions)).json();

            if (this.updateWrapper) {
                this.updateFound = true;
            } else {
                // using "hasRenewalUpdates" because we don't need to even see this form if there is not an update
                this.updateFound = false;
            }
            this.parseUpdate();
        },
        parseUpdate() {
            // all paths lead here before showing anything

            this.agentId = this.updateWrapper.agentNumber;

            // get general Update status:
            // updateSubmitted?
            this.readOnly = this.updateWrapper.updateSubmitted;
            // show read-only version of everything if true

            // is EFT allowed to take action?
            if (this.updateWrapper.eftUpdate.elementStatus === 1) {
                this.actionsAllowed = true; 
            }
            // is renewal?
            this.hasRenewal = this.updateWrapper.hasRenewal;

            // Get the data for each form:
            //eft
            if (this.updateWrapper.eftUpdate !== null) {
                this.eftUpdate = this.updateWrapper.eftUpdate;
            }
            else {
                this.eftUpdate = {};
            }
            this.isLoading = false;
        },
        async saveClick() {
            this.isLoading = true;
            let body = {};
            body.updateWrapperGuid = this.updateGUID;
            body.userId = this.userId;
            body.eft = this.eftUpdate;
            //
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString();
            if (body.eft.eftInformationApproved) {
                body.eft.eftApprovalDate = formattedDate;
                body.eft.eftRejectionDate = '0001-01-01T00:00:00';
            } else {
                body.eft.eftApprovalDate = '0001-01-01T00:00:00';
                body.eft.eftRejectionDate = formattedDate;
            }

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            await (await fetch(this.SAVE_EFT_API_URL, appsRequestOptions)).json();
            this.isLoading = false;
            this.errors = [];
            this.hasErrors = false;

            return Promise.resolve();
        },
        async rejectClick() {
            this.eftRejectError = false;
            let body = {};
            let message = '';
            
            if (this.badRouting || this.badAccount) {
                this.isLoading = true;
                message += '<ul>';
                if (this.badRouting) {
                    message += '<li>Bad Routing number</li>';
                }

                if (this.badAccount) {
                    message += '<li>Bad Account name</li>';
                }

                message += '</ul>';

                body.updateWrapperGuid = this.updateGUID;
                body.userId = this.userId;
                body.message = message;

                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        body
                    )
                };

                let response = await (await fetch(this.REJECT_EFT_API_URL, appsRequestOptions)).json();
                // redirect if successful
                if (response.errorList.length > 0) {
                    this.errors = response.errorList;
                    this.hasErrors = true;
                    this.isLoading = false;
                    this.closeModal('#rejectModal');
                }
                else {
                    window.location.assign(this.successRedirect);
                } 

                // fetch(this.REJECT_EFT_API_URL, appsRequestOptions)
                //     .then((response) => response.json())
                //     .then((json) => {
                //         console.log(json);
                //         if (json.errorList.length) {
                //             this.submitErrors = true;
                //             this.errorList = json.errorList;
                //             this.closeModal('#rejectModal');
                //         } else {
                //             this.submitErrors = false;
                //             this.errorList = [];
                //             window.location = window.location.origin + this.successRedirect;
                //         }
                //     });

                return Promise.resolve();
            } else {
                this.eftRejectError = true;
                this.loading = false;
            }
        },
        async approveClick() {
            this.errors = [];
            this.isLoading = true;
            let body = {};
            body.updateWrapperGuid = this.updateGUID;
            body.userId = this.userId;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            let response = await (await fetch(this.APPROVE_EFT_API_URL, appsRequestOptions)).json();
                // .then((response) => response.json())
                // .then((json) => {
                    // console.log(json);
                    // if (json.errorList.length) {
                    //     this.submitErrors = true;
                    //     this.errorList = json.errorList;
                    // } else {
                    //     this.submitErrors = false;
                    //     this.errorList = [];
                        // window.location = window.location.origin + "/eapplications/Dashboard/Regular";
                    // }
                // });

            // redirect if successful
            if (response.errorList.length > 0) {
                this.errors = response.errorList;
                this.hasErrors = true;
                this.isLoading = false;
                this.closeModal('#approveModal');
            }
            else {
                window.location.assign(this.successRedirect);
            } 

            return Promise.resolve();
        },
        async submitClick() {
            this.isLoading = true;
            let body = {};
            this.updateWrapper.eftUpdate = this.eftUpdate;
            body.updateWrapper = this.updateWrapper;
            body.userId = this.userId;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            await (await fetch(this.SUBMIT_EFT_API_URL, appsRequestOptions)).json();
            this.isLoading = false;

            return Promise.resolve();
        },
        tryGetFromQueryString() {
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);

            if (urlParams.has('updateId')) {
                this.updateGUID = urlParams.get('updateId');
            }

            if (urlParams.has('an')) {
                this.agentId = urlParams.get('an');
            }

            return Promise.resolve();
        },
        closeModal(target) {
            this.isLoading = false;
            this.eftRejectError = false;
            $(target).hide();
            $('.reveal-overlay').hide();
        }
    }
}
</script>
<style lang="scss" scoped>
    .updateCreateForm {
        padding-top: 20px;
    }
    .btnSave {
        margin-bottom: 150px;
    }
    .toApplicant {
        padding-bottom: 20px;
    }
    .eAppModalBtns .btnCancel.button {
        float: right;
    }

</style>