import Vue from "vue";

import ArchivePicker from "./Winning-Numbers-Archive-Picker.vue";

export function load() {
    const selector = "wn-archive-picker";

    // Is the custom Vue root element in the DOM?

    if (!document.querySelector(selector)) {
        return;
    }

    // Create a new Vue app with the imported component

    new Vue({
        render: createElement =>
            createElement(ArchivePicker, {
                props: {
                    ...window[selector],
                },
            }),
    }).$mount(selector);
}
