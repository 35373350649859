<template>
    <div>
        <p class="tracker-disclaimer" v-if="submitted">The below tracking meter shows the steps required to process your application. Those checked in green have already been completed, with the unchecked steps in purple still pending lottery review and approval.</p>
        <div class="eAppTracker_module eAppTrack_status" :data-appstatus="appStatusId" v-if="submitted">
            <ul class="eAppLabels eAppTracker_container">
                <li>
                    <p class="eAppTitle">Submitted</p>
                </li>
                <li>
                    <p class="eAppTitle">Sales Rep.</p>
                </li>
                <li>
                    <p class="eAppTitle">Regional Mngr.</p>
                </li>
                <li id="eApp-SecurityLabel">
                    <p class="eAppTitle">Security / EFT</p>
                </li>
                <li>
                    <p class="eAppTitle">Licensing</p>
                </li>
                <li>
                    <p class="eAppTitle">Central Sales</p>
                </li>
            </ul>
            <ul class="eAppTracker_container">
                <li class="eAppTracker eAppTrack-appSub" data-step="2" v-if="!error">
                    <div class="segment" :class="{ eApp_approved : (appStatusId >= 2)}">
                        <div class="segment_body"></div>
                    </div>
                </li>
                <li class="eAppTracker eAppTrack-salesRepAssigned" data-step="3" v-if="!error">
                    <div class="segment" :class="{ eApp_approved : (appStatusId >= 3)}">
                        <span class="check eappTrack-1"></span>
                        <div class="segment_body"></div>
                    </div>
                </li>
                <li class="eAppTracker eAppTrack-regionalManager" data-step="4" v-if="!error">
                    <div class="segment" :class="{ eApp_approved : (appStatusId >= 4)}">
                        <div class="segment_body"></div>
                    </div>
                </li>
                <li class="eAppTracker eAppTrack-security_EFT" data-step="5" v-if="!error">
                    <div class="segment" :class="{ eApp_approved : (appStatusId >= 5)}">
                        <div class="segment_body"></div>
                    </div>
                </li>
                <li class="eAppTracker eAppTrack-licensing" data-step="6" v-if="!error">
                    <div class="segment" :class="{ eApp_approved : (appStatusId >= 6)}">
                        <div class="segment_body"></div>
                    </div>
                </li>
                <li class="eAppTracker eAppTrack-centralSales" data-step="10" v-if="!error">
                    <div class="segment" :class="{ eApp_approved : (appStatusId >= 10)}">
                        <div class="segment_body"></div>
                    </div>
                </li>
                <li class="eAppTracker_error" v-if="error">An issue has occured with your application. Please contact your sales
                    rep for more information.</li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        components: {},
        props: {
            appStatus: Number
        },
        data: function () {
            return {
                appStatusName: '',
                error: false,
                submitted: false,
                appStatusId: 0
            }
        },
        mounted: function () {
            this.getAppStatus(this.appStatus);
        },
        methods: {
            getAppStatus(statusCode) {
                this.appStatusId = statusCode;
                // console.log(statusCode);
                if (statusCode > 1) {
                    this.submitted = true;
                    // show these status all as 3
                    if (statusCode == 12 || statusCode == 7 || statusCode == 13 || statusCode == 14 ) { 
                        this.appStatusId = 3 
                    } else if (statusCode == 11) {
                        // hide result and show error
                        this.error = true;
                    }
                }
                // not using this anywhere yet
                this.appStatusName = this.getEappStatus(statusCode);
            }
        }
    }

</script>

<style>
.tracker-disclaimer {
    text-align: center;
    font-size: .6em;
    max-width: 60%;
    margin: auto;
    font-weight: 600;
}
</style>