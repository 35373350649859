<template>
    <section class="globalDisclaimers">
        <div v-html="terms" id="loginTermsBox" class="disclaimerScroll"></div>
    </section>
</template>

<script>
    import $ from 'jquery';
    import moment from "moment";

    export default {
        data: function () {
            return {
                mobileCashing: false,
                token: '',
                terms: ''
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getTerms();
                });
            },
            getTerms() {
                let apiToken = this.token,
                    sku = this.getURLQuery('orderId'),
                    url = process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=mlr-terms-conditions';

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    this.terms = json.data[0].centralContent;
                    let button = document.getElementById('closeTC');
                    
                    $('#closeTC').prop('disabled', false);
                    $('#login-focus').focus();
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });

                // let apiToken = this.token,
                //     red = redeemed,
                //     url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserCouponList?userId=' + this.user.user.userId + '&isRedeemed=' + red + '&categoryId=0';

                // return fetch(url, {
                //         method: 'GET',
                //         // body: JSON.stringify(datCreds),
                //         headers: {
                //             'Content-Type': 'application/json',
                //             'Authorization': "Bearer " + apiToken,
                //         },
                //     }
                // )
                // .then((response) => response.json())
                // .then((json) => {
                //     let d = json.data;
                // })
                // .catch((error) => {
                //     // console.error("There was an error!", error);
                //     return (this.errorMessage = error);
                // });
            },
            print() {
                window.print();
            }
		}
    };
</script>