<template>
    <div class="eAppBackgroundCheck">
        
        <hollow-dots-spinner :animation-duration="1000"
                             :dot-size="15"
                             :dots-num="3"
                             color="#0FD8C9"
                             v-if="isLoading" />

        <SignInRegister v-if="!isLoading && !isUserLogged"></SignInRegister>

        <div v-if="!isLoading && isUserLogged">
            <div v-if="updateFound" class="eAppUpdates">
                <div class="eAppHeader cf">
                    <!-- ifIsInRole: eApp-Retailer || eAppPrincipal -->
                    <a v-if="showAppUpdatesHome" class="button eAppDashBtn" href="/eApplications/Updates">Application Updates Home</a>
                    <!-- ifIsInRole: eApp-Retailer && eApp-ChainApplicant -->
                    <a v-if="showAppHome" class="button eAppDashBtn" href="/eApplications">Application Home</a>
                    
                    <h1>Agent Number: <strong>{{ updateWrapper.agentNumber }}</strong></h1>
                    <h3 style="clear:both;">Batch Number: <strong>{{ updateWrapper.batchNumber }}</strong></h3>

                </div>
                <div class="ErrorLabel alert-error" v-if="showErrorMessage">There was an error processing your request.</div>
            </div>

            <div v-if="showAuthorizeForm">

                <principal-authorize :bgToken="token" :principalJson="principalAuthJson"
                                             @onPrincipalSaveClick="onPrincipalSaveClick"></principal-authorize>

            </div>
            <div v-else>
                <h4>Principal Update</h4>
                <p>To approve your own background check please click Authorize on the background check page.</p>
                <div v-if="hasPrincipals">
                    <ul class="principalList">
                        <li v-for="(principal, index) in backgroundCheckListResponse.principals" v-bind:key="index" class="principalItem cf">
                            <div class="principalInfo">
                                <span v-if="principal.locked" class="icon-lock"></span>
                                <span class="principalName">{{ principal.principalName }}</span>
                            </div>
                            <div class="principalBtns">
                                <div v-if="principal.showStatus && !principal.showAuthorize" :class="principal.statusCssClass">
                                    Status: <strong>{{principal.status}}</strong>
                                </div>
                                <button v-if="principal.showAuthorize" type="button btn-secondary" @click="loadAuthorizeForm(principal.principalId)" :class="['btn-primary', principal.authorizeCssClass]">{{principal.authorizeText}}</button>
                            </div>
                        </li>
                    </ul>

                    <table class="eAppListTbl">
                        <tbody>
                            <tr>
                                <th>Principal Name</th>
                                <th>Ownership %</th>
                                <th>Update Type</th>
                            </tr>
                            <tr v-for="(principal, index) in updateWrapper.principalGroupUpdate.principalUpdates" v-bind:key="index">
                                <td v-if="principal.principalFirstName !== '' && principal.principalFirstName !== null && principal.principalLastName !== '' && principal.principalLastName !== null">
                                    {{ principal.principalFirstName }} {{ principal.principalLastName }}
                                </td>
                                <td v-else>
                                    {{ principal.principalFullName }}
                                </td>
                                <td>
                                    {{ principal.principalPercentOwnership }} %
                                </td>
                                <td>
                                    <span v-if="principal.principalOperation == 0">Added</span>
                                    <span v-if="principal.principalOperation == 1">Updated</span>
                                    <span v-if="principal.principalOperation == 2">Deleted</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="InfoLabel alert-message">
                    There are no principals requiring background check authorization.
                </div>

                
                <button v-if="showBackToUpdate" class="button" type="button" @click="backToSummary">Back</button>

            </div>
        </div>
    </div>
</template>


<script>
    // import $ from 'jquery';
    // import moment from "moment";
    import { HollowDotsSpinner } from 'epic-spinners';
    import SignInRegister from '/ViewComponents/SignInRegister/SignInRegister.vue';
    import PrincipalAuthorize from '/Views/Eapplication/background/principal-authorization.vue';
    export default {
        components: {
            HollowDotsSpinner,
            SignInRegister,
            PrincipalAuthorize
        },
        data: function () {
            return {
                token:'',
                userId: 0,
                isLoading: true,
                backgroundCheckListResponse: [],
                hasPrincipals:true,
                contentKey: 'eapp-regular-application',
                pageContent: {},
                isDisabled: false,            
                isUserLogged: false,
                updateGUID: '', // primary identifier
                contentKey: 'eapp-updates-renewals-details',
                pageContent: {}, // JSON for basic text content
                updateWrapper: {}, // JSON for entire Update
                isUserAllowed: false, // proper role and identified on the Update
                updateFound: false,
                showErrorMessage: false,
                agentId: '0',
                readOnly: false,
                step: '',
                showAuthorizeForm: false,
                principalId: 0,
                principalAuthJson: '',
                showBackToUpdate: false,
                showAppHome: false,
                showAppUpdatesHome: false,
            }
        },
        mounted: function () {
            this.isLoading = true;
            this.isUserLogged = this.isUserAuthed();
            this.onMounted();
        },
        computed:
        {
            BACKGROUND_CHECK_LIST_API_URL() {
                // this needs updated for a new endpoint
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Update/user/` + this.userId + `/update/` + this.updateGUID + `/backgroundchecklist`;
            },

            CREATE_PRINCIPAL_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Update/ManagePrincipal`;
            }
        },
        methods: {
            onMounted() {

                // get the user ID
                this.userId = this.getLoggedInUserId();

                // get the agent number
                this.agentId = this.getURLQuery('an');

                if (this.userId) {
                    this.getAPItoken()
                        .then((token) => {
                            this.token = token;
                            this.tryGetFromQueryString().then(() => {
                                this.fetchData().then(() => {
                                    if (this.backgroundCheckListResponse.principals !== undefined && this.backgroundCheckListResponse.principals !== null) {
                                        this.hasPrincipals = true;
                                    }
                                     console.log('has principals:'+this.hasPrincipals);
                                     console.log(this.backgroundCheckListResponse.principals);
                                     console.log('table ', this.backgroundCheckListResponse)

                                    // Get the updateWrapper from response
                                    if (this.backgroundCheckListResponse.updateWrapper !== undefined && this.backgroundCheckListResponse.updateWrapper !== null) {
                                         this.updateWrapper = this.backgroundCheckListResponse.updateWrapper;
                                         this.updateFound = true;
                                     }

                                    // parse the response
                                    this.getPrincipal();

                                    // Determine if user is Update owner
                                    // if () {
                                    //     this.showBackToUpdate = true;
                                    // }
                                    
                                    this.showPanelFromStepQueryString();

                                    // get roles and show buttons
                                    this.toggleTopButtons();


                                    this.isLoading = false;
                                });
                            });
                        });
                }
            },
            async fetchData() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                };
                // console.log(this.BACKGROUND_CHECK_LIST_API_URL);
                this.backgroundCheckListResponse = await (await fetch(this.BACKGROUND_CHECK_LIST_API_URL, appsRequestOptions)).json();
                
                return Promise.resolve();
            },
            tryGetFromQueryString() {

                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('updateId')) {
                    this.updateGUID = urlParams.get('updateId');
                } else {
                    // need to handle error here
                }

                if (urlParams.has('step')) {
                    this.step = urlParams.get('step');
                }

                if (urlParams.has('p')) {
                    this.principalId = urlParams.get('p');
                }

                return Promise.resolve();
            },
            toggleTopButtons() {
                if (this.isUserInRole('eApp-Retailer') || this.isUserInRole('eAppPrincipal')) {
                    this.showAppUpdatesHome = true;
                }
                if (this.isUserInRole('eApp-Retailer') || this.isUserInRole('eApp-ChainApplicant')) {
                    this.showAppHome = true;
                }
            },
            showPanelFromStepQueryString() {
                if (this.step ==  "authorize") {
                    this.showAuthorizeForm = true;
                } else {
                    this.showAuthorizeForm = false;
                }
            },
            loadAuthorizeForm(principalId) {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);

                if (urlParams.has('step')) {
                    urlParams.set('step', 'authorize');
                } else {
                    urlParams.append('step', 'authorize');
                }

                if (urlParams.has('p')) {
                    urlParams.set('p', principalId);
                } else {
                    urlParams.append('p', principalId);
                }

                if (urlParams.has('n')) {
                    urlParams.set('n', 1);
                } else {
                    urlParams.append('n', 1 );
                }

                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;

            },
            getPrincipal() {
                // console.log(this.principalId);
                if (this.principalId > 0) {
                    let principal = this.backgroundCheckListResponse.updateWrapper.principalGroupUpdate.principalUpdates.find(x => x.principalInformationUpdateID == this.principalId);
                    this.principalAuthJson = JSON.stringify(principal);
                    // console.log(this.principalAuthJson);
                }
                
            },
            updateUrl(step) {
                
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                urlParams.set('step', step);
                currentPage += '?' + urlParams.toString();

                //changing this to a page reload, so each section has the most up to date object
                //vue is making it weird to pass the application around and sometimes overwriting itself 
                window.location.href= currentPage;
            },
            onPrincipalSaveClick(principal, dir) {
                this.createPrincipal(principal)
                    .then(
                        () => {
                            
                            // alert('check console');
                            let queryString = window.location.search;
                            let urlParams = new URLSearchParams(queryString);

                            if (urlParams.has('n')) {
                                let stepNumber = urlParams.get('n');
                                this.navigateToNextPrincipalStep(stepNumber, dir);
                            }
                            else {
                                // redirect to listing page
                                if (urlParams.has('p')) {
                                    urlParams.delete('p');
                                }
                                // alert('check console');
                                let currentPage = window.location.origin + window.location.pathname;
                                currentPage += '?' + urlParams.toString();
                                window.location.href = currentPage;
                            }
                            
                        }
                    );
                
            },
            async createPrincipal(principal) {
                let createBody = { updateId: this.updateGUID, userId: this.userId, principal: principal };
                // console.log(createBody);
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        createBody
                    )
                };
                let response = await (await fetch(this.CREATE_PRINCIPAL_API_URL, appsRequestOptions)).json();
                if (response != null && response.statusCode == 200) {
                    this.isLoading = false;
                }
                else {
                    this.errorMessageShow = true;
                    this.isLoading = false;
                }
            },
            navigateToNextPrincipalStep(num, dir) {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (dir == 'next') {
                    num++;
                } else {
                    num--;
                }
                
                if (num > 3) {
                    urlParams.delete('n');
                    if (urlParams.has('step')) {
                        urlParams.set('step', 'background');
                    } else {
                        urlParams.append('step', 'background');
                    }
                }
                else {
                    if (urlParams.has('n')) {
                        urlParams.set('n', num);
                    } else {
                        urlParams.append('n', num);
                    }
                }
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            // customFormatter(dateVal) {
            //     return moment(dateVal).format("MM/DD/YYYY");
            // },
            backToSummary() {
                let updateEdit = "/eapplications/updates/license-edit?an="+this.agentId+"&updateId="+this.updateGUID;
                window.location.href = updateEdit;
            }
        }
    };
</script>