<template>
    <div>
        <div v-if="content != ''" v-html="content"></div>
    </div>
</template>
<script>
    export default {
        props: {
            propKey: String
        },
        data: function () {
            return {
                key: '',
                content: ''
            }
        },
        mounted: function () {
            this.getContentKey();
        },
        methods: {
            getContentKey() {
                // this is going to break when there are 2 of these widgets on one page. They will both end up showing the content for the first instance of the content-key element.
                let widgetKey = '';
                let contetKeyEl = document.getElementById('content-key');
                if (contetKeyEl !== null) {
                    widgetKey = document.getElementById('content-key').value;
                }
                let dataKey = this.propKey;
                // console.log(dataKey);

                if (dataKey !== null && dataKey !== undefined && dataKey !== '') {
                    // favor the data key 
                    this.key = dataKey;
                } else if (widgetKey !== null && widgetKey !== undefined && widgetKey !== '') {
                    this.key = widgetKey;
                } else {
                    console.log('Static Content Component failed to find content key.');
                    this.key = "static-error";
                }
                // console.log(this.key);
                this.getToken();
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.getMenu(token);
                });
            },
            getMenu(apiToken) {
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+'/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=' + this.key, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    // console.log(json);
                    let element = json.data[0].centralContent;

                    this.content = this.sanitizeMessage(element);

                    // let parser = new DOMParser();
                    // let htmlDoc = parser.parseFromString(element, 'text/html');

                    // this.menu = htmlDoc.body.innerHTML;
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>