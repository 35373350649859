<template>
    <div class="cta-shareStory">
        <div class="contentRow">
            <div class="cta-copy row no-gutters cta-shareStoryWrap">
                <div class="cta-shareStoryCopy txtCenter">
                <h3 class="cta-heading" style="font-size: 2em;">WINNER STORIES</h3>

                <p>Whether you won big or small playing an Ohio Lottery game, we want to hear your story! Share that special moment that made you jump for joy by uploading your photo or video below and show the world how good it feels to win with the Ohio Lottery!</p>
                
                <a class="button cta-share clickShareStory-cta" href="/Winners/Share-Your-Story">SHARE YOUR STORY</a></div>

                <div class="cta-shareStoryImg">
                    <img alt="Winners" class="img-responsive" src="/ohiolottery/media/ContentImages/LandingPages/winner-stories-landing.jpg">
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        components: {
            
        },
        data: function () {
            return {
            }
        },
        mounted: function () {
        },
        methods: {
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>


</style>