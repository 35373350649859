<!-- ~/Views/Games/Draw-Details/draw-detail.vue -->

<template>
    <!-- <div class="">
        <div class="game-information">
            <div :class="'DrawNumbersWrap winningNumbersItem ' + gameInfo.stringIdentifier">
                <a href="#" class="drawNumbersLogo" :style="'background-image:url('+gameInfo.logo+')'">
                    <img v-bind:src="gameInfo.gameLogoURL" v-bind:alt="gameInfo.name" class="winNumGameLogo" />
                </a>
                <span>{{gameInfo.gameName}}</span>
                <div class="winningNumbersContent">
                    <span v-if="gameInfo.identifier === 'pick3'">
                        <DrawNumbers v-if="pick3 === true" :numbers="gameInfo.draws[1]" :game="game" :evening="false" v-bind:key="gameInfo.gameID"></DrawNumbers>
                    </span>
                    
                    <span v-if="gameInfo.identifier === 'pick4'">
                        <DrawNumbers v-if="pick4 === true" :numbers="gameInfo.draws[1]" :game="game" :evening="false" v-bind:key="gameInfo.gameID"></DrawNumbers>
                    </span>
                    
                    <span v-if="gameInfo.identifier === 'pick5'">
                        <DrawNumbers v-if="pick5 === true" :numbers="gameInfo.draws[1]" :game="game" :evening="false" v-bind:key="gameInfo.gameID"></DrawNumbers>
                    </span>

                    <div v-for="drawNumber in drawNumbers" v-bind:key="drawNumber.id">
                        <span class="winNumDate">{{ new Date(drawNumber.drawDate) | moment("MM/DD/YYYY") }}</span>
                        <span class="winNumToD">{{ drawNumber.modifier | pickToD }}</span>
                        <ul class="winningNumbersList">
                            <li v-for="number in drawNumber.numbers" v-bind:key="number.id" class="winNumBall">
                                {{ number.value }}
                            </li>
                        </ul>
                    </div>
                    <span class="drawTimesLabel">
                        Drawings: {{gameInfo.drawSchedule}}<span class="drawTimes"></span>
                    </span>
                </div>
            </div>
            
            <template lang="html">
                <div class='wrapper'>
                    <tabs>
                        <tab :title="tab1title" id="tab0" v-html="gameInfo.tab1Content" v-if="tab1title" :isFirst="true"></tab>
                        <tab :title="tab2title" id="tab1" v-html="gameInfo.tab2Content" v-if="tab2title"></tab>
                        <tab :title="tab3title" id="tab2" v-html="gameInfo.tab3Content" v-if="tab3title"></tab>
                        <tab :title="tab4title" id="tab3" v-html="gameInfo.tab4Content" v-if="tab4title"></tab>
                        <tab :title="tab5title" id="tab4" v-html="gameInfo.tab5Content" v-if="tab5title"></tab>
                    </tabs>
                </div>
            </template>
        </div>
    </div> -->

    <div class="winning-numbers-wrapper draw-detail">
        <div :class="'DrawNumbersWrap winningNumbersItem drawDetail ' + game.stringIdentifier">
            <!-- logo -->
            <a :href="game.url" class="drawNumbersLogo" :style="'background-image:url('+game.logo+')'">
                <img v-bind:src="game.gameLogoURL" v-bind:alt="game.name" class="winNumGameLogo" />
            </a>

            <!-- <span v-if="game.numbers.errorMessage">{{game.numbers.errorMessage}}</span> -->
            <!-- For Identification ONLY -->
            <span>{{game.gameName}}</span>
            
            <div class="winningNumbersContent">
                <span v-if="game.stringIdentifier === 'Pick3'">
                    <DrawNumbers v-if="pick3 === true" :numbers="game.draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                </span>
                
                <span v-if="game.stringIdentifier === 'Pick4'">
                    <DrawNumbers v-if="pick4 === true" :numbers="game.draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                </span>
                
                <span v-if="game.stringIdentifier === 'Pick5'">
                    <DrawNumbers v-if="pick5 === true" :numbers="game.draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                </span>

                <DrawNumbers v-if="game.stringIdentifier === 'megamil'" :numbers="game.draws[0]" :game="game" :evening="false" :component="0" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.stringIdentifier === 'luckylife'" :numbers="game.draws[0]" :game="game" :evening="false" :component="0" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.stringIdentifier === 'pball'" :numbers="game.draws[0]" :game="game" :evening="false" :component="0" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.stringIdentifier === 'ClassicLotto'" :numbers="game.draws[0]" :game="game" :evening="false" :component="0" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.stringIdentifier === 'rollingcash5'" :numbers="game.draws[0]" :game="game" :evening="false" :component="0" v-bind:key="game.gameID"></DrawNumbers>

                <DrawNumbers v-if="game.stringIdentifier === 'Pick3'" :numbers="game.draws[0]" :game="game" :evening="evening" :component="0" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.stringIdentifier === 'Pick4'" :numbers="game.draws[0]" :game="game" :evening="evening" :component="0" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.stringIdentifier === 'Pick5'" :numbers="game.draws[0]" :game="game" :evening="evening" :component="0" v-bind:key="game.gameID"></DrawNumbers>

                <div v-if="game.stringIdentifier === 'megamil' || game.stringIdentifier === 'pball' || game.stringIdentifier === 'ClassicLotto' || game.stringIdentifier === 'rollingcash5'" class="winningNumberJackpots">
                    <div v-if="game.stringIdentifier === 'ClassicLotto'" class="kicker-number">
                        <span class="wnLbl">KICKER: </span>
                        <span class="wnVal">{{ game.draws[0].extendedNumbers.map(item => `${item.value}`).toString().replaceAll(',','') }}</span>
                    </div>
                    
                    <div class="nextJackpot" v-if="game.draws[0].nextPrizePool === 0 || game.draws[0].nextPrizePool === ''">
                        <span class="wnLbl detail">Next Jackpot: $XXX,XXX</span>
                    </div>
                    <div class="nextJackpot" v-if="game.draws[0].nextPrizePool > 0">
                        <span class="wnLbl detail" v-if="game.draws[0].nextPrizePool <= 1000000">Next Jackpot: ${{game.draws[0].nextPrizePool.toLocaleString()}}</span>
                        <span class="wnLbl detail" v-if="game.draws[0].nextPrizePool > 1000000 && game.draws[0].nextPrizePool < 1000000000">Next Jackpot: ${{(game.draws[0].nextPrizePool / 1000000).toLocaleString()}} Million</span>
                        <span class="wnLbl detail" v-if="game.draws[0].nextPrizePool >= 1000000000">Next Jackpot: ${{(game.draws[0].nextPrizePool / 1000000000).toLocaleString()}} Billion</span>
                    </div>
                    <!-- <div v-if="game.stringIdentifier != 'rolling5'" class="cashOptionValue">
                        <span class="wnLbl">Cash Option Value: </span>
                        <span class="wnVal" v-if="game.draws[0].nextPDCV <= 1000000">${{game.draws[0].nextPDCV.toLocaleString()}}</span>
                        <span class="wnVal" v-if="game.draws[0].nextPDCV > 1000000 && game.draws[0].nextPDCV < 1000000000">${{(game.draws[0].nextPDCV / 1000000).toLocaleString()}} MILLION</span>
                        <span class="wnVal" v-if="game.draws[0].nextPDCV >= 1000000000">${{(game.draws[0].nextPDCV / 1000000000).toLocaleString()}} BILLION</span>
                    </div> -->
                </div>

                <div v-if="game.stringIdentifier === 'luckyLife'" class="winningNumberJackpots">
                    <div class="nextJackpot">
                        <span class="wnLbl">Top Prize: </span>
                        <span class="wnVal">$1,000 a day for life.</span>
                    </div>
                    <div class="cashOptionValue">
                        <span class="wnLbl">Cash Option Value: </span>
                        <span class="wnVal">$5.8 MILLION</span>
                    </div>
                </div>

                <span class="drawTimesLabel">
                    Drawings: <span class="drawTimes">{{game.drawSchedule}}</span>
                </span>
            </div>
        </div>

        <template lang="html">
            <div class='wrapper'>
                <tabs>
                    <tab :title="tab1title" id="tab0" v-html="tildeKiller(game.tab1Content)" v-if="tab1title" :isFirst="true"></tab>
                    <tab :title="tab2title" id="tab1" v-html="tildeKiller(game.tab2Content)" v-if="tab2title"></tab>
                    <tab :title="tab3title" id="tab2" v-html="tildeKiller(game.tab3Content)" v-if="tab3title"></tab>
                    <tab :title="tab4title" id="tab3" v-html="tildeKiller(game.tab4Content)" v-if="tab4title"></tab>
                    <tab :title="tab5title" id="tab4" v-html="tildeKiller(game.tab5Content)" v-if="tab5title"></tab>
                </tabs>
            </div>
        </template>
    </div>
</template>

<script>
    import $ from 'jquery';
    import moment from 'moment';
    import DrawNumbers from '../../../ViewComponents/DrawNumbers/DrawNumbers_Detail.vue';
    import Tab from '../../../ViewComponents/Tabs/Tab.vue';
    import Tabs from '../../../ViewComponents/Tabs/Tabs.vue';

    export default {
        components: {
            DrawNumbers,
            Tab,
            Tabs
        },
        data: function() {
            return {
                drawNumbers: [],
                gameLogo: '',
                gameName: '',
                howToPlayData: '',
                game: {},
                tab1title: '',
                tab2title: '',
                tab3title: '',
                tab4title: '',
                tab5title: '',
                evening: false,
                errorMessage: 'Error in Detail. Please try again later.'
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                    this.fetchLatestDraws(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                // var splitGameName = window.location.pathname.split("/").pop();
                // var removeHypehnGameName = splitGameName.replace(/-/g, "");
                // var capitalizeGameName = removeHypehnGameName.charAt(0).toUpperCase() + removeHypehnGameName.slice(1);
                // var gameName = capitalizeGameName;
                var gameName = document.getElementById('gameID').value;
                // console.log(gameName);

                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/games/drawgames/"+gameName+"/getgameinformation", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    // console.log(json.data)
                    var gameInformation = json.data;
                    this.game = gameInformation;
                    this.howToPlayData = gameInformation.howToPlay;
                    this.gameLogo = gameInformation.gameLogoURL;
                    this.gameName = gameInformation.name;
                    this.tab1title = gameInformation.tab1Label;
                    this.tab2title = gameInformation.tab2Label;
                    this.tab3title = gameInformation.tab3Label;
                    this.tab4title = gameInformation.tab4Label;
                    this.tab5title = gameInformation.tab5Label;

                    if (moment(this.game.draws[0].drawDate).format('MM-DD-YYYY') === moment(this.game.draws[1].drawDate).format('MM-DD-YYYY')) {
                        this.evening = true;
                    }

                    // console.log(gameInformation);
                    $(document).foundation();
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            fetchLatestDraws(token){
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                var splitGameName = window.location.pathname.split("/").pop();
                var removeHypehnGameName = splitGameName.replace(/-/g, "");
                var capitalizeGameName = removeHypehnGameName.charAt(0).toUpperCase() + removeHypehnGameName.slice(1);
                var gameName = capitalizeGameName;
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/"+gameName+"/GetLatestDraws", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var drawNumbers = json.data;
                    // console.log(drawNumbers)
                    this.drawNumbers = drawNumbers;
                })
                .catch(error => {
                    return this.errorMessage = error;

                });
            }
        },
        computed: {
        }
    };
</script>


<style lang="scss" scoped>
    /* style the unique aspects here */
    .kicker-number {
        font-size: 2.4rem;

        .wnLbl {
            width: auto;
            display: inline-block;
        }

        // .wnVal {
        //     //color: #be0f34 !important;
        // }
    }
</style>