<template>
    <div class="keno-hot-n-cold module cf">
        <h3>Need help picking numbers?</h3>
        <p>Check out what's been hot and, more importantly, what's been cold.</p>

        <div class="kenoHnClistWrap">
            <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#be0f34" v-if="isLoading" />
            <span v-if="!isLoading">
                <h6>Hot</h6>
                <ul class="kenoHotList kenoHnClist cf">
                    <li class="kenoHnCitem" v-for="number in hotNumbers" :key="number.id">
                        <div class="kenoHnCnumber">{{number.number}}</div>
                        <div class="kenoHnCpercent"><strong>{{number.percentDrawn}}%</strong></div>
                    </li>
                </ul>
            </span>
        </div>	
        <div class="kenoHnClistWrap">
            <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#5161ac" v-if="isLoading" />

            <span v-if="!isLoading">
                <h6>Cold</h6>
                <ul class="kenoColdList kenoHnClist cf">
                    <li class="kenoHnCitem" v-for="number in coldNumbers" :key="number.id">
                        <div class="kenoHnCnumber">{{number.number}}</div>
                        <div class="kenoHnCpercent"><strong>{{number.percentDrawn}}%</strong></div>
                    </li>
                </ul>
            </span>
        </div>	
        <label for="kenoResults" class="kenoResults">
            Show results for the last:<br>
            <select id="kenoResults" @change="changeNumber($event)">
                <option value="0">5 Draws</option>
                <option value="1">10 Draws</option>
                <option value="2">25 Draws</option>
                <option value="3">50 Draws</option>
                <option value="4">100 Draws</option>
            </select>
        </label>
    </div>
</template>


<script>

    import { HollowDotsSpinner } from 'epic-spinners'

    export default {
        components: {
            HollowDotsSpinner
        },
        data: function () {
            return {
                drawSelectNumber: 0,
                hotNumbers: [],
                coldNumbers: [],
                isLoading: false
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.isLoading = true;
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                let apiToken = token;
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/Keno/GetKenoHotColdLastDrawData?type="+this.drawSelectNumber, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let results = json.data;
                    this.hotNumbers = results.slice(0,5);
                    this.coldNumbers = results.slice(1).slice(-5);
                    this.isLoading = false;


                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            changeNumber(event){
                this.isLoading = true;
                this.drawSelectNumber = event.target.value;
                this.getToken()
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>