<template>
    <div v-if="!readOnly" data-abide novalidate data-validate-on-blur="true" id="updateBusinessForm">
        <h4>Business Information <span v-if="hasRenewal">- Renewal</span></h4>

        <p v-if="pageContent" v-html="pageContent.updateBusinessIntro"></p>
        <div class="grid-margin-x grid-x grid-margin-y">
            <div class="formRow cell">
                <span class="req">*</span>
                <label title="This is the legal name of your business" class="helpTxt" for="BusinessLegalName">Legal Business
                    Name:</label>
                <input v-model="businessInformationUpdate.businessLegalName" type="text" id="BusinessLegalName" maxlength="200" @change="legalValueChanged('legalName')" class="infoChangeCheckInput" required :disabled="readOnly"/>

                <!-- <div class="form-error error" id="BusinessLegalNameError">Please provide the legal business name</div> -->
                <div class="error" id="BusinessLegalNameError" style="display:none;">Please provide the legal business name</div>
                <div class="error warningMessage" v-if="legalNameChanged">Please make sure to also update the W9 information to match the Legal Name</div>
            </div>
            <div class="formRow cell" :class="{ dividerRow: hasRenewal }">
                <span class="req">*</span>
                <label title="DBA-this is the name of your business" class="helpTxt" for="DBA">Doing Business As:</label>
                <input v-model="businessInformationUpdate.businessDoingBusinessAs" type="text" id="DBA" maxlength="200"
                    required  :disabled="readOnly || mailingAddValid === false || editing === true"/>
                <!-- <div class="form-error error">Please provide a Doing business as value</div> -->
                <div class="error" id="busAsError" style="display:none;">Please provide a Doing business as value</div>
            </div>
        </div>
        <div v-if="!hasRenewal" class="grid-margin-x grid-x grid-margin-y">
            <div class="formRow cell">
                <span class="req">*</span>
                <label title="This is the type of your business" class="helpTxt" for="BusinessType">Type of
                    Business:</label>
                <input v-model="businessInformationUpdate.businessType" type="text" id="BusinessType" maxlength="200"
                    required :disabled="readOnly || mailingAddValid === false || editing === true" />
                <!-- <div class="form-error error">Please provide a business type</div> -->
                <div class="error" id="busTypeError" style="display:none;">Please provide a business type</div>
            </div>
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="LegalStreetAddress">Legal Street Address:</label>
                <input v-model="businessInformationUpdate.legalStreetAddress" type="text" id="LegalStreetAddress" @input="invalidateBusiness()" @change="legalValueChanged('legalAddress')"
                    maxlength="200" class="infoChangeCheckInput" required :disabled="readOnly || mailingAddValid === false || editing === true" />
                <!-- <div class="form-error error">Please provide a legal street address</div> -->
                <div class="error" id="busAddError" style="display:none;">Please provide a legal street address</div>
                <div class="error warningMessage" v-if="legalAddressChanged">Please make sure to also update the W9 information to match the Legal Address</div>
            </div>
            <div class="formRow cell medium-8">
                <span class="req">*</span>
                <label for="LegalCity">Legal City:</label>
                <input v-model="businessInformationUpdate.legalCity" type="text" id="LegalCity" max="200" @input="invalidateBusiness()" @change="legalValueChanged('legalCity')"
                    class="infoChangeCheckInput" required :disabled="readOnly || mailingAddValid === false || editing === true" />
                <!-- <div class="form-error error">Please provide a legal city</div> -->
                <div class="error" id="busCityError" style="display:none;">Please provide a legal city</div>
                <div class="error warningMessage" v-if="legalCityChanged">Please make sure to also update the W9 information to match the Legal City</div>
            </div>
            <div class="formRow cell medium-4">
                <span class="req">*</span>
                <label for="LegalState">State:</label>
                <select v-model="businessInformationUpdate.legalState" id="LegalState" @change="legalValueChanged('legalState')"
                    class="DropDownField infoChangeCheckInput" required :disabled="readOnly || mailingAddValid === false || editing === true">
                    <option value="null">Select a state</option>
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AZ">AZ</option>
                    <option value="AR">AR</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="IA">IA</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="ME">ME</option>
                    <option value="MD">MD</option>
                    <option value="MA">MA</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MS">MS</option>
                    <option value="MO">MO</option>
                    <option value="MT">MT</option>
                    <option value="NE">NE</option>
                    <option value="NV">NV</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NY">NY</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="DC">DC</option>
                    <option value="WV">WV</option>
                    <option value="WI">WI</option>
                    <option value="WY">WY</option>
                </select>
                <!-- <div class="form-error error">Please select a State</div> -->
                <div class="error" id="busStateError" style="display:none;">Please select a State</div>
                <div class="error warningMessage" v-if="legalStateChanged">Please make sure to also update the W9 information to match the Legal State</div>
            </div>
            <div class="formRow zipFields validateZip cell medium-8">
                <div style="display: flex; align-items: center;">
                    <div style="padding-right:10px;">
                        <span class="req">*</span>
                        <label for="LegalZip">Zip:</label>
                        <input v-model="businessInformationUpdate.legalZip" type="text" id="LegalZip" @input="invalidateBusiness()" @change="legalValueChanged('legalZip')" class="zipFive infoChangeCheckInput" required pattern="^4[3-5]\d{3}$" minlength="5" maxlength="5" :disabled="readOnly || mailingAddValid === false || editing === true" />
                    </div>

                    <div style="padding-right:10px;"> 
                        <span class="req">*</span>
                        <label for="LegalZip">Zip +4:</label>
                        <input v-model="businessInformationUpdate.legalZipPlus4" type="text" id="LegalZipPlus4" @input="invalidateBusiness()" class="zipFour" required pattern="\b\d{4}\b" minlength="4" maxlength="4" :disabled="readOnly || mailingAddValid === false || editing === true" />
                    </div>
                    <a href="https://tools.usps.com/go/ZipLookupAction!input.action" target="_blank" class="eAppZip4Link">Zip +4 Lookup</a>
                </div>
                <div class="error" v-if="error.zipCode">Please provide a Zip Code</div>
                <div class="error" id="busZipError" style="display:none;">Please provide a Zip Code</div>
                <div class="error" v-if="error.zipCode4">Please provide a Zip +4</div>
                <div class="error" id="busZip4Error" style="display:none;">Please provide a Zip +4</div>
                <div class="error" v-if="error.zipCode">Please provide a valid Ohio Zip Code</div>
                <div class="error warningMessage" v-if="legalZipChanged">Please make sure to also update the W9 information to match the Legal Zip Code</div>
            </div>
            <div class="formRow cell medium-4">
                <span class="req">*</span>
                <label for="LegalCounty">County:</label>
                <select v-model="businessInformationUpdate.legalCounty" id="LegalCounty" class="DropDownField" required :disabled="readOnly || mailingAddValid === false || editing === true">
                    <option value="null" selected="selected">Select...</option>
                    <option value="39">ADAMS</option>
                    <option value="11">ALLEN</option>
                    <option value="01">ASHLAND</option>
                    <option value="02">ASHTABULA</option>
                    <option value="65">ATHENS</option>
                    <option value="28">AUGLAIZE</option>
                    <option value="66">BELMONT</option>
                    <option value="40">BROWN</option>
                    <option value="41">BUTLER</option>
                    <option value="77">CARROLL</option>
                    <option value="29">CHAMPAIGN</option>
                    <option value="30">CLARK</option>
                    <option value="42">CLERMONT</option>
                    <option value="43">CLINTON</option>
                    <option value="78">COLUMBIANA</option>
                    <option value="49">COSHOCTON</option>
                    <option value="12">CRAWFORD</option>
                    <option value="03">CUYAHOGA</option>
                    <option value="31">DARKE</option>
                    <option value="13">DEFIANCE</option>
                    <option value="50">DELAWARE</option>
                    <option value="04">ERIE</option>
                    <option value="51">FAIRFIELD</option>
                    <option value="52">FAYETTE</option>
                    <option value="53">FRANKLIN</option>
                    <option value="14">FULTON</option>
                    <option value="67">GALLIA</option>
                    <option value="05">GEAUGA</option>
                    <option value="32">GREENE</option>
                    <option value="68">GUERNSEY</option>
                    <option value="44">HAMILTON</option>
                    <option value="15">HANCOCK</option>
                    <option value="16">HARDIN</option>
                    <option value="79">HARRISON</option>
                    <option value="17">HENRY</option>
                    <option value="45">HIGHLAND</option>
                    <option value="54">HOCKING</option>
                    <option value="80">HOLMES</option>
                    <option value="06">HURON</option>
                    <option value="69">JACKSON</option>
                    <option value="81">JEFFERSON</option>
                    <option value="55">KNOX</option>
                    <option value="07">LAKE</option>
                    <option value="70">LAWRENCE</option>
                    <option value="56">LICKING</option>
                    <option value="33">LOGAN</option>
                    <option value="08">LORAIN</option>
                    <option value="18">LUCAS</option>
                    <option value="57">MADISON</option>
                    <option value="82">MAHONING</option>
                    <option value="58">MARION</option>
                    <option value="09">MEDINA</option>
                    <option value="71">MEIGS</option>
                    <option value="34">MERCER</option>
                    <option value="35">MIAMI</option>
                    <option value="72">MONROE</option>
                    <option value="36">MONTGOMERY</option>
                    <option value="73">MORGAN</option>
                    <option value="59">MORROW</option>
                    <option value="60">MUSKINGUM</option>
                    <option value="74">NOBLE</option>
                    <option value="19">OTTAWA</option>
                    <option value="20">PAULDING</option>
                    <option value="61">PERRY</option>
                    <option value="62">PICKAWAY</option>
                    <option value="47">PIKE</option>
                    <option value="83">PORTAGE</option>
                    <option value="37">PREBLE</option>
                    <option value="21">PUTNAM</option>
                    <option value="10">RICHLAND</option>
                    <option value="63">ROSS</option>
                    <option value="22">SANDUSKY</option>
                    <option value="48">SCIOTO</option>
                    <option value="23">SENECA</option>
                    <option value="38">SHELBY</option>
                    <option value="84">STARK</option>
                    <option value="85">SUMMIT</option>
                    <option value="86">TRUMBULL</option>
                    <option value="87">TUSCARAWAS</option>
                    <option value="64">UNION</option>
                    <option value="24">VAN WERT</option>
                    <option value="75">VINTON</option>
                    <option value="46">WARREN</option>
                    <option value="76">WASHINGTON</option>
                    <option value="88">WAYNE</option>
                    <option value="25">WILLIAMS</option>
                    <option value="26">WOOD</option>
                    <option value="27">WYANDOT</option>
                </select>
                <!-- <div class="form-error error">Please select a County</div> -->
                <div class="error" id="busCountyError" style="display:none;">Please select a County</div>
            </div>
        </div>
        <div class="grid-margin-x grid-x grid-margin-y dividerRow">
            <div class="formRow phoneField cell medium-6 large-4">
                <span class="req">*</span>
                <label for="BusinessPhoneNumber">Business Phone:</label>
                <input 
                    v-model="businessInformationUpdate.businessPhoneNumber" 
                    type="text" id="BusinessPhoneNumber"
                    placeholder="###-###-####" 
                    pattern="^\d{3}-\d{3}-\d{4}$" 
                    @input="phoneFormatBusinessPhoneNumber($event)" 
                    required 
                    :disabled="readOnly || mailingAddValid === false || editing === true" 
                />
                <!-- <span class="form-error error">Please provide a business phone</span> -->
                <span class="error" id="busPhoneError" style="display:none;">Please provide a business phone</span>
                <span class="form-error error" data-form-error-on="pattern">Please enter valid phone number. Ex.
                    ###-###-####</span>
            </div>
            <div class="formRow phoneField cell medium-6 large-4">
                <label for="BusinessPhoneNumberSecondary">Alternate Business Phone:</label>
                <input 
                    v-model="businessInformationUpdate.businessPhoneNumberSecondary" 
                    type="text"
                    id="BusinessPhoneNumberSecondary" 
                    placeholder="###-###-####"
                    pattern="^\d{3}-\d{3}-\d{4}$" 
                    @input="phoneFormatBusinessPhoneNumberSecondary($event)" 
                    :disabled="readOnly || mailingAddValid === false || editing === true" />
                <span class="form-error error">Please provide a alternate business phone</span>
                <span class="form-error error" data-form-error-on="pattern">Please enter valid phone number. Ex.
                    ###-###-####</span>
            </div>
            <div class="formRow phoneField cell medium-6 large-4">
                <label for="BusinessFaxNumber">Business Fax:</label>
                <input 
                    v-model="businessInformationUpdate.businessFaxNumber" 
                    type="text" id="BusinessFaxNumber"
                    placeholder="###-###-####" 
                    pattern="^\d{3}-\d{3}-\d{4}$" 
                    @input="phoneFormatBusinessFaxNumber($event)"
                    :disabled="readOnly || mailingAddValid === false || editing === true" />
                <span class="form-error error">Please provide a business fax</span>
                <span class="form-error error" data-form-error-on="pattern">Please enter valid fax number. Ex.
                    ###-###-####</span>
            </div>
            <!-- <input type="button" class="button btn btn-primary" @click="validateAddress('business')" value="Validate Business Address" v-if="businessAddValid === false">
            <br>
            <div class="error" v-if="businessAddValid === false">You must validate this address before continuing the update.</div> -->
        </div>

        <div class="formRow checkboxRow padTheTop cf" v-if="!hasRenewal"
            :class="{ dividerRow: businessInformationUpdate.mailingSameAsBusiness }">
            <input v-model="sameAsMailing" @click="toggleMailing" type="checkbox" id="chkMSAB" :disabled="readOnly || businessAddValid === false || editing === true" />
            <label for="chkMSAB">Is the mailing address the same as the business address? If yes, please click the box to
                the left.</label>
        </div>

        <div v-if="!sameAsMailing || hasRenewal" class="grid-margin-x grid-x grid-margin-y">
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="MailingName">Mailing Name:</label>
                <input v-model="businessInformationUpdate.mailingName" type="text" id="MailingName" maxlength="200" required
                    :disabled="sameAsMailing || readOnly || businessAddValid === false || editing === true" />
                <!-- <span class="form-error error">Please provide a mailing name</span> -->
                <span class="error" id="mailNameError" style="display:none;">Please provide a mailing name</span>
            </div>
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="MailingStreetAddress">Mailing Street Address:</label>
                <input v-model="businessInformationUpdate.mailingStreetAddress" type="text" id="MailingStreetAddress"
                    maxlength="200" required @input="invalidateMailing()" :disabled="sameAsMailing || readOnly || businessAddValid === false || editing === true" />
                <!-- <span class="form-error error">Please provide a mailing street address</span> -->
                <span class="error" id="mailAddError" style="display:none;">Please provide a mailing street address</span>
            </div>
            <div class="formRow cell medium-8">
                <span class="req">*</span>
                <label for="MailingCity">Mailing City:</label>
                <input v-model="businessInformationUpdate.mailingCity" type="text" id="MailingCity" maxlength="200" required
                    :disabled="sameAsMailing || readOnly || businessAddValid === false || editing === true" @input="invalidateMailing()" />
                <!-- <span class="form-error error">Please provide a mailing city</span> -->
                <span class="error" id="mailCityError" style="display:none;">Please provide a mailing city</span>
            </div>
            <div class="formRow cell medium-4">
                <span class="req">*</span>
                <label for="MailingState">Mailing State:</label>
                <select v-model="businessInformationUpdate.mailingState" id="MailingState" class="DropDownField" required
                    :disabled="sameAsMailing || readOnly || businessAddValid === false || editing === true" @change="invalidateMailing()">
                    <option value="null">Select an Option</option>
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AZ">AZ</option>
                    <option value="AR">AR</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="IA">IA</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="ME">ME</option>
                    <option value="MD">MD</option>
                    <option value="MA">MA</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MS">MS</option>
                    <option value="MO">MO</option>
                    <option value="MT">MT</option>
                    <option value="NE">NE</option>
                    <option value="NV">NV</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NY">NY</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="DC">DC</option>
                    <option value="WV">WV</option>
                    <option value="WI">WI</option>
                    <option value="WY">WY</option>
                </select>
                <!-- <span class="form-error error">Please select a State</span> -->
                <span class="error" id="mailStateError" style="display:none;">Please select a State</span>
            </div>

            <div class="formRow zipFields validateZip cell medium-8">
                <div style="display: flex; align-items: center;">
                    <div style="padding-right:10px;">
                        <span class="req">*</span>
                        <label for="MailingZip">Mailing Zip:</label>
                        <input v-model="businessInformationUpdate.mailingZip" type="text" id="MailingZip" max="5"
                            class="zipFive" required pattern="^4[3-5]\d{3}$" minlength="5" maxlength="5"
                            :disabled="businessInformationUpdate.mailingSameAsBusiness || readOnly || businessAddValid === false || editing === true" @input="invalidateMailing()" />
                    </div>

                    <div  style="padding-right:10px;">
                        <span class="req">*</span>
                        <label for="MailingZipPlus4">Mailing Zip +4:</label>
                        <input v-model="businessInformationUpdate.mailingZipPlus4" type="text" id="MailingZipPlus4"
                            class="zipFour" required pattern="\b\d{4}\b" minlength="4" maxlength="4"
                            :disabled="businessInformationUpdate.mailingSameAsBusiness || readOnly || businessAddValid === false || editing === true" @input="invalidateMailing()" />
                    </div>
                    <a href="https://tools.usps.com/go/ZipLookupAction!input.action" target="_blank"
                        class="eAppZip4Link">Zip +4 Lookup</a>
                </div>
                <div class="error" v-if="error.mailingZipCode">Please provide a Zip Code</div>
                <div class="error" id="mailZipError" style="display:none;">Please provide a Zip Code</div>
                <div class="error" v-if="error.mailingZipCode4">Please provide a Zip +4</div>
                <div class="error" id="mailZip4Error" style="display:none;">Please provide a Zip +4</div>
                <div class="error" v-if="error.mailingZipCode">Please provide a valid Ohio Zip Code</div>
                <div class="error" v-if="error.mailingZipCode || error.mailingZipCode4">Please make sure to also update the
                    W9 information to match the Legal Zip Code</div>
                <!-- <input type="button" class="button btn btn-primary" @click="validateAddress('mailing')" value="Validate Mailing Address" v-if="mailingAddValid === false">
                <br>
                <div class="error" v-if="mailingAddValid === false">You must validate this address before continuing the update.</div> -->
            </div>
        </div>

        <h4 class="padTheTop">Contact Information</h4>
        <div class="eAppForm grid-margin-x grid-x grid-margin-y">
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="PrimaryContactName">Primary Contact Name:</label>
                <input v-model="businessInformationUpdate.primaryContactName" type="text" id="PrimaryContactName"
                    maxlength="200" required :disabled="readOnly|| businessAddValid === false|| mailingAddValid === false" />
                <!-- <span class="form-error error">Please provide a contact name</span> -->
                <span class="error" id="conNameError" style="display:none;">Please provide a contact name</span>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="PrimaryContacttitle">Primary Contact title:</label>
                <input v-model="businessInformationUpdate.primaryContactTitle" type="text" id="PrimaryContacttitle"
                    maxlength="200" required :disabled="readOnly|| businessAddValid === false|| mailingAddValid === false" />
                <!-- <span class="form-error error">Please provide a title</span> -->
                <span class="error" id="conTitleError" style="display:none;">Please provide a title</span>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="PrimaryContactEmail">Primary Contact E-mail:</label>
                <input v-model="businessInformationUpdate.primaryContactEmail" type="text" id="PrimaryContactEmail"
                    maxlength="100" pattern="email" required :disabled="readOnly|| businessAddValid === false|| mailingAddValid === false" />
                <!-- <span class="form-error error">Please enter email</span> -->
                <span class="error" id="conEmailError" style="display:none;">Please enter email</span>
                <span class="form-error error" data-form-error-on="pattern">Please enter valid email address. Eg. Something@domain.com</span>
            </div>
            <div class="formRow phoneField cell medium-6">
                <span class="req">*</span>
                <label for="PrimaryContactPhone">Primary Contact Phone:</label>
                <input v-model="businessInformationUpdate.primaryContactPhone" type="text" id="PrimaryContactPhone"
                    maxlength="12" placeholder="###-###-####" pattern="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                    required :disabled="readOnly|| businessAddValid === false|| mailingAddValid === false" />
                <!-- <span class="form-error error">Please provide a contact phone</span> -->
                <span class="error" id="conPhoneError" style="display:none;">Please provide a contact phone</span>
                <span class="form-error error" data-form-error-on="pattern">Please enter valid phone number. Ex.
                    ###-###-####</span>
            </div>
            <div class="formRow cell medium-6">
                <label for="SecondaryContactName">Secondary Contact Name:</label>
                <input v-model="businessInformationUpdate.secondaryContactName" type="text" id="SecondaryContactName"
                    maxlength="200" class=" secondaryContactName" :disabled="readOnly|| businessAddValid === false|| mailingAddValid === false" />
            </div>
            <div class="formRow cell medium-6">
                <label for="SecondaryContacttitle">Secondary Contact title:</label>
                <input v-model="businessInformationUpdate.secondaryContactTitle" type="text" id="SecondaryContacttitle"
                    maxlength="200" :disabled="readOnly|| businessAddValid === false|| mailingAddValid === false" />
            </div>
            <div class="formRow cell medium-6">
                <label for="SecondaryContactEmail">Secondary Contact E-mail:</label>
                <input v-model="businessInformationUpdate.secondaryContactEmail" type="text" id="SecondaryContactEmail"
                    maxlength="100" pattern="email" :disabled="readOnly|| businessAddValid === false|| mailingAddValid === false" />
                <span class="form-error error" data-form-error-on="pattern">Please enter valid email address. Eg.
                    Something@domain.com</span>
            </div>
            <div class="formRow cell medium-6">
                <label for="SecondaryContactPhone">Secondary Contact Phone:</label>
                <input type="text" v-model="businessInformationUpdate.secondaryContactPhone" id="SecondaryContactPhone" maxlength="12" :disabled="readOnly|| businessAddValid === false|| mailingAddValid === false" placeholder="###-###-####" pattern="^\d{3}-\d{3}-\d{4}$" @input="phoneFormatContactSecondaryPhone($event)">
                <span class="form-error error" v-if="secondaryPhoneError">Please enter a valid phone number. Eg. 555-555-5555</span>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="moduleContent cf">
            <!-- BusinessUpdate -->
            <h3>Business Information Update</h3>
            <div class="moduleContent cf appReview cf">
                <h4>Business Name</h4>
                <dl class="reviewFields cf">
                    <dt>Legal Business Name</dt>
                    <dd>{{ businessInformationUpdate.businessLegalName }}</dd>
                    <dt>Doing Business As</dt>
                    <dd>{{ businessInformationUpdate.businessDoingBusinessAs }}</dd>
                    <dt>Type of Business</dt>
                    <dd>{{ businessInformationUpdate.businessType }}</dd>
                    <dt>Business Phone</dt>
                    <dd>{{ businessInformationUpdate.businessPhoneNumber }}</dd>
                    <dt>Alternate Phone</dt>
                    <dd>{{ businessInformationUpdate.businessPhoneNumberSecondary }}</dd>
                    <dt>Business Fax</dt>
                    <dd>{{ businessInformationUpdate.businessFaxNumber }}</dd>
                </dl>
                <h4>Business Address</h4>
                <dl class="reviewFields cf">
                    <dt>Legal Business Address</dt>
                    <dd>
                        {{ businessInformationUpdate.legalStreetAddress }}<br>
                        {{ businessInformationUpdate.legalCity }} {{ businessInformationUpdate.legalState }}, {{ businessInformationUpdate.legalZip }} {{ businessInformationUpdate.legalZipPlus4 }}<br>
                        {{ businessInformationUpdate.legalCounty }}
                        <a :href="'https://www.google.com/maps/place/' + businessInformationUpdate.legalStreetAddress + ',+' + businessInformationUpdate.legalCity + ',+' + businessInformationUpdate.legalState + '+' + businessInformationUpdate.legalZip" target="_blank">View on map</a>
                    </dd>
                    <dt v-if="businessInformationUpdate.mailingSameAsBusness === false">Mailing Address</dt>
                    <dd v-if="businessInformationUpdate.mailingSameAsBusness === false">
                        {{ businessInformationUpdate.mailingStreetAddress }}
                        {{ businessInformationUpdate.mailingCity }} {{ businessInformationUpdate.mailingState }}, {{ businessInformationUpdate.mailingZip }} {{ businessInformationUpdate.mailingZipPlus4 }}
                    </dd>
                    <dt v-if="businessInformationUpdate.liquorLicenseID !== null">
                        Liquor License ID
                    </dt>
                    <dd v-if="businessInformationUpdate.liquorLicenseID !== null">
                        {{ businessInformationUpdate.liquorLicenseID }}
                    </dd>
                </dl>
                <h4>Contact Information</h4>
                <dl class="reviewFields cf">
                    <dt>Primary Contact Name</dt>
                    <dd>{{ businessInformationUpdate.primaryContactName }}</dd>
                    <dt>Primary Contact Title</dt>
                    <dd>{{ businessInformationUpdate.primaryContactTitle }}</dd>
                    <dt>Primary Contact E-mail</dt>
                    <dd>{{ businessInformationUpdate.primaryContactEmail }}</dd>
                    <dt>Primary Contact Phone</dt>
                    <dd>{{ businessInformationUpdate.primaryContactPhone }}</dd>
                    <dt>Secondary Contact Name</dt>
                    <dd>{{ businessInformationUpdate.secondaryContactName }}</dd>
                    <dt>Secondary Contact Title</dt>
                    <dd>{{ businessInformationUpdate.secondaryContactTitle }}</dd>
                    <dt>Secondary Contact E-mail</dt>
                    <dd>{{ businessInformationUpdate.secondaryContactEmail }}</dd>
                    <dt>Secondary Contact Phone</dt>
                    <dd>{{ businessInformationUpdate.secondaryContactPhone }}</dd>
                </dl>
                <h4 v-if="rejected">Business Hours</h4>
                <dl class="reviewFields cf" v-if="rejected">
                    <dt>Monday</dt><dd>{{ businessInformationUpdate.businessHours1 }}</dd>
                    <dt>Tuesday</dt><dd>{{ businessInformationUpdate.businessHours2 }}</dd>
                    <dt>Wednesday</dt><dd>{{ businessInformationUpdate.businessHours3 }}</dd>
                    <dt>Thursday</dt><dd>{{ businessInformationUpdate.businessHours4 }}</dd>
                    <dt>Friday</dt><dd>{{ businessInformationUpdate.businessHours5 }}</dd>
                    <dt>Saturday</dt><dd>{{ businessInformationUpdate.businessHours6 }}</dd>
                    <dt>Sunday</dt><dd>{{ businessInformationUpdate.businessHours7 }}</dd>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import USPSAddressCheckVue from '../../Shared/USPS-AddressCheck.vue';
export default {
    props: {
        businessInformationUpdate: Object,
        userId: Number,
        hasRenewal: Boolean,
        pageContent: Object,
        readOnly: Boolean,
        rejected: Boolean,
        businessAddValid: Boolean,
        mailingAddValid: Boolean,
        editing: Boolean
    },
    components: {
        USPSAddressCheckVue
    },
    data() {
        return {
            updateBusinessForm: false,
            error: {
                zipCode: false,
                zipCode4: false,
                mailingZipCode: false,
                mailingZipCode4: false,
            },
            secondaryPhoneError: false,
            legalNameOrig: '',
            legalAddressOrig: '',
            legalCityOrig: '',
            legalStateOrig: '',
            legalCounty: '',
            legalZipOrig: '',
            legalNameChanged: false,
            legalAddressChanged: false,
            legalCityChanged: false,
            legalStateChanged: false,
            legalZipChanged: false,
            sameAsMailing: false,
            streetAddress: '',
            city: '',
            state: '',
            zip: ''
        }
    },
    mounted: function () {
        // let _this = this;
        $("#updateBusinessForm").foundation();

        $('#updateBusinessForm').on("invalid.zf.abide", function (ev, elem) {
            let field = ev.target;
            field.id === 'LegalZip' && (this.error.zipCode = true);
            field.id === 'LegalZipPlus4' && (this.error.zipCode4 = true);
            field.id === 'MailingZip' && (this.error.mailingZipCode = true);
            field.id === 'MailingZipPlus4' && (this.error.mailingZipCode4 = true);
        }).on("valid.zf.abide", function (ev, elem) {
            let field = ev.target;
            field.id === 'LegalZip' && (this.error.zipCode = false);
            field.id === 'LegalZipPlus4' && (this.error.zipCode4 = false);
            field.id === 'MailingZip' && (this.error.mailingZipCode = false);
            field.id === 'MailingZipPlus4' && (this.error.mailingZipCode4 = false);
        }).on("formvalid.zf.abide", function (ev, frm) {
            // console.log("Form id " + frm.attr('id') + " is valid");
            this.updateBusinessForm = true;
        });

        this.setOriginalVals();
        // console.log(this.businessInformationUpdate);
    },
    computed: {
        checkboxMailingSameAsBusiness: function(){
            // console.log(this.businessInformationUpdate.mailingSameAsBusiness);
            if(this.businessInformationUpdate.mailingSameAsBusiness === true){ 
                return true
            }else{
                this.reInitFoundation();
            }
        }
    },
    methods: {
        validateAddress(section) {
            this.$parent.validateAddress(section);
        },
        invalidateBusiness() {
            this.$parent.invalidateAddress('bus', this.businessInformationUpdate);
        },
        invalidateMailing() {
            this.$parent.invalidateAddress('mail', this.businessInformationUpdate);
        },
        reInitFoundation(){
            setTimeout(() => {
                Foundation.reInit('abide');
            }, 200);
        },
        setOriginalVals() {
            if (this.businessInformationUpdate.businessLegalName) {
                this.legalNameOrig = this.businessInformationUpdate.businessLegalName;
            }
            if (this.businessInformationUpdate.legalStreetAddress) {
                this.legalAddressOrig = this.businessInformationUpdate.legalStreetAddress;
            }
            if (this.businessInformationUpdate.legalCity) {
                this.legalCityOrig = this.businessInformationUpdate.legalCity;
            }
            // Parse County Using this.mapSelect(text, id of select element)
            if (this.businessInformationUpdate.legalCounty && !this.readOnly) {
                this.businessInformationUpdate.legalCounty = this.mapSelect(this.businessInformationUpdate.legalCounty, "LegalCounty");
            }
            if (this.businessInformationUpdate.legalState) {
                this.legalStateOrig = this.businessInformationUpdate.legalState;
            }
            if (this.businessInformationUpdate.legalZip) {
                this.legalZipOrig = this.businessInformationUpdate.legalZip;
            }
            this.sameAsMailing = this.businessInformationUpdate.mailingSameAsBusiness;
            this.businessInformationUpdate.businessHours1 = this.xmlBusinessHours(this.businessInformationUpdate.businessHours1);
            this.businessInformationUpdate.businessHours2 = this.xmlBusinessHours(this.businessInformationUpdate.businessHours2);
            this.businessInformationUpdate.businessHours3 = this.xmlBusinessHours(this.businessInformationUpdate.businessHours3);
            this.businessInformationUpdate.businessHours4 = this.xmlBusinessHours(this.businessInformationUpdate.businessHours4);
            this.businessInformationUpdate.businessHours5 = this.xmlBusinessHours(this.businessInformationUpdate.businessHours5);
            this.businessInformationUpdate.businessHours6 = this.xmlBusinessHours(this.businessInformationUpdate.businessHours6);
            this.businessInformationUpdate.businessHours7 = this.xmlBusinessHours(this.businessInformationUpdate.businessHours7);
        },
        phoneFormatBusinessPhoneNumber() {
            var x = this.businessInformationUpdate.businessPhoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.businessInformationUpdate.businessPhoneNumber = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        phoneFormatBusinessPhoneNumberSecondary() {
            var x = this.businessInformationUpdate.businessPhoneNumberSecondary.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.businessInformationUpdate.businessPhoneNumberSecondary = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        phoneFormatBusinessFaxNumber() {
            var x = this.businessInformationUpdate.businessFaxNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.businessInformationUpdate.businessFaxNumber = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        saveBusinessData() {
            // might not be needed
        },
        isMailingSame() {
            this.businessFormData.mailingSameAsBusiness = !this.businessFormData.mailingSameAsBusiness;
        },
        phoneFormatContactSecondaryPhone() {
            var x = this.businessInformationUpdate.secondaryContactPhone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.businessInformationUpdate.secondaryContactPhone = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        legalValueChanged(field) {
            // compare original values to changed
            // if not the same then show message
            switch (field) {
                case "legalName":
                    if (this.legalNameOrig !== this.businessInformationUpdate.businessLegalName) {
                        this.legalNameChanged = true;
                    } else {
                        this.legalNameChanged = false;
                    }
                    console.log(this.legalNameOrig, this.businessInformationUpdate.businessLegalName, this.legalNameChanged);
                    break;
                case "legalAddress":
                    if (this.legalAddressOrig !== this.businessInformationUpdate.legalStreetAddress) {
                        this.legalAddressChanged = true;
                    } else {
                        this.legalAddressChanged = false;
                    }
                    break;
                case "legalCity":
                    if (this.legalCityOrig !== this.businessInformationUpdate.legalCity) {
                        this.legalCityChanged = true;
                    } else {
                        this.legalCityChanged = false;
                    }
                    break;
                case "legalState":
                    if (this.legalStateOrig !== this.businessInformationUpdate.legalState) {
                        this.invalidateBusiness();
                        this.legalStateChanged = true;
                    } else {
                        this.legalStateChanged = false;
                    }
                    break;
                case "legalZip":
                    if (this.legalZipOrig !== this.businessInformationUpdate.legalZip) {
                        this.legalZipChanged = true;
                    } else {
                        this.legalZipChanged = false;
                    }
                    break;
                    
            
                default:
                    break;
            }
        },
        toggleMailing() {
            this.invalidateMailing();

            this.sameAsMailing = !this.sameAsMailing;
            this.businessInformationUpdate.mailingSameAsBusiness = this.sameAsMailing;
            if (this.sameAsMailing === true) {
                this.businessInformationUpdate.mailingName = null;
                this.businessInformationUpdate.mailingStreetAddress = null;
                this.businessInformationUpdate.mailingCity = null;
                this.businessInformationUpdate.mailingState = null;
                this.businessInformationUpdate.mailingZip = null;
                this.businessInformationUpdate.mailingZipPlus4 = null;
            }
        },
        uspsVerified(msg) {
            if (msg.passthrough === 'business') {
                this.businessInfo.businessStreetAddress = msg.mailingAddress;
                this.businessInfo.businessCity = msg.city;
                this.businessInfo.businessState = msg.state;
                this.businessInfo.businessZip = msg.zip;
                this.businessInfo.businessZipPlus4 = msg.zipFour;

                this.businessAddValid = true;

                this.uspsClose();
            } else {
                this.businessInfo.mailingStreetAddress = msg.mailingAddress;
                this.businessInfo.mailingCity = msg.city;
                this.businessInfo.mailingState = msg.state;
                this.businessInfo.mailingZip = msg.zip;
                this.businessInfo.mailingZipPlus4 = msg.zipFour;

                this.mailingAddValid = true;

                this.uspsClose();
            }

            if (this.businessAddValid === true && this.mailingAddValid === true) {
                this.validated = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#updateBusinessForm{
    .validateZip{
        input{
            width: auto;
        }
    }
}
.padTheTop {
    padding-top: 15px;
}
</style>