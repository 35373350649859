<template>
    <div class="eAppModule eAppBackgroundCheck">
        <h3>Background Check</h3>
        <hollow-dots-spinner :animation-duration="1000"
                             :dot-size="15"
                             :dots-num="3"
                             color="#0FD8C9"
                             v-if="isLoading" />
        <!--<div v-html="BackgroundCheckContent">
           
        </div>-->

        <div v-if="!isLoading">

            <div class="eApp-Intro" v-html="pageContent.backgroundCheckIntro"></div>

            <ul class="principalList" v-if="hasPrincipals">
                <li v-for="(principal, index) in backgroundCheckListResponse.principals" v-bind:key="index" class="principalItem cf">
                    <div class="principalInfo">
                        <span v-if="principal.locked" class="icon-lock"></span>
                        <span class="principalName">{{ principal.principalName }}</span>
                    </div>
                    <div class="principalBtns">
                        <!-- if principal === user don't show if status === pending authoriztion -->
                        <div v-if="principal.showStatus" :class="principal.statusCssClass">
                            Status: <strong>{{principal.status}}</strong>
                        </div>
                        <button v-if="principal.showAuthorize && application.applicationStatus === 1 || principal.showAuthorize && application.applicationStatus === 8" type="button" @click="loadAuthorizeForm(principal.principalId)" class="button btn-secondary">{{principal.authorizeText}}</button>
                    </div>
                </li>
            </ul>
            <div v-else class="InfoLabel alert-message">
                There are no principals added yet. Please add some principals to the application
            </div>


            <div class="btn-actions" v-if="hasPrincipals && !isDisabled && owner">
                <button type="button" class="btnNext button" @click="onSaveClick('next')" title="Proceed to next page">Review Application</button>
            </div>
        </div>
       

    </div>
</template>


<script>
    //import $ from 'jquery';

    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {
            HollowDotsSpinner
        },
        props: {
            readOnly: Boolean,
            app: String,
            userRole: String
        },
        data: function () {
            return {
                bgToken:'',
                userId: 0,
                eappId: '',
                isLoading: true,
                backgroundCheckListResponse: [],
                hasPrincipals:true,
                contentKey: 'eapp-regular-application',
                pageContent: {},
                isDisabled: false,
                application: '',
                owner: false
            }
        },
        mounted: function () {
            this.isLoading = true;
            this.isDisabled = this.readOnly;
            this.onMounted();
        },
        computed:
        {
            BACKGROUND_CHECK_LIST_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/user/` + this.userId + `/eapplication/` + this.eappId + `/backgroundchecklist`;
            }
        },
        methods: {
            onMounted() {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    this.userId = uId;
                    this.getAPItoken()
                        .then((token) => {
                            this.bgToken = token;
                            this.getContent();
                            this.tryGetFromQueryString().then(() => {
                                this.fetchData().then(() => {
                                    if (this.backgroundCheckListResponse.principals !== undefined && this.backgroundCheckListResponse.principals !== null) {
                                        this.hasPrincipals = true;
                                    }

                                    let application = JSON.parse(this.app);

                                    if (this.userId === application.createdBy) {
                                        this.owner = true;
                                    }
                                    // console.log('has principals');
                                    // console.log(this.hasPrincipals);

                                    // console.log(this.backgroundCheckListResponse.principals);
                                    this.isLoading = false;
                                });
                            });
                        });
                }

                this.application = JSON.parse(this.app);
                console.log(this.userRole)
                // this.pageContent = this.getContentByKey(this.contentKey, 'eapp_regular_application');
                // console.log(this.pageContent);
            },
            getContent() {
                let cookieContent = JSON.parse(localStorage.getItem('eapp_regular_application'));
                if (cookieContent !== undefined && cookieContent !== "" && cookieContent !== null) {
                    this.pageContent = JSON.parse(cookieContent);
                } else {
                    this.fetchContent();
                }
            },
            fetchContent() {
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+'/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey='+this.contentKey, {
                    headers: {
                        'Authorization': 'Bearer '+this.bgToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent;
                    localStorage.setItem('eapp_regular_application', JSON.stringify(element)); 
                    this.pageContent = JSON.parse(element);
                })
            },
            async fetchData() {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + this.bgToken,
                        "Content-Type": "application/json"
                    },
                };
                this.backgroundCheckListResponse = await (await fetch(this.BACKGROUND_CHECK_LIST_API_URL, appsRequestOptions)).json();

                return Promise.resolve();
            },
            tryGetFromQueryString() {

                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('id')) {
                    this.eappId = urlParams.get('id');
                }

                return Promise.resolve();
            },
            loadAuthorizeForm(principalId) {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);

                if (urlParams.has('step')) {
                    urlParams.set('step', 'authorize');
                } else {
                    urlParams.append('step', 'authorize');
                }

                if (urlParams.has('p')) {
                    urlParams.set('p', principalId);
                } else {
                    urlParams.append('p', principalId);
                }

                if (urlParams.has('n')) {
                    urlParams.set('n', 1);
                } else {
                    urlParams.append('n', 1 );
                }

                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;

            },
            onSaveClick(dir) {
                let request = {};
                request.dir = dir;
                this.$emit('onBackgroundSaveClick', request);
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
