<template>
  <div class="full social_promo__wrapper">
      <header class="social_promo__header">
          <div><img src="/getattachment/b8db3ae0-f628-41d4-8d00-0268bcbd0d12/rockpaperscissors_scratch_logo.png?chset=89c3e13f-357e-49c2-8cde-d28a03e0fb30" alt="Scratch-Offs Logo" class="img-responsive"></div>
          <div class="ggrobarLogo"><img src="/getattachment/240b5db1-3151-40d9-986a-7c70eb9c676b/Asset-3.png?chset=ff8b036c-594d-4a1d-a5c5-13c54949b29e" alt="Ticket Logo" class="img-responsive" width="242"></div>
          <div><img src="/getattachment/e798346b-b0a8-4c75-b2bf-5e572fc1573d/rockpaperscissors_oh_logo.png?chset=6817173e-5db8-4f32-a98f-f025adef67ad" alt="Ohio Lottery Logo" class="img-responsive"></div>
      </header>
      <section class="social_promo__intro">
          <h1>Second Chance Giveaway</h1>
          <h2>December 14-20</h2>
          <p>Enter to win a book of the limited ticket!</p>
      </section>
      <SignInRegister v-if="!isUserLogged"></SignInRegister>
      <div v-else><!--v-else-->
        <div class="social_promo__play" v-if="isUserLogged">
          <div v-if="selected === null" class="initial_scene">
            <img src="/getattachment/dc1860b6-0b6d-43aa-9ecf-1ed19aeceaf9/ggrobar-backdrop-initial.jpg?chset=8e8ea8fe-16c2-43fa-afe7-197b4db68467" alt="Street Signs Default Image">
            <!-- <button class="btnStart" @click="compareChoiceToWin()">Start</button> -->
          </div>
          <div v-if="selected" class="social_promo_videos responsive_video">
            <!-- <video id="vid-player" playsinline muted autoplay class="social_promo_video">
              <source type="video/mp4" :src="vidUrl"> -->
              <iframe :src="vidUrl" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
            <!-- </video> -->
          </div>
      </div>
      <p class="social_promo_txt text-center" v-if="isUserLogged">Choose your road and run, run, run as fast as you can!</p>
      <div class="social_promo_controls" v-if="isUserLogged">
          <button 
            class="socialPromoBtn btnSweet"
            :class="{ active: selected === 'Sweet' }"
            @click="selectOption('Sweet')">
            Sweet
          </button>
          <button 
            class="socialPromoBtn btnHolly"
            :class="{ active: selected === 'Holly' }"
            @click="selectOption('Holly')">
            Holly
          </button>
          <button 
            class="socialPromoBtn btnMerry"
            :class="{ active: selected === 'Merry' }"
            @click="selectOption('Merry')">
            Merry
          </button>
      </div>
      </div>
      <section class="results-message" v-if="hasResult">
          <div v-if="hasResult === 'Win'" class="message1">
            <h2>{{ winTitle }}</h2>
            <p>{{ winMsg }}</p>
          </div>
          <div v-if="hasResult === 'Lose'" class="message2">
            <h2>{{ loseTitle }}</h2>
            <p>{{ loseMsg }}</p>
          </div>
      </section>
      <section class="social_promo__footer">
          <div class="social_promo__details">
              <h2>$800 in total prizes!</h2>
              <p>
                  Four winners will win a book of<br>
                  GRANDMA GOT RUN OVER BY A REINDEER<br>
                  Scratch-Off tickets (valued at $200 each).<br>
                  Winner announced: December 21
              </p>
              <a href="/grandma-got-run-over-by-a-reindeer/grandma-got-run-over-by-a-reindeer-rules">SEE COMPLETE GAME RULES</a>
          </div>
          <div class="social_promo__ticket">
              <img src="/getattachment/7184f260-36fa-416f-9888-3377165f2910/1420_TicketArtFront-60.jpeg?chset=34a6aa0b-aff5-4cfb-ab92-0bc11ed7bb3d" alt="Grandma Got Run Over by a Reindeer Ticket" class="img-responsive">
          </div>
      </section>
      <section class="social_promo__disclaimers">
          <div>
              <p>TM &amp; &copy; 2000, 2006 The Fred Rappoport Company, Inc.<br>
                  All Rights Reserved. Licensing by Unforgettable Licensing.
              </p>
          </div>
          <div>
              <p class="playResp">Play responsibly.</p>
          </div>
      </section>
  </div>
</template>

<script>
import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';

import { ref } from 'vue';

  export default {
      components: {
        SignInRegister
      },
      data: function () {
          return {
              isUserLogged: false,
              selected: null,
              hasResult: null,
              usersChoice: '',
              token: {},
              win: '',
              vidUrl: '',
              winTitle: 'GRANDMA DUCKED THE REINDEER.',
              winMsg: "She made it just in time. You're entered to win.",
              loseTitle: 'GRANDMA GOT RUN OVER BY A REINDEER.',
              loseMsg: "Poor grandma. You're still entered to win.",
              id: '',
              useAltMsg: true,
              winAltMsg: "She's quicker than she looks. You're entered to win!",
              winAltTitle: 'GRANDMA DODGED THE REINDEER.',
              winOrgTitle: "GRANDMA DUCKED THE REINDEER.",
              winOrgMsg: "She made it just in time. You're entered to win.",
              drawingId: 55
          }
      },
      mounted: function () {
          this.isUserLogged = this.isUserAuthed();
          this.getToken();
          this.getWin();
      },
      methods: {
          getToken() {
              // then hit API with token
              this.getAPItoken()
                .then((token) => {
                  // this.fetchData(token);
                  this.token = token;
                  // console.log('Hello World');
              }).then(() => {
                this.id = this.getLoggedInUserId();
              }).then(() => {
                //if not logged in don't check
                this.checkIfUserEntered();
                // this.enterUserIntoDrawing();
              });

          },
          enterUserIntoDrawing() {
            let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/AddDrawingEntries?entries=1&drawingId=' + this.drawingId +'&userID=' + this.id;

            fetch(url, {
              method: 'POST',
              headers: {
                // 'Content-Type': 'application/json-patch+json',
                'Authorization': "Bearer " + this.token
              }
            })
              .catch(error => {
                console.log(error);
              })
          },
          checkIfUserEntered() {
            let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/GetActiveDrawingEntriesByUserId/' + this.id;

            fetch(url, {
              method: 'GET',
              headers: {
                // 'Content-Type': 'application/json-patch+json',
                'Authorization': "Bearer " + this.token
              }
            })
              .then((response) => response.json())
              .then((json) => {
                if (json.data.length) {
                  this.processEntries(json.data);
                } else {
                  // console.log('No entries')
                }
              })
              .catch(error => {
                console.log(error);
              })
          },
          processEntries(arr) {
            let allowEntry = true;
            arr.forEach(element => {
              if (element.entryCount > 0 && element.plainDrawingID === this.drawingId) {
                // console.log('Already entered');
                allowEntry = false;
              }
            });
            if (allowEntry) {
              this.enterUserIntoDrawing();
            }
          },
          // fetchData(token) {
          //     var apiToken = token;
          //     var urlProtocol = this.getProtocol();

          //     return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetAllByCategory/FeaturedWinner", {
          //         headers: {
          //             'Authorization': 'Bearer '+apiToken,
          //         },
          //     })
          //     .then(response => response.json())
          //     .then(json => {
          //         var res = json.data;

          //         function sortByDate(a, b) {
          //             return new Date(b.date).getTime() - new Date(a.date).getTime();
          //         }
                 
          //         res.sort(sortByDate);

          //         this.winners = res;

          //     })
          //     .catch(error => {
          //         // console.error("There was an error!", error);
          //         return this.errorMessage = error;
          //     });
          // },
          selectOption(option) {
            this.hasResult = null;
            this.selected = option;
            this.compareChoiceToWin();
          },
          getWin() {
            let returning = Math.random();
            if (returning < 0.3) {
              this.win = 'Sweet';
            } else if (returning > 0.3 && returning < 0.6) {
              this.win = 'Holly';
            } else {
              this.win = 'Merry';
            }
            // console.log(this.win);
          },
          compareChoiceToWin() {
            if (!this.selected) {
              alert('You must pick an option first.');
              return
            }
            if (this.selected === this.win) {
              // this.vidUrl = '/getattachment/60707acb-49cb-4111-b766-4b47699eea5e/olc22994-1_gma_reindeer_reveal_1_and_2_FULL_SONG_231130.mp4?chset=3e28b045-928f-4bc2-9779-a1b5160402e7&disposition=attachment'
              this.vidUrl = 'https://player.vimeo.com/video/891210348';
              // console.log('Win');
              // let videoPlayer = document.getElementById('vid-player');
              // videoPlayer.play();
              setTimeout(() => {
                this.setWinOrLoseMsg('Win');
              }, 18000);
            } else {
              // this.vidUrl = '/getattachment/bb5b9da4-f3cf-4274-8c6a-e72122e6ed29/olc22994-1_gma_reindeer_reveal_3_FULL_SONG_231130.mp4?chset=0b95003e-2d86-43bd-85aa-0c3ddec1a695&disposition=attachment'
              this.vidUrl = 'https://player.vimeo.com/video/891210693';
              // console.log('Lose');
              // let videoPlayer = document.getElementById('vid-player');
              // videoPlayer.play();
              setTimeout(() => {
                this.setWinOrLoseMsg('Lose');
              }, 11000);
            }
          },
          setWinOrLoseMsg(outcome) {
            if (outcome === 'Win') {
              if (this.useAltMsg) {
                this.winTitle = this.winAltTitle;
                this.winMsg = this.winAltMsg;
                this.useAltMsg = false;
              } else {
                this.winTitle = this.winOrgTitle;
                this.winMsg = this.winOrgMsg;
                this.useAltMsg = true;
              }
               this.hasResult = 'Win';

            } else if (outcome === 'Lose') {
              this.hasResult = 'Lose';
            }
          }
      },
      computed: {
        // selectRoad(road) {
        //   this.usersChoice = road;
        // }
      },
      // watch: {
      //   selected() {
      //     console.log(this.selected);
      //     //do things
      //     if (this.selected === 'Sweet') {
      //       // alert("Run Sweet");
      //     } else if (this.selected === 'Holly') {

      //     } else if (this.selected === 'Merry') {

      //     }
      //   }
      // }
  };
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;700;900&display=swap');

.initial_scene {
  display: grid;

  img {
    grid-area: 1/1;
    z-index: 1;
  }
}
.olc_social_promo {
  background-color: #9bc0e5;
}
.social_promo__play {
  width: 100%;
  max-width: 650px;
  aspect-ratio: 4/3;
  margin: 0 auto 10px;
}
.social_promo_controls {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

//   .container.body-content {
//   background-color: #9bc0e5;
// }

.responsive_video {
  padding-bottom: 75%;
}

iframe {
  width: 100%;
}

.social_promo__wrapper {
min-height: 100vh;
}

.social_promo__header {
display: flex;
justify-content: space-evenly;
align-items: center;
margin-bottom: 20px;
}

.ggrobarLogo {
  margin-left: 10px;
  margin-right: 10px;
}

.social_promo__wrapper h1, 
.social_promo__wrapper h2, 
.social_promo__wrapper p {
color: #002329;
}

.social_promo__wrapper h1 {
font-family: 'Rokkitt', 'Roboto Slab', serif;
font-weight: 900;
font-size: 4rem;
line-height: 1.1;
}

.social_promo__wrapper h2 {
font-size: 3rem;
font-weight: 700;
}

.social_promo__intro {
text-align: center;
}

.social_promo__intro h2 {
font-family: Roboto, Arial, sans-serif;
}

.social_promo__intro p {
font-family: 'Rokkitt', 'Roboto Slab', serif;
font-weight: 700;
font-size: 2.6rem;
line-height: 1.1;
}

.social_promo_txt {
font-size: 2rem;
font-weight: 700;
line-height: 1.1;
}

.social_promo__footer {
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 20px;
}

.social_promo__ticket img {
max-width: 250px;
}

.social_promo__details {
text-align: center;
}

.social_promo__details h2 {
color: #d5001f;
font-weight: 900;
font-family: 'Rokkitt', 'Roboto Slab', serif;
line-height: 1.1;
font-size: 3.6rem;
margin-bottom: 0;
}

.social_promo__details p {
font-weight: 700;
font-size: 1.6rem;
}

.social_promo__details a {
font-weight: 700;
font-style: italic;
margin-bottom: 20px;
display: inline-block;
}

.socialPromoBtn {
background-repeat: no-repeat;
color: transparent;
}

.btnSweet {
width: 172px;
height: 74px;

&.btnActive {
  
}
}
.btnHolly {
width: 172px;
height: 74px;

&.btnActive {
  
}
}
.btnMerry {
width: 172px;
height: 74px;

&.btnActive {
  
}
}

.results-message {
display: flex;
justify-content: center;
align-items: center;
background-repeat: no-repeat;
background-size: cover;
width: 100%;
max-width: 602px;
height: 176px;
margin: 0 auto 20px;

h2 {
  color: #d5001f;
  font-weight: 900;
  font-family: 'Rokkitt', 'Roboto Slab', serif;
  font-size: 3.6rem;
  text-align: center;
  line-height: 1.1;
}

p {
  font-weight: 700;
  text-align: center;
  line-height: 1.1;
}
}

.social_promo__disclaimers p {
font-weight: 700;
font-size: 1.6rem;
text-align: center;
}

video.social_promo_video {
max-width: 100%;
}

@media only screen and (min-width: 641px) {

.social_promo__wrapper h1 {
  font-size: 4.2rem;
}

.social_promo_controls {
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 620px;
  margin: 0 auto;
}
.social_promo__footer {
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 60px;
}  
.social_promo__ticket img {
  transform: rotate(15deg);
}
.social_promo__disclaimers {
  display: flex;
  justify-content: space-evenly;
  padding-top: 60px;
}

// .playResp {
//   margin-left: -100px;
// }
}
</style>