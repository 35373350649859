// NOTE Winning Numbers Module

// Game Markup
// import * as vueMixins from '../../vueMixins';
import moment from 'moment';

// const mixin = vueMixins.default.methods;

export default {
    namespaced: true,
    state: () => ({
        local: window.localStorage,
        olcDrawNumbers: JSON.parse(window.localStorage.getItem('olcDrawNumbers')),
        masterGameList: "ClassicLotto,rollingcash5,Pick5,Pick4,Pick3,luckylife,megamil,pball",
        activeGames: [
            'megamillions',
            'powerball',
            'luckylife',
            'classic',
            'rolling5',
            'pick3',
            'pick4',
            'pick5'
        ],
        games: [
            {
                api: '/1.0/Games/DrawGames/MegaMillions/GetLatestDraws',
                mutation: 'mega',
                logo: '/1.0/Games/DrawGames/MegaMillions/GetGameInformation?getAllGameInfo=true',
                logoMutation: 'megaLogo'
            },
            {
                api: '/1.0/Games/DrawGames/PowerBall/GetLatestDraws',
                mutation: 'power',
                logo: '/1.0/Games/DrawGames/PowerBall/GetGameInformation?getAllGameInfo=true',
                logoMutation: 'powerLogo'
            },
            {
                api: '/1.0/Games/DrawGames/LuckyForLife/GetLatestDraws',
                mutation: 'lucky',
                logo: '/1.0/Games/DrawGames/LuckyForLife/GetGameInformation?getAllGameInfo=true',
                logoMutation: 'luckyLogo'
            },
            {
                api: '/1.0/Games/DrawGames/ClassicLotto/GetLatestDraws',
                mutation: 'classic',
                logo: '/1.0/Games/DrawGames/ClassicLotto/GetGameInformation?getAllGameInfo=true',
                logoMutation: 'classicLogo'
            },
            {
                api: '/1.0/Games/DrawGames/RollingCashFive/GetLatestDraws',
                mutation: 'rolling5',
                logo: '/1.0/Games/DrawGames/RollingCashFive/GetGameInformation?getAllGameInfo=true',
                logoMutation: 'rollingLogo'
            },
            {
                api: '/1.0/Games/DrawGames/Pick3/GetLatestDraws',
                mutation: 'pick3',
                logo: '/1.0/Games/DrawGames/Pick3/GetGameInformation?getAllGameInfo=true',
                logoMutation: 'pick3Logo'
            },
            {
                api: '/1.0/Games/DrawGames/Pick4/GetLatestDraws',
                mutation: 'pick4',
                logo: '/1.0/Games/DrawGames/Pick4/GetGameInformation?getAllGameInfo=true',
                logoMutation: 'pick4Logo'
            },
            {
                api: '/1.0/Games/DrawGames/Pick5/GetLatestDraws',
                mutation: 'pick5',
                logo: '/1.0/Games/DrawGames/Pick5/GetGameInformation?getAllGameInfo=true',
                logoMutation: 'pick5Logo'
            },
            // Scratch-off Games
        ],
        olcNumbers: [],
        drawGames: []
    }),
    mutations: {
        drawGame (state, payload) {
            let stateDrawGames = state.drawGames;

            stateDrawGames.push(payload);
            state.drawGames = stateDrawGames;
        },
        drawGameLogo (state, payload) {
            let stateDrawGames = state.drawGames;

            stateDrawGames[payload.id].numbers.gameLogo = payload.logo;
            stateDrawGames[payload.id].numbers.gameName = payload.name;

            state.drawGames = stateDrawGames;
        },
        storageNumbers (state, payload) {
            let stateStorageNumbers = state.storageNumbers;

            stateStorageNumbers.push(payload.game);

            state.storageNumbers = stateStorageNumbers;
        }
    },
    actions: {
        getOLCDrawGames({dispatch, state}, payload) {
            let storedNumbers = document.getElementById('cmsNumbers').value;
            let parsedNumbers = JSON.parse(storedNumbers);
            return parsedNumbers;
        },
        getOLCScratchGames({dispatch}, payLoad) {
            const apiToken = payLoad.token,
                  game = payLoad.game,
                  localStorage = window.localStorage,
                  localScratchNumbers = JSON.parse(localStorage.getItem('olcScratchNumbers'));

            if (localScratchNumbers === null || localScratchNumbers === undefined) {
                dispatch('fetchScratchoff', {token: apiToken, game: game, update: false});
            } else {
                dispatch('setScratchoffPrice', {game: game, data: null, update: true});
            }
        },
        setLocalNumbers({dispatch, state}, payload) {
            let length = payload.length,
                apiToken = payload.token,
                update = payload.update;

            let gameObject;

            let drawGames = {
                lastAPIDate: moment(),
                games: ''
            };

            let gameList = [];

            let fetchWaiting = async () => {
                dispatch('fetchNumbers', apiToken)
                .then(response => {
                    let res = response.data,
                        game = '';

                    for (let i = 0; i < res.length; i++) {
                        game = res[i];

                        gameList.push(game);
                    }

        //             let game = {
        //                 identifier: state.games[i].mutation,
        //                 betCardArtBack: res.betCartArtBack,
        //                 betCardArtBackURL: res.betCartArtBackURL,
        //                 betCardArtFront: res.betCardArtFront,
        //                 betCardArtFrontURL: res.betCardArtFrontURL,
        //                 closingDate: res.closingDate,
        //                 drawGameId: res.drawGameId,
        //                 drawGameType: res.drawGameType,
        //                 drawSchedule: res.drawSchedule,
        //                 draws: res.draws,
        //                 frequencies: res.frequencies,
        //                 gameLogo: res.gameLogo,
        //                 gameLogoURL: res.gameLogoURL,
        //                 howToPlay: res.howToPlay,
        //                 id: res.id,
        //                 introCallout: res.introCallout,
        //                 lastDayToRedeem: res.lastDayToRedeem,
        //                 mobileAppLogo: res.mobileAppLogo,
        //                 mobileAppLogoURL: res.mobileAppLogoURL,
        //                 name: res.name,
        //                 nodeAliasPath: res.nodeAliasPath,
        //                 number: res.number,
        //                 numericIdentifier: res.numericIdentifier,
        //                 oddsOfWinning: res.oddsOfWinning,
        //                 oddsRelatedInformation: res.oddsRelatedInformation,
        //                 onSaleDate: res.onSaleDate,
        //                 parentNumericIdentifier: res.parentNumericIdentifier,
        //                 payoutPercent: res.payoutPercent,
        //                 price: res.price,
        //                 prizeStructureJSON: res.prizeStructureJSON,
        //                 promotionalLogo: res.promotionalLogo,
        //                 promotionalLogoURL: res.promotionalLogoURL,
        //                 stringIdentifier: res.stringIdentifier,
        //                 tab1Active: res.tab1Active,
        //                 tab1CodeName: res.tab1CodeName,
        //                 tab1Content: res.tab1Content,
        //                 tab1Label: res.tab1Label,
        //                 tab1MobileContent: res.tab1MobileContent,
        //                 tab2Active: res.tab2Active,
        //                 tab2CodeName: res.tab2CodeName,
        //                 tab2Content: res.tab2Content,
        //                 tab2Label: res.tab2Label,
        //                 tab2MobileContent: res.tab2MobileContent,
        //                 tab3Active: res.tab3Active,
        //                 tab3CodeName: res.tab3CodeName,
        //                 tab3Content: res.tab3Content,
        //                 tab3Label: res.tab3Label,
        //                 tab3MobileContent: res.tab3MobileContent,
        //                 tab4Active: res.tab4Active,
        //                 tab4CodeName: res.tab4CodeName,
        //                 tab4Content: res.tab4Content,
        //                 tab4Label: res.tab4Label,
        //                 tab4MobileContent: res.tab4MobileContent,
        //                 tab5Active: res.tab5Active,
        //                 tab5CodeName: res.tab5CodeName,
        //                 tab5Content: res.tab5Content,
        //                 tab5Label: res.tab5Label,
        //                 tab5MobileContent: res.tab5MobileContent,
        //                 thumbnailArt: res.thumbnailArt,
        //                 thumbnailArtURL: res.thumbnailArtUrl,
        //                 ticketArtFront: res.ticketArtFront,
        //                 ticketArtFrontURL: res.ticketArtFrontURL,
        //                 vendorCode: res.vendorCode
        //             }
                    
                    // gameList.push(game);
                })
                .then(() => {
                    drawGames.games = gameList;
                })
                .then(() => {
                    dispatch('setStorage', {games: drawGames, update: update});
                });
            };

            fetchWaiting();
        },
        fetchNumbers({state}, payload) {
            const apiToken = payload;
            console.log(state)

                return fetch(process.env.VUE_APP_APIURL + "/1.0/Games/DrawGame/GetLatestGameNumbers?csv=" + state.masterGameList, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer '+ apiToken
                    }
                })
                .then(response => response.json());
        },
        fetchScratchoff({dispatch}, payLoad) {
            let apiToken = payLoad.token,
                game = payLoad.game,
                urlProtocol = process.env.VUE_APP_APIURL;

            return fetch(urlProtocol + "/1.0/Games/ScratchOffs/ScratchOffGame/GetAllGames", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
            .then(response => response.json())
            .then(json => {
                let data = json.data;

                dispatch('setScratchPrice', {data: data, game: game, update: payLoad.update});
            })
            .catch(error => {
                // console.error("There was an error!", error);
                return this.errorMessage = error;
            });
        },
        setScratchPrice({state}, payLoad) {
            let data = payLoad.data,
                update = payLoad.update;

                let tempScratchNumbers = {
                    date: moment(),
                    games: data
                }

                if (update) {
                    let numbers = localStorage.getItem("olcScratchNumbers");

                    tempScratchNumbers = numbers;
                    tempScratchNumbers.date = moment();
                }

                for (const key in tempScratchNumbers.games) {
                    let pair = tempScratchNumbers.games[key];

                    for (let t = 0; t < pair.length; t++) {
                        let currentGame = pair[t];

                        let tempGame = {
                            "gameID": currentGame.gameID,
                            "nodeAliasPath": currentGame.nodeAliasPath,
                            "gameName": currentGame.gameName,
                            "gameNumber": currentGame.gameNumber,
                            "gamePrice": currentGame.gamePrice,
                            "onSaleDate": currentGame.onSaleDate,
                            "closingDate": currentGame.closingDate,
                            "lastDayToRedeem": currentGame.lastDayToRedeem,
                            "oddsOfWinning": currentGame.oddsOfWinning,
                            "tpDstatus": currentGame.tpDstatus,
                            "tpDdrawDate": currentGame .tpDdrawDate,
                            "jackpotAmount": currentGame.jackpotAmount,
                            "headingIntro": currentGame.headingIntro,
                            "playbookTickets": currentGame.playbookTickets,
                            "playbookPrice": currentGame.playbookPrice,
                            "payoutPercent": currentGame.payoutPercent,
                            "upcNumber": currentGame.upcNumber,
                            "ticketsPerBook": currentGame.ticketsPerBook,
                            "ticketsOrdered": currentGame.ticketsOrdered,
                            "oddsTableData": currentGame.oddsTableData,
                            "gameLogoURL": currentGame.gameLogoURL,
                            "mobileAppLogoURL": currentGame.mobileAppLogoURL,
                            "gameGraphicURL": currentGame.gameGraphicURL,
                            "gameGraphicThumbURL": currentGame.gameGraphicThumbURL,
                            "prizesRemaining": currentGame.prizesRemaining,
                            "nodeID": currentGame.nodeID
                        }

                        tempScratchNumbers.games[key][t] = tempGame;
                    }

                    localStorage.setItem('olcScratchNumbers' + key, JSON.stringify(pair));
                }
                
        },
        setStorage({state}, payload) {
            let storedNumbers = state.games;

            storedNumbers = JSON.stringify(payload.games);
            
            // localStorage.setItem('olcDrawNumbers', storedNumbers);
        }
    },
    getters: {
        getGames(state) {
            return state.activeGames
        },
        getDrawGame(state) {
            return state.drawGames;
        },
        getStorageNumbers() {
            return JSON.parse(window.localStorage.getItem('olcDrawNumbers'));
        },
        getScratchNumbers: () => (payload) => {
            if (payload) {
                // get a specific price point
                return JSON.parse(window.localStorage.getItem('olcScratchNumbers' + payload));
            } else {
                // get all of the games
                return [
                    JSON.parse(window.localStorage.getItem('olcScratchNumbers1')),
                    JSON.parse(window.localStorage.getItem('olcScratchNumbers2')),
                    JSON.parse(window.localStorage.getItem('olcScratchNumbers3')),
                    JSON.parse(window.localStorage.getItem('olcScratchNumbers5')),
                    JSON.parse(window.localStorage.getItem('olcScratchNumbers10')),
                    JSON.parse(window.localStorage.getItem('olcScratchNumbers30')),
                    JSON.parse(window.localStorage.getItem('olcScratchNumbers50'))
                ]
            }
            
        }
    }
}