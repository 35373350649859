import Vue from 'vue';
import Vuex from 'vuex';

// Import modules here
import tokenManagement from './modules/tokenManagement';
import survey from './modules/survey.js';
import drawNumbers from './modules/drawNumbers.js';
import winningNumbers from './modules/winningNumbers.js';
import checkYourNumbers from './modules/checkYourNumbers.js';
import getNumbers from './modules/getNumbers.js'

// Load Vuex
Vue.use(Vuex);

export function load() {
    
}

// Create store
export const store = new Vuex.Store({
    state: {
        user: {
            userId: '',
            username: '',
            firstname: '',
            lastname: ''
        }
    },
    actions: {
        // a-syncronous
        setCurrentUser (state, payload) {
            console.log(payload);
            state.commit("setCurrentUser", payload);
        },
        addModule (state) {
            console.log('I should be appending');
            return state.ohio;
        }
    },
    mutations: {
        // syncronous
        initialiseStore(state) {
			// Check if the ID exists
			if(localStorage.getItem('store')) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store')))
				);
			}
		},
        setCurrentUser (state, user) {
            state.user = user;
        }
    },
    modules: {
        tokenManagement,
        survey,
        drawNumbers,
        winningNumbers,
        checkYourNumbers,
        getNumbers
    },
    getters: {
        getUser(state) {
            return state.user;
        }
    }
});

store.subscribe((mutations, state) => {
    localStorage.setItem('store', JSON.stringify(state));
});

export default store;