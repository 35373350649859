<!-- ~/Views/Games/Pick4/Pick4-WinningNumbers.vue -->

<template>
    <div class="winningNumbersWrap">
        <a href="/games/draw-games/pick-5">
            <img v-bind:src="gameLogo" v-bind:alt="gameName" class="winNumGameLogo" />
        </a>
        <!-- <span>{{game}}</span> -->
        <WinningNumbers :numbers="drawNumbers" :date="date" v-bind:key="drawNumbers.id"></WinningNumbers>

        <a href="/winning-numbers/check-your-numbers#tab7" class="button">Past Draws</a>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import WinningNumbers from '../../../ViewComponents/WinningNumbers/WinningNumbers.vue';

    export default {
        name: 'Pick4',
        props: ['gamesData'],
        components: {
            WinningNumbers
        },
        data: function() {
            return {
                games: this.gamesData,
                drawNumbers: [],
                gameLogo: '',
                gameName: '',
                errorMessage: 'Numbers currently not available. Please try again later.',
                date: ''
            }
        },
        mounted: function() {
            this.setGames();
            // this.getToken();
        },
        methods: {
            setGames() {
                this.games = this.gamesData;
                
                for (let i = 0; i < this.games.length; i++) {
                    if (this.games[i].StringIdentifier === "Pick5") {
                        this.gameName = this.games[i].Name;
                        this.date = this.games[i].Draws[0].DrawDate;
                        this.gameLogo = this.games[i].GameLogoURL;
                        this.drawNumbers = this.games[i].Draws;
                    }
                }
            },
            // fetchData(token) {
            //     var apiToken = token;
            //     var urlProtocol = this.getProtocol();
            //     return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/Pick4/GetLatestDraws", {
            //         headers: {
            //             'Authorization': 'Bearer '+apiToken,
            //         },
            //     })
            //     .then(response => response.json())
            //     .then(json => {
            //         var drawNumbers = json.data;
            //         this.drawNumbers = drawNumbers;
            //     })
            //     .catch(error => {
            //         return this.errorMessage = error;
            //     });
            // },
            // fetchLogo(token) {
            //     var apiToken = token;
            //     var urlProtocol = this.getProtocol();
            //     return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/Pick4/GetGameInformation", {
            //         headers: {
            //             'Authorization': 'Bearer '+apiToken,
            //         },
            //     })
            //     .then(response => response.json())
            //     .then(json => {
            //         var gameData = json.data;
            //         this.gameLogo = gameData.gameLogoURL;
            //         this.gameName = gameData.name;
            //     })
            //     .catch(error => {
            //         // console.error("There was an error!", error);
            //         return this.errorMessage = error;
            //     });

            // },
            ...mapActions ({
                checkLocalNumbers: 'getNumbers/checkLocalNumbers'
            }),
            ...mapGetters ({
                getStorageNumbers: 'getNumbers/getStorageNumbers'
            })
        },
        watch: {
            gamesData() {
                this.setGames();
            }
        }
    };
</script>


<style lang="scss" scoped>
    /* style the unique aspects here */
    .winNumDate {
        display: inline-block;
        margin-right: $global-margin * 0.5;
    }
</style>