import Vue from "vue";

import DrawLanding from "./Drawing-Landing.vue";

export function load(payload) {
    const selector = "draw-landing";
    let store = payload;
  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return;
  }

  // Create a new Vue app with the imported Home component
  new Vue({
    store,
    render: createElement =>
      createElement(DrawLanding, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}
 