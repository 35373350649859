<template>
    <div class="">
        <header id="eAppHeader" class="cf">
            <h1 class="cf">Retailer Network</h1>
        </header>
        <SignInRegister v-if="!isLoading && !isUserLogged"></SignInRegister>

        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />
        <div v-if="!isLoading && isUserLogged && updateFound" class="eAppUpdates">

            <div class="eAppHeader cf">
                <!-- ifIsInRole: eApp-Retailer || eAppPrincipal -->
                <a v-if="showApplicationUpdatesHome" class="button eAppDashBtn" href="/eApplications/Updates">Application Updates Home</a>
                <!-- ifIsInRole: eApp-Retailer && eApp-ChainApplicant -->
                <a v-if="showApplicationsHome" class="button eAppDashBtn" href="/eApplications">Application Home</a>
                <!-- ifIsInRole: eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-RegionalManager || eAppDistrictRep || eApp-Security -->
                <a v-if="showApplicationDashboard" class="button eAppDashBtn" href="/eApplications/Dashboard/Regular">Application Dashboard</a>
                <!-- ifIsInRole: eApp-ChainSalesRep || eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-Security -->
                <a v-if="showChainApplicationDashboard" class="button eAppDashBtn" href="/eApplications/Dashboard/Chain">Chain Application Dashboard</a>

                <h1>Agent Number: <strong>{{ updateWrapper.agentNumber }}</strong></h1>
                <h3 style="clear:both;">Batch Number: <strong>{{ updateWrapper.batchNumber }}</strong></h3>

            </div>
            <div class="cf updateCreateForm module">
                <div class="moduleContent cf">

                    <div v-if="hasErrors" class="ErrorLabel alert-error">
                        There were errors:
                        <ul>
                            <template v-for="error in errors">
                                <li v-if="error !== ''">
                                    {{ error }}
                                </li>
                            </template>
                        </ul>
                    </div>

                    <BusinessUpdate :readOnly="!showSubmitButton" :businessInformationUpdate="businessInformationUpdate" :token="token" :userId="userId">
                    </BusinessUpdate>
                </div>
                <div class="moduleContent updatesActions">
                    <button v-if="showReviewButton" class="button btn-secondary eAppBtnSubmit" data-open="reviewModal">Review</button>
                    <button v-if="showRejectRMButton" class="button btn-secondary eAppBtnSubmit" data-open="rejectModalRegionalManager">Reject</button>
                    <button v-if="showRejectLicButton" class="button btn-secondary eAppBtnSubmit" data-open="rejectModalLicensing">Reject</button>
                    <button v-if="showApproveButton" class="button btn-secondary eAppBtnSubmit" data-open="approveModal">Approve</button>
                    <button v-if="showSubmitButton" class="button btn-secondary eAppBtnSubmit" @click="submitClick()">Submit</button>
                </div>

            </div>
        </div>

        <div class="reveal small eAppModal" id="reviewModal" data-reveal>
            <button class="close-button"  @click="closeModal('#reviewModal')" aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>
            <h2>Mark Update as Reviewed</h2>
            <p>
                Are you done reviewing the Update and wish to move it one step closer to approval?<br>
                <textarea ID="txbxReviewNotes" v-model="reviewMessage" />
            </p>
            <div class="formActions">
                <button class="btnCancel"  @click="closeModal('#reviewModal')" aria-label="Cancel" type="button">Cancel</button>
                <button class="btnSubmitApp button" aria-label="Reject" type="button" @click="reviewClick()">Review</button>
            </div>
        </div>

        <div class="reveal small eAppModal" id="rejectModalRegionalManager" data-reveal>
            <button class="close-button" @click="closeModal('#rejectModalRegionalManager')" aria-label="Close modal" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
            <h2>Reject Update</h2>
            <div class="formRow dividerRow cf">
                <label>Choose the type of rejection:</label>
                <div class="formRowRight cf">
                    <input type="radio" id="one" value="reject" v-model="regionalManagerPicked" />
                    <label for="one">Recommend Reject</label>

                    <input type="radio" id="two" value="return" v-model="regionalManagerPicked" />
                    <label for="two">Return To Retailer</label>
                </div>
            </div>
            <div>
                <label v-if="regionalManagerPicked === 'reject' ">Rejection message:</label>
                <label v-if="regionalManagerPicked === 'return' ">Sections to review message:</label><br />
                <textarea ID="txbxRejectionMessageRegional" v-model="rejectMessage" />
            </div>
            <div class="formActions">
                <button class="btnCancel" @click="closeModal('#rejectModalRegionalManager')" aria-label="Cancel" type="button">Cancel</button>
                <button v-if="regionalManagerPicked === 'reject' " class="button" aria-label="Reject" type="button" @click="rejectRMClick()">Reject</button>
                <button v-if="regionalManagerPicked === 'return' " class="button" aria-label="Reject" type="button" @click="recommendRejectRMClick()">Recommend Reject</button>
            </div>
        </div>

        <div class="reveal small eAppModal" id="rejectModalLicensing" data-reveal>
            <button class="close-button" @click="closeModal('#rejectModalLicensing')" aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>
            <h2>Reject Update</h2>
            <div class="toApplicant">
                <p>
                    Choose the reason(s) for rejection to be included in a message to the retailer:
                </p>
                <div class="rejectOption">
                    <input type="checkbox" id="failedIrs" v-model="failedIrs" /><label for="failedIrs">Failed the IRS check</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedState" v-model="failedState" /><label for="failedState">Failed the Secretary of State check</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedDBA" v-model="failedDBA" /><label for="failedDBA">Failed DBA and/or business address check</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedBank" v-model="failedBank" /><label for="failedBank">Incorrect Bank Account Information</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedPayment" v-model="failedPayment" /><label for="failedPayment">Had a previous bond payment and we need verification of repayment to bond company</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedSignature" v-model="failedSignature" /><label for="failedSignature">Renewal/Updates require a principal signature</label>
                </div>
            </div>
            <div class="formActions">
                <button class="btnCancel" @click="closeModal('#rejectModalLicensing')" aria-label="Cancel" type="button">Cancel</button>
                <button class="btnSubmitApp button" aria-label="Reject" type="button" @click="rejectLicensingClick()">Reject</button>
            </div>
            <div v-if="lmRejectError" class="error">Please provide the reason(s) for rejection.</div>
        </div>

        <div class="reveal small eAppModal" id="approveModal" data-reveal>
            <button class="close-button" @click="closeModal('#approveModal')" aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>
            <h2>Approve Update</h2>
            <div class="formActions">
                <button class="btnCancel" @click="closeModal('#approveModal')" aria-label="Cancel" type="button">Cancel</button>
                <button class="btnSubmitApp button" aria-label="Approve" type="button" @click="approveClick()">Approve</button>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import moment from "moment";
    import Static from "../../../Shared/Static.vue";
    import { HollowDotsSpinner } from 'epic-spinners';
    import SignInRegister from '/ViewComponents/SignInRegister/SignInRegister.vue';
    import BusinessUpdate from '/Views/Eapplication/updates/update-business.vue';

export default {
    components: {
        HollowDotsSpinner,
        SignInRegister,
        BusinessUpdate,
        Static
    },
    data() {
        return {
            isUserLogged: false,
            updateGUID: '', // primary identifier
            updateWrapper: {}, // JSON for entire Update
            isUserAllowed: false, // proper role and identified on the Update
            errors: [],
            hasErrors: false,
            isLoading: false,
            updateFound: false,
            userId: 0,
            agentId: '0',
            token: '',
            readOnly: false,
            eftUpdate: {},
            showEftUpdate: false,
            hasRenewal: false,
            renewal: {},
            reviewMessage:'',
            failedIrs:false,
            failedState: false,
            failedDBA: false,
            failedBank: false,
            failedPayment: false,
            failedSignature: false,
            showReviewButton: false,
            showRejectRMButton: false,
            showRejectLicButton: false,
            regionalManagerPicked: '',
            showApproveButton: false,
            showSubmitButton:false,
            rejectMessage: '',
            applicantRole: ['eApp-Retailer'],
            salesRepRole: ['eAppDistrictRep'],
            regionalManagerRole: ['eApp-RegionalManager'],
            licensingManagerRole: ['eApp-CentralLicensingManager'],
            showApplicationUpdatesHome: false,
            applicationUpdatesHomeRoles: ['eApp-Retailer', 'eAppPrincipal'],
            showApplicationsHome: false,
            applicationsHomeRoles: ['eApp-Retailer', 'eApp-ChainApplicant'],
            showApplicationDashboard: false,
            applicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eAppDistrictRep', 'eApp-EFTReviewer', 'eApp-RegionalManager', 'eApp-Security'],
            showChainApplicationDashboard: false,
            chainApplicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eApp-EFTReviewer', 'eApp-Security', 'eApp-ChainSalesRep', 'eApp-RegionalManager'],
            // rejectModal: false,
            lmRejectError: false,
            successRedirect: '/eapplications/dashboard/regular?appType=updates',
        }
    },
    mounted: function () {
        $(document).foundation();

        this.isUserLogged = this.isUserAuthed();
        if (this.isUserLogged) {
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getContent();
                });
        }

        // get the user ID
        this.userId = this.getLoggedInUserId();
        this.showButtons();

        this.isLoading = false;

    },
    computed: {
        GET_UPDATE_WRAPPER_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/user/' + this.userId + '/reviewupdate/' + this.updateGUID;
        },
        REVIEW_BUSINESS_UPDATE() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/ReviewUpdateBusiness';
        },
        APPROVE_BUSINESS_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/ApproveUpdateBusinessInformation';
        },
        REJECT_BUSINESS_TO_APPLICANT_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/RejectUpdateBusinessInformationToApplicant';
        },
        REJECT_BUSINESS_TO_RM_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/RejectUpdateBusinessInformationToRegionalManager';
        },
        RECOMMEND_REJECT_BUSINESS_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/RecommendRejectUpdateBusinessInformation';
        },
        SUBMIT_BUSINESS_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SubmitUpdateBusiness';
        }
    },
    methods: {
        getContent() {
            this.isLoading = true;
            this.tryGetFromQueryString().then(() => {
                this.getUpdateWrapper();
            });
        },
        showButtons() {
            this.applicationDashboardRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationDashboard = true;
                }
            });

            this.chainApplicationDashboardRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showChainApplicationDashboard = true;
                }
            });

            this.applicationUpdatesHomeRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationUpdatesHome = true;
                }
            });

            this.applicationsHomeRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationsHome = true;
                }
            });

            this.applicantRole.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showReviewButton = false;
                    this.showRejectRMButton = false;
                    this.showRejectLicButton = false;
                    this.showApproveButton = false;
                    this.showSubmitButton = true;
                }
            });

            this.salesRepRole.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showReviewButton = true;
                    this.showRejectRMButton = false;
                    this.showRejectLicButton = false;
                    this.showApproveButton = false;
                    this.showSubmitButton = false;
                }
            });
            this.regionalManagerRole.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showReviewButton = true;
                    this.showRejectRMButton = true;
                    this.showRejectLicButton = false;
                    this.showApproveButton = false;
                    this.showSubmitButton = false;
                }
            });
            this.licensingManagerRole.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showReviewButton = false;
                    this.showRejectRMButton = false;
                    this.showRejectLicButton = true;
                    this.showApproveButton = true;
                    this.showSubmitButton = false;
                }
            });
        },
        getUpdateWrapper() {
            this.updateGUID = this.getURLQuery('updateId');
            if (this.updateGUID == '') {
                this.updateFound = false;
            } else {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    this.userId = uId;
                    this.fetchData();
                }
            }
        },
        async fetchData() {
            const appsRequestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
            };
            this.updateWrapper = await (await fetch(this.GET_UPDATE_WRAPPER_API_URL, appsRequestOptions)).json();

            if (this.updateWrapper) {
                this.updateFound = true;
            } else {
                // using "hasRenewalUpdates" because we don't need to even see this form if there is not an update
                this.updateFound = false;
            }
            this.parseUpdate();
        },
        parseUpdate() {
            this.agentId = this.updateWrapper.agentNumber;

            // is renewal?
            this.hasRenewal = this.updateWrapper.hasRenewal;

            // business info - for Update + Renewals
            if (this.updateWrapper.businessInformationUpdate !== null) {
                this.businessInformationUpdate = this.updateWrapper.businessInformationUpdate;
            }
            else {
                this.businessInformationUpdate = {};
            }

            
            // Who allowed to take action?
            // if (this.updateWrapper.taxUpdate.elementStatus === 1) {
            //     this.showRejectButton = true;
            //     this.showApproveButton = true;
            //     this.showSubmitButton = false;
            // }

            this.isLoading = false;
        },
        async rejectRMClick() {
            this.isLoading = true;
            let body = {};
            body.updateWrapperGuid = this.updateGUID;
            body.userId = this.userId;
            body.message = this.rejectMessage;
            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            await (await fetch(this.REJECT_BUSINESS_TO_APPLICANT_API_URL, appsRequestOptions)).json();
            this.isLoading = false;

            return Promise.resolve();
        },
        async recommendRejectRMClick() {
            this.isLoading = true;
            let body = {};
            body.updateWrapperGuid = this.updateGUID;
            body.userId = this.userId;
            body.message = this.rejectMessage;
            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            await (await fetch(this.RECOMMEND_REJECT_BUSINESS_API_URL, appsRequestOptions)).json();
            this.isLoading = false;

            return Promise.resolve();
        },
        async rejectLicensingClick() {
            this.lmRejectError = false;
            this.isLoading = true;

            if (this.failedBank || this.failedDBA || this.failedIrs || this.failedPayment || this.failedSignature || this.failedState) {
                let body = {};
                let message = '';

                message += '<ul>';
                if (this.failedIrs) {
                    message += '<li>Failed the IRS check" Value="Failed the IRS check</li>';
                }

                if (this.failedState) {
                    message += '<li>Failed the Secretary of State check</li>';
                }

                if (this.failedDBA) {
                    message += '<li>Failed DBA and/or business address check</li>';
                }

                if (this.failedBank) {
                    message += '<li>Incorrect Bank Account Information</li>';
                }

                if (this.failedPayment) {
                    message += '<li>Had a previous bond payment and we need verification of repayment to bond company</li>';
                }

                if (this.failedSignature) {
                    message += '<li>Renewal/Updates require a principal signature</li>';
                }
                message += '</ul>';

                body.updateWrapperGuid = this.updateGUID;
                body.userId = this.userId;
                body.message = message;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        body
                    )
                };

                let response = await (await fetch(this.REJECT_BUSINESS_TO_APPLICANT_API_URL, appsRequestOptions)).json();
                // redirect if successful
                if (response.errorList.length > 0) {
                    this.errors = response.errorList;
                    this.hasErrors = true;
                    this.isLoading = false;
                    this.closeModal('#rejectModalLicensing');
                }
                else {
                    // alert(response);
                    window.location.assign(this.successRedirect);
                } 

                this.isLoading = false;

                return Promise.resolve();
            } else {
                this.lmRejectError = true;
                this.isLoading = false;
            }
        },
        async approveClick() {
            this.isLoading = true;
            let body = {};
            body.updateWrapperGuid = this.updateGUID;
            body.userId = this.userId;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            let response = await (await fetch(this.APPROVE_BUSINESS_API_URL, appsRequestOptions)).json();
            // redirect if successful
            if (response.errorList.length > 0) {
                this.errors = response.errorList;
                this.hasErrors = true;
                this.isLoading = false;
                this.closeModal('#approveModal');
            }
            else {
                window.location.assign(this.successRedirect);
            } 

            return Promise.resolve();
        },

        async submitClick() {
            this.isLoading = true;
            let body = {};
            this.updateWrapper.businessInformationUpdate = this.businessInformationUpdate;
            body.updateWrapper = this.updateWrapper;
            body.userId = this.userId;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            let response = await (await fetch(this.SUBMIT_BUSINESS_API_URL, appsRequestOptions)).json();
            // redirect if successful
            if (response.errorList.length > 0) {
                this.errors = response.errorList;
                this.hasErrors = true;
                this.isLoading = false;
                this.closeModal('#approveModal');
            }
            else {
                window.location.assign(this.successRedirect);
            } 

            return Promise.resolve();
        },
        tryGetFromQueryString() {
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);

            if (urlParams.has('updateId')) {
                this.updateGUID = urlParams.get('updateId');
            }

            if (urlParams.has('an')) {
                this.agentId = urlParams.get('an');
            }

            return Promise.resolve();
        },
        closeModal(target) {
            $(target).foundation('close');
            this.lmRejectError = false;
            $(target).hide();
            $('.reveal-overlay').hide();
        }
    }
}
</script>
