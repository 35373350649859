<template>
	<div class="eAppModule eAppPrincipalList">
        <div v-if="visibleList">
            <h4>Principals</h4>
            <div class="PrincipalListIntro" v-html="PrincipalsIntro"></div>

            <div class="EditingFormError" style="display: none;">
            </div>

            <ul v-if="!noPrincipals" class="principalList">
                <li v-for="(principal, index) in principalsList" :key="index" class="principalItem cf">
                    <div class="principalInfo">
                        {{ principal.principalFirstName }}
                        {{ principal.principalLastName }}
                        <span class="principalPercent">{{ principal.principalPercentOwnership }} % owner</span>
                    </div>
                    <div class="principalBtns cf">
                        <a @click="deletePrincipal(principal.principalInformationID)" v-if="!isDisabled">Delete</a>
                        <a @click="edit(principal.principalInformationID)" class="button editBtn">View</a>
                    </div>
                </li>
            </ul>
            <span v-else class="noPrincipalsLbl">No Principals Entered</span>


            <button type="button" name="btnAddPrincipal" v-if="!isDisabled" id="btnAddPrincipal" @click="addPrincipal()" class="btnAddPrincipal button">Add Principal</button>

            <!-- if % less than 100% -->
            <div class="percentAlert"
                 v-if="totalPrincipalPercentOwnership < 100 && totalPrincipalPercentOwnership > 0">
                <p>
                    Total business ownership entered does not total 100%.<br /><span class="error">
                        Total
                        business ownership entered is not 100%. Please acknowledge it and
                        explain why.
                    </span>
                </p>
                <div class="formRow cf">
                    <label :for="application.principalOwnershipCheck">
                        <input type="checkbox" :id="application.principalOwnershipCheck"
                               v-model="application.principalOwnershipCheck"
                               style="height: auto; margin: 0 5px 0 0;" />
                        I acknowledge that this is correct
                    </label>
                    <div class="formRow" v-if="application.principalOwnershipCheck">
                        <label for="txbxPercentageMissingMessage">Please provide a reason</label>
                        <textarea v-model="application.principalPercentOwnershipMessage" maxlength="200" required id="txbxPercentageMissingMessage"></textarea>
                        <div class="error percentAlert" v-if="showPercentageMissingMessageError">Provide a reason</div>
                    </div>
                    <div class="error percentAlert" v-if="showPrincipalOwnershipCheckError">You must acknowledge that the total business ownership does not total 100%</div>
                </div>
            </div>
            <div class="btn-actions">
                <input type="button" name="" value="Save & Back" @click="onSaveClick('back')" class=" btn-primary btnBack" title="Return to previous page">
                <input type="button" name="" value="Save & Next" @click="onSaveClick('next')" class=" btn-primary btnNext" title="Proceed to next page">
            </div>
        </div>
        <div v-if="!visibleList">
            <principal-add-edit-form :principalJson="principalJson" :readOnly="isDisabled"
                                     :totalPrincipalPercent="application.totalPrincipalPercentage"
                                     @onPrincipalSaveClick="onPrincipalSaveClick"></principal-add-edit-form>
        </div>
    </div>
</template>


<script>
    import PrincipalAddEditForm from './principal-add-edit';
    export default {
        props: {
            appJson: String,
            principalsJson: String,
            businessInfoJson: String,
            readOnly:Boolean
        },
        components: {
            PrincipalAddEditForm
        },
        data: function () {
            return {
                PrincipalsIntro: document.getElementById('PrincipalListContent').value,
                principalsList: [],
                visibleList: true,
                principalJson: String,
                principalId: -1,
                noPrincipals: true,
                businessInfo: {},
                application: {},
                totalPrincipalPercentOwnership: 0,
                isDisabled: false,
                showPercentageMissingMessageError: false,
                showPrincipalOwnershipCheckError: false
            }
        },
        mounted: function () {
            this.businessInfo = JSON.parse(this.businessInfoJson);
            this.principalsList = JSON.parse(this.principalsJson);
            this.application = JSON.parse(this.appJson);
            this.isDisabled = this.readOnly;
            this.tryGetFromQueryString().then(() => {                
                if (this.step == 'principals' && this.principalId == -1 && this.principalsList[0] !== undefined) {
                    if (this.principalsList[0].principalEmail !== undefined && this.principalsList[0].principalEmail !== null) {
                        this.noPrincipals = false;
                    }
                    this.getTotalPercentage();
                    this.visibleList = true;
                } else if (this.principalId >= 0) {
                    this.setEditPrincipal();
                }
                // console.log('hello from list: ');
                // console.log(this.application.totalPrincipalPercentage)
            });
        },
        methods: {
            deletePrincipal(id) {
                this.$emit('deletePrincipal', id);
            },
            edit(id) {
                this.redirectToAddEditForm(id);
            },
            addPrincipal() {
                this.redirectToAddEditForm(0);
            },
            setEditPrincipal() {
                //*
                //if id> 0 try to get from principalsList
                // find principal, serialize pass to child component
                //else new up object, serialize pass to child component
                //* /
                if (this.principalId == 0) {
                    this.principal = {};
                    this.serializePrincipal();
                }
                else {
                    this.principal = this.principalsList.find(x => x.principalInformationID == this.principalId);
                    this.serializePrincipal();
                }

                this.visibleList = false;
            },
            getTotalPercentage() {
                this.principalsList.forEach((prin) => {
                    this.totalPrincipalPercentOwnership += this.int_try_parse(prin.principalPercentOwnership);
                });
            },
           int_try_parse(val) {
               try
               {
                   if (!isNaN(val)) {
                       return parseInt(val, 10);
                   }
                   else {
                       return 0;
                   }
                }
                catch (err) {
                    console.log(err);
                }
            },
            tryGetFromQueryString() {

                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);

                if (urlParams.has('step')) {
                    this.step = urlParams.get('step');
                }
                if (urlParams.has('p')) {
                    this.principalId = urlParams.get('p');
                }
                return Promise.resolve();
            },
            serializePrincipal() {
                this.principalJson = JSON.stringify(this.principal);
            },
            redirectToAddEditForm(id) {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                urlParams.set('p', id);
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            onPrincipalSaveClick(pr) {
                // business-section.vue
                this.$emit('onPrincipalSaveClick', pr);
            },
            onSaveClick(dir) {
                if (this.validate()) {
                    let request = {};
                    request.dir = dir;
                    request.updates = true;
                    request.businessInformation = this.businessInfo;
                    request.principalOwnershipCheck = this.application.principalOwnershipCheck;
                    request.principalPercentOwnershipMessage = this.application.principalPercentOwnershipMessage;
                    this.$emit('onSaveClick', request);
                }
                else {
                    // console.log('could not validate');
                }
                
            },
            validate() {
                this.showPrincipalOwnershipCheckError = false;
                this.showPercentageMissingMessageError = false;
                let totalPercentage = parseInt(this.totalPrincipalPercentOwnership);

                if (this.totalPrincipalPercentOwnership && totalPercentage < 100) {
                    if (!this.application.principalOwnershipCheck) {
                        this.showPrincipalOwnershipCheckError = true;
                        // console.log(this.totalPrincipalPercentOwnership);
                        // console.log(totalPercentage);
                        // console.log(this.showPrincipalOwnershipCheckError);
                        return false;
                    }
                    // check for reason text
                    if (!this.application.principalPercentOwnershipMessage || this.application.principalPercentOwnershipMessage.trim() == '') {
                        this.showPercentageMissingMessageError = true;
                        return false;
                    } else {
                        return true;
                    }
                    // console.log('error validating principal percentages but no error scenarios were hit.');
                    // return false;
                }

                return true;
            }

        }
    };
</script>

<style lang="scss" scoped>

</style>
