<template>
    <div class="full social_promo_wrapper holidazeLanding">
        <div class="secretGame-enterSecret">
            <img :src="isMobile ? enterSecretBackgroundMobile : enterSecretBackground" alt="" class="secretGame-enterSecret-Background">
            <img :src="enterSecretBow" alt="" class="secretGame-enterSecret-Bow">
            <div class="secretGame-enterSecret-TopContent">
                <img v-if="!isUserLogged" :src="isMobile ? enterSecretTextMobile : enterSecretText" alt="You've found the Secret Game" class="secretGame-enterSecret-Text">
                <PromotionCodeEntry v-if="isUserLogged" class="promoCodeEntry" content-key="holidaze-code-entry-content-key" ></PromotionCodeEntry>

                <p v-if="!isUserLogged" class="secretGame-enterSecretButton btn_popupLogIn">ENTER THE SECRET CODE</p>
                <p v-if="!isUserLogged" class="secretGame-enterSecretText">LOG IN OR <a href="/mylotto-rewards/my-account/register">SIGN UP</a> TO GET STARTED</p>

            </div>
        </div>
    
        <div class="secretGame-videoBlock">
            <h2 >{{ headline }}</h2>
            <p>{{ instructions }}</p>
            <div align="center" class="responsive_video secretGame-video">
                <iframe :src="vidUrl" alt="Promotion Video"></iframe>
            </div>
            <h4>{{ nextMessage }}</h4>
        </div>
    
        <div class="secretGame-WinPrizes">
            <img :src="isMobile ? winPrizesBackgroundMobile : winPrizesBackground" alt="" class="secretGame-WinPrizes-Background">
        
            <img :src="winPrizesText" alt="Win $450 in Prizes!" class="secretGame-WinPrizes-Text">
            <img :src="isMobile ? winPrizesTicketsMobile : winPrizesTickets" alt="Lucky Times 10 Ticket and 200$ Gift card" class="secretGame-WinPrizes-Tickets">
        
            <img :src="winPrizesBow" alt="" class="secretGame-WinPrizes-Bow">
        
            <div class="secretGame-WinPrizes-TopContent">
                <img :src="isMobile ? winPrizesSolidBackgroundMobile : winPrizesSolidBackground" alt="" class="secretGame-WinPrizes-SolidBackground">
        
                <div class="secretGame-WinPrizes-ColumnContent">
                    <div class="secretGame-WinPrizes-LeftColumn">
                        <p class="secretGame-WinPrizes-Title">{{ chancesToWin }}</p>
                        <p class="secretGame-WinPrizes-enter">
                            {{ codeWord1 }}
                            <br/>
                            <strong class="strong">{{ entryPeriod1 }}</strong>
                        </p>
                        <p class="secretGame-WinPrizes-enter">
                            {{ codeWord2 }}
                            <br/>
                            <strong class="strong">{{ entryPeriod2 }}</strong>
                        </p>
                        <p class="secretGame-WinPrizes-enter">
                            {{ codeWord3 }}
                            <br/>
                            <strong class="strong">{{ entryPeriod3 }}</strong>
                        </p>
                    </div>
                    <div class="secretGame-WinPrizes-RightColumn">
                        <p class="secretGame-WinPrizes-disclaimer"><em>{{ prizes }}</em></p>
                    </div>
                </div>
        
            </div>
                <p class="secretGame-WinPrizes-official">For full official rules and prizing details, click <a href="/mylotto-rewards/club-promotions/secretgame/rules">here</a>.</p>
        </div>

        <div class="secretGame-TicketsBlock">
            <img :src="scratchTickets" alt="Holiday Scratch Tickets" class="secretGame-Tickets">
            <div class="secretGame-TicketsBlock-Right">
                <h2>{{ slogan }}</h2>
                <h4>{{ giftInfo }}</h4>
                <p v-html="loterryInfo"></p>
            </div>
        </div>
    </div>
  </template>
  <script>
  import PromotionCodeEntry from './/OLC_holidaze_PromotionCodeEntry.vue';

      export default {
        components: {
            PromotionCodeEntry,
        },
        data: function() {
          return {
            isUserLogged: false,
            isMobile: true,

            promoContent: {},
            nextMessage: '',
            vidUrl: '',

            headline: '',
            instructions: '',
            chancesToWin: '',
            codeWord1: '',
            codeWord2: '',
            codeWord3: '',
            entryPeriod1: '',
            entryPeriod2: '',
            entryPeriod3: '',
            prizes: '',
            slogan: '',
            giftInfo: '',
            loterryInfo: '',
  
            enterSecretBackground: '/getattachment/6991f51d-37c8-4f48-9a72-32c7c0168928/2024-Holiday_Secret-Game_YouFoundBackground.png',
            enterSecretBackgroundMobile: '/getattachment/a717dce4-62ab-4d9d-9130-8a215245fefb/2024-Holiday_Secret-Game_YouFoundBackground_Mobile.png',
            enterSecretText: '/getattachment/d3489c2d-54e6-4642-86dc-db01bc95b186/2024-Holiday_Secret-Game_YouFoundText.png',
            enterSecretTextMobile:'/getattachment/203da467-3446-46da-86f4-5f4ce2334a86/2024-Holiday_Secret-Game_YouFoundText_Mobile.png',
            enterSecretBow: '/getattachment/ce3790a0-397c-4c31-8f73-05d916ec245b/2024-Holiday_Secret-Game_YouFoundBow.png',
  
            winPrizesBackground: '/getattachment/95040b9d-0e53-404a-a581-7177cedb2814/2024-Holiday_Secret-Game_WinPrizesBackground.png',
            winPrizesBackgroundMobile: '/getattachment/ce502df7-19a6-4cfc-a7ba-55d8aa4b8d40/2024-Holiday_Secret-Game_WinPrizesBackground_Mobile.png',
            winPrizesSolidBackground: '/getattachment/94862a3a-92b7-4a43-99a0-04821f69140d/2024-Holiday_Secret-Game_WinPrizesSolidBackground.png',
            winPrizesSolidBackgroundMobile: '/getattachment/9dee9942-f4a1-44da-b45d-da3e6b2624d7/2024-Holiday_Secret-Game_WinPrizesSolidBackground_Mobile.png',
            winPrizesText:'/getattachment/c8e931bc-d31d-412d-bbaa-f51c8aaff61c/2024-Holiday_Secret-Game_WinPrizesText.png',
            winPrizesBow:'/getattachment/b2c8025b-4a50-4b6f-9270-a4abdfac7ce3/2024-Holiday_Secret-Game_WinPrizesBow.png',
            winPrizesTickets: '/getattachment/adf239ca-66a0-4d17-ad17-7d78e5d7b316/2024-Holiday_Secret-Game_WinPrizesTickets.png',
            winPrizesTicketsMobile: '/getattachment/abc53f49-ffb1-44bd-a7b5-891d63b4066d/2024-Holiday_Secret-Game_WinPrizesTickets_Mobile.png',

            scratchTickets:'/getattachment/fdbc65af-5ef4-4980-bf1f-ed8020ebe308/2024-Holiday_Secret-Game_Tickets.png',
  
          }
        },
        mounted: async function() {
            this.checkIfMobile();
            window.addEventListener('resize', this.checkIfMobile);
            
            this.isUserLogged = this.isUserAuthed();

            await this.loadContent();
            },
        methods: {
            checkIfMobile() {
                this.isMobile = window.matchMedia("(max-width: 640px)").matches;
            },
            async loadContent() {
                try {
                    await this.getContent();
                    this.updateVideoBasedOnDate();
                } catch (error) {
                    console.error('Error loading content:', error);
                }
            },
            async getContent() {
                try {
                    this.promoContent = await this.getContentByKey('holidaze-text-content', 'holidaze-promo-session');

                    this.headline = this.promoContent?.promotion.headline || '';
                    this.instructions = this.promoContent?.promotion.instructions || '';
                    this.chancesToWin = this.promoContent?.promotion.chances_to_win || '';
                    this.codeWord1 = this.promoContent?.promotion.secret_codes[0].code_word || '';
                    this.codeWord2 = this.promoContent?.promotion.secret_codes[1].code_word || '';
                    this.codeWord3 = this.promoContent?.promotion.secret_codes[2].code_word || '';
                    this.entryPeriod1 = this.promoContent?.promotion.secret_codes[0].entry_period || '';
                    this.entryPeriod2 = this.promoContent?.promotion.secret_codes[1].entry_period || '';
                    this.entryPeriod3 = this.promoContent?.promotion.secret_codes[2].entry_period || '';
                    this.prizes = this.promoContent?.promotion.prizes || '';
                    this.slogan = this.promoContent?.promotion.slogan || '';
                    this.giftInfo = this.promoContent?.promotion.gift_info || '';
                    this.loterryInfo = this.promoContent?.promotion.lottery_info || '';

                } catch (error) {
                    console.error('Error fetching content:', error);
                }
            },
            updateVideoBasedOnDate() {
                const currentDate = new Date();

                this.promoContent.promotion.video.forEach(video => {
                    const startDate = new Date(video.start_date);
                    const endDate = new Date(video.end_date);

                    if (currentDate >= startDate && currentDate <= endDate) {
                        this.vidUrl = video.video_url;
                        this.nextMessage = video.next_message;
                    }
                });
            }
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.checkIfMobile);
        }
      }
  </script>
  
<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css?family=Roboto Slab');
  
    h2{
        color: rgba(213, 32, 41, 1);
        font-family: "Roboto Slab";
        font-size: 3rem;
        font-weight: 400;
        text-align: center;
        margin: 8px 0px;
    }
    h4{
        color: rgba(213, 32, 41, 1);
        font-family: "Arial";
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        margin: 8px 0px;
    }
    p{
        color: rgba(0, 0, 0, 1);
        font-family: "Arial";
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center;
        margin:8px 0px;
    }
    a{
        color: white;
    }

    .holidazeLanding{
        margin: 0px -6%;
    }

    @media (min-width: 641px){
        .holidazeLanding{
            margin:initial;
        }
    }
  
  /*Enter Code Block*/
    .secretGame-enterSecret {
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: clamp(50px, 10vw, 100px);
    }

    .secretGame-enterSecret-Background {
        width: 90%;
        height: auto;
    }
  
    .secretGame-enterSecret-Text {
        width: 100%;
        max-width: 350px;
        height: auto;
    }
  
    .secretGame-enterSecret-Bow {
        width:100%;
        height:auto;
        position: absolute;
        bottom: -15.5%;
        left: -5%;
    }
    .secretGame-enterSecret-TopContent{
        position: absolute;
        max-width: 600px;
        width: 90%;
        margin: 20px;
        padding: 20px;
    }
  
    .secretGame-enterSecretButton{
        border-radius: 39px;
        background-color: rgba(255, 255, 255, 1);
        color: rgba(213, 32, 41, 1);
        font-family: "Roboto Slab";
        font-size: clamp(14px, 2.5vw, 30px);
        font-weight: 700;
        padding: clamp(5px, 1.5vw, 15px) clamp(5px, 1.5vw, 10px);
        width:90%;
        margin: 15px auto;
    }
    .secretGame-enterSecretButton:hover{
        cursor:pointer;
    }
  
    .secretGame-enterSecretText{
        color: rgba(255, 255, 255, 1);
        font-family: "Arial";
        font-size: clamp(14px, 2vw, 18px);
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    @media (min-width:641px){    
        .secretGame-enterSecret-Text {
            max-width: initial;
        }
    
        .secretGame-enterSecret-Bow {
            bottom: -25.5%;
            left: -5%;
        }
   
        .secretGame-enterSecretButton{
            font-size: clamp(10px, 2vw, 30px);
        }
    }
  
  /* Video Block */
    .secretGame-videoBlock {
        text-align: center;
        margin: 40px auto;
        margin-bottom: clamp(50px, 12vw, 115px);
        width: 90%;
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .secretGame-video{
        width:100%;
    }
  
    iframe{
        border: none;
    }
    @media( min-width: 641px){
        .secretGame-videoBlock p{
            width:70%;
        }
    }
  
  /*Win Prizes Block*/
    .secretGame-WinPrizes {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: clamp(15px, 5vw, 5px);
    }

    .secretGame-WinPrizes-Background {
        width: 90%;
        height: auto;
    }

    .secretGame-WinPrizes-Bow {
        width:100%;
        height:auto;
        position: absolute;
        top: -8%;
        left: 5%;
    }
    
    .secretGame-WinPrizes-Text {
        position: absolute;
        max-width: 70%;
        width:100vw;
        top: 5%;
    }

    .secretGame-WinPrizes-Tickets {
        position: absolute;
        max-width: 50%;
        width:100vw;
        top: 25%;
        z-index: 5;
    }

    .secretGame-WinPrizes-TopContent{
        position: absolute;
        width: 85%;
        top: 118vw;
        margin-bottom: 35px;
    }

    @media (max-width:360px) {
        .secretGame-WinPrizes-TopContent{
            top: 109vw;
        }
    }

    .secretGame-WinPrizes-SolidBackground {
        width: 100%;
        position: absolute;
        top: -50vw;
        z-index: 1;
        max-height: 650px;
        height: 116vw;
    }

    .secretGame-WinPrizes-ColumnContent {
        position: relative;
        z-index: 2; 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .secretGame-WinPrizes-LeftColumn {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .secretGame-WinPrizes-Title {
        color: rgba(255, 255, 255, 1);
        font-family: "Roboto Slab";
        font-size: clamp(4px, 5.5vw, 30px); 
        font-weight: 400;
        text-align: center;
        margin: 0px;
    }

    .secretGame-WinPrizes-ColumnContent p{
        margin: clamp(2px, .3vw, 4px); 
    }

    .secretGame-WinPrizes-enter{
        color: rgba(255, 255, 255, 1);
        font-family: "Arial";
        font-size: clamp(3px, 4vw, 16px);
        font-weight: 400;
        text-align: center;
        line-height: initial;
        margin-bottom: clamp(3px, 2vw, 16px) !important;
    }

    .strong{
        font-size: clamp(3px, 6vw, 18px);
    }

    .secretGame-WinPrizes-RightColumn {
        width:90%;
        margin: 10px 0px;
    }

    .secretGame-WinPrizes-disclaimer {
        color: rgba(255, 255, 255, 1);
        font-family: "Arial";
        font-size: clamp(9px, 3vw, 18px);
        font-weight: 700;
        text-align: center;
        margin-bottom: 0px !important;
        line-height: initial;
    }

    .secretGame-WinPrizes-official {
        color: rgba(255, 255, 255, 1);
        font-family: "Arial";
        font-size: clamp(12px, 2.5vw, 16px);
        font-weight: 700;
        text-align: center;
        bottom: 13vw;
        position: relative;
        z-index: 3; 
        margin: clamp(2px, .75vw, 15px);
    }

    @media (min-width:641px){
        .secretGame-WinPrizes-Bow {
            top: -24%;
        }
        
        .secretGame-WinPrizes-Text {
            max-width: 40%;
            left: 13.5%;
            top: 7%;
        }

        .secretGame-WinPrizes-Tickets {
            max-width: 34%;
            right: 8.5%;
            top: 10%;
        }
    
        .secretGame-WinPrizes-TopContent{
            top: clamp(30px, 30vw, 375px);
        }
    
        .secretGame-WinPrizes-SolidBackground {
            top: .5vw;
            height: 92%;
            max-height: initial;
        }
    
        .secretGame-WinPrizes-ColumnContent {
            flex-direction: row;
            align-items: flex-end;
            padding: 1% 2%;
        }
    
        .secretGame-WinPrizes-LeftColumn {
            width: 60%;
        }
    
        .secretGame-WinPrizes-Title {
            font-size: clamp(4px, 2.9vw, 30px); 
        }
    
        .secretGame-WinPrizes-enter{
            font-size: clamp(8px, 1.75vw, 16px);
            margin-bottom: clamp(3px, 1vw, 16px) !important;
        }
    
        .strong{
            font-size: clamp(3px, 2.5vw, 16px);
        }
    
        .secretGame-WinPrizes-RightColumn {
            width: 40%;
            margin: 20px 5px 40px 5px;
        }
    
        .secretGame-WinPrizes-disclaimer {
            font-size: clamp(9px, 2vw, 18px);
        }
    
        .secretGame-WinPrizes-official {
            bottom: clamp(10px, 11vw, 100px);
        }
    }

    @media (min-width:961px){
        .secretGame-WinPrizes-TopContent{
            top: clamp(30px, 22.5vw, 260px);
        }
       
        .secretGame-WinPrizes-official {
            bottom: clamp(10px, 7vw, 100px);
        }
        .secretGame-WinPrizes-Title[data-v-0a7fa5ee] {
            font-size: clamp(4px, 3vw, 28px);
        }
    }  
  
/*Tickets Block*/
    .secretGame-TicketsBlock {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        width: 90%;
        margin: 0px auto 40px auto;
    }
  
    .secretGame-Tickets {
        max-width: 100%;
        height: auto;
        width: 90%;
    }
    
    .secretGame-TicketsBlock-Right {
        width: 90%;
    }
  
    .secretGame-TicketsBlock h2,
    .secretGame-TicketsBlock h4,
    .secretGame-TicketsBlock p{
        text-align: center;
    }
  
  /*Tickets Block*/
    @media (min-width:641px){
        .secretGame-TicketsBlock {
            flex-direction: row;
        }

        .secretGame-Tickets {
            width: 50%;
        }

        .secretGame-TicketsBlock-Right {
            width: 50%;
        }
    
        .secretGame-TicketsBlock h2,
        .secretGame-TicketsBlock h4,
        .secretGame-TicketsBlock p{
            text-align: left;
        }

        .secretGame-TicketsBlock p{
            line-height: initial;
        }

        .secretGame-TicketsBlock h2{
            font-size: 2.5rem;
        }
    }

/*Tickets Block*/
    @media (min-width:641px) and (max-width:800px){
        .secretGame-TicketsBlock {
            width: 95%;
            position: relative;
            left:-1.25%;
        }
    }
  
  </style>
  