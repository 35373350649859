<template>
    <div class="video-player-wrapper cash-explosion-video">
        
        <h3>{{ selectedVideoTitle }}</h3>
        
        <div class="video_player">
            <span class="placeholder-youtube" @click="playVideo" v-if="!videoId"></span>

            <template v-if="videoId">
                <youtube :video-id="videoId"
                        :host="host"
                        player-width="100%" player-height="511"
                >
                </youtube>
            </template>
        </div>

        <div class="video_archive">
            <h3 class="archive_heading">Watch Show Archives</h3>
            <div class="archive_date_wrap">
                <select name="video-date" @change="onChangeSelect" id="selectDate">
                    <option v-for="videoDate in videos" v-bind:key="videoDate.id" :value="videoDate.snippet.resourceId.videoId">
                        {{ customFormatter(videoDate.snippet.title) }}
                    </option>
                </select>
            </div>
        </div>
        
    </div>
</template>


<script>
    import $ from 'jquery';
    import moment from 'moment';

    export default {
        components: {
        },
        data: function () {
            return {
                videos: [],
                videoId: '',
                selectedVideoTitle: '',
                host: 'https://www.youtube.com'
            }
        },
        mounted: function () {
            this.fetchVideoList();

            function onAir() {
                var d = new Date(),
                    n = d.getDay(),
                    now = new Date(),
                    stamp = now.getTime(),
                    start = now.setHours(19, 20, 0),
                    end = start + (45 * 60 * 1000),
                    after = end + (45 * 30 * 1000);
                if (n == 6) {
                    if (stamp >= start && stamp <= end) {
                        //we are in the range of showtime
                        $('.ce-live-stream-btn').show();
                        $('.video_cooldown').hide();
                        $('.video_play.off').hide();
                        $('.video_play.on').show();
                    } else if (stamp >= end && stamp <= after) {
                        //we are inside the cooldown period
                        $('.ce-live-stream-btn').hide();
                        $('.video_play.off').hide();
                        $('.video_play.on').hide();
                        $('.video_cooldown').show();
                    } else {
                        //outside of showtime and cooldown
                        $('.ce-live-stream-btn').hide();
                        $('.video_cooldown').hide();
                        $('.video_play.on').hide();
                        $('.video_play.off').show();
                    }
                } else {
                    $('.ce-live-stream-btn').hide();
                    $('.video_play.off').show();
                    $('.video_play.on').hide();
                }
            }
            onAir();
            setInterval(function() {
                onAir()
            }, 10000);
        },
        methods: {  
            customFormatter(textString) {
                // return moment(textString).format('LL');
                if (textString.startsWith('Cash Explosion ')) {
                    textString = textString.substr(14);
                    return textString;
                } else {
                    return textString;
                }
            },
            fetchVideoList() {
                return fetch("https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyBPQS5zqrWLNMIm9XhtIzQJY-EQQr5Wu_I&part=snippet&playlistId=PL91kIN6sk-tBtNLPJxg54p4CVUqnyEaRV&maxResults=5")
                .then(response => response.json())
                .then(videoList => {
                    // console.log(videoList);
                    this.videos = videoList.items;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            onChangeSelect(event) {
                this.videoId = event.target.value;
                let getThisEle;

                Array.from( event.target ).forEach( function( ele ){
                    if (ele.value == event.target.value) {
                        getThisEle =  ele.innerText;
                    }
                });

                this.selectedVideoTitle =  getThisEle

            },
            playVideo(){
                
                this.videoId = this.videos[0].snippet.resourceId.videoId
                this.selectedVideoTitle = this.videos[0].snippet.title

                
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>