import Vue from "vue";

import Pick3WN from "./Pick3-WinningNumbers.vue";

export function load(payload) {
	const selector = "pick3-numbers";
	const store = payload;

	// Is the custom Vue root element in the DOM?
	if (!document.querySelector(selector)) {
	return; // go no further
	}

  // Create a new Vue app with the imported component
	new Vue({
		store,
		render: createElement =>
			createElement(Pick3WN, {
			props: {
				...window[selector]
			}
		}),
	}).$mount(selector);
}