<template>
  <div class="module">
    <div class="cf moduleContent">
      <h3>Announcement Schedule</h3>

      <table width="100%">
        <tbody>
          <tr class="bottomLine">
            <th>Entry Received On or Before</th>
            <th>Announcement Date</th>
            <th>Show Date</th>
          </tr>

          <tr v-for="show in allShows" :key="show.id">
            <td>{{ customFormatter(show.entryReceivedByDate) }}</td>
            <td>{{ customFormatter(show.announcementDate) }}</td>
            <td>{{ customFormatter(show.showDateAndTime) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data: function () {
    return {
      allShows: [],
      count: 9,
    };
  },
  mounted: function () {
    this.getShows();
  },
  methods: {
    customFormatter(date) {
      if (date === '0001-01-01T00:00:00') {
        return 'n/a';
      } else {
        return moment(date).format("MM/DD/YYYY");
      }
    },
    getToken() {
      // then hit API with token
      this.getAPItoken().then((token) => {
        this.fetchData(token);
      });
    },
    fetchData(token) {
      var apiToken = token;

      return fetch(
        process.env.VUE_APP_APIPROTOCOL +
          process.env.VUE_APP_APIURL +
          "/1.0/CashExplosion/CashExplosion/GetAllShowItems?count=" +
          this.count,
        {
          headers: {
            Authorization: "Bearer " + apiToken,
          },
        }
      )
      .then((response) => response.json())
      .then((json) => {
        var res = json.data;  
        sessionStorage.setItem('olcShowItems', JSON.stringify(res));        
        this.allShows = res.sort((a, b) => new Date(a.showDateAndTime) - new Date(b.showDateAndTime));
      })
      .catch((error) => {
        // console.error("There was an error!", error);
        return (this.errorMessage = error);
      });

    },
    getShows() {
      let showData = JSON.parse(sessionStorage.getItem('olcShowItems'));

      if (showData !== undefined && showData !== "" && showData !== null) {
        return this.allShows = showData.sort((a, b) => new Date(a.showDateAndTime) - new Date(b.showDateAndTime));
        
      } else {
        this.getToken();
      }
      
      // var apiToken = token;

      // return fetch(
      //   process.env.VUE_APP_APIPROTOCOL +
      //     process.env.VUE_APP_APIURL +
      //     "/1.0/CashExplosion/CashExplosion/GetAllShowItems?count=" +
      //     this.count,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + apiToken,
      //     },
      //   }
      // )
      //   .then((response) => response.json())
      //   .then((json) => {
      //     var res = json.data;
      //     this.allShows = res.sort((a, b) => new Date(a.showDateAndTime) - new Date(b.showDateAndTime));
      //   })
      //   .catch((error) => {
      //     // console.error("There was an error!", error);
      //     return (this.errorMessage = error);
      //   });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
