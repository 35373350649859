<template>



<!-- this has been replaced by the ScratchOffs-SingleGame.vue component -->















    <div class="contentRow">
        <div class="page_content cf">
                <h1>
                    {{ name }}
                </h1>
                <div class="gameNumber">Game Number: <span class="number">{{ number }}</span></div>
                <div class="gameIntro">{{ heading }}</div>

                <div class="about clear module_callOut">
                    <h3 class="mobileToggleTrigger">{{ description }}</h3>
                    <div class="mobileToggleContent">
                        <div class="about_content">{{ about }}</div>
                        <p class="odds">Overall odds of winning: {{ odds }}</p>
                        <div class="playbook_spread"></div>
                    </div>
                </div>

                <div class="tbl_PrizesRemaining">
                    {{ breakdown }}
                </div>

                <img class="igTicketImg" :src="graphic"/>

                {{ breakdown }}
                <!-- <table>
                    <tbody>
                        <tr class="table_title">
                            <th colspan="3">
                                (<span id="p_lt_zoneContent_pageplaceholder_p_lt_zoneContent_PrizesRemainingIG_GameCode">552</span>) 
                                <span id="p_lt_zoneContent_pageplaceholder_p_lt_zoneContent_PrizesRemainingIG_GameName">Cash Celebration</span> 
                                <span id="p_lt_zoneContent_pageplaceholder_p_lt_zoneContent_PrizesRemainingIG_Cost">$30</span>
                            </th>
                        </tr>
                        <tr class="col_titles">
                            <th>Prizes</th>
                            <th>Remaining</th>
                            <th style="display:none;">TPD Status</th>
                        </tr>
                        <tr>
                            <td class="tpdPrizeCell">$40K/YR FOR 25 YRS</td>
                            <td class="tpdRemainCell">28</td>
                            <td class="tpdStatusCell" style="display:none;"><a href="/Games/InstantGames/Top-Prize-Drawing-TPD/TPD-Games">&nbsp;</a></td>
                        </tr>
                    
                        <tr class="odd">
                            <td class="tpdPrizeCell">$ 50,000.00</td>
                            <td class="tpdRemainCell">44</td>
                            <td class="tpdStatusCell" style="display:none;"><a href="/Games/InstantGames/Top-Prize-Drawing-TPD/TPD-Games">&nbsp;</a></td>
                        </tr>
                    
                        <tr>
                            <td class="tpdPrizeCell">$ 10,000.00</td>
                            <td class="tpdRemainCell">476</td>
                            <td class="tpdStatusCell" style="display:none;"><a href="/Games/InstantGames/Top-Prize-Drawing-TPD/TPD-Games">&nbsp;</a></td>
                        </tr>
                    
                        <tr class="odd">
                            <td class="tpdPrizeCell">$ 1,000.00</td>
                            <td class="tpdRemainCell">8,700</td>
                            <td class="tpdStatusCell" style="display:none;"><a href="/Games/InstantGames/Top-Prize-Drawing-TPD/TPD-Games">&nbsp;</a></td>
                        </tr>
                    
                        <tr>
                            <td class="tpdPrizeCell">$ 500.00</td>
                            <td class="tpdRemainCell">29,728</td>
                            <td class="tpdStatusCell" style="display:none;"><a href="/Games/InstantGames/Top-Prize-Drawing-TPD/TPD-Games">&nbsp;</a></td>
                        </tr>
                    
                        <tr class="odd">
                            <td class="tpdPrizeCell">$ 100.00</td>
                            <td class="tpdRemainCell">386,857</td>
                            <td class="tpdStatusCell" style="display:none;"><a href="/Games/InstantGames/Top-Prize-Drawing-TPD/TPD-Games">&nbsp;</a></td>
                        </tr>
                    
                        <tr>
                            <td class="tpdPrizeCell">$ 50.00</td>
                            <td class="tpdRemainCell">758,908</td>
                            <td class="tpdStatusCell" style="display:none;"><a href="/Games/InstantGames/Top-Prize-Drawing-TPD/TPD-Games">&nbsp;</a></td>
                        </tr>
                    
                        <tr class="odd">
                            <td class="tpdPrizeCell">$ 40.00</td>
                            <td class="tpdRemainCell">826,395</td>
                            <td class="tpdStatusCell" style="display:none;"><a href="/Games/InstantGames/Top-Prize-Drawing-TPD/TPD-Games">&nbsp;</a></td>
                        </tr> 
                    </tbody>
                </table> -->
            </div>
        </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

    export default {
        data: function () {
            return {
                name: document.getElementById('name').value,
                price: document.getElementById('price').value,
                number: document.getElementById('number').value,
                heading: document.getElementById('heading').value,
                description: document.getElementById('description').value,
                about: document.getElementById('about').value,
                logo: document.getElementById('logo').value,
                mLogo: document.getElementById('mLogo').value,
                graphic: document.getElementById('graphic').value,
                graphicThumb: document.getElementById('graphicThumb').value,
                onSale: document.getElementById('onSale').value,
                closing: document.getElementById('closing').value,
                redeem: document.getElementById('redeem').value,
                odds: document.getElementById('odds').value,
                tpdStatus: document.getElementById('tpdStatus').value,
                tpdDate: document.getElementById('tpdDate').value,
                jackpot: document.getElementById('jackpot').value,
                payout: document.getElementById('payout').value,
                breakdown: document.getElementById('breakdown').value,
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                var apiToken = token;
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL + "/1.0/Games/ScratchOffs/ScratchOffGame/GetAllGames", {
                    headers: {
                        'Authorization': 'Bearer ' + apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    this.pricegroup = json.data;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            }
        },
        ...mapActions ({
            checkScratchoffs: 'getNumbers/getOLCScratchGames'
        }),
        ...mapGetters ({
            getScratchoffs: 'getNumbers/getOLCScratchNumbers'
        })
    };

</script>
<style lang="scss" scoped>
</style>