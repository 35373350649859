import Vue from "vue";

import Home from "./Home.vue";
// import store from '../../src/store';

export function load(payload) {
  const selector = "site-home";
  let store = payload;
  // Is the custom Vue root element in the DOM?

  if (!document.querySelector(selector)) {
    return;
  }

  // Create a new Vue app with the imported Home component

  new Vue({
    store,
    beforeCreate() {
      this.$store.commit('initialiseStore');
    },
    render: createElement =>
      createElement(Home, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}
