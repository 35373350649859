<template>
    <div class="eAppModule">
        <h3>Lottery Equipment</h3>
        {{ readOnly }}
        <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="3"
            color="#0FD8C9"
            v-if="isLoading"
        />
        
        <div v-if="!isLoading" class="moduleContent cf equipInfo cf">
            <h4 v-if="SmallMonitorWall !== 0 || SmallMonitorCeiling !== 0 || MediumMonitorWall !== 0 || MediumMonitorCeiling !== 0 || LargeMonitorWall !== 0 || LargeMonitorCeiling !== 0" :disabled="readOnly">Monitors</h4>
            <dl class="reviewFields cf" v-if="SmallMonitorWall !== 0 || SmallMonitorCeiling !== 0 || MediumMonitorWall !== 0 || MediumMonitorCeiling !== 0 || LargeMonitorWall !== 0 || LargeMonitorCeiling !== 0" :disabled="readOnly">
                <dt v-if="SmallMonitorWall !== 0">Small (up to 32\") Wall Monitor:</dt><dd v-if="SmallMonitorWall !== 0" :disabled="readOnly">{{ SmallMonitorWall }}</dd>
                <dt v-if="SmallMonitorCeiling !== 0">Small (up to 32\") Ceiling Monitor:</dt><dd v-if="SmallMonitorCeiling !== 0" :disabled="readOnly">{{ SmallMonitorCeiling }}</dd>
                <dt v-if="MediumMonitorWall !== 0">Medium (33\" to 42\") Wall Monitor:</dt><dd v-if="MediumMonitorWall !== 0" :disabled="readOnly">{{ MediumMonitorWall }}</dd>
                <dt v-if="MediumMonitorCeiling !== 0">Medium (33\" to 42\") Ceiling Monitor:</dt><dd v-if="MediumMonitorCeiling !== 0" :disabled="readOnly">{{ MediumMonitorCeiling }}</dd>
                <dt v-if="LargeMonitorWall !== 0">Large (43\" to 50\") Wall Monitor:</dt><dd v-if="LargeMonitorWall !== 0" :disabled="readOnly">{{ LargeMonitorWall }}</dd>
                <dt v-if="LargeMonitorCeiling !== 0">Large (43\" to 50\") Ceiling Monitor:</dt><dd v-if="LargeMonitorCeiling !== 0" :disabled="readOnly">{{ LargeMonitorCeiling }}</dd>
            </dl>

            <h4>Requested Equipment</h4>
            <dl class="reviewFields cf">
                <dt v-if="EquipCDU !== 0">CDU:</dt><dd v-if="EquipCDU !== 0">{{ EquipCDU }}</dd>
                <dt v-if="EquipKenoBacklight !== 0">Keno Backlight:</dt><dd v-if="EquipKenoBacklight !== 0" :disabled="readOnly">{{ EquipKenoBacklight }}</dd>
                <dt v-if="EquipMP !== 0">MP:</dt><dd v-if="EquipMP !== 0" :disabled="readOnly">{{ EquipMP }}</dd>
                <dt v-if="EquipMPNG !== 0">MPNG:</dt><dd v-if="EquipMPNG !== 0" :disabled="readOnly">{{ EquipMPNG }}</dd>
                <dt v-if="EquipPAD !== 0">PAD:</dt><dd v-if="EquipPAD !== 0" :disabled="readOnly">{{ EquipPAD }}</dd>
                <dt v-if="EquipPhoton !== 0">Photon:</dt><dd v-if="EquipPhoton !== 0" :disabled="readOnly">{{ EquipPhoton }}</dd>
                <dt v-if="EquipPlayStation !== 0">PlayStation:</dt><dd v-if="EquipPlayStation !== 0" :disabled="readOnly">{{ EquipPlayStation }}</dd>
                <dt v-if="EquipPole !== 0">Pole Mount:</dt><dd v-if="EquipPole !== 0" :disabled="readOnly">{{ EquipPole }}</dd>
                <dt v-if="EquipSATSign !== 0">SAT Sign:</dt><dd v-if="EquipSATSign !== 0" :disabled="readOnly">{{ EquipSATSign }}</dd>
                <dt v-if="EquipStarlight !== 0">Starlight:</dt><dd v-if="EquipStarlight !== 0" :disabled="readOnly">{{ EquipStarlight }}</dd>
                <dt v-if="EquipTouchWin !== 0">Touch &amp; Win :</dt><dd v-if="EquipTouchWin !== 0" :disabled="readOnly">{{ EquipTouchWin }}</dd>
                <dt v-if="EquipWinStation !== 0">WinStation:</dt><dd v-if="EquipWinStation !== 0" :disabled="readOnly">{{ EquipWinStation }}</dd>
                <dt v-if="EquipWiredReader !== 0">Wired Reader:</dt><dd v-if="EquipWiredReader !== 0" :disabled="readOnly">{{ EquipWiredReader }}</dd>
                <dt v-if="EquipWirelessReader !== 0">Wireless Reader:</dt><dd v-if="EquipWirelessReader !== 0" :disabled="readOnly">{{ EquipWirelessReader }}</dd>
                <dt v-if="EquipWirelessTicketChecker !== 0">Wireless Ticket Checker:</dt><dd v-if="EquipWirelessTicketChecker !== 0" :disabled="readOnly">{{ EquipWirelessTicketChecker }}</dd>
                <dt v-if="EquipDreamTouchSmart !== 0">DreamTouchSmart:</dt><dd v-if="EquipDreamTouchSmart !== 0" :disabled="readOnly">{{ EquipDreamTouchSmart }}</dd>
                <dt v-if="EquipSelect4Unit !== 0">Select 4 Unit:</dt><dd v-if="EquipSelect4Unit !== 0" :disabled="readOnly">{{ EquipSelect4Unit }}</dd>
                <dt v-if="EquipSciTrackUnit !== 0">SciTrack Unit:</dt><dd v-if="EquipSciTrackUnit !== 0" :disabled="readOnly">{{ EquipSciTrackUnit }}</dd>
            </dl>
        </div>


    </div>
</template>


<script>
    import { HollowDotsSpinner } from 'epic-spinners';

    export default {
        components: {
            HollowDotsSpinner
        },
        props: {
            applicationJson: String,
            readOnly: Boolean
        },
        data: function () {
            return {
                application: {},

            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init(){
                this.deserializeJson();
            },
            deserializeJson() {
                this.application = JSON.parse(this.applicationJson);
            },
        }
    };
</script>

<style lang="scss" scoped>
</style>
