import Vue from "vue";

import KENODrawings from "./KENO-Drawings.vue";

export function load() {
    const selector = "keno-drawings";

    // Is the custom Vue root element in the DOM?

    if (!document.querySelector(selector)) {
        return;
    }

    // Create a new Vue app with the imported component

    new Vue({
        render: createElement =>
            createElement(KENODrawings, {
                props: {
                    ...window[selector],
                },
            }),
    }).$mount(selector);
}
