import Vue from "vue";

import WinningNumbersMenu from "./WinningNumbersMenu.vue";

export function load(payload) {
    const selector = "winning-numbers-menu";
    const store = payload;
    // Is the custom Vue root element in the DOM?
    if (!document.querySelector(selector)) {
        return;
    }

    // Create a new Vue app with the imported Home component
    new Vue({
        store,
        render: createElement =>
            createElement(WinningNumbersMenu, {
                props: {
                    ...window[selector],
                },
            }),
    }).$mount(selector);
}