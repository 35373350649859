<template>
    <div class="game_home_wrapper">
        <ul id="keno_numbers" class="cf winningNumbers">	
			<li class="luckyOneNumbers">
				<a href="/games/the-lucky-one" class="gameLogo gameLogo--KENO ir">KENO</a>
				<div class="winningNumbersContent">
					<span class="jackpot">
                        <span class="wnLbl">Current Drawing:</span> <span class="drawNumber wnVal">{{currentDrawing}}</span>
                    </span>
					
					<span class="number">
                        {{ number }}
                    </span>

                    <span class="highLow">{{ highLow }}</span>
                    <span class="oddEven">{{ oddEven }}</span>
					<span class="updateFreq">Draws occur every few minutes</span>
				</div>
				<!-- <a class="btn_purple btn_pastDraws" href="/WinningNumbers/KenoDrawings/KenoDrawingsArchive">Past Draws</!-->
			</li>
		</ul>
    </div>
</template>


<script>

    

    export default {
        components: {
            
        },
        data: function () {
            return {
                currentDrawing: '',
                number: [],
                highLow: '',
                oddEven: ''

            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                let apiToken = token;
                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/TheLuckyOne/GetLatestDraws", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let results = json.data;
                    // console.log(results)
                    this.currentDrawing = results[0].externalDrawId;
                    this.number = results[0].numbers[0].value;

                    if(this.number >= 19){
                        this.highLow = 'HIGH'
                    } else{
                        this.highLow = 'LOW'
                    }

                    if(this.number % 2 == 0){
                        this.oddEven = 'EVEN'
                    }
                    else{
                        this.oddEven = 'ODD'
                    }
                                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            }
        }
    };
</script>

<style lang="css" scoped>


</style>