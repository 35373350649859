<template>
    <div>
        <HollowDotsSpinner v-if="isLoading" color="#0FD8C9" class="home-banner-loader" />

        <div class="orbit" id="obritMain" role="region" data-orbit v-if="!isLoading">
            <ul class="orbit-container">
                <li class="orbit-slide" v-for="(slide, index) in bannerSlides" :key="slide.orderOfAppearance" :class="{ first: isFirst(index) }">
                    
                    <figure v-if="slide.bannerType == 'standard'" class="orbit-figure" :class='[slide.bannerType, slide.bannerLayout, slide.bannerClass]'>
                        <a :href="slide.bannerLinkURL" :target="slide.bannerLinkType" class="bannerLink" v-if="slide.bannerLinkURL">
                            <div class="bg-img bg-img-desktop" :style="{ 'background-image': 'url(' + slide.bannerImageBackgroundUrl + ')' }">
                                <div class="bg-img bg-img-mobile" :style="{ 'background-image': 'url(' + slide.bannerImageBackgroundMobileUrl + ')' }">
                                    <div class="banner-container">
                                        <img class="bannerImg show-for-medium" :src="slide.bannerImageForegroundUrl"
                                            alt="banner image" v-if="slide.bannerImageForegroundUrl">
                                        <img class="bannerImg hide-for-medium" :src="slide.bannerImageForegroundMobileUrl"
                                            alt="banner image" v-if="slide.bannerImageForegroundMobileUrl">

                                        <p class="bannerText" v-if="slide.bannerText">
                                            {{ slide.bannerText }}
                                        </p>
                                        <span class="bannerCustomCode" v-if="slide.bannerCustomCode" v-html="slide.bannerCustomCode"></span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>
                    
                    <figure v-if="slide.bannerType == 'jackpot' && slide.bannerPrizePool >= slide.bannerThreshold"
                        class="orbit-figure" :class='[slide.bannerType, slide.bannerClass]'>
                        <a :href="slide.bannerLinkURL" :target="slide.bannerLinkType" class="bannerLink"
                            v-if="slide.bannerLinkURL">
                            <div class="bg-img bg-img-desktop"
                                v-bind:style="{ 'background-image': 'url(' + slide.bannerImageBackgroundUrl + ')' }">
                                <div class="bg-img bg-img-mobile"
                                    v-bind:style="{ 'background-image': 'url(' + slide.bannerImageBackgroundMobileUrl + ')' }">
                                    <div class="banner-container">
                                        <img class="jackpot-image show-for-medium" :src="slide.bannerImageForegroundUrl"
                                            alt="banner image" v-if="slide.bannerImageForegroundUrl">
                                        <img class="jackpot-image hide-for-medium" :src="slide.bannerImageForegroundMobileUrl"
                                            alt="banner image" v-if="slide.bannerImageForegroundMobileUrl">

                                        <div class="jackpot-text">
                                            <!-- Dynamic Jackpot Banner -->
                                            <span v-if="slide.bannerPrizePool == 'MegaMillions'">PrizePool Present</span>
                                            <span>{{ slide.bannerPrizePool }}</span>
                                            <span>{{ slide.bannerJackpotType }}</span>
                                            <span class="bannerCustomCode" v-html="slide.bannerCustomCode"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>

                    <figure v-if="slide.bannerType == 'multiple-jackpot'" class="orbit-figure" :class='["banner-container-multi", slide.bannerType]'>
                        <div v-for="(multiSlide, index) in slide.bannerSlides" :key="index" :class="{'single':single, 'double':double}">
                            <a :href="multiSlide.bannerLinkURL" :target="multiSlide.bannerLinkType" class="bannerLink">
                                <div v-if="multiSlide.mega" class="bg-img bg-img-desktop megaBanner" v-bind:style="{ 'background-image': 'url(' + bannerABackground + ')' }">
                                    <div class="bg-img bg-img-mobile megaBanner" v-bind:style="{ 'background-image': 'url(' + bannerABackground + ')' }">
                                        <div class="banner-container">
                                            <!-- <img class="jackpot-image show-for-medium" :src="multiSlide.bannerImageForegroundUrl" alt="banner image" v-if="multiSlide.bannerImageForegroundUrl"> -->
                                            <img :class="'jackpot-image megaBanner'" :src="bannerAForeground" alt="banner image">

                                            <div class="jackpot-text">
                                                <!-- Dynamic Jackpot Banner -->
                                                <span v-if="multiSlide.bannerPrizePool == 'MegaMillions'">PrizePool Present</span>
                                                <span class="prizePool"><span class="cash">$</span>{{ multiSlide.bannerPrizePool }}</span>
                                                <span class="jackpotType">{{ multiSlide.bannerJackpotType }}</span>
                                                <span class="bannerCustomCode" v-if="multiSlide.bannerCustomCode" v-html="multiSlide.bannerCustomCode"></span>
                                            </div>

                                            <div class="jackpot-schedule">
                                                <span class="schedule">Drawings: </span>{{ multiSlide.schedule }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!multiSlide.mega" class="bg-img bg-img-desktop powerBanner" v-bind:style="{ 'background-image': 'url(' + bannerBBackground + ')' }">
                                    <div class="bg-img bg-img-mobile powerBanner" v-bind:style="{ 'background-image': 'url(' + bannerBBackground + ')' }">
                                        <div class="banner-container">
                                            <!-- <img class="jackpot-image show-for-medium" :src="multiSlide.bannerImageForegroundUrl" alt="banner image" v-if="multiSlide.bannerImageForegroundUrl"> -->
                                            <img class="jackpot-image powerBanner" :src="bannerBForeground" alt="banner image">

                                            <div class="jackpot-text">
                                                <!-- Dynamic Jackpot Banner -->
                                                <span v-if="multiSlide.bannerPrizePool == 'MegaMillions'">PrizePool Present</span>
                                                <span class="prizePool"><span class="cash">$</span>{{ multiSlide.bannerPrizePool }}</span>
                                                <span class="jackpotType">{{ multiSlide.bannerJackpotType }}</span>
                                                <span class="bannerCustomCode" v-if="multiSlide.bannerCustomCode" v-html="multiSlide.bannerCustomCode"></span>
                                            </div>

                                            <div class="jackpot-schedule">
                                                <span class="schedule">Drawings: </span>{{ multiSlide.schedule }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <span class="mmpbPRtext"><span>Play responsibly. </span><span>Need Help? 1.800.589.9966</span></span>
                    </figure>

                    <figure v-if="slide.bannerType == 'video'" class="orbit-figure video-slide"
                        :class='[slide.bannerType, slide.bannerClass]'>

                        <div class="bg-img bg-img-desktop"
                            v-bind:style="{ 'background-image': 'url(' + slide.bannerImageBackgroundUrl + ')' }">
                            <div class="bg-img bg-img-mobile"
                                v-bind:style="{ 'background-image': 'url(' + slide.bannerImageBackgroundMobileUrl + ')' }">
                                <LazyYoutube ref="youtubeLazyVideo"
                                    :src="'https://www.youtube.com/watch?v=' + slide.bannerVideoID" />
                            </div>
                        </div>
                    </figure>

                    <figure v-if="slide.bannerType == 'custom'" class="orbit-figure" :class='slide.bannerType'>
                        <div class="bg-img bg-img-desktop"
                            v-bind:style="{ 'background-image': 'url(' + slide.bannerImageBackgroundUrl + ')' }">
                            <div class="bg-img bg-img-mobile"
                                v-bind:style="{ 'background-image': 'url(' + slide.bannerImageBackgroundMobileUrl + ')' }">
                                <span v-html="slide.bannerCustomCode" class="bannerCustom"></span>
                            </div>
                        </div>
                    </figure>
                </li>

            </ul>
            <nav class="orbit-bullets" v-if="showBullets">
                <button v-for="(buttons, i) in bannerSlides" class="orbit-buttons" :key="buttons.bannerSlideID" :data-slide="i" :class="{ 'is-active': i === 0 }">
                    <span class="show-for-sr">{{ i }}</span>
                </button>
            </nav>
        </div>
    </div>
</template>


<script>

import $ from 'jquery';
import Foundation from "@/foundation-sites/js/foundation.js";
import { LazyYoutube } from "vue-lazytube";
import { HollowDotsSpinner } from 'epic-spinners'

export default {
    components: {
        LazyYoutube,
        HollowDotsSpinner
    },
    data() {
        return {
            megaThreshold: 200,
            isLoading: true,
            bannerSlides: [],
            slides: [],
            jackpotSlides: [],
            single: true,
            double: false,
            thresholds: "0,0",
            errorMessage: 'Banners currently not available. Please try again later.',
            bannerGroup: document.querySelector("#bannerGroup").value,
            showBullets: document.querySelector("#showBullets").value,
            bannerAForeground: '',
            bannerABackground: '',
            bannerBForeground: '',
            bannerBBackground: ''
        };
    },

    mounted() {
        // console.log(showBullets);
        this.getToken();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(token) {
            let apiToken = token,
                bannerId = this.bannerGroup;
            // console.log(bannerId);
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/BannerManager/BannerManager/GetBannerGroupByKey?bannerIdentifier=" + bannerId, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    let res = json.data,
                        self = this;

                    // console.log(res.bannerSlides);

                    this.getJackpots(token, res.bannerSlides).then(res => {
                        $(document).foundation();
                        new Foundation.Orbit($('.orbit-banner'));

                        $(document).on('slidechange.zf.orbit', function (e, orbit_info) {
                            if (!orbit_info[0].children[0]._prevClass.indexOf('video-slide') !== -1) {
                                if (!Object.getOwnPropertyNames(self.$refs).length === 0) {
                                    self.$refs['youtubeLazyVideo'][0].stopVideo();
                                }
                            }
                        });

                        this.isLoading = true;
                    });
                })
                .catch(error => {
                    return this.errorMessage = error;
                });
        },
        getJackpots(token, banners) {
            let rawSlides = banners;

            for (let i = 0; i < rawSlides.length; i++) {
                let banner = rawSlides[i];

                if (banner.bannerType === 'jackpot') {
                    let apiToken = token;

                    let games = banner.bannerJackpotID;

                    games = games.split("|");
                    
                    this.thresholds = games[1];
                    let threshold = this.thresholds.split(",");
                    
                    games = games[0].split(",");

                    if (games.length < 2) {
                        return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + banner.bannerJackpotID + "/GetLatestDraws", {
                            headers: {
                                'Authorization': 'Bearer ' + apiToken,
                            },
                        })
                        .then(response => response.json())
                        .then(json => {
                            let res = json.data;

                            banner.bannerThreshold = this.thresholds[0];

                            banner.bannerPrizePool = res[0].nextPrizePool;

                            banner.bannerPrizePool <= 999999999 ? banner.bannerJackpotType = 'Million' : banner.bannerJackpotType = 'Billion';

                            if (banner.bannerPrizePool > 999999999) {
                                banner.bannerPrizePool = (banner.bannerPrizePool / 1000000000).toFixed(2);
                            } else {
                                banner.bannerPrizePool = (banner.bannerPrizePool / 1000000);
                            }
                            
                            this.bannerSlides.unshift(banner);
                        })
                        .catch(error => {
                            return this.errorMessage = error;
                        });

                    } else if (games.length > 1) {

                        // I think we need to check the nextPrizePool against the threshold values for both games, before we decide if we're showing a combined banner or not. If both are over threshold then bannerType: 'multiple-jackpot'
                        // otherwise treat it like bannerType: 'jackpot'

                        // can we also get the game info from store?

                        let gameBanners = {
                            bannerType: 'multiple-jackpot',
                            bannerSlides: []
                        };

                        for (let i = 0; i < games.length; i++) {
                            let game = { ...banner };
                            game.bannerJackpotID = games[i];
                            game.bannerThreshold = threshold[i];

                            // fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + game.bannerJackpotID + "/GetLatestDraws", {
                            fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + game.bannerJackpotID + "/GetGameInformation", {
                                headers: {
                                    'Authorization': 'Bearer ' + apiToken,
                                },
                            })
                            .then(response => response.json())
                            .then(json => {
                                let data = json.data,
                                    res = data.draws;

                                game.bannerPrizePool = res[0].nextPrizePool;
                                game.schedule = data.drawSchedule;

                                // TESTING ONLY --
                                // game.bannerPrizePool < 1000000 ? game.bannerPrizePool = 1100000 : false;
                                if (game.bannerPrizePool >= game.bannerThreshold) {
                                    if (game.bannerPrizePool >= 999999999) {
                                        game.bannerPrizePool = (game.bannerPrizePool / 1000000000).toFixed(2);
                                        game.bannerJackpotType = 'Billion';
                                    } else if (game.bannerPrizePool) {
                                        game.bannerPrizePool = (game.bannerPrizePool / 1000000);
                                        game.bannerJackpotType = 'Million';
                                    }

                                    if (game.bannerJackpotID === 'Powerball') {
                                        game.mega = false;
                                    } else {
                                        game.mega = true;
                                    }

                                    // console.log('banner')
                                    // console.log(game)

                                    gameBanners.bannerSlides.unshift(game);
                                    // console.log(gameBanners)
                                }

                                if (i === games.length -1) {
                                    // console.log(gameBanners.bannerSlides.length)
                                    if (gameBanners.bannerSlides.length === 1) {
                                        this.single = true;
                                        this.double = false;
                                    } else if (gameBanners.bannerSlides.length === 2) {
                                        this.single = false;
                                        this.double = true;
                                    }

                                    if (gameBanners.bannerSlides.length > 0) {
                                        this.bannerSlides.unshift(gameBanners);
                                    
                                        setTimeout(() => {
                                            this.bannerAForeground = document.getElementById('banner-a-foreground').value;
                                            this.bannerABackground = document.getElementById('banner-a-background').value;
                                            this.bannerBForeground = document.getElementById('banner-b-foreground').value;
                                            this.bannerBBackground = document.getElementById('banner-b-background').value;
                                        }, 500);
                                    } else {
                                        // console.log(' === No Appropriate Banners ===')
                                    }
                                }
                            })
                            .catch(error => {
                                return this.errorMessage = error;
                            });
                        }
                    }
                } else {
                    this.bannerSlides.push(banner);

                }
            }
            this.isLoading = false;

            //Order the slides based on the orderOfAppearance value
            /* this.bannerSlides.sort( (a, b) => {
                if (a.orderOfAppearance > b.orderOfAppearance) return 1;
                if (a.orderOfAppearance < b.orderOfAppearance) return -1;
                return 0;
            }) */

            return this.bannerSlides;
        },
        isFirst(index) {
            if (index === 0) {
                return true;
            }
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .mmpbPRtext {
        position: absolute;
        bottom: 0;
        left: 10%;
        font-size: 1.2rem;
        color: #fff;

        span:first-child {
            font-weight: 500;
        }
    }
</style>