<template>
    <div class="list_scratchoff--byPrice">
        <section class="scratchoff__priceGroup" v-for="group in games" :key="group.ScratchNumber">
            <h3>
                <a :href="'/Games/Scratch-Offs/$' + group.ScratchNumber + '-Games'">${{ group.ScratchNumber }} Games</a>
            </h3>

            <div class="list__games">
                <ul class="list__games priceGroup__list">
                    <li class="igLandListItem" v-for="game in group.value" :key="game.gameID">
                        <!-- need to check date and show "NEW" and CSS for games within first 30 days -->
                        <span class="igNew" v-if="newGame(game.onSaleDate)">NEW! </span>
                        <a :href="game.nodeAliasPath">{{ game.gameName }} <span class="igLandListItem-gamenumber">#{{
                            game.gameNumber }}</span></a>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>
<script>
// need to build URLs from context - create array of unique paths:
// loop the games and get the nodeAliasPath, 
// remove the page (anything after last /),
// push to array if it's not already in the arrayw

// import { mapActions, mapGetters } from 'vuex';

export default {
    data: function () {
        return {
            games: []
        }
    },
    mounted: function () {
        this.getToken();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(token) {
            var apiToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/ScratchOffs/ScratchOffGame/GetAllGames", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(res => {
                    let result = res.data;
                    // this.games = res.data;

                    this.transformData(result);
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        transformData(apiData) {

            const objToArr = Object.entries(apiData).map(([key, value]) => ({
                "ScratchNumber": Number(key),
                value
            }));

            const sortedData = objToArr.slice().sort((a, b) => b.ScratchNumber - a.ScratchNumber);

            const groupedData = [];

            sortedData.forEach(item => {
                const group = groupedData.find(group => group.ScratchNumber === item.ScratchNumber);
                if (group) {
                    group.value.push(...item.value);
                } else {
                    groupedData.push({ ScratchNumber: item.ScratchNumber, value: item.value });
                }
            });

            function dynamicSort(property) {
                var sortOrder = 1;

                if(property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }

                return function (a,b) {
                    if(sortOrder == -1){
                        return b[property].localeCompare(a[property]);
                    }else{
                        return a[property].localeCompare(b[property]);
                    }        
                }
            }


            // console.log(groupedData);

            groupedData.forEach( item =>{
                item.value.sort(dynamicSort('gameName'))
            });

            this.games = groupedData;

            //console.log(groupedData)
        },
        newGame(onSaleDate) {
            const dateIni = new Date(onSaleDate);
            const dateNow = new Date();
            const diffMs = dateNow.getTime() - dateIni.getTime();
            const pastDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

            // Check if 30 days have passed
            const res = pastDays <= 30;
            if(res){
                return res;
            }
        }

        // ...mapActions({
        //     checkScratchoffs: 'getNumbers/getOLCScratchGames'
        // }),
        // ...mapGetters({
        //     getScratchoffs: 'getNumbers/getScratchNumbers'
        // })
    },
    computed: {
    }
};
</script>