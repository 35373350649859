import Vue from "vue";

import SignInRegister from "./SignInRegister.vue";

export function load() {
    const selector = "sign-in-register";

    // Is the custom Vue root element in the DOM?
    if (!document.querySelector(selector)) {
        return; // go no further
    }

    // Create a new Vue app with the imported component
    new Vue({
        render: createElement =>
            createElement(SignInRegister, {
                props: {
                    ...window[selector]
                }
            }),
    }).$mount(selector);
}
