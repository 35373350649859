<template>
    <section class="tabbed_content fluid_tabs cf" id="TransactionLogPage">
        <UserLoginVue v-if="!isUserLogged"></UserLoginVue>
        <div v-if="isUserLogged">
            <div class="tabbed_content mlrCouponTabs fluid_tabs cf">
                <ul class="tab_nav cf">
                    <li :class="{'selected':product}">
                        <a href="#1" id="tab_1" @click="switchView">Active Lottery Cash &amp; Coupons</a>
                    </li>
                    <li :class="{'selected':gift}">
                        <a href="#2" id="tab_2" @click="switchView">Redeemed and Expired Lottery Cash &amp; Coupons</a>
                    </li>
                </ul>
                <div class="tab_content_wrap first_active cf" id="tab_content_block">
                    <div class="tab_content current_content" id="tab_content_1">
                        <ul class="myCouponsList" v-if="product && regularOrders.length">
                            <li class="mlrCouponListItem" v-for="order in regularOrders" :key="order.orderID">
                                <div class="mlrCouponListInfo">
                                    <a :href="'/mylotto-rewards/my-account/my-lottery-cash-coupons/lottery-cash-coupon-details?id=' + order.associatedOrderId + '&redeem=false'" class="mlrCouponName">
                                        {{ order.associatedProductName }}
                                    </a>
                                    <span class="mlrActiveSerial">
                                        <strong>Serial Number:</strong>
                                        <br>
                                        {{ order.skuNumber }}
                                    </span>
                                    <span class="mlrCouponExpDate">
                                        Expires on {{ order.expiresOn }}
                                    </span>
                                </div>
                                <a class="mlrCouponPrintLink" :href="'/mylotto-rewards/my-account/my-lottery-cash-coupons/lottery-cash-coupon-details?id=' + order.associatedOrderId + '&redeem=false'">Print</a>
                            </li>
                        </ul>
                        <div class="noInactiveCoupons" v-if="product && regularOrders.length < 1">
                            <p>You don't have any active Lottery Cash or Coupons.</p>
                            <a class="button btn_purple" href="/mylotto-rewards/redemption-central/coupons-and-lottery-cash">SHOP THE LOTTERY CASH &amp; COUPON CATALOG</a>
                        </div>
                        <ul class="myCouponsList" v-if="!product && redeemedOrders.length">
                            <li class="mlrCouponListItem" v-for="order in redeemedOrders" :key="order.associatedOrderId">
                                <div class="mlrCouponListInfo">
                                    <a :href="'/mylotto-rewards/my-account/my-lottery-cash-coupons/lottery-cash-coupon-details?id=' + order.associatedOrderId + '&redeem=true'" class="mlrCouponName">
                                        {{ order.associatedProductName }}
                                    </a>
                                    <span class="mlrActiveSerial">
                                        <strong>Serial Number:</strong>
                                        <br>
                                        {{ order.skuNumber }}
                                    </span>
                                    <span class="mlrCouponExpDate">
                                        Expires on {{ order.expiresOn }}
                                    </span>
                                </div>
                                <a class="mlrCouponPrintLink" :href="'/mylotto-rewards/my-account/my-lottery-cash-coupons/lottery-cash-coupon-details?id=' + order.associatedOrderId + '&redeem=true'">Print</a>
                            </li>
                        </ul>
                        <div class="noInactiveCoupons" v-if="!product && redeemedOrders.length < 1">
                            <p>You don't have any redeemed or expired Lottery Cash or Coupons.</p>
                            <a class="button btn_purple" href="/mylotto-rewards/redemption-central/coupons-and-lottery-cash">SHOP THE LOTTERY CASH &amp; COUPON CATALOG</a>
                        </div>
                    </div>
                    <div style="display:none;" class="tab_content current_content" id="tab_content_2">
                        <div class="redeemedHeader snippOrders">
                            <span class="headerPname">Product Name</span>
                            <span class="headerOrdCode">Redemption Code</span>
                            <span class="headerPdate">Purchase Date</span>
                            <span class="headerMasR">Mark as Not Redeemed</span>
                        </div>
                        <ul class="myphysicalGiftcardOrdersList"></ul>
                    </div>
                </div>
            </div>
            
            <!-- <div id="redeemOrderModal" class="iziModal data-modalOptions">
                <h3>Did you redeem this Digital Purchase?</h3>
                <p>If you have redeemed it, tap YES to remove it from your Active list.</p>
                <div class="orderModalOptions">
                    <a class="btn_purple btn_gray_secondary" data-izimodal-close>NO, KEEP ACTIVE</a>
                    <button type="button" class="btn_primary redeemOrder" data-izimodal-close>YES, I REDEEMED IT</button>
                </div>
            </div>
            <div id="notRedeemedModal" class="iziModal data-modalOptions">
                <h3>Did you redeem this Digital Purchase?</h3>
                <p>If you have not redeemed it, tap YES to remove it from your Redeemed list.</p>
                <div class="orderModalOptions">
                    <a class="btn_purple btn_gray_secondary" data-izimodal-close>NO, I REDEEMED IT</a>
                    <button type="button" class="btn_primary unredeemOrder" data-izimodal-close>YES, IT'S NOT REDEEMED</button>
                </div>
            </div> -->
        </div>
    </section>
</template>

<script>
    // import $ from 'jquery';
    import moment from "moment";
    import Pagination from '../Pagination/pagination.vue';
    import UserLoginVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    // const modal = {
    //     mounted() {console.log('wee')}
    // }

    export default {
        // directives: {
        //     modal
        // },
        components: {
           Pagination,
           UserLoginVue
        },
        data: function () {
            return {
                isUserLogged: this.isUserAuthed(),
                mobileCashing: false,
                token: '',
                user: '',
                regularOrders: '',
                redeemedOrders: '',
                points: 0,
                disable: true,
                totalPages: 10,
                itemsPerPage: 10,
                currentPage: 1,
                currentItems: '',
                physicalGiftcard: false,
                activeOrders: false,
                product: true,
                gift: false
            }
        },
        mounted: function () {
            this.getToken();

            this.startDate = moment().startOf('month').format('yyyy-MM-DD');
            this.endDate = moment().format('yyyy-MM-DD');
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.apiOrders(true);
                    this.apiOrders(false);
                });
            },
            getOrders() {
                // Check if orders already exist
                // let refresh = this.$cookies.get('couponOrdersTimestamp');

                // let currentDate = moment(new Date()),
                //     refreshDate = moment(refresh),
                //     difference = currentDate.diff(refreshDate, 'minutes');

                // let orders = this.$cookies.get('couponOrders');
                // console.log(orders);

                // if (difference < 2 && orders != null) {
                //     orders.length ? (function() {this.regularOrders = orders.regular; this.redeemedOrders = orders.redeem})() : this.apiOrders();
                // } else {
                    this.apiOrders(true);
                    this.apiOrders(false);
                // }
            },
            apiOrders(redeemed) {
                let apiToken = this.token,
                    red = redeemed,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserCouponList?userId=' + this.user.user.userId + '&isRedeemed=' + red + '&categoryId=0';

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let d = json.data;

                    for (let i = 0; i < d.length; i++) {
                        let data = d[i];

                        let date = moment(new Date(data.expiresOn)).format('MMMM Do YYYY');

                        data.expiresOn = date;
                    }

                    if (red === false) {
                        this.regularOrders = d;
                    } else {
                        this.redeemedOrders = d;
                    }

                    let items = {
                        regular: this.regularOrders,
                        redeem: this.redeemedOrders
                    };

                    this.$cookies.set('couponOrdersTimestamp', new Date());
                    this.$cookies.set('couponOrders', JSON.stringify(items));
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            switchView() {
                this.product = !this.product;
                this.gift = !this.gift;
            },
            setPages() {
                this.itemsPerPage = 5;
                this.totalPages = Math.ceil(this.tLog.length / this.itemsPerPage);
            },
            changePage(p) {
                this.currentPage = p;
                this.setItems();
            },
            setItems() {
                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.tLog.slice(start, end);

                this.currentItems = currentItems;
            },
            setStartDate(t) {
                let target = t;

                this.startDate = moment(target.target.value).format('yyyy-MM-DD');
            },
            setEndDate(t) {
                let target = t;

                this.endDate = moment(target.target.value).format('yyyy-MM-DD');
            }
		}
    };
</script>