<template>
    <div>
        <h3>Electronic Funds Transfer</h3>
        <div v-if="!isLoading && step == 'eft'">
            <div class="addEditListWrap EFTstatus cf">

                <div class="addEditList cf" v-if="!hasEft">
                    <label>No Account Entered</label>
                    <button @click="editEft()" class="button btn-secondary editBtn">Add Account</button>
                </div>
                <div class="addEditList cf" v-if="hasEft">
                    <span class="icon-Status statusEFT"></span>
                    <label>
                        {{eft.eftAccountName}}
                        <span>Account Number {{eft.checkingAccountNumber}}</span>
                    </label>
                    <button @click="editEft()" class="button btn-secondary editBtn">{{ btnText }}</button>
                </div>

            </div>
            <div class="btn-actions cf">
                <button class="btnNext button btn-primary" @click="onSaveClick('next')">Federal Tax Information</button>
            </div>
        </div>
        <div v-if="!isLoading && step == 'eft-edit'">
            <electronic-funds-edit :eftJson="eftJson" :readOnly="isDisabled" @onEftSaveClick="onEftSaveClick"></electronic-funds-edit>
        </div>
    </div>
</template>

<script>
    import ElectronicFundsEdit from './electronic-funds-edit';
    export default {
        components: {
            ElectronicFundsEdit
        },
        props: {
            eftJson: String,
            readOnly: Boolean
        },
        data: function () {
            return {
                eft: {},
                hasEft: false,
                isLoading: true,
                step: '',
                isDisabled:false,
                btnText: 'Edit'
            };
        },
        mounted: function () {
            this.step = this.getURLQuery('step');
            this.deserializeJson();
            this.isLoading = false;
            this.isDisabled = this.readOnly;
            if (this.isDisabled) {
                this.btnText = 'View';
            }
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            if (urlParams.has('step')) {
                this.step = urlParams.get('step');
            }
        },
        methods: {
            deserializeJson() {
                this.eft = JSON.parse(this.eftJson);
                if (this.eft.checkingAccountNumber !== undefined && this.eft.checkingAccountNumber !== null) {
                    this.hasEft = true;
                }
            },
            editEft() {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                this.step = 'eft-edit';
                urlParams.set('step', 'eft-edit');
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            onEftSaveClick(eftEdit) {
                // let request = {};
                // request.dir = 'next';
                // request.eft = this.eft;
                this.$emit('onEftSaveClick', eftEdit);
            },
            onSaveClick(dir) {
                let request = {};
                request.dir = dir;
                request.eft = this.eft;
                this.$emit('onEftSaveClick', request);
            }
        },
        computed: {}
    };
</script>
