<template>
    <div class="nav-row">
        <!-- <TokenTimeout /> -->
        <button class="menu-mobile btn-close" v-on:click="menuMobile"></button>
        <div v-if="menu != ''" v-html="menu"></div>
        <input type='hidden' id="userInput" v-model="userInput" @click="getForgottenPassword">

        <WinningNumbers v-if="!mobile" v-show="false"></WinningNumbers>
        <!-- <WinningNumbersMobile v-if="mobile" v-show="false"></WinningNumbersMobile> -->
    </div>
</template>
<script>
    import Vue from 'vue';
    import WinningNumbers from './WinningNumbersMenu';
    import WinningNumbersMobile from './WinningNumbersMenuMobile';
    // import TokenTimeout from '../Shared/TokenTimeout.vue';
    import $ from 'jquery';
    import {mapActions, mapGetters} from 'vuex';

    export default {
        components: {
            WinningNumbers,
            WinningNumbersMobile,
            // TokenTimeout
        },
        data: function () {
            return {
                menu: '',
                // winningNumbers: '<winningnumbers/>',
                breakpoint: 960,
                toggleSpeed: 469,
                userInput: '',
                token: '',
                mobile: false,
                close: true
            }
        },
        mounted: function () {
            this.getMenuData();
        },
        methods: {
            getMenuData() {
                let menuData = JSON.parse(localStorage.getItem('mt_siteMenu'));

                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                })
                .then(() => {

                    if (menuData == undefined || menuData == "" || menuData == null || Object.keys(menuData).length === 0) {
                        fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+'/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=site-menu', {
                            headers: {
                                'Authorization': 'Bearer ' + this.token
                            }
                        })
                        .then(response => response.json())
                        .then(json => {
                            let dataJson = json.data;
                            localStorage.setItem('mt_siteMenu', JSON.stringify(dataJson));
                            this.showMenu(dataJson);
                            this.checkLocalNumbers(this.token);
                        });
                    } else {
                        this.showMenu(menuData);
                        this.checkLocalNumbers(this.token);
                    }
                })
            },
            addMenu(menuData) {
                let contentMarkup = menuData[0].centralContent;
                let parser = new DOMParser();
                let htmlDoc = parser.parseFromString(contentMarkup, 'text/html');

                this.menu = htmlDoc.body.innerHTML;
                return Promise.resolve();
            },
            showMenu(menuData) {
                this.addMenu(menuData)
                .then(() => {
                    setTimeout(() => {
                        let ComponentClass = Vue.extend(WinningNumbers),
                            instance = new ComponentClass(),
                            element = document.getElementById('winning-numbers-menu');

                        instance.$mount();
                        element.appendChild(instance.$el);
                    }, 1500)
                    setTimeout(() => {
                        let ComponentClass = Vue.extend(WinningNumbersMobile),
                            instance = new ComponentClass(),
                            element = document.getElementById('winning-numbers-menu-mobile');

                        instance.$mount();
                        element.appendChild(instance.$el);
                    }, 1500)
                })
                .then(() => {
                    this.megaMenu()

                    if ($(window).width() <= this.breakpoint) {
                        this.mobile = true;
                        setTimeout(() => {
                            let signout = document.getElementById('mobileMenuSignOut');
                            signout.addEventListener('click', function() {
                                let realSignout = document.getElementsByClassName('sign-out-button');
                                realSignout[0].click();
                            });
                        }, 500);
                    }
                })
            },
            megaMenu: function () {
                var toggleSpeed = 469;
                var breakpoint = 960;
                var container = $('#container');
                $('.megaMenu li:has( > ul)').addClass('menu-dropdown-icon');
                $('.megaMenu > li > ul:not(:has(ul))').addClass('normal-sub');

                if ($(window).width() > breakpoint) {
                    $(document).on({
                        mouseenter: function () {
                            $(this).children('ul').stop(true, false).fadeIn(150).addClass('opened');
                        },
                        mouseleave: function () {
                            $(this).children('ul').stop(true, false).fadeOut(150).removeClass('opened');
                        }
                    }, ".megaMenu > li");
                }
                
                var thisPath = location.pathname,
                    aboutRegex = /about/i;

                thisPath = thisPath.substring(1, 7);
                if (aboutRegex.test(thisPath)) {
                    $('#aboutMenu > li').addClass('current-path');
                }
                if ($(window).width() <= breakpoint) {
                    $(document).on({
                        click: function (e) {
                            e.preventDefault();
                        }
                    }, '.megaMenu > .menu-dropdown-icon > a');
                }

                let close = true;

                // open/close menus
                $(document).on({
                    click: function () {
                        $('#winning-numbers-menu-mobile>.recent_numbers_wrap>.orbit>.orbit-wrapper>.orbit-bullets>.orbit-buttons').on({
                            click: function() {
                                close = false;
                            }
                        })
                        if ($(window).width() <= breakpoint) {
                            if (close === false) {
                                close = true;
                            } else if ($(this).hasClass('subMenuOpen')) {
                                if ($('.mobileB').length) {
                                    $('.subMenuOpen').off();
                                } else {
                                    $('.subMenuOpen').removeClass('subMenuOpen').children('ul').slideUp(toggleSpeed);
                                }
                            } else {
                                $('.subMenuOpen').removeClass('subMenuOpen').children('ul').slideUp(toggleSpeed);
                                $(this).addClass('subMenuOpen').children('ul').slideDown(toggleSpeed, function () {});
                            }
                        }
                    }
                }, '.megaMenu > li');

                var initWidth = container.width();

                $(window).on({
                    resize: function () {
                        var newWidth = container.width();
                        if (initWidth != newWidth) {
                            $('.megaMenu > li').children('ul').hide();
                            $('#container').removeClass('show-on-mobile');
                            $('.menu-mobile').removeClass('menu-open');
                            $('.topnav').removeClass('menu-open');
                            $('.topnav').css({
                                'min-height': '',
                                'width': ''
                            });
                        }

                        if ($(window).width() <= breakpoint) {
                            $(document).on({
                                click: function (e) {
                                    e.preventDefault();
                                }
                            }, '.megaMenu > .menu-dropdown-icon > a');
                        }

                        if (newWidth <= this.breakpoint) {
                            this.mobile = true;
                            $(document).on({
                                click: function (e) {
                                    e.preventDefault();
                                }
                            }, '.megaMenu > .menu-dropdown-icon > a');
                        } else {
                            this.mobile = false;
                        }
                    }
                });
            },
            menuMobile: function(event) {
                var container = $('#container');
                var winHeight = container.height();
                var winWidth = container.width();

                if (winWidth > 490) {
                    winWidth = 450;
                } else {
                    winWidth = winWidth - 40;
                }
                $('.topnav').css({
                    'min-height': winHeight,
                    'width': winWidth
                });

                setTimeout(() => {
                    $('#top-menu').css('display', 'none');
                }, 300)

                $('#container').removeClass('show-on-mobile');
                $('.menu-mobile').removeClass('menu-open');
                $('.topnav').removeClass('menu-open');
                
                event.preventDefault();
            },
            getForgottenPassword() {
                let apiToken = this.token,
                    urlProtocol = this.getProtocol(),
                    url = process.env.VUE_APP_APIURL+'/1.0/PAM/ForgotPassword';

                let bodyAPI = {
                        "userName": document.getElementById('userInput').value
                }

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    },
                    body: JSON.stringify(bodyAPI)
                }

                fetch(urlProtocol + url, requestOptions)
                .then(response => response.json())
                .then(json => {
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            ...mapActions ({
                checkLocalNumbers: 'getNumbers/getOLCDrawGames'
            }),
            ...mapGetters ({
                getGames: 'getNumbers/getGames',
                getStorageNumbers: 'getNumbers/getStorageNumbers'
            })
        },
        watch: {
            password(newVal, oldVal) {
                console.log(newVal);
                console.log("=========================")
                console.log(oldVal);
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>