<template>
    <div class="moduleContent cf">
        
        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

        <!-- current application info -->
        <div v-if="!isLoading && licenseFound">
            <h4>Current Application Information</h4>
            <dl class="reviewFields cf">
                <dt>Agent Number</dt>
                <dd><span id="lblAgentNumber">{{ applicationData.agentNumber }}</span></dd>
                <dt>Business Name</dt>
                <dd><span id="lblBusinessName">{{ applicationData.businessLegalName }}</span></dd>
                <dt>Doing Business As</dt>
                <dd><span id="lblDoingBusinessAs">{{ applicationData.businessDoingBusinessAs }}</span></dd>
                <dt>Business Address</dt>
                <dd><span id="lblBusinessAddress">{{ applicationData.businessStreetAddress }}, {{
                    applicationData.businessCity }}, {{ applicationData.businessState }} {{ applicationData.businessZip }} {{ applicationData.businessZipFour }}</span></dd>
                <dt>Legal Address</dt>
                <dd><span id="lblLegalAddress">{{ applicationData.w9StreetAddress }}, {{ applicationData.w9City }}, {{
                    applicationData.w9State }} {{ applicationData.w9Zip }} {{ applicationData.w9ZipPlus4 }}</span></dd>
                <dt>Mailing Address</dt>
                <dd><span id="lblMailingAddress">{{ applicationData.mailingStreetAddress }}, {{
                    applicationData.mailingCity }}, {{ applicationData.mailingState }} {{ applicationData.mailingZip }} {{ applicationData.mailingZipFour }}</span></dd>
                <dt>Business Phone Number</dt>
                <dd><span id="lblBusinessPhoneNumber">{{ applicationData.businessPhoneNumber }}</span></dd>
                <dt>Renew Date</dt>
                <dd><span id="lblRenewDate">{{ customFormatter(applicationData.renewDate) }}</span></dd>
                <dt>Current Principal List</dt>
                <dd>
                    <ul class="eAppUpdatePrincipalList">
                        <li v-for="(principal, index) in applicationData.principals" :key="index">
                            <span class="app_principal_salutation">{{ principal.principalSalutation }} </span>
                            <span class="app_principal_name">{{ principal.principalName }}</span>,
                            <span class="app_principal_title">{{ principal.principalTitle }}</span><br />
                            <span class="app_principal_email">{{ principal.principalEmail }} </span>
                            <span class="app_principal_share">Ownership: {{ principal.principalPercentOwnership }}%</span>
                        </li>
                    </ul>
                </dd>
            </dl>
        </div>

        <!-- error messages -->
        <div class="ErrorLabel alert-message" v-if="!isLoading && !licenseFound">
            We could not locate a license for you.
        </div>

    </div>
</template>

<script>
// import $ from 'jquery';
import moment from "moment";
import { HollowDotsSpinner } from 'epic-spinners';

export default {
    props: {
        userId: Number,
        agentId: String,
        eAppToken: String,
    },
    components: {
        HollowDotsSpinner
    },
    data() {
        return {
            isLoading: false, 
            applicationData: {},
            licenseFound: false,
            testMode: false, // toggle me for testing
            agenNumber: ''
        }
    },
    mounted: function () {
        this.init();
    },
    methods: {
        init() {
            if (this.testMode) {
                this.setTestData()
                .then((appData) => {
                    this.applicationData = appData;
                    // toggle for different states:
                    // this.applicationData.hasRenewal = true;
                    this.applicationData.hasUpdate = true;
                    this.$emit('licenseReady', this.applicationData);
                });
            } else {
                
                // to be used to populate the applicationData
                if (this.agentId == undefined || this.agentId == '') {
                    // get the Agent Number from the querystring 'an'
                    this.agentId = this.getURLQuery('an');
                }
                if (this.agentId == '') {
                    this.licenseFound = false;
                } else {
                    this.tryGetFromQueryString().then(() => {
                        this.fetchData();
                    });
                }
            }
        },
        tryGetFromQueryString() {

            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            if (urlParams.has('an')) {
                this.agentNumber = urlParams.get('an');
            }
            
            return Promise.resolve();
        },
        async fetchData() {
            this.isLoading = true;
            const appsRequestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.eAppToken,
                    "Content-Type": "application/json"
                },
            };
            
            this.applicationData = await (await fetch(this.GET_RETAILER_LICENSE_API_URL, appsRequestOptions)).json();
            // this.applicationData = JSON.stringify(this.applicationData);
            // handle no data found
            if (this.applicationData) {
                this.licenseFound = true;
                this.isLoading = false;
                this.$emit('licenseReady', this.applicationData);
            } else {
                this.licenseFound = false;
            }

            // this.$emit(this.applicationData);
        },
        customFormatter(dateVal) {
            return moment(dateVal).format("MM/DD/YYYY");
        },
        setTestData() {
            let appData = {
                agentNumber: "0242184",
                businessLegalName: "Official Business Name",
                doingBusinessAs: 'DBA Name',
                businessStreetAddress: '123 Test Street',
                businessCity: 'Cityville',
                businessState: 'OH',
                businessZip: '43215',
                businessZipFour: '4444',
                w9StreetAddress: '11 Legal Street',
                w9City: 'Taxville',
                w9State: 'OH',
                w9Zip: '43215',
                w9ZipPlus4: '4444',
                mailingStreetAddress: '22 Mailing Road',
                mailingCity: 'Mailburg',
                mailingState: 'OH',
                mailingZip: '43215',
                mailingZipFour: '4444',
                businessPhone: '555-555-5555',
                renewDate: '05/22/2024',
                principals: [
                    {
                        principalSalutation: 'Sir',
                        principalFirstName: 'Prince',
                        principalLastName: 'Ipal',
                        principalTitle: 'CEO',
                        principalEmail: 'fake@dot.com',
                        principalPercentOwnership: '90',
                    },
                    {
                        principalSalutation: 'Dr.',
                        principalFirstName: 'Feel',
                        principalLastName: 'Good',
                        principalTitle: 'CMO',
                        principalEmail: 'fake@gmail.com',
                        principalPercentOwnership: '10',
                    }
                ],
                hasRenewal: false,
                hasUpdate: false,
                updateWRapperGuid: '6f1787c8-da63-4a34-9f00-e4747bc7a0b7'

            };
            this.licenseFound = true;
            this.isCurrentAppLoading = false;
            return Promise.resolve(appData);
        }
    },
    computed: {
        GET_RETAILER_LICENSE_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Retailer/LicenseDetail/' + this.agentNumber;
        }
    }
}
</script>