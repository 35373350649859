<template>
    <div class="mlrHomeHeader">

        <!-- <SignInRegister v-if="!isUserLogged"></SignInRegister> -->

        <div class="mlrIntro cf" v-if="!isUserLogged">
            <h1>Play&nbsp;Games. Earn&nbsp;Points. Get&nbsp;Rewarded.</h1>
            <p class="intro">Sign-in or register to enter your promotion codes or shop our catalog.</p>
            <a class="button btn_popupLogIn globalLogin" id="globalSignIn" href="#">Sign In</a>
            <a class="button btn_joinNow" href="/mylotto/my-account/register" id="globalRegister">Register</a>
        </div>

        <div class="modulePrpl quickTicketEntry cf" v-if="isUserLogged">
            <div>
                <div class="quickEntryGreeting">
                    <h2>Hello, <span v-if="nickname">{{nickname}}</span><span v-if="!nickname"></span>{{username}}</h2>
                    <p>You have <span class="currentBalance">{{pointBalance.toLocaleString()}} pts.</span>
                    </p>
                    <a :href="pointsbuttonurl" class="button btn-secondary btn_pointHistory">
                        <p>{{ pointsbuttontext }}</p>
                    </a>
                    <a :href="profilebuttonurl" class="button btn-secondary btn_myProfile"> 
                        <p>{{ profilebutton }}</p>
                    </a>
                </div>
                <div class="quickEntryForm showAuthed" v-if="isUserLogged">
                    <TicketEntry contentKey="ticket-entry-text" requireDigits="3"></TicketEntry>
                </div>
            </div>
            <div class="entryFooterLinks">
                <a :href="footerlinks.link1url">{{ footerlinks.link1text }}</a>
                <a :href="footerlinks.link2url">{{ footerlinks.link2text }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import TicketEntry from '../TicketEntry/TicketEntry.vue';
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components:{
            TicketEntry,
            SignInRegister
        },
        data: function () {
            return {
                username: '',
                pointBalance: '',
                entered: '',
                footerlinks: {
                    link1text: '',
                    link1url: '',
                    link2text: '',
                    link2url: ''
                },
                heading: '',
                logo: '',
                nonauthheadline: '',
                nonauthsubhaedline: '',
                placeholder: '',
                pointsbuttontext: '',
                pointsbuttonurl: '',
                profilebutton: '',
                profilebuttonurl: '',
                submit: '',
                isUserLogged: false

            }
        },
        mounted: function () {
            
            let apiToken = this.$cookies.get('apiToken');
            // console.log(apiToken);
            this.isUserLogged = this.isUserAuthed();
            this.getToken();

        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.fetchData(token);
                    this.loadComponent();
                    this.nickname = apiToken.user.nickname;
                    this.username = apiToken.user.firstName;
                    this.pointBalance = apiToken.user.pointBalance.balance;
                });
            },
            loadComponent() {
                $(document).ready(function () {
                    $('.close-button').on('click', function () {
                        $("#globalLogInModal").hide();
                        $("#globalLoginBlocker").hide();
                    })

                    $(".btn_popupLogIn").on('click', function () {
                        $("#globalLogInModal").show();
                        $("#globalLoginBlocker").show();
                    });
                })

            },
            fetchData(token) {
                var apiToken = token;

                return fetch(process.env.VUE_APP_APIPROTOCOL +process.env.VUE_APP_APIURL + "/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=RewardsLandingGreeting", {
                        headers: {
                            Authorization: "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let res = json.data;
                    let greetingContent = res[0].centralContent;
                    let parsedContent = JSON.parse(greetingContent)

                    this.pointsbuttontext = parsedContent.pointsbuttontext;
                    this.pointsbuttonurl = parsedContent.pointsbuttonurl;
                    this.profilebutton = parsedContent.profilebutton;
                    this.profilebuttonurl = parsedContent.profilebuttonurl;
                    
                    this.footerlinks.link1text = parsedContent.footerlinks.link1text;
                    this.footerlinks.link2text = parsedContent.footerlinks.link2text;
                    this.footerlinks.link1url  = parsedContent.footerlinks.link1url;
                    this.footerlinks.link2url  = parsedContent.footerlinks.link2url;


                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
           
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
    .modulePrpl {
         .quickEntryGreeting {
            .button {
                display: inline-flex;
                align-items: center;
            }
         }
    }
    .mlrHomeHeader .quickTicketEntry .quickEntryGreeting p {
        margin-bottom: 0px;
    }
    .input-group {
        width: 100%;
    }
    .quickEntryForm {
        
    }
</style>