<template>
    <span>
        <UserLoginRegisterVue v-if="!isUserLogged"></UserLoginRegisterVue>
        <button class="mlrAccountDeletion button btn_purple" @click="confirmDeletion" v-if="isUserLogged">Delete My Account</button>
    </span>
</template>

<script>
    // import $ from 'jquery';
    import UserLoginRegisterVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
           UserLoginRegisterVue
        },
        data: function () {
            return {
                isUserLogged: false
            }
        },
        mounted: function () {
            this.getToken();
            this.isUserLogged = this.isUserAuthed();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                });
            },
            confirmDeletion() {
                window.location.pathname = "/mylotto-rewards/my-account/my-profile/delete-your-account/confirm-delete";
            }
		}
    };
</script>