<template>
    <div class="ubo">
        <div v-if="!isUserLogged">
            <h2>Sign In below to claim your birthday offer.</h2>
        </div>
        <div v-else>
            <!--<div>
                Success: {{response.success}}
            </div>-->
            <div>
                <h2>{{ setResponseTitle() }}</h2>
                <p><strong v-html="response.message"></strong></p>
            </div>
        </div>
        <SignInRegister v-if="!isUserLogged"></SignInRegister>
    </div>
</template>
<script>
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
            SignInRegister
        },
        data: function() {
            return {
                isUserLogged: false,
                token: {},
                userId: '',
                currentUserId: '',
                response: {},
                responseTitle: ''
            }
        },
        mounted: function() {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                  
                    this.token = token;
                    this.currentUserId = this.getLoggedInUserId();
                 
              }).then(() => {
                  let queryString = window.location.search;
                  let urlParams = new URLSearchParams(queryString);
                  if (urlParams.has('ubo')) {
                      this.userId = urlParams.get('ubo');
                  }
                //   console.log(this.userId);
                  if (this.userId) {
                      this.fetchData();
                  }
              });
            },
            async fetchData() {
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                };
                let url = this.getProtocol() + process.env.VUE_APP_APIURL + '/1.0/rewards/birthdayoffer?ubo=' + this.userId; 
                this.response = await (await fetch(url, appsRequestOptions)).json();

                
            },
            setResponseTitle() {
                return this.response.success ? 'Birthday points claimed.' : 'There was an error.';
            }
        }
    }
</script>
