import Vue from "vue";

import HomeNumbers from "./Home-Numbers.vue";

export function load() {
    const selector = "home-numbers";

    // Is the custom Vue root element in the DOM?

    if (!document.querySelector(selector)) {
        return;
    }

    // Create a new Vue app with the imported Home component

    new Vue({
        render: createElement =>
            createElement(HomeNumbers, {
                props: {
                    ...window[selector],
                },
            }),
    }).$mount(selector);
}
