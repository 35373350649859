<template>
    <div class="bynumber-wrapper">
        <input type="number" name="inputSearchByNumber" :value="value" @input="$emit('input', $event.target.value)" placeholder="Search By Number">
    </div>
       
</template>

<script>

    export default {
        props: ['value'],
        components: {
        },
        data: function () {
            return {
            }
        }       
    };
</script>

<style>
.bynumber-wrapper{
    padding: 2.5rem 2.5rem 0 2.5rem;
}
.bynumber-wrapper input:invalid {
  border: 2px solid red;
}
</style>