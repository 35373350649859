<template>
    <div class="full">
        
        <div class="featuredWinner-container text-center">
            <h1>Winners Happen Every Day.</h1>

            <p class="featuredWinner-intro">Whether it’s $14 million, $100 thousand, $100 or less, there are over 350,000 winners every day! Here are our latest big winners. And who knows? You could be next on this list.</p>
              
                <VueSlickCarousel  v-bind="settings" v-if="winners.length">
                    <div class="win-item" v-for="winner in winners" :key="winner.id">
                        <div class="win-value">
                            {{winner.winAmount}}
                        </div>
                        <div class="win-info">
                            <span class="win-date">{{ customFormatter(winner.date) }}:</span>
                            {{winner.title}}
                        </div>
                    </div>
                    
                </VueSlickCarousel>
           
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import moment from 'moment';


    export default {
        components: {
            VueSlickCarousel
        },
        data: function () {
            return {
                winners: [],
                settings: {
                    "dots": false,
                    "focusOnSelect": true,
                    "infinite": false,
                    "speed": 500,
                    "slidesToShow": 5,
                    "slidesToScroll": 3,
                    "touchThreshold": 5,
                    "responsive": [
                        {
                           "breakpoint": 1500,
                           "settings": {
                               "slidesToShow": 4
                           }
                        },{
                           "breakpoint": 1200,
                           "settings": {
                               "slidesToShow": 3
                           }
                        },{
                           "breakpoint": 940,
                           "settings": {
                               "slidesToShow": 2,
                               "slidesToScroll": 2,
                           }
                        },{
                           "breakpoint": 620,
                           "settings": {
                               "slidesToShow": 1,
                               "slidesToScroll": 1,
                           }
                        }
                    ]
                }
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('MMMM Do');
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();

                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetAllByCategory/FeaturedWinner", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;

                    function sortByDate(a, b) {
                        return new Date(b.date).getTime() - new Date(a.date).getTime();
                    }
                   
                    res.sort(sortByDate);

                    this.winners = res;

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>


</style>