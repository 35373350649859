import Vue from "vue";

import DrawWinners from "./Drawing-Winners.vue";

export function load(payload) {
    const selector = "drawing-winners";
    let store = payload;
  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return;
  }

  // Create a new Vue app with the imported Home component
  new Vue({
    store,
    render: createElement =>
      createElement(DrawWinners, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}
 