import Vue from "vue";
import Achievements from "./Achievements.vue";

export function load() {
    const selector = "achievements";

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return;
  }

  // Create a new Vue app with the imported Home component
  new Vue({
    render: createElement =>
      createElement(Achievements, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}
