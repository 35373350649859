<!-- ~/Views/Games/MegaMillions/MegaMillions-WinningNumbers.vue -->

<template>
    <div class="winningNumbersWrap">
        <!-- logo -->
        <a href="/games/draw-games/gimme-5">
            <img v-bind:src="gameLogo" v-bind:alt="gameName" class="winNumGameLogo" />
        </a>
        <div class="winningNumbersContent tall rolling5">
            <span class="winNumDate">{{ new Date(drawDate) | moment("MM/DD/YYYY") }}</span>

            <div class="winningNumbersContent">
                <SpecialWinningNumbers :numbers="drawNumbers" ></SpecialWinningNumbers>

                <span class="nextJackpot">
                    <span class="wnLbl">Next Jackpot: </span>
                    <span class="wnVal" v-if="nextJackpot < 1000000">${{ nextJackpot.toLocaleString() }}</span>
                    <span class="wnVal" v-if="nextJackpot >= 1000000 && nextJackpot < 1000000000">${{ (nextJackpot/1000000).toLocaleString() }} Million</span>
                    <span class="wnVal" v-if="nextJackpot >= 1000000000">${{ (nextJackpot/1000000000).toLocaleString() }} Billion</span>
                </span>
            </div>
        </div>
        
        <!-- <span class="cov">
            <span class="wnLbl">Cash Option Value: </span><span class="wnVal">{{ cashOption | jackpotFormat }}</span>
        </span> -->
        <a href="/winning-numbers/check-your-numbers#tab4" class="button">Past Draws</a>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import SpecialWinningNumbers from '../../../ViewComponents/WinningNumbers/SpecialWinningNumbers.vue';

    export default {
        props: [
            'gamesData'
        ],
        components:{
            SpecialWinningNumbers
        },
        data: function() {
            return { 
                drawNumbers: [],
                gameLogo: '',
                gameName: '',
                drawDate: '',
                nextJackpot: '',
                cashOption: '',
                errorMessage: 'Numbers currently not available. Please try again later.'
            }
        },
        mounted: function() {
            // this.getToken();
            this.setGames();
        },
        methods: {
            specialBall: function(index) {
                if (index == 5) {
                    return 'ball_yellow winNumBall';
                } else if (index == 6) {
                    return 'megaplier'
                } else {
                    return 'winNumBall';
                }
            },
            setGames() {
                this.games = this.gamesData;
                
                for (let i = 0; i < this.games.length; i++) {
                    if (this.games[i].StringIdentifier === "rollingcash5") {
                        this.drawDate = this.games[i].Draws[0].DrawDate;
                        this.gameName = this.games[i].Name;
                        this.gameLogo = this.games[i].GameLogoURL;
                        this.drawNumbers = this.games[i].Draws[0].Numbers;
                        this.nextJackpot = this.games[i].Draws[0].NextPrizePool; // megaresults.draws[0].nextPrizePool;
                        this.cashOption = this.games[i].Draws[0].NextPDCV; // megaresults.draws[0].nextPDCV;
                    }
                }
            },
            // getToken() {
            //     // then hit API with token
            //     this.getAPItoken()
            //     .then((token) => {
            //         // this.fetchData(token);
            //         this.checkLocalNumbers(token)
            //         .then(() => {
            //             this.games = this.getStorageNumbers();
            //             console.log(this.games);
            //             for (let i = 0; i < this.games.length; i++) {
            //                 if (this.games[i].name === "Mega Millions") {
            //                     let mega = this.games[i];

            //                     this.gameLogo = mega.logo;
            //                     this.gameName = mega.name;
            //                     this.drawDate = mega.drawDate;
            //                     this.drawNumbers = mega.numbers;
            //                     this.nextJackpot = 244; // megaresults.draws[0].nextPrizePool;
            //                     this.cashOption = 184.7; // megaresults.draws[0].nextPDCV;
            //                 }
            //             }
            //         })
            //     });

            // },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                // alert('Mega token: '+token);
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/DrawGames/MegaMillions/GetGameInformation", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var megaresults = json.data;
                    this.gameLogo = megaresults.gameLogoURL;
                    this.gameName = megaresults.name;
                    this.drawDate = megaresults.draws[0].drawDate;
                    this.drawNumbers = megaresults.draws[0].numbers;
                    this.nextJackpot = 244; // megaresults.draws[0].nextPrizePool;
                    this.cashOption = 184.7; // megaresults.draws[0].nextPDCV;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            ...mapActions ({
                checkLocalNumbers: 'getNumbers/checkLocalNumbers'
            }),
            ...mapGetters ({
                getStorageNumbers: 'getNumbers/getStorageNumbers'
            })
        },
        watch: {
            gamesData() {
                this.setGames();
            }
        }
    };
</script>


<style lang="scss" scoped>
    /* style the unique aspects here */
    .winNumDate,
    .nextJackpot,
    .cov {
        // display: block;
    }
    .nextJackpot {
        color: $red;
    }
</style>