<template>
    <div id="cash_option_values_content" class="module">
        <ul id="cash_option_values_list" class="cf moduleContent">
            <li class="cash_option_megamillions cf grid-x" v-if="gameMega">
                <div class="columns cell small-12 medium-4 game_logo">
                    <img :src="gameMega.GameLogoURL" alt="Mega Millions">
                </div>
                <div class="columns cell small-12 medium-8 cash_option_content">
                    <div class="grid-x">
                        <div class="columns cell small-6 medium-12 cash_option_content_group">
                            <div class="cash_option_title">Next Jackpot</div>
                            <div class="cash_option_jackpot_value">$
                                <span v-if="gameMega.Draws[gameMega.drawIndex] != undefined">
                                    {{ gameMega.Draws[gameMega.drawIndex].NextPrizePool | decimalNumber }}
                                </span>
                                <span v-if="gameMega.Draws[gameMega.drawIndex] == undefined">
                                    {{ gameMega.Draws[0].NextPrizePool | decimalNumber }}
                                </span>
                                <!-- {{ gameMega.Draws[gameMega.drawIndex].NextPrizePool | decimalNumber }} -->
                            </div>
                        </div>
                        <div class="columns cell small-6 medium-12 cash_option_content_group">
                            <div class="cash_option_title">Cash Option Value</div>
                            <div class="cash_option_jackpot_value">$
                                <span v-if="gameMega.Draws[gameMega.drawIndex] != undefined">
                                    {{ gameMega.Draws[gameMega.drawIndex].NextPDCV | decimalNumber }}
                                </span>
                                <span v-if="gameMega.Draws[gameMega.drawIndex] == undefined">
                                    {{ gameMega.Draws[0].NextPDCV | decimalNumber }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="cash_option_caption">NEXT DRAWING <span class="cash_option_caption">
                            <!-- {{ gameMega.Draws[gameMega.drawIndex].DrawDate | moment }} -->
                            {{ calcNextDraw('megaMillion') | moment }}
                        </span>
                    </div>
                </div>
            </li>

            <li class="cash_option_powerball grid-x" v-if="gamePower">
                <div class="columns cell small-12 medium-4 game_logo">
                    <img :src="gamePower.GameLogoURL" alt="Powerball">
                </div>
                <div class="columns cell small-12 medium-8 cash_option_content">
                    <div class="grid-x">
                        <div class="columns cell small-6 medium-12 cash_option_content_group">
                            <div class="cash_option_title">Next Jackpot</div>
                            <div class="cash_option_jackpot_value">$<span>{{
                                gamePower.Draws[gamePower.drawIndex].NextPrizePool | decimalNumber
                            }}</span></div>
                        </div>
                        <div class="columns cell small-6 medium-12 cash_option_content_group">
                            <div class="cash_option_title">Cash Option Value</div>
                            <div class="cash_option_jackpot_value">$<span>{{
                                gamePower.Draws[gamePower.drawIndex].NextPDCV | decimalNumber
                            }}</span></div>
                        </div>
                    </div>
                    <div class="cash_option_caption">NEXT DRAWING <span class="cash_option_caption">
                            <!-- {{ gamePower.Draws[gamePower.drawIndex].DrawDate | moment }} -->
                            {{ calcNextDraw('powerBall') | moment }}
                        </span>
                    </div>
                </div>
            </li>

            <li class="cash_option_classic grid-x" v-if="gameClassic">
                <h3 class="columns cell small-12 medium-12">Classic Lotto Annuity and Cash Option Calculator</h3>
                <div class="columns cell small-12 medium-4 game_logo">
                    <img :src="gameClassic.GameLogoURL" alt="Classic Lotto">
                </div>

                <div class="columns cell small-12 medium-8 cash_option_content">
                    <div class="grid-x">
                        <div class="columns cell small-12 medium-8 cash_option_content_group">
                            <div class="cash_option_title">Enter Jackpot Value</div>
                            <div class="cash_option_jackpot_value">$
                                <input type="text" name="valNumb" v-model="inputNumb" @keyup="checkInput(inputNumb)"
                                    class="cash_value_input" maxlength="5" required pattern="number">
                                Million
                            </div>
                            <div class="error errortextOnly" v-if="!isFormValid && inputNumb != ''">Please provide a
                                valid number.</div>
                            <div class="cash_option_caption">Use decimal points to enter exact amount. As an example,
                                for $1,500,000 enter 1.5.</div>
                        </div>
                        <div class="columns cell small-12 medium-4 cash_option_content_group cash_option_buttons">
                            <button class="button calculate" @click="submit()"
                                :disabled="!isFormValid || inputNumb == ''">Calculate</button>
                            <button class="button reset" @click="reset()">Reset</button>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

        <div class="cash_option_values_results">
            <div class="grid-x">
                <div class="columns cell small-12 medium-8 cash_option_annuity_block">
                    <h3>Annuity Payments</h3>
                    <table cellspacing="0" cellpadding="0" border="0" class="cash_option_annuity_table">
                        <tbody>
                            <tr>
                                <th> </th>
                                <th>Year 1</th>
                                <th>Year 2-30</th>
                            </tr>
                            <tr>
                                <td class="cash_option_label">Annual Payment</td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.AnnuityPayments.AnnualPayment.Year1 | setNumberUSA
                                }}</span></td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.AnnuityPayments.AnnualPayment.Year230 | setNumberUSA
                                }}</span></td>
                            </tr>
                            <tr>
                                <td class="cash_option_label">Federal Tax (24%)</td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.AnnuityPayments.FederalTax.Year1 | setNumberUSA
                                }}</span></td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.AnnuityPayments.FederalTax.Year230 | setNumberUSA
                                }}</span></td>
                            </tr>
                            <tr>
                                <td class="cash_option_label">State Tax (4%)</td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.AnnuityPayments.StateTax.Year1 | setNumberUSA
                                }}</span></td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.AnnuityPayments.StateTax.Year230 | setNumberUSA
                                }}</span></td>
                            </tr>
                            <tr>
                                <td class="cash_option_label">Net Payment Per Year</td>
                                <td><span class="cash_option_result_medium">{{
                                    tableResults.AnnuityPayments.NetPaymentPerYear.Year1 | setNumberUSA
                                }}</span></td>
                                <td><span class="cash_option_result_medium">{{
                                    tableResults.AnnuityPayments.NetPaymentPerYear.Year230 | setNumberUSA
                                }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="columns cell small-12 medium-4 cash_option_block">
                    <h3>Cash Option</h3>
                    <table cellspacing="0" cellpadding="0" border="0">
                        <tbody>
                            <tr>
                                <td class="cash_option_label">Present Day Cash Value</td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.CashOption.PresentDayCashValue | setNumberUSA
                                }}</span></td>
                            </tr>
                            <tr>
                                <td class="cash_option_label">Federal Tax (24%)</td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.CashOption.FederalTax | setNumberUSA
                                }}</span></td>
                            </tr>
                            <tr>
                                <td class="cash_option_label">State Tax (4%)</td>
                                <td><span class="cash_option_result_small">{{
                                    tableResults.CashOption.StateTax | setNumberUSA
                                }}</span></td>
                            </tr>
                            <tr>
                                <td class="cash_option_label">One-Time Net Cash Value Amount</td>
                                <td><span class="cash_option_result_large">{{
                                    tableResults.CashOption.OneTimeNetCashValueAmount | setNumberUSA
                                }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="cash_value_disclaimers">
            <p>Please note that the jackpot prize calculator will provide you with only an <strong>estimate</strong> of
                the annuity and cash option prize amount. The accuracy of this calculator is not guaranteed. The actual
                prize award amounts may vary. Therefore, please contact your nearest Ohio Lottery office to obtain the
                actual prize award amounts.</p>
            <h4>Current tax withholding rates: <span>(Tax rates are subject to change.)</span></h4>
            <p>Federal - 24%<br>
                State - 4%</p>
        </div>

    </div>
</template>
<script>
import moment from "moment";
import { HollowDotsSpinner } from 'epic-spinners'
export default {
    components: {
        HollowDotsSpinner
    },
    data: function () {
        return {
            errorMessage: '',
            games: {},
            gameMega: null,
            gamePower: null,
            gameClassic: null,
            centralContent: {},
            fedTaxRate: null,
            stateTaxRate: null,

            inputNumb: '',
            isFormValid: null,
            isLoading: false,

            tableResults: {
                AnnuityPayments: {
                    AnnualPayment: {
                        Year1: '',
                        Year230: ''
                    },
                    FederalTax: {
                        Year1: '',
                        Year230: ''
                    },
                    StateTax: {
                        Year1: '',
                        Year230: ''
                    },
                    NetPaymentPerYear: {
                        Year1: '',
                        Year230: ''
                    },
                },
                CashOption: {
                    PresentDayCashValue: '',
                    FederalTax: '',
                    StateTax: '',
                    OneTimeNetCashValueAmount: ''
                }
            }

        }
    },
    mounted: function () {
        this.getToken();
        this.getLocalData();
    },
    methods: {
        getLocalData() {
            // this.games = JSON.parse(localStorage.getItem('olcDrawNumbers'));
            this.games = document.getElementById('cmsNumbers').value;
            this.games = JSON.parse(this.games);
            if (this.games != null || this.games != undefined) {
                this.games = this.games;
                this.gameMega = this.getGame('megamil');
                this.gamePower = this.getGame('pball');
                this.gameClassic = this.getGame('ClassicLotto');
            }

            console.log(this.games);
        },

        getGame(gameName) {
            console.log(this.games)
            let game = this.games.find(x => x.StringIdentifier == gameName);

            if (gameName != 'ClassicLotto') {
                // Get Near Draw Game And Index to get the data 
                let currentDate = new Date();
                let drawGameDate = game.Draws.map(x => new Date(x.DrawDate));

                let nearestDate;
                let drawIndex;

                drawGameDate.map((date, index) => {
                    let diff = moment(date).diff(moment(currentDate));
                    console.log(diff);
                    if (diff < 0) {
                        if (nearestDate) {
                            if (moment(date).diff(moment(nearestDate)) > 0) {
                                nearestDate = date;
                                drawIndex = index;
                            }
                        } else {
                            nearestDate = date;
                            drawIndex = index;
                        }
                    }
                });
                game.drawIndex = drawIndex;
            }
            return game
        },

        getToken() {
            // then hit API with token
            this.getAPItoken().then((token) => {
                this.fetchData(token);
            });
        },

        fetchData(token) {
            var apiToken = token;

            return fetch(
                process.env.VUE_APP_APIPROTOCOL +
                process.env.VUE_APP_APIURL +
                "/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=cash-option-content",
                {
                    headers: {
                        Authorization: "Bearer " + apiToken,
                    },
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    this.centralContent = JSON.parse(json.data[0].centralContent);
                    this.fedTaxRate = this.centralContent.fedTaxRate;
                    this.stateTaxRate = this.centralContent.stateTaxRate;
                })
                .catch((error) => {
                    return (this.errorMessage = error);
                });

        },
        reset() {
            this.inputNumb = '';
            this.tableResults = {
                AnnuityPayments: {
                    AnnualPayment: {
                        Year1: '',
                        Year230: ''
                    },
                    FederalTax: {
                        Year1: '',
                        Year230: ''
                    },
                    StateTax: {
                        Year1: '',
                        Year230: ''
                    },
                    NetPaymentPerYear: {
                        Year1: '',
                        Year230: ''
                    },
                },
                CashOption: {
                    PresentDayCashValue: '',
                    FederalTax: '',
                    StateTax: '',
                    OneTimeNetCashValueAmount: ''
                }
            }
        },
        submit() {
            let jackPot = this.inputNumb * 1000000
            let avgYear = Math.floor(jackPot / 30000.0) * 1000.0;
            let firstYear = avgYear + (jackPot - (avgYear * 30));

            this.tableResults.AnnuityPayments.AnnualPayment.Year1 = firstYear;
            this.tableResults.AnnuityPayments.AnnualPayment.Year230 = avgYear;

            this.tableResults.AnnuityPayments.FederalTax.Year1 = (firstYear * 24) / 100;
            this.tableResults.AnnuityPayments.FederalTax.Year230 = (avgYear * 24) / 100;

            this.tableResults.AnnuityPayments.StateTax.Year1 = (firstYear * 4) / 100;
            this.tableResults.AnnuityPayments.StateTax.Year230 = (avgYear * 4) / 100;

            this.tableResults.AnnuityPayments.NetPaymentPerYear.Year1 = firstYear - (firstYear * 0.29);
            this.tableResults.AnnuityPayments.NetPaymentPerYear.Year230 = avgYear - (avgYear * 0.29);

            this.tableResults.CashOption.PresentDayCashValue = jackPot * 0.5;
            this.tableResults.CashOption.FederalTax = (this.tableResults.CashOption.PresentDayCashValue * 24) / 100;
            this.tableResults.CashOption.StateTax = (this.tableResults.CashOption.PresentDayCashValue * 4) / 100;
            this.tableResults.CashOption.OneTimeNetCashValueAmount = (this.tableResults.CashOption.PresentDayCashValue - this.tableResults.CashOption.FederalTax - this.tableResults.CashOption.StateTax)
        },
        checkInput(numb) {
            this.isFormValid = /(?<=^| )\d+(\.\d+)?(?=$| )/.test(numb);
            return /(?<=^| )\d+(\.\d+)?(?=$| )/.test(numb);
        },
        calcNextDraw(gameName) {
            const nowDate = new Date();
            const nowHours = nowDate.getHours();
            const nowMinutes = nowDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = saturday

            let weekDay = nowDate.getDay();

            if (gameName === 'megaMillion') {
                // if it is Tuesday or Friday and the clock shows 23:00 or after
                if ((weekDay === 2 || weekDay === 5) && (nowHours > 23 || (nowHours === 23 && nowMinutes >= 0))) {
                    const nextDraw = new Date(nowDate);

                    if (weekDay === 5) {  // if it Friday
                        nextDraw.setDate(nowDate.getDate() + (2 - weekDay) + 3);  // we add 3 days to reach the following Tuesday
                    } else {  // if it Tuesday
                        nextDraw.setDate(nowDate.getDate() + (5 - weekDay));  // We add 5 days to reach the following Friday
                    }

                    nextDraw.setHours(23, 0, 0, 0);  // set the draw time (23:00)
                    return nextDraw;
                } else {
                    // if it is not Tuesday or Friday after 23:00, we calculate the next draw for today
                    const nextDraw = new Date(nowDate);

                    if (nowHours >= 23) {
                        nextDraw.setDate(nowDate.getDate() + 1);
                    }

                    nextDraw.setHours(23, 0, 0, 0);  // set the draw time (23:00)
                    return nextDraw;
                }
            }

            if (gameName === 'powerBall') {
                if (nowHours >= 23) {
                    if (nowMinutes === 0 || nowMinutes === 1) {
                        weekDay = 3;
                    } else if (nowMinutes === 3) {
                        weekDay = 2;
                    } else if (nowMinutes === 5) {
                        weekDay = 2;
                    }
                } else {
                    if (nowMinutes === 0 || nowMinutes === 1) {
                        weekDay = 0;
                    } else if (nowMinutes === 2) {
                        weekDay = 1;
                    } else if (nowMinutes === 4) {
                        weekDay = 1;
                    } else if (nowMinutes === 6) {
                        weekDay = 2;
                    }
                }

                const nextDraw = new Date(nowDate);
                nextDraw.setDate(nowDate.getDate() + weekDay);
                nextDraw.setHours(23, 0, 0, 0);

                return nextDraw;
            }
        }
    },
    filters: {
        moment: function (date) {
            return moment(date).format('LLLL');
        },
        decimalNumber: function (number) {

            if (number >= 1000000000) {
                let res = number / 1000000000;
                return (Math.round(res * 100) / 100).toFixed(2) + " Billion";
            } else if (number >= 1000000) {
                let res = number / 1000000;
                return (Math.round(res * 100) / 100).toFixed(2) + " Million";
            } else {
                return number.toString();
            }

        },
        setNumberUSA: function (number) {
            let setNumberUSA = number.toLocaleString("en-US");
            if (number != '') {
                return '$' + setNumberUSA;
            } else {
                return;
            }

        }
    }
};
</script>