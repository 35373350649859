<template>
    <div class="blog-detail">
        <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="3"
            color="#0FD8C9"
            v-if="isLoading"
        />

        <h2>{{ title }}</h2>
        <h3> {{ subtitle}} </h3>
        
        <div v-html="content" class="blog-post-content"></div>

        <p v-if="date">Posted:  {{ customFormatter(date) }}</p>

    </div>
</template>


<script>
import { HollowDotsSpinner } from 'epic-spinners'
import moment from 'moment';

export default{
    props: ['articleId'],
    components: {
        HollowDotsSpinner
    },
    data(){
        return{
            isLoading: true,
            title: '',
            subtitle: '',
            content: '',
            image: '',
            date: ''
        };
    },

    mounted(){
        this.getToken();
    },
    methods:{
        customFormatter(date) {
            return moment(date).format('LL');
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
            .then((token) => {
                this.fetchData(token);
            });

        },
        fetchData(token) {
            let apiToken = token,
                urlProtocol = this.getProtocol(),
                articleId = this.articleId;


            return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetById/"+articleId, {
                headers: {
                    'Authorization': 'Bearer '+apiToken,
                },
            })
            .then(response => response.json())
            .then(json => {
                this.isLoading = false;
                var res = json.data;
                // console.log(res)
                this.title = res.title;
                this.subtitle = res.subtitle;
                this.image1 = res.image1;
                this.content = res.content;
                this.date = res.date;
            })
            .catch(error => {
                // console.error("There was an error!", error);
                return this.errorMessage = error;
            });
        }
       
    }
}

</script>


<style scoped>


</style>