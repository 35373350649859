<template>
    <div>

        <MiddleArea></MiddleArea>

        <BottomArea></BottomArea>

    </div>
</template>


<script>
   // import moment from 'moment'
    import MiddleArea from './MiddleArea.vue';
    import BottomArea from './BottomArea.vue';



    export default {
        components: {
            MiddleArea,
            BottomArea
        },
        data: function () {
            return {
            }
        },
        mounted: function () {
        },
        methods: {
        },
        filters:{
        }
    };
</script>

<style lang="scss">
.highPayoutLandingHead{
   // background-image: url('../media/site-design/winning-numbers/HighPayout/highPayoutLandingHead_bkgnd.png');
    background-color: #239905;
    background-size: 864px 456px;
    background-repeat: no-repeat;
    padding: 2rem;

    p{
        padding-top: 0;
        padding-left: 40rem;
        color: #fff;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
    }
}


</style>