<template>
    <div class="draw-games-lp-container winning-numbers-wrapper">
        <div v-for="game in games" :key="game.Name" :class="'DrawNumbersWrap bg-draw winningNumbersItem ' + game.StringIdentifier">
            <!-- logo -->
            <a :href="'/games/draw-games/' + game.NodeAliasPath" class="drawNumbersLogo" :style="'background-image:url('+game.logo+')'">
                <img v-bind:src="game.GameLogoURL" v-bind:alt="game.Name" class="winNumGameLogo" />
            </a>

            <!-- <span v-if="game.numbers.errorMessage">{{game.numbers.errorMessage}}</span> -->
            <!-- For Identification ONLY -->
            <span>{{game.gameName}}</span>
            
            <div class="winningNumbersContent drawLandingPage">
                <span v-if="game.StringIdentifier === 'Pick3'">
                    <DrawNumbers v-if="pick3 === true" :numbers="game.Draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                </span>
                
                <span v-if="game.StringIdentifier === 'Pick4'">
                    <DrawNumbers v-if="pick4 === true" :numbers="game.Draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                </span>
                
                <span v-if="game.StringIdentifier === 'Pick5'">
                    <DrawNumbers v-if="pick5 === true" :numbers="game.Draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                </span>

                <DrawNumbers v-if="game.StringIdentifier === 'megamil'" :numbers="game.Draws[0]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'luckylife'" :numbers="game.Draws[0]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'pball'" :numbers="game.Draws[0]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'ClassicLotto'" :numbers="game.Draws[0]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'rollingcash5'" :numbers="game.Draws[0]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>

                <!-- MIDDAY before Evening -->
                <DrawNumbers v-if="game.StringIdentifier === 'Pick3' && pm === false" :numbers="game.Draws[0]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'Pick4' && pm === false" :numbers="game.Draws[0]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'Pick5' && pm === false" :numbers="game.Draws[0]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <!-- MIDDAY after Eveneing -->
                <DrawNumbers v-if="game.StringIdentifier === 'Pick3' && pm === true" :numbers="game.Draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'Pick4' && pm === true" :numbers="game.Draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'Pick5' && pm === true" :numbers="game.Draws[1]" :game="game" :evening="false" v-bind:key="game.gameID"></DrawNumbers>
                <!-- EVENING -->
                <DrawNumbers v-if="game.StringIdentifier === 'Pick3' && pm === true" :numbers="game.Draws[0]" :game="game" :evening="true" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'Pick4' && pm === true" :numbers="game.Draws[0]" :game="game" :evening="true" v-bind:key="game.gameID"></DrawNumbers>
                <DrawNumbers v-if="game.StringIdentifier === 'Pick5' && pm === true" :numbers="game.Draws[0]" :game="game" :evening="true" v-bind:key="game.gameID"></DrawNumbers>

                <div v-if="game.StringIdentifier === 'megamil' || game.StringIdentifier === 'pball' || game.StringIdentifier === 'ClassicLotto' || game.StringIdentifier === 'rollingcash5'" class="winningNumberJackpots">
                    <div v-if="game.StringIdentifier === 'ClassicLotto'" class="kicker-number">
                        <span class="wnLbl">KICKER: </span>
                        <span class="wnVal">{{ game.Draws[0].ExtendedNumbers.map(item => `${item.Value}`).toString().replaceAll(',','') }}</span>
                    </div>


                    <div class="nextJackpot">
                        <span class="wnLbl">Next Jackpot: </span>
                        <span class="wnVal" v-if="game.Draws[0].NextPrizePool <= 1000000">${{game.Draws[0].NextPrizePool.toLocaleString()}}</span>
                        <span class="wnVal" v-if="game.Draws[0].NextPrizePool > 1000000 && game.Draws[0].NextPrizePool < 1000000000">${{(game.Draws[0].NextPrizePool / 1000000).toLocaleString()}} MILLION</span>
                        <span class="wnVal" v-if="game.Draws[0].NextPrizePool >= 1000000000">${{(game.Draws[0].NextPrizePool / 1000000000).toLocaleString()}} BILLION</span>
                    </div>
                    <div v-if="game.StringIdentifier != 'rollingcash5'" class="cashOptionValue">
                        <span class="wnLbl">Cash Option Value: </span>
                        <span class="wnVal" v-if="game.Draws[0].NextPDCV <= 1000000">${{game.Draws[0].NextPDCV.toLocaleString()}}</span>
                        <span class="wnVal" v-if="game.Draws[0].NextPDCV > 1000000 && game.Draws[0].NextPDCV < 1000000000">${{(game.Draws[0].NextPDCV / 1000000).toLocaleString()}} MILLION</span>
                        <span class="wnVal" v-if="game.Draws[0].NextPDCV >= 1000000000">${{(game.Draws[0].NextPDCV / 1000000000).toLocaleString()}} BILLION</span>
                    </div>
                </div>

                <div v-if="game.StringIdentifier === 'luckylife'" class="winningNumberJackpots">
                    <div class="nextJackpot">
                        <span class="wnLbl">Top Prize: </span>
                        <span class="wnVal">$1,000 a day for life.</span>
                    </div>
                    <div class="cashOptionValue">
                        <span class="wnLbl">Cash Option Value: </span>
                        <span class="wnVal">$5.8 MILLION</span>
                    </div>
                </div>

                <a v-if="game.StringIdentifier === 'megamil'" href="/winning-numbers/check-your-numbers#tab0" class="button pastDraws"><span>Past Draws</span></a>
                <a v-if="game.StringIdentifier === 'luckylife'" href="/winning-numbers/check-your-numbers#tab2" class="button pastDraws"><span>Past Draws</span></a>
                <a v-if="game.StringIdentifier === 'pball'" href="/winning-numbers/check-your-numbers#tab1" class="button pastDraws"><span>Past Draws</span></a>
                <a v-if="game.StringIdentifier === 'ClassicLotto'" href="/winning-numbers/check-your-numbers#tab3" class="button pastDraws"><span>Past Draws</span></a>
                <a v-if="game.StringIdentifier === 'rollingcash5'" href="/winning-numbers/check-your-numbers#tab4" class="button pastDraws"><span>Past Draws</span></a>
                <a v-if="game.StringIdentifier === 'Pick3'" href="/winning-numbers/check-your-numbers#tab5" class="button pastDraws"><span>Past Draws</span></a>
                <a v-if="game.StringIdentifier === 'Pick4'" href="/winning-numbers/check-your-numbers#tab6" class="button pastDraws"><span>Past Draws</span></a>
                <a v-if="game.StringIdentifier === 'Pick5'" href="/winning-numbers/check-your-numbers#tab7" class="button pastDraws"><span>Past Draws</span></a>
                <span class="drawTimesLabel">
                    {{game.Name}} drawings: <span class="drawTimes">{{game.DrawSchedule}}</span>
                </span>
            </div>
        </div>
    </div>
</template>


<script>
    import {mapActions, mapGetters} from 'vuex';
    import moment from 'moment';
    import DrawNumbers from '../../ViewComponents/DrawNumbers/DrawNumbers.vue';

    export default {
        components: {
            DrawNumbers
        },
        data: function () {
            return {
                token: '',
                date: '',
                games: [],
                getters: [
                    this.getPick3,
                    this.getPick4,
                    this.getMega
                ],
                pick3: false,
                pick4: false,
                pick5: false,
                pm: false
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            /*
                let key = {
                    'drawGames': true,
                    'scratchGames': true,
                    'progressive': false
                }
            */
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    setTimeout(() => {
                        // let games = JSON.parse(localStorage.getItem('olcDrawNumbers'));
                        let games = document.getElementById('cmsNumbers').value;
                        games = JSON.parse(games);

                        for (let i = 0; i < games.length; i++) {
                            let game = games[i],
                                draw1 = game.Draws[0],
                                draw2 = game.Draws[1]; 
                            if (game.identifier === 'pick3' || game.StringIdentifier === 'Pick3') {
                                let draw1Date = moment(draw1.DrawDate),
                                    draw2Date = moment(draw2.DrawDate);

                                draw1Date.isSame(draw2Date, 'date') === true ? this.pm = true : this.pm = false;

                                // console.log(draw1Date)
                                // console.log(draw2Date)

                                // if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                                //     this.pick3 = true
                                // } else {
                                //     this.pick3 = false;
                                // }
                            }

                            // if (game.identifier === 'pick4' || game.StringIdentifier === 'Pick4') {
                            //     let draw1Date = new Date(draw1.DrawDate),
                            //         draw2Date = new Date(draw2.DrawDate);

                            //     console.log(draw1Date.getDay() + ' -- ' + draw2Date.getDay())

                            //     if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                            //         this.pick4 = true
                            //     } else {
                            //         this.pick4 = false;
                            //     }
                            // }

                            // if (game.identifier === 'pick5' || game.StringIdentifier === 'Pick5') {
                            //     let draw1Date = new Date(draw1.DrawDate),
                            //         draw2Date = new Date(draw2.DrawDate);

                            //     console.log(draw1Date.getDay() + ' -- ' + draw2Date.getDay())

                            //     if (draw1Date.getYear() === draw2Date.getYear() && draw1Date.getDay() === draw2Date.getDay() && draw1Date.getMonth() === draw2Date.getMonth()) {
                            //         this.pick5 = true
                            //     } else {
                            //         this.pick5 = false;
                            //     }
                            // }
                        }

                        //Sort Games
                        let gameSorted = [];

                        for (let i = 0; i < games.length; i++) {
                            let game = games[i];

                            if (game.identifier === 'mega' || game.StringIdentifier === 'megamil') {
                                game.order = 0;
                                game.url = '/games/draw-games/mega-millions';
                            } else if (game.identifier === 'power' || game.StringIdentifier === 'pball') {
                                game.order = 1;
                                game.url = '/games/draw-games/powerball';
                            } else if (game.identifier === "lucky" || game.StringIdentifier === 'luckylife') {
                                game.order = 2;
                                game.url = '/games/draw-games/lucky-for-life';
                            } else if (game.identifier === "classic" || game.StringIdentifier === 'ClassicLotto') {
                                game.order = 3;
                                game.url = '/games/draw-games/classic-lotto';
                            } else if (game.identifier === "rolling5" || game.StringIdentifier === 'rollingcash5') {
                                game.order = 4;
                                game.url = '/games/draw-games/gimme-5';
                            } else if (game.identifier === "pick3" || game.StringIdentifier === 'Pick3') {
                                game.order = 5;
                                game.url = '/games/draw-games/pick-3';
                            } else if (game.identifier === "pick4" || game.StringIdentifier === 'Pick4') {
                                game.order = 6;
                                game.url = '/games/draw-games/pick-4';
                            } else if (game.identifier === "pick5" || game.StringIdentifier === 'Pick5') {
                                game.order = 7;
                                game.url = '/games/draw-games/pick-5';
                            }

                            gameSorted.push(game);
                        }

                        gameSorted.sort((a, b) => a.order - b.order)

                        this.games = gameSorted;
                        // console.log(this.games)
                    }, 1500);
                })
            },
            returnGames: () => {
                // let gameLength = this.getGames();
                        
                // this.games = this.getStorageNumbers();

                // while (this.games.length < gameLength.length) {
                    // setTimeout(() => {
                        return this.games = this.getStorageNumbers();
                    // }, 500);

                //     console.log(this.games);
                // }
            },
            ...mapActions ({
                checkLocalNumbers: 'getNumbers/getOLCDrawGames',
                setStorage: 'getNumbers/setStorage',
                fetchToken: 'drawNumbers/getToken',
                fetchData: 'drawNumbers/fetchData',
                fetchLogo: 'drawNumbers/fetchLogo'
            }),
            ...mapGetters ({
                getGames: 'drawNumbers/getGames',
                getDrawGame: 'drawNumbers/getDrawGame',
                getStorageNumbers: 'getNumbers/getStorageNumbers'
            })
        },
        watch: {
            
        }
    };
</script>

<style lang="scss" scoped>
    a.button:hover {
        display: block;
    }
    .pastDraws {
        span {
            position: relative;
            top: 9px;
        }
    }

    footer {
        display: none;
    }

    .kicker-number {
        font-size: 2.4rem;

        .wnLbl {
            width: 50%;
            display: inline-block;

            @include breakpoint(large) {
                width: 40%;
            }
        }

        .wnVal {
           // color: #be0f34 !important;
        }
    }
</style>