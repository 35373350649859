<template>
    <div class="winningNumbersContent" v-if="!loading">
        <span class="winNumDate">{{ customFormatter(dateData) }}</span>
        <!-- time-of-day (DrawModifier: midday = 1, evening = 2) -->
        <span class="pickGame">MIDDAY</span>
        <ul class="winningNumbersList" v-if="!pm">
            <li v-for="number in numbersData[0].Numbers" :key="number.Id" class="winNumBall">
                {{ number.Value }}
            </li>
        </ul>
        <ul class="winningNumbersList" v-if="pm">
            <li v-for="number in numbersData[1].Numbers" :key="number.Id" class="winNumBall">
                {{ number.Value }}
            </li>
        </ul>
        <span class="pickGame" v-if="pm">EVENING</span>
        <span class="winNumToD">{{ numbersData.modifier | pickToD }}</span>
        <ul class="winningNumbersList" v-if="pm">
            <li v-for="number in numbersData[0].Numbers" :key="number.Id" class="winNumBall">
                {{ number.Value }}
            </li>
        </ul>
        
    </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['numbers', 'date', 'timeDay'],
    components: {
    },
    data() {
        return {
            numbersData: this.numbers,
            dateData: this.date,
            timeDays: this.timeDay,
            pm: false,
            loading: true
        }
    },
    mounted: function () {
    },
    methods: {
        customFormatter(date) {
            return moment(new Date(date)).format('MM/DD/YYYY');
        },
        setNumbersData() {
            let draws = this.numbers,
                draw1 = draws[0],
                draw2 = draws[1],
                draw1Date = moment(draw1.DrawDate),
                draw2Date = moment(draw2.DrawDate);

                //console.log(draws)

                let pm = '';

                draw1Date.isSame(draw2Date, 'date') === true ? this.pm = true : this.pm = false;
            this.numbersData = this.numbers;
            this.loading = false;
        },
        setDate() {
            this.dateData = this.date;
        }
    },
    watch: {
        numbers() {
            this.setNumbersData();
            this.setDate();
        }
    }
}
</script>

<style lang="css">
  
</style>