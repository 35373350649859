
<template>
    <span>
        <!-- If Regular -->
        <ul class="eAppListMenuUL eAppLeftMenu" id="menuElem">
            <li v-if="showWelcome" class="eAppLeftMenuItem" :class="{ active: activeLink == 'welcome'}">
                <a v-if="chain" href="/eapplications/chain" class="eAppLeftMenuLink">Welcome</a>
                <a v-else href="/eapplications" class="eAppLeftMenuLink">Welcome</a>
            </li>
            <li v-if="showBusiness" class="eAppLeftMenuItem" :class="{ active: activeLink == 'business'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('business-landing')">Business Information</a>
            </li>
            <li v-if="showEft" class="eAppLeftMenuItem" :class="{ active: activeLink == 'eft'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('eft')">Electronic Funds Transfer</a>
            </li>
            <li v-if="showTax" class="eAppLeftMenuItem" :class="{ active: activeLink == 'tax'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('tax')">Federal Tax Information</a>
            </li>
            <li v-if="showBackground" class="eAppLeftMenuItem" :class="{ active: activeLink == 'background'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('background')">Background Check</a>
            </li>
            <li v-if="showEquipment" class="eAppLeftMenuItem" :class="{ active: activeLink == 'equipment'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('equipment')">Lottery Equipment</a>
            </li>
            <li v-if="showLiability" class="eAppLeftMenuItem" :class="{ active: activeLink == 'liability'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('liability')">Liability Deposit</a>
            </li>
            <li v-if="showLicensing" class="eAppLeftMenuItem" :class="{ active: activeLink == 'licensing'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('licensing')">Licensing</a>
            </li>
            <li v-if="showChains" class="eAppLeftMenuItem" :class="{ active: activeLink == 'chains'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('chains')">Chain Locations</a>
            </li>
            <li v-if="showReview" class="eAppLeftMenuItem" :class="{ active: activeLink == 'review'}">
                <a class="eAppLeftMenuLink" @click="sectionClick('review')">{{ reviewBtnTxt }}</a>
            </li>
        </ul>
    </span>
</template>
<script>
    export default {
        props: {
            applicationJson: String,
            userRole: String,
            visibleSection: String,
            readOnly: Boolean
        },
        data() {
            return {
                activeLink: '',
                application: {},
                showWelcome: true,
                showBusiness: false,
                showEft: false,
                showTax: false,
                showLiability: false,
                showLicensing: false,
                showEquipment: false,
                showChains: false,
                showBackground: false,
                showReview: false,
                chain: false,
                acodeChain: false,
                fcodeChain: false,
                masterChain: false,
                isStaff: true,
                districtRep: false,
                regionalManager: false,
                centralSalesManager: false,
                chainSalesRep: false,
                eftReview: false,
                licensingManager: false,
                security: false,
                applicant: false,
                principal: false,
                reviewBtnTxt: 'Final Review',
                appStatus: 1
            }
        },
        mounted: function () {
            this.init();
            this.activeLink = this.visibleSection;
        },
        methods: {
            init() {
                // Set Keys
                this.application = JSON.parse(this.applicationJson);
                this.chain = this.application.applicationIspartOfChain || this.application.applicationIsMasterChain;
                this.existingChain = this.application.applicationIspartOfChain
                this.acodeChain = this.application.applicationChainIsFranchise;
                this.fcodeChain = this.application.applicationChainIsCoorporate;
                this.masterChain = this.application.applicationIsMasterChain;
                this.appStatus = this.application.applicationStatus;
                console.log(this.application)

                // Set Current User Role
                switch (this.userRole) {
                    case 'eAppDistrictRep':
                        this.districtRep = true;
                        break;
                    case 'eApp-RegionalManager':
                        this.regionalManager = true;
                        break;
                    case 'eApp-CentralSalesManager':
                        this.centralSalesManager = true;
                        break;
                    case 'eApp-ChainSalesRep':
                        this.chainSalesRep = true;
                        break;
                    case 'eApp-EFTReviewer':
                        this.eftReview = true;
                        break;
                    case 'eApp-CentralLicensingManager':
                        this.licensingManager = true;
                        break;
                    case "eApp-Security":
                    case "eApp-RegionalSecurity":
                        this.security = true;
                        break;
                    case 'Retailer':
                        this.reviewBtnTxt = 'Review Application';
                        break;
                    default:
                        this.applicant = true;
                        this.reviewBtnTxt = 'Review Application';
                        break;
                }

                this.principal = this.isUserInRole('eAppPrincipal');
                
                let user = this.getCookie("TokenInfo"),
                    userID = user.User.UserId;

                // Filter by Principal Owner
                if (this.principal) {
                    if (userID === this.application.createdBy) {
                        // Begin Filter by Creation -or- Review
                        if (this.chain === true) {
                            this.filterChain();
                        } else {
                            this.filterRegular();
                        }
                    } else {
                        this.showWelcome = true;
                        this.showBusiness = false;
                        this.showBackground = true;
                        this.showReview = false;
                    }
                    // everyone else must be staff role or non-principal creator
                } else if (this.chain === true) {
                    this.filterChain();
                } else {
                    this.filterRegular();
                }
                
            },
            filterChain() {
                // Creation -or- Review
                if (this.appStatus === 1) { // Creation
                    if (this.acodeChain === true && this.masterChain === true) { // ACode Master Chain
                        // BI
                        this.showBusiness = true;
                        // BC
                        this.showBackground = true;
                        // RA
                        this.showReview = true;
                    } else if (this.fcodeChain === true && this.masterChain === true) { // FCode Master Chain
                        // BI
                        this.showBusiness = true;
                        // EFT
                        this.showEft = true;
                        // W9
                        this.showTax = true;
                        // BC
                        this.showBackground = true;
                        // this.showChains = true;
                        // RA
                        this.showReview = true;
                    } else if (this.acodeChain === true && this.masterChain === false) { // ACode Location
                        // BI
                        this.showBusiness = true;
                        // EFT
                        this.showEft = true;
                        // W9
                        this.showTax = true;
                        // RA
                        this.showReview = true;
                    } // FCode Location is done during the FCode Master Chain Review
                } else {
                    // Review
                    this.showWelcome = false;
                    console.log(this.masterChain)
                    console.log(this.existingChain)
                    if (this.acodeChain === true) { // ACode Filter
                    console.log('ACode')
                        if (this.masterChain === true && this.existingChain === false) {
                            this.filterACodeMaster(); // Master Chain
                        } else {
                            this.filterACodeLocation(); // Location
                        }
                    } else if (this.fcodeChain === true) { // FCode Filter
                        if (this.masterChain && this.existingChain === false) {
                            this.filterFCodeMaster(); // Master Chain
                        } else {
                            this.filterFCodeLocation(); // Location
                        }
                    }
                }
            },
            filterRegular() {
                // Creation -or- Review
                if (this.appStatus === 1) {
                    // Creation
                        this.showWelcome - true;
                        // BI
                        this.showBusiness = true;
                        // EFT
                        this.showEft = true;
                        // W9
                        this.showTax = true;
                        // BC
                        this.showBackground = true;
                        // RA
                        this.showReview = true;
                } else {
                    // Review
                    this.showWelcome = false;
                    if (this.districtRep === true) { // Chain Sales Rep
                        this.showBusiness = true;
                        this.showEquipment = true;
                        this.showLiability = true;
                        this.appStatus === 2 ? this.showReview = true : false;
                        this.showReview = true;
                    } else if (this.chainSalesRep === true) { // Chain Sales Rep
                        this.showBusiness = true;
                        this.showEquipment = true;
                        this.showLiability = true;
                        this.appStatus === 2 ? this.showReview = true : false;
                        this.showReview = true;
                    } else if (this.districtRep === true) {
                        this.showBusiness = true;
                        this.showEft = true;
                        this.showTax = true;
                        this.showEquipment = true;
                        this.showLiability = true;
                        this.appStatus === 2 ? this.showReview = true : false;
                        this.showReview = true;
                    } else if (this.regionalManager === true) {
                        this.showBusiness = true;
                        this.showEft = true;
                        this.showTax = true;
                        this.showEquipment = true;
                        this.showLiability = true;
                        this.appStatus === 3 || this.appStatus === 7 ? this.showReview = true : false;
                        this.showReview = true;
                    } else if (this.eftReview === true) { // EFT Reviewer {
                        this.showBusiness = true;
                        this.showEft = true;
                        this.appStatus === 3 || this.appStatus === 4 || this.appStatus === 7 ? this.showReview = true : false;
                        this.showReview = true;
                    } else if (this.security === true) { // Security
                        this.showBusiness = true;
                        this.showBackground = true;
                        this.appStatus === 3 || this.appStatus === 4 || this.appStatus === 7 ? this.showReview = true : false;
                        this.showReview = true;
                    } else if (this.licensingManager === true) { // Licensing
                        this.showBusiness = true;
                        this.showEft = true;
                        this.showTax = true;
                        this.showBackground = true;
                        this.showEquipment = true;
                        this.showLiability = true;
                        this.showLicensing = true;
                        this.appStatus === 5 || this.appStatus === 14 || this.appStatus === 7 ? this.showReview = true : false;
                        this.showReview = true;
                    } else if (this.centralSalesManager === true) { // Central Sales Manager
                        this.showBusiness = true;
                        this.showEft = true;
                        this.showBackground = true;
                        this.showEquipment = true;
                        this.showLiability = true;
                        this.showLicensing = true;
                        this.appStatus === 6 ? this.showReview = true : false;
                        this.showReview = true;
                    } else {
                        // catching all non-staff roles

                        this.showWelcome = true;
                        this.showBusiness = true;
                        this.showEft = true;
                        this.showTax = true;
                        this.showBackground = true;
                        
                        // if not submitted or if rejected
                        this.appStatus === 0 || this.appStatus === 8 ? this.showReview = true : this.showReview = false;
                        
                    }
                }
            },
            filterACodeMaster() { // Used only for Review ACode Master Chain
                this.reviewBtnTxt = "Final Review";
                console.log('ACode Master')
                
                // Filter by Role
                if (this.chainSalesRep === true || this.regionalManager === true) { // Chain Sales Rep
                    this.showBusiness = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    // this.showReview = true;
                    this.appStatus === 2 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.eftReview === true) { // EFT Reviewer {
                    this.showBusiness = true;
                    // this.showReview = true;
                    this.appStatus === 3 || this.appStatus === 4 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.security === true) { // Security
                console.log('AO')
                    this.showBusiness = true;
                    this.showBackground = true;
                    // this.showReview = true;
                    this.appStatus === 3 || this.appStatus === 4 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.licensingManager === true) { // Licensing
                    this.showBusiness = true;
                    this.showEft = true;
                    this.showTax = true;
                    this.showBackground = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    this.showLicensing = true;
                    // this.showReview = true;
                    this.appStatus === 5 || this.appStatus === 14 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.centralSalesManager === true) { // Central Sales Manager
                    this.showBusiness = true;
                    this.showBackground = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    this.showLicensing = true;
                    // this.showReview = true;
                    this.appStatus === 6 ? this.showReview = true : false;
                    this.showReview = true;
                }

                if (this.chainSalesRep === false && this.regionalManager === false && this.districtRep === false && this.eftReview === false && this.security === false && this.licensingManager === false && this.centralSalesManager === false && this.appStatus > 1) {
                    this.showWelcome = true;
                    this.showBusiness = true;
                    this.showBackground = true;
                    this.showReview = false;
                }
            },
            filterACodeLocation() { // ACode Locations
                this.reviewBtnTxt = "Final Review";
                
                // Filter by Role
                if (this.chainSalesRep === true || this.regionalManager === true) { // Chain Sales Rep
                    this.showBusiness = true;
                    this.showTax = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    // this.showReview = true;
                    this.appStatus === 2 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.eftReview === true) { // EFT Reviewer {
                    this.showBusiness = true;
                    this.showEft = true;
                    // this.showReview = true;
                    this.appStatus === 3 || this.appStatus === 4 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.security === true) { // Security
                    this.showBusiness = true;
                    this.showBackground = true;
                    // this.showReview = true;
                    this.appStatus === 3 || this.appStatus === 4 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.licensingManager === true) { // Licensing
                    this.showBusiness = true;
                    this.showEft = true;
                    this.showTax = true;
                    this.showBackground = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    this.showLicensing = true;
                    // this.showReview = true;
                    this.appStatus === 5 || this.appStatus === 14 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.centralSalesManager === true) { // Central Sales Manager
                    this.showBusiness = true;
                    // this.showReview = true;
                    this.appStatus === 6 ? this.showReview = true : false;
                    this.showReview = true;
                }

                if (this.chainSalesRep === false && this.regionalManager === false && this.districtRep === false && this.eftReview === false && this.security === false && this.licensingManager === false && this.centralSalesManager === false && this.appStatus > 1) {
                    this.showWelcome = true;
                    this.showBusiness = true;
                    this.showEft = true;
                    this.showTax = true;
                    this.showReview = false;
                }
            },
            filterFCodeMaster() { // Used only for Review FCode Master Chain
                this.reviewBtnTxt = "Final Review";
                console.log("FCode Master")
                
                // Filter by Role
                if (this.chainSalesRep === true || this.regionalManager === true) { // Chain Sales Rep
                    this.showBusiness = true;
                    this.showTax = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    this.showChains = true;
                    // this.showReview = true;
                    this.appStatus === 2 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.eftReview === true) { // EFT Reviewer {
                    this.showBusiness = true;
                    this.showEft = true;
                    // this.showReview = true;
                    this.appStatus === 3 || this.appStatus === 4 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.security === true) { // Security
                    this.showBusiness = true;
                    this.showBackground = true;
                    // this.showReview = true;
                    this.appStatus === 3 || this.appStatus === 4 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.licensingManager === true) { // Licensing
                    this.showBusiness = true;
                    this.showEft = true;
                    this.showTax = true;
                    this.showBackground = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    this.showLicensing = true;
                    // this.showReview = true;
                    this.appStatus === 5 || this.appStatus === 14 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.centralSalesManager === true) { // Central Sales Manager
                    this.showBusiness = true;
                    this.showEft = true;
                    this.showBackground = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    this.showLicensing = true;
                    // this.showReview = true;
                    this.appStatus === 6 ? this.showReview = true : false;
                    this.showReview = true;
                }
                if (this.chainSalesRep === false && this.regionalManager === false && this.districtRep === false && this.eftReview === false && this.security === false && this.licensingManager === false && this.centralSalesManager === false && this.appStatus > 1) {
                    this.showWelcome = true;
                    this.showBusiness = true;
                    this.showEft = true;
                    this.showTax = true;
                    this.showBackground = true;
                    this.showReview = false;
                }
            },
            filterFCodeLocation() { // Used only for Review FCode Location
                this.reviewBtnTxt = "Final Review";
                console.log('F Code Location')
                
                // Filter by Role
                if (this.chainSalesRep === true || this.regionalManager === true) { // Chain Sales Rep
                    this.showBusiness = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    // this.showReview = true;
                    this.appStatus === 2 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.licensingManager === true) { // Licensing
                    this.showBusiness = true;
                    this.showEft = true;
                    this.showTax = true;
                    this.showBackground = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    this.showLicensing = true;
                    // this.showReview = true;
                    this.appStatus === 5 || this.appStatus === 14 || this.appStatus === 7 ? this.showReview = true : false;
                    this.showReview = true;
                } else if (this.centralSalesManager === true) { // Central Sales Manager
                    this.showBusiness = true;
                    this.showEft = true;
                    this.showBackground = true;
                    this.showEquipment = true;
                    this.showLiability = true;
                    this.showLicensing = true;
                    // this.showReview = true;
                    this.appStatus === 6 ? this.showReview = true : false;
                    this.showReview = true;
                }

                if (this.chainSalesRep === false && this.regionalManager === false && this.districtRep === false && this.eftReview === false && this.security === false && this.licensingManager === false && this.centralSalesManager === false && this.appStatus > 1) {
                    this.showWelcome = true;
                    this.showReview === false;
                }
            },
            sectionClick(key) {
                this.$emit('sectionClick', key);
            }
        }
    }
</script>
