<!-- ~/Views/Home/Home-Numbers.vue -->

<template>
    <div class="home_winningNumbers winningNumbers_GroupList">
        
        <div class="test-settings">
            <p>Settings styles test OLC</p>
        </div>

        <div class="winningNumbersWrap">
            <template lang="html">
                <div class='wrapper'>











                    <!-- THIS IS NOT USED - ABORT -->




















                    <tabs>
                        <tab title="Draw Games">
                            <div class="grid-x grid-margin-x small-up-2 medium-up-2 large-up-4">
                                <div class="cell"><Pick3></Pick3></div>
                                <div class="cell"><Pick4></Pick4></div>
                                <div class="cell"><MegaMillions></MegaMillions></div>
                            </div>
                        </tab>
                        <tab title="Progressive Jackpot Games">


                        </tab>
                        <tab title="KENO">

                        </tab>
                        <tab title="The Lucky One">

                        </tab>
                    </tabs>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Tab from '../../ViewComponents/Tabs/Tab.vue';
import Tabs from '../../ViewComponents/Tabs/Tabs.vue';
import Pick3 from '../Games/Pick3/Pick3-WinningNumbers.vue';
import Pick4 from '../Games/Pick4/Pick4-WinningNumbers.vue';
import MegaMillions from '../Games/MegaMillions/MegaMillions-WinningNumbers';

    export default {
        components: {
            Tab,
            Tabs,
            Pick3,
            Pick4,
            MegaMillions
        },
        data: function() {
            return {
            }
        },
        mounted: function() {
        },
        methods: {
            
        },
        computed: {
        }
    };
</script>


<style lang="scss" scoped>
    
</style>