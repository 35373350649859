<template>
    <div class="addEditListWrap businessInfoStatus cf" v-if="!isLoading">
        <h3>Business Information</h3>
        <div class="addEditList cf">
            <span v-if="businessLanding.businessRelatedFieldsPopulated" class="icon-Status statusHours"></span>
            <label v-if="businessLanding.businessRelatedFieldsPopulated">Business<br> <span>{{businessLanding.businessLegalName}}</span></label>
            <label v-else>No Business Entered</label>
            <a v-if="businessLanding.businessRelatedFieldsPopulated" @click="stepClick('business-1')" class='button editBtn'>{{businessLanding.editText}}</a>
            <a v-else @click="stepClick('business-1')" class='button'>Add Business</a>
        </div>
        <div class="addEditList cf" v-if="businessLanding.isBusinessBeingTransfer">
            <span class="icon-Status statusSOBInfo"></span>
            <label>Sale of Business</label> <a href="?step=sob" class="button">View</a>
        </div>
        <div class="addEditList cf">
            <span v-if="businessLanding.hoursPopulated" class="icon-Status statusHours"></span>
            <label v-if="businessLanding.hoursPopulated">Business Hours</label>
            <label v-else>No Hours Entered</label>
            <a v-if="businessLanding.hoursPopulated" @click="stepClick('business-3')" class='button editBtn'>{{businessLanding.editText}}</a>
            <a v-else @click="stepClick('business-3')" class='button'>Add Hours</a>
        </div>
        <div v-if="!businessLanding.isACodeChainLocation && !businessLanding.isFCodeChainLocation" class="addEditList cf">
            <span v-if="validPrincipals && validPrincipalPercentage" class="icon-Status statusPrincipals"></span>
            <label v-if="validPrincipals && validPrincipalPercentage">Principals</label>
            <label v-if="!validPrincipalPercentage">Principals Need Attention</label>
            <label v-if="!validPrincipals">No Principals Entered</label>
            <a v-if="validPrincipals" @click="stepClick('principals')" class="button">{{businessLanding.editText}}</a>
            <a v-else @click="stepClick('principals')" class="button">Add Principals</a>

        </div>
        <div class="addEditList cf">
            <span v-if="businessLanding.contactInformationPopulated" class="icon-Status statusContact"></span>
            <label v-if="businessLanding.contactInformationPopulated">Contact<br> <span>{{businessLanding.contactName}}</span></label>
            <label v-else>No Contact Entered</label>
            <a v-if="businessLanding.contactInformationPopulated" @click="stepClick('contact')" class="button">{{businessLanding.editText}}</a>
            <a v-else @click="stepClick('contact')" class="button">Add Contact</a>
        </div>
        <div class="btn-actions">
            <input type="button" name="" @click="onNextClick()" :value="nextButtonText" class=" btn-primary btnNext" title="Save the document and proceed to next page">
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            appStatus: Number
        },
        data() {
            return {
                editText: 'New Application',
                userId: 0,
                token: '',
                eappId: '',
                businessLanding: {},
                isLoading: true,
                validPrincipals: false,
                validPrincipalPercentage: true,
                nextButtonText: 'Electronic Funds Transfer',
            }
        },
        mounted: function () {
            this.isLoading = true;
            this.getToken();

        },
        computed:
        {
            BUSINESS_LANDING_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/user/` + this.userId + `/eapplication/` + this.eappId + `/businesslanding`;
            }
        },
        methods: {
            getToken() {
                let uId = this.getLoggedInUserId();
                if (uId) {
                        this.userId = uId;
                        this.getAPItoken()
                            .then((token) => {
                                this.token = token;
                                this.tryGetFromQueryString().then(() => {
                                    this.fetchData(token);
                                });
                            });
                    }
            },
            async fetchData(token) {
                this.isLoading = true;
                this.token = token
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json"
                    },
                };
                this.businessLanding = await (await fetch(this.BUSINESS_LANDING_API_URL, appsRequestOptions)).json();
                if (this.appStatus == 1 || this.appStatus == 8) {
                    this.businessLanding.editText = "Edit";
                }
                this.validPrincipals = this.businessLanding.requiredPrincipalCreated;
                if (this.validPrincipals == true) {
                    this.validPrincipalPercentage = this.businessLanding.principalPercentageFieldCorrect;
                }
                if (this.businessLanding.isACodeChain) {
                    if (this.businessLanding.isACodeChainLocation === false) {
                        this.nextButtonText = 'Background Check';
                    } else {
                        this.nextButtonText = 'Electronic Funds Transfer';
                    }
                }
                this.isLoading = false;
            },
            tryGetFromQueryString() {

                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('id')) {
                    this.eappId = urlParams.get('id');
                }

                return Promise.resolve();
            },
            stepClick(step) {
                
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                urlParams.set('step', step);
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            onNextClick() {
                if (this.businessLanding.isACodeChain) {
                    this.stepClick('background');
                }
                else {
                    this.stepClick('eft');
                }
            }
        }
    }
</script>