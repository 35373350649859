<template>
    <div>
        <!-- everybody sees this -->
        <!--next click added-->
        <StaffBusinessReview v-if="visibleSection == 'business'" :userRole="userRole"
                             :applicationJson="applicationJson"
                             :readOnly="readOnly"
                             @onNextAdminClick="onNextAdminClick"></StaffBusinessReview>

        <!-- EFT & Central Sales only -->
        <!--next click added-->
        <StaffEFTReview v-if="visibleSection == 'eft' && (userRole == 'eApp-RegionalManager' || userRole == 'eApp-EFTReviewer' || userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-CentralLicensingManager')"
                        :applicationJson="applicationJson"
                        :userRole="userRole"
                        :eftToken="staffToken"
                        :readOnly="readOnly"
                        @onNextAdminClick="onNextAdminClick"></StaffEFTReview>

        <!-- eApp-CentralLicensingManager eApp-CentralSalesManager-->
        <!--next click added-->
        <StaffTaxReview v-if="visibleSection == 'tax' && (userRole == 'eApp-RegionalManager' || userRole == 'eApp-CentralLicensingManager' || userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-ChainSalesRep')"
                        :applicationJson="applicationJson"
                        :userRole="userRole"
                        :readOnly="readOnly"
                        @onNextAdminClick="onNextAdminClick"></StaffTaxReview>

        <!-- Background Check -->
        <!--next click added-->
        <StaffBackgroundCheck v-if="visibleSection == 'background' && (userRole == 'eApp-CentralLicensingManager' || userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-RegionalSecurity' || userRole == 'eApp-Security' || userRole == 'eApp-CentralLicensingStaff')"
                              :userId="userId"
                              :userRole="userRole"
                              :applicationJson="applicationJson"
                              :bgToken="staffToken"
                              :readOnly="readOnly"
                              @onNextAdminClick="onNextAdminClick"></StaffBackgroundCheck>

        <!-- Liability Deposit -->
        <!--next click added-->
        <StaffLiabilityDeposit v-if="visibleSection == 'liability' && (userRole == 'eApp-RegionalManager' || userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-ChainSalesRep' || userRole == 'eAppDistrictRep' || userRole == 'eApp-CentralLicensingManager')"
                               :userId="userId"
                               :userRole="userRole"
                               :applicationJson="applicationJson"
                               :liabilityToken="staffToken"
                                :readOnly="readOnly"
                               @onNextAdminClick="onNextAdminClick"></StaffLiabilityDeposit>

        <!-- Lottery Equipment -->
        <StaffLotteryEquipement v-if="visibleSection == 'equipment' && (userRole == 'eApp-RegionalManager' || userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-ChainSalesRep' || userRole == 'eAppDistrictRep' || userRole == 'eApp-CentralLicensingManager')"
                                :userId="userId"
                                :userRole="userRole"
                                :staffEquipmentToken="staffToken"
                                :applicationJson="applicationJson"
                                :readOnly="readOnly"
                                @onNextAdminClick="onNextAdminClick"></StaffLotteryEquipement>

        <!-- Licensing Form -->
        <StaffLicensing v-if="visibleSection == 'licensing' && (userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-CentralLicensingManager')"
                        :userId="userId"
                        :userRole="userRole"
                        :licensingToken="staffToken"
                        :applicationJson="applicationJson"
                        :readOnly="readOnly"
                        @onNextAdminClick="onNextAdminClick"></StaffLicensing>

        <!-- Final Review - everybody sees this -->
        <StaffFinalReview v-if="visibleSection == 'review'"
                        :userId="userId" 
                        :userRole="userRole" 
                        :finalToken="staffToken" 
                        @edit="editReview"
                        :applicationJson="applicationJson"></StaffFinalReview>



        <!-- Chains -->
        <StaffChainReview v-if="visibleSection == 'chains' && showCorpLocations"
                          :userId="userId"
                          :userRole="userRole"
                          :chainToken="staffToken"
                          :applicationJson="applicationJson"></StaffChainReview>

        

    </div>
</template>


<script>
    // need to create a CorporateLocations component and import here
    
    import StaffBusinessReview from './review-staff-business.vue';
    import StaffTaxReview from './review-staff-tax.vue';
    import StaffEFTReview from './review-staff-eft.vue';
    import StaffBackgroundCheck from './review-staff-background-check.vue';
    import StaffLiabilityDeposit from './review-staff-liability.vue';
    import StaffLotteryEquipement from './review-staff-equipment.vue';
    import StaffLicensing from './review-staff-licensing.vue';
    import StaffFinalReview from './review-staff-final.vue';
    import StaffChainReview from './review-staff-chains.vue';

    export default {
        components: {
            StaffBusinessReview,
            StaffTaxReview,
            StaffEFTReview,
            StaffBackgroundCheck,
            StaffLiabilityDeposit,
            StaffLotteryEquipement,
            StaffLicensing,
            StaffFinalReview,
            StaffChainReview
        },
        props: {
            userId: Number,
            applicationJson: String,
            eftJson: String,
            taxJson: String,
            visibleSection: String,
            userRole: String,
            staffToken:String,
            readOnly: Boolean
        },
        data: function () {
            return {
                application: {},
                submitting: false,
                eappId: '',
                showCorpLocations: false,
                eftApproveReject: null,
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init() {
                this.application = JSON.parse(this.applicationJson);
                if (this.userRole == 'eApp-ChainSalesRep' || this.userRole == 'eApp-RegionalManager') {
                    this.showCorpLocations = true;
                }

                // console.log(this.applicationJson)

            },
            onNextAdminClick(appRequest) {
                this.$emit('onNextAdminClick', appRequest);
            },
            onSubmitClick() {

            },
            onRejectClick() {

            },
            editReview(e) {
                this.$emit('showPanel', e);
            }
        },
        computed:
        {
        },
    };
</script>

<style lang="scss" scoped>
</style>
