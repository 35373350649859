<!-- ~/Views/Games/EasyPlay/EasyPlay-landing.vue -->

<template>
    <section class="page_content cf">
        <h1>EZPLAY&reg; Progressive Jackpot:</h1>
        <article class="pjLPheader noGutter">
            <img class="pjLogoEZPLAY desktopOnly" src="" alt="EZPLAY&reg;">
            <img class="pjLogoEZPLAY desktopOnly" src="" alt="Progressive Series">
            <img class="pjLogoEZPLAYProgressive mobileOnly" src="" alt="EZPLAY&reg; Progressive Series">

            <p class="jpLPheaderText">
                EZPLAY® Games are instant play-style games generated through the Lottery terminal. Everything you need to play is on each EZPLAY® Game ticket.
            </p>
        </article>
        <article class="pjLpList">
            <div class="pjLpListItem" v-for="(ezgame, game) in easyListGames" v-bind:key="game">
                <div class="jpJackpot pjID" id="1240">
                    Progressive Jackpot: <span class="progressivejackpot"><span class="wnVal">${{ game.jackpotAmount }}</span> <span class="pjTime"></span></span>
                </div>
                <img class="pjBannerImgSm" :src="game.gameLogoURL" alt="Lucky Numbers">
                <a href="/Games/EZPLAY-Progressive-Jackpot/Lucky-Numbers" class="pjHowToBtn">How to Play</a>
                <div class="note">*Jackpot amount updated every two minutes</div>
            </div>
        </article>
        <article class="module">
            <h3>More Info</h3>
            <div class="cf moduleContent">
                <!-- {{game.info}} -->
            </div>
        </article>
    </section>
</template>

<script>
    export default {
        data: function() {
            return {
                games: [],
                easyListGames: [],
                errorMessage: 'Error in EasyPlay. Please try again later.'
            }
        },
        mounted: function() {
             this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/OnlineGames/OnlineGame/GetAllGames", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var easyresults = json.data;
                    // console.log(easyresults);
                    // bring all child arrays of objects up to the top level array
                    easyresults = Object.values(easyresults);
                    easyresults = easyresults.flat();
                    this.easyListGames = easyresults;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            }
        },
        computed: {
            filteredGames() {
                const self = this;
                let parsedObj = JSON.parse( JSON.stringify(self.easyListGames) )
                return parsedObj
                
            }
        }
    };
</script>


<!-- style lang="scss" scoped>
    /* style the unique aspects here */
</style -->