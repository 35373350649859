<template>
  <div class="module">
    <h3>Future Contestants</h3>
    <div class="future-contestants-wrapper">
      <div class="ce-future-contestants" v-for="show in allShows" :key="show.id" >
        <h3>{{ customDateMMDo(show.showDateAndTime) }} Contestants</h3>
        <ul class="ce-future-contestant-list" style="display: block">
          <li>Announced {{ customDateMMDDYYY(show.announcementDate) }}</li>
          <li v-if="show.isSpecialShow">
            <p class="ce-special-message">
              {{ show.specialShowMessage }}
            </p>
          </li>

          <li v-if="!show.isSpecialShow && show.contestantName1 !== ''">
            <strong>{{ show.contestantName1 }}</strong> -
            {{ show.contestantCity1 }}
          </li>
          <li v-if="!show.isSpecialShow && show.contestantName2 !== ''">
            <strong>{{ show.contestantName2 }}</strong> -
            {{ show.contestantCity2 }}
          </li>
          <li v-if="!show.isSpecialShow && show.contestantName3 !== ''">
            <strong>{{ show.contestantName3 }}</strong> -
            {{ show.contestantCity3 }}
          </li>
          <li v-if="!show.isSpecialShow && show.contestantName4 !== ''">
            <strong>{{ show.contestantName4 }}</strong> -
            {{ show.contestantCity4 }}
          </li>
          <li v-if="!show.isSpecialShow && show.contestantName5 !== ''">
            <strong>{{ show.contestantName5 }}</strong> -
            {{ show.contestantCity5 }}
          </li>
          <li v-if="!show.isSpecialShow && show.contestantName6 !== ''">
            <strong>{{ show.contestantName6 }}</strong> -
            {{ show.contestantCity6 }}
          </li>
          <li v-if="!show.isSpecialShow && show.contestantName7 !== ''">
            <strong>{{ show.contestantName7 }}</strong> -
            {{ show.contestantCity7 }}
          </li>
          <li v-if="!show.isSpecialShow && show.contestantName8 !== ''">
            <strong>{{ show.contestantName8 }}</strong> -
            {{ show.contestantCity8 }}
          </li>
          <li v-if="!show.isSpecialShow && show.contestantName9 !== ''">
            <strong>{{ show.contestantName9 }}</strong> -
            {{ show.contestantCity9 }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data: function () {
    return {
      allShows: [],
      count: 9,
    };
  },
  mounted: function () {
    this.getToken();
    //let apiToken = this.$cookies.get("apiToken");
    //console.log(apiToken.user);
  },
  methods: {
    customDateMMDDYYY(date) {
      return moment(date).format("M/D/YYYY");
    },
    customDateMMDo(date) {
      return moment(date).format('M/D');
    },
    getToken() {
      // then hit API with token
      this.getAPItoken().then((token) => {
        this.fetchData(token);
      });
    },
    fetchData(token) {
      var apiToken = token;

      return fetch(
        process.env.VUE_APP_APIPROTOCOL +
          process.env.VUE_APP_APIURL +
          "/1.0/CashExplosion/CashExplosion/GetAllShowItems?count=" +
          this.count,
        {
          headers: {
            Authorization: "Bearer " + apiToken,
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          var res = json.data;  
          sessionStorage.setItem('olcShowItems', JSON.stringify(res));        
          this.allShows = res.sort((a, b) => new Date(a.showDateAndTime) - new Date(b.showDateAndTime));
        })
        .catch((error) => {
          // console.error("There was an error!", error);
          return (this.errorMessage = error);
        });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
