<template>
    <div class="full">
        <div v-if="step === 0">
            <div v-if="isUserLogged && isKYCVerified && kycMode" class="alreadyIdverified module_callOut">
                <div class="verifiedCheckWrap"><span class="verifiedCheck icon-check-circle"></span></div>
                <h2>Your account has been verified.</h2>
            </div>
            <div v-if="isUserLogged && isKYCVerified && !kycMode">
                <div class="kycModeOff module_callOut">
                    <strong>Identity Verification is not required at this time.</strong>
                </div>
            </div>
            <div v-if="!isKYCVerified || !isUserLogged">
                <p><strong>We are committed to protecting your personal information and ensuring that your online account is secure.</strong></p>
                <p>You are required to verify your identity before redeeming points in your MyLotto Rewards<sup>&reg;</sup> account. This helps us prevent unauthorized access to your account and protect your personal information.</p>
                <h2>How to verify your identity</h2>
                <ol>
	                <li>Select the link below to launch our ID verification portal. <strong>We recommend using a smartphone to complete this process.</strong> If you are using a desktop or laptop computer, you have the option to send the verification process to your smartphone.</li>
	                <li>You will be asked to capture images of an approved government-issued ID. Acceptable forms of ID include:
	                    <ul style="list-style-type: lower-alpha;">
		                    <li>An unexpired driver&#39;s license</li>
		                    <li>An unexpired state ID card</li>
		                    <li>An unexpired US passport</li>
	                    </ul>
	                </li>
	                <li>Follow the prompts to complete the verification process</li>
	                <li>Return to this site to complete your transaction</li>
                </ol>
            </div>
            <SignInRegister v-if="!isUserLogged"></SignInRegister>
            <div class="verifyIdActions" v-if="isUserLogged && !isKYCVerified">
                <div><a @click="triggerPersonaWorkflow" class="button btn-primary verifyIdBtn" target="_blank">VERIFY MY ID NOW</a></div>
            </div>
        </div>
        <div v-if="step === 2">
            <div v-if="personaStatus === 'completed' || personaStatus === 'approved' || personaStatus === 'passed'">
                <div v-if="dataMismatch === 'false'" class="alreadyIdverified module_callOut">
                    <div class="verifiedCheckWrap"><span class="verifiedCheck icon-check-circle"></span></div>
                    <h2>Your account has been verified.</h2>
                    <p v-if="kycReturnUrl !== ''">Click the button below to go back to what you were doing</p>
                    <a :href="kycReturnUrl" class="button btn-primary" v-if="kycReturnUrl !== ''">Go Back</a>
                </div>
                <div v-if="dataMismatch === 'true'">
                    <h3>Mismatched Profile Info</h3>
                    <p>Your verified ID legal name or birth date doesn't match your MyLotto Rewards profile. <strong>We need to update your profile to the following in order to complete your identity verification:</strong></p>
                    <div v-if="nameDoesNotMatch">
                        <p>Verified legal name:<br><strong>{{ personaReturnedData["name-first"].value + " " + personaReturnedData["name-last"].value }}</strong></p>
                    </div>
                    <div v-if="birthdateDoesNotMatch">
                        <p>Verified birth date:<br><strong>{{ personaReturnedData["birthdate"].value }}</strong></p>
                    </div>
                    <p>You are required to complete verifying your identity before redeeming points in MyLotto Rewards.</p>
                    <div class="updateKYCinfo">
                        <a @click="updateProfileKYC(personaReturnedData)" class="button btn-primary">Update My Profile</a>
                        <a @click="cancelVerification">Cancel ID Verification</a>
                    </div>
                    <div class="error" v-if="updateKYCError !== ''">
                        <span class="error">{{ updateKYCError }}</span>
                    </div>
                </div>
            </div>
            <div v-if="personaStatus === 'failed' || personaStatus == 'declined'">
                <div class="module_callOut">
                        <h1>Unable to verify your ID</h1>
                        <p>I'm sorry, we are unable to verify your ID.</p>
                        <p>Possible reasons for an unsuccessful verification include:</p>
                        <ul>
                            <li>The form of identification already exists in our records</li>
                            <li>The form of identification is expired</li>
                            <li>The details on the form of identification were unclear or blurry</li>
                            <li>The ID type detected does not match the ID type you've selected</li>
                            <li>There was a problem with your Selfie verification</li>
                        </ul>
                        <p>If you need assistance, please contact MyLotto Rewards customer support:</p>
                        <p><a href="/mylotto-rewards/help">Online Help Center</a></p>
                        <p>Helpline: <a href="tel:+8553197189">(855) 319-7189</a></p>
                        <div>
                            <button type="button" class="button btn-tertiary" @click="cancelVerification">Try Again</button>
                        </div>
                    </div>
            </div>
            <div v-if="personaStatus === 'default'">
                <p><strong>There was a problem with the verification service.</strong></p>
            </div>
        </div>
        <div v-if="step === 3">
            <div class="alreadyIdverified module_callOut">
                <div class="verifiedCheckWrap"><span class="verifiedCheck icon-check-circle"></span></div>
                    <h2>Your account has been verified.</h2>
                    <p v-if="kycReturnUrl !== ''">Click the button below to go back to what you were doing</p>
                    <a :href="kycReturnUrl" class="button btn-primary" v-if="kycReturnUrl !== ''">Go Back</a>
            </div>
        </div>
    </div>
</template>
<script>
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    export default {
        components: {
            SignInRegister
        },
        data: function() {
            return {
                isUserLogged: false,
                token: {},
                user: {},
                id: '',
                kycMode: process.env.VUE_APP_KYCMODE,
                isKYCVerified: false, //always true
                userEmail: '',
                step: 0,
                personaStatus: '',
                dataMismatch: '',
                nameDoesNotMatch: false,
                birthdateDoesNotMatch: false,
                personaReturnedData: {},
                minimumAppVersion: '',
                summary: '',
                transactionID: '',
                kycReturnUrl: '',
                updateKYCError: ''
            }
        },
        mounted: function() {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();
        },
        // computed: {
        //     successfulSend() {
        //         return this.successfulSend;
        //     }
        // },
        methods: {
            getToken() {
                this.getAPItoken()
                    .then((token) => {
                        this.token = token;
                        // console.log(this.token);

                        if (this.isUserLogged) {
                            this.user = this.$cookies.get('apiToken');
                            this.id = this.user.user.userId;
                            // this.isKYCVerified = this.user.user.KYCVerified;
                            this.userEmail = this.user.user.email;
                            // console.log(this.user);
                            // console.log(`KYC : ${this.isKYCVerified}`);
                            // console.log(this.userPhone);
                            // console.log(this.userEmail);
                        }
                    })
                    .then(() => {
                        this.setAppVersion(this.token);
                    })
                    .then(() => {
                        this.getKYCVerified();
                        console.log("KYC Mode: " + this.kycMode);

                        if (this.$cookies.get('kycReturnUrl') !== null) {
                            this.kycReturnUrl = this.$cookies.get('kycReturnUrl').href;
                            console.log(this.kycReturnUrl);
                        }
                    })
            },
            getKYCVerified() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.id;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    console.log(json.data);
                    //always true
                    this.isKYCVerified = json.data.userIsVerified;

                    console.log("User is verified?" + json.data.userIsVerified);

                    if (json.data.errorMessage !== '') {
                       console.log(json.data.errorMessage);
                    }

                    if (!this.kycMode) {
                        this.isKYCVerified = true;
                    }

                    // this.isKYCVerified = true;
                    console.log(`KYC : ${this.isKYCVerified}`);
                })
                .catch((error) => {
                    // return (this.errorMessage = error)
                    console.log(error);
                })
                 
            },
            triggerPersonaWorkflow() {
                this.step = 1;
                this.launchPersonaEmbed(this.userEmail, this.handlePersonaData);
            },
            handlePersonaData(personaData, inqId, status, email) {
                console.log(personaData);

                this.personaReturnedData = personaData;
                this.summary = JSON.stringify(personaData);
                this.transactionID = inqId;

                switch(status) {
                    case 'completed':
                        console.log('completed');
                        this.personaStatus = 'completed';
                    break;
                    case 'approved':
                        console.log('approved');
                        this.personaStatus = 'approved';
                    break;
                    case 'passed':
                        console.log('passed');
                        this.personaStatus = 'passed';
                    break;
                    case 'failed':
                        console.log('failed');
                        this.personaStatus = 'failed';
                    break;
                    case 'declined':
                        console.log('declined');
                        this.personaStatus = 'declined';
                    break;
                    case 'default':
                        console.log('default');
                        this.personaStatus = 'default';
                }

                this.step = 2;

                this.compareUsersInfo(this.user, personaData);
            },
            compareUsersInfo(currentInfo, personaInfo) {
                console.log("Current Info");
                console.log(currentInfo);
                console.log("Persona Info");
                console.log(personaInfo);

                var currentFirstName = currentInfo.user.firstName,
                    currentLastName = currentInfo.user.lastName,
                    personaFirstName = personaInfo["name-first"].value,
                    personaLastName = personaInfo["name-last"].value,
                    currentDOB = new Date(currentInfo.user.birthdate),
                    personaDOB = this.processDOB(personaInfo["birthdate"].value);

                    currentDOB = new Intl.DateTimeFormat('en-US').format(currentDOB);

                    this.personaReturnedData["birthdate"].value = personaDOB;


                    currentFirstName = currentFirstName.toLowerCase();
                    currentLastName = currentLastName.toLowerCase();

                    personaFirstName = personaFirstName.toLowerCase();
                    personaLastName = personaLastName.toLowerCase();

                    console.log("Compare")
                    console.log("First Names : " + currentFirstName + " compared to " + personaFirstName);
                    console.log("Last Names : " + currentLastName + " compared to " + personaLastName);
                    console.log("DOB : " + currentDOB + " compared to " + personaDOB);

                    if (currentFirstName !== personaFirstName || currentLastName !== personaLastName) {
                        this.nameDoesNotMatch = true;
                    } 

                    if (currentDOB !== personaDOB) {
                        this.birthdateDoesNotMatch = true;
                    } 

                    if (this.nameDoesNotMatch || this.birthdateDoesNotMatch) {
                        this.dataMismatch = 'true';
                    } else {
                        this.dataMismatch = 'false';
                        //run updateKYCProfile to trigger the verification
                        this.updateProfileKYC(this.personaReturnedData);
                    }
            },
            cancelVerification() {
                window.location.reload();
            },
            processDOB(dob) {
                var newDOB = dob.replace(/-/g, '/');
                newDOB = new Date(newDOB);
                newDOB = new Intl.DateTimeFormat('en-US').format(newDOB);
                return newDOB;
            },
            updateProfileKYC(newData) {
                var dataObj = {
                    "UserId": this.id,
                    "FirstName": newData["name-first"].value,
                    "LastName": newData["name-last"].value,
                    "DateOfBirth": newData["birthdate"].value,
                    "TransactionID": this.transactionID,
                    "Summary": this.summary,
                }
                console.log(dataObj);
                //send to updateProfile
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/UpdateKYCProfile';

                const profileUpdatesKYCOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.token
                    },
                    body: JSON.stringify(dataObj)
                };

                fetch(url, profileUpdatesKYCOptions)
                .then(response => response.json())
                .then(dataUpdates => {
                    console.log(dataUpdates);

                    if (dataUpdates.statusCode === 500) {
                        this.updateKYCError = 'Profile update is not available at this time. Please try again later.';
                    } else {
                        this.updateKYCError = '';
                    }

                    if (dataUpdates.statusCode === 200 && dataUpdates.data === "User Validated.") {
                        this.dataMismatch = 'false';
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

            },
            setAppVersion(token) {
                let url = window.location.origin + '/registration/GetMinimumAppVersion';

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json'
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log('Registration Minimum App Version Data');
                    this.minimumAppVersion = json.minAppVersionNumber;
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .kycModeOff {

    }
    .alreadyIdverified {
        h2 {
            font-size: 3.2rem;
            line-height: normal;
            text-align: center;
            font-family: 'Roboto', Arial, sans-serif;
        }

        p {
            text-align: center;
        }

        a.button {
            margin: 0 auto;
        }
    }
    .verifiedCheckWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .verifiedCheck {
        color: green;
        font-size: 4rem;
    }
    .verifyIdActions a {
        letter-spacing: 0.3px;
        font-size: 1.4rem;
        font-weight: 400;
    }
    .emailFieldID, .phoneFieldID {
        margin-bottom: 1em;

        > div {
            margin-right: 0.8em;
        }

        .userCreds {
            margin-bottom: 0.8em;
        }

        button {
            margin-bottom: 0;
        }
    }
    .linkSent {
        color: green;
        margin-bottom: 1.5rem;
    }
    li.error {
        margin-bottom: 0;
    }

    .button.btn-tertiary.vIdBtn {
        background-color: #5161ac;
    }

    .updateKYCinfo {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        max-width: 420px;
        justify-content: space-between;
    }

    // @media only screen and (min-width: 641px) {
    //     .emailFieldID, .phoneFieldID {
    //         display: flex;
    //         align-items: center;
    //     }
    // }
</style>