<template>
    <div class="noGutter scratchoff-carousel">

        <div class="featured">
            <div class="instant_feature instant_feature_right">
                <div class="content">
                    <h2> {{ heading }} </h2>
                    <p>{{ copy }}</p>
                    <a href="/Games/Scratch-Offs" class="button btn_purpleLong">{{ button }}</a>
                </div>
            </div>
            <div class="instant_feature instant_feature_left instant_feature_tickets" v-if="scratchOffItems.length > 0">

                <!-- <img class="ticketImgLeft" :src="ticketLeft"> -->
                <!-- <img class="ticketImgRight" :src="ticketRight"> -->

                <a :href="item.nodeAliasPath" v-for="(item, index) in randomGame(scratchOffItems)" :key="item.gameID"
                    :class="[index == 0 ? 'ticketImgLeft' : 'ticketImgRight']">
                    <img :alt="item.gameName" :src="item.gameGraphicThumbURL">
                </a>

            </div>

        </div>

        <div class="instant_ticket_slider_wrap">
            <VueSlickCarousel v-bind="settings" class="instant_ticket_slider" v-if="scratchOffItems.length > 0">
                <div v-for="item in scratchOffItems" :key="item.id">
                    <a :href="item.nodeAliasPath">
                        <img :alt="item.gameName" :src="item.mobileAppLogoURL">
                    </a>
                </div>
            </VueSlickCarousel>
        </div>

    </div>
</template>
<script>
// need to build URLs from context - create array of unique paths:
// loop the games and get the nodeAliasPath, 
// remove the page (anything after last /),
// push to array if it's not already in the array

import { mapActions, mapGetters } from 'vuex';
// import moment from 'moment';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: {
        VueSlickCarousel
    },
    data: function () {
        return {
            heading: '',
            button: '',
            copy: '',
            ticketLeft: '',
            ticketRight: '',
            scratchOffItems: [],
            pricegroup: [],
            games: [],
            randomUrl: [],
            settings: {
                "dots": false,
                "infinite": false,
                "speed": 700,
                "slidesToShow": 6,
                "slidesToScroll": 6,
                "initialSlide": 0,
                "responsive": [
                    {
                        "breakpoint": 1024,
                        "settings": {
                            "slidesToShow": 4,
                            "slidesToScroll": 4
                        }
                    },
                    {
                        "breakpoint": 600,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 3
                        }
                    }
                ]
            }

        }
    },
    mounted: function () {
        // console.log('loaded');
        this.getToken();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchStaticContent(token);
                    this.fetchData(token);
                });
        },
        fetchData(token) {
            var apiToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/ScratchOffs/ScratchOffGame/GetAllGames", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    let results = json.data;
                    // console.log(results)

                    const concatArrs = Object.values(results)
                        .filter((x) => Array.isArray(x))
                        .flat();

                    const byOnSaleDate = concatArrs.sort(function (a, b) {
                        return new Date(b.onSaleDate) - new Date(a.onSaleDate);
                    });

                    this.scratchOffItems = byOnSaleDate.slice(0, 12);

                    // console.log(this.scratchOffItems[0].gameGraphicURL)
                    // console.log(this.scratchOffItems[2].gameGraphicURL)

                    this.ticketLeft = this.scratchOffItems[8].gameGraphicURL;
                    this.ticketRight = this.scratchOffItems[10].gameGraphicURL;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
        },
        fetchStaticContent(token) {
            var apiToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=scratch-carousel-content", {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    let content = json.data[0].centralContent;
                    let parseContent = JSON.parse(content)

                    // console.log(parseContent)

                    this.heading = parseContent.heading;
                    this.copy = parseContent.copy;
                    //this.button = parseContent.button;
                    this.button = 'See all scratch-offs'

                })
                .catch(error => {
                    return this.errorMessage = error;
                });
        },
        randomGame(obj) {
            while (this.randomUrl.length < 2) {
                const indexRandom = Math.floor(Math.random() * obj.length);
                const indexSelected = obj[indexRandom];

                if (indexSelected.url !== '') {
                    this.randomUrl.push(indexSelected);
                    obj.splice(indexRandom, 1);
                }
            }
            return this.randomUrl;
        },
        ...mapActions({
            checkScratchoffs: 'getNumbers/getOLCScratchGames'
        }),
        ...mapGetters({
            getScratchoffs: 'getNumbers/getScratchNumbers'
        })
    }
};

</script>
<style lang="scss">
/* INSTANT TICKET MODULE  ------------------------- */
.scratchoff-carousel {

    // NEVER put these outside of a parent selector!
    .slick-prev:before,
    .slick-next:before {
        color: #000;
    }

    margin-top: 20px;

    .featured {
        display: flex;
        background-color: #F1EFEE;
        vertical-align: bottom;
        text-align: center;
        padding-top: 20px;
        max-width: 1240px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(large) {
            min-height: 260px;
        }

        .instant_feature_right {
            width: 48%;
            display: flex;
            justify-content: center;
            align-items: center;
            .content{
                padding: 0;
                button{
                    margin-bottom: 0;
                }
            }
        }

        .instant_feature_left {
            width: 48%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        .ticketImgLeft {
            display: block;
            width: 35%;
            z-index: 1;
            transform: rotate(-20deg);

            img {
                box-shadow: 0px 4px 7px rgb(0 0 0 / 70%);
                max-height: 300px;
            }
        }

        .ticketImgRight {
            display: block;
            width: 35%;
            z-index: 2;
            transform: rotate(20deg);

            img {
                box-shadow: 0px 6px 10px rgb(0 0 0 / 70%);
                max-height: 300px;
            }
        }


    }

    .instant_ticket_slider_wrap {
        position: relative;
        z-index: 10;
        padding: 30px 50px;
        height: 120px;
        background-color: #B9BDC3;
    }

    .instant_ticket_slider {
        vertical-align: middle;
        text-align: center;
        height: 60px;

        a {
            display: flex;
            vertical-align: middle;
            height: 60px;
            text-align: center;
            background-color: #fff;
            margin: 0 5px;
            -webkit-border-radius: 7px;
            -moz-border-radius: 7px;
            border-radius: 7px;
            width: 90%;

            img {
                margin: 0 auto;
                max-width: 90%;
                max-height: 60px;
            }
        }
    }

    .btn_purpleLong {
        font-size: 1.4rem;
    }
}
</style>