<template>
    <div id="modal-kyc-login" class="loginKYCModal modal-kyc">
        <div class="modal-kyc_wrap">
            <h2>Please verify your account</h2>
            <p>We have updated security for MyLotto Rewards. You must verify your ID before making any transactions using your MyLotto Rewards points.</p>
            <div class="modal-kyc_actions">
                <a id="verifyBtnKYC" class="button btn-primary" @click="goVerify">Verify My Id</a>
                <button type="button" id="extBtnKYC" class="button btn-primary close-button" @click="closeModal">I'll do it later</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {

            }
        },
        methods: {
            goVerify() {
                let goUrl = window.parent.location.origin + "/mylotto-rewards/my-account/verify-my-identity";
                window.parent.location.assign(goUrl);
            },
            closeModal() {
                window.parent.location.reload(true);
            }
        }
    }
</script>