<template>
    <div class="eAppModule">
        <div v-if="!isLoading" class="moduleContent cf eAppLicensing  saveForm cf">
            <h3>Licensing</h3>

            <div class="formRow cf checkbox">
                <input :disabled="disabledForUser" v-model="application.license.irsCheck" id="irsCheck" type="checkbox"
                    name="irsCheck">
                <label for="irsCheck">IRS Check</label>
            </div>

            <div class="formRow cf checkbox">
                <input :disabled="disabledForUser" v-model="application.license.secretaryOfStateCheck"
                    id="secretaryOfStateCheck" type="checkbox">
                <label for="secretaryOfStateCheck">Secretary Of State Check</label>
            </div>

            <div class="formRow cf checkbox">
                <input :disabled="disabledForUser" v-model="application.license.liquorCheck" id="liquorCheck"
                    type="checkbox" name="liquorCheck">
                <label for="liquorCheck">Liquor Check</label>
            </div>

            <div class="formRow cf checkbox">
                <input :disabled="disabledForUser" v-model="application.license.historyCheck" id="historyCheck"
                    type="checkbox" name="historyCheck">
                <label for="historyCheck">History Check</label>
            </div>

            <div class="formRow fileUpload cf" v-if="reviewOnly">
                <label for="licensingFile">Upload a File: <a
                        v-if="file === true"
                        @click="downloadFile()">Click Here to View {{ application.license.licensingFileName }}</a></label>
                <!-- <input type="file" name="licensingFile" id="licensingFile" v-if="reviewOnly"
                    @input="event => handleFile(event)"> -->


                <VueFileAgent ref="vueFileAgent" :multiple="false" :deletable="true" :meta="true" :linkable="true"
                    :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.zip'" :theme="'list'" :maxSize="'5MB'"
                    :maxFiles="1" :helpText="'Choose file'" :errorText="{
                        type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png, zip.',
                        size: 'Files should not exceed 5mb in size',
                    }" @select="handleFile($event)" @beforedelete="onBeforeDelete($event)" @delete="onDelete($event)"
                    v-if="reviewOnly">
                </VueFileAgent>
                <!-- show link to file if uploaded already -->
            </div>

            <div class="btn-actions">
                <button type="button" @click="onNextAdminClick()" name="Save" id="licensingSave"
                    class="btn btn-primary btnNext" title="Save the document">Save</button>
            </div>


        </div>
    </div>
</template>


<script>
export default {
    components: {},
    props: {
        applicationJson: String,
        userId: Number,
        licensingToken: String,
    },
    data: function () {
        return {
            application: {},
            reviewOnly: false,
            disabledForUser: false,
            isLoading: true,
            file: false
        }
    },
    mounted: function () {
        this.init();
    },
    methods: {
        init() {
            this.deserializeJson();

            // hide Save from the CSM, you just can't trust them!
            if (this.isUserInRole('eApp-CentralSalesManager') || this.isUserInRole('eApp-CentralLicensingManager')) {
                this.reviewOnly = true;
            }

            this.disabledForUser = this.isUserInRole('eApp-CentralSalesManager');

            this.isLoading = false;
        },
        deserializeJson() {
            this.application = JSON.parse(this.applicationJson);

            let file = this.application.license.licensingFile;
            if (file === null || file === undefined || file === '' ) {
                this.file = false;
            } else {
                this.file = true;
            }
        },
        onNextAdminClick() {
            this.$emit('onNextAdminClick', { application: this.application, step: 'licensing', updates: true });
        },
        downloadFile() {
            var binaryString = window.atob(this.application.license.licensingFile);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            var blob = new Blob([bytes], { type: this.application.license.licensingFileExtension });
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        },
        handleFile(event) {
            // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
            var _this = this;
            var file = event[0].file;
            var fileName = event[0].file.name;
            var fileType = event[0].file.type

            const reader = new FileReader();
            reader.onload = function (e) {
                _this.application.license.licensingFile = e.target.result.split(',')[1];
                _this.application.license.licensingFileExtension = fileType;
                _this.application.license.licensingFileName = fileName;
            };

            reader.readAsDataURL(file);

            // var file = event.target.files[0];

            // var reader = new FileReader();
            // var fileData;
            // var fileType = '';
            // var fileName = '';
            // reader.readAsDataURL(file, 'base64');
            // reader.onload = function (e) {
            //     var fileDataArray = reader.result.split(',')[1];
            //     fileData = fileDataArray
            //     fileType = file.type;
            //     fileName = file.name;
            // }

            // reader.onloadend = () => {
            //     this.application.license.licensingFile = fileData;
            //     this.application.license.licensingFileExtension = fileType;
            //     this.application.license.licensingFileName = fileName;
            // }
        },
        onBeforeDelete(event) {
            // optionally, you can confirm and call the deleteFileRecord method
            this.$refs.vueFileAgent.deleteFileRecord(event);
        },
        onDelete(event) {
            this.application.license.licensingFile = null;
            this.application.license.licensingFileExtension = null;
            this.application.license.licensingFileName = null;
        }

    },
    computed: {}
};
</script>

<style lang="scss" scoped></style>
