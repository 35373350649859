<template>
    <div class="reveal" id="USPSmodal" data-reveal data-options="closeOnClick:false; closeOnEsc:false;">
        <h1>{{time}}</h1>
    </div>
</template>

<script>

export default {
    props: [
        'time'
    ],
    components: {},
    data: function () {
        return {
            token: ''
        }
    },
    mounted: function () {
        this.getToken();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
            .then((token) => {
                this.token = token;
            });
        },
        verified() {
            // On "Confirm" click this will call a method on the parent component that will submit the USPS address.
            this.$parent.uspsVerified({
                address: this.verifiedAddress,
                mailingAddress: this.verifiedMailingAddress,
                city: this.verifiedCity,
                state: this.verifiedState,
                zip: this.verifiedZipcode,
                isProvidedAddressAvailable: this.isProvidedAddressAvailable,
                isSuggestedAddressAvailable: this.isSuggestedAddressAvailable
            });
        },
        close() {
            // On "Edit" click this will call a method on the parent component that will close the modal
            this.$parent.uspsClose();
        },
        validate() {
            this.$parent.uspsOpen();
        },
        compareAddresses() {
            if (
                this.user_Address1 == this.USPS_Address1 &&
                this.user_Address2 == this.USPS_Address2 &&
                this.user_City == this.USPS_City &&
                this.user_State == this.USPS_State &&
                this.user_Zip == this.USPS_Zip
            ) {
                // addresses already match
                this.acceptNewAddress = true;
                // do not show modal

            } else {
                // populate and display modal

            }

        }
    },
    computed: {
        dataProp: function () {
            return this.dataProp;
        }
    },
    watch: {
       // mailingAddress: function (newVal) {
       //     this.form.mailingAddress = newVal;
       // },
       // addressContinued: function (newVal) {
       //     this.form.addressContinued = newVal;
       // },
       // city: function (newVal) {
       //     this.form.city = newVal;
       // },
       // state: function (newVal) {
       //     this.form.state = newVal;
       // },
       // country: function (newVal) {
       //     this.form.country = newVal;
       // }
    }
};
</script>
<style lang="scss" scoped>
.ctaAddress{
    display: flex;
    .button{
        &+.button{
            margin-left: 1em;
        }
    }
}
</style>