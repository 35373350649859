var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full"},[(_vm.isUserLogged)?_c('div',[(_vm.kyc)?_c('div',[_c('h1',[_vm._v("Verify My Identity")]),_vm._m(0),_vm._m(1)]):_c('div',[(_vm.hasErrors)?_c('div',[_c('h1',[_vm._v("Verify My Identity")]),_vm._m(2),_c('h3',[_vm._v("There was a problem with your verification.")]),_c('p',[_vm._v("The ID you provided does not match the name or birth date we have on record for your MyLotto Rewards account. You can try again with an alternate ID (passport, state drivers license or state ID card).")]),_vm._m(3),_vm._m(4)]):(!_vm.hasErrors && _vm.kycVerificationLink !== '')?_c('div',[_c('h1',[_vm._v("Verify My Identity")]),(_vm.isLoading)?_c('HollowDotsSpinner',{staticClass:"home-banner-loader",attrs:{"color":"#0FD8C9"}}):_vm._e(),_c('p',{staticClass:"processingAnchor"},[_vm._v("We are still processing your ID.")])],1):_c('div',[_c('h1',[_vm._v("Verify My Identity")]),_c('h3',[_vm._v("There was a problem with your verification.")]),_vm._m(5),_vm._m(6)])])]):_c('div',[_c('h1',[_vm._v("Sign In below to complete your verification.")])]),(!_vm.isUserLogged)?_c('SignInRegister'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("We have received your ID upload for verification.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"icon-check verifiedCheck"}),_vm._v(" We have verified your ID. You can now close this browser tab.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("We have received your ID upload for verification.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("If this is in error, please "),_c('a',{attrs:{"href":"/mylotto-rewards/help"}},[_vm._v("contact Customer Support")]),_vm._v(" to resolve this issue.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"button btn-primary",attrs:{"href":"/mylotto-rewards/my-account/verify-my-identity"}},[_vm._v("Try Again")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("We did not receive your ID upload for verification.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"button btn-primary",attrs:{"href":"/mylotto-rewards/my-account/verify-my-identity"}},[_vm._v("Try Again")])])
}]

export { render, staticRenderFns }