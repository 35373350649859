<template>
  <div class="module">
    <div
      class="cf moduleContent"
      v-for="show in allShows.slice(0, 1)"
      :key="show.id"
    >
      <h3>This Week's Contestants</h3>

      <div class="responsive_video" v-if="show.contestantVideoURL !== ''">
        <iframe
          :src="show.contestantVideoURL"
          width="560"
          height="315"
        ></iframe>
      </div>
      <p class="ce-weeks-special-message" v-if="show.isSpecialShow == true">
        {{ show.specialShowMessage }}
      </p>
      <ul class="ce-this-weeks-contestants" v-if="show.isSpecialShow == false">
        <li v-if="show.contestantName1 !== ''">
          <h5>{{ show.contestantName1 }}, {{ show.contestantCity1 }}</h5>
          <p v-if="show.retailerName1 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName1 !== ''">
            <strong>{{ show.retailerName1 }}</strong
            >, {{ show.retailerAddress1 }}
          </p>
        </li>
        <li v-if="show.contestantName2 !== ''">
          <h5>{{ show.contestantName2 }}, {{ show.contestantCity2 }}</h5>
          <p v-if="show.retailerName2 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName2 !== ''">
            <strong>{{ show.retailerName2 }}</strong
            >, {{ show.retailerAddress2 }}
          </p>
        </li>
        <li v-if="show.contestantName3 !== ''">
          <h5>{{ show.contestantName3 }}, {{ show.contestantCity3 }}</h5>
          <p v-if="show.retailerName3 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName3 !== ''">
            <strong>{{ show.retailerName3 }}</strong
            >, {{ show.retailerAddress3 }}
          </p>
        </li>
        <li v-if="show.contestantName4 !== ''">
          <h5>{{ show.contestantName4 }}, {{ show.contestantCity4 }}</h5>
          <p v-if="show.retailerName4 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName4 !== ''">
            <strong>{{ show.retailerName4 }}</strong
            >, {{ show.retailerAddress4 }}
          </p>
        </li>
        <li v-if="show.contestantName5 !== ''">
          <h5>{{ show.contestantName5 }}, {{ show.contestantCity5 }}</h5>
          <p v-if="show.retailerName5 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName5 !== ''">
            <strong>{{ show.retailerName5 }}</strong
            >, {{ show.retailerAddress5 }}
          </p>
        </li>
        <li v-if="show.contestantName6 !== ''">
          <h5>{{ show.contestantName6 }}, {{ show.contestantCity6 }}</h5>
          <p v-if="show.retailerName6 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName6 !== ''">
            <strong>{{ show.retailerName6 }}</strong
            >, {{ show.retailerAddress6 }}
          </p>
        </li>
        <li v-if="show.contestantName7 !== ''">
          <h5>{{ show.contestantName7 }}, {{ show.contestantCity7 }}</h5>
          <p v-if="show.retailerName7 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName7 !== ''">
            <strong>{{ show.retailerName7 }}</strong
            >, {{ show.retailerAddress7 }}
          </p>
        </li>
        <li v-if="show.contestantName8 !== ''">
          <h5>{{ show.contestantName8 }}, {{ show.contestantCity8 }}</h5>
          <p v-if="show.retailerName8 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName8 !== ''">
            <strong>{{ show.retailerName8 }}</strong
            >, {{ show.retailerAddress8 }}
          </p>
        </li>
        <li v-if="show.contestantName9 !== ''">
          <h5>{{ show.contestantName9 }}, {{ show.contestantCity9 }}</h5>
          <p v-if="show.retailerName9 !== ''">Ticket purchased at:</p>
          <p v-if="show.retailerName9 !== ''">
            <strong>{{ show.retailerName9 }}</strong
            >, {{ show.retailerAddress9 }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data: function () {
    return {
      allShows: [],
      count: 9,
    };
  },
  mounted: function () {
    this.getShows();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    getToken() {
      this.getAPItoken().then((token) => {
        this.fetchData(token);
      });
    },
    fetchData(token) {
      var apiToken = token;

      return fetch(
        process.env.VUE_APP_APIPROTOCOL +
          process.env.VUE_APP_APIURL +
          "/1.0/CashExplosion/CashExplosion/GetAllShowItems?count=" +
          this.count,
        {
          headers: {
            Authorization: "Bearer " + apiToken,
          },
        }
      )
      .then((response) => response.json())
      .then((json) => {
        var res = json.data;  
        sessionStorage.setItem('olcShowItems', JSON.stringify(res));        
        this.showShows(res);
      })
      .catch((error) => {
        // console.error("There was an error!", error);
        return (this.errorMessage = error);
      });

    },
    getShows() {
      let showData = JSON.parse(sessionStorage.getItem('olcShowItems'));
      
      if (showData !== undefined && showData !== "" && showData !== null) {
        this.showShows(showData);
      } else {
        this.getToken();
      }
    },
    showShows(localItems) {
      
      let currentDate = new Date();
      let res = [];

      localItems.map((item) => {
        if (currentDate <= new Date(item.showDateAndTime)) {
          res.push(item);
        }
      });

      this.allShows = res.sort(
        (a, b) => new Date(a.showDateAndTime) - new Date(b.showDateAndTime)
      );
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
