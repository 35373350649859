<template>
    <section class="modulePrpl myAccountSummary" v-if="loaded">
        <div class="accountUserInfo cf">
            <h2 v-if="user.nickname.length">{{ user.nickname }}</h2>
            <h2 v-if="user.nickname === ''">{{ user.firstName }}</h2>
            <span class="userLocation">{{ user.address.city }}, {{ user.address.ctate }}</span>
            <span class="memberSince">Member since {{ memberSince }}</span>
            <a class="button btn_link_small" href="/mylotto-rewards/my-account/my-profile">Update profile</a>
            <a class="button btn_link_small" href="/mylotto-rewards/my-account/notification-settings">Notifications
                Settings</a>
            <a class="button btn_link_small" href="/mylotto-rewards/my-account/drawing-entries">Drawing Entries</a>
            <a class="button btn_link_small" href="/mylotto-rewards/my-account/my-lottery-cash-coupons">My Lottery Cash
                &amp; Coupons</a>
            <a class="button btn_link_small" href="/mylotto-rewards/my-account/my-digital-purchases">My Digital
                Purchases</a>
            <a class="button btn_link_small" href="/mylotto-rewards/my-account/my-physical-purchases">My Physical
                Purchases</a>
            <a class="button btn_link_small" href="/mylotto-rewards/my-account/my-profile/delete-your-account">Delete
                Your Account</a>
        </div>
        <div class="accountMonthlySummary cf">
            <div class="monthlySummary">
                Monthly Summary
                <span>(activity since {{ startDate }})</span>
            </div>
            <ul class="accountSummaryList cf">
                <li class="cf">Starting balance
                    <span class="points">{{ balance }}</span>
                </li>
                <li class="cf">
                    <span class="toolTip">Points entered <span class="toolTipIcon" @mouseover="showTooltip(1)"
                            @mouseout="hideTooltip(1)">?
                            <span class="toolTipContent"
                                :class="{ 'tooltip-visible': isTooltipVisible(1) }"><strong>Points entered: </strong>The
                                points
                                you’ve earned from entering non-winning ticket numbers.</span>
                        </span>

                    </span>
                    <span class="points">{{ earned }}</span>
                </li>
                <li class="cf">
                    <span class="toolTip">Points awarded <span class="toolTipIcon" @mouseover="showTooltip(2)"
                            @mouseout="hideTooltip(2)">?
                            <span class="toolTipContent secondTooltip"
                                :class="{ 'tooltip-visible': isTooltipVisible(2) }"><strong>Points awarded:
                                </strong>The points you've won or been awarded through drawing activities and program
                                participation.</span></span></span>
                    <span class="points">{{ awarded }}</span>
                </li>
                <li class="cf">Points redeemed <span class="points">{{ redeemed }}</span></li>
                <li class="cf currentBalance">Current balance <span class="points">{{ current }}</span></li>
            </ul>
        </div>
    </section>
</template>

<script>
import moment from "moment";

export default {
    props: [
        'user',
        'pointsBalance'
    ],
    data: function () {
        return {
            points: '',
            balance: '0',
            earned: '0',
            awarded: '0',
            redeemed: '0',
            current: '0',
            startDate: 'January 1, 2000',
            memberSince: 'January 1, 2000',
            loaded: false,
            tooltips: {
                1: false,
                2: false,
            },
        }
    },
    mounted: function () {
        let points = this.user;
        console.log(points)
        this.points = points.userDto.pointBalance;
        this.points.monthStart != 0 ? this.balance = this.points.monthStart.toLocaleString() : false;
        this.points.monthEarned != 0 ? this.earned = this.points.monthEarned.toLocaleString() : false;
        this.points.monthAwarded != 0 ? this.awarded = this.points.monthAwarded.toLocaleString() : false;
        this.points.monthRedeemed != 0 ? this.redeemed = this.points.monthRedeemed.toLocaleString() : false;
        this.points.balance > 99 ? this.current = this.points.balance.toLocaleString() : this.current = this.points.balance;

        this.memberSince = moment(points.userDto.memberSince).format('LL')
        this.startDate = moment().startOf('month').format('MMMM Do YYYY');

        this.loaded = true;
    },
    methods: {
        getToken() {
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    // this.user = this.$cookies.get('apiToken');
                    this.getPoints();
                    // this.isUserLogged = this.isUserAuthed();
                });
        },
        getPoint() {
            let apiToken = this.token,
                url = process.env.VUE_APP_CASHLESSAPIUR + '/1.0/Cashless/User/GetCashlessTransactionItems';

            let datCreds = {
                "userId": this.user.userId,
                "recordsPerPage": 10000,
                "startDate": new Date(this.startDate),
                "endDate": new Date(this.endDate),
                "pageNumber": 1
            };

            return fetch(url, {
                method: 'POST',
                body: JSON.stringify(datCreds),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + apiToken,
                },
            }
            )
                .then((response) => response.json())
                .then((json) => {
                    console.log(json)
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
        },
        showTooltip(index) {
            this.$set(this.tooltips, index, true);
        },
        hideTooltip(index) {
            this.$set(this.tooltips, index, false);
        },
        isTooltipVisible(index) {
            return this.tooltips[index];
        },
    },
    watch: {
        pointsBalance(newVal, oldVal) {
            if (newVal != oldVal) {
                this.current = this.pointsBalance.toLocaleString();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.tooltip-visible {
    display: block;
    color: black;
    left: 2rem;
}
.toolTip{
    .toolTipContent{
        &::before{
            content: "";
        }
    }
}
</style>