<template>
    <div v-if="!isUserLogged">
        <div class="module_SignInRegister">
            <p class="logInIntro" v-if="heading != ''">{{heading}}</p>
            <div class="signInRegister_btns">
                <button class="button btn_SignIn btn_popupLogIn" id="globalSignIn">{{ logInText }}</button>
                <a :href="registerUrl" class="button btn_link btn_Register">{{ registerText }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
    components: {
    },
    props: {
        heading: {
            type: String,
            default: ''
        },
        contentKey: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isUserLogged: false,
            logInText: 'Sign In',
            registerText: 'Register',
            registerUrl: '/myLotto/My-Account/Register'
        }
    },
    mounted: function () {
        this.isUserLogged = this.isUserAuthed();
        this.init();
    },
    methods: {
        init() {
            if (this.contentKey !== '') {
                this.getToken();
            } else {
                this.loadComponent();
            }
        },
        loadComponent() {
            $(document).ready(function () {
                $('.close-button').on('click', function () {
                    $("#globalLogInModal").hide();
                    $("#globalLoginBlocker").hide();
                })

                $(".btn_popupLogIn").on('click', function () {
                    $("#globalLogInModal").show();
                    $("#globalLoginBlocker").show();
                });
            })

        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(apiToken) {
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=' + this.contentKey, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken
                }
            })
                .then(response => response.json())
                .then(json => {
                    let element = JSON.parse(json.data[0].centralContent);

                    // localStorage.setItem('signInRegister-eApp', JSON.stringify(element));
                    // console.log(element.register);

                    this.logInText = element.logIn;
                    this.registerText = element.register;
                    this.registerUrl = element.registerurl;
                    this.loadComponent();

                })
        }
    }
}
</script>

<style lang="css"></style>