
<template>
    <div class="paginateWrapper">
        <nav aria-label="Pagination">
            <ul class="pagination" :class="{'text-center': !center, 'text-right': right, 'text-left': left}">
                <li v-if="prevButtonDisabled && showPrevNext" class="pagination-previous disabled" aria-label="Previous Page">Previous</li>
                <li v-if="!prevButtonDisabled"  :class="['pagination-previous']"><a v-if="!prevButtonDisabled" @click="onArrowClick('<')" aria-label="Previous page">Previous</a></li>
                <li v-for="(page, index) in paginate.pages" :key="index">
                    <a :class="page === activePage ? activePageClass : ''" :aria-label="'Page ' + page" @click="onPageClick(page)"><span v-if="page===activePage" class="show-for-sr">You're on page</span>{{page}}</a>
                </li>
                <li v-if="showElipsisAndLastPage" class="ellipsis"></li>
                <li v-if="showElipsisAndLastPage" ><a @click="onPageClick(paginate.totalPages)" :aria-label="'Page ' + paginate.totalPages">{{paginate.totalPages}}</a></li>
                <li v-if="nextButtonDisabled && showPrevNext" class="pagination-next disabled" aria-label="Next page">Next</li>
                <li v-if="!nextButtonDisabled" class="pagination-next" @click="onArrowClick('>')"><a v-if="!nextButtonDisabled" aria-label="Next page">Next</a></li>
            </ul>
        </nav>
    </div>
</template>
<script>
    export default {
        props: {
            totalPages: Number,
            itemsPerPage: Number,
            currentPage: Number,
            right: Boolean,
            left: Boolean,
            center: Boolean
        },
        data() {
            return {
                maxPagesShown: 5,
                prevButtonContent: '<',
                nextButtonContent: '>',
                numberButtonsClass: 'number-buttons',
                paginateButtonsClass: 'paginate-buttons',
                activePageClass: 'current',
                activePage: this.currentPage
            }
        },
        methods: {
            onArrowClick(dir) {
                if (dir === '<') {
                    this.onPageClick(this.activePage - 1);
                }
                else if (dir === '>') {
                    this.onPageClick(this.activePage + 1);
                }
            },
            onPageClick(pageNumber) {
                if (pageNumber === this.activePage) {
                    return;
                }

                if (pageNumber > this.totalPages || pageNumber < 1) {
                    return;
                }

                this.$emit('changePage', pageNumber);
            }
            /*
            // NOTE These get copied to parent component.
            setPages() {
                // Sets current pages, items per page, max pages etc.
                // Add the folloing to you data object

                // items: (get this from the api),
                // totalPages: 0,
                // itemsPerPage: this.items / this.totalPages,
                // currentPage: 1,
                // currentItems: []

                // Then add:
                // this.setPages();
                // this.setItems();
                // to the end of your API call function chain once this.items has been set
                
                this.itemsPerPage = 5;
                this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
            },
            changePage(p) {
                this.currentPage = p;
                this.setItems();
            },
            setItems() {
                // Sets current items to show up in pagination
                 

                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.items.slice(start, end);

                this.currentItems = currentItems;
            }
        */
        },
        computed: {
            paginate() {
                let startPage, endPage;
                if (this.totalPages < this.maxPagesShown) {
                    // console.log(this.totalPages)
                    startPage = 1;
                    endPage = this.totalPages;
                } else {
                    // total pages is more than maxPagesShown...
                    // calculating start and end pages
                    let maxPagesShownBeforeCurrentPage = Math.floor(this.maxPagesShown / 2);
                    let maxPagesShownAfterCurrentPage = Math.ceil(this.maxPagesShown / 2) - 1;
                    if (this.currentPage <= maxPagesShownBeforeCurrentPage) {
                        // current page is at the start of the pagination
                        startPage = 1;
                        endPage = this.maxPagesShown;
                    } else if (this.currentPage + maxPagesShownAfterCurrentPage >= this.totalPages) {
                        // current page is at the end of the pagination
                        startPage = this.totalPages - this.maxPagesShown + 1;
                        endPage = this.totalPages;
                    } else {
                        // current page is somewhere in the middle of the pagination
                        startPage = this.currentPage - maxPagesShownBeforeCurrentPage;
                        endPage = this.currentPage + maxPagesShownAfterCurrentPage;
                    }
                }
                // create an array of pages to be displayed
                let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
                    (i) => startPage + i
                );               
                return {
                    totalItems: this.totalItems,
                    currentPage: this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    totalPages: this.totalPages,
                    startPage: startPage,
                    endPage: endPage,
                    pages: pages,
                };
            },
            showPrevNext() {
                let show = true;

                if (this.totalPages < this.maxPagesShown) {
                    show = false;
                }

                return show;
            },
            nextButtonDisabled() {
                let disabled = false;
                if (this.totalPages < this.maxPagesShown) {
                    disabled = true;
                } else {
                    let maxPagesShownAfterCurrentPage = Math.ceil(this.maxPagesShown / 2) - 1;
                    if (this.currentPage >= this.totalPages) {
                        // current page is at the end of the pagination
                        disabled = true;
                    }
                }
                return disabled;
            },
            prevButtonDisabled() {
                let disabled = false;
                if (this.totalPages < this.maxPagesShown) {
                    disabled = true;
                }
                else {
                    let maxPagesShownBeforeCurrentPage = Math.floor(this.maxPagesShown / 2);
                    if (this.currentPage < maxPagesShownBeforeCurrentPage) {
                        disabled = true;
                    }
                }
                return disabled;
            },
            showElipsisAndLastPage() {
                let show = true;
                let maxPagesShownAfterCurrentPage = Math.ceil(this.maxPagesShown / 2) - 1;
                if (this.currentPage + maxPagesShownAfterCurrentPage >= this.totalPages || this.totalPages <= this.maxPagesShown) {
                    // current page is at the end of the pagination
                    show = false;
                }
                return show;
            }
        },
        watch: {
            currentPage: function(newVal) {
                this.activePage = newVal;
            }
        }
    }
</script>

<style>

</style>