<!-- ~/Views/Games/EasyPlay/EasyPlay-landing.vue -->

<template>
    <section class="gameList_onlineGame">
        <h3>Games</h3>
        <ul class="list__games onlineGameList">
            <li v-for="easyListGame in easyListGames" v-bind:key="easyListGame.gameID" class="onlineGameList_item">
                <a :href="easyListGame.nodeAliasPath">{{ easyListGame.gameName }}</a>
            </li>
        </ul>
    </section>
</template>

<script>

    export default {
        data: function() {
            return {
                allGames: [],
                easyListGames: [],
                errorMessage: 'Error in EasyPlay. Please try again later.'
            }
        },
        mounted: function() {
             this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/OnlineGames/OnlineGame/GetStandardGames", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var easyresults = json.data;
                    // bring all child arrays of objects up to the top level array
                    easyresults = Object.values(easyresults);
                    easyresults = easyresults.flat();
                    this.allGames = easyresults;
                    this.filterGame();
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            filterGame(){
                this.allGames.forEach(element => {
                    // console.log(element.gameType);
                    if(element.gameType == 1){
                        this.easyListGames.push(element);
                    }
                });
            }
        },
        computed: {
            filteredGames() {
                const self = this;
                let parsedObj = JSON.parse( JSON.stringify(self.easyListGames) )
                return parsedObj
                
            }
        }
    };
</script>


<!-- style lang="scss" scoped>
    /* style the unique aspects here */
</style -->