<template>
    <span>
        <ul class="winningNumbersList" v-if="type !== 1">
            <li v-for="(number, index) in numbersData" :index="index" :key="number.Id" style="display: inline-block;" :class="game">
                <span v-if="index == 6" class="winNumBall specialBall">x {{ number.Value }}</span>
                <span v-if="index == 5" :class="ballClass">{{ number.Value }}</span>
                <span v-if="index !== 5 && index !== 6" class="winNumBall">{{ number.Value }}</span>
            </li>
        </ul>
        <ul class="winningNumbersList" v-if="type === 1">
            <li v-for="(number, index) in numbersData" :index="index" :key="number.id" style="display: inline-block;" :class="game">
                <span v-if="index == 6" class="winNumBall specialBall">x {{ number.value }}</span>
                <span v-if="index == 5" :class="ballClass">{{ number.value }}</span>
                <span v-if="index !== 5 && index !== 6" class="winNumBall">{{ number.value }}</span>
            </li>
        </ul>
    </span>
</template>

<script>
export default {
    props: ['numbers', 'game', 'type'],
    components: {
    },
    data() {
        return {
            numbersData: this.numbers,
            gameData: this.game,
            games: [
                'specialBall megaplier',
                'specialBall powerball',
                'specialBall kicker',
                'specialBall luckyball'
            ],
            classes: [
                'ball_yellow',
                'ball_red',
                'ball_classic',
                'ball_yellow'
            ],
            ballClass: 'winNumBall'
        }
    },
    mounted: function () {

        // console.log('----', this.game);
        console.log(this.numbers)


        if (this.game == 'mega' || this.game == 'lucky') {
            this.ballClass = 'winNumBall ball_yellow';
            // const itemSix = document.querySelector('.winningNumbersItem.mega li:nth-child(6) .winNumBall, .winningNumbersItem.lucky li:nth-child(6) .winNumBall');

            // if (!itemSix.classList.contains('specialBall')) {
            //     itemSix.classList.add('ball_yellow');
            // }
        }

        if (this.game == 'power') {
            this.ballClass = 'winNumBall ball_red';
            // const itemSix = document.querySelector('.winningNumbersItem.power li:nth-child(6) .winNumBall');

            // if (!itemSix.classList.contains('specialBall')) {
            //     itemSix.classList.add('ball_red');
            // }
        }

    },
    methods: {
        setNumbersData() {
            this.numbersData = this.numbers;
        },
        setGameData() {
            this.gameData = this.game;
        },
        specialBall: function (index) {
            if (index == 5) {
                return this.classes[this.game] + ' winNumBall';
            } else if (index == 6) {
                return this.games[this.game]
            } else {
                return 'winNumBall';
            }
        }
    },
    watch: {
        numbers() {
            this.setNumbersData();
        },
        game() {
            this.setGameData();
        }
    }
}
</script>

<style lang="css"></style>