<template>
    <article>
        <SignInRegisterVue v-if="!isUserLogged"></SignInRegisterVue>
        <span v-if="isUserLogged && loaded">
            <h1 class="productName">
                {{ name }}
            </h1>
            <section>
                <section class="productImg cf">
                    <a class="enalrgeProductImg productDetailImg" title="Title Here" target="_blank" @click="openImage">
                        <img :src="image" alt="">
                    </a>
                    <br/>
                    <a class="enalrgeProductImg productDetailImg" title="Title Here" target="_blank" @click="openImage">
                        View Larger
                    </a>
                </section>
                <section class="productOrderForm cf">
                    <div class="CartItemSelectorContainer">
                        <div class="TotalPriceContainer">
                            <span class="TotalPrice">{{ price }} Points</span>
                        </div>
                        <div class="AddToCartContainer add-to-card-container control-group-inline">
                            <DetailCoupon v-if="pointsAvailable && coupon && kyc" :data="{
                                isUserLogged: this.isUserLogged,
                                userPoints: this.userPoints,
                                pointsAvailable: this.pointsAvailable,
                                category: this.category,
                                description: this.description,
                                image: this.image,
                                name: this.name,
                                parameter1: this.parameter1,
                                parameter2: this.parameter2,
                                parameter3: this.parameter3,
                                parameter4: this.parameter4,
                                parameter5: this.parameter5,
                                price: this.price,
                                productId: this.productId,
                                skuNumber: this.skuNumber,
                                stock: this.stock,
                                token: this.token,
                                user: this.user,
                                address: this.address
                            }"></DetailCoupon>
                            <span v-else v-html="kycStatic"></span>
                            <p v-if="!pointsAvailable">You do not have enough points for this product.</p>
                        </div>
                    </div>
                </section>
                <section class="productDescription" v-html="description"></section>
                <div id="modal-image" class="reveal modal-image reveal-modal" data-reveal>
                    <section class="shoppingCartImage">
                        <picture class="productImg">
                            <img :src="image" class="fullSizeImg">
                        </picture>
                    </section>
                </div>
            </section>
        </span>
    </article>
</template>

<script>
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";
    import DetailCoupon from './ProductDetailCoupon.vue';
    import SignInRegisterVue from "../../ViewComponents/SignInRegister/SignInRegister.vue"

    export default {
        components:{
            DetailCoupon,
            SignInRegisterVue
        },
        data: function () {
            return {
                id: 0,
                isUserLogged: false,
                userPoints: 0,
                pointsAvailable: false,
                category: '',
                description: '',
                image: '',
                name: '',
                parameter1: '',
                parameter2: '',
                parameter3: '',
                parameter4: '',
                parameter5: '',
                price: 0,
                productId: 0,
                skuNumber: '',
                stock: 0,
                token: {},
                user: {},
                address: '',
                purchase: false,
                message: '',
                drawing: false,
                catalog: false,
                coupon: false,
                loaded: false,
                kyc: true,
                kycStatic: '',
                userId: ''
            }
        },
        mounted: function () {
            console.log('CatalogProduct Detail Loaded')
            this.isUserLogged = this.isUserAuthed();            
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.fetchData(token);
                    this.token = token;
                });
            },
            fetchData(token) {
                let idTest = document.getElementById("id").value;
                
                this.id = idTest;
                
                var apiToken = token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetProductDetail?productId=' + this.id;

                return fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let data = json.data;

                    //NOTE This is assuming a ~ is in poition 0
                    let image = data.image,
                        imageSplit = image.split("~");
                    console.log(data);
                    
                    this.category = data.category;
                    this.description = data.description;
                    this.image = imageSplit[1];
                    this.name = data.name;
                    this.parameter1 = data.parameter1;
                    this.parameter2 = data.parameter2;
                    this.parameter3 = data.parameter3;
                    this.parameter4 = data.parameter4;
                    this.parameter5 = data.parameter5;
                    this.price = data.price;
                    this.productId = data.productId;
                    this.skuNumber = data.skuNumber;
                    this.stock = data.stock;

                    if (this.category === 'Weekly' || this.category === 'Monthly' || this.category === 'Yearly') {
                        this.drawing = true;
                    } else if (this.category === '') {
                        this.coupon = true;
                    } else {
                        this.catalog = true;
                    }

                    let points = 0;

                    if (this.isUserLogged) {
                        this.getUserPoints();
                        this.user = this.$cookies.get('apiToken');

                        points = this.user.user.pointBalance.balance;
                        // Check to be sure this works?
                        this.address = this.user.user.address;
                        this.userPoints = points;
                        // this.kyc = this.user.user.KYCVerified;
                        this.userId = this.user.user.userId;
                        this.getKYCVerified();

                        this.kyc === false ? this.verifyKYC() : false;
                    }

                    this.userPoints > data.price ? this.pointsAvailable = true : this.pointsAvailable = false;
                    this.loaded = true;
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
           openPurchase() {
            let popup = new Foundation.Reveal($('#modal-checkout'));
            popup.open();
           },
           openImage() {
            let popup = new Foundation.Reveal($('#modal-image'));
            popup.open();
           },
           confirmPurchase() {
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/PerformPurchase',
                body = {
                    "userId": this.user.user.userId,
                    "productId": this.productId,
                    "qty": 1
                };
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': "Bearer " + this.token
                },
            })
            .then((response) => response.json())
            .then((json) => {
                let res = json;

                if (res.data === null && res.error != null) {
                    this.message = res.error.message;
                    this.purchase = true;
                } else {
                    this.message = "Thank you for your purchase";
                    this.purchase = true;
                }
            })
           },
           verifyKYC() {
                let apiToken = this.token,
                    sku = this.getURLQuery('orderId'),
                    url = process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=mlr-rewards-kyc';

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    console.log(json)
                    this.kycStatic = json.data[0].centralContent;
                    //try setting the current url into localStorage
                    let pathname = window.location.href;
                    localStorage.setItem("kycReturnLink", pathname);
                })
                .catch((error) => {
                    return (this.errorMessage = error);
                });
            },
            getKYCVerified() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.userId;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json.data);
                    // this.kyc = json.data.userIsVerified;
                    // this.verifyLink = json.data.kycVerificationLink;
                    // this.kyc = true;
                    console.log(`KYC : ${this.kyc}`);
                    // console.log(`Link URL : ${this.verifyLink}`);
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>

</style>