import Vue from "vue";

import CheckNumbers from "./CheckNumbers.vue";

export function load() {
    const selector = "check-numbers-home";

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return;
  }

  new Vue({
    render: createElement =>
      createElement(CheckNumbers, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}
