// NOTE Check Your Numbers Module

// Game Markup
// import * as vueMixins from '../../vueMixins';

// const mixin = vueMixins.default.methods;

export default {
    namespaced: true,
    state: () => ({
        tabs: {
            megaMillions: "MegaMillions",
            luckyForLife: "LuckyForLife",
            classic: "ClassicLotto",
            rollingCash: "RollingCashFive",
            powerBall: "PowerBall",
            pick3: "Pick3",
            pick4: "Pick4",
            pick5: "Pick5"
        },
        tabClasses: ['mega', 'power', 'lucky', 'classic', 'rolling', 'pick3', 'pick4', 'pick5'],
        games: [
            {
                tabClass: 'mega',
                title: 'Mega Millions',
                gameName: "megaMillions",
                titleGameName: "Mega Millions",
                descGame: 'The Mega Millions drawing is held every Tuesday and Friday',
                threadNames: ['Date','Winning Numbers','Megaplier','Jackpot Amount'],
                inputsQuantity: parseInt(6),
                startNumber: parseInt(75),
                endNumber: parseInt(1),
                formatPickDate: false,
                pastResults: true,
                winner: true,
                frequentlyNumbers: true
            },
            {
                tabClass: 'power',
                title: 'Power',
                gameName: 'powerBall',
                titleGameName: 'Powerball',
                descGame: 'The Powerball drawing is held every Monday, Wednesday and Saturday.',
                threadNames: ['Date','Winning Numbers','PP#','Est. Jackpot'],
                inputsQuantity: parseInt(6),
                startNumber: parseInt(69),
                endNumber: parseInt(1),
                formatPickDate: false,
                pastResults: true,
                winner: true,
                frequentlyNumbers: true
            },
            {
                tabClass: 'lucky',
                title: 'Lucky For Life',
                gameName: 'luckyForLife',
                titleGameName: 'Lucky For Life',
                descGame: 'The Lucky For Life drawing is held Daily',
                threadNames: ['Date', 'Winning Numbers'],
                inputsQuantity: parseInt(6),
                startNumber: parseInt(48),
                endNumber: parseInt(1),
                formatPickDate: false,
                pastResults: true,
                winner: true,
                frequentlyNumbers: true
            },
            {
                tabClass: 'classic',
                title: 'Classic Lotto',
                gameName: 'classicLotto',
                titleGameName: 'Classic Lotto',
                descGame: 'The Classic Lotto drawing is held every Monday, Wednesday, and Saturday.',
                threadNames: ['Date', 'Winning Numbers', 'KICKER' ,'Jackpot Amount', 'Payouts'],
                inputsQuantity: parseInt(6),
                startNumber: parseInt(49),
                endNumber: parseInt(1),
                formatPickDate: false,
                pastResults: true,
                winner: true,
                frequentlyNumbers: true
            },
            {
                tabClass: 'rolling',
                title: 'Rolling Cash 5',
                gameName: 'rollingCashFive',
                titleGameName: 'Rolling Cash 5',
                descGame: 'The Rolling Cash 5 drawing is held Daily.',
                threadNames: ['Date', 'Winning Numbers', 'Jackpot Amount', 'Payouts'],
                inputsQuantity: parseInt(5),
                startNumber: parseInt(39),
                endNumber: parseInt(1),
                formatPickDate: false,
                pastResults: false,
                winner: true,
                frequentlyNumbers: true
            },
            {
                tabClass: 'pick3',
                title: 'Pick 3',
                gameName: 'pick3',
                titleGameName: 'Pick 3',
                descGame: 'The Pick 3 drawing is held Twice Daily',
                threadNames: ['Date', 'Draw', 'Numbers', 'Total Payout'],
                inputsQuantity: parseInt(3),
                startNumber: parseInt(9),
                endNumber: parseInt(0),
                formatPickDate: true,
                pastResults: true,
                winner: true,
                frequentlyNumbers: true
            },
            {
                tabClass: 'pick4',
                title: 'Pick 4',
                gameName: 'pick4',
                titleGameName: 'Pick 4',
                descGame: 'The Pick 4 drawing is held Twice Daily',
                threadNames: ['Date', 'Draw', 'Numbers', 'Total Payout'],
                inputsQuantity: parseInt(4),
                startNumber: parseInt(9),
                endNumber: parseInt(0),
                formatPickDate: true,
                pastResults: true,
                winner: true,
                frequentlyNumbers: true
            },
            {
                tabClass: 'pick5',
                title: 'Pick 5',
                gameName: 'pick5',
                titleGameName: 'Pick 5',
                descGame: 'The Pick 5 drawing is held Twice Daily',
                threadNames: ['Date', 'Draw', 'Numbers', 'Total Payout'],
                inputsQuantity: parseInt(5),
                startNumber: parseInt(9),
                endNumber: parseInt(0),
                formatPickDate: true,
                pastResults: false,
                winner: true,
                frequentlyNumbers: true
            }
        ]
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        getGames(state) {
            return state.games;
        },
        getTabClasses(state) {
            return state.tabClasses;
        }
    }
}