<template>
    <div class="winning-numbers-wrapper">

        <Pagination v-if="totalPages > 1" :totalPages="totalPages" :itemsPerPage="itemsPerPage" :currentPage="currentPage" @changePage="changePage"></Pagination>
        <article class="drawingList drawingListMobile DrawingWinners drawingListWinnersResponsive">
            <section class="drawingWinnersList entryList cf">
                <ul class="listHeader cf">
                    <li class="listHead dwName">
                        Winner Name & Drawing
                    </li>
                    <li class="listHead dwDrawing">
                        Date
                    </li>
                </ul>
                <ul>
                    <li class="stRow cf" v-for="winner in currentItems" :key="winner.itemID">
                        <div class="drawHisLI">
                            <div class="singleWinner">
                                <span class="drawWinnerName">
                                    {{ winner.fullName }}
                                </span>,
                                <span class="drawWinnerCity">
                                    {{ winner.location }}
                                </span>
                            </div>
                        </div>
                        <div class="drawHisLI drawWinnerDate cf">
                            {{ new Date(winner.winDate) | moment("MM/DD/YYYY") }}
                        </div>
                        <div class="drawHisLI drawingWinnersDrawingName cf">
                            {{ winner.prizeDescription }}
                        </div>
                    </li>
                </ul>
            </section>
        </article>
    </div>
</template>


<script>
    import Pagination from '../Pagination/pagination.vue';

    export default {
        components: {
            Pagination
        },
        data: function () {
            return {
                token: '',
                items: [],
                totalPages: 1,
                itemsPerPage: 1,
                currentPage: 1,
                currentItems: []
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.getWinners(token);
                })
            },
            getWinners(token) {
                let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/GetCompletedDrawingsWithWinners',
                    apiToken = token;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken,
                    },
                })
                .then(response => response.json())
                .then((json) => {
                    let drawings = json.data,
                        winners = [];

                    for (let i = 0; i < drawings.length; i++) {
                        for (let w = 0; w < drawings[i].winners.length; w++) {
                            winners.push(drawings[i].winners[w]);
                        }
                    }

                    winners.sort(function (a, b) {
                        return new Date(b.winDate) - new Date(a.winDate);
                    })

                    this.items = winners;
                    this.setPages();
                    this.setItems();
                });
            },
            setPages() {

                this.itemsPerPage = 50;
                this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
            },
            changePage(p) {
                this.currentPage = p;
                this.setItems();
            },
            setItems() {
                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.items.slice(start, end);

                this.currentItems = currentItems;
            }
        },
        watch: {
            
        }
    };
</script>

<style lang="scss" scoped>
</style>