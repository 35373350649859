<template>
    <div>
        Default!
    </div>
</template>
<script>

    export default {
        data: function () {
            return {
            }
        },
        mounted: function () {
            this.getToken()
        },
        methods: {
            getToken() {
                let grabToken = async() => {
                    // await Promise.resolve(this.storeToken());
                    console.log('broken promises... storeToken() was removed');
                }
                
                grabToken()
                .then(() => {
                    console.log('done')
                    return "done";
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>