import Vue from "vue";

import PrincipalRegistration from "./PrincipalRegistrationForm.vue";

export function load() {
    const selector = "principal-registration";

    // Is the custom Vue root element in the DOM?
    if (!document.querySelector(selector)) {
        return; // go no further
    }

    // Create a new Vue app with the imported component
    new Vue({
        render: createElement =>
            createElement(PrincipalRegistration, {
                props: {
                    ...window[selector]
                }
            }),
    }).$mount(selector);
}
