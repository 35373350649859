<template>
    <div id="ftfEventComponent">
        <SignInRegister v-if="!isUserLogged"></SignInRegister>
        <div v-else>
            <div class="ftfEventHeader" v-if="allowAccess">
                <img alt="Fun Turns Fifty Event Registration" class="img-responsive ftfEventHeaderMobile" src="/getattachment/17dd9bc6-c0e8-4880-aa4c-94609abb4d9b/olc22903-1_50thFacebookHeader_550x550.jpg?chset=58a81b8b-305a-4cc6-a5ea-f8229c743601" /> 
                <img alt="Fun Turns Fifty Event Registration" class="img-responsive ftfEventHeaderDesktop" src="/getattachment/0e277e67-956b-4eaa-b025-e8b40278f91d/olc22903-1_50thFacebookHeader_864.jpg?chset=6aeb270b-3ac3-435f-b7d4-fbcd1625cd2c" />
            </div>
            <div class="ftfEventStars" v-if="allowAccess">
                <div class="module">
                    <h4>How to register</h4>
                    <p>Click here to complete registration and receive your winner pack.</p>
                    <a class="button btn-tertiary" href="https://www.olcfunturns50.com/registration" target="_blank">Register</a>
                </div>
                <div class="module">
                    <h4>Join the winner&#39;s Facebook Group</h4>
                    <p>Join the private 50th Anniversary Winner Facebook Group to connect with winners and receive event details.</p>
                    <a class="button btn-tertiary" href="https://www.facebook.com/groups/funturns50">Join</a>
                </div>
                <div class="module">
                    <h4>We want to meet our 150 lucky winners!</h4>
                    <p>Upload a photo of yourself to be featured at the event and on social media.</p>
                    <div id="ftfPhotoForm"></div>
                </div>
            </div>
            <div class="ftfEventInfo module_callOut" v-if="allowAccess">
                <h3>FAQs</h3>
                <p>Click the link below to view answers to your frequently asked questions.</p>
                <a href="/mylotto-rewards/fun-turns-fifty-event-registration/faq" class="button btn-secondary">View FAQs</a>
                <h3>Need help?</h3>
                <p>Registration is performed by a third-party vendor called ATLAS. If you have trouble with the registration process, contact an ATLAS customer service representative: <a href="mailto:customerservice@atlasexperiences.com">customerservice@atlasexperiences.com</a> | 404-790-6394</p>
                <h3>Email updates</h3>
                <p>Our event partner, ATLAS, will send emails with more details and updates leading up to the event.</p>
            </div>
        </div>
    </div>
</template>
<script>
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
            SignInRegister
        },
        data: function() {
            return {
                isUserLogged: false,
                id: '',
                token: {},
                user: {},
                roles: [],
                allowAccess: null
            }
        },
        mounted: function() {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();

            let photoUploadBtn = document.querySelector('input[type="submit"]');
            photoUploadBtn.classList.add('button', 'btn-primary');

            let ktc = document.getElementsByClassName('ktc-default-section');

            let parent = ktc[0].parentElement.parentElement.parentElement;

            parent.id = "ktc-gotcha";
            parent.style.display = "none";

            // this.placeForm(parent);

            document.onreadystatechange = () => {
                if (document.readyState === 'complete') {
                    this.placeForm(parent);
                }
            }
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                        this.token = token;
                        // console.log(this.token);

                        if (this.isUserLogged) {
                            this.user = this.$cookies.get('apiToken');
                            this.id = this.user.user.userId;
                            this.roles = this.user.user.listOfRoles;
                            console.log("+++ THIS USER +++");
                            console.log(this.roles);
                        }
                    })
                    .then(() => {
                        this.determineUserAccess(this.roles);
                    })
            },
            determineUserAccess(roles) {
                console.log(roles);
                if (roles.includes('Fun50Event')) {
                    console.log('Is in role');
                    this.allowAccess = true;
                }
            },
            placeForm(parent) {
                let formHTML = document.querySelector('#ftfPhotoForm');
                let oldForm = parent;

                formHTML.appendChild(oldForm);
                parent.style.display = "block";

            }
        }

    }
</script>
<style lang="scss" scoped>
    #ktc-gotcha {
        input[type="submit"] {
            background-color: #0FD8C9;
        }
    }
</style>