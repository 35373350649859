<template>
    <div class="reveal" id="USPSmodal" data-reveal data-options="closeOnClick:false; closeOnEsc:false;">
        <h1>Address Confirmation<span v-if="title">: </span>{{ title }}</h1>
        <p>
            Please review the verified United States Postal Service address shown below.
            Click the Confirm button to proceed with registration using the verified address
            or click on Edit to revise your address.
        </p>
        <div class="grid-x">
            <div class="cell large-6 userAddress addressBox">
                <h3>Submitted Address</h3>
                <p>{{ this.mailingAddress }}</p>
                <p v-if="this.addressContinued">{{ this.addressContinued }}</p>
                <p>{{ this.city }}</p>
                <p>{{ this.state }}</p>
                <p>{{ this.zipcode }} <span v-if="zipFour"> - {{ this.zipFour }}</span></p>
            </div>

            <div class="cell large-6 USPSaddress addressBox">
                <h3>Verified USPS Address</h3>
                <div v-if="allowMultiple === true">
                    <p v-if="isProvidedAddressValid === false">
                        We could not verify the address supplied. Please review it for accuracy and
                        make any necessary changes before continuing.
                    </p>
                    <div v-else>
                        <p>{{ this.verifiedMailingAddress }}</p>
                        <p>{{ this.verifiedCity }}</p>
                        <p>{{ this.verifiedState }}</p>
                        <p>{{ this.verifiedZipcode }} <span v-if="verifiedZipFour"> - {{ this.verifiedZipFour }}</span></p>
                    </div>
                </div>
                <div v-else>
                    <p v-if="isProvidedAddressValid === false">
                        We could not verify the address supplied. Please review it for accuracy and
                        make any necessary changes before continuing.
                    </p>
                    <p v-else-if="isSuggestedAddressAvailable === false">
                        The <em>Suggested Address</em> is already registered. It may already be registered to another account. Please check the address and try again.
                    </p>
                    <div v-else>
                        <p>{{ this.verifiedMailingAddress }}</p>
                        <p>{{ this.verifiedCity }}</p>
                        <p>{{ this.verifiedState }}</p>
                        <p>{{ this.verifiedZipcode }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="ctaAddress">
            <button class="cancelAddress button" @click="close">Edit</button>
            <button class="submitAddress button" @click="verified" v-if="isSuggestedAddressAvailable || allowMultiple === true && isProvidedAddressValid">Confirm</button>
        </div>
        <p>If you have questions or are having trouble registering, please <a href="#">contact us.</a></p>
    </div>
</template>

<script>
// a "mustBeAvailable" Bool will be passed in along with the address
// send supplied address values to API: /1.0/PAM/ValidateAddress
// Expected params:
// {
//     "address": {
//         "address": "string",
//         "address2": "string",
//         "city": "string",
//         "state": "string",
//         "zipCode": "string"
//     }
// }
// if "mustBeAvailable" is true, then only show the modal if "isSuggestedAddressAvailable" returns true.

// If Error, then return the error message back with the key "addressError".

// If a new address is returned without error then compare it to
// the original address using compareAddresses(). 
// 
// Display original address values in the "userAddress" box,
// Display returned address values in the "USPSaddress" box,
// Open the modal.

// Edit button closes modal without any changes, sends the sender back 
// Confirm button closes modal and passes new address values from API back to calling component.

export default {
    props: [
        // Expected props
        'mailingAddress',
        'addressContinued',
        'city',
        'state',
        'zipcode',
        'zipFour',
        'country',
        'title',
        'allowMultiple'
    ],
    components: {},
    data: function () {
        return {
            token: '',
            verifiedAddress: '',
            verifiedMailingAddress: '',
            verifiedCity: '',
            verifiedState: '',
            verifiedZipcode: '',
            verifiedZipFour: '',
            isProvidedAddressAvailable: null,
            isSuggestedAddressAvailable: null,
            isProvidedAddressValid: null,
            passthrough: ''
        }
    },
    mounted: function () {
        this.getToken();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                });
        },
        verified() {
            // On "Confirm" click this will call a method on the parent component that will submit the USPS address.
            if (this.allowMultiple === false || this.allowMultiple === null || this.allowMultiple === undefined) {
                this.$parent.uspsVerified({
                    address: this.verifiedAddress,
                    mailingAddress: this.verifiedMailingAddress,
                    city: this.verifiedCity,
                    state: this.verifiedState,
                    zip: this.verifiedZipcode,
                    isProvidedAddressValid: this.isProvidedAddressValid,
                    isProvidedAddressAvailable: this.isProvidedAddressAvailable,
                    isSuggestedAddressAvailable: this.isSuggestedAddressAvailable,
                    passthrough: this.passthrough
                });
            } else {
                this.$parent.uspsVerified({
                    address: this.verifiedAddress,
                    mailingAddress: this.verifiedMailingAddress,
                    city: this.verifiedCity,
                    state: this.verifiedState,
                    zip: this.verifiedZipcode,
                    zipFour: this.verifiedZipFour,
                    isProvidedAddressValid: this.isProvidedAddressValid,
                    isProvidedAddressAvailable: this.isProvidedAddressAvailable,
                    isSuggestedAddressAvailable: this.isSuggestedAddressAvailable,
                    passthrough: this.passthrough
                });
            }
            
        },
        close() {
            // On "Edit" click this will call a method on the parent component that will close the modal
            this.$parent.uspsClose();
        },
        validate(msg) {
            if (msg != null | msg != undefined) {
                this.passthrough = msg;
            }

            let apiToken = this.token;

            // console.log(this.mailingAddress)
            // console.log(this.addressContinued)
            // console.log(this.city)
            // console.log(this.state)
            // console.log(this.zipcode)
            // console.log(this.zipFour)

            let bodyAddress;

            if (this.addressContinued === undefined) {
                this.addressContinued = null;
            }

            if (this.allowMultiple === false || this.allowMultiple === null || this.allowMultiple === undefined) {
                bodyAddress = {
                    "address": {
                        "address": this.mailingAddress,
                        "address2": this.addressContinued,
                        "city": this.city,
                        "state": this.state,
                        "zipCode": this.zipcode,
                        "plus4": ""
                    },
                    "customerId": 0
                };
            } else {
                bodyAddress = {
                    "address": {
                        "address": this.mailingAddress,
                        "address2": this.addressContinued,
                        "city": this.city,
                        "state": this.state,
                        "zipCode": this.zipcode,
                        "plus4": this.zipFour
                    },
                    "customerId": 0
                };
            }

            // console.log(bodyAddress);

            const requestAddressOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(bodyAddress)
            };

            fetch("https:" + process.env.VUE_APP_APIURL + "/1.0/PAM/ValidateAddress", requestAddressOptions)
                .then(response => response.json())
                .then(data => {
                // console.log('--------', data.data);
                    if (data.code === "1") {
                        alert("Your session has expired, please refresh the page and try again.");
                    }

                    if (data.data !== null) {
                        this.isProvidedAddressValid = data.data.isProvidedAddressValid;
                        this.isProvidedAddressAvailable = data.data.isProvidedAddressAvailable;
                        this.isSuggestedAddressAvailable = data.data.isSuggestedAddressAvailable;

                        // console.log(data.data.isProvidedAddressValid)
                        // console.log(this.allowMultiple)

                        if (data.data.isProvidedAddressAvailable && data.data.isSuggestedAddressAvailable || this.allowMultiple === true && data.data.isProvidedAddressValid === true) {

                            // console.log('Address Confirmed')

                            if (bodyAddress.address.address2 === '') {
                                bodyAddress.address.address2 = null
                            }

                            let newAddress = bodyAddress.address;
                            let returnAddress = data.data.address;

                            // console.log(newAddress)
                            // console.log(returnAddress)

                            if (JSON.stringify(newAddress) === JSON.stringify(returnAddress)) {
                                // console.log('are same objects')

                                this.verifiedAddress = true;
                                this.confirmBodyAddress = returnAddress;
                                this.verifiedMailingAddress = returnAddress.address;
                                this.verifiedCity = returnAddress.city;
                                this.verifiedState = returnAddress.state;
                                this.verifiedZipcode = returnAddress.zipCode;
                                this.verifiedZipFour = returnAddress.plus4;

                                this.verified();
                            } else {
                                // console.log(returnAddress);
                                // console.log(returnAddress.address);

                                this.verifiedAddress = true;
                                this.confirmBodyAddress = returnAddress;
                                this.verifiedMailingAddress = returnAddress.address;
                                this.verifiedCity = returnAddress.city;
                                this.verifiedState = returnAddress.state;
                                this.verifiedZipcode = returnAddress.zipCode;
                                this.verifiedZipFour = returnAddress.plus4;

                                this.$parent.uspsOpen();
                            }
                        } else {
                            this.$parent.uspsOpen();
                        }
                    } else if (data.error !== null) {
                        this.$parent.uspsOpen();
                    }
                })
        },
        compareAddresses() {
            if (
                this.user_Address1 == this.USPS_Address1 &&
                this.user_Address2 == this.USPS_Address2 &&
                this.user_City == this.USPS_City &&
                this.user_State == this.USPS_State &&
                this.user_Zip == this.USPS_Zip &&
                this.user_ZipFour === this.USPS_ZipFour
            ) {
                // addresses already match
                this.acceptNewAddress = true;
                // do not show modal

            } else {
                // populate and display modal

            }

        }
    },
    computed: {
        dataProp: function () {
            return this.dataProp;
        }
    },
    watch: {
       // mailingAddress: function (newVal) {
       //     this.form.mailingAddress = newVal;
       // },
       // addressContinued: function (newVal) {
       //     this.form.addressContinued = newVal;
       // },
       // city: function (newVal) {
       //     this.form.city = newVal;
       // },
       // state: function (newVal) {
       //     this.form.state = newVal;
       // },
       // country: function (newVal) {
       //     this.form.country = newVal;
       // }
    }
};
</script>
<style lang="scss" scoped>
.ctaAddress{
    display: flex;
    .button{
        &+.button{
            margin-left: 1em;
        }
    }
}
</style>