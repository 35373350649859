import Vue from "vue";

import Pagination from "./pagination.vue";

export function load() {
    const selector = "pagination";

    // Is the custom Vue root element in the DOM?

    if (!document.querySelector(selector)) {
        return;
    }

    // Create a new Vue app with the imported Home component

    new Vue({
        render: createElement =>
            createElement(Pagination, {
                props: {
                    ...window[selector],
                },
            }),
    }).$mount(selector);
}
