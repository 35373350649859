<template>
    <div class="full social_promo_wrapper eclipse_social noGutter">
        <header class="social_promo_header">
            <div class="social_soLogo"><img src="/getattachment/93d38ca3-d776-42b7-b4df-4916be31bb3a/rockpaperscissors_scratch_logo.png" alt="Scratch-Offs Logo" class="img-responsive"></div>
            <div class="social_eclipseLogo"><img src="/getattachment/7b0675a8-df56-4b23-bd66-1ae1b5a7be7d/eclipse_odds_logo.png" alt="What are the odds?" width="658" class="img-responsive"></div>
            <div class="social_mlrLogo"><img src="/getattachment/b6c14984-4eb8-4fb2-8c1c-75752307cb0a/rockpaperscissors_oh_logo.png" alt="Ohio Lottery Logo" class="img-responsive"></div>
        </header>
        <SignInRegister v-if="!isUserLogged" class="eclipse_signin"></SignInRegister>
        <section v-if="isUserLogged" class="eclipse_intro">
            <div class="eclipse_intro_text" v-if="step === 0">
                <h1>COUNTDOWN TO THE SOLAR<br> ECLIPSE GIVEAWAY</h1>
                <p>Test your eclipse trivia knowledge and win up to $300 in total prizes! Two winners will win a book of $10 Mega Multiplier (Gigantix) Scratch-Offs.</p>
                <p>Winners will be announced on Monday, April 8th, 2024, on The Ohio Lottery Facebook page.</p>
                <button class="button" @click="startSurvey">Play Now</button>
                <a href="/eclipse-social-promotion/eclipse-social-promotion-rules">See Complete Game Rules</a>
            </div>
            <div class="eclipse-survey" v-if="step === 1">
                <SurveyModule :surveys="surveyArray"></SurveyModule>
            </div>
            <div v-if="step === 3" class="eclipse_success">
                <h1>YOU'RE ENTERED<br> TO WIN!</h1>
                <p>Winners will be announced on Monday, April 8th, 2024, on The Ohio Lottery Facebook page.</p>
                <a class="button btn-primary" href="">Ohio Lottery Facebook</a>
            </div>
        </section>
        <!-- Other -->
        <!-- <section v-if="!currentQuestionAnswered" class="eclipse_success">
            <div>
                <h2>YOU'RE ENTERED<br> TO WIN!</h2>
                <p>Winners will be announced on Monday, April 8th, 2024, on The Ohio Lottery Facebook page.</p>
                <a class="button btn-primary" href="">Ohio Lottery Facebook</a>
            </div>
        </section> -->
        <section class="countdown-timer">
            <div><span class="countdown-timer-panel">{{ days }}</span><span>DAYS</span></div>
            <div><span class="countdown-timer-panel">{{ hours }}</span><span>HRS</span></div>
            <div><span class="countdown-timer-panel">{{ minutes }}</span><span>MINS</span></div>
            <div><span class="countdown-timer-panel">{{ seconds }}</span><span>SECS</span></div>
        </section>
        <!-- <dialog id="gameRulesModal">
            <div class="gameRulesClose">
                <a @click="closeGameRules">X</a>
            </div>
            <div class="gameRulesContent">
                <h3>GAME RULES</h3>
                <p>On April 8, 2024, many Ohioans will be in the path of totality for a solar eclipse! What better way to celebrate this event with a MEGA fun giveaway?</p>
                <p>Two winners will win a book of $10 Mega Multiplier (Gigantix) scratch-offs.</p>
                <p>Visit this page each day to answer a piece of eclipse trivia. Each time you participate earns you an entry into the drawing. You may enter a max of once per day with a maximum possible total number of five entries.</p>
                <p>Winners will be announced on the day of the solar eclipse &ndash; April 8!</p>
            </div>
        </dialog> -->
    </div>
</template>
<script>
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';
    import SurveyModule from '/Views/SurveyWidget/SurveyWidget.vue';

    export default {
        components: {
            SignInRegister,
            SurveyModule
        },
        data: function() {
            return {
                isUserLogged: false,
                token: {},
                id: '',
                step: 0,
                targetDate: new Date('2024-04-08T13:59:00'),
                countDownInterval: null,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                surveyStarted: false,
                currentSurvey: null,
                surveyArray: ['eclipse_day_1','eclipse_day_2','eclipse_day_3','eclipse_day_4','eclipse_day_5']
            }
        },
        mounted: function() {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();
        },
        created: function() {
            this.startCountdown();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                  // this.fetchData(token);
                  this.token = token;
                  // console.log('Hello World');
              }).then(() => {
                this.id = this.getLoggedInUserId();
              });
            },
            startSurvey() {
                this.step = 1;
            },
            startCountdown() {
                this.countDownInterval = setInterval(() => {
                    const now = new Date().getTime();
                    const timeDifference = this.targetDate - now;

                    if (timeDifference > 0) {
                        this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                        this.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        this.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                        this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
                    } else {
                        clearInterval(this.countDownInterval);
                        // handle end?
                    }
                }, 1000)
            },
            showGameRules() {
                document.getElementById('gameRulesModal').showModal();
            },
            closeGameRules() {
                document.getElementById('gameRulesModal').close();
            },
            surveyAnswered() {
                this.step = 3;
            }
        },
        beforeDestroy() {
            clearInterval(this.countDownInterval);
        },
    }
</script>
<style lang="scss" scoped>
    .contentRow.olc_social_promo {
        padding-left: 0;
        padding-right: 0;
    }
    .eclipse_social {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        button.button.btn-primary {
            background-color: #c72127 !important;
        }
    }
    .social_promo_header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 28px;
    }

    .social_eclipseLogo {
        order: 3;
        max-width: 300px;
        margin-top: 20px;
    }

    .social_soLogo {
        max-width: 88px;
    }

    .social_mlrLogo {
        order: 2;
        max-width: 88px;
    }
    .eclipse_intro {
        flex-grow: 1;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }

    .eclipse_intro_text {
        margin: 0 auto;
        max-width: 550px;

        button {
            margin: 0 auto;
            background-color: #c72127;
            margin-bottom: 28px;
        }

        a {
            color: #fff;
            font-weight: 400;
        }
    }

    h1 {
        color: #fff;
        font-family: 'Roboto', Arial, sans-serif;
        font-size: 2.6rem;
        letter-spacing: 0.3px;
    }

    p {
        color: #fff;
        font-weight: 400;
        font-size: 1.6rem;
        font-weight: bold;
    }

    .countdown-timer {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-top: auto;
        margin-bottom: 3rem;


        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 4px;
        }
    }

    .countdown-timer-panel {
        color: #000;
        background-color: #fff;
        display: grid;
        place-items: center;
        font-size: 2.6rem;
        font-weight: 400;
        padding: 1.2rem 1.4rem;
        border-radius: 16px;
        width: 70px;
    }

    #gameRulesModal {
        background-color: rgba(0,0,0,0.9);
        width: 100%;
        min-height: 100vh;
    }

    .gameRulesClose {
        display: flex;
        justify-content: flex-end;

        a {
            text-decoration: none;
            color: #fff;
            font-size: 2.6rem;
        }
    }

    .gameRulesContent {
        text-align: center;

        h3 {
            font-weight: bold;
            color: #fff;
            font-size: 3.8rem;
            margin-bottom: 4rem;
        }
    }

    @media only screen and (min-width: 476px) {
        .social_eclipseLogo {
            order: 2;
            margin-top: unset;
            margin-left:10px;
            margin-right: 10px;
        }
        .social_promo_header {
            justify-content: space-around;
        }
    }

    @media only screen and (min-width: 641px) {
        .social_eclipseLogo {
            order: 2;
        }

        .social_mlrLogo {
        
        }

        #gameRulesModal {
            max-width: 1344px;
            min-height: unset;
        }

        .gameRulesContent {
            padding: 3.6rem 4rem 6rem;
        }
    }

    @media only screen and (min-width: 961px) {
        .social_eclipseLogo {
            max-width: 400px;
        }
        .social_mlrLogo {
           max-width: 100px;
        }
        .social_soLogo {
            max-width: 100px;
        }
    }
</style>