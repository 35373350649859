<template>
    <div class="">
        <header id="eAppHeader" class="cf">
            <h1 class="cf">Retailer Network</h1>
        </header>
        <SignInRegister v-if="!isLoading && !isUserLogged"></SignInRegister>

        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />
        <div v-if="!isLoading && isUserLogged && updateFound" class="eAppUpdates">

            <div class="eAppHeader cf">
                <!-- ifIsInRole: eApp-Retailer || eAppPrincipal -->
                <a v-if="showApplicationUpdatesHome" class="button eAppDashBtn" href="/eApplications/Updates">Application Updates Home</a>
                <!-- ifIsInRole: eApp-Retailer && eApp-ChainApplicant -->
                <a v-if="showApplicationsHome" class="button eAppDashBtn" href="/eApplications">Application Home</a>
                <!-- ifIsInRole: eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-RegionalManager || eAppDistrictRep || eApp-Security -->
                <a v-if="showApplicationDashboard" class="button eAppDashBtn" href="/eApplications/Dashboard/Regular">Application Dashboard</a>
                <!-- ifIsInRole: eApp-ChainSalesRep || eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-Security -->
                <a v-if="showChainApplicationDashboard" class="button eAppDashBtn" href="/eApplications/Dashboard/Chain">Chain Application Dashboard</a>

                <h1>Agent Number: <strong>{{ updateWrapper.agentNumber }}</strong></h1>
                <h3 style="clear:both;">Batch Number: <strong>{{ updateWrapper.batchNumber }}</strong></h3>

            </div>
            <div class="cf updateCreateForm module">
                <div class="moduleContent cf">

                    <div v-if="hasErrors" class="ErrorLabel alert-error">
                        There were errors:
                        <ul>
                            <template v-for="error in errors">
                                <li v-if="error !== ''">
                                    {{ error }}
                                </li>
                            </template>
                        </ul>
                    </div>

                    <W9Update :taxUpdate="taxUpdate" :token="token" :userId="userId" :readOnly="!showSubmitButton"></W9Update>

                </div>
                <div class="moduleContent updatesActions">
                    <button v-if="showRejectButton" class="button btn-secondary eAppBtnSubmit" data-open="rejectModal">Reject</button>
                    <button v-if="showApproveButton" class="button btn-secondary eAppBtnSubmit" data-open="approveModal">Approve</button>
                    <button v-if="showSubmitButton" class="button eAppBtnSubmit" @click="submitClick()">Submit</button>
                </div>

            </div>
        </div>

        <div class="reveal small eAppModal" id="rejectModal" data-reveal>
            <button class="close-button" data-close aria-label="Close modal" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
            <h2>Reject Update</h2>
            <div class="toApplicant">
                <p>
                    Choose the reason(s) for rejection to be included in a message to the retailer:
                </p>
                <div class="rejectOption">
                    <input type="checkbox" id="failedIrs" v-model="failedIrs" /><label for="failedIrs">Failed the IRS check</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedState" v-model="failedState" /><label for="failedState">Failed the Secretary of State check</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedDBA" v-model="failedDBA" /><label for="failedDBA">Failed DBA and/or business address check</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedBank" v-model="failedBank" /><label for="failedBank">Incorrect Bank Account Information</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedPayment" v-model="failedPayment" /><label for="failedPayment">Had a previous bond payment and we need verification of repayment to bond company</label>
                </div>
                <div class="rejectOption">
                    <input type="checkbox" id="failedSignature" v-model="failedSignature" /><label for="failedSignature">Renewal/Updates require a principal signature</label>
                </div>
                <br>
            </div>
            <div class="formActions">
                <button class="btnCancel" @click="closeModal('#rejectModal')" aria-label="Cancel" type="button">Cancel</button>
                <button class="btnSubmitApp button" aria-label="Reject" type="button" @click="rejectClick()">Reject to Retailer</button>
            </div>
            <div v-if="lmRejectError" class="error">Please provide the reason(s) for rejection.</div>
        </div>

        <div class="reveal small eAppModal" id="approveModal" data-reveal>
            <button class="close-button" data-close aria-label="Close modal" type="button">
                <span aria-hidden="true">&times;</span>
            </button>
            <h2>Approve Update</h2>
            <p>Do you wish to approve the Update and move it one step closer to final approval?</p>
            <br>
            <div class="formActions">
                <button class="btnCancel" @click="closeModal('#approveModal')" aria-label="Cancel" type="button">Cancel</button>
                <button class="btnSubmitApp button" aria-label="Approve" type="button" @click="approveClick()">Approve</button>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import moment from "moment";
import { HollowDotsSpinner } from 'epic-spinners';
    import SignInRegister from '/ViewComponents/SignInRegister/SignInRegister.vue';
    import W9Update from '/Views/Eapplication/updates/update-w9.vue';

export default {
    components: {
        HollowDotsSpinner,
        SignInRegister,
        W9Update,
    },
    data() {
        return {
            isUserLogged: false,
            updateGUID: '', // primary identifier
            updateWrapper: {}, // JSON for entire Update
            isUserAllowed: false, // proper role and identified on the Update
            errors: [],
            hasErrors: false,
            isLoading: false,
            updateFound: false,
            userId: 0,
            agentId: '0',
            token: '',
            readOnly: false,
            taxUpdate: {},
            showTaxUpdate: false,
            hasRenewal: false,
            renewal: {},
            failedIrs: false,
            failedState: false,
            failedDBA: false,
            failedBank: false,
            failedPayment: false,
            failedSignature: false,
            showRejectButton: false,
            showApproveButton: false,
            showSubmitButton: false,
            showApplicationUpdatesHome: false,
            applicantRole: ['eApp-Retailer'],
            applicationUpdatesHomeRoles: ['eApp-Retailer', 'eAppPrincipal'],
            showApplicationsHome: false,
            applicationsHomeRoles: ['eApp-Retailer', 'eApp-ChainApplicant'],
            showApplicationDashboard: false,
            applicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eAppDistrictRep', 'eApp-EFTReviewer', 'eApp-RegionalManager', 'eApp-Security'],
            showChainApplicationDashboard: false,
            chainApplicationDashboardRoles: ['eApp-CentralLicensingManager', 'eApp-CentralSalesManager', 'eApp-EFTReviewer', 'eApp-Security','eApp-ChainSalesRep', 'eApp-RegionalManager'],
            rejectModal: false,
            lmRejectError: false,
            successRedirect: '/eapplications/dashboard/regular?appType=updates',
        }
    },
    mounted: function () {
        $(document).foundation();

        this.isUserLogged = this.isUserAuthed();
        if (this.isUserLogged) {
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getContent();
                });
        }

        // get the user ID
        this.userId = this.getLoggedInUserId();
        this.showButtons();

        // get the agent number
        this.agentId = this.getURLQuery('an');
        this.isLoading = false;

    },
    computed: {
        GET_UPDATE_WRAPPER_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/user/' + this.userId + '/reviewupdate/' + this.updateGUID;
        },
        APPROVE_TAX_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/ApproveUpdateTax';
        },
        REJECT_TAX_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/RejectUpdateTaxToApplicant';
        },
        SUBMIT_TAX_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SubmitUpdateTax';
        }
    },
    methods: {
        getContent() {
            this.isLoading = true;
            this.tryGetFromQueryString().then(() => {
                this.getUpdateWrapper();
            });
        },
        showButtons() {
            this.applicationDashboardRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationDashboard = true;
                }
            });

            this.chainApplicationDashboardRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showChainApplicationDashboard = true;
                }
            });

            this.applicationUpdatesHomeRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationUpdatesHome = true;
                }
            });

            this.applicationsHomeRoles.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showApplicationsHome = true;
                }
            });

            this.applicantRole.forEach(element => {
                if (this.isUserInRole(element)) {
                    this.showRejectButton = false;
                    this.showApproveButton = false;
                    this.showSubmitButton = true;
                }
            });
        },
        getUpdateWrapper() {
            if (this.updateGUID == '') {
                this.updateFound = false;
            } else {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    this.userId = uId;
                    this.fetchData();
                }
            }
        },
        async fetchData() {
            const appsRequestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
            };
            this.updateWrapper = await (await fetch(this.GET_UPDATE_WRAPPER_API_URL, appsRequestOptions)).json();

            if (this.updateWrapper) {
                this.updateFound = true;
            } else {
                // using "hasRenewalUpdates" because we don't need to even see this form if there is not an update
                this.updateFound = false;
            }
            this.parseUpdate();
        },
        parseUpdate() {
            this.agentId = this.updateWrapper.agentNumber;

            // is renewal?
            this.hasRenewal = this.updateWrapper.hasRenewal;

            // atax info - for Update + Renewals
            if (this.updateWrapper.taxUpdate !== null) {
                this.taxUpdate = this.updateWrapper.taxUpdate;
            }
            else {
                this.taxUpdate = {};
            }
            
            // console.log('updateWrapper.taxUpdate.elementStatus: '+this.updateWrapper.taxUpdate.elementStatus);
            // is Licensing allowed to take action?
            if (this.updateWrapper.taxUpdate.elementStatus === 1) {
                this.showRejectButton = true;
                this.showApproveButton = true;
                this.showSubmitButton = false;
            }

            this.isLoading = false;
        },
        async rejectClick() {
            this.isLoading = true;
            let body = {};
            let message = '';
            if (this.failedBank || this.failedDBA || this.failedIrs || this.failedPayment || this.failedSignature || this.failedState) {
                message += '<ul>';
                if (this.failedIrs) {
                    message += '<li>Failed the IRS check" Value="Failed the IRS check</li>';
                }

                if (this.failedState) {
                    message += '<li>Failed the Secretary of State check</li>';
                }

                if (this.failedDBA) {
                    message += '<li>Failed DBA and/or business address check</li>';
                }

                if (this.failedBank) {
                    message += '<li>Incorrect Bank Account Information</li>';
                }

                if (this.failedPayment) {
                    message += '<li>Had a previous bond payment and we need verification of repayment to bond company</li>';
                }

                if (this.failedSignature) {
                    message += '<li>Renewal/Updates require a principal signature</li>';
                }
                message += '</ul>';

                body.updateWrapperGuid = this.updateGUID;
                body.userId = this.userId;
                body.message = message;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        body
                    )
                };

                let response = await (await fetch(this.REJECT_TAX_API_URL, appsRequestOptions)).json();
                // redirect if successful
                if (response.errorList.length > 0) {
                    this.errors = response.errorList;
                    this.hasErrors = true;
                    this.isLoading = false;
                    this.closeModal('#rejectModal');
                }
                else {
                    // alert(response);
                    window.location.assign(this.successRedirect);
                } 

                return Promise.resolve();
            } else {
                this.lmRejectError = true;
                this.isLoading = false;
            }
        },
        async approveClick() {
            this.errors = [];
            this.isLoading = true;
            let body = {};
            body.updateWrapperGuid = this.updateGUID;
            body.userId = this.userId;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            let response = await (await fetch(this.APPROVE_TAX_API_URL, appsRequestOptions)).json();
            this.isLoading = false;

            // redirect if successful
            if (response.errorList.length > 0) {
                this.errors = response.errorList;
                this.hasErrors = true;
                this.isLoading = false;
                this.closeModal('#approveModal');
            }
            else {
                window.location.assign(this.successRedirect);
            } 

            return Promise.resolve();
        },
        async submitClick() {
            this.isLoading = true;
            let body = {};
            this.updateWrapper.taxUpdate = this.taxUpdate;
            body.updateWrapper = this.updateWrapper;
            body.userId = this.userId;

            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            await (await fetch(this.SUBMIT_TAX_API_URL, appsRequestOptions)).json();
            this.isLoading = false;

            return Promise.resolve();
        },
        tryGetFromQueryString() {
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);

            if (urlParams.has('updateId')) {
                this.updateGUID = urlParams.get('updateId');
            }

            if (urlParams.has('an')) {
                this.agentId = urlParams.get('an');
            }

            return Promise.resolve();
        },
        closeModal(target) {
            // $(target).foundation('close');
            this.lmRejectError = false;
            $(target).hide();
            $('.reveal-overlay').hide();
        }
    }
}
</script>
