import Vue from "vue";

import ApplicationGrid from "./application-grid.vue";

export function load() {
    const selector = "application-grid";

    // Is the custom Vue root element in the DOM?
    if (!document.querySelector(selector)) {
        return; // go no further
    }

    // Create a new Vue app with the imported component
    new Vue({
        render: createElement =>
            createElement(ApplicationGrid, {
                props: {
                    ...window[selector]
                }
            }),
    }).$mount(selector);
}
