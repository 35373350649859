import { render, staticRenderFns } from "./review-staff-final.vue?vue&type=template&id=637ae6bd&scoped=true"
import script from "./review-staff-final.vue?vue&type=script&lang=js"
export * from "./review-staff-final.vue?vue&type=script&lang=js"
import style0 from "./review-staff-final.vue?vue&type=style&index=0&id=637ae6bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637ae6bd",
  null
  
)

export default component.exports