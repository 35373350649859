<template>
    <div class="" :class="widgetCssClass, cssClass, customClass">
        <div class="custom-content-block" v-if="widgetCustomContent">
                <span v-html="widgetCustomContent"></span>
        </div>
        
        <div class="ticket-entry-form">
            <h4 id="ticket-entry-header">{{ heading }}</h4>
            <span v-html="content" class="ticket-entry-content"></span>
            <div class="input-group">
                <span id="spanImage" class="ticketImg" v-if="logo"><img :src="gameLogo" width="50"></span>
                <span id="spanImage" class="ticketImg lotteryLogo" v-if="!logo && search"></span>
                <input class="input-group-field" type="text" :placeholder="placeholder" v-model="inputValue" v-on:input="onInput" :disabled="disabled">
                <div class="input-group-button">
                    <input class="button" type="submit" :value="submit"  @click="go" :disabled="!validInput">
                </div>
            </div>
            <div class="error-ticket text-center" v-if="showErrMsg">
                <p v-if="errorMessage">{{ errorMessage }}</p>
                <p v-if="gameCodeError && validGame">{{ gameCodeError }}</p>
                <button class="button secondary" value="Clear Fields" @click="clearInput">Clear Field</button>
            </div>
            <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="15"
                :dots-num="3"
                color="#0FD8C9"
                v-if="isLoading"
            />

            <div class="ticket-entry-gamecode-results" v-if="showGameCodeResults">
                <div class="ticket-entered grid-x grid-margin-x">   
                    <div class="cell small-4" v-if="gameLogo">
                        <img :src="`/getattachment/${gameLogo}/attachment.aspx`"  />
                    </div>
                    <div class="cell small-4" v-if="!gameLogo">
                        <span class="lotteryLogo"></span>
                    </div>
                    <div class="cell small-6">
                        <p v-if="gameNumber"> {{ gameNumber }} </p>
                        

                    </div>
                    <div class="cell small-2">
                        <p>{{ gamePrice }} points</p>
                    </div> 
                </div>        
            </div>

            <div class="ticket-entry-results" v-if="showResults">
                <h5 class="text-center">{{entered }}</h5>

                <div class="ticket-entered grid-x grid-margin-x"  v-for="result in resultsArray.slice().reverse()" :key="result.id" >   
                    <div class="cell small-1" v-if="result.icon.length > 0">
                        <img :src="result.icon" />
                    </div>
                    <div class="cell small-1" v-if="result.icon === ''">
                        <span class="lotteryLogo"></span>
                    </div>
                    <div class="cell small-9 result">
                        <p v-if="result.serial">{{ result.serial }}</p>
                        <p v-if="result.message">{{ result.message }}</p>
                    </div>
                    <div class="cell small-2 points">
                        <p>{{ result.points }} points</p>
                    </div> 
                </div>
            </div>            
        </div>

    </div>
</template>

<script>

    import { HollowDotsSpinner } from 'epic-spinners'

    export default {
        props: ['contentKey', 'requireDigits', 'placeholderProp', 'headingProp', 'submitTextProp', 'customClass'],
        components: {
            HollowDotsSpinner
        },
        data: function () {
            return {
                heading: 'Enter your non-winning ticket numbers or promotion codes below.',
                placeholder: 'Enter ticket number or promotion code',
                submit: 'GO',
                entered: 'Entered Tickets',
                search: false,
                content: '',
                cssClass: '',
                inputValue: '',
                widgetContentKey: '',
                widgetCssClass: '',
                widgetCustomContent: '',
                gameLogo: '',
                logo: false,
                gameNumber: '',
                gamePrice: '',
                gameCodeError: '',
                isLoading: false,
                showResults: false,
                showGameCodeResults: false,
                showErrMsg: false,
                isLogoExist: false,
                disabled: false,
                validInput: false,
                validGame: false,
                resultsArray: [],
                wager: 0,
                gameId: 0,
                isWinningTicket: 0,
                submittable: false,
                errorMessage: ''
            }
        },
        mounted: function () {

            let widgetContentKey = document.getElementById('content-key'),
                widgetCssClass = document.getElementById('custom-css'),
                widgetCustomContent = document.getElementById('custom-content');
            

            if(widgetContentKey !== null){ 
                this.contentKey = widgetContentKey.value;
                this.widgetCssClass = widgetCssClass.value;
                this.widgetCustomContent = widgetCustomContent.value;
                 
            }
            
            this.fetchContentByKey();

        },
        methods: {
            go(){
                this.isLoading = true;
                this.showGameCodeResults = false;
                this.getToken();
                // Add ability to launch modal from here for special promos based on a true/false prop passed from parent page.
            },
            clearInput(){
                this.inputValue = ""
                this.showErrMsg = false;
                this.showGameCodeResults = true;
                this.showGameCodeResults = false;
                this.logo = false;
                this.search = false;
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.token = token
                    this.fetchData();
                });
            },
            fetchData() {
                const apiToken = this.token;
                const userId = this.$cookies.get('TokenInfo').User.UserId; 
                const urlProtocol = this.getProtocol();
                const serialNumber = this.inputValue;

                console.log(userId)

                 if (this.validInput) {
                    let requestOptions = {
                        method: 'GET',
                        headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+ apiToken
                        }
                    };

                    return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/TicketChecker/CheckTicket/" + serialNumber, requestOptions)
                    .then(response => response.json())
                    .then(json => {
                        console.log(json);
                        let data = json.data;

                        if (data.isValid === true) {
                            this.isLoading = false;
                            this.wager = data.ticketCost;
                            this.gameId = data.gameID;
                            this.isWinningTicket = data.isWinningTicket;

                            let forSubmission = {
                                "serialNumber": serialNumber,
                                "userId": userId,
                                "wager": this.wager,
                                "gameNumericIdentifier": this.gameId,
                                "isWinningTicket": this.isWinningTicket
                            }

                            this.submitTicket(forSubmission);
                        } else {
                            this.isLoading = false;
                            this.showErrMsg = true;
                            this.errorMessage = data.scanResultMessage;
                        }
                        
                        // this.gameLogo = '';
                        // this.showResults = true;
                        // var results = json.data;
                        // this.isLoading = false;
                        // this.showErrMsg = false;
                        // console.log(json)
                        // if(json.statusCode === 400 || json.statusCode === 500 || json.error || results.scanResultMessage === "This promotion code does not exist or is already expired." || results.scanResultMessage === "The ticket is not valid.") {
                        //     // this.showResults = false;
                        //     this.showErrMsg = true;
                        //     if (results.scanResultMessage.length > 0) {
                        //         this.errorMessage = results.scanResultMessage;
                        //     } else {
                        //         this.errorMessage = "Invalid Ticket";
                        //     }
                        // } else if (results) {
                        //     this.resultsArray.push(results);
                        //     this.showResults = true;
                        //     console.log(this.resultsArray);
                        // }
                        
                        // this.inputValue = "";
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.showErrMsg = true;
                        this.errorMessage = "Invalid Ticket";
                        console.log(error)
                    });
                }
            },
            submitTicket(data) {
                const urlProtocol = this.getProtocol();

                let requestOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.token
                    },
                    body: JSON.stringify(data)
                };

                return fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/TicketChecker/SubmitTicket", requestOptions)
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    let data = json.data;

                    if (json.error === null) {
                        let result = {
                            icon: this.gameLogo,
                            serial: data.serialNumber,
                            message: data.submitMessage,
                            points: data.rewardsPointsValue
                        }

                        this.resultsArray.push(result);

                        this.showResults = true;
                    } else {
                        this.isLoading = false;
                        this.showErrMsg = true;
                        this.errorMessage = json.error.message;
                    }
                    
                })
                .catch(error => {
                    this.isLoading = false;
                    this.showErrMsg = true;
                    this.errorMessage = error;
                    console.log(error)
                });
            },
            fetchContentByKey(){
                this.getAPItoken()
                .then((token) => {
                    let apiToken = token;
                    let ContentKey = this.contentKey;

                    return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey="+ContentKey, { 
                        headers: {
                            'Authorization': 'Bearer '+apiToken,
                        }
                    })
                    .then(response => response.json())
                    .then(json => {
                        
                        

                        console.log('placeholder '+ this.placeholderProp, 'heading '+ this.headingProp, 'submit '+ this.submitTextProp)

                       console.log(this.headingProp);

                        if (json.data.length > 0) {
                            let content = json.data[0].centralContent;
                            let parseContent = JSON.parse(content)

                            this.headingProp.length > 1 ? this.heading = this.headingProp : this.heading = parseContent.heading;
                            this.placeholder = parseContent.placeholder;
                            this.submit = parseContent.submit;
                            this.entered = parseContent.entered;
                            this.content = parseContent.content;
                            this.cssClass = parseContent.cssclass;
                        }
                        

                    })
                    .catch(error => {
                        console.log(error)

                    });
                });
            },
            fetchIcon(inputValue){
                this.getAPItoken()
                .then((token) => {
                    let apiToken = token;

                    return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/ScratchOffs/ScratchOffGame/GetGameInformation?gameCode="+inputValue+"&getAllGameInfo=false", { 
                        headers: {
                            'Authorization': 'Bearer '+apiToken,
                        }
                    })
                    .then(response => response.json())
                    .then(json => {
                        console.log(json)
                        let result = json.data;
                        // this.disabled = false;
                        // this.showGameCodeResults = true;

                        if(result !== null){
                            this.gameLogo = result.gameLogoURL;
                            this.logo = true;
                            this.gameNumber = result.gameNumber;
                            this.gamePrice = result.gamePrice;
                            this.gameCodeError = "";
                            // this.showErrMsg = false;
                            // this.showGameCodeResults = true;
                        }else{
                            // this.showErrMsg = true;
                            // this.showGameCodeResults = false;
                            this.gameLogo = " ";
                            this.gameNumber = " ";
                            this.gamePrice = " ";
                            this.gameCodeError = json.error.message;
                        }
                        
                        

                    })
                    .catch(error => {
                        console.log(error)

                    });
                });
            },
            validate() {
                // Numbers only
                // 23 || 35 characters long
                let isnum = /^\d+$/.test(this.inputValue);

                if (isnum) {
                    this.showErrMsg = false;
                    this.inputValue.length === 23 || this.inputValue.length === 35 ? this.validInput = true : this.validInput = false;
                } else {
                    this.validInput = false;
                    this.showErrMsg = true;
                    this.errorMessage = "Please enter numbers only.";
                }
            },
            onInput() {
                this.showErrMsg = true;
                this.validate();
                let requireDigits = parseInt(this.requireDigits);
                if (this.inputValue.length === requireDigits || this.inputValue.length === 23 || this.inputValue.length === 35) {
                    this.search = true;
                    // this.disabled = true;
                    console.log('fecth')
                    let fourInputValue = this.inputValue;
                    this.fetchIcon(fourInputValue)
                }
                if (this.inputValue.length === 0) {
                    this.search = false;
                }
            }
        },
        computed: {
           
        },
    };
</script>

<style lang="scss" scoped>
    .ticket-entered{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border-top: 1px solid #d3d3d3;
        padding-top: 20px;

        div.cell.small-1 {
            img {
                width: 36px;
            }
        }

        .points {
            width: 100%;

            @include breakpoint(medium) {
                width: inherit;
            }
        }

        .result {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            @include breakpoint(medium) {
                flex-direction: row;
            }
        }

        @include breakpoint(medium) {
            flex-direction: row;
        }
    }
    .button.secondary {
        text-transform: none;
        letter-spacing: unset;
        display: inline-block;
        margin-left: 20px;
    }
    .error-ticket {
        p {
            color: #fc551e;
            display: inline-block;
            vertical-align: middle;
        }
    }
</style>