<template>
    <section class="autologout-wrapper">
        <div class="modal-container"  v-if="warningZone">
            <div class="modalAutoLogout" id="modalAutoLogout">
                <div class="grid-x">
                    <div class="cell small-12">
                        <div class="text-center">
                            <h2>Are you still there?</h2>
                            <a @click="getRefreshToken()" class="continue-link">Click to continue</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from 'jquery';

import moment from 'moment';

export default {
    data: function () {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],

            firstLoad: true,
            token: '',
            apiRefreshToken: '',
            username: '',
            timeRemaining: '',
            refreshTimeRemaining: '',

            warningTimer: null,
            logoutTimer: null,
            warningZone: false,
            updateRefreshToken: false,
        }
    },
    mounted: function () {
        this.trackEvents()
        //this.getRefreshToken();

        $(".autologout-wrapper").appendTo("body");

        console.log('AutoLogout: Active')

        let tokenInfo = this.$cookies.get('apiToken');

        this.$cookies.set('tokenExpires', tokenInfo.expires)


        this.apiRefreshToken = tokenInfo.refreshToken.token
        this.username = tokenInfo.user.username
    },

    destroyed: function(){
        this.events.forEach( function (event){
            window.removeEventListener(event, this.resetTimer)
        }, this)

        this.resetTimer();

    },

    methods: {
        trackEvents(){
            this.events.forEach( function (event){
                window.addEventListener(event, this.resetTimer)
            }, this)

            this.setTimers();
        },

        getRefreshToken() {
            this.warningZone = false
            document.getElementsByClassName('continue-link')[0].classList.add("disabled-link");
      
            this.refreshToken();
        },
        calculateTimeRemaining () {           
            let formatExpire = new Date(this.$cookies.get('tokenExpires'));
            let formatCurrentDate;
            formatCurrentDate = moment(  new Date() )
    
            let token = this.$cookies.get('TokenInfo'),
                expire = moment(  formatExpire  ),
                refreshTime = moment(token.RefreshToken.Expires),
                currentTime =  formatCurrentDate;

            

            let timeLeft = expire.diff(currentTime, 'minutes');

            console.log(timeLeft)
            // console.log(token)

            this.timeRemaining = timeLeft;
            this.refreshTimeRemaining = refreshTime.diff(currentTime, 'minutes');

            return timeLeft;
        },
        refreshToken() {

            let username = this.username,
                refreshToken = this.apiRefreshToken,
                protocol = this.getProtocol();


             let loginCreds = {
                "userName": username,
                "refreshToken": refreshToken
            }


            return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/RefreshToken', {
                method: 'POST',
                body: JSON.stringify(loginCreds),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => response.json())
            .then((response) => {
                let res = response;
                this.setToken(res);
                this.updateRefreshToken = false;
            })
        },
        submitRefresh(response) {
            let tokenObj = {};
            tokenObj = response.data,
            tokenInfo = this.$cookies.get('TokenInfo');
  

            if (tokenObj.token === null || tokenObj.token === undefined || tokenObj.token === "") {
                if (tokenObj.refreshToken.isExpired === true) {
                    // console.log('Logout process..')
                    document.querySelector('.sign-out-button').click();
                } else {
                    // console.log('Refresh token process...')
                    this.getRefreshToken();
                }
            } else {
                var tokenExpires = new Date(tokenObj.expires);
                tokenExpires = tokenExpires.toUTCString();

                this.setToken(tokenObj);
            }
        },
        setToken(data) {
            let tokenObj = data.data,
                tokenAPI = data.data,
                tokenInfo = this.$cookies.get('TokenInfo'),
                apiToken = this.$cookies.get('apiToken');

                console.log(tokenObj)

            if (tokenInfo) {
                     tokenInfo.Token = tokenObj.token;
                     tokenInfo.Message = tokenObj.message;
                     tokenInfo.Expires = tokenObj.expires;
                     tokenInfo.IsExpired = tokenObj.isExpired;
                     tokenInfo.MinutesToExpiration = tokenObj.minutesToExpiration;
                     tokenInfo.IsAuthenticated = tokenObj.isAuthenticated;
                     tokenInfo.RefreshToken.Token = tokenObj.refreshToken.token;
                     tokenInfo.RefreshToken.CurrentDatetime = tokenObj.refreshToken.currentDateTime;
                     tokenInfo.RefreshToken.Expires = tokenObj.refreshToken.expires;
                     tokenInfo.RefreshToken.IsExpired = tokenObj.refreshToken.isExpired;
                     tokenInfo.RefreshToken.MinutesToExpiration = tokenObj.refreshToken.minutesToExpiration;
                     tokenInfo.RefreshToken.Created = tokenObj.refreshToken.created;
                     tokenInfo.RefreshToken.Revoked = tokenObj.refreshToken.revoked;
                     tokenInfo.RefreshToken.IsActive = tokenObj.refreshToken.isActive;
                     tokenInfo.RefreshToken.LastUpdated = tokenObj.refreshToken.lastUpdated;

                     tokenAPI.token = tokenObj.token;
                     tokenAPI.message = tokenObj.message;
                     tokenAPI.expires = tokenObj.expires;
                     tokenAPI.isExpired = tokenObj.isExpired;
                     tokenAPI.minutesToExpiration = tokenObj.minutesToExpiration;
                     tokenAPI.isAuthenticated = tokenObj.isAuthenticated;
                     tokenAPI.refreshToken.token = tokenObj.refreshToken.token;
                     tokenAPI.refreshToken.currentDatetime = tokenObj.refreshToken.currentDateTime;
                     tokenAPI.refreshToken.expires = tokenObj.refreshToken.expires;
                     tokenAPI.refreshToken.isExpired = tokenObj.refreshToken.isExpired;
                     tokenAPI.refreshToken.minutesToExpiration = tokenObj.refreshToken.minutesToExpiration;
                     tokenAPI.refreshToken.created = tokenObj.refreshToken.created;
                     tokenAPI.refreshToken.revoked = tokenObj.refreshToken.revoked;
                     tokenAPI.refreshToken.isActive = tokenObj.refreshToken.isActive;
                     tokenAPI.refreshToken.lastUpdated = tokenObj.refreshToken.lastupdated;

                    this.$cookies.set('TokenInfo', JSON.stringify(tokenInfo));
                    this.$cookies.set("apiToken" , JSON.stringify(tokenAPI) )
                    this.$cookies.set("tokenExpires", JSON.stringify(tokenObj.Expires) )
                
                    let tokenInfoSetted = this.$cookies.get('apiToken');
                    this.apiRefreshToken = tokenInfoSetted.refreshToken.token

            //     console.log("= TokenInfo =")
                
            }  


            

            this.token = tokenObj;
            
            //this.getTokenTime();
        },

        setTimers() {
            this.warningTimer = setInterval(this.warningMessage, 45 * 1000)
            this.logoutTimer = setTimeout(this.logoutUser, 900 * 1000)           
        }, 

        warningMessage() {
            //this.warningZone = true
            //let refreshTimeRemaining = this.calculateTimeRemaining();
     

            let formatExpire = this.$cookies.get('TokenInfo');
            let formatCurrentDate;
            formatCurrentDate = moment(  new Date() )

            let token = this.$cookies.get('apiToken'),
                expire = moment( formatExpire.Expires ),
                currentTime = formatCurrentDate,
                timeLeft = expire.diff(currentTime, 'minutes');            
            
            

            // Check refreshToken time remaining
            if (timeLeft < 5) {
                this.updateRefreshToken = true;
            } 
            
            if (timeLeft < 1){
                // console.log('Logout process..')
                document.querySelector('.sign-out-button').click();
            }
            
        },

        logoutUser() {
            // console.log('Logout process..')
            document.querySelector('.sign-out-button').click();
        },

        resetTimer() {
            clearInterval(this.warningTimer)
            clearTimeout(this.logoutTimer)
            this.setTimers();
            
            let refreshTimeRemaining = this.calculateTimeRemaining()
            // Check refreshToken time remaining
            if (refreshTimeRemaining < 5) {
                this.updateRefreshToken = true;
                
            }
        },
        
    },
    watch: {
        updateRefreshToken: function (val) {
            if(val === true){
                this.warningZone = true
            }
        }
    }
};
</script>

<style scoped lang="scss">
.modal-container {
    position:fixed;
    top:50%;
    left:50%;
    width: 300px;
   height: auto;
   background-color: white;
   padding: 20px;
   box-shadow:0px 0px 20px #000;;
   border-radius: 10px;
    z-index:1;
}

.modalAutoLogout{
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
</style>