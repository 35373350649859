<template>
    <div class="winning-numbers-wrapper">
        <div class="randomizer-wrap">
            <h2 class="visuallyhidden">The OH! Lottery Randomizer</h2>
            <div class="randomizer-content">
                <p>{{ outputText }}</p>

                <div class="randomizer_output">
                    <div v-if="arrNumbers.length" :class="gameClassName">
                        <span></span>
                        <ul class="winningNumbersList">
                            <li v-for="number in arrNumbers" v-bind:key="number.id" class="winNumBall">
                                {{ number }}
                            </li>
                        </ul>
                    </div>
                </div>
                
                
                <select class="randomizer_select" v-model="gameData.selectedName" @change="onChange($event)">
                    <option v-for="(option, index) in gameData.names" :key="index" :value="option.value">{{ option.name }}</option>
                </select>

                <button class="btn_randomizer" v-on:click="getNumbers()">{{ btnText }}</button>

             
            </div>
        </div>   
    </div>
</template>


<script>
    export default {
        components: {
        },
        data: function () {
            return {
                arrNumbers: [],
                gameData: {
                    names: [],
                    selectedName: ''
                },
                gameName: '',
                gameClassName: '',
                outputText: "Looking for some lucky numbers? Pick your game and we'll offer a few random digits. If you like what you see, give them a play!",
                btnText: 'Get Numbers'
            }
        },
        mounted: function () {
            let names = [
                {
                    name: 'Classic Lotto',
                    value: 'classic'
                },
                {
                    name: 'Mega Millions',
                    value: 'mega'
                },
                {
                    name: 'Powerball',
                    value: 'power'
                },
                {
                    name: 'Pick 3',
                    value: 'pick3'
                },
                {
                    name: 'Pick 4',
                    value: 'pick4'
                },
                {
                    name: 'Pick 5',
                    value: 'pick5'
                },
                {
                    name: 'Rolling Cash 5',
                    value: 'rolling'
                },
                {
                    name: 'Lucky for Life',
                    value: 'lucky'
                }
            ]

            this.gameData.names = Object.assign({}, this.gameData.names, names);
            this.gameData.selectedName = this.gameData.names[0].value;
            this.gameName = 'classic';
            this.gameClassName = 'classic';
        },
        methods: {
            generateRandomNumber(rangeMin, rangeMax, numberOfBalls, specialBall, rangeMinSB, rangeMaxSB, unique){
                this.arrNumbers = [];

                function randomNumber(min, max) {
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                }
                function testNumber(num, arr) {
                    let isUnique = arr.includes(num);
                    if (isUnique) {
                        newNumber(arr);
                    } else {
                        arr.push(num) ;
                    }
                }
                function newNumber(arr) {
                    var num = randomNumber(rangeMin, rangeMax);
                    testNumber(num, arr);
                }

                for (var i = 0; i < numberOfBalls; i++) { 
                    var num = randomNumber(rangeMin, rangeMax);
                    // test for unique sequences
                    if (unique) {
                        testNumber(num, this.arrNumbers);
                    } else {
                        this.arrNumbers.push(num);
                    }
                }
                // sort the unique number sets
                if (unique) {
                    this.arrNumbers.sort(function(a, b){return a-b});
                }

                // special ball
                for (var s = 0; s < specialBall; s++) { 
                    var specialNum = randomNumber(rangeMinSB, rangeMaxSB);
                    this.arrNumbers.push(specialNum) 
                }
            },
            getNumbers(){
                // console.log( this.gameName )

                this.outputText = "These Could Be Your Lucky Numbers!"
                this.btnText = "Generate New Numbers"

                this.gameClassName = this.gameName;
                let rangeMin, rangeMax, numberOfBalls, specialBall, rangeMinSB, rangeMaxSB, unique;

                switch (this.gameName) {
                    case 'classic': 
                    rangeMin=1,rangeMax=49,numberOfBalls=6,unique=true
                    break;
                    case 'mega': 
                    rangeMin=1,rangeMax=70,numberOfBalls=5,specialBall=1,rangeMinSB=1,rangeMaxSB=25,unique=true
                    break;
                    case 'power': 
                    rangeMin=1,rangeMax=69,numberOfBalls=5,specialBall=1,rangeMinSB=1,rangeMaxSB=26,unique=true
                    break;
                    case 'pick3': 
                    rangeMin=0,rangeMax=9,numberOfBalls=3,unique=false
                    break;
                    case 'pick4': 
                    rangeMin=0,rangeMax=9,numberOfBalls=4,unique=false
                    break;
                    case 'pick5': 
                    rangeMin=0,rangeMax=9,numberOfBalls=5,unique=false
                    break;
                    case 'rolling': 
                    rangeMin=1,rangeMax=39,numberOfBalls=5,unique=true
                    break;
                    case 'lucky': 
                    rangeMin=1,rangeMax=48,numberOfBalls=5,specialBall=1,rangeMinSB=1,rangeMaxSB=18,unique=true
                    break;
                    default: alert ("Select game in dropdown"); break;
                }

                this.generateRandomNumber(rangeMin, rangeMax, numberOfBalls, specialBall, rangeMinSB, rangeMaxSB, unique);
            },
            onChange(e) {
                this.gameName = e.target.value
            }
        }
    };
</script>

<style lang="scss" scoped>
.mega ul li:last-child,
.lucky ul li:last-child {
    background-color: #FFDE1B;
    color: #20262B;
}

.power ul li:last-child{
    background-color: #be0f34;
    color: #fff;
}


</style>