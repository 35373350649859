<template>
    <div class="myApplicationsWrap module" v-if="isUserRetailer">
        <h5>Your Submitted Applications</h5>
        <hollow-dots-spinner :animation-duration="1000"
                             :dot-size="15"
                             :dots-num="3"
                             color="#0FD8C9"
                             v-if="isLoading" />
                             
        <div class="appGridWrapper" v-if="!isLoading">
            <ApplicationGridStatus :gridData="gridData"
                                   :headers="gridHeaders">
            </ApplicationGridStatus>
        </div>
    </div>
</template>


<script>
    import ApplicationGridStatus from '../../../Views/Eapplication/dashboard/application-grid-status.vue';
    import { HollowDotsSpinner } from 'epic-spinners';
    
    export default {
        components: {
            HollowDotsSpinner,
            ApplicationGridStatus
        },
        data() {
            return {
                isUserRetailer: false,
                showClearSearch: false,
                gridHeaders: ['Business Name', 'Status'],
                gridData: [],
                isLoading: false,
                userId: 0,
                token: ''
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init(){
                this.isUserRetailer = this.isUserInRole('eApp-Retailer');
                if(this.isUserRetailer){
                    this.getToken();
                }
            },
            getToken() {
                
                let uId = this.getLoggedInUserId();
                if(uId){
                    this.userId = uId;
                    this.getAPItoken()
                        .then((token) => {
                            this.token = token;
                            this.fetchData(token, 1, 100, '', "BusinessName", "asc");
                        });
                }
            },
            async fetchData(token, _pageNumber, _pageSize, _businessName, _sortBy, _sortOrder) {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        pageNumber: _pageNumber,
                        pageSize: _pageSize,
                        businessName: _businessName,
                        sortBy: _sortBy,
                        sortOrder: _sortOrder
                    })
                };
                let applicationsRequest = await (await fetch(this.GET_APPS_API_URL, appsRequestOptions)).json();
                let appArray = applicationsRequest.results;
                appArray.forEach(element => {
                    let isSubmitted = element.applicationStatus;
                    if (isSubmitted > 1) {
                        this.gridData.push(element);        
                    }
                });
                this.isLoading = false;
            }
        },
        computed: {
            GET_APPS_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetApplicationListForRetailer?userId=` + this.userId;
            }
        }
    }
</script>