<template>
    <div>
        <div>
            <div v-if="menu != ''" v-html="menu"></div>
        </div>
        
    </div>
</template>
<script>


export default {
    data: function () {
        return {
            currentTime: new Date().getFullYear(),
            menu: '',
            cookieAllow: false
        }
    },
    mounted: function () {
        this.getMenu();
        //this.checkAndCreateCookie();
    },
    methods: {
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        getMenu() {
            let menuData = JSON.parse(localStorage.getItem('mt_footerMenu'));

            if (menuData !== undefined && menuData !== "" && menuData !== null) {
                this.showMenu(menuData);

                setTimeout(() => {
                    let selector = document.querySelector(".currentTime");
                    let printYear = document.createTextNode(this.currentTime);
                    if (printYear !== null && selector !== null) {
                        selector.appendChild(printYear);
                    }
                }, 500);

            } else {
                this.getToken();
            }
        },
        showMenu(data) {
            let parser = new DOMParser();
            let htmlDoc = parser.parseFromString(data, 'text/html');

            this.menu = htmlDoc.body.innerHTML;
        },
        fetchData(apiToken) {
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=footercontent', {
                headers: {
                    'Authorization': 'Bearer ' + apiToken
                }
            })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent;

                    localStorage.setItem('mt_footerMenu', JSON.stringify(element));
                    this.showMenu(element)
                })
        },
        checkAndCreateCookie() {
            // Check if Exist
            const cookieValue = this.getCookie('CMSCookieLevel');
            if (cookieValue === null) {
                this.setCookie('CMSCookieLevel', '-100');
            }
            if (cookieValue === '1000') {
                this.cookieAllow = true;
            }
            // const updatedCookieValue = this.getCookie('CMSCookieLevel');
            // console.log('Valor actual de CMSCookieLevel:', updatedCookieValue);
        },

        getCookie(name) {
            const cookies = document.cookie.split(';');
            for (const cookie of cookies) {
                const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim());
                if (cookieName === name) {
                    return decodeURIComponent(cookieValue);
                }
            }
            return null;
        },
        setCookie(name, value) {
            const expirationDate = new Date();
            expirationDate.setFullYear(expirationDate.getFullYear() + 1); // Caducidad en 1 año

            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expirationDate}; path=/`;
        },
        handleAllowCookie() {
            this.setCookie('CMSCookieLevel', '1000');
            this.cookieAllow = true;
        }
    }
}
</script>
<style lang="scss" scoped>
.CookieConsent {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 3em 2em;
    color: #fff;
    background-color: #5161ac;
    z-index: 99;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    z-index: 999999;
}

.CookieConsent a {
    color: white;
}

.CookieConsent .ConsentButtons {
    margin: 3rem auto 0;
}
</style>