<template>
    <div>
        <div class="highPayoutHighAlert" v-if="showHighPayout">
            <div id="highPayoutHighAlert-logo">
                <span :class="`${highAlertGameName}-logo`"></span>
            </div>

            <div id="highPayoutHighAlert-alert">
                <span class="highpayoutalert-alertTag"></span>
                <div id="highPayoutHighAlert-amount">
                    <p>$<span id="payoutAlertAmount">{{numberFormatter(highAlert.payout)}}</span>*</p>
                </div>

                <div id="highPayoutHighAlert-dates-numbers">
                    <p id="highPayoutHighAlert-date">*PAYOUT ON <span id="payoutAlertDate">{{customFormatter( highAlert.date )}} </span></p>

                    <div id="highPayoutHighAlert-numbers">
                        <div class="highPayoutHighAlert-ball" :id="number.id"  v-for="number in highAlert.numbers">
                            <span class="alert-number">{{number}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>


<script>
    import moment from 'moment';

    export default {
        components: {
        },
        data: function () {
            return {
                highAlert: [],
                highAlertGameName: '',
                showHighPayout: false
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');
            },
            numberFormatter(number){
                return new Intl.NumberFormat().format(number)
            },
            getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(apiToken) {
                return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/DrawGame/HighPayout', {
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    const results = json.data;

                    if(results.length === 0){
                        this.showHighPayout = false;
                        return;
                    }
                    
                    this.showHighPayout = true;
                    
                    this.highAlert = results[0];
                    this.highAlertGameName = results[0].gameName.toLowerCase().split(' ').join('')

                })
            }
        },
        filters:{
        }
    };
</script>

<style lang="scss">
.highPayoutHighAlert{
    margin-top: 20px;
    color: #ed2726;
    text-align: center;
    //background: url("/ohiolottery/media/site-design/winning%20numbers/HighPayout/highPayoutAlert_bkgnd.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f68d1e;
    padding: 2rem;
}

</style>