<template>
    <div class="for_retailers">
          
            <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="15"
                :dots-num="3"
                color="#0FD8C9"
                v-if="isLoading"
            />

            <div id="retailer_of_the_week" v-if="showContent">
                
                <div v-if="showErrorMsg">{{ errorMsg }}</div>

                <div class="retailer_photo_container" v-if="!!image1">
                    <div class="retailer_photo" v-if="image1 != '00000000-0000-0000-0000-000000000000'">
                        <img id="" :src="`/getattachment/${image1}/attachment.aspx`" alt="Retailer Photo">
                    </div>
        
                    <div class="retailer_photo_caption">{{image1cap}}</div>

                    <div class="retailer_photo2" v-if="image2 != '00000000-0000-0000-0000-000000000000'">
                        <img id="" :src="`/getattachment/${image2}/attachment.aspx`" alt="Retailer Photo" >
                    </div>
        
                    <div class="retailer_photo_caption">{{image2cap}}</div>
                </div>

                <div class="retailer_week" v-if="date">Week of {{ customFormatter(date) }} </div>

                <div class="retailer_title">
                    {{title}}
                </div>

                <div class="retailer_location">
                    <div class="retailer_address">{{ streetAddress }}</div>
                    <div class="retailer_county">{{ city }}{{  state }}</div>
                </div>

                <div class="retailer_body">
                    <span v-html="content"></span>
                </div>
            </div>          
    </div>    
</template>


<script>

    import moment from 'moment';
    import { HollowDotsSpinner } from 'epic-spinners'

    export default {
        props: ['subtractDays'],
        components: {
            HollowDotsSpinner
        },
        data: function () {
            return {
                showContent: false,
                isLoading: true,
                image1: '',
                image1cap: '',
                image2: '',
                image2cap: '',
                content: '',
                name: '',
                streetAddress: '',
                city: '',
                state: '',
                title: '',
                date: '',
                dateFrom: moment().subtract(this.subtractDays, "days").format('YYYY-MM-DD'),
                dateTo: moment(new Date()).format('YYYY-MM-DD'),
                showErrorMsg: false,
                errorMsg: ''
            }
        },
        computed: {
        },
        mounted: function () {
            let url = window.location.href;

            if(url.includes('?articleDateFrom')){
                const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            
            // console.log( params.articleDateFrom, params.articleDateTo )

            this.dateFrom = params.articleDateFrom;
            this.dateTo = params.articleDateTo;

            this.getToken();
                
            }else{
                this.getToken()
            }
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('LL');
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                let apiToken = token,
                    urlProtocol = this.getProtocol(),
                    dateFrom = this.dateFrom,
                    dateTo = this.dateTo;


                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetByDateRangeAndCategory?dateFrom="+ dateFrom +"&dateTo="+ dateTo +"&category=FeaturedRetailer", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var res = json.data;

                    console.log(json)

                    if(json.error !== null){
                        
                            this.isLoading = false;
                            this.showContent = true;
                            this.showErrorMsg = true;
                            this.errorMsg = json.error.message;

                            return
                    }
                    res.sort(function(a, b) {
                        return b.date.localeCompare(a.date);
                    })

                    let articleId = res[0].articleID;

                    return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetById/"+articleId, {
                        headers: {
                            'Authorization': 'Bearer '+apiToken,
                        },
                    })
                    .then(response => response.json())
                    .then(json => {
                        console.log(json)
                        let articleRes = json.data;

                        this.isLoading = false;
                        this.showContent = true;

                        this.image1 = articleRes.image1;
                        this.image1cap = articleRes.image1Caption;
                        this.image2 = articleRes.image2;
                        this.image2cap = articleRes.image2Caption;
                        this.city = articleRes.city;
                        this.state = ', '+articleRes.state;
                        this.content = articleRes.content;
                        this.streetAddress = articleRes.streetAddress;
                        this.title = articleRes.title;
                        this.name = articleRes.name;
                        this.date = articleRes.date;
                        

                        



                    })
                    .catch(error => {
                        console.error("There was an error!", error);
                        //return this.errorMessage = error;
                    });


                    

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>