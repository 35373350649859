<template>
    <div data-abide novalidate data-validate-on-blur="true" id="updateEftForm" class="grid-x grid-margin-x grid-margin-y">

        <p v-if="pageContent" v-html="pageContent.updateEFTintro" class="cell"></p>

        <div class="formRow cell large-6">
            <span class="req">*</span>
            <label title="This is the name of your financial institution" class="helpTxt" for="dfiName">Financial
                Institution Name:</label>
            <input v-model="eftUpdate.dfiName" type="text" id="dfiName" maxlength="200" required :disabled="readOnly || editing === true" />
            <div class="error" id="bankNameError" style="display:none;">Please provide the financial institution name.</div>
        </div>
        <div class="formRow cell large-6">
            <span class="req">*</span>
            <label title="This is the name that appears in the left hand corner of your check" class="helpTxt"
                for="eftAccountName">EFT Account Name:</label>
            <input v-model="eftUpdate.eftAccountName" type="text" id="eftAccountName" maxlength="200" required
                :disabled="readOnly || editing === true" />
            <div class="error" id="accountNameError" style="display:none;">Please enter an account name.</div>
        </div>
        <div class="formRow cell large-6">
            <span class="req">*</span>
            <label title="This is your checking account number" class="helpTxt" for="checkingAccountNumber">Checking Account
                Number:</label>
            <input v-model="eftUpdate.checkingAccountNumber" type="text" id="checkingAccountNumber" maxlength="17" required
                :disabled="readOnly || editing === true" pattern="[a-zA-Z]?[0-9]{6,}" autocomplete="off" />
            <!-- <div class="form-error error">Please provide a valid account number.</div> -->
            <div class="form-error error" data-error-on="pattern" id="accountNumError" style="display:none;">Please provide a valid account number.</div>
        </div>
        <div v-if="!readOnly" class="formRow cell large-6">
            <span class="req">*</span>
            <label title="Re-enter your checking account number" class="helpTxt" for="checkingAccountNumberVerify">Verify
                Checking Account Number:</label>
            <input v-model="eftUpdate.checkingAccountNumberVerification" type="text" id="checkingAccountNumberVerify"
                maxlength="17" required pattern="[a-zA-Z]?[0-9]" data-equalto="checkingAccountNumber" autocomplete="off" />
            <!-- <div class="form-error error" data-form-error-on="required">Please re-enter account number.</div> -->
            <div class="error" id="accountNumValidationError" style="display:none;">Please re-enter account number.</div>
            <!-- <div class="form-error error" data-form-error-on="equalTo">Please make sure that the checking account numbers match.</div> -->
            <div class="error" id="accountCompareError" style="display:none;">Please make sure that the checking account numbers match.</div>
        </div>
        <div class="formRow cell large-6">
            <span class="req">*</span>
            <label title="This is your checking account routing number" class="helpTxt"
                for="checkingAccountRoutingNumber">Checking Account Routing Number:</label>
            <input v-model="eftUpdate.checkingAccountRoutingNumber" :disabled="readOnly || editing === true" type="text"
                id="checkingAccountRoutingNumber" maxlength="9" required pattern="[0-9]{9}" autocomplete="off"
                @input="blockInvalidChar" />
            <!-- <div class="form-error error" data-form-error-on="required">Please provide your checking account routing number</div> -->
            <div class="error" id="routeNumError" style="display:none;">Please provide your checking account routing number</div>
            <div class="form-error error" data-error-on="pattern">Routing number should have 9 digits</div>
        </div>
        <div v-if="!readOnly" class="formRow cell large-6">
            <span class="req">*</span>
            <label title="Re-enter your checking account routing number." class="helpTxt"
                for="checkingAccountRoutingNumberVerify">Verify Routing Number:</label>
            <input v-model="eftUpdate.checkingAccountRoutingNumberVerification" type="text"
                id="checkingAccountRoutingNumberVerify" maxlength="9" required pattern="[0-9]{9}"
                data-equalto="checkingAccountRoutingNumber" autocomplete="off" @input="blockInvalidChar" />
            <!-- <div class="form-error error" data-form-error-on="required">Please re-enter the checking number</div> -->
            <div class="error" id="routeNumValidationError" style="display:none;">Please re-enter the checking number</div>
            <!-- <div class="form-error error" data-form-error-on="equalTo">Please make sure that the routing numbers match</div> -->
            <div class="error" id="routeCompareError" style="display:none;">Please make sure that the routing numbers match</div>
        </div>

        <!-- SECONDARY INFORMATION -->
        <div class="formRow checkboxRow cell" v-if="showSecondary && !readOnly">
            <input v-model="eftUpdate.secondaryEFTShared" id="chkShareEFT" type="checkbox" name="chkShareEFT"
                @change="toggleSecondary()" :disabled="readOnly || editing === true">
            <label for="chkShareEFT">Same routing number and account number for Secondary EFT</label>
        </div>
        
        <div class="secondaryEFT cell grid-x grid-margin-x grid-margin-y" v-if="showSecondary && !secondarySame"
            id="secondaryBanking">
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label title="This is the name of your financial institution" class="helpTxt" for="dfiName">Secondary Financial
                    Institution Name:</label>
                <input v-model="eftUpdate.secondaryDFIName" type="text" id="secondaryDfiName" maxlength="200" required :disabled="readOnly || editing === true" />
                <div class="error" id="secondaryBankNameError" style="display:none;">Please provide the financial institution name.</div>
            </div>
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label title="This is the name that appears in the left hand corner of your check" class="helpTxt"
                    for="eftAccountName">Secondary EFT Account Name:</label>
                <input v-model="eftUpdate.secondaryEFTAccountName" type="text" id="secondaryEftAccountName" maxlength="200" required
                    :disabled="readOnly || editing === true" />
                <div class="error" id="secondaryAccountNameError" style="display:none;">Please enter an account name.</div>
            </div>
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label title="This is your checking account number" class="helpTxt"
                    for="secondaryCheckingAccountNumber">Secondary Checking Account Number:</label>
                <input v-model="eftUpdate.secondaryCheckingAccountNumber" type="text" id="secondaryCheckingAccountNumber"
                    pattern="[a-zA-Z]?[0-9]{6,}" maxlength="17" required :disabled="secondarySame || readOnly || editing === true" />
                <div class="form-error error" id="secondaryAccountNumberError" data-error-on="pattern">Please provide a valid secondary checking account number.</div>
            </div>
            <div v-if="!readOnly" class="formRow cell large-6">
                <span class="req">*</span>
                <label title="Re-enter your secondary checking account number" class="helpTxt"
                    for="secondaryCheckingAccountNumberVerify">Verify Secondary Checking Account Number:</label>
                <input v-model="eftUpdate.secondaryCheckingAccountNumberVerification" type="text"
                    id="secondaryCheckingAccountNumberVerify" maxlength="17" required pattern="[a-zA-Z]?[0-9]"
                    data-equalto="secondaryCheckingAccountNumber" :disabled="secondarySame || readOnly || editing === true" />
                <div class="form-error error" data-error-on="pattern">Please re-enter secondary account number.</div>
                <div class="form-error error" id="secondaryAccountCompareError" data-error-on="equalto">Please make sure that the secondary checking account numbers match.</div>
            </div>
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label title="This is your secondary checking account routing number" class="helpTxt"
                    for="secondaryCheckingAccountRoutingNumber">Secondary Checking Account Routing Number:</label>
                <input v-model="eftUpdate.secondaryCheckingAccountRoutingNumber" type="text"
                    id="secondaryCheckingAccountRoutingNumber" maxlength="9" required pattern="[0-9]{9}"
                    :disabled="secondarySame || readOnly || editing === true" />
                <div class="form-error error" id="secondaryRouteNumError" data-error-on="required">Please provide your checking account routing number</div>
                <div class="form-error error" data-error-on="pattern">Routing number should have 9 digits</div>
            </div>
            <div v-if="!readOnly" class="formRow cell large-6">
                <span class="req">*</span>
                <label title="Re-enter your secondary checking account routing number." class="helpTxt"
                    for="secondaryCheckingAccountRoutingNumberVerify">Verify Secondary Routing Number:</label>
                <input v-model="eftUpdate.secondaryCheckingAccountRoutingNumberVerification" type="text"
                    id="secondaryCheckingAccountRoutingNumberVerify" maxlength="9" required pattern="[0-9]{9}"
                    data-equalto="secondaryCheckingAccountRoutingNumber" :disabled="secondarySame || readOnly || editing === true" />
                <div class="form-error error" id="secondaryRouteNumValidationError" data-error-on="pattern">Please re-enter the checking number</div>
                <div class="form-error error" id="secondaryRouteCompareError" data-error-on="equalto">Please make sure that the routing numbers match</div>
            </div>
        </div>

        <div v-if="!readOnly" class="formRow  dividerRow txtCenter cell">
            <img src="/getattachment/6ef3edb7-7890-4603-92ba-91cf4942ec1c/Check-Image.jpg"
                class="eftCheckImg img-responsive">
        </div>

        <div v-if="!readOnly" class="formRow checkboxRow  cell">
            <span class="req">*</span>
            <input v-model="eftUpdate.dfIauthorization" type="checkbox" id="checkDFIauth" required :disabled="readOnly || editing === true" />
            <label for="checkDFIauth">I authorize the Ohio Lottery Commission to make Automatic Clearing House (ACH)
                withdrawals or deposits each week into the account identified above. I also authorize the named Depository
                Financial Institution to charge such withdrawals or deposits to my listed account.</label>

            <div class="error" id="dfIautorizationError" style="display: none;">Please authorize OLC to make ACH withdrawals or deposits.</div>
        </div>
        <div v-if="!readOnly" class="formRow checkboxRow  cell">
            <span class="req">*</span>
            <input v-model="eftUpdate.signatureAcknowledgement" type="checkbox" id="checkSignature" required
                :disabled="readOnly || editing === true" />
            <label for="checkSignature">By checking this box I acknowledge that I am authorized by the licensed lottery
                holder to make this change to the bank account and that everything entered is accurate and true.</label>

            <div class="error" id="signatureAcknowledgmentError" style="display:none;">Please acknowledge your digital signature addition.</div>
        </div>

    </div>
</template>

<script>
import $ from 'jquery';
export default {
    props: {
        eftUpdate: Object,
        userId: Number,
        // hasRenewal: Boolean,
        pageContent: Object,
        updateEftForm: false,
        readOnly: Boolean,
        showSecondary: Boolean,
        role: Boolean,
        editing: Boolean
    },
    components: {
    },
    data() {
        return {
            // dfiNameValid: true,
            // accountNameValid: true,
            // checkingAccountValid: true,
            // checkingAccountConfirmValid: true,
            // checkingAccountConfirmMatch: true,
            // checkingAccountRoutingValid: true,
            // checkingAccountRoutingConfirmValid: true,
            // checkingAccountRoutingConfirmMatch: true,
            // confirmCheckingAccountValid: true,
            // checkingAccountValid2: true,
            // checkingAccountConfirmValid2: true,
            // checkingAccountConfirmMatch2: true,
            // checkingAccountRoutingValid2: true,
            // checkingAccountRoutingConfirmValid2: true,
            // checkingAccountRoutingConfirmMatch2: true,
            // dfiAuthorizationValid: true,
            secondaryCheckingAccountNumberVerification: true,
            ackValid: true,
            ack: false,
            auth: false,
            // confirm: false, 
            secondarySame: false,
            previousInput: '',
            previousInputValidation: ''
        }
    },
    mounted: function () {
        let _this = this;
        this.$emit("rejectedSecondary", this.eftUpdate.sportsgamingActive);
        if (this.eftUpdate.secondaryEFTShared === true) {
            this.secondarySame = true;
        }
        
        $("#updateEftForm").foundation();
        $('#updateEftForm').on("formvalid.zf.abide", function (ev, frm) {
            _this.updateEftForm = this.validateEFT();
        });
        // }
    },
    computed: {},
    methods: {
        blockInvalidChar() {
            let input = this.eftUpdate.checkingAccountRoutingNumber,
                rule = /^[0-9]*$/,
                result = rule.test(input);

            if (result === true) {
                this.previousInput = input;
            } else {

            }

            this.eftUpdate.checkingAccountRoutingNumber = this.previousInput;
        },
        saveEFTData() {
            // might not be needed
        },
        toggleSecondary() {
            this.secondarySame = !this.secondarySame;
            // this is not working. Calling this again anywhere doesn't work
            $('#updateEftForm').foundation();
            if (this.secondarySame) {
                // empty the fields
                // this.eftUpdate.sportsgamingActive = true;
                this.eftUpdate.secondaryCheckingAccountNumber = '';
                this.eftUpdate.secondaryCheckingAccountNumberVerification = '';
                this.eftUpdate.secondaryCheckingAccountRoutingNumber = '';
                this.eftUpdate.secondaryCheckingAccountRoutingNumberVerification = '';
            } else {
                // this is not working. Calling this again anywhere doesn't work
                $('#updateEftForm').foundation();
            }
        },
        validateEFT() {
            //     this.dfiNameValid = this.eftUpdate.dfiName;
            //     this.accountNameValid = this.eftUpdate.eftAccountName;
            //     this.checkingAccountValid = this.eftUpdate.checkingAccountNumber && this.accountNumberValidation(this.eftUpdate.checkingAccountNumber);
            //     this.checkingAccountConfirmValid = this.eftUpdate.checkingAccountNumberVerification && this.accountNumberValidation(this.eftUpdate.checkingAccountNumberVerification);
            //     this.checkingAccountConfirmMatch = this.eftUpdate.checkingAccountNumber == this.eftUpdate.checkingAccountNumberVerification;

            //     this.checkingAccountRoutingValid = this.eftUpdate.checkingAccountRoutingNumber && this.routingNumberValidation(this.eftUpdate.checkingAccountRoutingNumber);
            //     this.checkingAccountRoutingConfirmValid = this.eftUpdate.checkingAccountRoutingNumberVerification;
            //     this.checkingAccountRoutingConfirmMatch = this.eftUpdate.checkingAccountRoutingNumber == this.eftUpdate.checkingAccountRoutingNumberVerification;

            //     this.checkingAccountValid2 = this.eftUpdate.secondaryCheckingAccountNumber && this.accountNumberValidation(this.eftUpdate.secondaryCheckingAccountNumber);
            //     this.checkingAccountConfirmValid2 = this.eftUpdate.checkingAccountNumberVerification2 && this.accountNumberValidation(this.eftUpdate.checkingAccountNumberVerification2);
            //     this.checkingAccountConfirmMatch2 = this.eftUpdate.secondaryCheckingAccountNumber == this.eftUpdate.checkingAccountNumberVerification2;

            //     this.checkingAccountRoutingValid2 = this.eftUpdate.secondaryCheckingAccountRoutingNumber && this.routingNumberValidation(this.eftUpdate.secondaryCheckingAccountRoutingNumber);
            //     this.checkingAccountRoutingConfirmValid2 = this.eftUpdate.checkingAccountRoutingNumberVerification2;
            //     this.checkingAccountRoutingConfirmMatch2 = this.eftUpdate.secondaryCheckingAccountRoutingNumber == this.eftUpdate.checkingAccountRoutingNumberVerification2;

            this.dfiAuthorizationValid = this.eftUpdate.dfIauthorization;
            this.ackValid = this.eftUpdate.signatureAcknowledgement;

            if (this.dfiAuthorizationValid && this.ackValid) {
                return true;
            }
            return false;
        }
    }
}
</script>