import Vue from "vue";

import ProgressiveJackpotValue from "./ProgressiveJackpotValue.vue";

export function load(payload) {
	const selector = "progressive-jackpot-value";
	const store = payload;

	// Is the custom Vue root element in the DOM?
	if (!document.querySelector(selector)) {
	return; // go no further
	}

  // Create a new Vue app with the imported component
	new Vue({
		store,
		render: createElement =>
			createElement(ProgressiveJackpotValue, {
			props: {
				...window[selector]
			}
		}),
	}).$mount(selector);
}