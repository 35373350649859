<template>
    <div class="full">
        <div v-if="isUserLogged">
            <!--User Logged In -->
            <div v-if="kyc">
                <!-- Verified -->
                <h1>Verify My Identity</h1>
                <p><strong>We have received your ID upload for verification.</strong></p>
                <p><span class="icon-check verifiedCheck"></span> We have verified your ID. You can now close this browser tab.</p>
                <!-- <p><a href="/mylotto-rewards/my-account/verify-my-identity/" class="button btn-tertiary">Verify My Id</a></p> -->
            </div>
            <div v-else>
                <!--Not Verified-->
                <!-- <h1>ID Verification Upload Success</h1>
                <p><strong>We have received your upload for ID verification.</strong></p> -->
                <div v-if="hasErrors">
                  <!--Has Errors-->
                  <h1>Verify My Identity</h1>
                  <p><strong>We have received your ID upload for verification.</strong></p>
                  <h3>There was a problem with your verification.</h3>
                  <p>The ID you provided does not match the name or birth date we have on record for your MyLotto Rewards account. You can try again with an alternate ID (passport, state drivers license or state ID card).</p>
                  <p>If this is in error, please <a href="/mylotto-rewards/help">contact Customer Support</a> to resolve this issue.</p>
                  <p><a href="/mylotto-rewards/my-account/verify-my-identity" class="button btn-primary">Try Again</a></p>
                  <!-- <p>Please visit your <a href="/mylotto-rewards/my-account/my-profile" target="_blank">MyLotto Rewards Profile</a> to correct the errors listed below before attempting to verify your ID again.</p> -->
                    <!-- <ul>
                      <li v-for="reason in attemptReasons" class="error">{{ reason }}</li>
                    </ul> -->
                </div>
                <div v-else-if="!hasErrors && kycVerificationLink !== ''">
                    <h1>Verify My Identity</h1>
                    <HollowDotsSpinner v-if="isLoading" color="#0FD8C9" class="home-banner-loader" />
                    <p class="processingAnchor">We are still processing your ID.</p>
                </div>
                <div v-else>
                    <h1>Verify My Identity</h1>
                    <h3>There was a problem with your verification.</h3>
                    <p><strong>We did not receive your ID upload for verification.</strong></p>
                    <p><a href="/mylotto-rewards/my-account/verify-my-identity" class="button btn-primary">Try Again</a></p>
                </div>
                <!-- <div v-else>
                    <p>Please use the link below to check your verification status:</p>
                    <p><a href="/mylotto-rewards/my-account/verify-my-identity/" class="button btn-tertiary">Verify My Id</a></p>
                </div> -->
            </div>
            <!--<div v-else-if="failedKYC">
                <h1>ID Verification Upload Unsuccessful</h1>
                <p>The ID document you provided was not successfully submitted. Please try again, using the link below:</p>
                <p><a href="/mylotto-rewards/my-account/verify-my-identity/" class="button btn-tertiary">Verify My Id</a></p>
                <p>If you continue experiencing an issue, please contact MyLotto Rewards<sup>&reg;</sup> Customer Service</p>
                <p>Where are we directing them if there is an issue?</p>
            </div> -->
        </div>
        <div v-else>
            <!--User Not Logged In -->
            <!-- Coming from App -->
            <h1>Sign In below to complete your verification.</h1>
            <!-- <p>If you were looking to verify your ID for MyLotto Rewards, please use the link below:</p> -->
        </div>
        <SignInRegister v-if="!isUserLogged"></SignInRegister>
    </div>
</template>
<script>
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    import { HollowDotsSpinner } from 'epic-spinners';
    export default {
        components: {
            SignInRegister, HollowDotsSpinner
        },
        data: function() {
            return {
                isUserLogged: false,
                token: {},
                user: {},
                id: '',
                kyc: false,
                failedKYC: null,
                attemptReasons: [],
                hasErrors: null,
                kycVerificationLink: ''
            }
        },
        beforeMount: function() {
            this.determineSuccessOrFail();
        },
        mounted: function() {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                    .then((token) => {
                        this.token = token;
                        // console.log(this.token);

                        if (this.isUserLogged) {
                            this.user = this.$cookies.get('apiToken');
                            this.id = this.user.user.userId;
                            // this.kyc = this.user.user.KYCVerified;
                            // this.userPhone = this.user.user.phoneNumber;
                            // this.userEmail = this.user.user.email;
                            // console.log(this.user);
                            // console.log(`KYC : ${this.kyc}`);
                            // console.log(this.userPhone);
                            // console.log(this.userEmail);
                        }
                    })
                    // .then(() => {
                    //     this.determineSuccessOrFail();
                    // })
                    .then(() => {
                        this.getKYCVerified();
                    })
                    .then(() => {
                        this.getNewAPItoken();
                    })
            },
            getKYCVerified() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.id;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json.data);
                    this.kyc = json.data.userIsVerified;
                    this.hasErrors = json.data.hasErrors;
                    this.kycValidationLink = json.data.kycVerificationLink;

                    if (json.data.errorMessage !== '') {
                        this.displayIDErrors(json.data.errorMessage);
                    }
                    // this.kyc = true;
                    console.log(`KYC : ${this.kyc}`);
                    // console.log(`Link URL : ${this.verifyLink}`);
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            },
            determineSuccessOrFail() {
                 let searchParams = new URLSearchParams(window.location.search);
                 let path = searchParams.get('cyk');
                 console.log(path);

                 if(path === 'nocyk') {
                    //path not null means failed
                    this.failedKYC = true;
                 } else if (path === 'cyk') {
                    this.failedKYC = false;
                 }
            },
            displayIDErrors(errors) {
                console.log(errors);
                let errorArr = errors.split(',');
                this.attemptReasons = errorArr;
            },
            recheckKYCStatus() {
                //check this, not currently called
                setInterval(() => {
                    this.getKYCVerified();
                }, 10000)
            }
        }
    }
</script>
<style lang="scss" scoped>
    li.error {
        margin-bottom: 0;
    }

    .verifiedCheck {
        color: #0fd8c9;
        font-size: 24px;
        vertical-align: middle;
        border: 2px solid #0fd8c9;
        border-radius: 50%;
        padding: 2px;
    }
</style>