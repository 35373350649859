<template>
    <div class="">
        <div class="secondChance">
            <h1>Promotion Winners</h1>

            <div class="moduleContent cf">
                <p><strong v-if="!isWinners">{{noWinnermsg}}</strong></p>
                <p><strong v-if="isWinners">If you have not yet been contacted, regarding your prize, you will be contacted within the next 1-2 business days.</strong></p>
            </div>
            
            <div class="moduleContent cf">
                <div class="module_callOut scWinnersTable" v-for="winner of winners" :key="winner.id">
                    <h3>{{ winner.drawingName }}</h3>
                    <div class="scWinnerJSON" style="display: block;">
                        <table class="purple_table scWinnerTbl">
                            <tbody>
                                <tr>
                                    <th>Winner's Name</th>
                                    <th>Location</th>
                                    <th>Draw Date</th>
                                    <th>Prize</th>
                                </tr>
                                <tr v-for="winnerTable of winner.winners" :key="winnerTable.id">
                                    <td>{{ winnerTable.fullName }}</td>
                                    <td>{{ winnerTable.location}}</td>
                                    <td>{{ customFormatter(winnerTable.winDate) }}</td>
                                    <td>{{ winnerTable.prizeDescription }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        data: function () {
            return {
                isWinners: true,
                winners: [],
                noWinnermsg: '',
                promotionId: document.getElementById('PromotionID').value
            }
        },
        mounted: function () {
            this.getToken()
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('LL');
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();

                console.log(this.promotionId)
                
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/SecondChance/DrawingWinners/Get-Winners-By-Promotion-Id/"+this.promotionId, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let result = json.data;
                    console.log(result)
                    if(result == null){
                        this.isWinners = false;
                        this.noWinnermsg = "Check back soon for promotion winners."
                    }
                    this.winners = result;
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
</style>