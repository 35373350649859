<template>
    <div class="moduleContent equipForm cf saveForm">

        <h2>Lottery Equipment</h2>

        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />


        <div v-if="!isLoading" class="form-horizontal eapp_equipmentinformation">
            <h4>Monitors</h4>

            <p>Indicate the size and number of units needed for this location. Leave blank if unit is not required.</p>

            <table class="equipMonitorTbl">
                <tbody>
                    <tr>
                        <th>Monitor Size</th>
                        <th>Wall-mount</th>
                        <th>Ceiling-mount</th>
                    </tr>
                    <tr>
                        <td>Small (up to 32")</td>
                        <td>
                            <input :disabled="formReadOnly" v-model="application.equipment.smallMonitorWall"
                                @change="validateMonitor($event)" name="SmallMonitorWall" type="text" maxlength="3"
                                id="SmallMonitorWall">
                        </td>
                        <td>
                            <input :disabled="formReadOnly" v-model="application.equipment.smallMonitorCeiling"
                                @change="validateMonitor($event)" name="SmallMonitorCeiling" type="text" maxlength="3"
                                id="SmallMonitorCeiling">
                        </td>
                    </tr>
                    <tr>
                        <td>Medium (33" to 42")</td>
                        <td>
                            <input :disabled="formReadOnly" v-model="application.equipment.mediumMonitorWall"
                                @change="validateMonitor($event)" name="MediumMonitorWall" type="text" maxlength="3"
                                id="MediumMonitorWall">
                        </td>
                        <td>
                            <input :disabled="formReadOnly" v-model="application.equipment.mediumMonitorCeiling"
                                @change="validateMonitor($event)" name="MediumMonitorCeiling" type="text" maxlength="3"
                                id="MediumMonitorCeiling">
                        </td>
                    </tr>
                    <tr>
                        <td>Large (43" to 50")</td>
                        <td>
                            <input :disabled="formReadOnly" v-model="application.equipment.largeMonitorWall"
                                @change="validateMonitor($event)" name="LargeMonitorWall" type="text" maxlength="3"
                                id="LargeMonitorWall">
                        </td>
                        <td>
                            <input :disabled="formReadOnly" v-model="application.equipment.largeMonitorCeiling"
                                @change="validateMonitor($event)" name="LargeMonitorCeiling" type="text" maxlength="3"
                                id="LargeMonitorCeiling">
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="error" v-if="monitorError">Quantities must be in numeric format only.</div>

            <div class="formRow radiosRight cf">
                <span>Type of ceiling:</span>
                <div class="formRowRight radios cf">
                    <span id="EquipTypeCeiling_list" class="radio radio-list-vertical">
                        <input :disabled="formReadOnly" v-model="application.equipment.equipTypeCeiling"
                            id="EquipTypeCeiling_list_0" type="radio" name="EquipTypeCeiling" value="Drywall">
                        <label for="EquipTypeCeiling_list_0">Drywall</label><br>
                        <input :disabled="formReadOnly" v-model="application.equipment.equipTypeCeiling"
                            id="EquipTypeCeiling_list_1" type="radio" name="EquipTypeCeiling" value="Open">
                        <label for="EquipTypeCeiling_list_1">Open</label><br>
                        <input :disabled="formReadOnly" v-model="application.equipment.equipTypeCeiling"
                            id="EquipTypeCeiling_list_2" type="radio" name="EquipTypeCeiling" value="Drop">
                        <label for="EquipTypeCeiling_list_2">Drop</label>
                    </span>
                </div>
            </div>

            <div class="formRow field10wide dividerRow cf" id="estimatedCeilingHeight">
                <label for="EquipEstimatedCeilingHeight">Estimated ceiling height:</label>
                <input :disabled="formReadOnly" v-model="application.equipment.equipEstimatedCeilingHeight"
                    name="EquipEstimatedCeilingHeight" type="text" maxlength="20" id="EquipEstimatedCeilingHeight">
            </div>

            <div class="formRow cf businessType">
                <label for="EquipBusinessType_dropDownList">Business type:</label>

                <select :disabled="formReadOnly" v-model="application.equipment.equipBusinessType" name="EquipBusinessType"
                    id="EquipBusinessType" class="">
                    <option value="null">Select...</option>
                    <option value="53110">53110 - Department-Discount Store</option>
                    <option value="54111">54111 - Supermarkets</option>
                    <option value="54112">54112 - Small Food-Deli-Specialty Food Store</option>
                    <option value="54113">54113 - Convenience Food Store</option>
                    <option value="55411">55411 - Gasoline Stations</option>
                    <option value="55412">55412 - Gasoline Station W-Convenience Store</option>
                    <option value="58120">58120 - Eating Place</option>
                    <option value="58130">58130 - Bars-Taverns</option>
                    <option value="59120">59120 - Drug Stores</option>
                    <option value="59210">59210 - Liquor Carry Out Stores &amp; Drive-Thrus</option>
                    <option value="59940">59940 - Newsstands &amp; Tobacco Shops</option>
                    <option value="59991">59991 - Small Retail Sales Stores</option>
                    <option value="59992">59992 - Small Retail Service Stores</option>
                    <option value="59993">59993 - Agencies &amp; Clubs</option>
                    <option value="59994">59994 - General Service Stores</option>
                    <option value="59995">59995 - Video Stores</option>
                    <option value="59996">59996 - Money Exchange-Check Cashing Stores</option>
                    <option value="59997">59997 - Bowling Lanes</option>
                    <option value="59998">59998 - Variety Stores</option>
                    <option value="59999">59999 - Hardware Stores</option>
                </select>
                <br><br>
            </div>
            <div class="formRow cf businessType">
                Security:<br />
                <div class="formRowRight checkboxWithExplan cf CheckBoxField checkbox">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipSecurityAcceptance"
                        id="EquipSecurityAcceptance" type="checkbox" name="EquipSecurityAcceptance">
                    <label class="explanation-text" for="EquipSecurityAcceptance">Applicant can provide reasonable security
                        for equipment, tickets and proceeds</label>
                </div>
            </div>
            <div class="formRow fileUpload cf businessType">
                <label for="EquipSiteLayoutFile">Site layout: <a v-if="fileLayout === true"
                        @click="downloadFile()">Click Here to View
                        {{ application.equipment.equipSiteLayoutFileName }}</a></label>
                <!-- <input :disabled="formReadOnly" type="file" name="EquipSiteLayoutFile" id="EquipSiteLayoutFile" @input="event => handleFile(event)"> -->

                <VueFileAgent ref="vueFileAgent" :multiple="false" :deletable="true" :meta="true" :linkable="true"
                    :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.zip'" :theme="'list'" :maxSize="'5MB'"
                    :maxFiles="1" :helpText="'Choose file'" :errorText="{
                        type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png, zip.',
                        size: 'Files should not exceed 5mb in size',
                    }" @select="handleFile($event)" @beforedelete="onBeforeDelete($event)" @delete="onDelete($event)">
                </VueFileAgent>
                <span class="explanation-text">Upload a sketch of the retail location's floor plan.</span>
            </div>
            <div class="formRow dividerRow cf checkboxWithExplan businessType">
                <label for="EquipOnPremiseConsumption">On premise consumption:</label>
                <span class="CheckBoxField checkbox">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipOnPremiseConsumption"
                        id="EquipOnPremiseConsumption" type="checkbox" name="EquipOnPremiseConsumption">
                    <label for="EquipOnPremiseConsumption" class="explanation-text">Check this box for on premise
                        consumption</label>
                </span>
            </div>
            <br /><br />
            <h4>ADA Compliance Information</h4>
            <div class="formRow radiosRight cf" id="adaCompliance">
                <span style="display: block; float: left; width: 23%;">ADA Compliance:</span>
                <div class="formRowRight radios cf">
                    <span id="EquipADAInCompliance_list" class="radio radio-list-vertical">
                        <input :disabled="formReadOnly" v-model="application.equipment.equipADAInCompliance"
                            id="EquipADAInCompliance_list_0" type="radio" name="EquipADAInCompliance" value="true">
                        <label for="EquipADAInCompliance_list_0">In Compliance</label><br>
                        <input :disabled="formReadOnly" v-model="application.equipment.equipADAInCompliance"
                            id="EquipADAInCompliance_list_1" type="radio" name="EquipADAInCompliance" value="false">
                        <label for="EquipADAInCompliance_list_1">Not Achievable</label>
                    </span>
                </div>
            </div>
            <div class="formRow datePicker cf">
                <label for="EquipADAInspectionDate">Inspection Date:</label>
                <datepicker :disabled="formReadOnly" placeholder="Select Date" name="EquipADAInspectionDate"
                    :format="customFormatter" v-model="application.equipment.equipADAInspectionDate" style="width: 58%;">
                </datepicker><!--:input-class="input-width-58"-->
            </div>
            <div class="formRow datePicker cf">
                <label for="EquipADANoticeSentDate">Notice Sent Date:</label>
                <datepicker :disabled="formReadOnly" placeholder="Select Date" name="EquipADANoticeSentDate"
                    :format="customFormatter" v-model="application.equipment.equipADANoticeSentDate" style="width: 58%;">
                </datepicker> <!--:input-class="input-width-58"-->
            </div>
            <div class="formRow notCompliantReasons cf">
                <div>Not Compliant Reasons:</div>
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="cell small-6 medium-3 checkboxes-wrapper">
                        <input :disabled="formReadOnly" v-model="application.equipment.equipADANotCompliantParking"
                            id="EquipADANotCompliantParking" type="checkbox" name="EquipADANotCompliantParking">
                        <label for="EquipADANotCompliantParking" class="explanation-text">Parking</label>
                    </div>
                    <div class="cell small-6 medium-3 checkboxes-wrapper">
                        <input :disabled="formReadOnly" v-model="application.equipment.equipADANotCompliantExteriorTravel"
                            id="EquipADANotCompliantExteriorTravel" type="checkbox"
                            name="EquipADANotCompliantExteriorTravel">
                        <label for="EquipADANotCompliantExteriorTravel" class="explanation-text">Exterior Travel</label>
                    </div>
                    <div class="cell small-6 medium-3 checkboxes-wrapper">
                        <input :disabled="formReadOnly" v-model="application.equipment.equipADANotCompliantEntrance"
                            id="EquipADANotCompliantEntrance" type="checkbox" name="EquipADANotCompliantEntrance">
                        <label for="EquipADANotCompliantEntrance" class="explanation-text">Entrance</label>
                    </div>
                    <div class="cell small-6 medium-3 checkboxes-wrapper">
                        <input :disabled="formReadOnly" v-model="application.equipment.equipADANotCompliantInteriorTravel"
                            id="EquipADANotCompliantInteriorTravel" type="checkbox"
                            name="EquipADANotCompliantInteriorTravel">
                        <label for="EquipADANotCompliantInteriorTravel" class="explanation-text">Interior Travel</label>
                    </div>
                    <div class="cell small-6 medium-3 checkboxes-wrapper">
                        <input :disabled="formReadOnly" v-model="application.equipment.equipADASpacing" id="EquipADASpacing"
                            type="checkbox" name="EquipADASpacing">
                        <label for="EquipADASpacing" class="explanation-text">Spacing</label>
                    </div>
                    <div class="cell small-6 medium-3 checkboxes-wrapper">
                        <input :disabled="formReadOnly" v-model="application.equipment.equipADASignage" id="EquipADASignage"
                            type="checkbox" name="EquipADASignage">
                        <label for="EquipADASignage" class="explanation-text">Signage</label>
                    </div>
                </div>

                <!-- <table class="equipTble">
                    <tbody>
                        <tr>
                            <td class="CheckBoxField checkbox">
                                <input :disabled="formReadOnly" v-model="application.equipment.equipADANotCompliantParking" id="EquipADANotCompliantParking" type="checkbox" name="EquipADANotCompliantParking">
                                <label for="EquipADANotCompliantParking" class="explanation-text">Parking</label>
                            </td>
                            <td class="CheckBoxField checkbox">
                                <input :disabled="formReadOnly" v-model="application.equipment.equipADANotCompliantExteriorTravel" id="EquipADANotCompliantExteriorTravel" type="checkbox" name="EquipADANotCompliantExteriorTravel">
                                <label for="EquipADANotCompliantExteriorTravel" class="explanation-text">Exterior Travel</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="CheckBoxField checkbox">
                                <input :disabled="formReadOnly" v-model="application.equipment.equipADANotCompliantEntrance" id="EquipADANotCompliantEntrance" type="checkbox" name="EquipADANotCompliantEntrance">
                                <label for="EquipADANotCompliantEntrance" class="explanation-text">Entrance</label>
                            </td>
                            <td class="CheckBoxField checkbox">
                                <input :disabled="formReadOnly" v-model="application.equipment.equipADANotCompliantInteriorTravel" id="EquipADANotCompliantInteriorTravel" type="checkbox" name="EquipADANotCompliantInteriorTravel">
                                <label for="EquipADANotCompliantInteriorTravel" class="explanation-text">Interior Travel</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="CheckBoxField checkbox">
                                <input :disabled="formReadOnly" v-model="application.equipment.equipADASpacing" id="EquipADASpacing" type="checkbox" name="EquipADASpacing">
                                <label for="EquipADASpacing" class="explanation-text">Spacing</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="CheckBoxField checkbox">
                                <input :disabled="formReadOnly" v-model="application.equipment.equipADASignage" id="EquipADASignage" type="checkbox" name="EquipADASignage">
                                <label for="EquipADASignage" class="explanation-text">Signage</label>
                            </td>
                        </tr>
                    </tbody>
                </table> -->
            </div>
            <h4>Requested Equipment</h4>
            <div class="formRow cf grid-x grid-margin-x grid-margin-y equip-grid">
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipCDU" @change="validateEqiup($event)"
                        name="EquipCDU" type="text" maxlength="3" id="EquipCDU">
                    <label class="explanation-text">CDU</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipPhoton" @change="validateEqiup($event)"
                        name="EquipPhoton" type="text" maxlength="3" id="EquipPhoton">
                    <label class="explanation-text">Photon</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipTouchWin"
                        @change="validateEqiup($event)" name="EquipTouchWin" type="text" maxlength="3" id="EquipTouchWin">
                    <label class="explanation-text">Touch &amp; Win</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipKenoBacklight"
                        @change="validateEqiup($event)" name="EquipKenoBacklight" type="text" maxlength="3"
                        id="EquipKenoBacklight">
                    <label class="explanation-text">Keno Backlight</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipPlayStation"
                        @change="validateEqiup($event)" name="EquipPlayStation" type="text" maxlength="3"
                        id="EquipPlayStation">
                    <label class="explanation-text">PlayStation</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipWinStation"
                        @change="validateEqiup($event)" name="EquipWinStation" type="text" maxlength="3"
                        id="EquipWinStation">
                    <label class="explanation-text">WinStation</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipMP" @change="validateEqiup($event)"
                        name="EquipMP" type="text" maxlength="3" id="EquipMP">
                    <label class="explanation-text">MP</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipPole" @change="validateEqiup($event)"
                        name="EquipPole" type="text" maxlength="3" id="EquipPole">
                    <label class="explanation-text">Pole Mount</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipWiredTicketChecker"
                        @change="validateEqiup($event)" name="EquipWiredTicketChecker" type="text" maxlength="3"
                        id="EquipWiredTicketChecker">
                    <label class="explanation-text">Wired Ticket Checker</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipMPNG" @change="validateEqiup($event)"
                        name="EquipMPNG" type="text" maxlength="3" id="EquipMPNG">
                    <label class="explanation-text">MPNG</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipSATSign" @change="validateEqiup($event)"
                        name="EquipSATSign" type="text" maxlength="3" id="EquipSATSign">
                    <label class="explanation-text">SAT Sign</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipWirelessReader"
                        @change="validateEqiup($event)" name="EquipWirelessReader" type="text" maxlength="3"
                        id="EquipWirelessReader">
                    <label class="explanation-text">Wireless Reader</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipPAD" @change="validateEqiup($event)"
                        name="EquipPAD" type="text" maxlength="3" id="EquipPAD">
                    <label class="explanation-text">Pad</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipStarlight"
                        @change="validateEqiup($event)" name="EquipStarlight" type="text" maxlength="3" id="EquipStarlight">
                    <label class="explanation-text">Starlight</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipWirelessTicketChecker"
                        @change="validateEqiup($event)" name="EquipWirelessTicketChecker" type="text" maxlength="3"
                        id="EquipWirelessTicketChecker">
                    <label class="explanation-text">Wireless Ticket Checker</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipDreamTouchSmart"
                        @change="validateEqiup($event)" name="EquipDreamTouchSmart" type="text" maxlength="3"
                        id="EquipDreamTouchSmart">
                    <label class="explanation-text">Dream Touch Smart</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipSelect4Unit"
                        @change="validateEqiup($event)" name="EquipSelect4Unit" type="text" maxlength="3"
                        id="EquipSelect4Unit">
                    <label class="explanation-text">Select 4 Unit</label>
                </div>
                <div class="cell small-6 medium-4 large-3 xlarge-2">
                    <input :disabled="formReadOnly" v-model="application.equipment.equipSciTrackUnit"
                        @change="validateEqiup($event)" name="EquipSciTrackUnit" type="text" maxlength="3"
                        id="EquipSciTrackUnit">
                    <label class="explanation-text">Sci Track Unit</label>
                </div>
            </div>
            <div class="error" v-if="equipRequestError">Quantities must be in numeric format only.</div>
            <div class="formRow cf">
                <label for="">Other:</label>
                <input :disabled="formReadOnly" v-model="application.equipment.equipOtherDesc" name="EquipOtherDesc" type="text"
                    maxlength="600" id="EquipOtherDesc" class="otherField">
            </div>
            <br>
            <h4>Notes</h4>
            <textarea v-model="application.equipment.adminNotes" name="equipmentAdminNotes" rows="2" cols="20"
                id="equipmentAdminNotes" class="adminNotes"></textarea>
            <div class="btn-actions">
                <button type="button" :disabled="equipRequestError || monitorError" name="Save" @click="onNextAdminClick"
                    id="equipmentReviewSave" class="btn btn-primary btnNext" title="Save the document">Save</button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { HollowDotsSpinner } from 'epic-spinners';
// https://www.npmjs.com/package/vue-moment-datepicker
import Datepicker from "vue-moment-datepicker";
import { useVuelidate } from '@vuelidate/core'
export default {
    components: {
        HollowDotsSpinner,
        Datepicker
    },
    props: {
        applicationJson: String,
        userId: Number,
        staffEquipmentToken: String,
        userRole: String,
        readOnly: Boolean
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data: function () {
        return {
            equipment: {},
            application: {},
            submitting: false,
            isLoading: true,
            eappId: false,
            monitorError: false,
            equipRequestError: '',
            formReadOnly: '',
            fileRecords: [],
            uploadUrl: '',
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUpload: [],
            fileLayout: false
        }
    },
    mounted: function () {
        this.init();
        this.formReadOnly = this.userRole == 'eApp-CentralSalesManager';
        this.formReadOnly === true ? false : this.formReadOnly = this.readOnly;
    },
    methods: {
        init() {
            this.deserializeJson();
            this.isLoading = false;
        },
        deserializeJson() {
            this.application = JSON.parse(this.applicationJson);
            // console.log(this.application.equipment.equipSiteLayoutFile);
            // console.log(this.application.equipment.equipADAInCompliance);
            this.fileRecords.concat(this.application.equipment.equipSiteLayoutFile);
            if (this.application.equipment.equipSiteLayoutFile === null || this.application.equipment.equipSiteLayoutFile === "" || this.application.equipment.equipSiteLayoutFile === undefined) {
                this.fileLayout = false;
            } else {
                this.fileLayout = true;
            }
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/yyyy');
        },
        validateEqiup(event) {
            let thisVal = event.target.value;
            this.equipRequestError = this.isValNumber(thisVal);
        },
        validateMonitor(event) {
            let thisVal = event.target.value;
            this.monitorError = this.isValNumber(thisVal);
        },
        isValNumber(num) {
            return isNaN(num);
        },
        onNextAdminClick() {
            this.$emit('onNextAdminClick', { application: this.application, step: 'equipment', updates: true });
        },
        downloadFile() {
            var binaryString = window.atob(this.application.equipment.equipSiteLayoutFile);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            var blob = new Blob([bytes], { type: this.application.equipment.equipSiteLayoutFileExtension });
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        },
        // handleFile(event) {
        //     // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
        //     var file = event.target.files[0];
        //     var reader = new FileReader();
        //     var fileData;
        //     var fileType = '';
        //     var fileName = '';
        //     reader.readAsDataURL(file, 'base64');
        //     reader.onload = function (e) {
        //         var fileDataArray = reader.result.split(',')[1];
        //         fileData = fileDataArray
        //         fileType = file.type;
        //         fileName = file.name;
        //     }
        //     reader.onloadend = () => {
        //         this.application.equipment.equipSiteLayoutFile = fileData;
        //         this.application.equipment.equipSiteLayoutFileExtension = fileType;
        //         this.application.equipment.equipSiteLayoutFileName = fileName;
        //     }
        // }
        handleFile(event) {
            // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
            var _this = this;
            var file = event[0].file;
            var fileName = event[0].file.name;
            var fileType = event[0].file.type

            const reader = new FileReader();
            reader.onload = function (e) {
                _this.application.equipment.equipSiteLayoutFile = e.target.result.split(',')[1];
                _this.application.equipment.equipSiteLayoutFileExtension = fileType;
                _this.application.equipment.equipSiteLayoutFileName = fileName;
            };

            reader.readAsDataURL(file);
        },
        onBeforeDelete(event) {
            // optionally, you can confirm and call the deleteFileRecord method
            this.$refs.vueFileAgent.deleteFileRecord(event);
        },
        onDelete(event) {
            this.application.equipment.equipSiteLayoutFile = null;
            this.application.equipment.equipSiteLayoutFileExtension = null;
            this.application.equipment.equipSiteLayoutFileName = null;
        }
    }
};
</script>
<style lang="scss" scoped>
.checkboxes-wrapper {
    display: flex;
    align-items: baseline;

    input {
        margin: 0;
    }
}

.notCompliantReasons {
    padding-top: 20px;

    hr {
        margin: 1.5rem auto;
    }

    .cell {
        margin: 0;
    }
}
</style>
