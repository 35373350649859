<template>
    <div>
        <TicketEntryForm 
            v-if="tEntry === 'true' && showTicketEntryForm"
            contentKey="ticket-entry-text" 
            requireDigits="3" 
            placeholderProp=""
            :headingProp="header"
            submitTextProp="">
        </TicketEntryForm>
        <PromotionCodeEntry v-if="tEntry === 'false' && showTicketEntryForm" contentKey="promotion-code-entry-text" requireDigits="3"></PromotionCodeEntry>
        <SignInRegister v-if="!showTicketEntryForm"></SignInRegister>
    </div>
        
</template>

<script>
    import TicketEntryForm from './TicketEntry.vue';
    import PromotionCodeEntry from './PromotionCodeEntry.vue';
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        props: [
            'header',
            'scdCode'
        ],
        components: {
            TicketEntryForm,
            PromotionCodeEntry,
            SignInRegister
        },
        data: function () {
            return {
                showTicketEntryForm: true,
                tEntry: false
            }
        },
        mounted: function () {
            let apiToken = this.$cookies.get('apiToken');

            if(apiToken.user.username == "mobilepublic@mtllc.com"){
                this.showTicketEntryForm = false;
            }

            this.fetchContentFromAPI('manual-ticket-entry', 'manual-ticket-entry')
            .then(json => {
                this.tEntry = json.enabled;
            });
        },
        methods: {
           
        },
        computed: {
           
        },
    };
</script>

<style lang="scss" scoped>

</style>