<template>
    
    <span>
        <a title="Printer Friendly" class="printfriendly" href="#" onclick="window.print()">Print</a>
        <PastResults 
            :gameName="gameName" 
            :titleGameName="titleGameName"
            :descGame="descGame"
            :theadNames="threadNames"
        ></PastResults>

        <Winner v-if="winner"
            :gameName="gameName"
            :inputsQuantity="inputsQuantity"
        >
        </Winner>

        <MyNumberBeenDrawn
            :gameName="gameName"
            :inputsQuantity="inputsQuantity"
        >

        </MyNumberBeenDrawn>

        <FrequentlyNumbers v-if="frequentlyNumbers"
            :gameName="gameName"
            :startNumber="startNumber"
            :endNumber="endNumber"
        >
        </FrequentlyNumbers>
    </span>
</template>

<script>
    import PastResults from '../../ViewComponents/WinningNumbers/CheckNumbersPanels/PastResults.vue';
    import FrequentlyNumbers from '../../ViewComponents/WinningNumbers/CheckNumbersPanels/FrequentlyNumbers.vue';
    import Winner from '../../ViewComponents/WinningNumbers/CheckNumbersPanels/Winner.vue';
    import MyNumberBeenDrawn from '../../ViewComponents/WinningNumbers/CheckNumbersPanels/MyNumberBeenDrawn.vue';

    export default {
        props: ['gameName','titleGameName','descGame','threadNames','inputsQuantity','startNumber','endNumber','formatPickDate','pastResults','winner','frequentlyNumbers'],
        components: {
            PastResults,
            FrequentlyNumbers,
            Winner,
            MyNumberBeenDrawn
        },
        mounted: function () {
            
        }
    };
</script>