<template>
  <section id="account-profile">
    <UserLoginVue v-if="!isUserLogged"></UserLoginVue>
    <form class="module olcForm cf module_callOut">
      <h3>
        Verified Phone Number
        <p class="reqNote">
          <span class="req">*</span>
          Required field
        </p>
      </h3>
      <div class="confirmationCodes">
        <span class="InfoLabel" v-if="complete">
            Your Phone was successfully verified.
        </span>
        <span class="InfoLabel" v-if="submitted">
            A verification code has been sent to your mobile phone. Please provide it to complete the verification process.
        </span>
        <span class="ErrorLabel" v-if="errorCodeSame">
            Primary and alternative phone numbers can not be the same.
        </span>
        <span class="ErrorLabel" v-if="errorCodeSubmit">
            The provided code is not valid. Please try again
        </span>
      </div>
      <div class="cf moduleContent">
        <fieldset class="regForm cf verifyPhoneForm">
          <div class="halfForm clear phoneWrap">
            Primary Phone
            <span class="req">*</span>
            <input type="tel" value="" class="txtPrimaryPhone phoneNumber" v-model="primaryPhone" :disabled="submitted"/>
            <span class="error" v-if="error&&errorPrimary">Please provide a valid phone number format. e.g. 555-555-5555</span>
          </div>
          <div class="halfForm halfFormRight phoneForTxt">
            <span class="smsPhoneCheck">
              <input type="checkbox" v-model="primaryPhoneForTxt" @click="checkPrimary"/>
              <label for="">Use Primary number for text messaging</label>
            </span>
          </div>
          <div class="halfForm clear phoneWrap">
            Alternative Phone
            <input type="tel" class="txtAltPhone phoneNumber" v-model="altPhone" :disabled="submitted"/>
            <span class="error" v-if="error&&errorAlt">Please provide a valid phone number format. e.g. 555-555-5555</span>
          </div>
          <div class="halfFormRight halfForm phoneForTxt">
            <div class="smsPhoneCheck">
                <input type="checkbox" name="" value="" v-model="altPhoneForTxt" @click="checkAlt"/>
                <label for="">Use Alternate number for text messaging</label>
            </div>
          </div>
          <div class="error clear error_smsCheck" v-if="errorText">
            Please select a phone number for text messaging.
          </div>
          <div class="halfForm clear" v-if="submitted">
            Verification Code
            <input type="text" v-model="verificationCode">
            <span class="error" v-if="errorCode">Please enter the code sent to the phone number above.</span>
          </div>
          <div class="fullForm clear formButtons">
            <button type="button" class="button" v-if="submitted === false" @click="validate">SUBMIT</button>
            <button type="button" class="button" v-if="submitted === true" @click="submitCode">SUBMIT CODE</button>
            <button type="button" class="button" v-if="submitted === true" @click="submitPhone">RESEND CODE</button>
            <button type="button" class="button" v-if="submitted === true" @click="unSubmit">EDIT PHONE</button>
            <a href="/MyLotto-Rewards/My-Account/My-Profile">Cancel</a>
          </div>
        </fieldset>
      </div>
    </form>
  </section>
</template>

<script>
import $ from "jquery";
import moment from "moment";
import UserLoginVue from "../Shared/User-Login.vue";

export default {
  components: {
    UserLoginVue,
  },
  data: function () {
    return {
      isUserLogged: this.isUserAuthed(),
      points: this.$cookies.get("apiToken"),
      user: "",
      primaryPhone: "",
      altPhone: "",
      primaryPhoneForTxt: false,
      altPhoneForTxt: false,
      verificationCode: '',
      submitted: false,
      complete: false,
      error: false,
      errorPrimary: false,
      errorAlt: false,
      errorText: false,
      errorPhone: false,
      errorCode: false,
      errorCodeSubmit: false,
      errorSame: false
    };
  },
  mounted: function () {
    this.getToken();
    $(document).foundation();
  },
  methods: {
    getToken() {
      this.getAPItoken().then((token) => {
        this.token = token;
        this.getUser();
      });
    },
    getUser() {
      let url =
          process.env.VUE_APP_APIURL +
          "/1.0/PAM/GetUser/" +
          this.points.user.userId,
        apiToken = this.token;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiToken,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          let data = json.data;

          // data.userDto.birthdate = null;
          // data.listOfRoles.push('CashlessUser');

          if (data.userDto.birthdate === null) {
            this.editBirthdate = true;
          } else {
            data.userDto.birthdate = moment(data.userDto.birthdate).format(
              "MM/DD/YYYY"
            );
          }

          for (let i = 0; i < data.listOfRoles.length; i++) {
            if (data.listOfRoles[i] === "CashlessUser") {
              this.cashlessUser = true;
            }
          }

          this.user = data;

          console.log(this.user);
        });
    },
    checkPrimary() {
        this.altPhoneForTxt = false;
    },
    checkAlt() {
        this.primaryPhoneForTxt = false;
    },
    validate() {
        let primarySuccess = false,
            // altSuccess = false,
            textSuccess = false,
            same = true;

        this.error = false;
        this.errorPrimary = false;
        this.errorAlt = false;
        this.errorText = false;

        if (this.primaryPhone.length >= 10) {
            console.log(this.phoneValidation(this.primaryPhone));

            if (this.phoneValidation(this.primaryPhone) === false) {
                this.error = true;
                this.errorPrimary = true;
            } else {
                primarySuccess = true;
            }
        } else {
            this.error = true;
            this.errorPrimary = true;
        }

        if (this.altPhone.length >= 10) {
            console.log(this.phoneValidation(this.altPhone));

            if (this.phoneValidation(this.altPhone) === false) {
                this.error = true;
                this.errorAlt = true;
            } else {
                // altSuccess = true;
            }
        }

        if (this.primaryPhone === this.altPhone) {
            same = true;
            this.error = true;
            this.errorSame = true;
        }

        if (this.primaryPhoneForTxt === true || this.altPhoneForTxt === true) {
            textSuccess = true;
        } else {
            this.error = true;
            this.errorText = true;
        }

        if (primarySuccess === true && textSuccess === true && same === false) {
            this.submitted = true;
            this.submitPhone();
        }

    },
    submitPhone() {
        // Hit API to save the change
        let apiToken = this.token,
            userId = this.user.userId,
            url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/User/SaveMFPhoneInformation',
            body = {
                "userId": userId,
                "phone": this.primaryPhone,
                "phoneSMSEnabled": this.primaryPhoneForTxt,
                "altPhone": this.altPhone,
                "altPhoneSMSEnabled": this.altPhoneForTxt
            }

        fetch(url, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + apiToken,
            },
        })
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
        });
    },
    submitCode() {
        // Hit API to save the change
        let apiToken = this.token,
            userId = this.user.userId,
            url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/User/VerifyCodeSessionSecondsLeft/' + this.user.userId + '/' + this.verificationCode;

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + apiToken,
            },
        })
        .then((response) => response.json())
        .then((json) => {
            console.log(json);

            let d = json;

            if (d.error === null && d.data === 1200) {
                this.complete = true;
            } else {
                this.error = true;
                this.errorCodeSubmit = true;
            }
        });
    },
    unSubmit() {
        this.submitted = false;
    }
  },
};
</script>