<template>
    <div>
    <div class="ctv-page-header">
        <span class="ohioLottery"></span>
        <span class="myLottoRewards"></span>
    </div>
    <div class="ctv-message-nw">
        <span class="sorry"></span>

        <div class="ctv-panel">
            <div class="ctv-scratch">
                <span class="logo"></span>
            </div>

            <p>Check out some of our many other opportunities to win prizes.</p>
            <a class="btn btn-purple ctv-checkout" href="/MyLotto-Rewards/Club-Promotions">View List</a>
        </div>
    </div>
</div>
</template>


<script>
export default {
    components: {
    },
    data() {
        return {
        };
    },
    mounted() { },
    beforeDestroy() { },
    methods: {},
    computed: {}
}

</script>
<style scoped></style>