import Vue from "vue";

import DigitalPurchasesDetail from "./DigitalPurchasesDetail.vue";

export function load() {
    const selector = "digital-purchases-detail";

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return;
  }

  // Create a new Vue app with the imported Home component
  new Vue({
    render: createElement =>
      createElement(DigitalPurchasesDetail, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}
