<template>
    <div class="eAppModule">
        <div class="moduleContent liabilityForm cf saveForm liabilityLock">
            <h3>Liability Deposit Amount</h3>
            <div v-html="introContent"></div>

            <div class="EditForm eAppFormEdit eapp_info grid-x grid-margin-x grid-margin-y">
                <div class="cell medium-5 large-3">
                    <label>Liability Multiplier:</label>
                </div>
                <div class="cell medium-7 large-9">
                    <span class="radio depositMultiplier radio-list-horizontal">
                        <input :disabled="readOnly" v-model="application.keno.kenoDepositMultiplier" id="DepositMultiplier_0" type="radio"
                            name="DepositMultiplier" value="7">
                        <label for="DepositMultiplier_0">7x</label>
                        <input :disabled="readOnly" v-model="application.keno.kenoDepositMultiplier" id="DepositMultiplier_1" type="radio"
                            name="DepositMultiplier" value="10">
                        <label for="DepositMultiplier_1">10x</label>
                    </span>
                    <div v-if="errorDepositMultiplier" class="error">Please select a multiplier.</div>
                </div>
                <div class="cell medium-5 large-3">
                    <label for="DepositAmount">Current deposit amount:</label>
                </div>
                <div class="cell medium-7 large-9 field10wide">
                    $ <input :disabled="readOnly" v-model="application.keno.kenoDepositAmount" name="DepositAmount" type="number"
                        pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;" maxlength="11"
                        id="DepositAmount" class="depositInput">
                    <div v-if="errorDepositAmount" class="error">Please provide a valid number.</div>
                </div>
                <div class="cell medium-5 large-3">
                    <label for="MaxLiability">Maximum Liability Limit:</label>
                </div>
                <div class="cell medium-7 large-9">
                    $ <span class="maxLiability">{{ maxLiability }}</span>
                    <div class="explanation-text">Maximum liablility limit is calculated by multiplying the Liability
                        Multiplier times the deposit amount.</div>
                </div>
                <div class="cell medium-5 large-3">
                    <label for="DepositCheckNumber">Check Number:</label>
                </div>
                <div class="cell medium-7 large-9 field10wide">
                    <input :disabled="readOnly" v-model="application.keno.kenoDepositCheckNumber" name="DepositCheckNumber" type="text"
                        maxlength="10" id="DepositCheckNumber" class="field30wide">
                    <div v-if="errorDepositCheckNumber" class="error">Please provide a valid check number.</div>
                </div>
                <div class="cell medium-5 large-3">
                    <label for="DepositCurrentDueAmount">Current Due Amount:</label>
                </div>
                <div class="cell medium-7 large-9 field10wide">
                    $ <input :disabled="readOnly" v-model="application.keno.kenoDepositCurrentDueAmount" name="DepositCurrentDueAmount"
                        type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;"
                        maxlength="11" id="DepositCurrentDueAmount" class="currentDueAmount">
                    <div v-if="errorDepositCurrentDueAmount" class="error">Please provide a valid number.</div>
                </div>
                <div class="cell medium-5 large-3">
                    <label for="LiquorLicenseID">Liquor license ID:</label>
                </div>
                <div class="cell medium-7 large-9">
                    <input :disabled="readOnly" v-model="application.keno.kenoLiquorLicenseID" name="LiquorLicenseID" type="text" maxlength="11"
                        id="LiquorLicenseID" class="field30wide">
                    <div v-if="errorLiquorLicenseID" class="error">Please provide a valid number.</div>
                </div>
                <div class="cell medium-5 large-3">
                    <label for="">Liquor License Type:</label>
                </div>
                <div class="cell medium-7 large-9 radiosRight">
                    <div class="checkbox checkbox-list-horizontal">
                        <input :disabled="readOnly" v-model="d1" id="D1" type="checkbox" name="D1" value="1"><label for="1">D1</label>
                        <input :disabled="readOnly" v-model="d2" id="D2" type="checkbox" name="D2" value="2"><label for="2">D2</label>
                        <input :disabled="readOnly" v-model="d3" id="D3" type="checkbox" name="D3" value="3"><label for="3">D3</label>
                        <input :disabled="readOnly" v-model="d4" id="D4" type="checkbox" name="D4" value="4"><label for="4">D4</label>
                        <input :disabled="readOnly" v-model="d5" id="D5" type="checkbox" name="D5" value="5"><label for="5">D5</label>
                        <input :disabled="readOnly" v-model="d7" id="D7" type="checkbox" name="D7" value="6"><label for="6">D7</label>
                        <input :disabled="readOnly" v-model="a1a" id="A1A" type="checkbox" name="A1A" value="7"><label for="7">A1A</label>
                        <input :disabled="readOnly" v-model="a1c" id="A1c" type="checkbox" name="A1c" value="8"><label for="8">A1c</label>
                    </div>
                    <div class="explanation-text">(Check all the apply)</div>
                </div>
                <div class="cell medium-5 large-3 dividerRow ">
                    <label for="">Uploaded File:</label>
                </div>
                <div class="cell medium-7 large-9 fileUpload">
                    <label>File: <a v-if="file === true" @click="downloadFile()">Click Here to
                            View {{ application.keno.kenoUploadedFileName }}</a></label>
                    <!-- <input type="file" id="KenoUploadedFile" name="KenoUploadedFile" @input="event => handleFile(event)" /> -->
                    <VueFileAgent ref="vueFileAgent" :multiple="false" :deletable="true" :meta="true" :linkable="true"
                        :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.zip'" :theme="'list'" :maxSize="'5MB'"
                        :maxFiles="1" :helpText="'Choose file'" :errorText="{
                            type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png, zip.',
                            size: 'Files should not exceed 5mb in size'
                        }" @select="handleFile($event)"
                        @beforedelete="onBeforeDelete($event)" @delete="onDelete($event)">
                    </VueFileAgent>
                    <br />
                    <div class="explanation-text">Upload a copy of the retailer's liquor license or a photograph of the
                        posted "18 or over" sign.</div>
                    <div v-if="errorUploadedFile" class="error">Please upload a photograph.</div>
                </div>
            </div>

            <h4>Notes</h4>
            <textarea v-model="application.keno.liabilityAdminNotes" name="LiabilityAdminNotes" rows="2" cols="20"
                id="LiabilityAdminNotes" class="adminNotes"></textarea>

            <div class="btn-actions">
                <button type="button" name="Save" id="eftReviewSave" @click="onNextAdminClick"
                    class="btn btn-primary btnNext" title="Save the document">Save</button>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    components: {
    },
    props: {
        applicationJson: String,
        userId: Number,
        liabilityToken: String,
        readOnly: Boolean
    },
    data: function () {
        return {
            application: {},
            submitting: false,
            introContent: '',
            errorDepositMultiplier: false,
            errorDepositAmount: false,
            errorDepositCheckNumber: false,
            errorDepositCurrentDueAmount: false,
            errorLiquorLicenseID: false,
            uploadedFile: '',
            errorUploadedFile: false,
            liabilityAdminNotes: '',
            d1: false,
            d2: false,
            d3: false,
            d4: false,
            d5: false,
            d7: false,
            a1a: false,
            a1c: false,
            contentKey: 'eapp-review-screens',
            file: false
        }
    },
    mounted: function () {
        this.init();
        // get content: eapp-review-screens.liability-deposit
    },
    methods: {
        init() {
            this.deserializeJson();
            this.getContent();
        },
        getContent() {
            let cookieContent = JSON.parse(localStorage.getItem('eapp_review_content'));

            if (cookieContent !== undefined && cookieContent !== "" && cookieContent !== null) {
                let pageContent = JSON.parse(cookieContent);
                this.introContent = pageContent.liabilityDeposit;
            } else {
                this.fetchContent();
            }
        },
        fetchContent() {
            fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=' + this.contentKey, {
                headers: {
                    'Authorization': 'Bearer ' + this.liabilityToken
                }
            })
            .then(response => response.json())
            .then(json => {
                let element = json.data[0].centralContent;

                localStorage.setItem('eapp_review_content', JSON.stringify(element));

                let pageContent = JSON.parse(element);
                this.introContent = pageContent.liabilityDeposit;
            })
        },
        deserializeJson() {
            this.application = JSON.parse(this.applicationJson);
            
            let file = this.application.keno.kenoUploadedFile;
            if (file === null || file === undefined || file === '' ) {
                this.file = false;
            } else {
                this.file = true;
            }

            this.setLiquorLicenseTypeCheckboxes();
        },
        onNextAdminClick() {
            this.getLiquorLicenseType();
            this.application.keno.maxLiability = this.maxLiability;
            // console.log(this.application);
            this.$emit('onNextAdminClick', { application: this.application, step: 'liability', updates: true });
        },
        setLiquorLicenseTypeCheckboxes() {
            let licTypes = this.application.keno.kenoLiquorLicenseClass;
            if (licTypes != null) {
                this.d1 = licTypes.includes(1);
                this.d2 = licTypes.includes(2);
                this.d3 = licTypes.includes(3);
                this.d4 = licTypes.includes(4);
                this.d5 = licTypes.includes(5);
                this.d7 = licTypes.includes(6);
                this.a1a = licTypes.includes(7);
                this.a1c = licTypes.includes(8);
            }

        },
        getLiquorLicenseType() {
            let licTypes = [];
            if (this.d1 === true) {
                licTypes.push(1);
            }
            if (this.d2 === true) {
                licTypes.push(2);
            }
            if (this.d3 === true) {
                licTypes.push(3);
            }
            if (this.d4 === true) {
                licTypes.push(4);
            }
            if (this.d5 === true) {
                licTypes.push(5);
            }
            if (this.d7 === true) {
                licTypes.push(6);
            }
            if (this.a1a === true) {
                licTypes.push(7);
            }
            if (this.a1c === true) {
                licTypes.push(8);
            }
            this.application.keno.kenoLiquorLicenseClass = licTypes.toString();
        },
        downloadFile() {
            var binaryString = window.atob(this.application.keno.kenoUploadedFile);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }

            var blob = new Blob([bytes], { type: this.application.keno.kenoUploadedFileExtension });
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        },
        // handleFile(event) {
        //     // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
        //     var file = event.target.files[0];

        //     var reader = new FileReader();
        //     var fileData;
        //     var fileType = '';
        //     var fileName = '';
        //     reader.readAsDataURL(file, 'base64');
        //     reader.onload = function (e) {
        //         var fileDataArray = reader.result.split(',')[1];
        //         fileData = fileDataArray
        //         fileType = file.type;
        //         fileName = file.name;
        //     }

        //     reader.onloadend = () => {
        //         this.application.keno.kenoUploadedFile = fileData;
        //         this.application.keno.kenoUploadedFileExtension = fileType;
        //         this.application.keno.kenoUploadedFileName = fileName;
        //     }
        // },
        handleFile(event) {
            // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
            var _this = this;
            var file = event[0].file;
            var fileName = event[0].file.name;
            var fileType = event[0].file.type

            const reader = new FileReader();
            reader.onload = function (e) {
                if (_this.application.keno !== undefined) {
                    _this.application.keno.kenoUploadedFile = e.target.result.split(',')[1];
                    _this.application.keno.kenoUploadedFileExtension = fileType;
                    _this.application.keno.kenoUploadedFileName = fileName;
                }
            };

            reader.readAsDataURL(file);
        },
        onBeforeDelete(event) {
            // optionally, you can confirm and call the deleteFileRecord method
            this.$refs.vueFileAgent.deleteFileRecord(event);
        },
        onDelete(event) {
            this.application.keno.kenoUploadedFile = null;
            this.application.keno.kenoUploadedFileExtension = null;
            this.application.keno.kenoUploadedFileName = null;
        }
    },
    computed: {
        maxLiability() {

            let maxL = 0;
            if (this.application.keno.kenoDepositMultiplier != null && !Number.isNaN(this.application.keno.kenoDepositMultiplier) && !Number.isNaN(this.application.keno.kenoDepositAmount)) {
                maxL = this.application.keno.kenoDepositMultiplier * this.application.keno.kenoDepositAmount;
            }

            return maxL;
        }
    }
};
</script>

<style lang="scss" scoped>
.liabilityForm input[type=number].depositInput {
    width: auto;
    max-width: 90px;
    display: inline-block;
}
</style>
