<template>
    <div class="transDetailsWrapper">
        <ul class="transDetails toggleContent col5" id="transContent00003982080230686720196499077753409" style=""> 
            <li class="transDescrip">{{ data.description }}</li>
            <li class="transDiff">Winning&nbsp;Amount: ${{ data.winAmount }} &nbsp; &nbsp; &nbsp; Actual Payment: ${{ data.payAmount }}</li>
            <li class="transMethod">Payment&nbsp;Method: {{ data.payMethod }}</li>
        </ul>
    </div>
</template>

<script>
    // import $ from 'jquery';
    // import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        props: [
            'transtaction'
        ],
        components:{
        },
        data: function () {
            return {
                data: this.transtaction,
                view: false
            }
        },
        mounted: function () {
            console.log(this.data)
        },
        methods: {
        },
        computed: {
        },
        watch: {
        }
    };
</script>

<style lang="scss" scoped>
    h2 {
        float: left;
    }
    .col5 {
        display: block !important;
    }
    .drawHisViewAll {
        display: inline;
        float: right;
        margin-top: -30px;
        margin-bottom: 10px;
        font-weight: 300;

        @media screen and (min-width: 641px) {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>