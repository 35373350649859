<template>
    <section class="retailer-registration userRegFormWrap">
        <div v-if="!submitted" class=" register-form">
            <!-- <form data-abide novalidate data-validate-on-blur="true" id="registerForm" v-if="formEnable"> -->
                <div data-abide-error class="alert callout" style="display: none;">
                    <p><i class="fi-alert"></i> There are some errors in your form.</p>
                </div>
                <!-- Login Information -->
                <article class=" regLogInFields">
                    <h3>Log In Information</h3>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <label>Email Address <small>Required</small>
                                <input type="text" id="email-address" v-model="userName" placeholder="Email Address" pattern="email" required>
                                <span class="error" v-if="!emailValid">
                                    Please provide a valid email address
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label>Confirm Email Address <small>Required</small>
                                <input type="text" id="email-address-match" placeholder="Confirm Email Address" pattern="email" data-equalto="email-address" required>
                                <span class="error" v-if="!emailMatch">
                                    Emails do not match
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label>Password <small>Required</small>
                                <input type="password" v-model="password"  id="password" placeholder="Password" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"  required  >
                                <password v-model="password" :strength-meter-only="true"/>

                                <span class="error" v-if="!passwordValid">
                                    Please create your password
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label>Re-enter Password
                                <input type="password" id="password-match" placeholder="Confirm Password" aria-describedby="passwordHint" data-equalto="password" required>
                                <span class="error" v-if="!passwordMatch">
                                    Passwords do not match
                                </span>
                            </label>
                        </div>
                        <p class="help-text" id="passwordHint">Your password must be at least eight characters long and include an uppercase letter AND a lowercase letter AND a number.</p>
                    </div>
                </article>

                <!-- Contact Information -->
                <article class=" regContactFields">
                    <h3>Contact Information</h3>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <label>First Name <small>Required</small>
                                <input type="text" id="firstName" v-model="firstName" placeholder="First Name"  required>
                                <span class="error" v-if="!firstNameValid">
                                    Please enter your first name
                                </span>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label>Last Name <small>Required</small>
                                <input type="text" id="lastName" v-model="lastName" placeholder="Last Name"  required>
                                <span class="error" v-if="!lastNameValid">
                                    Please enter your last name
                                </span>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label>Date of birth <small>Required</small> </label>

                            <datepicker 
                                    placeholder="Select Date" 
                                    name="dateOfBirth"
                                    :format="customFormatter"
                                    v-model="date_birth"
                                    @selected="dateSelected()"
                                    :input-class="{'is_valid': birth_error}"
                                    required
                                    >
                            </datepicker>

                            <span class="form-error-birth" v-if="birth_error">
                                You must be over 18
                            </span>

                        </div>

                        
                        <fieldset class="cell large-6 ">
                            <input required id="ageCheckbox" name="ageCheckbox" type="checkbox" class="dobLabel"><label for="ageCheckbox">I am 18 years old or older *</label>
                        </fieldset>
                        <span class="error" v-if="!ageCheckValid">You must verify that you are at least 18 years old.</span>
                        
                    </div>
                    
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <label>Mailing Address <small>Required</small>
                                <input type="text" v-model="mailingAddress" placeholder="Street Address" required >
                                <span class="error" v-if="!addressValid">
                                    Please enter your address
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label>Address (continued)
                                <input type="text" v-model="addressContinued" placeholder="Address (continued)" >
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label>City <small>Required</small>
                                <input type="text" placeholder="CITY" v-model="city" required >
                                <span class="error" v-if="!cityValid">
                                    Please enter your city
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label>State <small>Required</small>
                                <select id="select" v-model="state" required>
                                    <option value="OH">OH</option>
                                    <option value = "AL">AL</option>
                                    <option value = "AK">AK</option>
                                    <option value = "AZ">AZ</option>
                                    <option value = "AR">AR</option>
                                    <option value = "CA">CA</option>
                                    <option value = "CO">CO</option>
                                    <option value = "CT">CT</option>
                                    <option value = "DE">DE</option>
                                    <option value = "FL">FL</option>
                                    <option value = "GA">GA</option>
                                    <option value = "HI">HI</option>
                                    <option value = "ID">ID</option>
                                    <option value = "IL">IL</option>
                                    <option value = "IN">IN</option>
                                    <option value = "IA">IA</option>
                                    <option value = "KS">KS</option>
                                    <option value = "KY">KY</option>
                                    <option value = "LA">LA</option>
                                    <option value = "ME">ME</option>
                                    <option value = "MD">MD</option>
                                    <option value = "MA">MA</option>
                                    <option value = "MI">MI</option>
                                    <option value = "MN">MN</option>
                                    <option value = "MS">MS</option>
                                    <option value = "MO">MO</option>
                                    <option value = "MT">MT</option>
                                    <option value = "NE">NE</option>
                                    <option value = "NV">NV</option>
                                    <option value = "NH">NH</option>
                                    <option value = "NJ">NJ</option>
                                    <option value = "NM">NM</option>
                                    <option value = "NY">NY</option>
                                    <option value = "NC">NC</option>
                                    <option value = "ND">ND</option>
                                    <option value = "OK">OK</option>
                                    <option value = "OR">OR</option>
                                    <option value = "PA">PA</option>
                                    <option value = "RI">RI</option>
                                    <option value = "SC">SC</option>
                                    <option value = "SD">SD</option>
                                    <option value = "TN">TN</option>
                                    <option value = "TX">TX</option>
                                    <option value = "UT">UT</option>
                                    <option value = "VT">VT</option>
                                    <option value = "VA">VA</option>
                                    <option value = "WA">WA</option>
                                    <option value = "DC">DC</option>
                                    <option value = "WV">WV</option>
                                    <option value = "WI">WI</option>
                                    <option value = "WY">WY</option>
                                    <option>- Province -</option>
                                    <option value = "AB">AB</option>
                                    <option value = "BC">BC</option>
                                    <option value = "MB">MB</option>
                                    <option value = "NB">NB</option>
                                    <option value = "NL">NL</option>
                                    <option value = "NS">NS</option>
                                    <option value = "NT">NT</option>
                                    <option value = "NU">NU</option>
                                    <option value = "ON">ON</option>
                                    <option value = "PE">PE</option>
                                    <option value = "QC">QC</option>
                                    <option value = "SK">SK</option>
                                    <option value = "YT">YT</option>
                                    <option>- Other -</option>
                                    <option value="DS">DS</option>
                                </select>
                                <span class="error" v-if="!stateValid">
                                    Please enter your state
                                </span>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label>Zip/Postal Code <small>Required</small>
                                <input type="text" minlength="5" maxlength="5" placeholder="Zip/Postal Code" v-model="zipcode" required pattern="number" >
                                <span class="error" v-if="!zipValid">
                                Please provide a valid Zip/Postal code
                                </span>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label>Country
                                <select id="select" v-model="country" >
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                </select>
                            </label>
                        </div>

                    </div>
                </article>

                <div class="">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <label>Phone Number <small>required</small>
                                <input type="text" id="phoneInput" placeholder="123-456-7890" required pattern="^\d{3}-\d{3}-\d{4}$" >
                                <span class="error" v-if="!phoneValid">
                                Please provide a valid phone number
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <!-- Terms -->

                <div class=" regDisclaimers">
                    <h3>Terms of Use</h3>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell small-12">
                            <h4>{{ termsTitle }}</h4>
                            <div class="terms" v-html="termsContent"></div>
                        </div>

                        <fieldset class="cell small-12">
                            <input required id="agreeTerms" v-model="terms" name="agreeTerms" type="checkbox"><label for="agreeTerms"><b>
                                I have read and agree to the above Ohio Lottery Terms of Use. *
                                </b>
                            </label>
                        </fieldset>
                        <span class="error" v-if="!termsValid">You must accept the Terms of Use</span>
                    </div>
                </div>

                <div class="error" v-if="error">There were errors with your application.</div>

                <div class="">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <fieldset class="cell large-6">
                            <button class="button" @click="formValidate">Submit</button>
                        </fieldset>
                    </div>
                </div>
            <!-- </form> -->

            <div class="callout success" v-if="formSuccess">
                <p>Your user has been created successfully.</p>
            </div>

            <USPS :mailingAddress="this.mailingAddress" :addressContinued="this.addressContinued" :city="this.city" :state="this.state" :country="this.country" :zipcode="this.zipcode" :allowMultiple="true" ref="uspsValidate"></USPS>

            <!-- Confirmation Component if Validated -->
            <!-- Thank You Component after Confirmation -->

            <div class="reveal alert callout" id="errorRegisterModal" data-reveal>
                <p><i class="fi-alert"></i> <span>There was a error with your application.</span></p>
            </div> 
        </div>

        <!-- <RetailConfirm v-if="submitted" :successful="this.successful" :unsuccessful="this.unsuccessful" :deleted="this.deleted" ></RetailConfirm> -->
    </section>
       
</template>

<script>
    import Datepicker from "vue-moment-datepicker";
    import $ from 'jquery';
    import Password from 'vue-password-strength-meter';
    import moment from 'moment';
    import USPS from '../Shared/USPS-AddressCheck.vue';
    // import RetailConfirm from './RetailerRegistrationConfirmation.vue';

    export default {
        props: {
            successurl: String,
            assignrole: Number
        },
        components: {
           Datepicker,
           Password,
           USPS,
        //    RetailConfirm
        },
        data: function () {
            return {
                userName: '',
                password: null,
                firstName: '',
                lastName: '',
                date_birth: '',
                birth_error: false,
                mailingAddress: '',
                addressContinued: '',
                city: '',
                state: 'OH',
                zipcode: '',
                country: 'USA',
                termsTitle: '',
                termsContent: '',
                terms: '',
                registerApiToken: '',
                registerErrorMsg: '',
                formEnable: true,
                formSuccess: false,
                verifiedAddress: false,
                verifiedMailingAddress: '',
                verifiedCity: '',
                verifiedState: '',
                verifiedZipcode: '',
                confirmBodyAddress: {},
                phoneInput: document.getElementById('phoneInput'),
                successful: false,
                unsuccessful: false,
                deleted: false,
                submitted: false,
                userIsAuthed: false,
                RegistrationType: '',
                minimumAppVersion: '',
                emailValid: true,
                emailMatch: true,
                passwordValid: true,
                passwordMatch: true,
                firstNameValid: true,
                lastNameValid: true,
                ageCheckValid: true,
                addressValid: true,
                cityValid: true,
                stateValid: true,
                zipValid: true,
                countryValid: true,
                phoneValid: true,
                termsValid: true,
                error: false
            }
        },
        mounted: function () {
            $(document).foundation();
            this.getToken();
            this.RegistrationType = this.assignrole.type;
            this.phoneNumberFormat(this.phoneInput);
            this.setAppVersion()
        },
        methods: {
            formValidate() {
                let valid = false;

                valid = this.validate();
                
                if (valid === true) {
                    this.error = false;
                    this.$refs.uspsValidate.validate()
                } else {
                    this.error = true;
                }
            },
            validate() {
                this.error = false;

                let emailReg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                    passwordReg = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/),
                    valid = false;

                let email1 = this.userName,
                    email2 = $('#email-address-match').val(),
                    password1 = this.password,
                    password2 = $('#password-match').val(),
                    firstName = this.firstName,
                    lastName = this.lastName,
                    ageCheck = $('#ageCheckbox')[0].checked,
                    address = this.mailingAddress,
                    city = this.city,
                    state = this.state,
                    zip = this.zipcode,
                    country = this.country,
                    phone = $('#phoneInput').val(),
                    terms = this.terms;

                this.emailValid = emailReg.test(email1),
                this.emailMatch = true,
                this.passwordValid = passwordReg.test(password1),
                this.passwordMatch = true,
                this.firstNameValid = true,
                this.lastNameValid = true,
                this.ageCheckValid = true,
                this.addressValid = true,
                this.cityValid = true,
                this.stateValid = true,
                this.zipValid = true,
                this.countryValid = true,
                this.phoneValid = true,
                this.termsValid = true;

                console.log(this.passwordValid)

                if (email1 === email2) {
                    console.log('False')
                } else {
                    this.emailMatch = false;
                }

                // if (password1 === null || password1 === undefined || password1 === "") {
                //     this.passwordValid = false;
                // }

                if (password1 === password2) {
                    this.passwordMatch = true;
                } else {
                    this.passwordMatch = false;
                }

                if (firstName === null || firstName === undefined || firstName === "") {
                    this.firstNameValid = false;
                }

                if (lastName === null || lastName === undefined || lastName === "") {
                    this.lastNameValid = false;
                } else {
                    this.lastNameValid = true;
                }

                this.ageCheckValid = ageCheck;

                if (address === null || address === undefined || address === "") {
                    this.addressValid = false;
                } else {
                    this.addressValid = true;
                }

                if (city === null || city === undefined || city === "") {
                    this.cityValid = false;
                } else {
                    this.cityValid = true;
                }

                if (state === null || state === undefined || state === "") {
                    this.stateValid = false;
                } else {
                    this.stateValid = true;
                }

                if (zip === null || zip === undefined || zip === "") {
                    this.zipValid = false;
                } else {
                    this.zipValid = true;
                }

                if (country === null || country === undefined || country === "") {
                    this.countryValid = false;
                } else {
                    this.countryValid = true;
                }

                this.phoneValid = this.phoneValidation(phone);

                // if (phone === null || phone === undefined || phone === "") {
                //     this.phoneValid = false;
                // } else {
                //     this.phoneValid = true;
                // }

                this.termsValid = terms;

                if (
                    this.emailValid === true &&
                    this.emailMatch === true &&
                    this.passwordValid === true &&
                    this.passwordMatch === true &&
                    this.firstNameValid === true &&
                    this.lastNameValid === true &&
                    this.ageCheckValid === true &&
                    this.addressValid === true &&
                    this.cityValid === true &&
                    this.stateValid === true &&
                    this.zipValid === true &&
                    this.countryValid === true &&
                    this.phoneValid === true &&
                    this.termsValid === true
                ) {
                    valid = true;
                } else {
                    valid = false;
                    this.error = true;
                }

                return valid;
            },
            uspsVerified(msg) {
                console.log("UPSP VALIDATED")
                console.log(msg);
                this.confirmBodyAddress = msg;
                // this.submitted = true;
                // this.successful = true;
                this.confirmAddress();
                this.uspsClose();
            },
            uspsOpen() {
                $('#USPSmodal').foundation('open');
            },
            uspsClose() {
                $('#USPSmodal').foundation('close');
            },
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                this.registerApiToken = apiToken;
                var urlProtocol = this.getProtocol();
                
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=terms-conditions", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var terms = json.data;
                    
                    this.termsTitle = terms[0]['title'];
                    this.termsContent = terms[0]['centralContent'];
                    
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            dateSelected () {
                this.$nextTick(function(){
                    function getAge(birth, target) {
                        let months = target.diff(birth, 'months', true)
                        let birthSpan = {year: Math.floor(months/12), month: Math.floor(months)%12, day: Math.round((months%1)*target.daysInMonth(),0)}
                        if (birthSpan.year < 1 && birthSpan.month < 1) {
                            return 10
                        } else if (birthSpan.year < 1) {
                            return 10
                        } else if (birthSpan.year < 2) {
                            return 10
                        } else {
                            return birthSpan.year
                        }
                    
                    }
                    let birth = moment(this.date_birth)
                    let ageOfPerson = getAge(birth, moment());
                    if (ageOfPerson < 18 ){
                        this.birth_error = true
                    }else{
                        this.birth_error = false;
                    }

                });
                      
            },
            submit: function() {
                // let _this = this;

                let bodyRegister = {
                    "userId": 0,
                    "email": this.userName,
                    "firstName": this.firstName,
                    "lastName": this.lastName,
                    "address": this.mailingAddress,
                    "validateAddress": true,
                    "phoneNumber": this.phoneNumber,
                    "birthdate": this.date_birth,
                    "appVersion": this.minimumAppVersion
                };
                /// for this, just always submit the communication opt-in as false
                console.log(bodyRegister);

                // send data to: this.endpoint

                // on success, redirect to: this.successurl




                // const requestRegisterOptions = {
                //     method: 'POST',
                //     headers: { 
                //     'Content-Type': 'application/json',
                //     'Authorization': 'Bearer '+apiToken
                //     },
                //     body: JSON.stringify(bodyRegister)
                // };
                            
                // $(document).off('formvalid.zf.abide');
                //  // script to trigger other events 
                // $(document).on("formvalid.zf.abide", function(ev) {
                //     // var apiToken = _this.registerApiToken;
                //     ev.preventDefault();
                //     this.$refs.uspsValidate.validate();
                // })
                // // to prevent form from submitting upon successful validation
                // .on("submit", function(ev) {
                //     ev.preventDefault();
                // });
                
            },
            editAddress: function(){
                $('#errorAddressModal').foundation('close');
            },
            confirmAddress: function(){
                console.log(this.successurl)
                console.log( this.confirmBodyAddress  )
                var apiToken = this.registerApiToken;
                console.log("Registration Type")
                console.log(this.RegistrationType)

                let confirmBodyRegister = {
                    "userName": this.userName,
                    "email": this.userName,
                    "password": this.password,
                    "firstName": this.firstName,
                    "lastName": this.lastName,
                    "address": {
                        City: this.confirmBodyAddress.city,
                        State: this.confirmBodyAddress.state,
                        Address: this.confirmBodyAddress.mailingAddress,
                        ZipCode: this.confirmBodyAddress.zip
                    },
                    // "npiPlayerID": "string"
                    "phoneNumber": this.phoneNumber,
                    "birthdate": this.date_birth,
                    "termsAcceptance": this.terms,
                    "registrationType": 2,
                    "appVersion": this.minimumAppVersion
                }

                const confirmRegisterOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiToken
                    },
                    body: JSON.stringify(confirmBodyRegister)
                };

                

                fetch("https:"+process.env.VUE_APP_APIURL+"/1.0/PAM/RegisterUser", confirmRegisterOptions)
                .then(response => response.json())
                .then(dataRegister => {
                    console.log(dataRegister)
                    // $('#errorAddressModal').foundation('close');
                    if( dataRegister.data == null ){
                        this.formEnable = true;
                        this.formSuccess = false;
                        this.submitted = false;
                        this.registerErrorMsg = dataRegister.error.message;
                        $('#errorRegisterModal').foundation('open');
                    }else{
                        this.formEnable = false;
                        this.formSuccess = true;
                        this.submitted = true;
                        window.location.href = this.successurl;
                    }
                });
            },
            setAppVersion () {
                let url = window.location.origin + '/registration/GetMinimumAppVersion';

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json'
                    }
                }).then((response) => response.json())
                .then((json) => {
                    this.minimumAppVersion = json.minAppVersionNumber;
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            }
        },
        computed: {
           
        },
    };
</script>

<style lang="scss" scoped>
input, select{
    height: auto;
    line-height: inherit;
}
.form-error-birth{
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: #cc4b37;
    display: block;
}

.terms{
    overflow: auto;
    height: 200px;
    width: 100%;
    padding: 10px;
    margin: 0 0 10px;
    background: #fff;
    border: 1px solid #B9BDC3;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-clip: padding-box;
}
</style>