<template>
    <section class="tabbed_content fluid_tabs cf" id="TransactionLogPage">
        <UserLoginVue v-if="!isUserLogged"></UserLoginVue>
        <div v-if="isUserLogged">
            <div class="tabbed_content mlrCouponTabs fluid_tabs cf">
                <ul class="tab_nav cf">
                    <li :class="{'selected':product}">
                        <a href="#1" id="tab_1" @click="switchView">Physical Products</a>
                    </li>
                    <li :class="{'selected':gift}">
                        <a href="#2" id="tab_2" @click="switchView">Physical Gift Cards</a>
                    </li>
                </ul>
                <div class="tab_content_wrap first_active cf" id="tab_content_block">
                    <div class="tab_content current_content" id="tab_content_1">
                        <ul class="myPhysicalOrdersList">
                            <li class="digitalOrderListItem" v-if="product && regularOrders.length">
                                <div class="digitalOrderInfo" v-for="order in regularOrders" :key="order.orderID">
                                    <a :href="'/MyLotto-Rewards/My-Account/My-Physical-Purchases/Physical-Purchase-Detail?id=' + order.orderID" class="giftCardTitle">
                                        {{ order.orderItems[0].name }}
                                    </a>
                                    <span class="giftCardPurchDate">
                                        Purchase Date:
                                        <br>
                                        {{ order.orderDate }}
                                    </span>
                                    <span class="giftCardCode">
                                        Order Status:
                                        <br>
                                        {{ order.statusName }}
                                    </span>
                                </div>
                            </li>
                            <div class="noActiveOrders" v-if="product && regularOrders.length < 1">
                                <p>You don't have any active Physical Purchases.</p>
                                <a class="button btn_purple" href="/MyLotto-Rewards/Redemption-Central/Catalog">SHOP THE CATALOG</a>
                            </div>
                            <li class="digitalOrderListItem" v-if="!product && giftOrders.length">
                                <div class="digitalOrderInfo" v-for="order in giftOrders" :key="order.orderID">
                                    <a :href="'/MyLotto-Rewards/My-Account/My-Physical-Purchases/Physical-Purchase-Detail?id=' + order.orderID" class="giftCardTitle">
                                        {{ order.orderItems[0].name }}
                                    </a>
                                    <span class="giftCardPurchDate">
                                        Purchase Date:
                                        <br>
                                        {{ order.orderDate }}
                                    </span>
                                    <span class="giftCardCode">
                                        Order Status:
                                        <br>
                                        {{ order.statusName }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <div class="noActiveOrders" v-if="!product && giftOrders.length < 1">
                            <p>You don't have any Physical Gift Card purchases.</p>
                            <a class="button btn_purple" href="/mylotto-rewards/redemption-central/catalog">SHOP THE PHYSICAL CATALOG</a>
                        </div>
                    </div>
                    <div style="display:none;" class="tab_content current_content" id="tab_content_2">
                        <div class="redeemedHeader snippOrders">
                            <span class="headerPname">Product Name</span>
                            <span class="headerOrdCode">Redemption Code</span>
                            <span class="headerPdate">Purchase Date</span>
                            <span class="headerMasR">Mark as Not Redeemed</span>
                        </div>
                        <ul class="myphysicalGiftcardOrdersList"></ul>
                    </div>
                </div>
            </div>
            
            <!-- <div id="redeemOrderModal" class="iziModal data-modalOptions">
                <h3>Did you redeem this Digital Purchase?</h3>
                <p>If you have redeemed it, tap YES to remove it from your Active list.</p>
                <div class="orderModalOptions">
                    <a class="btn_purple btn_gray_secondary" data-izimodal-close>NO, KEEP ACTIVE</a>
                    <button type="button" class="btn_primary redeemOrder" data-izimodal-close>YES, I REDEEMED IT</button>
                </div>
            </div>
            <div id="notRedeemedModal" class="iziModal data-modalOptions">
                <h3>Did you redeem this Digital Purchase?</h3>
                <p>If you have not redeemed it, tap YES to remove it from your Redeemed list.</p>
                <div class="orderModalOptions">
                    <a class="btn_purple btn_gray_secondary" data-izimodal-close>NO, I REDEEMED IT</a>
                    <button type="button" class="btn_primary unredeemOrder" data-izimodal-close>YES, IT'S NOT REDEEMED</button>
                </div>
            </div> -->
        </div>
    </section>
</template>

<script>
    // import $ from 'jquery';
    import moment from "moment";
    import Pagination from '../Pagination/pagination.vue';
    import UserLoginVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    // const modal = {
    //     mounted() {console.log('wee')}
    // }

    export default {
        // directives: {
        //     modal
        // },
        components: {
           Pagination,
           UserLoginVue
        },
        data: function () {
            return {
                isUserLogged: this.isUserAuthed(),
                mobileCashing: false,
                token: '',
                user: '',
                regularOrders: '',
                physicalGiftcardOrders: '',
                points: 0,
                disable: true,
                totalPages: 10,
                itemsPerPage: 10,
                currentPage: 1,
                currentItems: '',
                startDate: '',
                endDate: '',
                physicalGiftcard: false,
                activeOrders: false,
                product: true,
                gift: false
            }
        },
        mounted: function () {
            this.getToken();

            this.startDate = moment().startOf('month').format('yyyy-MM-DD');
            this.endDate = moment().format('yyyy-MM-DD');
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.getOrders();
                });
            },
            getOrders() {
                // Check if orders already exist
                // let refresh = this.$cookies.get('physicalOrdersTimestamp'),
                //     refreshPhysicalGiftcard = this.$cookies.get('physicalGiftcardOrdersTimeStamp');

                // let currentDate = moment(new Date()),
                //     refreshDate = moment(refresh),
                //     difference = currentDate.diff(refreshDate, 'minutes');

                // let physicalGiftcardRefreshDate = moment(refreshPhysicalGiftcard),
                //     physicalGiftcardDifference = currentDate.diff(physicalGiftcardRefreshDate, 'minutes');

                // if (difference < 2) {
                //     let orders = this.$cookies.get('physicalOrders');

                //     orders.length ? this.regularOrders = orders : this.apiOrders();
                // } else {
                    this.apiOrders();
                // }

                // if (physicalGiftcardDifference < 2) {
                //     let orders = this.$cookies.get('physicalGiftcardOrders');

                //     orders.length ? this.physicalGiftcardOrders = orders : this.apiphysicalGiftcard();
                // } else {
                    this.apiGiftcard();
                // }
            },
            apiOrders() {
                let apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserOrderList?type=1&userId=' + this.user.user.userId + '&offset=0&top=' + 1000;

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let d = json.data;

                    let items = [];

                    for (let i = 0; i < d.length; i++) {
                        let item = d[i],
                            date = new Date(item.orderDate);
                        // ------------------------------------------ //
                        date = moment(date).format("MM/DD/YYYY");
                        item.orderDate = date;

                        items.push(item);
                    }

                    
                    console.log(items)

                    this.regularOrders = items;
                    this.$cookies.set('physicalOrdersTimestamp', new Date());
                    this.$cookies.set('physicalOrders', JSON.stringify(items));
                    // this.setPages();
                    // this.setItems();
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            apiGiftcard() {
                let apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserOrderList?type=2&userId=' + this.user.user.userId + '&offset=0&top=' + 1000;

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let d = json.data;
                    console.log(d)
                    let items = [];

                    for (let i = 0; i < d.length; i++) {
                        let item = d[i],
                            date = new Date(item.orderDate);
                        // ------------------------------------------ //
                        date = moment(date).format("MM/DD/YYYY");
                        item.orderDate = date;

                        items.push(item);
                    }

                    this.giftOrders = items;
                    this.$cookies.set('physicalGiftcardOrdersTimestamp', new Date());
                    this.$cookies.set('physicalGiftcardOrders', JSON.stringify(items));
                    // this.setPages();
                    // this.setItems();
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            switchView() {
                this.product = !this.product;
                this.gift = !this.gift;
            },
            setPages() {
                this.itemsPerPage = 5;
                this.totalPages = Math.ceil(this.tLog.length / this.itemsPerPage);
            },
            changePage(p) {
                this.currentPage = p;
                this.setItems();
            },
            setItems() {
                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.tLog.slice(start, end);

                this.currentItems = currentItems;
            },
            setStartDate(t) {
                let target = t;

                this.startDate = moment(target.target.value).format('yyyy-MM-DD');
            },
            setEndDate(t) {
                let target = t;

                this.endDate = moment(target.target.value).format('yyyy-MM-DD');
            }
		}
    };
</script>