<template>
    <div class="eAppModule">
        <div class="moduleContent cf saveForm reviewBackground appReview">
            <h3>Background check</h3>

            <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />
            <div v-if="userRole == 'eApp-Security' || userRole == 'eApp-RegionalSecurity'" class="bgCheckSecurity">
                <table class="eAppListTbl eAppBGcheckTble">
                    <tbody v-for="(principal, index) in principals" :key="index">
                        <tr class="firstRow">
                            <td>
                                <label>Principal:</label>
                            </td>
                            <td>
                                <strong>{{ principal.principalFirstName }} {{ principal.principalLastName }}</strong>
                            </td>
                            <td>
                                <label>Application Date:</label>
                            </td>
                            <td>
                                {{ customFormatter(principal.principalInformationLastModified) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="tdBGcheckInfo" colspan="2">
                                <a @click="showDetails(principal.principalInformationID)"
                                    class="bgCheckShow toggleContentBtn">Show Details</a>
                            </td>
                            <td>
                                <label>Percent Ownership:</label>
                            </td>
                            <td>
                                {{ principal.principalPercentOwnership }} %
                            </td>
                        </tr>
                        <tr :id="'principal' + principal.principalInformationID" style="display:none;"
                            class="BGcheckMoreInfo toggleContent">
                            <td colspan="4" class="eAppBGcheckTbleDetails">
                                <table class="">
                                    <tr>
                                        <td>
                                            <label>Mailing Address:</label>
                                        </td>
                                        <td>
                                            {{ principal.principalStreetAddress }}<br />
                                            {{ principal.principalCity }},
                                            {{ principal.principalState }}
                                            {{ principal.principalZip }}
                                            {{ principal.principalZipPlus4 }}
                                        </td>
                                        <td>
                                            <label>E-mail Address:</label>
                                        </td>
                                        <td>
                                            {{ principal.principalEmail }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Home Phone:</label>
                                        </td>
                                        <td>
                                            {{ principal.principalHomePhoneNumber }}
                                        </td>
                                        <td>
                                            <label>Mobile Phone:</label>
                                        </td>
                                        <td>
                                            {{ principal.principalCellPhoneNumber }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Date of Birth:</label>
                                        </td>
                                        <td>
                                            {{ customFormatter(principal.principalDateOfBirth) }}
                                        </td>
                                        <td>
                                            <label>Social Security Number:</label>
                                        </td>
                                        <td>
                                            {{ principal.principalSSN }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalAnyOffense) }}
                                            </div>
                                            <label>Has the applicant been convicted of any offense in any
                                                jurisdiction?</label>
                                            {{ principal.principalAnyOffenseExplanation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalGamblingOffense) }}
                                            </div>
                                            <label>Has the applicant been convicted of any offense involving
                                                gambling?</label>
                                            {{ principal.principalGamblingOffenseExplanation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalLotteryRuleViolation) }}
                                            </div>
                                            <label>Has the applicant been found to violate any rule, regulation or order of
                                                the Ohio Lottery Commission?</label>
                                            {{ principal.principalLotteryRuleViolationExplanation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalFraudOffense) }}
                                            </div>
                                            <label>Has the applicant been found guilty or subjected to disciplinary action,
                                                by any agency, court or authority of any kind, for an offense involving
                                                fraud or misrepresentation?</label>
                                            {{ principal.principalFraudOffenseExplanation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalLicensePermit) }}
                                            </div>
                                            <label>Has the applicant had any state license or permit revoked or
                                                suspended?</label>
                                            {{ principal.principalLicensePermitExplanation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalPastFinancialInterest) }}
                                            </div>
                                            <label>Has the applicant ever had any financial interest in a location that had
                                                an Ohio Lottery license?</label>
                                            {{ principal.principalPastFinancialInterestExplanation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalCurrentFinancialInterest) }}
                                            </div>
                                            <label>Does the applicant currently have any financial interest in a location
                                                that has an Ohio Lottery license?</label>
                                            {{ principal.principalCurrentFinancialInterestExplanation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalAffairs) }}
                                            </div>
                                            <label>Does any other person or entity other than the applicant, its employees,
                                                or family participate in the management of applicant's affairs? </label>
                                            {{ principal.principalAffairsExplanation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="bgCheckAnswerCol">
                                            <div class="bgCheckAnswer">
                                                {{ booleanFormatter(principal.principalIsVendor) }}
                                            </div>
                                            <label>Are you a vendor, employee or agent of any vendor of the Ohio Lottery
                                                Commission? </label>
                                            {{ principal.principalIsVendorExplanation }}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Federal Status:</label>
                            </td>
                            <td>
                                <select v-model="principal.principalFBIBackgroundCheckStatus" id="FBIStatus"
                                    class="bgStatusSelect">
                                    <option value="null">Select...</option>
                                    <option value="0">Processing</option>
                                    <option value="1">Outstanding</option>
                                    <option value="2">Approved</option>
                                    <option value="-1">Rejected</option>
                                </select>
                            </td>
                            <td>
                                <label>Federal Received Date:</label>
                            </td>
                            <td>
                                <datepicker class="check-datepicker" :inline="true" name="FBIReceivedDate"
                                    :format="customFormatter" v-model="principal.principalFBIReceivedDate"
                                    :disabledDates="disabledDates">
                                </datepicker>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>BCI Status:</label>
                            </td>
                            <td>
                                <select v-model="principal.principalBCIBackgroundCheckStatus" id="BCIStatus"
                                    class="bgStatusSelect">
                                    <option value="null">Select...</option>
                                    <option value="0">Processing</option>
                                    <option value="1">Outstanding</option>
                                    <option value="2">Approved</option>
                                    <option value="-1">Rejected</option>
                                </select>
                            </td>
                            <td>
                                <label>BCI Received Date:</label>
                            </td>
                            <td>
                                <datepicker class="check-datepicker" :inline="true" name="BCIReceivedDate"
                                    :format="customFormatter" v-model="principal.principalBCIReceivedDate"
                                    :disabledDates="disabledDates">
                                </datepicker>
                            </td>
                        </tr>
                        <tr class="dividerRow">
                            <td colspan="2">
                                <label>Documentation:
                                    <span v-if="principals[index].principalSecurityAssociatedFile === '' || principals[index].principalSecurityAssociatedFile === null || principals[index].principalSecurityAssociatedFile === undefined">&nbsp;</span>
                                    <a v-else
                                        @click="downloadFile(index)">Click Here to View
                                        {{ principals[index].principalSecurityAssociatedFileName }}</a>
                                </label>
                                <!-- <input type="file" id=""
                                       @input="event => handleFile(event, index)" /> -->
                                <VueFileAgent :ref="`vueFileAgent${index}`"
                                    :multiple="false"
                                    :deletable="false"
                                    :meta="true" :linkable="true"
                                    :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.zip'" :theme="'list'"
                                    :maxSize="'5MB'" :maxFiles="1" :helpText="'Choose file'" :errorText="{
                                        type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png, zip.',
                                        size: 'Files should not exceed 5mb in size'
                                    }" @select="handleFile($event, index)">
                                </VueFileAgent>
                            </td>
                            <td colspan="2">
                                <label>Approval/Denial Notes:</label>
                                <textarea v-model="principal.principalSecurityNotes" type="text" id="NotesPerPrincipal"
                                    class="bgCheckNotes"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- general principal status for non-Security roles -->
            <div v-else>
                <ul class="principalList">
                    <li v-for="(principal, index) in application.principals" v-bind:key="index" class="principalItem cf">
                        <div class="principalInfo">
                            <span class="icon-lock"></span>
                            <span class="principalName">{{ principal.principalFirstName }} {{ principal.principalLastName }}</span>
                        </div>
                        <div class="principalBtns">
                            <div>
                                Status: <strong>
                                    <span v-if="principal.principalBackgroundCheckProcessed">Reviewed</span>
                                    <span v-else="principal.principalAuthorizedBackgroundCheck">Recieved</span>
                                </strong>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- Areas for Notes by specific role -->
            <div v-if="userRole == 'eApp-RegionalManager' || userRole == 'eAppDistrictRep' || userRole == 'eApp-CentralSalesManager'"
                class="moduleContent cf reviewNotes appReview">
                <h3>Notes</h3>
                <textarea v-model="application.backgroundCheckNotes" class="reviewNotes "></textarea>

            </div>

            <div v-if="userRole != 'eApp-CentralLicensingManager'" class="btn-actions">
                <button @click="onNextAdminClick()" type="button" name="Save" id="bgCheckSave"
                    class="btn btn-primary btnSave" title="Save the principal updates">Save</button>
            </div>

        </div>
    </div>
</template>


<script>
import Datepicker from "vue-moment-datepicker";
import { HollowDotsSpinner } from 'epic-spinners';
import moment from 'moment';

export default {
    components: {
        Datepicker,
        HollowDotsSpinner
    },
    props: {
        applicationJson: String,
        userId: Number,
        userRole: String,
        bgToken: String,
        // showDetails: false,
    },
    data: function () {
        return {
            application: {},
            submitting: false,
            eappId: '',
            principals: [],
            principalJson: '',
            applicationDate: '',
            isLoading: true,
            disabledDates: {
                from: new Date()
            },
            bgCheckNotesDistrictRep: '',
            bgCheckNotesRegionalManager: '',
            bgCheckNotesCentralSalesManager: '',
        }
    },
    mounted: function () {
        this.init();
    },
    methods: {
        init() {
            this.application = JSON.parse(this.applicationJson);
            this.principals = this.application.principals;
            this.applicationDate = this.application.SubmissionDate;
            this.isLoading = false;
            console.log(this.principals)
        },
        downloadFile(index) {
            var binaryString = window.atob(this.principals[index].principalSecurityAssociatedFile);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            var blob = new Blob([bytes], { type: this.principals[index].principalSecurityAssociatedFileExtension });
            var objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        },
        customFormatter(d) {
            return moment(d).format('M/D/YYYY');
        },
        booleanFormatter(boolean) {
            if (boolean == '0') {
                return 'No';
            }
            else if (boolean == '1') {
                return 'Yes';
            }
            else {
                return '';
            }
        },
        showDetails(principalId) {
            let divId = 'principal' + principalId;
            let div = document.getElementById(divId);
            var style = window.getComputedStyle(div);
            let hidden = style.display === 'none';
            if (hidden) {
                div.style.display = 'contents';
            }
            else {
                div.style.display = 'none';
            }
        },
        submitBgCheck() {
            //validate & submit
        },
        onNextAdminClick() {
            this.application.principals = this.principals;
            console.log(this.application);
            this.$emit('onNextAdminClick', { application: this.application, step: 'background', updates: true });
        },
        // handleFile(event, index) {
        //     // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
        //     var file = event.target.files[0];

        //     var reader = new FileReader();
        //     var fileData;
        //     var fileType = '';
        //     var fileName = '';
        //     reader.readAsDataURL(file, 'base64');
        //     reader.onload = function (e) {
        //         var fileDataArray = reader.result.split(',')[1];
        //         fileData = fileDataArray
        //         fileType = file.type;
        //         fileName = file.name;
        //         console.log(fileDataArray);
        //     }

        //     reader.onloadend = () => {
        //         this.principals[index].principalSecurityAssociatedFile = fileData;
        //         this.principals[index].principalSecurityAssociatedFileExtension = fileType;
        //         this.principals[index].principalSecurityAssociatedFileName = fileName;
        //     }

        // },
        handleFile(event, index) {
            // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
            var _this = this;
            var file = event[0].file;
            var fileName = event[0].file.name;
            var fileType = event[0].file.type

            const reader = new FileReader();
            reader.onload = function (e) {
                _this.principals[index].principalSecurityAssociatedFile = e.target.result.split(',')[1];
                _this.principals[index].principalSecurityAssociatedFileExtension = fileType;
                _this.principals[index].principalSecurityAssociatedFileName = fileName;
            };

            reader.readAsDataURL(file);
        }
    },
    computed: {
    }
};
</script>

<style lang="scss" scoped></style>