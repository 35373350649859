<template>
    <div class="eAppModule">
        <div class="moduleContent cf eftReview saveForm cf">
            <h3>Electronic Funds Transfer</h3>
            <div class="formRow cf">
                <label for="BankName_txtText">Bank Name:</label>
                <input type="text" maxlength="200" :value="application.eft.dfiName" id="BankName_txtText" disabled="disabled" />
            </div>

            <div class="formRow cf">
                <label for="AccountName_txtText">Account Name:</label>
                <input :disabled="userRole == 'eApp-CentralLicensingManager'" name="AccountName_txtText" v-model="application.eft.eftAccountName" type="text" maxlength="100" id="AccountName_txtText" autocomplete="off" aria-describedby="AccountName_error" class="eAppLimitChars" />
                <div class="error" role="alert" v-if="!accountNameValid" id="AccountName_error">Please enter an account Name.</div>
            </div>

            <div class="formRow cf">
                <label for="AccountNumber_txtText">Checking Account Number:</label>
                <input name="AccountNumber_txtText" :value="application.eft.checkingAccountNumber" type="text" maxlength="30" id="AccountNumber_txtText" disabled="disabled" />
            </div>

            <div class="formRow dividerRow cf">
                <label for="RoutingNumber_txtText">Bank Routing Number:</label>
                <input name="RoutingNumber_txtText" :value="application.eft.checkingAccountRoutingNumber" type="text" maxlength="9" id="RoutingNumber_txtText" disabled="disabled" />
            </div>

            <div class="formRow checkboxRow cf" v-if="userRole != 'eApp-RegionalManager'">
                <input :disabled="userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-CentralLicensingManager'" type="checkbox" v-model="application.eft.bankInformationProcessed" id="BankInformationProcessed_check" />
                <label for="BankInformationProcessed_check">Bank Information Processed</label>
            </div>
            <div class="reviewNotes appReview cf" v-if="userRole != 'eApp-RegionalManager'">
                
                <div v-if="!hideNotes">
                    <h3>Notes</h3>
                    <!--eftReviewMessage-->
                    <textarea v-model="application.eftReviewMessage" class="reviewNotes "></textarea>
                </div>

                <div class="formRow radio radio-list-horizontal cf">
                    <label>EFT Information Approved</label>
                    <input :disabled="userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-CentralLicensingManager'" v-model="application.eft.eftInformationApproved" id="EFTInformationApproved_list_0" type="radio" name="EFTInformationApproved_list" value="true">
                    <label for="EFTInformationApproved_list_0">Approved</label>
                    <input :disabled="userRole == 'eApp-CentralSalesManager' || userRole == 'eApp-CentralLicensingManager'" v-model="application.eft.eftInformationApproved" id="EFTInformationApproved_list_1" type="radio" name="EFTInformationApproved_list" value="false">
                    <label for="EFTInformationApproved_list_1">Rejected</label>
                </div>

                <div class="btn-actions">
                    <button type="button" @click="onNextAdminClick" name="Save" id="eftReviewSave" class="btn btn-primary btnNext" title="Save the document">Save</button>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
    export default {
        components: {},
        props: {
            applicationJson: String,
            userId: Number,
            eftToken: String,
            userRole: String
        },
        data: function () {
            return {
                application: {},
                submitting: false,
                eappId: '',
                eft: {},
                hideNotes: false,
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init(){
                this.deserializeJson();
            },
            deserializeJson() {
                this.application = JSON.parse(this.applicationJson);
                this.hideNotes = this.userRole != 'eApp-EFTReviewer' || this.userRole != 'eApp-CentralSalesManager';
            },
            onNextAdminClick() {
                this.$emit('onNextAdminClick', { application: this.application, step: 'eft', updates: true });
            },
            accountNameValid() {
                return this.application.eft.eftAccountName != '';
            }
            
        },
        computed: { }
    };
</script>

<style lang="scss" scoped>
</style>
