<!-- ~/Views/Home/Home.vue -->

<template>
    <div class=" winningNumbersWrap no-gutters">
        <template>
            <h3 class="current_numbers_heading">Current Winning Numbers</h3>
            <tabs class="current_numbers_tabs" v-if="isLoaded">
                <tab title="Draw Games" class="is-active">
                    <div class="grid-x grid-margin-x small-up-2 medium-up-2 large-up-4 winning-numbers-home" id="current_winning_numbers">
                        <div class="winningNumbersItem"><Pick3 :gamesData="games"></Pick3></div> 
                        <div class="winningNumbersItem"><Pick4 :gamesData="games"></Pick4></div>
                        <div class="winningNumbersItem"><Pick5 :gamesData="games"></Pick5></div>
                        <!-- Pick 5, Rolling 5, Powerball -->
                        <div class="winningNumbersItem"><Rolling5 :gamesData="games"></Rolling5></div>
                        <div class="winningNumbersItem"><PowerBall :gamesData="games"></PowerBall></div>
                        <div class="winningNumbersItem"><MegaMillions :gamesData="games"></MegaMillions></div>
                        <div class="winningNumbersItem"><Classic :gamesData="games"></Classic></div>
                        <div class="winningNumbersItem"><Lucky :gamesData="games"></Lucky></div>
                        <!-- Classic Lotto, Lucky for Life -->
                    </div>
                </tab>
                <tab title="Progressive Jackpot Games">
                    <ProgressiveJackpot v-if="hideContentTab" title="Progressive Jackpot Games"></ProgressiveJackpot>
                </tab>
                <tab title="KENO">
                    <KenoWinningNumbers></KenoWinningNumbers>

                </tab>
                <tab title="The Lucky One">
                    <LuckyOneWinningNumbers></LuckyOneWinningNumbers>
                </tab>
            </tabs>
        </template>
    </div>
    
</template>

<script>
import $ from 'jquery';
import {mapActions, mapGetters} from 'vuex';

    export default {
        components: {
            'Tab': () => import('../../ViewComponents/Tabs/Tab.vue'),
            'Tabs': () => import('../../ViewComponents/Tabs/Tabs.vue'),
            'Pick3': () => import('../Games/Pick3/Pick3-WinningNumbers.vue'),
            'Pick4': () => import('../Games/Pick4/Pick4-WinningNumbers.vue'),
            'Pick5': () => import('../Games/Pick5/Pick5-WinningNumbers.vue'),
            'Rolling5': () => import('../Games/Rolling5/Rolling5-WinningNumbers.vue'),
            'PowerBall': () => import('../Games/PowerBall/PowerBall-WinningNumbers.vue'),
            'MegaMillions': () => import('../Games/MegaMillions/MegaMillions-WinningNumbers.vue'),
            'Classic': () => import('../Games/ClassicLotto/Classic-WinningNumbers.vue'),
            'Lucky': () => import('../Games/LuckForLife/Lucky-WinningNumbers.vue'),
            'ProgressiveJackpot': () => import('../Games/ProgressiveJackpot/ProgressiveJackpotHome.vue'),
            'KenoWinningNumbers': () => import('../Games/KENO/Keno-WinningNumbers.vue'),
            'LuckyOneWinningNumbers': () => import('../Games/TheLuckyOne/LuckyOne-WinningNumbers.vue')
        },
        data: () => ({
            user: {},
            games: [],
            hideContentTab: true,
            token: '',
            isLoaded: false
        }),
        mounted: function() {
            this.getToken();
            this.user = this.$store.getters.getUser;
            if (this.$cookies.get('deleted')) {
                this.$cookies.remove('deleted');
                window.location.href = '/mylotto-rewards/my-account/my-profile/delete-your-account/account-deleted';
            }
        },
        methods: {
            getLocalGames() {
                // let games = JSON.parse(window.localStorage.getItem('olcDrawNumbers'));
                let games = document.getElementById('cmsNumbers').value;
                games = JSON.parse(games);

                this.games = games;
                this.isLoaded = true;
                
                setTimeout(() => {
                    $('.current_numbers_tabs #tab0').click();
                }, 1000);
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getLocalGames();
                    // console.log(this.games)
                });
            },
            returnGames: () => {
                let gameLength = this.getGames();
                        
                this.games = this.getStorageNumbers();

                while (this.games.length < gameLength.length) {
                    setTimeout(() => {
                        this.games = this.getStorageNumbers();
                    }, 5000);
                }
            },
            ...mapActions ({
                checkLocalNumbers: 'getNumbers/getOLCDrawGames'
            }),
            ...mapGetters ({
                getGames: 'getNumbers/getGames',
                getStorageNumbers: 'getNumbers/getStorageNumbers'
            })
        }
    };
</script>


<style lang="scss" scoped>
    
</style>