<template>
    <div class="survey-wrapper">

        <SurveyList @getIdSurvey="startSurvey" v-if="showStart"></SurveyList>

        
       
        <form-wizard @on-complete="onComplete" title="" subtitle="" v-if="showSurvey">
                <span class="error-message" v-if="displayErrMsg">You must answer all the questions before proceeding to the next step</span>
                <tab-content v-for="index in NumberOfTabs" :key="index" :before-change="()=>validateStep('step-'+index)" >
                     <div v-for="(survey, i) in getContentSurvey(index)" :key="i" :id="survey.displayOrder" :question-id="survey.questionID" :question-type="survey.questionType"  class="question-wrapper">
                        
                         <div class="question-text">{{ survey.text }}</div>

                         <div class="question-image" v-if="survey.image">
                             <img :src="survey.image" />
                         </div>

                         <div class="answer-container">
                           <div class="option-radiobox" v-if="survey.questionType == 0">
                             <div v-for="(item,index) in survey.answers" :key="index" class="radio-answer">
                               <input type="radio" :id="item.answerID"  :name="'radio-answer-' + survey.questionID" :value="item.answerText" @change="selectedRadioAnswer($event)">
                               <label :for="item.answerID">{{item.answerText}}</label>
                             </div>
                           </div>

                           <div class="option-dropdown" v-if="survey.questionType == 1">
                               <div>
                                    <select @change="selectedDropdownAnswer($event)" id="dropdown-list">
                                        <option value=""></option>
                                        <option v-for="(item, index) in survey.answers" :key="index" v-bind:value="item.answerID">
                                            {{item.answerText}}
                                        </option>
                                    </select>
                                </div>
                           </div>

                           <div class="option-checkbox" v-if="survey.questionType == 2">
                                <div v-for="(item,index) in survey.answers" :key="index" class="checkbox-answer">
                                    <input type="checkbox" :id="item.answerID" :value="item.answerText"  name="checkbox-answer" @change="selectedCheckboxAnswer($event)">
                                    <label :for="item.answerID">{{item.answerText}}</label>
                                </div>
                           </div>

                           <div class="option-txtInput" v-if="survey.questionType == 3">
                                <div v-for="(item,index) in survey.answers" :key="index">
                                    <label>{{ item.answerText }}</label>
                                    <input type="text" :id="item.answerID" name="text-answer" @change="fillTextInput($event)">
                                </div>
                           </div>

                           <div class="option-scale" v-if="survey.questionType == 4">
                                <div v-for="(item,index) in survey.answers" :key="index">
                                    <label class="label-range" :for="item.answerID">
                                        <span v-if="item.answerText != ''" class="label-range-text">{{ item.answerText }} </span>
                                        <!-- <span class="label-range-scale"> (between {{item.scaleMinValue}} and {{item.scaleMaxValue}})</span> -->
                                    </label>
                                    <div class="range-key">
                                        <span class="scale-marker" v-for="n in markerQuant(item.scaleMinValue, item.scaleMaxValue, item.scaleIntervalValue)" :key="n">
                                            <span class="scale-marker-text">{{ 
                                                markerValue(item.scaleMinValue, item.scaleMaxValue, item.scaleIntervalValue, n)
                                            }}</span>
                                        </span>
                                    </div>
                                    <output class="output-range"></output>
                                    <input type="range" :id="item.answerID" :min="item.scaleMinValue" :max="item.scaleMaxValue" oninput="this.previousElementSibling.value = this.value" @change="scaleRangeInput($event)"  :step="item.scaleIntervalValue">
                                    
                                </div>
                           </div>
                         </div>
                     </div>
                </tab-content>
                
        </form-wizard>

        <div class="success-message" v-if="displaySuccessMsg">
            <h1 class="success-message__title">Survey Received!</h1>
        </div>

        <a href="#" class="button secondary backTo"  @click="backToSurveys" onclick="sendEventToApp('close')" v-if="showBack">Back to Surveys</a>

    </div>
</template>


<script>
import SurveyList from './SurveyList.vue';
import {FormWizard, TabContent} from 'vue-form-wizard';
//import $ from 'jquery';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

   // import moment from 'moment'
    export default {
        components: {
            FormWizard,
            TabContent,
            SurveyList
        },
        data: function () {
            return {
                showSurvey: false,
                showStart: true,
                showBack: false,
                displayErrMsg: false,
                displaySuccessMsg: false,
                questionPerPage : 0,
                questions: [],
                totalQuestions: 0,
                NumberOfTabs: 0,
                answerData: [],
                surveyId: 0,
                surveyNameIdentifier: '',
                globalToken: '',
                globalUser: ''
                //checkboxesSelectedArr: new Set()
            }
        },
        mounted: function () {
            
        },
        watch:{
            'answerData': {
                handler: function () {
                    let json_str = JSON.stringify(this.answerData);
                    this.$cookies.set('survey-user-data', json_str);
                    console.log(this.$cookies.get('survey-user-data'))
            }, deep:true}
		},
        methods: {
            markerQuant(min,max,step) {
                if (step == 2 && Boolean(max % 2)) {
                    // odd number by 2
                    // double the results then use placeholders for the even numbers
                    return Math.floor(((max - min)+step)/step) * 2;

                } else if (step == 2 && min == 1 && (max % 2) == 0) {
                    // step is 2, min = 1 and even number
                    // creates a trailing empty placeholder
                    // double the steps and then use empty placeholders for markerValue()
                    return Math.floor(((max - min)+step)/step) * 2;

                } else if (step == 5 && min == 1 && (max % 2) == 0) {
                    return max;

                } else if (step == 10 && min == 1 && ((max + 1 ) % 10) != 0) {
                    return max;

                } else {
                    return Math.floor(((max - min)+step)/step);
                }
            },
            markerValue(min,max,step,x) {
                // CMS has fixed values for:
                // step 1, 2, 5, 10
                // min 0, 1, 10
                // max 3, 5, 10, 20, 50, 100
                // console.log('markerValue: '+min,max,step,x):
                 if (min == 0) {
                    if (max % 2 == 1 && step == 2) {
                        // max is odd & step is 2
                        // return spaces for even number x 
                        if ((x % 2) == 0) {
                            return ' ';
                        } else {
                            return ((x * step) - step) / 2;
                        }
                    } else {
                        return (x * step) - step;
                    }

                } else if (min == 1) {
                    if (step == 2 && (max % 2) == 0) {
                        // step is 2 and even number
                        // creates a trailing empty value
                        // return spaces for even number x 
                        if ((x % 2) == 0) {
                            return ' ';
                        } else {
                            return x;
                        }

                    } else if (step == 5 && ((max + 1) % 5) != 0) {
                        if (((x - 1) % 5) == 0) {
                            return x;
                        } else {
                            return ' ';
                        }

                    } else if (step == 10 && ((max + 1) % 10) != 0) {
                        if (((x - 1) % 10) == 0) {
                            return x;
                        } else {
                            return ' ';
                        }

                    } else {
                        return x * step;
                    }

                } else if (min == 10) {
                    if (step == 1) {
                        return (x - 1) + 10;
                    } else if (step == 2) {
                        return (x * 2) - 2 + 10;
                    } else if (step == 5) {
                        return (x * step) + step;
                    } else {
                        return x * step;
                    }
            
                } else if (step == 5) {
                    return (x * step) + step;

                } else if (step == 2 && max % 2 == 1) {
                    // return spaces for even number x 
                    if ((x % 2) == 0) {
                        return ' ';
                    } else {
                        return ((x * step) - step) / 2;
                    }
                } else {
                    return x * step;
                }
            },
            startSurvey(surveyID, userID, surveyToken){
                console.log(surveyID, userID, surveyToken)
                this.answerData = []
                this.surveyNameIdentifier = surveyID;
                this.globalUser = userID;
                if(surveyToken == undefined || surveyToken == null){
                    this.getToken()
                } else {
                    this.fetchData(surveyToken)
                }
                // this.$store.dispatch('setCurrentUser', {
                //     userId: userID
                // })
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                var apiToken = token;
                this.globalToken = token;
                var urlProtocol = 'https:';
                var surveyIdentifier = this.surveyNameIdentifier;

                console.log(apiToken)

                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Survey/Survey/GetSurveyById?surveyId="+surveyIdentifier, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    this.showSurvey = true;
                    this.showStart = false;
                    const surveyJson = json.data;
                    console.log(surveyJson)

                    this.surveyId = surveyJson.surveyID;
                    this.questions = surveyJson.questions;
                    this.questionPerPage = surveyJson.questionsPerPage;
                    this.totalQuestions = this.questions.length;
                    this.NumberOfTabs = Math.ceil(this.totalQuestions / this.questionPerPage );
                    

                    for(var i=0;i<this.questions.length;i++){
                         //var item = {answerId: [], responseText: [] };

                        var item =  {
                            questionId: 0,
                            answers: [
                                    {
                                    answerId: 0,
                                    responseText: ""
                                }
                            ]
                        }

                         this.answerData.push(item);
                    }                   

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
            },
            getContentSurvey (idx){
                function paginate(array, page_size, page_number) {
                    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
                    return array.slice((page_number - 1) * page_size, page_number * page_size);
                }

                return paginate(this.questions, this.questionPerPage, idx)
            },
            getValueOfquestions(event){
                let getIdOfQuestionWrapper = event.currentTarget.parentElement.parentElement.parentElement.parentElement.id;
                let getCustomIdOfquestion = event.currentTarget.parentElement.parentElement.parentElement.parentElement.getAttribute("question-id");

                this.answerData[getIdOfQuestionWrapper].questionId = parseInt(getCustomIdOfquestion)
                this.answerData[getIdOfQuestionWrapper].answers[0].answerId = parseInt(event.currentTarget.id)
                // this.answerData[getIdOfQuestionWrapper].answers[0].responseText = event.currentTarget.value
            },
            selectedRadioAnswer(event){
                this.getValueOfquestions(event)
            },
            selectedDropdownAnswer(event){
                let getIdOfQuestionWrapper = event.currentTarget.parentElement.parentElement.parentElement.parentElement.id;
                let getCustomIdOfquestion = event.currentTarget.parentElement.parentElement.parentElement.parentElement.getAttribute("question-id");

                this.answerData[getIdOfQuestionWrapper].questionId = parseInt(getCustomIdOfquestion)
                this.answerData[getIdOfQuestionWrapper].answers[0].answerId = parseInt(event.currentTarget.value)
            },
            selectedCheckboxAnswer(event){
                let getIdOfQuestionWrapper = event.currentTarget.parentElement.parentElement.parentElement.parentElement.id;
                let getCustomIdOfquestion = event.currentTarget.parentElement.parentElement.parentElement.parentElement.getAttribute("question-id");
                
                if( this.answerData[getIdOfQuestionWrapper].questionId == parseInt(getCustomIdOfquestion)  ){
                    console.log('exist one')
                    
                    var item =  {
                            questionId: parseInt(getCustomIdOfquestion),
                            answers: [
                                    {
                                    answerId: parseInt(event.currentTarget.id),
                                    responseText: ""
                                }
                            ]
                    }

                    let findDeep = function(data, answerId) {
                        return data.some(function(e) {
                            if(e.answerId == answerId) return true;
                            else if(e.answers) return findDeep(e.answers, answerId)
                        })
                    }
                    

                    if( findDeep(this.answerData, parseInt(event.currentTarget.id)) ){
                        
                        console.log(parseInt(event.currentTarget.id))
                        if( this.answerData[getIdOfQuestionWrapper].answers[0].answerId === parseInt(event.currentTarget.id) ){
                            console.log('original position')
                            this.answerData[getIdOfQuestionWrapper].questionId = 0
                            this.answerData[getIdOfQuestionWrapper].answers[0].answerId = 0
                        }else{
                            const removed = this.answerData.filter(object => object.answers.some(item => item.answerId === parseInt(event.currentTarget.id) ));
                            this.answerData = this.answerData.filter(item => !removed.includes(item));
                        }

                        return
                    }else{
                        this.answerData.push(item)
                    }

                    return
                }else{
                    
                    let findDeep = function(data, answerId) {
                        return data.some(function(e) {
                            if(e.answerId == answerId) return true;
                            else if(e.answers) return findDeep(e.answers, answerId)
                        })
                    }

                    if( findDeep(this.answerData, parseInt(event.currentTarget.id)) ){
                       if( this.answerData[getIdOfQuestionWrapper].answers[0].answerId === parseInt(event.currentTarget.id) ){
                            
                            this.answerData[getIdOfQuestionWrapper].questionId = 0
                            this.answerData[getIdOfQuestionWrapper].answers[0].answerId = 0
                        }else{
                            const removed = this.answerData.filter(object => object.answers.some(item => item.answerId === parseInt(event.currentTarget.id) ));
                            this.answerData = this.answerData.filter(item => !removed.includes(item));
                            
                            return
                        }

                        
                    }

                    
                    this.answerData[getIdOfQuestionWrapper].questionId = parseInt(getCustomIdOfquestion)
                    this.answerData[getIdOfQuestionWrapper].answers[0].answerId = parseInt(event.currentTarget.id)
                }
            },
            fillTextInput(event){
                let getIdOfQuestionWrapper = event.currentTarget.parentElement.parentElement.parentElement.parentElement.id;
                let getCustomIdOfquestion = event.currentTarget.parentElement.parentElement.parentElement.parentElement.getAttribute("question-id");

                this.answerData[getIdOfQuestionWrapper].questionId = parseInt(getCustomIdOfquestion)
                this.answerData[getIdOfQuestionWrapper].answers[0].answerId = parseInt(event.currentTarget.id)
                this.answerData[getIdOfQuestionWrapper].answers[0].responseText = event.currentTarget.value
            },
            scaleRangeInput(event){
                let getIdOfQuestionWrapper = event.currentTarget.parentElement.parentElement.parentElement.parentElement.id;
                let getCustomIdOfquestion = event.currentTarget.parentElement.parentElement.parentElement.parentElement.getAttribute("question-id");

                this.answerData[getIdOfQuestionWrapper].questionId = parseInt(getCustomIdOfquestion)
                this.answerData[getIdOfQuestionWrapper].answers[0].answerId = parseInt(event.currentTarget.id)
                this.answerData[getIdOfQuestionWrapper].answers[0].responseText = event.currentTarget.value;
            },
            midRange(min,max) {
                var middleVal = ((max - min) * 0.5) + min;
                // console.log('middleVal: '+middleVal);
                return middleVal;
            },
            onComplete: function(){
                this.answerData.splice(this.answerData.findIndex(({questionId}) => questionId == 0), 1);
                var currentuserID;
                if(this.globalUser == undefined || this.globalUser == null) {
                    var getCurrentUser = this.$cookies.get('apiToken');
                    currentuserID = getCurrentUser.user.userId;
                } else {
                    //coming from mobile app / no cookies
                    currentuserID = this.globalUser;
                }
                
                let submitJson = {
                    surveyID: parseInt(this.surveyId),
                    userId: parseInt(currentuserID),
                    questions: this.answerData
                }
                const urlProtocol = 'https:';
                const apiToken = this.globalToken;
                const requestOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiToken
                    },
                    body: JSON.stringify(submitJson)
                };
                fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Survey/Survey/SubmitAnswers", requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        if(data.statusCode === 200){
                            this.showSurvey = false
                            this.displaySuccessMsg = true
                            this.showBack = true;
                        }
                        // need to handle expired token
                    })

                if(this.globalUser == '') {
                    // this assumes we came from desktop, not mobile app
                    this.showBack = true;
                }

            },
            validateStep(name) {
                let getActiveTab = document.querySelectorAll("div[aria-labelledby='"+name+"']"),
                    getChilds = getActiveTab[0].childNodes,
                    arrValidation = []
                
                
                for (var i = 0; i < getChilds.length; ++i) {
                    let questionType = getChilds[i].getAttribute('question-type');
                    validateByType(questionType, arrValidation)

                }

                function validateByType(typeNumber, arrForValidate){
                    if (typeNumber == 0  ){
                        let getRadioInputs = getChilds[i].getElementsByClassName('answer-container')[0].querySelectorAll('input[type=radio]');
                        let validateRadio = {isValid:false}

                        for (var k = 0; k < getRadioInputs.length; k++) {   
                            if (getRadioInputs[k].checked) {   
                                validateRadio.isValid = true;
                            }  
                        }

                        arrForValidate.push(validateRadio)
                    }

                    if (typeNumber == 1){
                        let getDropdown = getChilds[i].getElementsByClassName('answer-container')[0].querySelectorAll("select");
                        let validateDrop = {isValid:false}

                        if (!getDropdown[0].value == "")  {  
                            validateDrop.isValid = true; 
                        }

                        arrForValidate.push(validateDrop)
                    }

                    if (typeNumber == 2  ){
                        let getCheckboxInputs = getChilds[i].getElementsByClassName('answer-container')[0].querySelectorAll('input[type=checkbox]') 
                        let validateCheckBox = {isValid:false};
                        
                        for (var j = 0; j < getCheckboxInputs.length; j++) {   
                            if (getCheckboxInputs[j].checked) {   
                                validateCheckBox.isValid = true;
                            }  
                        }

                        arrForValidate.push(validateCheckBox)
                    }

                    if (typeNumber == 3){
                        let getTxtInput = getChilds[i].getElementsByClassName('answer-container')[0].querySelectorAll("input[type=text]")
                        let validateTextInput = {isValid:false};
                        
                        if (!getTxtInput[0].value == "")  {  
                            validateTextInput.isValid = true; 
                        }

                        arrForValidate.push(validateTextInput)
                    }

                    if(typeNumber == 4){
                        let getRangeOutput = getChilds[i].getElementsByClassName('answer-container')[0].querySelectorAll("output")
                        let validateRangeScale = {isValid:false};

                        if (!getRangeOutput[0].innerText == "")  {  
                            validateRangeScale.isValid = true; 
                        }

                        arrForValidate.push(validateRangeScale)
                    }
                     return arrForValidate
                }

                const isAllTrue = (v) => v.isValid == true;
                let validation = arrValidation.every(isAllTrue)
                this.displayErrMsg = !validation
                return validation
            },
            backToSurveys(){
                this.showSurvey = false;
                this.showBack = false;
                this.displaySuccessMsg = false;
                this.showStart = true;
                
                var url = window.location.href;
                var arr = url.split('?');
                arr.pop();


                window.history.pushState( {} , '', arr[0] );
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>


    $success__primary: #3DC480;
    $success__secondary: #B8BABB;


    .debug{
        position: fixed;
        width: 100%;
        left: 0;
        z-index: 2;
        background: aliceblue;
        top: 0;
        padding: 10px;
        border-bottom: 1px solid black;
    }

    .question-image img{
        max-width: 90%;
        max-height: 250px;
        display: block;
        margin: 0 auto $global-margin;
    }


</style>
