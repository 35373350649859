<template>
    <div class="grid-x grid-margin-x grid-margin-y pjl-game-list">
        <div class="cell small-12 medium-6 pjl-list-items" v-for="games in progressiveGames" :key="games.id">
            <div>  
                <span class="jackpot-current">Progressive Jackpot: <strong>${{ numberWithCommas(getHighestJackpot(games)) }}</strong></span>
                <span class="jackpot-date">{{ customFormatter(games.jackpotLastUpdatedDate) }}</span>
            </div>
            <img :src="games.listViewImageURL" :alt="games.name">

            <a class="button secondary" :href="truncateURL(games.games[0].nodeAliasPath)">How to Play</a>

            <span class="jackpot-update">*Jackpot updates every 2 minutes</span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        components: {
        },
        data: function() {
            return {
                progressiveGames: []
            }
        },
        mounted: function() {
            this.getAPItoken()
            .then((token) => {
                this.fetchData(token);
            });
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy h:mm A');
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            truncateURL(urlPath) {
                let lastSlash = urlPath.lastIndexOf("/");
                return urlPath.substr(0, lastSlash);
            },
            fetchData(token) {
                var apiToken = token;
                var urlProtocol = this.getProtocol();
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/OnlineGames/OnlineGame/GetProgressiveGames", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let response = json.data;
                    this.progressiveGames = response;

                    // console.log(response);
        
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
            },
            getHighestJackpot(game) {
                let gamesArray = game.games;
                let jackpotArray = [];
                //pull out the jackpot values for each game
                gamesArray.forEach((element) => jackpotArray.push(element.jackpotAmount));
                //sort the jackpot array highest to lowest
                jackpotArray.sort(function(a,b){
                    return b - a;
                });
                // console.log(gamesArray);
                // console.log(jackpotArray);
                
                //return the first element of the new sorted jackpot array
                return jackpotArray[0];
            }
        }
    };
</script>


<style lang="scss" scoped>
</style>